import React from "react";
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import AccountContentHeader from './components/AccountContentHeader';
import Loading from './components/Loading';
import ResultErrors from './components/ResultErrors';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { Link, useParams, Navigate } from "react-router-dom";
import Select from 'react-select';
import Cropper from 'react-easy-crop';
import img_preview from './media/item-preview-product.png';
import getCroppedImg from './utils/cropImage';
import sprite from './media/icons.svg';
import { getAuth } from "firebase/auth";
let Editor;
let EditorState;
let stateToHTML;
let ContentState;
let convertFromHTML;
let convertToRaw;

if (typeof window !== 'undefined') {
  Editor = require('react-draft-wysiwyg').Editor;
  EditorState = require('draft-js').EditorState;
  convertToRaw = require('draft-js').convertToRaw;
  stateToHTML = require('draft-js-export-html').stateToHTML;
  ContentState = require('draft-js').ContentState;
  convertFromHTML = require('draft-js').convertFromHTML;

  require('../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
}
let arrayMoveImmutable;
if (typeof window !== 'undefined') {
  // Выполняем импорт только в клиентской среде
  const { arrayMoveImmutable: clientArrayMoveImmutable } = require('array-move');
  arrayMoveImmutable = clientArrayMoveImmutable;
}
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const SortableItem = SortableElement(({item, value, handlerDelete, coverLabel}) => <div><button className="edit-product-img__remove" type="button" onClick={handlerDelete.bind(this, item)}><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><span className="product-num">{item + 1}</span>{item < 1 ? <span className="product-label">{coverLabel}</span> : ''}<img className="sort-img" src={URL.createObjectURL(value)} alt="" /></div>);

const SortableList = SortableContainer(({items, handlerDelete, coverLabel}) => {
  return (
    <div className="edit-product-img">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} item={index} index={index} value={value} handlerDelete={handlerDelete} coverLabel={coverLabel} />
      ))}
    </div>
  );
});
class ProductsCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      isLoadingCategories: false,
      isLoadedSubmitAddProduct: false,
      categoryID: this.props.id,
      categories: [],
      categoriesSelect: [],
      selectCategory: null,
      errors: null,
      descriptionValue: '',
      nameValue: '',
      priceValue: '',
      stockValue: '',
      limitedValue: false,
      sendNotification: false,
      formData: null,
      formDataForSubmit: null,
      percentValue: 0,
      errorFields: false,
      objForSubmit: {},
      previewStatus: false,
      modalDeleteEvents: false,
      productEnable: true,
      categoryIDForSubmit: null,
      imageSrc: null,
      crop: [],
      zoom: [],
      aspect: 1,
      rotation: 0,
      croppedAreaPixels: null,
      croppedImage: null,
      mainImgName: '',
      cropImageStatus: false,
      imageName: '',
      imageIndex: 0,
      timeoutId: null,
      SortableItemsImg: false,
      lengthData: 0,
      photosFiles: null,
      modalImg: false,
      modalImgIndex: null,
      priceValueNumError: false,
      stockValueNumError: false,
      priceValueCountError: false,
      errorFormatImgs: false,
      contentState: '',
      stockValueZeroError: false,
      priceValueLeadingZeroError: false,
      errorLengthImgs: false
    }
    this.onCropChange = this.onCropChange.bind(this);
    this.onZoomChange = this.onZoomChange.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
  }

  onCropChange = (crop) => {
    let cropArr = this.state.crop;
    cropArr[this.state.imageIndex] = crop;
    this.setState({ crop: cropArr, cropImageStatus: true })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    let that = this;
    this.setState({ croppedAreaPixels: croppedAreaPixels })
    let croppedImage = '';
    if (croppedAreaPixels && croppedAreaPixels.width >= 325 && croppedAreaPixels.height >= 325) {
      croppedImage = getCroppedImg(
        that.state.imageSrc,
        croppedAreaPixels,
        that.state.rotation
      );
    } else {
      const requiredSize = Math.max(325, croppedAreaPixels.width, croppedAreaPixels.height);
      const scale = 325 / requiredSize;
      const scaledAreaPixels = {
        x: croppedAreaPixels.x * scale,
        y: croppedAreaPixels.y * scale,
        width: croppedAreaPixels.width * scale,
        height: croppedAreaPixels.height * scale,
      };
      croppedImage = getCroppedImg(
        that.state.imageSrc,
        scaledAreaPixels,
        that.state.rotation
      );
    }

     let timeoutId = that.state.timeoutId;
     if(timeoutId) {
      clearTimeout(timeoutId);
     }
     timeoutId = setTimeout(() => {
        const myPromise = new Promise((resolve, reject) => {
          const result = croppedImage;
          resolve(result);
        });
        myPromise.then((result) => {
          if(typeof window !== "undefined" && typeof document !== 'undefined'){
          var img = new Image();
          img.src = result;
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');
          img.onload = function() {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            var dataURL = canvas.toDataURL('image/jpeg');
            var blob = dataURLToBlob(dataURL);

            const formData = new FormData();
            formData.append('file', blob, that.state.imageName);
            let formDataArr = that.state.formData;
            formDataArr[that.state.imageIndex] = formData.get('file');
            that.setState({
              formData: formDataArr
            });
            function dataURLToBlob(dataURL) {
              var byteString = atob(dataURL.split(',')[1]);
              var mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
              var ab = new ArrayBuffer(byteString.length);
              var ia = new Uint8Array(ab);
              for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
              }
              return new Blob([ab], {type: mimeString});
            }
          };
        }
        });
     }, 500);
    this.setState({ timeoutId: null })
  }

  onZoomChange = (zoom) => {
    let zoomArr = this.state.zoom;
    zoomArr[this.state.imageIndex] = zoom;
    this.setState({ zoom: zoomArr })
  }

  zoomIn = () => {
    let zoomArr = this.state.zoom;
    zoomArr[this.state.imageIndex] = zoomArr[this.state.imageIndex] + 0.1;
    this.setState((prevState) => ({
      zoom: zoomArr
    }));
  };

  zoomOut = () => {
    let zoomArr = this.state.zoom;
    if(zoomArr[this.state.imageIndex] > 1){
      zoomArr[this.state.imageIndex] = zoomArr[this.state.imageIndex] - 0.1;
      this.setState((prevState) => ({
        zoom: zoomArr
      }));
    }
  };

  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalDisabled: false,
        modalDeleteEvents: false,
        modalAddPhotos: false,
        formData: null,
        formDataForSubmit: null,
        imageSrc: null,
        aspect: 1,
        rotation: 0,
        croppedAreaPixels: null,
        croppedImage: null,
        mainImgName: '',
        cropImageStatus: false,
        imageName: '',
        imageIndex: 0,
        timeoutId: null,
        modalData: {},
        crop: [],
        zoom: [],
        SortableItemsImg: false,
        lengthData: 0,
        photosFiles: null
      }));
    }, 500);
  }
  handleFileSelect() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      var file = this.state.formData;
      var f = file[0];
      var reader = new FileReader();
      reader.onload = (function(theFile) {
          return function(e) {
              var span = document.querySelector('.account-preview__wgt_col-logo');
              span.innerHTML = ['<img class="thumb" title="', escape(theFile.name), '" src="', e.target.result, '" />'].join('');
          };
      })(f);
      reader.readAsDataURL(f);
    }
  }
  handlerDeleteEventModal(event){
    this.setState({
      modalDeleteEvents: true
    });
  }
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(prevState => ({
      formData: arrayMoveImmutable(prevState.formData, oldIndex, newIndex),
      formDataForSubmit: arrayMoveImmutable(prevState.formDataForSubmit, oldIndex, newIndex)
    }));
  }
  handlerNameValue (event) {
    if(this.state.errorFields){
      this.setState({
        errorFields: false
      });
    }
    this.setState({
      nameValue: event.target.value
    });
  }
  handlerPriceValue(event) {
    let inputValue = event.target.value.replace(',', '.'); // Заменяем запятую на точку
    this.setState({
      priceValueNumError: false,
      priceValueCountError: false,
      priceValueLeadingZeroError: false
    });

    // Проверка на ведущее количество нулей (например, 00000)
    if (inputValue.startsWith('0') && inputValue.length > 1 && !inputValue.startsWith('0.')) {
      this.setState({
        priceValueLeadingZeroError: true
      });
      return; // Прерываем выполнение, если введено число с множеством ведущих нулей
    }

    // Разрешаем пустую строку, целые числа и числа с десятичной точкой или запятой
    if (inputValue === "" || /^\d*[\.,]?\d*$/.test(inputValue)) {
      const numericValue = parseFloat(inputValue);

      // Если строка не пустая, проверяем числовое значение
      if (inputValue === "" || numericValue <= 999999) {
        this.setState({
          priceValue: inputValue
        });
      } else {
        this.setState({
          priceValueCountError: true
        });
      }
    } else {
      this.setState({
        priceValueNumError: true
      });
    }
  }



  handlerStockValue(event) {
    const inputValue = event.target.value;

    this.setState({
      stockValueNumError: false,
      stockValueZeroError: false, // Сброс ошибки нуля
      stockValueCountError: false, // Сброс ошибки превышения лимита
    });

    if (/^\d*$/.test(inputValue)) {
      if (inputValue === '0') {
        // Если значение равно 0, устанавливаем ошибку
        this.setState({
          stockValueZeroError: true
        });
      } else if (inputValue.length > 1 && inputValue.startsWith('0')) {
        // Если значение начинается с нуля и имеет больше одной цифры
        this.setState({
          stockValueZeroError: true
        });
      } else if (inputValue <= 999999) {
        this.setState({
          stockValue: inputValue
        });
      } else {
        this.setState({
          stockValueCountError: true
        });
      }
    } else {
      this.setState({
        stockValueNumError: true
      });
    }
  }


  handlerLimitedValue(event) {
    this.setState(prevState => ({
      limitedValue: !prevState.limitedValue
    }));
  }
  handlerDescriptionValue (event) {
    this.setState({
      descriptionValue: event.target.value
    });
  }

  onEditorStateChange = (contentState) => {
    if(this.state.errorDescriptionOnlySpace){
      let s = contentState.getCurrentContent();
      const blocks = s.getBlockMap().toArray();
      const isOnlySpaces = blocks.every(block => block.getText().trim() === '');
      if (!isOnlySpaces) {
        this.setState({
          errorDescriptionOnlySpace: false
        });
      }
    }
    this.setState({
      contentState: contentState
    });
  };

  handlerProductCategory(event){
    this.setState({
      categoryID: event.value,
      categoryName: event.label
    });
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    this.setState({
      categoryID: Number(this.props.id)
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/products/categories/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "itemsPerPage": 0,
          "direction": "DESC",
          "orderField": "productCategoryID"
        })
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            });
          });
        } else {
          res.json().then(function(data) {
            data.data.map((item, i) => {
              that.setState(prevState => ({
                categoriesSelect: [...prevState.categoriesSelect, {value: item.categoryID, label: item.name ? item.name : 'Без категорії'}]
              }));
            });
            that.setState({
              isLoadingCategories: true
            });
          })
        }
      })
    })
  }
  handlerNextPreview(type, event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.body.classList.add('preview');
      if(type === 'back'){
        document.querySelector('.account-preview__wgt_col-logo').innerHTML = '';
      }
    }
    let that = this;
    let error = false;
    this.setState(prevState => ({
      errorFields: false,
      errorNameOnlySpace: false
    }));
    let nameValue = that.validItemEmpty('nameValue');
    if(/^\s*$/.test(nameValue) && nameValue !== ''){
      error = true;
      this.setState({errorNameOnlySpace: true });
    }
    if(error || !nameValue || !this.state.priceValue || (!this.state.limitedValue && (!this.state.stockValue || this.state.stockValue === '0'))){
      error = true;
      this.setState({
        errorFields: true
      });
    } else {
      this.setState(prevState => ({
        previewStatus: !prevState.previewStatus
      }));
      let obj = {
        "categoryID": that.state.categoryID,
        "description": (typeof window !== "undefined" && typeof document !== 'undefined') ? stateToHTML(this.state.contentState.getCurrentContent()) : '',
        "name": nameValue,
        "price": that.state.priceValue,
        "sendNotification": that.state.sendNotification,
        "stock": that.state.stockValue,
        "unlimited": that.state.limitedValue,
        "enable": that.state.productEnable
      };
      this.setState({
        objForSubmit: obj
      });
    }
  }
  validItemEmpty = (item) => {
    let itemValue = this.state[item];
    if (itemValue !== null && itemValue !== undefined && typeof itemValue === 'string') {
      let onlySpace = /^\s*$/.test(itemValue);
      let itemCorrect;
      if(onlySpace){
        itemCorrect = itemValue;
      } else {
        itemCorrect = itemValue.trim();
      }
      this.setState({ [item]: itemCorrect });
      return itemCorrect;
    }

    return itemValue;
  }
  handlerAddProductSubmit (event) {
    let that = this;
    let error = false;
    event.preventDefault();
    this.setState({
      errorFields: false,
      errorNameOnlySpace: false
    });
    let nameValue = that.validItemEmpty('nameValue');
    if(/^\s*$/.test(nameValue) && nameValue !== ''){
      error = true;
      this.setState({errorNameOnlySpace: true });
    }
    if(error || !nameValue || !this.state.priceValue || (!this.state.limitedValue && (!this.state.stockValue || this.state.stockValue === '0'))){
      this.setState({
        errorFields: true
      });
    } else {
        this.setState({
          isLoadedSubmitAddProduct: true
        });
        getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/products`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(that.state.objForSubmit)
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                isLoadedSubmitAddProduct: false
              });
            });
          } else {
            res.json().then(function(data) {
            if(!that.state.formData){
              if(typeof window !== "undefined" && typeof document !== 'undefined'){
                localStorage.setItem("successProductEdit", JSON.stringify(true))
              }
              that.setState({
                isLoadedSubmitAddProduct: false,
                successSubmit: true
              });
              setTimeout(() => {
                that.setState({
                  successSubmit: false
                });
              }, 5000);
            }
          if(that.state.formData){
            const myHeaders = new Headers({
              'Authorization': 'Bearer ' + idToken
            });
            async function asyncAwaitWay(that) {
              let formData = new FormData();
              let i = that.state.formData.length;
              let countPercent = 100 / i;
                    for (let file of that.state.formData) {
                      if(typeof window !== "undefined" && typeof document !== 'undefined'){
                        formData.append('file', file);
                      }
                      await  fetch(`${that.props.queryLink}/api/v1/products/${data.productID}/photos`, {
                        method: 'POST',
                        headers: myHeaders,
                        body: formData
                      }).then(res => {
                        if(res.status !== 200) {
                          res.json().then(function(data) {
                            that.setState({
                              errors: data.errors
                            });
                          });
                        } else {
                          res.json().then(function(data) {
                            that.setState(prevState => ({
                              percentValue: (Math.ceil(prevState.percentValue + countPercent))
                            }));
                            i--;
                            if(i < 1){
                              if(typeof window !== "undefined" && typeof document !== 'undefined'){
                                localStorage.setItem("successProductEdit", JSON.stringify(true));
                              }
                              that.setState({
                                isLoadingSubmit: false,
                                successSubmit: true
                              });
                              setTimeout(() => {
                                that.setState({
                                  successSubmit: false
                                });
                              }, 5000);
                            }
                          })
                        }
                      })
                      formData.delete('file');
                    }
            }
            asyncAwaitWay(that);
          };
          })
        }
        })
      })
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handlerCloseSuccess (event) {
    event.preventDefault();
    this.setState(prevState => ({
      successSubmit: false
    }));
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errorSubmit: null,
      errors: null
    }));
  }
  handlerAddPhotos(event){
    let that = this;
    let files = event.target.files;
    let error = false;
    this.setState(prevState => ({
      errorFormatImgs: false,
      errorLengthImgs: false
    }));
    if(files){
      if(files && files.length > 5){
        this.setState(prevState => ({
          errorLengthImgs: true
        }));
        return false;
      }
      for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileName = file.name.toLowerCase();
          const extension = fileName.split('.').pop();
          if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png' && extension !== 'webp') {
              error = true;
          }
      }
    }
    if(!error){
      if(this.state.photosFiles === null){
        this.setState(prevState => ({
          photosFiles: files
        }))
      }
      let formData = this.state.formData;
      if(this.state.crop && this.state.crop.length > 0){
        this.setState(prevState => ({
          lengthData: (formData.length + prevState.crop.length) + 1
        }))
      }
      if(formData){
          setTimeout(() => {
            let arrCrop = this.state.crop;
            let arrZoom = this.state.zoom;
              for(let i = 0; i < that.state.photosFiles.length; i++){
                arrCrop.push({ x: 0, y: 0 });
                arrZoom.push(1);
              }
              this.setState(prevState => ({
                crop: arrCrop,
                zoom: arrZoom
              }));
              this.setState(prevState => ({
                imageSrc: URL.createObjectURL(this.state.photosFiles[this.state.photosFiles.length - 1]),
                imageName: that.state.photosFiles[that.state.photosFiles.length - 1],
                imageIndex: prevState.crop.length - 1,
                lengthData: prevState.crop.length - 1,
              }));
            }, 1000);
          const dt = new DataTransfer();
          for (let file of formData) {
            dt.items.add(file)
          }
          if(!this.state.modalAddPhotos){
            for (let file of files) {
              dt.items.add(file)
            }
          }
            this.setState(prevState => ({
              formData: Array.from(dt.files),
              formDataForSubmit: Array.from(dt.files),
              SortableItemsImg: true
            }));
      } else {
        setTimeout(() => {
          let arrCrop = this.state.crop;
          let arrZoom = this.state.zoom;
            for(let i = 0; i < files.length; i++){
              arrCrop.push({ x: 0, y: 0 });
              arrZoom.push(1);
            }
          this.setState(prevState => ({
            crop: arrCrop,
            zoom: arrZoom
          }));
          this.setState(prevState => ({
            imageSrc: URL.createObjectURL(files[0]),
            imageName: files[0],
            imageIndex: 0
          }));
          this.setState(prevState => ({
            formData: Array.from(files),
            formDataForSubmit: Array.from(files),
            lengthData: (files.length - 1)
          }));
        }, 1000);
      }
      if(this.state.modalAddPhotos){
        this.setState(prevState => ({
          modalAddPhotos: false
        }));
      } else {
        this.setState(prevState => ({
          modalAddPhotos: true
        }));
      }
    } else {
      this.setState(prevState => ({
        errorFormatImgs: true
      }));
    }
  }
  handlerSetFormDataEdit(item, index, event){
    this.setState({
      imageSrc: URL.createObjectURL(item),
      imageName: item.name,
      imageIndex: index,
      aspect: 1,
      rotation: 0,
      croppedAreaPixels: null
    });
  }
  handlerDisabledPhoto(event){
    this.setState({
      imageSrc: null,
      imageName: '',
      imageIndex: null
    });
  }
  handlerDeleteImg(index, event){
    let formData = this.state.formData;
    let formDataForSubmit = this.state.formDataForSubmit;
    let cropData = this.state.crop;
    let zoomData = this.state.zoom;
    formData.splice(index, 1);
    formDataForSubmit.splice(index, 1);
    cropData.splice(index, 1);
    zoomData.splice(index, 1);
    this.setState(prevState => ({
      formData: formData,
      formDataForSubmit: formDataForSubmit,
      crop: cropData,
      zoom: zoomData
    }));
  }
  handlerNotificationProduct(event){
    let obj = this.state.objForSubmit;
    if(this.state.productEnable){
      obj.sendNotification = !obj.sendNotification;
    }
    this.setState(prevState => ({
      objForSubmit: obj
    }));
  }
  handlerEnableForSubmit(event){
    let obj = this.state.objForSubmit;
    if(obj.enable){
      obj.sendNotification = false;
    }
    obj.enable = !obj.enable;
    this.setState({
      objForSubmit: obj
    });
  }
  handlerOpenModalImg(index, event){
    this.setState({
      modalImg: true,
      modalImgIndex: index
    });
  }
  handlerCloseModalImg(index, event){
    this.setState({
      modalImg: false,
      modalImgIndex: null
    });
  }
  render() {
    function removeLeadingZeros(inputString) {
      inputString = inputString.replace(',', '.');
      var number = parseFloat(inputString);
      return number;
    }
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('catalog-AddItemTitle') + ' | ' + this.props.companyName;
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successSubmit && <Navigate to={`/account/products/${this.state.categoryID}`} replace={true} />) : ''}
      <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
      <div className="account-wrap">
        <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
        <div className="account-content">
          {this.state.previewStatus ? <div className="account-content__inner account-preview">

          {/* Account header component */}
          <AccountContentHeader
            handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
            languageActive={this.props.chooseLanguage}
            preview={true}
            backBtn={true}
            backBtnType="btn"
            backBtnHandler={this.handlerNextPreview.bind(this, 'back')}
            backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
          />

          <h2 className="account-preview__headline">{this.props.getTranslate('catalog-previewTitle')}</h2>
          <div className="account-preview__inner account-preview__inner_preview">
            <div className="account-preview__info">
              <div className="account-preview__wgt">
                <div className="account-preview__wgt_row">
                  <div className="account-preview__wgt_col-info account-preview__info-product">
                    <div className="account-preview__wgt-headline">
                      <div className="account-preview__wgt-headline-text">{this.state.objForSubmit.name}</div>
                    </div>
                    <div className="account-preview__info-row">
                      <div className="account-preview__info-wgt account-preview__info-wgt_width_33">
                        <div className="account-preview__info-wgt-label">{this.props.getTranslate('catalog-ModalProductPrice')}:</div>
                        <div className="account-preview__info-wgt-value">{removeLeadingZeros(this.state.objForSubmit.price) + ' ' + this.props.currency}</div>
                      </div>
                      <div className="account-preview__info-wgt account-preview__info-wgt_width_33">
                        <div className="account-preview__info-wgt-label">{this.props.getTranslate('catalog-ModalProductAmount')}:</div>
                        <div className="account-preview__info-wgt-value">{this.state.objForSubmit.unlimited ? '100+' : this.state.objForSubmit.stock}</div>
                      </div>
                      <div className="account-preview__info-wgt account-preview__info-wgt_width_33">
                        <div className="account-preview__info-wgt-label">{this.props.getTranslate('catalog-ModalProductCategory')}:</div>
                        <div className="account-preview__info-wgt-value">{this.state.categoryName ? this.state.categoryName : this.state.categoriesSelect.find(value => value.value === this.state.categoryID).label}</div>
                      </div>
                      <div className="account-preview__info-wgt">
                        <div className="account-preview__info-wgt-label">{this.props.getTranslate('catalog-ModalProductDescription')}:</div>
                        <div className="account-preview__info-wgt-value account-preview__info-wgt-value_editor" dangerouslySetInnerHTML={{__html: this.state.objForSubmit.description}}></div>
                      </div>
                      {this.state.formDataForSubmit ? <div className="account-preview__info-imgs">
                          {this.state.formDataForSubmit.map((item, index) => {
                            return <div className="account-preview__info-imgs-thumb" key={index}><img key={index} src={URL.createObjectURL(item)} alt="" onClick={this.handlerOpenModalImg.bind(this, index)} /></div>
                          })}
                      </div> : ''}
                    </div>
                  </div>
                </div>
                <div className="account-preview__info-nav account-preview__info-nav-between">
                  <button className="button-standart button-standart_theme_blue" type="button" onClick={this.handlerNextPreview.bind(this, 'back')}>{this.props.getTranslate('catalog-ModalProductBtnEdit')}</button>
                  <button className="btn-edit btn-edit_theme_remove" type="button" onClick={this.handlerDeleteEventModal.bind(this)}>
                    <svg className="btn-edit__icon">
                      <use href={`${sprite}#delete-red`}></use>
                    </svg>
                  </button>
                </div>
              </div>
              <div className="account-preview__wgt">
                <div className="account-preview__wgt-headline-text">{this.props.getTranslate('catalog-previewAdditionalTitle')}</div>
                <div className="field-wrap field-wrap__tooltip">
                  <div className="unlimited-product"><div><input className="styled-checkbox" id="styled-checkbox-0" type="checkbox" checked={!this.state.objForSubmit.enable} onChange={this.handlerEnableForSubmit.bind(this)} /><label htmlFor="styled-checkbox-0"></label></div><span>{this.props.getTranslate('catalog-ModalProductHideCheckbox')}</span></div>
                </div>
                <div className="field-wrap">
                  <div className="unlimited-product"><div><input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" checked={this.state.objForSubmit.sendNotification} onChange={this.handlerNotificationProduct.bind(this)} /><label className={`checkbox-label ${!this.state.objForSubmit.enable ? 'checkbox-label__disabled' : ''}`} htmlFor="styled-checkbox-1"></label></div><span>{this.props.getTranslate('catalog-ModalProductNotifyCheckbox')}</span></div>
                </div>
              </div>
              <button className="preview-submit-btn" type="button" disabled={this.state.isLoadedSubmitAddProduct ? true : false} onClick={this.handlerAddProductSubmit.bind(this)}>{this.props.getTranslate('catalog-ModalProductSubmitPulish')}{this.state.isLoadedSubmitAddProduct && <Loading />}</button>
            </div>
            <div className="account-preview__img">
              <div className="account-preview__img-description">{this.props.getTranslate('catalog-ProductPreviewExample')}</div>
              <img src={img_preview} alt="" />
            </div>
          </div>
          </div> : <div className="account-content__inner">

          {/* Account header component */}
          <AccountContentHeader
            handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
            languageActive={this.props.chooseLanguage}
            backBtn={true}
            backBtnType="link"
            backBtnClass="button-back_theme_gray"
            backBtnHandler={this.props.getPermalink(`/account/products/${this.props.id}`)}
            backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
          />

          <div className="account-content__header-title">
            <h1 className="account-content__headline">{this.props.getTranslate('catalog-AddItemTitle')}</h1>
          </div>
              <div className="add-product-form-wrap">
                <div className="add-product-form">
                  <div className="add-product-form__inner">
                    <div className="edit-user-form__row">
                      <div className="edit-user-form__col edit-user-form__col_size_3">
                        <div className="field-wrap">
                          <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="name">{this.props.getTranslate('catalog-AddItemName')}</label>
                          <input id="name" className={`field-wrap__input ${(this.state.errorFields && !this.state.nameValue) || (this.state.errorFields && this.state.errorNameOnlySpace && this.state.nameValue !== '') ? 'field-wrap__input_error' : ''}`} value={this.state.nameValue} onChange={this.handlerNameValue.bind(this)} placeholder={this.props.getTranslate('catalog-AddItemNameFieldCaption')} />
                          {this.state.errorFields && !this.state.nameValue ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                          {this.state.errorFields && this.state.errorNameOnlySpace && this.state.nameValue !== '' ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                        </div>
                      </div>
                      <div className="edit-user-form__col edit-user-form__col_size_3">
                        <div className="field-wrap">
                          <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('catalog-AddItemCategory')}</label>
                          {this.state.categoriesSelect ? <Select
                            styles={customStyles}
                            options={this.state.categoriesSelect}
                            value={this.state.categoriesSelect.find(value => value.value === this.state.categoryID)}
                            onChange={this.handlerProductCategory.bind(this)}
                            isSearchable={false}
                            placeholder={this.props.getTranslate('select-selectPlaceholder')}
                          /> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="edit-user-form__row">
                      <div className="edit-user-form__col edit-user-form__col_size_3">
                        <div className="field-wrap">
                          <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="price">{this.props.getTranslate('catalog-ItemPrice')}</label>
                          <div className="field-wrap__currency-name">
                            <input id="price" className={`field-wrap__input ${(this.state.errorFields && !this.state.priceValue) || this.state.priceValueNumError || this.state.priceValueCountError ? 'field-wrap__input_error' : ''}`} value={this.state.priceValue} onChange={this.handlerPriceValue.bind(this)} placeholder={this.props.getTranslate('catalog-ItemPriceFeildCaption')} />
                            {this.props.currency && <label className="field-wrap__currency-name-item" htmlFor="price">{this.props.currency}</label>}
                          </div>
                          {this.state.errorFields && !this.state.priceValue ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                          {this.state.priceValueNumError ? <div className="validation-error">В полі повинні бути тільки цифри</div> : ''}
                          {this.state.priceValueCountError ? <div className="validation-error">Не повинно перевищувати 999 999</div> : ''}
                          {this.state.priceValueLeadingZeroError ? <div className="validation-error">Продовжуйте вводити ціну з використанням десятичного роздільника</div> : ''}
                        </div>
                      </div>
                      <div className="edit-user-form__col edit-user-form__col_size_3">
                        <div className="field-wrap">
                          <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="amount">{this.props.getTranslate('catalog-ItemCount')}</label>
                          {this.state.limitedValue ? <input id="amount" className="field-wrap__input" value="" disabled/> :
                          <input id="amount" className={`field-wrap__input ${this.state.errorFields && !this.state.limitedValue && (!this.state.stockValue || this.state.stockValue === '0') || this.state.stockValueNumError || this.state.stockValueCountError || this.state.stockValueZeroError ? 'field-wrap__input_error' : ''}`} value={this.state.stockValue} onChange={this.handlerStockValue.bind(this)} placeholder={this.props.getTranslate('catalog-ItemCountFieldCaption')} />}
                          {this.state.errorFields && !this.state.limitedValue && (!this.state.stockValue || this.state.stockValue === '0') ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                          {this.state.stockValueNumError ? <div className="validation-error">В полі повинні бути тільки цифри</div> : ''}
                          {this.state.stockValueCountError ? <div className="validation-error">Не повинно перевищувати 999 999</div> : ''}
                          {this.state.stockValueZeroError ? <div className="validation-error">Кількість не може починатись з 0</div> : ''}
                        </div>
                      </div>
                      <div className="edit-user-form__col edit-user-form__col_size_3">
                        <div className="unlimited-product">
                          <div>
                            <input className="styled-checkbox" id="styled-checkbox-0" type="checkbox" name="unlimited" checked={this.state.limitedValue} onChange={this.handlerLimitedValue.bind(this)}/>
                            <label htmlFor="styled-checkbox-0"></label>
                          </div>
                          <span>{this.props.getTranslate('catalog-ItemCountUnlimited')}</span>
                        </div>
                      </div>
                    </div>
                    <div className="field-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="message">{this.props.getTranslate('catalog-ItemDescription')}</label>
                      {(typeof window !== "undefined" && typeof document !== 'undefined') ? <Editor
                        toolbar={{
                          options: ['inline', 'blockType', 'list'],
                          inline: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['bold', 'italic', 'underline']
                          },
                          blockType: {
                            inDropdown: true,
                            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                          },
                          list: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['unordered', 'ordered']
                          }
                        }}
                        wrapperClassName={`wrapper-class editor-field`}
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class editor-toolbar"
                        editorState={this.state.contentState}
                        onEditorStateChange={this.onEditorStateChange}
                      /> : ''}
                    </div>
                    <div className="edit-user-form__imgs">
                      <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('catalog-ItemPhoto')}</label>
                      <div className="edit-user-form__imgs-inner">
                        <div className={`edit-user-upload${this.state.errorFormatImgs ? ' edit-user-upload-error' : ''}`}>
                          <input type="file" id="upload" multiple onChange={this.handlerAddPhotos.bind(this)} />
                          <label htmlFor="upload">
                            <svg className="upload__icon">
                              <use href={`${sprite}#img-upload`}></use>
                            </svg>
                          {this.props.getTranslate('catalog-ItemPhotoButton')} {this.state.formDataForSubmit && <span style={{marginTop: '5px'}}>{this.props.getTranslate('loadingImg-AmountTitle')} {this.state.formDataForSubmit.length}</span>}</label>
                        </div>
                        {this.state.formDataForSubmit && this.state.isLoadedSubmitAddProduct ? <div className="edit-user-upload">
                          <div className="edit-user-upload__percent">
                            <span>{this.props.getTranslate('loadingImg-PercentTitle')} {this.state.percentValue}%</span>
                            <div className="edit-user-upload__bar">
                              <div className="edit-user-upload__bar-inner" style={{width: this.state.percentValue + '%'}}></div>
                            </div>
                          </div>
                        </div> : ''}
                        {this.state.formDataForSubmit && this.state.SortableItemsImg ? <SortableList items={this.state.formDataForSubmit} onSortEnd={this.onSortEnd} axis={'xy'} handlerDelete={this.handlerDeleteImg.bind(this) } coverLabel={this.props.getTranslate('catalog-ItemMainPhoto')} /> : ''}
                      </div>
                      {this.state.errorFormatImgs ? <div className="validation-error">Формат зображень повинен бути jpg png або webp</div> : ''}
                      {this.state.errorLengthImgs ? <div className="validation-error">Кількість зображень для завантаження не повинно перевищувати 5</div> : ''}
                    </div>
                  </div>
                  <div className="field-wrap__submit add-product-form__submit">
                    <button className="btn btn_width_100 btn_size_lg" type="button" onClick={this.handlerNextPreview.bind(this)}>{this.props.getTranslate('addProduct-buttonGoPreview')}</button>
                  </div>
                </div>
              </div>
            </div>}
          </div>
      </div>

      {this.state.modalImg ? <div className="modal-img">
          <div className="modal-img__overlay"></div>
          <div className="modal-img__inner">
            <img className="modal-img__thumb" src={URL.createObjectURL(this.state.formDataForSubmit[this.state.modalImgIndex])} alt="" />
            <button className="modal-img__button" type="button" onClick={this.handlerCloseModalImg.bind(this)}>
              <svg className="modal-img__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
        </div> : ''}

      {this.state.modalDeleteEvents ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.props.getTranslate('catalog-ModalRemoveProductTitle')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
          <div className="delete-category-form__description">{this.props.getTranslate('catalog-ModalRemoveProductDescription')}</div>
            <div className="field-wrap__submit">
              <Link className="btn btn_size_lg btn_theme_remove btn_width_100 user-info__nav-btn" to={this.props.getPermalink(`/account/products/${this.state.categoryID}`)}>{this.props.getTranslate('catalog-ModalRemoveProductBtnRemove')}</Link>
            </div>
      </div>
      </div> : ''}

      {this.state.modalAddPhotos ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal modal__product-img">
        <div className="modal-header">
          <h2 className="modal-headline">Редагування зображення</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>

        <><div className="modal__img-setting-wrap">
          <div className="modal__img-setting-inner">
            {this.state.imageSrc && this.state.formData && this.state.formData.length > this.state.lengthData ? <Cropper
              image={this.state.imageSrc}
              crop={this.state.crop[this.state.imageIndex]}
              zoom={this.state.zoom[this.state.imageIndex]}
              aspect={this.state.aspect}
              onCropChange={this.onCropChange}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.onZoomChange}
              showGrid={true}
            /> : <Loading />}
            <div className="modal__img-zoom-btns">
              <button className="modal__img-zoom-btn" onClick={this.zoomIn} type="button">+</button>
              <button className="modal__img-zoom-btn" onClick={this.zoomOut} type="button">-</button>
            </div>
          </div>
        </div></>
        {this.state.formDataForSubmit ? <div className="modal__img-row">
            {this.state.formDataForSubmit.map((item, index) => {
              return <div className={`modal__col ${index === this.state.imageIndex ? 'active' : ''}`} key={index}><div className="modal__col-inner"><button className="edit-product-img__remove" type="button" onClick={this.handlerDeleteImg.bind(this, index)}><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><img onClick={this.handlerSetFormDataEdit.bind(this, item, index)} key={index} src={URL.createObjectURL(item)} alt="" /></div></div>
            })}
        </div> : ''}
        {this.state.errorLengthImgs ? <div className="validation-error">Кількість зображень для завантаження не повинно перевищувати 5</div> : ''}
        <button className="btn btn_size_lg btn_width_100" onClick={this.handlerAddPhotos.bind(this)} type="button">Завантажити</button>
      </div>
      </div> : ''}

    </>
  }
}
export default (props) => <ProductsCreate {...useParams()} {...props} />
