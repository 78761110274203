import React from "react";
class Field extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return  <div className="field-wrap">
      <label className="field-wrap__label" htmlFor={this.props.for}>{this.props.label}</label>
      {this.props.textarea ? <textarea id={this.props.for} className="field-wrap__input field-wrap__input_textarea" value={this.props.value} onChange={this.props.onChange} placeholder={this.props.placeholder} /> : <input id={this.props.for} className="field-wrap__input" type="text" placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.onChange} />}
    </div>
  }
}
export default Field;
