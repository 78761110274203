import React from "react";
import Button from '../Button';
import WgtInfo from './AboutInfoWgt';
class AboutInfoWgts extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <section className="about-info-wgts">
      <div className="wrapper">
        <div className="about-info-wgts__inner">
          <WgtInfo
            headline={this.props.getTranslate('aboutPanel-InfoWgtsFirstHeadline')}
            colorLine="#d2efd8"
            description={this.props.getTranslate('aboutPanel-InfoWgtsFirstDescription')}
            link="#"
            linkTxt={this.props.getTranslate('aboutPanel-InfoWgtsLinkTxt')}
            icon="about-team-icon"
          />
          <WgtInfo
            headline={this.props.getTranslate('aboutPanel-InfoWgtsSecondHeadline')}
            colorLine="#d2e4ef"
            description={this.props.getTranslate('aboutPanel-InfoWgtsSecondDescription')}
            link="#"
            linkTxt={this.props.getTranslate('aboutPanel-InfoWgtsLinkTxt')}
            icon="about-bonuses-icon"
          />
          <WgtInfo
            headline={this.props.getTranslate('aboutPanel-InfoWgtsThirdHeadline')}
            colorLine="#e1d2ef"
            description={this.props.getTranslate('aboutPanel-InfoWgtsThirdDescription')}
            link="#"
            linkTxt={this.props.getTranslate('aboutPanel-InfoWgtsLinkTxt')}
            icon="about-loyalty-icon"
          />
          <WgtInfo
            headline={this.props.getTranslate('aboutPanel-InfoWgtsFourHeadline')}
            colorLine="#efdad2"
            description={this.props.getTranslate('aboutPanel-InfoWgtsFourDescription')}
            link="#"
            linkTxt={this.props.getTranslate('aboutPanel-InfoWgtsLinkTxt')}
            icon="about-marketplace-icon"
          />
          <WgtInfo
            headline={this.props.getTranslate('aboutPanel-InfoWgtsFiveHeadline')}
            colorLine="#afe5cf"
            description={this.props.getTranslate('aboutPanel-InfoWgtsFiveDescription')}
            link="#"
            linkTxt={this.props.getTranslate('aboutPanel-InfoWgtsLinkTxt')}
            icon="about-events-icon"
          />
          <WgtInfo
            headline={this.props.getTranslate('aboutPanel-InfoWgtsSixHeadline')}
            colorLine="#efe1d2"
            description={this.props.getTranslate('aboutPanel-InfoWgtsSixDescription')}
            link="#"
            linkTxt={this.props.getTranslate('aboutPanel-InfoWgtsLinkTxt')}
            icon="about-polls-icon"
          />
          <WgtInfo
            headline={this.props.getTranslate('aboutPanel-InfoWgtsSevenHeadline')}
            colorLine="#ebd2ef"
            description={this.props.getTranslate('aboutPanel-InfoWgtsSevenDescription')}
            link="#"
            linkTxt={this.props.getTranslate('aboutPanel-InfoWgtsLinkTxt')}
            icon="about-library-icon"
          />
          <WgtInfo
            headline={this.props.getTranslate('aboutPanel-InfoWgtsEightHeadline')}
            colorLine="#d2e4ef"
            description={this.props.getTranslate('aboutPanel-InfoWgtsEightDescription')}
            link="#"
            linkTxt={this.props.getTranslate('aboutPanel-InfoWgtsLinkTxt')}
            icon="about-db-icon"
          />
          <WgtInfo
            headline={this.props.getTranslate('aboutPanel-InfoWgtsNineHeadline')}
            colorLine="#d7efd2"
            description={this.props.getTranslate('aboutPanel-InfoWgtsNineDescription')}
            link="#"
            linkTxt={this.props.getTranslate('aboutPanel-InfoWgtsLinkTxt')}
            icon="about-dashboard-icon"
          />
        </div>
        <div className="about-info-wgts__footer">
          <Button className="btn_size_large" type="link" text={this.props.getTranslate('about-RegCompanyButton')} handler="#" btnSize="large" />
        </div>
      </div>
    </section>
  }
}
export default AboutInfoWgts;
