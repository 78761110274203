import React from "react";
import ServiceInfoWgt from './ServiceInfoWgt';
import manage_img_1 from '../../media/manage-img3.png';
class ServiceInfo extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <section className="service-info">
      <div className="wrapper">
        <ServiceInfoWgt
          headline="Внутрішній магазин та маркетплейс"
          description="Теперь у вашей компании может появиться внутренний магазин, где за накопленные бонусы сотрудники будут покупать представленные на витрине товары. Для наполнения магазина нужно просто добавить товар и указать его цену в бонусах. После поступления заказа на определенную позицию менеджеру придет уведомление. Он сможет оформить покупку и подготовить ее к отправке заказчику или к выдаче в офисе."
          img={manage_img_1}
        />
        <ServiceInfoWgt
          headline="Внутрішній магазин та маркетплейс"
          description="Теперь у вашей компании может появиться внутренний магазин, где за накопленные бонусы сотрудники будут покупать представленные на витрине товары. Для наполнения магазина нужно просто добавить товар и указать его цену в бонусах. После поступления заказа на определенную позицию менеджеру придет уведомление. Он сможет оформить покупку и подготовить ее к отправке заказчику или к выдаче в офисе."
          img={manage_img_1}
          reverse={true}
        />
        <ServiceInfoWgt
          headline="Внутрішній магазин та маркетплейс"
          description="Теперь у вашей компании может появиться внутренний магазин, где за накопленные бонусы сотрудники будут покупать представленные на витрине товары. Для наполнения магазина нужно просто добавить товар и указать его цену в бонусах. После поступления заказа на определенную позицию менеджеру придет уведомление. Он сможет оформить покупку и подготовить ее к отправке заказчику или к выдаче в офисе."
          img={manage_img_1}
        />
      </div>
    </section>
  }
}
export default ServiceInfo;
