import React from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import sprite from '../media/icons.svg';
let times = [];
for (let hour = 0; hour < 24; hour++) {
  for (let minute = 0; minute <= 45; minute += 15) {
    let time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    times.push(time);
  }
}
let startDate = null;
let endDate = null;
class CalendarDropdownInterval extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusDropdownTime: false,
      typeDropdownTime: null,
      statusDropdownCalendar: false,
      typeDropdownCalendar: null,
      chooseDateFull: null,
      startDate: null,
      endDate:null,
      finishStartDate: null,
      finishEndDate: null,
      startTime: '00:00',
      hoverDate: null,
      dateModal: false,
      date: new Date(),
      fullYear: null,
      month: null,
      currentDate: new Date(),
      dates: [],
      countFirstElems: null,
      countEndedElems: null,
      iterStart: null,
      iterStartSave: null,
      iterHover: null,
      countMonth: 1,
      countMonthSave: null,
      iterFinish: null,
      countMonthFinish: null,
      clickedInside: false
    }
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    const currentDate = this.state.currentDate;
    const date = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const fullDate = (date < 10 ? ('0' + date) : date) + '.' + (month < 10 ? ('0' + month) : month) + '.' + currentDate.getFullYear();

    let chooseDateFull = null;
    if(this.props.startTime && this.props.endTime){
      let dateStringStart = this.props.startTime;
      let dateObjStart = new Date(dateStringStart);

      let dayStartSelect = dateObjStart.getDate().toString().padStart(2, '0');
      let monthStartSelect = (dateObjStart.getMonth() + 1).toString().padStart(2, '0');
      let yearStartSelect = dateObjStart.getFullYear();

      let formattedDateStart = `${dayStartSelect}.${monthStartSelect}.${yearStartSelect}`;

      let dateStringEnd = this.props.endTime;
      let dateObjEnd = new Date(dateStringEnd);

      let dayEndSelect = dateObjEnd.getDate().toString().padStart(2, '0');
      let monthEndSelect = (dateObjEnd.getMonth() + 1).toString().padStart(2, '0');
      let yearEndSelect = dateObjEnd.getFullYear();

      let formattedDateEnd = `${dayEndSelect}.${monthEndSelect}.${yearEndSelect}`;

      chooseDateFull = `${formattedDateStart} - ${formattedDateEnd}`;
    }

    this.setState({
      chooseDateFull: chooseDateFull ? chooseDateFull : fullDate,
      startDate: currentDate.getFullYear() + '-' + month + '-' + date,
      startTime: this.props.calendarTime ? this.props.calendarTime : '00:00',
    });
  }


  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handlerClickInside(event){
    if(this.props.reminderType || this.state.chooseDateType === 'finish'){
      this.setState({
        statusDropdownTime: false,
        typeDropdownTime: null,
        statusDropdownCalendar: false,
        typeDropdownCalendar: null
      });
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        statusDropdownTime: false,
        typeDropdownTime: null,
        statusDropdownCalendar: false,
        typeDropdownCalendar: null
      });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setWrapperRefButton(node){
    this.wrapperRefButton = node;
  }
  getLastDayOfMonth (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDate();
  }
  getUnshiftElemsNum (year, month) {
    let jsDayNum = this.getFirstWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return realDayNum - 1;
  }
  getPushElemsNum (year, month) {
    let jsDayNum = this.getLastWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return 7 - realDayNum;
  }
  createArr (from, to) {
    const arr = [];
    for(let i = from; i <= to; i++) {
      arr.push(i);
    }
    return arr;
  }
  unshiftElems (num, lastDayPrevMonth, arr) {
    this.setState(prevState => ({
      countFirstElems: num
    }));
    let count =  lastDayPrevMonth - num;
    for(let i = lastDayPrevMonth; i > count; i--) {
      arr.unshift(i);
    }
    return arr;
  }
  pushElems (num, arr) {
    this.setState(prevState => ({
      countEndedElems: num
    }));
    for(let i = 1; i <= num; i++) {
      arr.push(i);
    }
    return arr;
  }
  chunkArr (num, arr) {
    let result = [];
    let chunk = [];
    let iterCount = arr.length / num;
    for(let i = 0; i <iterCount; i++) {
      chunk = arr.splice(0, num);
      result.push(chunk);
    }
    return result;
  }
  getRealDayOfWeekNum (jsNumOfDay) {
    if(jsNumOfDay === 0){
      return 7;
    } else {
      return jsNumOfDay;
    }
  }
  getFirstWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month, 1);
    return date.getDay();
  }
  getLastWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDay();
  }
  getMonthName (num) {
    const monthes = [
      'Січень',
      'Лютий',
      'Березень',
      'Квітень',
      'Травень',
      'Червень',
      'Липень',
      'Серпень',
      'Вересень',
      'Жовтень',
      'Листопад',
      'Грудень'
    ];
    return monthes[num];
  }
  handlerCloseGetDate (event) {
    event.preventDefault();
    this.setState(prevState => ({
      dateModal: false
    }));
  }
  handlerPrevMonth (event) {
    event.preventDefault();
    let date = new Date(this.state.date.getFullYear(), this.state.date.getMonth() - 1, this.state.date.getDate());
    let fullYear = date.getFullYear();
    let month = date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      date: date,
      dates: arr,
      fullYear: fullYear,
      month: month,
      countMonth: Number(prevState.countMonth) - 1,
      iterStart: Number(prevState.countMonth) === (Number(prevState.countMonthSave) + 1) ? Number(prevState.iterStartSave) : '0'
    }));
  }
  handlerNextMonth (event) {
    event.preventDefault();
    let date = new Date(this.state.date.getFullYear(), this.state.date.getMonth() + 1, this.state.date.getDate());
    let fullYear = date.getFullYear();
    let month = date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      date: date,
      dates: arr,
      fullYear: fullYear,
      month: month,
      countMonth: Number(prevState.countMonth) + 1,
      iterStart: Number(prevState.countMonth) >= Number(prevState.countMonthSave) ? '0' : Number(prevState.iterStartSave)
    }));
  }
  chooseDate (iterStart, nextMonth, prevMonth, event) {
    event.preventDefault();
    let month = Number(this.state.month) + 1;
    let year = this.state.fullYear;
    if(nextMonth){
      month = Number(month) + 1;
      if(month === 13){
        month = 1;
        year = year + 1;
      }
    }
    if(prevMonth){
      month = Number(month) - 1;
      if(month === 0){
        month = 12;
        year = year - 1;
      }
    }
    if(!this.state.finishStartDate){
      this.setState(prevState => ({
        chooseDateType: !this.props.reminderType ? 'finish' : 'start',
        startDate: year + '-' + month + '-' + event.target.innerHTML,
        finishStartDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '-' + (month < 10 ? '0' + month : month) + '-' + year,
        iterStart: iterStart,
        iterStartSave: iterStart,
        countMonthSave: prevState.countMonth,
        dateStartValue: ''
      }));
      startDate = year + '-' + month + '-' + event.target.innerHTML;
    } else if(this.state.chooseDateType === 'start') {
      this.setState(prevState => ({
        chooseDateType: !this.props.reminderType ? 'finish' : 'start',
        startDate: year + '-' + month + '-' + event.target.innerHTML,
        finishStartDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '-' + (month < 10 ? '0' + month : month) + '-' + year,
        iterStart: iterStart,
        iterStartSave: iterStart,
        countMonthSave: prevState.countMonth,
        dateStartValue: ''
      }));
      startDate = year + '-' + month + '-' + event.target.innerHTML;
    } else if(this.state.chooseDateType === 'finish'){
      this.setState(prevState => ({
        endDate: new Date(prevState.startDate) > new Date(year + '-' + month + '-' + event.target.innerHTML) ? null : year + '-' + month + '-' + event.target.innerHTML,
        finishEndDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '-' + (month < 10 ? '0' + month : month) + '-' + year,
        iterFinish: iterStart,
        countMonthFinish: prevState.countMonth,
        dateEndValue: ''
      }));
      endDate = year + '-' + month + '-' + event.target.innerHTML;
    } else {
      this.setState(prevState => ({
        endDate: new Date(prevState.startDate) > new Date(year + '-' + month + '-' + event.target.innerHTML) ? null : year + '-' + month + '-' + event.target.innerHTML,
        finishEndDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '-' + (month < 10 ? '0' + month : month) + '-' + year,
        iterFinish: iterStart,
        countMonthFinish: prevState.countMonth,
        dateEndValue: ''
      }));
      endDate = year + '-' + month + '-' + event.target.innerHTML;
    }
    if(this.props.reminderType){
      this.setState(prevState => ({
        chooseDateFull: prevState.finishStartDate.split("-").join(".")
      }))
    } else {
      this.setState(prevState => ({
        chooseDateFull: prevState.finishStartDate.split("-").join(".") + ' - ' + (prevState.finishEndDate ? prevState.finishEndDate.split("-").join(".") : prevState.finishStartDate.split("-").join("."))
      }))
    }
    this.props.onChange(startDate, endDate);
  }
  chooseDateHover (iterHover, event) {
    event.preventDefault();
    if(this.state.startDate){
      this.setState(prevState => ({
        iterHover: iterHover
      }));
    }
  }
  chooseEndHover (event) {
    event.preventDefault();
    this.setState(prevState => ({
      iterHover: null
    }));
  }
  onKeyPress (event) {
    let input = event.target;
    if(event.charCode < 47 || event.charCode > 57) {
      event.preventDefault();
    }
    var len = input.value.length;
    if(len !== 1 || len !== 3) {
      if(event.charCode == 47) {
        event.preventDefault();
      }
    }
    if(len === 2) {
      input.value += '.';
    }
    if(len === 5) {
      input.value += '.';
    }
  }
  onKeyPress2 (event) {
    let input = event.target;
    if(event.charCode < 47 || event.charCode > 57) {
      event.preventDefault();
    }
    var len = input.value.length;
    if(len !== 1 || len !== 3) {
      if(event.charCode == 47) {
        event.preventDefault();
      }
    }
    if(len === 2) {
      input.value += ':';
    }
  }
  handlerGetCalendar(event) {
    event.preventDefault();
    let fullYear = this.state.date.getFullYear();
    let month = this.state.date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      dates: arr,
      fullYear: fullYear,
      pushElemsTotal: pushElemsNum,
      month: month,
      statusDropdownCalendar: !prevState.statusDropdownCalendar,
      typeDropdownCalendar: 'start'
    }));
  }
  handlerGetTime(event) {
    event.preventDefault();
    this.setState(prevState => ({
      statusDropdownTime: !prevState.statusDropdownTime
    }));
  }
  handlerGetStartTime(time, event){
    this.props.onChangeTime(time);
    this.setState(prevState => ({
      startTime: time,
      statusDropdownTime: false
    }));
  }
  isDropdownAtBottom() {
  if (this.wrapperRef) {
    const rect = this.wrapperRef.getBoundingClientRect();
    return rect.bottom > window.innerHeight;
  }
  return false;
}
  render() {
    let totalItem = this.state.dates.length * 7;
    let totalItemNotPush = totalItem - this.state.pushElemsTotal;
    let firstItems = this.state.countFirstElems;
    let endedItems = totalItem - this.state.countEndedElems;
    let iter = 0;
    let tableDate = this.state.dates.map((item, index) => {
      return <tr key={index}>
        {item.map((elem, i) => {
          iter++;
          if((this.state.date < this.state.currentDate && this.state.date.getMonth() !== this.state.currentDate.getMonth()) || (index === 0 && this.state.countFirstElems > i && this.state.month === this.state.currentDate.getMonth())){
            return <td className="ended" key={i}>{elem}</td>
          } else if(this.state.currentDate >= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + (elem + 1)) && iter < endedItems && (this.state.date.getMonth()) <= (this.state.currentDate.getMonth()) && this.state.date.getFullYear() <= this.state.currentDate.getFullYear()){
            return <td className="ended" key={i}>{elem}</td>
          } else if(!this.props.reminderType && new Date(this.state.startDate) <= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + elem) && new Date(this.state.hoverDate) >= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + elem)) {
            return <td className="checked" key={i} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)} onMouseLeave={this.chooseEndHover.bind(this)}>{elem}</td>
          } else if(iter < endedItems && this.state.startDate && (new Date(this.state.startDate).getDate() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getDate()) && (new Date(this.state.startDate).getMonth() - 1 === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getMonth()) && (new Date(this.state.startDate).getFullYear() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getFullYear())) {
            return <td className="checked" key={i} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)} onMouseLeave={this.chooseEndHover.bind(this)}>{elem}</td>
          } else if(!this.props.reminderType && this.state.iterStart && (this.state.countMonth >= this.state.countMonthSave && this.state.iterStart <= iter && this.state.iterHover >= iter)) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else if(!this.props.reminderType && this.state.iterStart && this.state.iterFinish && (this.state.countMonth <= this.state.countMonthFinish && this.state.countMonth >= this.state.countMonthSave) && (this.state.iterStart <= iter && this.state.iterFinish >= iter)) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else if(!this.props.reminderType && this.state.iterStart && this.state.iterFinish && this.state.countMonth < this.state.countMonthFinish && this.state.countMonth > this.state.countMonthSave){
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else if(!this.props.reminderType && (this.state.iterStart && this.state.iterFinish) && (this.state.countMonthSave !== this.state.countMonthFinish) && (this.state.countMonth === this.state.countMonthSave) && iter > this.state.iterStart) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else {
            return <td key={i} onMouseEnter={this.chooseDateHover.bind(this, iter)} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          }
        })}
      </tr>
    });
    return <div className="field-wrap">
      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="date">{this.props.label}</label>
      <div className="calendar-field-row">
        <div className="calendar-field__col calendar-field__col-endoff">
          <div className="calendar-field__input-calendar">
            <div className="calendar-field__input-calendar-inner">
              <div className={`calendar-field__value ${this.state.typeDropdownCalendar === 'start' ? 'active' : ''}`} onClick={this.handlerGetCalendar.bind(this)}>{this.state.chooseDateFull}</div>
              {this.state.statusDropdownCalendar && this.state.typeDropdownCalendar === 'start' ? <div className="calendar calendar__dropdown" ref={this.setWrapperRef}>
                <div className="calendar__info">
                  <button className="calendar__month-nav" type="button" onClick={this.handlerPrevMonth.bind(this)}>
                    <svg className="calendar__month-nav-icon">
                      <use href={`${sprite}#chevron-left`}></use>
                    </svg>
                  </button>
                  <div className="calendar__month-year">{this.getMonthName(this.state.date.getMonth())}, {this.state.date.getFullYear()}</div>
                  <button className="calendar__month-nav" type="button" onClick={this.handlerNextMonth.bind(this)}>
                    <svg className="calendar__month-nav-icon">
                      <use href={`${sprite}#chevron-right`}></use>
                    </svg>
                  </button>
                </div>
                <table className="calendar-table" onClick={this.handlerClickInside.bind(this)}>
                  <thead className="calendar-table__head">
                    <tr>
                      <th>ПН</th>
                      <th>ВТ</th>
                      <th>СР</th>
                      <th>ЧТ</th>
                      <th>ПТ</th>
                      <th>СБ</th>
                      <th>НД</th>
                    </tr>
                  </thead>
                  <tbody className="calendar-table__dates">{tableDate}</tbody>
                </table>
              </div> : ''}
            </div>
          </div>
          <div className="calendar-field__input-time">
            <div className="calendar-field__input-time-inner">
              <div className={`calendar-field__value ${this.props.errorTxt && !this.props.calendarTime  ? 'calendar-field__value_error' : ''} ${this.props.calendarTime ? '' : 'disabled'} ${this.state.statusDropdownTime ? 'active' : ''}`} onClick={this.handlerGetTime.bind(this)}>{this.state.startTime}</div>
              {this.state.statusDropdownTime ? <div className={"calendar-field__time-content" + (this.isDropdownAtBottom() ? " dropdown-top" : "")} ref={this.setWrapperRef}>
              <Scrollbars
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                    renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                    renderView={props => <div {...props} className="view"/>}
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={270}
                      >
                <ul className="calendar-field__list-time" onClick={this.handlerClickInside.bind(this)}>
                  {times.map((item, index) => {
                    return <li key={index} onClick={this.handlerGetStartTime.bind(this, item)}>{item}</li>
                  })}
                </ul>
                </Scrollbars>
              </div> : ''}
            </div>
          </div>
        </div>
      </div>
      {this.props.errorTxt && !this.props.calendarTime ? this.props.errorTxt : ''}
    </div>
  }
}
export default CalendarDropdownInterval;
