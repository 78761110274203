import React from "react";
import { Link } from "react-router-dom";
import sprite from '../../media/icons.svg';
import about_service_shop from '../../media/about-service-shop.png';
import about_bonuses from '../../media/img-about-bonuses.png';
import about_events from '../../media/img-about-events.png';
import about_marketplace from '../../media/img-about-marketplace.png';
import about_chalanges from '../../media/img-about-chalanges.png';
import app_index_slider_1 from '../../media/app-index-slider-1.png';
import app_index_slider_2 from '../../media/app-index-slider-2.png';
import app_index_slider_3 from '../../media/app-index-slider-3.png';
import app_index_slider_4 from '../../media/app-index-slider-4.png';
import app_index_slider_5 from '../../media/app-index-slider-5.png';
import app_index_slider_6 from '../../media/app-index-slider-6.png';
import app_index_slider_7 from '../../media/app-index-slider-7.png';
import AboutInfoService from './AboutInfoService';
class AboutMobileAppOpportunities extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <>
      <section className="about-opportunities">
        <div className="wrapper">
          <div className="about-opportunities__header">
            <h2 className="about-page__headline">{this.props.headline}</h2>
            <p className="about-page__description" dangerouslySetInnerHTML={{__html: this.props.description}}></p>
          </div>
          <AboutInfoService
            headline={this.props.getTranslate('about-AppOpportunitiesFirstHeadline')}
            description={<div dangerouslySetInnerHTML={{__html: this.props.getTranslate('about-AppOpportunitiesFirstDescription')}}></div>}
            link="/service"
            linkTxt={this.props.getTranslate('about-AppOpportunitieslinkTxt')}
            thumbnail={app_index_slider_2}
          />
          <AboutInfoService
            headline={this.props.getTranslate('about-AppOpportunitiesSecondHeadline')}
            description={<div dangerouslySetInnerHTML={{__html: this.props.getTranslate('about-AppOpportunitiesSecondDescription')}}></div>}
            link="/service"
            linkTxt={this.props.getTranslate('about-AppOpportunitieslinkTxt')}
            thumbnail={app_index_slider_6}
            reverse={true}
          />
          <AboutInfoService
            headline={this.props.getTranslate('about-AppOpportunitiesThirdHeadline')}
            description={<div dangerouslySetInnerHTML={{__html: this.props.getTranslate('about-AppOpportunitiesThirdDescription')}}></div>}
            link="/service"
            linkTxt={this.props.getTranslate('about-AppOpportunitieslinkTxt')}
            thumbnail={app_index_slider_4}
          />
          <AboutInfoService
          headline={this.props.getTranslate('about-AppOpportunitiesFourHeadline')}
            description={<div dangerouslySetInnerHTML={{__html: this.props.getTranslate('about-AppOpportunitiesFourDescription')}}></div>}
            link="/service"
            linkTxt={this.props.getTranslate('about-AppOpportunitieslinkTxt')}
            thumbnail={app_index_slider_3}
            reverse={true}
          />
          <AboutInfoService
            headline={this.props.getTranslate('about-AppOpportunitiesFiveHeadline')}
            description={<div dangerouslySetInnerHTML={{__html: this.props.getTranslate('about-AppOpportunitiesFiveDescription')}}></div>}
            link="/service"
            linkTxt={this.props.getTranslate('about-AppOpportunitieslinkTxt')}
            thumbnail={app_index_slider_5}
          />
          <AboutInfoService
            headline={this.props.getTranslate('about-AppOpportunitiesSixHeadline')}
            description={<ul>
                <li>{this.props.getTranslate('about-AppOpportunitiesSixDescriptionPointOneFirst')} <Link to="/service">{this.props.getTranslate('about-AppOpportunitiesSixDescriptionPointOneSecond')}</Link> {this.props.getTranslate('about-AppOpportunitiesSixDescriptionPointOneThird')}</li>
                <li>{this.props.getTranslate('about-AppOpportunitiesSixDescriptionPointTwoFirst')} <Link to="/service">{this.props.getTranslate('about-AppOpportunitiesSixDescriptionPointTwoSecond')}</Link></li>
                <li><Link to="/service">{this.props.getTranslate('about-AppOpportunitiesSixDescriptionPointThree')}</Link></li>
                <li>{this.props.getTranslate('about-AppOpportunitiesSixDescriptionPointFourFirst')} <Link to="/service">{this.props.getTranslate('about-AppOpportunitiesSixDescriptionPointFourSecond')}</Link></li>
                <li><Link to="/service">{this.props.getTranslate('about-AppOpportunitiesSixDescriptionPointFiveFirst')}</Link> {this.props.getTranslate('about-AppOpportunitiesSixDescriptionPointFiveSecond')}</li>
                <li>{this.props.getTranslate('about-AppOpportunitiesSixDescriptionPointSix')}</li>
              </ul>}
            link={false}
            thumbnail={app_index_slider_1}
            reverse={true}
          />
        </div>
      </section>
    </>;
  }
}
export default AboutMobileAppOpportunities;
