import React from "react";
import { Link, useParams } from "react-router-dom";
import AccountHeader from './components/AccountHeader';
import Navigation from './components/Navigation';
import Loading from '../components/Loading';
import sprite from './media/icons.svg';
import contactPhone from '../media/contacts-phone.png';
import contactEmail from '../media/contacts-email.png';
import contactTelegram from '../media/contacts-telegram.png';
import contactFacebook from '../media/contacts-facebook.png';
import contactInstagram from '../media/contacts-instagram.png';
import contactLinkedin from '../media/contacts-linkedin.png';
import contactTwitter from '../media/contacts-twitter.png';
import product_img_default from '../media/no-product-image.jpg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString.split('.').reverse().join('.'));
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
function getInfoDate(num){
  let now = new Date();
  if(num){
    let date = new Date(num.split('.').reverse().join('.'));
    const diffMs = now - date;
    const msPerYear = 1000 * 60 * 60 * 24 * 365.25;
    const msPerMonth = msPerYear / 12;
    const msPerDay = 1000 * 60 * 60 * 24;
    const years = Math.floor(diffMs / msPerYear);
    const months = Math.floor((diffMs % msPerYear) / msPerMonth);
    const days = Math.floor((diffMs % msPerYear % msPerMonth) / msPerDay);
    return [years, months, days];
  }
}
function getNumberOfDays(start, end) {
  const date1 = new Date(start);
  const date2 = new Date(end);
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  const diffInDays = Math.round(diffInTime / oneDay);
  return diffInDays;
}
function areAllValuesEmpty(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]) {
      return false;
    }
  }
  return true;
}
class ControlPanelCompanyUsersView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      modalDisabled: false,
      modal: false,
      isLoaded: false,
      errors: null,
      balance: null
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modal: false,
      }));
    }, 500);
  }
  componentDidMount() {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/bo/v1/users/${that.props.id}`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              error: true,
              isLoaded: true
            });
          })
        } else {
          res.json().then(function(data) {
            that.setState({
              user: data,
              isLoaded: true
            });
          });

          fetch(`${that.props.queryLink}/bo/v1/accounting/users/${that.props.id}/balance`, {
            method: 'GET',
            headers: myHeaders
          }).then(res => {
            if (res.status !== 200) {
              res.json().then(function(data) {

              })
            } else {
              res.json().then(function(data) {
                that.setState({
                  balance: data
                });
              });
            }
          });

        }
      });
    });
  }
  render() {
    let emptyContacts = null;
    let emptySocial = null;
    if(this.state.user && this.state.user.contacts){
      emptyContacts = areAllValuesEmpty(this.state.user.contacts);
    }
    if(this.state.user && this.state.user.socialNetworks){
      emptySocial = areAllValuesEmpty(this.state.user.socialNetworks);
    }
    let maritalStatus;
    if(this.state.user){
      if(this.state.user.gender === 'MALE'){
        if(this.state.user.maritalStatus === 'SINGLE'){
          maritalStatus = this.props.getTranslate('employees-UserMaritalStatusManNo');
        } else {
          maritalStatus = this.props.getTranslate('employees-UserMaritalStatusManYes');
        }
      } else {
        if(this.state.user.maritalStatus === 'SINGLE'){
          maritalStatus = this.props.getTranslate('employees-UserMaritalStatusWomanNo');
        } else {
          maritalStatus = this.props.getTranslate('employees-UserMaritalStatusWomanYes');
        }
      }
    }
    let resultContent = '';
    if(this.state.error) {
      resultContent = <div className="errors-content">Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultContent = <Loading />;
    } else {
      resultContent = <div className="account-content__content account-content__content-user">
        <div className="account-wgt account-content__wgt user-statistic">
        <div className="user-statistic__info">
          <div className="user-statistic__info-left">
            <img className="user-statistic__info-avatar" src={this.state.user.photo} alt="" />
            <div>
              <h2 className="user-statistic__info-name">{this.state.user.name} {this.state.user.middleName} {this.state.user.lastName}</h2>
              <div className="user-statistic__info-wrap">
                <ul className="user-statistic__info-position">
                  <li>{this.state.user.position}</li>
                  {this.state.user.team && <li>{this.state.user.team.name}</li>}
                </ul>
              </div>
            </div>
          </div>
          <div className="user-statistic__info-right user-statistic__info-right-desktop">
            <div className="user-statistic__info-nav">
              <Link className="user-statistic__info-nav-item btn-edit" to={this.props.getPermalink(`/account/users/edit/${this.props.id}`)} state={{from: this.props.getPermalink(`/account/users/view/${this.props.id}`)}}>
                <svg className="btn-edit__icon">
                  <use href={`${sprite}#edit-icon`}></use>
                </svg>
              </Link>
              <button className="user-statistic__info-nav-item btn-edit btn-edit_theme_remove" type="button">
                <svg className="btn-edit__icon">
                  <use href={`${sprite}#delete-red`}></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="user-statistic__info-wgt">
          <h3 className="user-statistic__info-wgt-headline">{this.props.getTranslate('employees-UserInformationTitle')}</h3>
          <div className="user-statistic-table">
            <div className="user-statistic-table__left">
              <table>
                <tbody>
                  {this.state.user.email && <tr>
                    <td>E-mail (аккаунт):</td>
                    <td>{this.state.user.email}</td>
                  </tr>}
                  {this.state.user.dateOfBirth && <tr>
                    <td>{this.props.getTranslate('employees-UserDate')}:</td>
                    <td>{this.state.user.dateOfBirth} ({getAge(this.state.user.dateOfBirth)})</td>
                  </tr>}
                  {this.state.user.maritalStatus && <tr>
                    <td>{this.props.getTranslate('employees-UserMaritalStatus')}:</td>
                    <td>{maritalStatus}</td>
                  </tr>}
                  {this.state.user.workStartDate && <tr>
                    <td>{this.props.getTranslate('employees-UserWorking')}:</td>
                    <td>{this.state.user.workStartDate} ({getInfoDate(this.state.user.workStartDate)[0] + ' роки, ' + getInfoDate(this.state.user.workStartDate)[1] + ' місяців, ' + getInfoDate(this.state.user.workStartDate)[2] + ' днів'})</td>
                  </tr>}
                  {this.state.user.lastLoginDate && <tr>
                    <td>{this.props.getTranslate('employees-UserLastVisit')}:</td>
                    <td>{this.state.user.lastLoginDate}</td>
                  </tr>}
                </tbody>
              </table>
            </div>
            <div className="user-statistic-table__right">
                {this.state.user.hobbies && this.state.user.hobbies.length > 0 ? <div>
                  <div className="user-info__label">{this.props.getTranslate('employees-UserHobbie')}:</div>
                  <div className="user-info__tags">
                    {this.state.user.hobbies.map((item, index) => {
                      return <span key={index}>{item}</span>})}
                  </div></div> : ''}
                  {!emptyContacts || !emptySocial ? <div className="user-statistic-table__contacts-wrap">
                    {!emptyContacts && this.state.user.contacts ? <div className="user-statistic-table__contacts">
                      <div className="user-info__label">{this.props.getTranslate('employees-UserContacts')}:</div>
                      {this.state.user.contacts.phone ? <div className="user-info-contact">
                        <img className="user-info-contact__icon" src={contactPhone} alt="" />
                        <a className="user-info-contact__val" href={`tel:${this.state.user.contacts.phone}`}>{this.state.user.contacts.phone}</a>
                      </div> : ''}
                      {this.state.user.contacts.secondaryPhone ? <div className="user-info-contact">
                        <img className="user-info-contact__icon" src={contactPhone} alt="" />
                        <a className="user-info-contact__val" href={`tel:${this.state.user.contacts.secondaryPhone}`}>{this.state.user.contacts.secondaryPhone}</a>
                      </div> : ''}
                      {this.state.user.contacts.telegram ? <div className="user-info-contact">
                        <img className="user-info-contact__icon" src={contactTelegram} alt="" />
                        <a className="user-info-contact__val" href={this.state.user.contacts.telegram} target="_blank">{this.state.user.contacts.telegram}</a>
                      </div> : ''}
                      {this.state.user.contacts.email ? <div className="user-info-contact">
                        <img className="user-info-contact__icon" src={contactEmail} alt="" />
                        <a className="user-info-contact__val" href={`mailto:${this.state.user.contacts.email}`}>{this.state.user.contacts.email}</a>
                      </div> : ''}
                    </div> : ''}
                    {!emptySocial ? <div className="user-statistic-table__networks">
                      <div className="user-info__label">Соціальні мережі:</div>
                      {this.state.user.socialNetworks.faceBook ? <div className="user-info-contact">
                        <img className="user-info-contact__icon" src={contactFacebook} alt="" />
                        <a className="user-info-contact__val" href={this.state.user.socialNetworks.faceBook} target="_blank">{this.state.user.socialNetworks.faceBook}</a>
                      </div> : ''}
                      {this.state.user.socialNetworks.instagram ? <div className="user-info-contact">
                        <img className="user-info-contact__icon" src={contactInstagram} alt="" />
                        <a className="user-info-contact__val" href={this.state.user.socialNetworks.instagram} target="_blank">{this.state.user.socialNetworks.instagram}</a>
                      </div> : ''}
                      {this.state.user.socialNetworks.linkedin ? <div className="user-info-contact">
                        <img className="user-info-contact__icon" src={contactLinkedin} alt="" />
                        <a className="user-info-contact__val" href={this.state.user.socialNetworks.linkedin} target="_blank">{this.state.user.socialNetworks.linkedin}</a>
                      </div> : ''}
                      {this.state.user.socialNetworks.twitter ? <div className="user-info-contact">
                        <img className="user-info-contact__icon" src={contactTwitter} alt="" />
                        <a className="user-info-contact__val" href={this.state.user.socialNetworks.twitter} target="_blank">{this.state.user.socialNetworks.twitter}</a>
                      </div> : ''}
                    </div> : ''}
                  </div> : ''}
              </div>
          </div>
        </div>
        <div className="user-statistic__info-wgt">
          <h3 className="user-statistic__info-wgt-headline">Баланс</h3>
          <div className="user-statistic__info-wgt-headline-balance">Поточний баланс</div>
          <div className="user-statistic__info-wgt-balance-wrap">
            <div className="user-statistic__info-wgt-balance-value">{this.state.balance && this.state.balance.balance ? this.state.balance.balance : '0'} {this.props.currency}</div>
            <div className="user-statistic__info-wgt-balance-value">{this.state.balance && this.state.balance.superBalance ? this.state.balance.superBalance : '0'} Super {this.props.currency}</div>
          </div>
        </div>
        </div>
      </div>;
    }
    return <>
    <AccountHeader status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
    <div className="account-wrap account-wrap__admin">
      <Navigation status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} replace={true} />
      <div className="account-content">
        <div className="account-content__inner">
          <div className="account-content__header">
            <div className="account-content__header-inner">
              {this.state.isLoaded ? <h1 className="account-content__headline"></h1> : ''}
            </div>
            <a className="header__language header__language-content" href="#">RU</a>
          </div>
          <div className="account-content__content">

                        {/* Query result */}
                        {resultContent}

                      </div>

                    </div>
                  </div>
                </div>
                </>
  }
}
export default (props) => <ControlPanelCompanyUsersView {...useParams()} {...props} />
