import React from "react";
import Header from './components/Header';
import HomeBanner from './components/HomePage/HomeBanner';
import HomeWgtsInfo from './components/HomePage/HomeWgtsInfo';
import HomeBonuses from './components/HomePage/HomeBonuses';
import HomePossibilities from './components/HomePage/HomePossibilities';
import HomeAddCompany from './components/HomePage/HomeAddCompany';
import HomeManager from './components/HomePage/HomeManager';
import HomeTry from './components/HomePage/HomeTry';
import Footer from './components/Footer';
class Home extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      const organizationData = {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "MateSpace",
        "url": "https://matespace.io",
        "logo": "https://matespace.io/static/media/mate_logo.383d62af798ded14cef5252cad69851d.svg"
      };
      let title = this.props.getTranslate('mainPage-Title');
      document.title = title ? title + ' - MateSpace.io' : 'MateSpace.io';
      const metaDescription = document.querySelector('meta[name="description"]');
      let description = this.props.getTranslate('mainPage-Description');
      if (metaDescription) {
        metaDescription.setAttribute('content', description ? description : 'MateSpace.io');
      }
      <script type="application/ld+json">
        {JSON.stringify(organizationData)}
      </script>
    }
    return <>
    {this.props.PageWithCanonical}
    <Header getPermalink={this.props.getPermalink} logged={this.props.logged} userPhoto={this.props.userPhoto} userName={this.props.userName} handlerChangeLanguage={this.props.handlerChangeLanguage} chooseLanguage={this.props.chooseLanguage} getTranslate={this.props.getTranslate} />
    <main>
      <HomeBanner getTranslate={this.props.getTranslate} queryLink={this.props.queryLink} />
      <HomeWgtsInfo getTranslate={this.props.getTranslate} />
      <HomeBonuses getTranslate={this.props.getTranslate} />
      <HomePossibilities getTranslate={this.props.getTranslate} />
      <HomeAddCompany getTranslate={this.props.getTranslate} queryLink={this.props.queryLink} />
      <HomeManager getTranslate={this.props.getTranslate} />
      <HomeTry getTranslate={this.props.getTranslate} queryLink={this.props.queryLink} />
    </main>
    <Footer getPermalink={this.props.getPermalink} getTranslate={this.props.getTranslate} />
    </>
  }
}
export default Home;
