import React from "react";
import { Link } from "react-router-dom";
import sprite from '../../media/icons.svg';
class AboutInfoServiceWgt extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <>
      <div className="about-info-service-wgt">
        <div className="about-info-service-wgt__inner">
          <div className={`about-info-service-wgt__content ${this.props.reverse ? 'reverse' : ''}`}>
            <h2 className="about-info-service-wgt__headline">{this.props.headline}</h2>
            <div className="about-info-service-wgt__thumbnail-mob">
              <img src={this.props.thumbnail} alt={this.props.headline} />
            </div>
            <div className="about-info-service-wgt__description">{this.props.description}</div>
            {this.props.link ? <Link style={{display: 'none'}} className="main-btn-v2" to={this.props.link}>
              <span className="main-btn-v2__content">{this.props.linkTxt}</span>
              <span className="main-btn-v2__icon">
                <svg>
                  <use href={`${sprite}#link-chevron`}></use>
                </svg>
              </span>
            </Link> : ''}
          </div>
          <div className={`about-info-service-wgt__thumbnail ${this.props.reverse ? 'reverse' : ''}`}>
            <img src={this.props.thumbnail} alt={this.props.headline} />
          </div>
        </div>
      </div>
    </>;
  }
}
export default AboutInfoServiceWgt;
