import React from "react";
import { Link } from "react-router-dom";
class ServiceNoteWgt extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <div className="service-note">
      <div className="service-note__inner">
        <div className="service-note__thumb">
          <img src={this.props.thumb} alt="" />
        </div>
        <div className="service-note__content">
          <Link to={this.props.to}>
            <h3 className="service-note__headline">{this.props.headline}</h3>
          </Link>
        </div>
      </div>
    </div>
  }
}
export default ServiceNoteWgt;
