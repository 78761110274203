import React from "react";
import GetEmoji from './components/GetEmoji';
import DateFormating from './components/DateFormating';
import AccountHeader from './components/AccountHeader';
import AccountContentHeader from './components/AccountContentHeader';
import AccountNav from './components/AccountNav';
import ResultErrors from './components/ResultErrors';
import Loading from './components/Loading';
import { Link, Navigate } from "react-router-dom";
import Select from 'react-select';
import sprite from './media/icons.svg';
import img_preview from './media/item-preview-bonuses.png';
import img from './media/img-event-2.png';
import smile from './media/emoji-icon.svg';
import { getAuth } from "firebase/auth";
let Editor;
let EditorState;
let stateToHTML;

if (typeof window !== 'undefined') {
  Editor = require('react-draft-wysiwyg').Editor;
  EditorState = require('draft-js').EditorState;
  stateToHTML = require('draft-js-export-html').stateToHTML;

  require('../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
}
let arrayMoveImmutable;
if (typeof window !== 'undefined') {
  // Выполняем импорт только в клиентской среде
  const { arrayMoveImmutable: clientArrayMoveImmutable } = require('array-move');
  arrayMoveImmutable = clientArrayMoveImmutable;
}
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const customStylesError = {
  control: () => ({
    height: '42px',
    border: '1px solid #ff0000',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}

class BonusesCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      cardList: [img],
      chooseDateFull: null,
      startDate: null,
      endDate:null,
      finishStartDate: null,
      finishEndDate: null,
      startTime: null,
      endTime: null,
      hoverDate: null,
      dateModal: false,
      date: new Date(),
      fullYear: null,
      month: null,
      currentDate: new Date(),
      dates: [],
      countFirstElems: null,
      countEndedElems: null,
      headlineValue: '',
      contentState: (typeof window !== "undefined" && typeof document !== 'undefined') ? EditorState.createEmpty() : '',
      loyalityTypeValue: null,
      typeValue: '',
      statusValue: 'ACTIVE',
      generalBonus: false,
      nameCompanyValue: '',
      nameAddressValue: '',
      categoriesValue: '',
      categories: [],
      successSubmit: false,
      isLoadedSubmit: false,
      formDataLogo: null,
      formData: null,
      percentValue: 0,
      smileValue: '',
      getSmileModal: false,
      iterStart: null,
      iterStartSave: null,
      iterHover: null,
      countMonth: 1,
      countMonthSave: null,
      iterFinish: null,
      countMonthFinish: null,
      modalEditCategory: false,
      newGategories: [],
      newCategoriesForSubmit: [],
      newCategoriesLoadingSubmit: false,
      categoriesResult: [],
      categoryIdValue: null,
      categoryValue: '',
      editCategoriesIndex: [],
      newCategoriesName: [],
      errorFields: false,
      errors: null,
      errorEmptyNewCategory: false,
      acceptNewCategoriesIndex: [],
      acceptEditCategoriesIndex: [],
      errorEmptyCategory: false,
      acceptEditCategoriesIndexChange: [],
      errorAcceptEditCategories: false,
      previewStatus: false,
      objForSubmit: {},
      modalDeleteEvents: false,
      bonuseID: null,
      addTypeBonus: false,
      dateStartValue: '',
      dateEndValue: ''
    }
  }
  handlerDeleteEventModal(event){
    this.setState({
      modalDeleteEvents: true
    });
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/loyalties/categories`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if (res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        });
      } else {
        res.json().then(function(data) {
          let resultData = data;
          let newObj = resultData.map(function(obj) {
            return {label: obj.name, value: obj.categoryID}
          });
          that.setState( {categoriesResult: newObj} )
        });
      }
    })
  })
  }
  handlerHeadlineValue(event) {
    this.setState({
      headlineValue: event.target.value
    });
  }
  handlerLoyalityType(event) {
    this.setState({
      loyalityTypeValue: event.target.value
    });
  }
  handlerTypeValue(event) {
    this.setState({
      typeValue: event.target.value
    });
  }
  handlerStatus(event) {
    this.setState({
      statusValue: event.value
    });
  }
  onEditorStateChange = (contentState) => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      this.setState({
        contentState: contentState
      });
    }
  };
  handlerGeneralBonus(){
    this.setState(prevState => ({
      generalBonus: !prevState.generalBonus
    }));
  }
  handlerNameCompanyValue(event){
    this.setState({
      nameCompanyValue: event.target.value
    });
  }
  handlerAddressValue(event){
    this.setState({
      nameAddressValue: event.target.value
    });
  }
  handlerCategories(event){
    this.setState({
      categoriesValue: event.value
    });
  }
  handlerGetDate (event) {
    event.preventDefault();
    let fullYear = this.state.date.getFullYear();
    let month = this.state.date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      dateModal: !prevState.dateModal,
      dates: arr,
      fullYear: fullYear,
      pushElemsTotal: pushElemsNum,
      month: month
    }));
  }
  getLastDayOfMonth (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDate();
  }
  getUnshiftElemsNum (year, month) {
    let jsDayNum = this.getFirstWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return realDayNum - 1;
  }
  getPushElemsNum (year, month) {
    let jsDayNum = this.getLastWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return 7 - realDayNum;
  }
  createArr (from, to) {
    const arr = [];
    for(let i = from; i <= to; i++) {
      arr.push(i);
    }
    return arr;
  }
  unshiftElems (num, lastDayPrevMonth, arr) {
    this.setState(prevState => ({
      countFirstElems: num
    }));
    let count =  lastDayPrevMonth - num;
    for(let i = lastDayPrevMonth; i > count; i--) {
      arr.unshift(i);
    }
    return arr;
  }
  pushElems (num, arr) {
    this.setState(prevState => ({
      countEndedElems: num
    }));
    for(let i = 1; i <= num; i++) {
      arr.push(i);
    }
    return arr;
  }
  chunkArr (num, arr) {
    let result = [];
    let chunk = [];
    let iterCount = arr.length / num;
    for(let i = 0; i <iterCount; i++) {
      chunk = arr.splice(0, num);
      result.push(chunk);
    }
    return result;
  }
  getRealDayOfWeekNum (jsNumOfDay) {
    if(jsNumOfDay === 0){
      return 7;
    } else {
      return jsNumOfDay;
    }
  }
  getFirstWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month, 1);
    return date.getDay();
  }
  getLastWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDay();
  }
  getMonthName (num) {
    const monthes = [
      this.props.getTranslate('modal-calendarMonthNameJan'),
      this.props.getTranslate('modal-calendarMonthNameFeb'),
      this.props.getTranslate('modal-calendarMonthNameMar'),
      this.props.getTranslate('modal-calendarMonthNameApr'),
      this.props.getTranslate('modal-calendarMonthNameMay'),
      this.props.getTranslate('modal-calendarMonthNameJun'),
      this.props.getTranslate('modal-calendarMonthNameJul'),
      this.props.getTranslate('modal-calendarMonthNameAug'),
      this.props.getTranslate('modal-calendarMonthNameSep'),
      this.props.getTranslate('modal-calendarMonthNameOct'),
      this.props.getTranslate('modal-calendarMonthNameNov'),
      this.props.getTranslate('modal-calendarMonthNameDec')
    ];
    return monthes[num];
  }
  handlerCloseGetDate (event) {
    event.preventDefault();
    this.setState(prevState => ({
      dateModal: false
    }));
  }
  handlerPrevMonth (event) {
    event.preventDefault();
    let date = new Date(this.state.date.getFullYear(), this.state.date.getMonth() - 1, this.state.date.getDate());
    let fullYear = date.getFullYear();
    let month = date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      date: date,
      dates: arr,
      fullYear: fullYear,
      month: month,
      countMonth: Number(prevState.countMonth) - 1,
      iterStart: Number(prevState.countMonth) === (Number(prevState.countMonthSave) + 1) ? Number(prevState.iterStartSave) : '0'
    }));
  }
  handlerNextMonth (event) {
    event.preventDefault();
    let date = new Date(this.state.date.getFullYear(), this.state.date.getMonth() + 1, this.state.date.getDate());
    let fullYear = date.getFullYear();
    let month = date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      date: date,
      dates: arr,
      fullYear: fullYear,
      month: month,
      countMonth: Number(prevState.countMonth) + 1,
      iterStart: Number(prevState.countMonth) >= Number(prevState.countMonthSave) ? '0' : Number(prevState.iterStartSave)
    }));
  }
  chooseDate (iterStart, nextMonth, prevMonth, event) {
    event.preventDefault();
    let month = Number(this.state.month) + 1;
    let year = this.state.fullYear;
    if(nextMonth){
      month = Number(month) + 1;
      if(month === 13){
        month = 1;
        year = year + 1;
      }
    }
    if(prevMonth){
      month = Number(month) - 1;
      if(month === 0){
        month = 12;
        year = year - 1;
      }
    }
    if(!this.state.finishStartDate){
      this.setState(prevState => ({
        chooseDateType: 'finish',
        startDate: year + '-' + month + '-' + event.target.innerHTML,
        finishStartDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '-' + (month < 10 ? '0' + month : month) + '-' + year,
        iterStart: iterStart,
        iterStartSave: iterStart,
        countMonthSave: prevState.countMonth,
        dateStartValue: ''
      }));
    } else if(this.state.chooseDateType === 'start') {
      this.setState(prevState => ({
        chooseDateType: 'finish',
        startDate: year + '-' + month + '-' + event.target.innerHTML,
        finishStartDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '-' + (month < 10 ? '0' + month : month) + '-' + year,
        iterStart: iterStart,
        iterStartSave: iterStart,
        countMonthSave: prevState.countMonth,
        dateStartValue: ''
      }));
    } else if(this.state.chooseDateType === 'finish'){
      this.setState(prevState => ({
        endDate: new Date(prevState.startDate) > new Date(year + '-' + month + '-' + event.target.innerHTML) ? null : year + '-' + month + '-' + event.target.innerHTML,
        finishEndDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '-' + (month < 10 ? '0' + month : month) + '-' + year,
        iterFinish: iterStart,
        countMonthFinish: prevState.countMonth,
        dateEndValue: ''
      }));
    } else {
      this.setState(prevState => ({
        endDate: new Date(prevState.startDate) > new Date(year + '-' + month + '-' + event.target.innerHTML) ? null : year + '-' + month + '-' + event.target.innerHTML,
        finishEndDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '-' + (month < 10 ? '0' + month : month) + '-' + year,
        iterFinish: iterStart,
        countMonthFinish: prevState.countMonth,
        dateEndValue: ''
      }));
    }
  }
  chooseDateHover (iterHover, event) {
    event.preventDefault();
    if(this.state.startDate){
      this.setState(prevState => ({
        iterHover: iterHover
      }));
    }
  }
  chooseEndHover (event) {
    event.preventDefault();
    this.setState(prevState => ({
      iterHover: null
    }));
  }
  onKeyPress (event) {
    let input = event.target;
    if(event.charCode < 47 || event.charCode > 57) {
      event.preventDefault();
    }
    var len = input.value.length;
    if(len !== 1 || len !== 3) {
      if(event.charCode == 47) {
        event.preventDefault();
      }
    }
    if(len === 2) {
      input.value += '.';
    }
    if(len === 5) {
      input.value += '.';
    }
  }
  onKeyPress2 (event) {
    let input = event.target;
    if(event.charCode < 47 || event.charCode > 57) {
      event.preventDefault();
    }
    var len = input.value.length;
    if(len !== 1 || len !== 3) {
      if(event.charCode == 47) {
        event.preventDefault();
      }
    }
    if(len === 2) {
      input.value += ':';
    }
  }
  handlerStartTime (event) {
    event.preventDefault();
    this.setState({
      startTime: event.target.value
    });
  }
  handlerEndTime (event) {
    event.preventDefault();
    this.setState({
      endTime: event.target.value
    });
  }
  setDateInput (event) {
    event.preventDefault();
    this.setState(prevState => ({
      chooseDateFull: prevState.finishStartDate.split("-").join(".") + ' - ' + (prevState.finishEndDate ? prevState.finishEndDate.split("-").join(".") : prevState.finishStartDate.split("-").join(".")),
      dateModal: !prevState.dateModal
    }));
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handlerAddPhotos(event){
    let error = false;
    let files = event.target.files;
    if(files){
      for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileName = file.name.toLowerCase();
          const extension = fileName.split('.').pop();
          const fileSizeInMB = file.size / (1024 * 1024);
          if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png' && extension !== 'webp') {
              error = true;
              this.setState(prevState => ({
                errorFormatImgs: true
              }));
          }
          if (fileSizeInMB > 20) {
              error = true;
              this.setState(prevState => ({
                errorSizeImgs: true
              }));
          }
      }
    }
    if(!error){
      let formData = this.state.formDataLogo;
      if(formData){
        formData = [];
        const dt = new DataTransfer();
        for (let file of formData) {
          dt.items.add(file)
        }
        for (let file of files) {
          dt.items.add(file)
        }
        this.setState(prevState => ({
          formDataLogo: Array.from(dt.files)
        }));
      } else {
        this.setState(prevState => ({
          formDataLogo: Array.from(files)
        }));
      }
    }
  }
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(prevState => ({
      formData: arrayMoveImmutable(prevState.formData, oldIndex, newIndex)
    }));
  }
  handlerNextPreview(event){
    let that = this;
    this.setState(prevState => ({
      errorFields: false
    }));
    if(this.state.headlineValue && Object.keys(this.state.contentState).length !== 0 && this.state.finishStartDate && that.state.categoryIdValue && this.state.statusValue && this.state.nameCompanyValue) {
      if(typeof window !== "undefined" && typeof document !== 'undefined'){
        document.body.classList.add('preview');
      }
      this.setState(prevState => ({
        previewStatus: !prevState.previewStatus
      }));
      let obj = {
        "address": that.state.nameAddressValue,
        "companyName": that.state.nameCompanyValue,
        "description": stateToHTML(this.state.contentState.getCurrentContent()),
        "endTime": (that.state.finishEndDate ? that.state.finishEndDate.split('-').reverse().join('-') : that.state.finishStartDate.split('-').reverse().join('-')),
        "loyaltyType": that.state.loyalityTypeValue,
        "companyLoyalty": that.state.generalBonus,
        "smile": that.state.smileValue,
        "startTime": that.state.finishStartDate.split('-').reverse().join('-'),
        "status": that.state.statusValue,
        "title": that.state.headlineValue,
        "typeValue": that.state.typeValue
      };
      obj.startTime = new Date(obj.startTime).toISOString();
      obj.endTime = new Date(obj.endTime).toISOString();
      if(that.state.categoryIdValue){
        obj.category = {
          "categoryID": that.state.categoryIdValue.value,
          "name": that.state.categoryIdValue.label
        };
      }
      this.setState({
        objForSubmit: obj
      });
      if(this.state.formDataLogo){
        this.handleFileSelect();
      }
    } else {
      this.setState({
        errorFields: true
      });
    }
  }
  handlerSubmit(event){
    event.preventDefault();
    let that = this;
    if(this.state.errorFields){
      this.setState({
        errorFields: false
      });
    }
    this.setState({
      isLoadedSubmit: true
    });
    if(this.state.headlineValue && Object.keys(this.state.contentState).length !== 0 && this.state.finishStartDate && this.state.statusValue && this.state.nameCompanyValue) {
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/loyalties`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(that.state.objForSubmit)
      }).then(res => {
          if (res.status !== 200) {
            that.setState({
              isLoadedSubmit: false
            });
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState({
                bonuseID: data.loyaltyID
              });
              if(!that.state.formDataLogo){
                if(typeof window !== "undefined" && typeof document !== 'undefined'){
                  localStorage.setItem("successBonusesSubmit", JSON.stringify({status: true, text: that.props.getTranslate('successStatus-Save')}))
                }
                that.setState({
                  isLoadedSubmit: false,
                  successSubmit: true
                });
                setTimeout(() => {
                  that.setState({
                    successSubmit: false
                  });
                }, 5000);
              }
              if(that.state.formDataLogo){
                const myHeaders = new Headers({
                  'Authorization': 'Bearer ' + idToken
                });
                let formData = new FormData();
                for (let file of that.state.formDataLogo) {
                  formData.append('file', file);
                  fetch(`${that.props.queryLink}/api/v1/loyalties/${data.loyaltyID}/photos/logo`, {
                    method: 'POST',
                    headers: myHeaders,
                    body: formData
                  }).then(res => {
                    if(res.status !== 200) {
                      res.json().then(function(data) {
                        that.setState({
                          errors: data.errors
                        })
                      })
                    } else {
                      if(typeof window !== "undefined" && typeof document !== 'undefined'){
                        localStorage.setItem("successBonusesSubmit", JSON.stringify({status: true, text: that.props.getTranslate('successStatus-Save')}));
                      }
                        that.setState({
                          isLoadedSubmit: false,
                          successSubmit: true
                        });
                        setTimeout(() => {
                          that.setState({
                            successSubmit: false
                          });
                        }, 5000);
                      }
                  })
                }
              }
            });
          }
        })
})

    } else {
      this.setState({
        errorFields: true,
        isLoadedSubmit: false
      });
    }
  }
  handlerDeleteImg(index, event){
    let formData = this.state.formData;
    formData.splice(index, 1);
    this.setState(prevState => ({
      formData: formData
    }));
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: false
    }));
  }
  chooseDateHandler(choose, event){
    this.setState({
      chooseDateType: choose
    });
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalDisabled: false,
        modalEditCategory: false,
        modalDeleteEvents: false
      }));
    }, 500);
    if(this.state.newGategories.length > 0){
      this.setState(prevState => ({
        newGategories: prevState.newGategories.filter(element => element !== '')
      }));
    }
  }
  handleEditCategories () {
    this.setState(prevState => ({
      modal: !prevState.modal,
      modalEditCategory: true
    }));
  }
  handlerSetGategory(event){
    this.setState({
      categoryIdValue: event
    });
  }
  handlerCategoryValue(event){
    this.setState({
      categoryValue: event.target.value
    });
  }
  handlerCreateCategory(event){
    this.setState(prevState => ({
      newGategories: ['', ...prevState.newGategories]
    }));
  }
  handerNewGategoryName(index, event){
    const newGategories = this.state.newGategories;
    newGategories.splice(index, 1, event.target.value);
    this.setState(prevState => ({
      newGategories: newGategories
    }));
  }
  handlerAddCreateCategory(index, event){
    let obj = {label: this.state.newGategories[index]};
    if(obj.label !== ''){
      this.setState(prevState => ({
        categoriesResult: [obj, ...prevState.categoriesResult],
        newCategoriesForSubmit: [...prevState.newCategoriesForSubmit, obj.label],
        newGategories: [...prevState.newGategories.slice(0, index), ...prevState.newGategories.slice(index + 1)],
        acceptNewCategoriesIndex: []
      }));
    } else {
      if(this.state.acceptNewCategoriesIndex.indexOf(index) === -1){
        this.setState(prevState => ({
          acceptNewCategoriesIndex: [index, ...prevState.acceptNewCategoriesIndex]
        }));
      }
    }
  }
  handlerDeleteCategoryById(categoryID, index, event){
    if(categoryID){
      let that = this;
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.token()
      });
      fetch(`${that.props.queryLink}/api/v1/loyalties/categories/${categoryID}`, {
        method: 'DELETE',
        headers: myHeaders
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState(prevState => ({
              errors: data.errors
            }));
          });
        } else {
          that.setState(prevState => ({
            categoriesResult: prevState.categoriesResult.filter(el => el.value !== categoryID)
          }));
        }
      })
    } else {
      this.setState(prevState => ({
        newGategories: [...prevState.newGategories.slice(0, index), ...prevState.newGategories.slice(index + 1)],
        categoriesResult: [...prevState.categoriesResult.slice(0, index), ...prevState.categoriesResult.slice(index + 1)]
      }));
    }
  }
  handlerDeleteCategory(index, event){
    this.setState(prevState => ({
      newGategories: [...prevState.newGategories.slice(0, index), ...prevState.newGategories.slice(index + 1)]
    }));
  }
  handlerEditCategoryName(index, event){
    let indexIsset = this.state.newCategoriesForSubmit.indexOf(this.state.categoriesResult[index].label);
    if(indexIsset !== -1){
      this.setState(prevState => ({
        newCategoriesForSubmit: prevState.newCategoriesForSubmit.filter(el => el !== this.state.categoriesResult[index].label)
      }))
    }
    this.setState(prevState => ({
      editCategoriesIndex: [...prevState.editCategoriesIndex, index]
    }));
  }
  handlerEditCategoryNameValue(index, event){
    const newGategories = this.state.categoriesResult;
    newGategories[index].label = event.target.value;
    this.setState(prevState => ({
      categoriesResult: newGategories
    }));
    if(this.state.acceptEditCategoriesIndexChange.indexOf(index) === -1){
      this.setState(prevState => ({
        acceptEditCategoriesIndexChange: [...prevState.acceptEditCategoriesIndexChange, index]
      }));
    }
  }
  handlerSaveCategoryName(index, event){
    if(this.state.categoriesResult[index].label !== ''){
      this.setState(prevState => ({
        newCategoriesName: [...prevState.newCategoriesName, {"categoryID": this.state.categoriesResult[index].value, "name": this.state.categoriesResult[index].label}],
        editCategoriesIndex: prevState.editCategoriesIndex.filter(el => el !== index),
        acceptEditCategoriesIndex: [],
        acceptEditCategoriesIndexChange: prevState.acceptEditCategoriesIndexChange.filter(el => el !== index)
      }));
    } else {
      if(this.state.acceptEditCategoriesIndex.indexOf(index) === -1){
        this.setState(prevState => ({
          acceptEditCategoriesIndex: [index, ...prevState.acceptEditCategoriesIndex]
        }))
      }
    }
  }
    handlerSubmitCategories(event){
      let that = this;
        this.setState({
          errorEmptyNewCategory: false,
          errorEmptyCategory: false,
          errorAcceptEditCategories: false,
          newCategoriesLoadingSubmit: true
        });
    if(this.state.newCategoriesForSubmit.length < 1 || this.state.newCategoriesName.length < 1){
      if(this.state.newGategories.length < 1 && this.state.newCategoriesName.length < 1 && this.state.editCategoriesIndex.length < 1){
        that.handleModalDisabled();
      }
    }
      this.state.newGategories.map((item, index) => {
        if(item === ''){
          this.setState(prevState => ({
            errorEmptyNewCategory: true,
            newCategoriesLoadingSubmit: false
          }));
          return false;
        }
      });
      this.state.categoriesResult.map((item, index) => {
        if(item.label=== ''){
          this.setState(prevState => ({
            errorEmptyNewCategory: true,
            newCategoriesLoadingSubmit: false
          }));
          return false;
        }
      })
    if(this.state.newCategoriesForSubmit && !this.state.errorEmptyNewCategory && this.state.editCategoriesIndex.length < 1){
            const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.token()
      });
      let categories = this.state.newCategoriesForSubmit
      async function asyncAwaitWay(that) {
        for (let category of categories) {
          fetch(`${that.props.queryLink}/api/v1/loyalties/categories`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
              "categoryID": 0,
              "name": category
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                });
              })
            } else {
              categories.shift();
              if(categories.length < 1){
                that.setState(prevState => ({
                  newGategories: [],
                  newCategoriesLoadingSubmit: false
                }));
                that.handleModalDisabled();
                fetch(`${that.props.queryLink}/api/v1/loyalties/categories`, {
                  method: 'GET',
                  headers: myHeaders
                }).then(res => {
                  if (res.status !== 200) {
                    res.json().then(function(data) {
                      that.setState({
                        errors: data.errors
                      });
                    });
                  } else {
                    res.json().then(function(data) {
                      let resultData = data;
                      let newObj = resultData.map(function(obj) {
                        return {label: obj.name, value: obj.categoryID}
                      });
                      that.setState( {categoriesResult: newObj} )
                    });
                  }
                })
              }
            }
          })
        }
      }
      asyncAwaitWay(this);
    }
      this.state.categoriesResult.map((item, index) => {
        if(item.label=== ''){
          this.setState(prevState => ({
            errorEmptyCategory: true,
            newCategoriesLoadingSubmit: false
          }));
          return false;
        }
      })
      if(this.state.editCategoriesIndex){
          this.setState(prevState => ({
            errorAcceptEditCategories: true,
            newCategoriesLoadingSubmit: false
          }));
      }
    if(this.state.newCategoriesName && !this.state.errorEmptyCategory && this.state.editCategoriesIndex.length < 1){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.token()
      });
      let categories = this.state.newCategoriesName;
      async function asyncAwaitWay(that) {
        for (let category of categories) {
          console.log(category);
          if(category.categoryID){
            fetch(`${that.props.queryLink}/api/v1/loyalties/categories/${category.categoryID}`, {
              method: 'PUT',
              headers: myHeaders,
              body: JSON.stringify(category)
            }).then(res => {
              if (res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors
                  });
                });
              } else {
                res.json().then(function(data) {
                  categories.shift();
                  if(categories.length < 1){
                    that.setState(prevState => ({
                      newCategoriesName: [],
                      newCategoriesLoadingSubmit: false
                    }));
                    that.handleModalDisabled();
                  }
                });
              }
            })
          } else {
          fetch(`${that.props.queryLink}/api/v1/loyalties/categories`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
              "categoryID": 0,
              "name": category.name
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                });
              })
            } else {
              categories.shift();
              if(categories.length < 1){
                that.setState(prevState => ({
                  newGategories: [],
                  newCategoriesLoadingSubmit: false
                }));
                that.handleModalDisabled();
                fetch(`${that.props.queryLink}/api/v1/loyalties/categories`, {
                  method: 'GET',
                  headers: myHeaders
                }).then(res => {
                  if (res.status !== 200) {
                    res.json().then(function(data) {
                      that.setState({
                        errors: data.errors
                      });
                    });
                  } else {
                    res.json().then(function(data) {
                      let resultData = data;
                      let newObj = resultData.map(function(obj) {
                        return {label: obj.name, value: obj.categoryID}
                      });
                      that.setState( {categoriesResult: newObj} )
                    });
                  }
                })
              }
            }
          })
          }
        }
      }
      asyncAwaitWay(this);
    }
  }
  handleFileSelect() {
    var file = this.state.formDataLogo;
    var f = file[0];
    var reader = new FileReader();
    reader.onload = (function(theFile) {
        return function(e) {
            var span = document.querySelector('.account-preview__wgt_col-logo');
            span.innerHTML = ['<img class="thumb" title="', escape(theFile.name), '" src="', e.target.result, '" />'].join('');
        };
    })(f);
    reader.readAsDataURL(f);
  }

  handlerAddTypeBonus(event){
    this.setState(prevState => ({
      addTypeBonus: !prevState.addTypeBonus
    }));
  }

  handlerAddFileBonus(event){
    this.setState(prevState => ({
      addFileBonus: !prevState.addFileBonus
    }));
  }

  handlerGetEmoji(smile, event){
    this.setState({
      smileValue: smile
    });
  }

  handlerDateStart(event){
    let value = event.target.value;
    this.setState({
      dateStartValue: value
    });
    if(value === '' && this.state.finishStartDate){
      this.setState({
        dateStartValue: null
      });
    }
  }

  handlerDateEnd(event){
    let value = event.target.value;
    this.setState({
      dateEndValue: value
    });
    if(value === '' && this.state.finishEndDate){
      this.setState({
        finishEndDate: null
      });
    }
  }

  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('bunusesForm-AddTitle') + ' | ' + this.props.companyName;
    }
    let totalItem = this.state.dates.length * 7;
    let totalItemNotPush = totalItem - this.state.pushElemsTotal;
    let firstItems = this.state.countFirstElems;
    let endedItems = totalItem - this.state.countEndedElems;
    let iter = 0;
    let tableDate = this.state.dates.map((item, index) => {
      return <tr key={index}>
        {item.map((elem, i) => {
          iter++;
          if((this.state.date < this.state.currentDate && this.state.date.getMonth() !== this.state.currentDate.getMonth()) || (index === 0 && this.state.countFirstElems > i && this.state.month === this.state.currentDate.getMonth())){
            return <td className="ended" key={i}>{elem}</td>
          } else if(this.state.currentDate >= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + (elem + 1)) && iter < endedItems && (this.state.date.getMonth()) <= (this.state.currentDate.getMonth()) && this.state.date.getFullYear() <= this.state.currentDate.getFullYear()){
            return <td className="ended" key={i}>{elem}</td>
          } else if(new Date(this.state.startDate) <= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + elem) && new Date(this.state.hoverDate) >= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + elem)) {
            return <td className="checked" key={i} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)} onMouseLeave={this.chooseEndHover.bind(this)}>{elem}</td>
          } else if(iter < endedItems && this.state.startDate && (new Date(this.state.startDate).getDate() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getDate()) && (new Date(this.state.startDate).getMonth() - 1 === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getMonth()) && (new Date(this.state.startDate).getFullYear() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getFullYear())) {
            return <td className="checked" key={i} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)} onMouseLeave={this.chooseEndHover.bind(this)}>{elem}</td>
          } else if(this.state.iterStart && (this.state.countMonth >= this.state.countMonthSave && this.state.iterStart <= iter && this.state.iterHover >= iter)) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else if(this.state.iterStart && this.state.iterFinish && (this.state.countMonth <= this.state.countMonthFinish && this.state.countMonth >= this.state.countMonthSave) && (this.state.iterStart <= iter && this.state.iterFinish >= iter)) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else if(this.state.iterStart && this.state.iterFinish && this.state.countMonth < this.state.countMonthFinish && this.state.countMonth > this.state.countMonthSave){
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else if((this.state.iterStart && this.state.iterFinish) && (this.state.countMonthSave !== this.state.countMonthFinish) && (this.state.countMonth === this.state.countMonthSave) && iter > this.state.iterStart) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else {
            return <td key={i} onMouseEnter={this.chooseDateHover.bind(this, iter)} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          }
        })}
      </tr>
    });
    let previewLoyaltyType = '';
    if(this.state.objForSubmit){
      if(this.state.objForSubmit.loyaltyType === "COMPENSATION"){
        previewLoyaltyType = this.props.getTranslate('bonuses-TypeCompensation');
      } else if(this.state.objForSubmit.loyaltyType === "CARD") {
        previewLoyaltyType = this.props.getTranslate('bonuses-TypeCard');
      } else if(this.state.objForSubmit.loyaltyType === "PROMO_CODE"){
        previewLoyaltyType = this.props.getTranslate('bonuses-TypePromo');
      } else if(this.state.objForSubmit.loyaltyType === "DEPOSIT") {
        previewLoyaltyType = this.props.getTranslate('bonuses-TypeDeposit');
      } else if(this.state.objForSubmit.loyaltyType === "NUMBER") {
        previewLoyaltyType = this.props.getTranslate('bonuses-TypeCertificate');
      } else {
        previewLoyaltyType = this.props.getTranslate('bonuses-TypeOther');
      }
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successSubmit && <Navigate to={`/account/benefits/view/${this.state.bonuseID}`} replace={true} />) : ''}
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed}  />
    <div className="account-wrap">
    <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
    <div className="account-content">
    {this.state.previewStatus ?
      <div className="account-content__inner account-preview">

        {/* Account header component */}
        <AccountContentHeader
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
          preview={true}
          backBtn={true}
          backBtnType="btn"
          backBtnHandler={this.handlerNextPreview.bind(this)}
          backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
        />

        <h2 className="account-preview__headline">{this.props.getTranslate('bonuses-previewTitle')}</h2>
        <div className="account-preview__inner account-preview__inner_preview">
          <div className="account-preview__info">
            <div className="account-preview__wgt">
              <div className="account-preview__wgt_row">
                <div className="account-preview__wgt_col-info">
                  <div className="account-preview__wgt-headline">
                    {this.state.objForSubmit.smile ? <div className="account-preview__wgt-smile" dangerouslySetInnerHTML={{__html: this.state.objForSubmit.smile}}></div> : <img className="account-preview__wgt-smile" src={smile} alt="" />}
                    <div className="account-preview__wgt-headline-text">{this.state.objForSubmit.title}</div>
                  </div>
                  <div className="account-preview__info-row">
                    <div className="account-preview__info-wgt">
                      <div className="account-preview__info-wgt-label">{this.props.getTranslate('bunusesForm-labelDescription')}:</div>
                      <div className="account-preview__info-wgt-value" dangerouslySetInnerHTML={{__html: this.state.objForSubmit.description}}></div>
                    </div>
                    {this.state.objForSubmit.category ? <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                      <div className="account-preview__info-wgt-label">{this.props.getTranslate('bunusesForm-previewLabelCategory')}:</div>
                      <div className="account-preview__info-wgt-value">{this.state.objForSubmit.category.name}</div>
                    </div> : ''}
                    <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                      <div className="account-preview__info-wgt-label">{this.props.getTranslate('bunusesForm-labelType')}:</div>
                      <div className="account-preview__info-wgt-value">{previewLoyaltyType}</div>
                    </div>
                    <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                      <div className="account-preview__info-wgt-label">{this.props.getTranslate('bunusesForm-labelDate')}:</div>
                      <div className="account-preview__info-wgt-value"><DateFormating start={this.state.objForSubmit.startTime} end={this.state.objForSubmit.endTime} time="off" /></div>
                    </div>
                    <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                      <div className="account-preview__info-wgt-label">{this.props.getTranslate('bunusesForm-LabelGeneralBonus')}:</div>
                      <div className="account-preview__info-wgt-value">{this.state.objForSubmit.companyLoyalty ? this.props.getTranslate('bunusesForm-StatusGeneralBonusYes') : this.props.getTranslate('bunusesForm-StatusGeneralNot')}</div>
                    </div>
                    <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                      <div className="account-preview__info-wgt-label">{this.props.getTranslate('bunusesForm-InfoCompanyTitle')}:</div>
                      <div className="account-preview__info-wgt-value">{this.state.objForSubmit.companyName}</div>
                    </div>
                    <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                      <div className="account-preview__info-wgt-label">{this.props.getTranslate('bunusesForm-PreviewLabelAddress')}:</div>
                      <div className="account-preview__info-wgt-value">{this.state.objForSubmit.address}</div>
                    </div>
                  </div>
                </div>
                <div className="account-preview__wgt_col-logo"></div>
              </div>
              <div className="account-preview__info-nav">
                <button className="button-standart button-standart_theme_blue" type="button" onClick={this.handlerNextPreview.bind(this)}>{this.props.getTranslate('bonuses-PreviewBtnEdit')}</button>
                <button className="button-standart button-standart_theme_red" type="button" onClick={this.handlerDeleteEventModal.bind(this)}>{this.props.getTranslate('bonuses-PreviewBtnDelete')}</button>
              </div>
            </div>
            <button className="preview-submit-btn" type="button" disabled={this.state.isLoadedSubmit ? true : false} onClick={this.handlerSubmit.bind(this)}>{this.props.getTranslate('bonuses-SubmitPublish')}{this.state.isLoadedSubmit && <Loading />}</button>
          </div>
          <div className="account-preview__img">
            <div className="account-preview__img-description">{this.props.getTranslate('bonuses-PreviewExample')}</div>
            <img src={img_preview} alt="" />
          </div>
        </div>
      </div> : <div className="account-content__inner">

        {/* Account header component */}
        <AccountContentHeader
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
          backBtn={true}
          backBtnType="link"
          backBtnClass="button-back_theme_gray"
          backBtnHandler={this.props.getPermalink("/account/benefits")}
          backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
        />

        <div className="account-content__header-title">
          <h1 className="account-content__headline">{this.props.getTranslate('bunusesForm-AddTitle')}</h1>
        </div>
        <div className="bonuses-form-wrap">
          <div className="bonuses-form">
            <div className="bonuses-form__inner">
              <h3 className="form__headline">{this.props.getTranslate('bunusesForm-InfoBonusTitle')}</h3>
              <div className="field-wrap">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="headline">{this.props.getTranslate('bunusesForm-labelTitle')}</label>
                <div className="field-wrap__smile">
                  <div className="field-wrap__smile__inner field-wrap__input_width_75">
                    <input id="headline" className={`field-wrap__input ${this.state.errorFields && !this.state.headlineValue ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.headlineValue} onChange={this.handlerHeadlineValue.bind(this)} placeholder={this.props.getTranslate('bunusesForm-placeholderTitle')} />
                    {this.state.errorFields && !this.state.headlineValue ? <div className="validation-error">{this.props.getTranslate('bunusesValidation-headline')}</div> : ''}
                  </div>
                  <GetEmoji
                    tooltipValue={this.props.getTranslate('event-tooltipEmoji')}
                    onChangeHandler={this.handlerGetEmoji.bind(this)}
                  />
                  </div>
              </div>
              <div className="field-wrap">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="description">{this.props.getTranslate('bunusesForm-labelDescription')}</label>
                {(typeof window !== "undefined" && typeof document !== 'undefined') ? <Editor
                  toolbar={{
                    options: ['inline', 'blockType', 'list'],
                    inline: {
                      inDropdown: false,
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                      options: ['bold', 'italic', 'underline']
                    },
                    blockType: {
                      inDropdown: true,
                      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                    },
                    list: {
                      inDropdown: false,
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                      options: ['unordered', 'ordered']
                    }
                  }}
                  wrapperClassName={`wrapper-class editor-field ${this.state.errorFields && Object.keys(this.state.contentState).length === 0 ? 'field-wrap__input_error' : ''}`}
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class editor-toolbar"
                  editorState={this.state.contentState}
                  onEditorStateChange={this.onEditorStateChange}
                  placeholder={this.props.getTranslate('bunusesForm-placeholderDescription')}
                /> : ''}
                {this.state.errorFields && Object.keys(this.state.contentState).length === 0 ? <div className="validation-error">{this.props.getTranslate('bunusesValidation-description')}</div> : ''}
              </div>
              <div className="board-form__row">
              <div className="field-wrap field-wrap__tooltip field-wrap__tooltip">
                <div className="unlimited-product"><div><input className="styled-checkbox" id="checkbox-add-type" type="checkbox" checked={this.state.addTypeBonus} onChange={this.handlerAddTypeBonus.bind(this)} /><label htmlFor="checkbox-add-type"></label></div><span>{this.props.getTranslate('bunusesForm-AddTypeLabel')}</span></div>
                <div className="tooltip-info">
                  <span className="tooltip-info__icon">?</span>
                  <div className="tooltip-info__content">
                    <div className="arrow"></div>
                    <p>{this.props.getTranslate('bunusesForm-InfoCompanyTypeLoyalty')}</p>
                  </div>
                </div>
              </div>
              </div>
            {this.state.addTypeBonus ? <div className="board-form__row">
              <div className="field-wrap">
                <div className="field-wrap__label-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="type">{this.props.getTranslate('bunusesForm-labelType')}</label>
                  <div className="tooltip-info">
                    <span className="tooltip-info__icon">?</span>
                    <div className="tooltip-info__content">
                      <div className="arrow"></div>
                      <p>{this.props.getTranslate('tooltipBonuses-KindLoyalty')}</p>
                    </div>
                  </div>
                </div>
                <input id="type" className="field-wrap__input" type="text" value={this.state.loyalityTypeValue} onChange={this.handlerLoyalityType.bind(this)} placeholder={this.props.getTranslate('bunusesForm-placeholderLoyaltyType')} />
              </div>
              <div className="field-wrap">
                <div className="field-wrap__label-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="type-value">{this.props.getTranslate('bunusesForm-TextType')}</label>
                  <div className="tooltip-info">
                    <span className="tooltip-info__icon">?</span>
                    <div className="tooltip-info__content">
                      <div className="arrow"></div>
                      <p>{this.props.getTranslate('tooltipBonuses-TypeLoyalty')}</p>
                    </div>
                  </div>
                </div>
                <input id="type-value" className="field-wrap__input" type="text" value={this.state.typeValue} onChange={this.handlerTypeValue.bind(this)} placeholder={this.props.getTranslate('bunusesForm-placeholderLoyaltyTypeValue')} />
              </div>
              </div> : ''}

              <div className="board-form__row board-form__row_padding">
                <div className="field-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('bunusesForm-labelCategory')}</label>
                  {this.state.categoriesResult && this.state.categoriesResult.length > 0 ? <Select
                    styles={this.state.errorFields && !this.state.categoryIdValue ? customStylesError : customStyles}
                    options={this.state.categoriesResult}
                    value={this.state.categoriesResult && this.state.categoryIdValue ? this.state.categoriesResult[this.state.categoriesResult.findIndex(el => el.value === this.state.categoryIdValue.value)] : null}
                    onChange={this.handlerSetGategory.bind(this)}
                    placeholder={this.props.getTranslate('bunusesForm-placeholderCategory')}
                  /> : <div onClick={this.handleEditCategories.bind(this)}> <Select
                    styles={this.state.errorFields && !this.state.categoryIdValue ? customStylesError : customStyles}
                    value={this.state.categoriesResult && this.state.categoryIdValue ? this.state.categoriesResult[this.state.categoriesResult.findIndex(el => el.value === this.state.categoryIdValue.value)] : null}
                    options={this.state.categoriesResult}
                    isSearchable={false}
                    placeholder={this.props.getTranslate('bunusesForm-placeholderCategory')}
                    isDisabled={true}
                  /></div> }
                  {this.state.errorFields && !this.state.categoryIdValue ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                  <button className="library-form__categories-edit account-wgt__edit-btn" type="button" onClick={this.handleEditCategories.bind(this)}>{this.props.getTranslate('bunusesForm-EditCategoriesBtn')}</button>
                </div>
                <div className="field-wrap questions-form__date">
                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="date">{this.props.getTranslate('bunusesForm-labelDate')}</label>
                  <div className="questions-form__date-inner">
                    <svg className="questions-form__date-icon">
                      <use href={`${sprite}#date-icon`}></use>
                    </svg>
                    <input id="date" className={`field-wrap__input questions-form__date-field ${this.state.errorFields && !this.state.chooseDateFull ? 'field-wrap__input_error' : ''}`} type="text" defaultValue={this.state.chooseDateFull} placeholder={this.props.getTranslate('bunusesForm-placeholderDate')} onClick={this.handlerGetDate.bind(this)} autoComplete="off" />
                  </div>
                  {this.state.errorFields && !this.state.chooseDateFull ? <div className="validation-error">{this.props.getTranslate('validation-getDate')}</div> : ''}
                </div>
                <div className="field-wrap field-wrap__tooltip field-wrap__tooltip-bonuses">
                  <div className="unlimited-product"><div><input className="styled-checkbox" id="styled-checkbox-0" type="checkbox" checked={this.state.generalBonus} onChange={this.handlerGeneralBonus.bind(this)} /><label htmlFor="styled-checkbox-0"></label></div><span>{this.props.getTranslate('bunusesForm-LabelGeneralBonus')}</span></div>
                  <div className="tooltip-info">
                    <span className="tooltip-info__icon">?</span>
                    <div className="tooltip-info__content">
                      <div className="arrow"></div>
                      <p>{this.props.getTranslate('tooltipBonuses-GeneralLoyalty')}</p>
                    </div>
                  </div>
                </div>
                </div>
                <h3 className="form__headline">{this.props.getTranslate('bunusesForm-InfoCompanyTitle')}</h3>
                <div className="board-form__row">
                <div className="field-wrap">
                  <div className="field-wrap__label-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="company-name">{this.props.getTranslate('bunusesForm-InfoCompanyTitle')}</label>
                    <div className="tooltip-info">
                      <span className="tooltip-info__icon">?</span>
                      <div className="tooltip-info__content">
                        <div className="arrow"></div>
                        <p>{this.props.getTranslate('tooltipBonuses-CompanyNameLoyalty')}</p>
                      </div>
                    </div>
                  </div>
                  <input id="company-name" className={`field-wrap__input ${this.state.errorFields && !this.state.nameCompanyValue ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.nameCompanyValue} onChange={this.handlerNameCompanyValue.bind(this)} />
                  {this.state.errorFields && !this.state.nameCompanyValue ? <div className="validation-error">{this.props.getTranslate('bunusesValidation-companyName')}</div> : ''}
                </div>
                <div className="field-wrap field-wrap__address">
                  <div className="field-wrap__label-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="address">{this.props.getTranslate('bunusesForm-InfoCompanyAddress')}</label>
                    <div className="tooltip-info">
                      <span className="tooltip-info__icon">?</span>
                      <div className="tooltip-info__content">
                        <div className="arrow"></div>
                        <p>{this.props.getTranslate('tooltipBonuses-CompanyAddressLoyalty')}</p>
                      </div>
                    </div>
                  </div>
                  <input id="address" className="field-wrap__input" type="text" value={this.state.nameAddressValue} onChange={this.handlerAddressValue.bind(this)} />
                </div>
              </div>
              <div className="field-wrap">
                <div className="field-wrap__label-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="upload">{this.props.getTranslate('bunusesForm-InfoCompanyLogo')}</label>
                  <div className="tooltip-info">
                    <span className="tooltip-info__icon">?</span>
                    <div className="tooltip-info__content">
                      <div className="arrow"></div>
                      <p>{this.props.getTranslate('bunusesForm-InfoCompanyLogoTooltip')}</p>
                    </div>
                  </div>
                </div>
                <div className="edit-user-form__imgs-inner">
                  <div className={`edit-user-upload${this.state.errorFormatImgs || this.state.errorSizeImgs ? ' edit-user-upload-error' : ''}`}>
                    <button className="edit-product-img__remove" type="button"><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button>
                    <input type="file" id="upload-logo" onChange={this.handlerAddPhotos.bind(this)} />
                    <label htmlFor="upload-logo">
                      <svg className="upload__icon">
                        <use href={`${sprite}#img-upload`}></use>
                      </svg>
                    {this.props.getTranslate('bunusesForm-InfoCompanyLogoLabel')}</label>
                  </div>
                  {this.state.formDataLogo && <div className="edit-product-img"><div><button className="edit-product-img__remove" type="button"><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><img className="sort-img" src={URL.createObjectURL(this.state.formDataLogo[0])} alt="" /></div></div>}
                </div>
              </div>
              {this.state.errorFormatImgs ? <div className="validation-error">{this.props.getTranslate('validation-AdsImgFormat')}</div> : ''}
              {this.state.errorSizeImgs ? <div className="validation-error">{this.props.getTranslate('validation-AdsImgSize')}</div> : ''}
            </div>
            <div className="field-wrap__submit">
              <button className="btn btn_size_lg" type="button" onClick={this.handlerNextPreview.bind(this)}>{this.props.getTranslate('addBonuses-buttonGoPreview')}</button>
            </div>
          </div>
        </div>
      </div>}
    </div>
    </div>
    {this.state.dateModal && <div className="date-modal">
      <div className="date-modal__overlay" onClick={this.handlerCloseGetDate.bind(this)}></div>
      <div className="date-modal__content">
        <div className="date-modal__header">
          <div className="date-modal__headline">{this.props.getTranslate('bunuses-Date')}</div>
          <button className="date-modal__close-modal" type="button" onClick={this.handlerCloseGetDate.bind(this)}>
            <svg className="date-modal__close-modal-icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <div className="date-fields date-fields__simple">
          <div className={`date-field ${this.state.chooseDateType === 'start' ? 'date-field__border' : ''}`} onClick={this.chooseDateHandler.bind(this, 'start')}>
            <input className="date-field__date" type="text" maxLength="10" placeHolder="dd.mm.yyyy" value={this.state.finishStartDate ? this.state.finishStartDate.replace(/-/g, '.') : ''} onKeyPress={this.onKeyPress.bind(this)} onChange={this.handlerDateStart.bind(this)} />
          </div>
          <div className={`date-field ${this.state.chooseDateType === 'finish' ? 'date-field__border' : ''}`} onClick={this.chooseDateHandler.bind(this, 'finish')}>
            <input className="date-field__date" type="text" maxLength="10" placeHolder="dd.mm.yyyy" value={this.state.dateEndValue ? this.state.dateEndValue : (this.state.finishEndDate ? this.state.finishEndDate.replace(/-/g, '.') : '')} onKeyPress={this.onKeyPress.bind(this)} onChange={this.handlerDateEnd.bind(this)} />
          </div>
        </div>
        <div id="calendar" className="calendar">
          <div className="calendar__info">
            <button className="calendar__month-nav" type="button" onClick={this.handlerPrevMonth.bind(this)}>
              <svg className="calendar__month-nav-icon">
                <use href={`${sprite}#chevron-left`}></use>
              </svg>
            </button>
            <div className="calendar__month-year">{this.getMonthName(this.state.date.getMonth())}, {this.state.date.getFullYear()}</div>
            <button className="calendar__month-nav" type="button" onClick={this.handlerNextMonth.bind(this)}>
              <svg className="calendar__month-nav-icon">
                <use href={`${sprite}#chevron-right`}></use>
              </svg>
            </button>
          </div>
          <table className="calendar-table">
            <thead className="calendar-table__head">
              <tr>
                <th>{this.props.getTranslate('modal-calendarDayWeekMonday')}</th>
                <th>{this.props.getTranslate('modal-calendarDayWeekTuesday')}</th>
                <th>{this.props.getTranslate('modal-calendarDayWeekWednesday')}</th>
                <th>{this.props.getTranslate('modal-calendarDayWeekThursday')}</th>
                <th>{this.props.getTranslate('modal-calendarDayWeekFriday')}</th>
                <th>{this.props.getTranslate('modal-calendarDayWeekSutarday')}</th>
                <th>{this.props.getTranslate('modal-calendarDayWeekSunday')}</th>
              </tr>
            </thead>
            <tbody className="calendar-table__dates">{tableDate}</tbody>
          </table>
        </div>
        <div className="date-modal__nav">
          <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="button" onClick={this.setDateInput.bind(this)}>{this.props.getTranslate('modal-calendarAddBtn')}</button>
        </div>
      </div>
    </div>}
          {this.state.modalEditCategory ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <div className="modal-header">
            <h2 className="modal-headline">{this.props.getTranslate('modalCategories-EditTitle')}</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          <div>
          <div className="edit-category__add-category">
            <button className="btn btn_width_100 btn_theme_transparent btn_size_lg" onClick={this.handlerCreateCategory.bind(this)}>{this.props.getTranslate('modalCategories-addButton')}</button>
          </div>

          {this.state.newGategories ? <>
          {this.state.newGategories.map((item, index) => {
            return     <div className="categories-faq-wgt" key={index}>
                      <div className="categories-faq-wgt__inner">
                        <input className={`categories-faq-wgt__input ${(this.state.errorEmptyNewCategory && item === '') || (this.state.acceptNewCategoriesIndex.indexOf(index) !== -1 && item === '')  ? 'categories-faq-wgt__input_error' : ''}`} onChange={this.handerNewGategoryName.bind(this, index)} value={item} />
                        <div className="categories-faq-wgt__nav">
                          <button className={`categories-faq-wgt__nav-btn categories-faq-wgt__nav-btn-add ${item !== '' ? 'categories-faq-wgt__nav-btn-add_error' : ''}`} type="button" onClick={this.handlerAddCreateCategory.bind(this, index)}>
                            <svg>
                              <use href={`${sprite}#check-icon`}></use>
                            </svg>
                          </button>
                          <button className="categories-faq-wgt__nav-btn" type="button">
                            <svg>
                              <use href={`${sprite}#edit`}></use>
                            </svg>
                          </button>
                          <button className="categories-faq-wgt__nav-btn" type="button" onClick={this.handlerDeleteCategory.bind(this, index)}>
                            <svg>
                              <use href={`${sprite}#delete`}></use>
                            </svg>
                          </button>
                        </div>
                        </div>
                        {(this.state.errorEmptyNewCategory && item === '') || (this.state.acceptNewCategoriesIndex.indexOf(index) !== -1 && item === '') ? <div className="validation-error">Обязательное поле</div> : ''}
                      </div>
          })}</> : ''}

          {this.state.categoriesResult.map((item, index) => {
            return     <div className="categories-faq-wgt" key={index}>
            <div className="categories-faq-wgt__inner">
                        {this.state.editCategoriesIndex.indexOf(index) !== -1 ? <input className={`categories-faq-wgt__input ${(this.state.errorEmptyCategory && item.label === '') || (this.state.acceptEditCategoriesIndex.indexOf(index) !== -1 && item.label === '') ? 'categories-faq-wgt__input_error' : ''}`} value={item.label} onChange={this.handlerEditCategoryNameValue.bind(this, index)} /> : <div className="categories-faq-wgt__name">{item.label}</div>}
                        <div className="categories-faq-wgt__nav">
                        {this.state.editCategoriesIndex.indexOf(index) !== -1 ? <button className={`categories-faq-wgt__nav-btn categories-faq-wgt__nav-btn-add ${this.state.acceptEditCategoriesIndexChange.indexOf(index) !== -1 || this.state.errorAcceptEditCategories ? 'categories-faq-wgt__nav-btn-add_error' : ''}`} type="button" onClick={this.handlerSaveCategoryName.bind(this, index)}>
                          <svg>
                            <use href={`${sprite}#check-icon`}></use>
                          </svg>
                        </button> : ''}
                          <button className="categories-faq-wgt__nav-btn" onClick={this.handlerEditCategoryName.bind(this, index)} type="button">
                            <svg>
                              <use href={`${sprite}#edit`}></use>
                            </svg>
                          </button>
                          <button className="categories-faq-wgt__nav-btn" type="button" onClick={this.handlerDeleteCategoryById.bind(this, item.value, index)}>
                            <svg>
                              <use href={`${sprite}#delete`}></use>
                            </svg>
                          </button>
                        </div>
                        </div>
                        {(this.state.errorEmptyCategory && item.label === '') || (this.state.acceptEditCategoriesIndex.indexOf(index) !== -1 && item.label === '') ? <div className="validation-error">Обязательное поле</div> : ''}
                      </div>
          })}

              <div className="field-wrap__submit">
                <button className="btn btn_size_lg btn_width_100 product-info__nav-btn" type="button" onClick={this.handlerSubmitCategories.bind(this)} disabled={this.state.newCategoriesLoadingSubmit ? true : false}>{this.props.getTranslate('modalCategories-SaveButton')}{this.state.newCategoriesLoadingSubmit && <Loading />}</button>
              </div>
          </div>
        </div>
        </div> : ''}
        {this.state.modalDeleteEvents ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <div className="modal-header">
            <h2 className="modal-headline">{this.props.getTranslate('modal-deleteBonusesTitle')}</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
            <div className="delete-category-form__description">{this.props.getTranslate('modal-deleteBonusesDescription')}</div>
              <div className="field-wrap__submit">
                <Link className="btn btn_size_lg btn_theme_remove btn_width_100 user-info__nav-btn" to={this.props.getPermalink("/account/benefits")}>{this.props.getTranslate('removeModal-ButtonSubmit')}</Link>
              </div>
        </div>
        </div> : ''}
    </>;
  }
}
export default BonusesCreate;
