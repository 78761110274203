import React from "react";
import Loading from './components/Loading';
import { Link, useParams } from "react-router-dom";
import AccountHeader from './components/AccountHeader';
import ResultErrors from './components/ResultErrors';
import Navigation from './components/Navigation';
import Pagination from './components/Pagination';
import sprite from './media/icons.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
class ControlPanelAccessGroupEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      modalDisabled: false,
      modal: false,
      authorities: [],
      name: null,
      isLoadedSubmit: false,
      isLoaded: false,
      errors: null,
      error: false,
      token: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("token-admin") ? JSON.parse(localStorage.getItem("token-admin")) : JSON.parse('')) : ''
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modal: false
      }));
    }, 500);
  }
  componentDidMount() {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/users/roles/${this.props.id}`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState( {authorities: data.authorities ? data.authorities : [], name: data.name, isLoaded: true} )
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            isLoaded: true,
            error: true
          });
        })
      }
    })
  })
  }
  handlerStatusChecked(elem, event){
    let arr = this.state.authorities;
    let index = arr.indexOf(elem);
    if(index !== -1){
      arr.splice(index, 1);
    } else {
      arr.push(elem);
    }
    this.setState( {authorities: arr} );
  }
  handlerSubmit(event){
    event.preventDefault();
    let that = this;
    this.setState( {isLoadedSubmit: true} );
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/users/roles/${this.props.id}`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        "authorities": this.state.authorities,
        "name": this.state.name,
        "roleID": this.props.id
      })
    }).then(res => {
      if (res.status === 200) {
        that.setState({
          isLoadedSubmit: false
        });
      } else {
        res.json().then(function(data) {
          that.setState({
            isLoadedSubmit: false,
            errors: data.errors
          });
        })
      }
    })
  })
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  render() {
    let authoritiesData = '';
    if(this.state.error) {
      authoritiesData = <div className="errors-content">Ошибка</div>;
    } else if(!this.state.isLoaded){
      authoritiesData = <Loading />;
    } else {
      authoritiesData = <div className="account-content__content">
              <div className="account-wgt">
      <div className="group-access-wrap">
                        <div className="group-access-wgt">
                          <div className="group-access-wgt__inner">
                            <div className="group-access-wgt__headline">Компании</div>
                            <ul>
                              <li>
                                <div>
                                  <input className="styled-checkbox" id="styled-checkbox-0" checked={this.state.authorities && this.state.authorities.indexOf('COMPANIES_READ') != -1} onChange={this.handlerStatusChecked.bind(this, 'COMPANIES_READ')} type="checkbox" />
                                  <label htmlFor="styled-checkbox-0">
                                    <svg className="styled-checkbox__icon">
                                      <use href={`${sprite}#icon-view`}></use>
                                    </svg>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <input className="styled-checkbox" id="styled-checkbox-1" checked={this.state.authorities && this.state.authorities.indexOf('COMPANIES_WRITE') != -1} onChange={this.handlerStatusChecked.bind(this, 'COMPANIES_WRITE')} type="checkbox" />
                                  <label htmlFor="styled-checkbox-1">
                                    <svg className="styled-checkbox__icon">
                                      <use href={`${sprite}#edit-icon`}></use>
                                    </svg>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <input className="styled-checkbox" id="styled-checkbox-2" checked={this.state.authorities && this.state.authorities.indexOf('COMPANIES_DELETE') != -1} onChange={this.handlerStatusChecked.bind(this, 'COMPANIES_DELETE')} type="checkbox" />
                                  <label htmlFor="styled-checkbox-2">
                                    <svg className="styled-checkbox__icon">
                                      <use href={`${sprite}#delete-icon`}></use>
                                    </svg>
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="group-access-wgt">
                          <div className="group-access-wgt__inner">
                            <div className="group-access-wgt__headline">Оплаты</div>
                            <ul>
                              <li>
                                <div>
                                  <input className="styled-checkbox" id="styled-checkbox-3" checked={this.state.authorities && this.state.authorities.indexOf('PAYMENTS_READ') != -1} onChange={this.handlerStatusChecked.bind(this, 'PAYMENTS_READ')} type="checkbox" />
                                  <label htmlFor="styled-checkbox-3">
                                    <svg className="styled-checkbox__icon">
                                      <use href={`${sprite}#icon-view`}></use>
                                    </svg>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <input className="styled-checkbox" id="styled-checkbox-4" checked={this.state.authorities && this.state.authorities.indexOf('PAYMENTS_WRITE') != -1} onChange={this.handlerStatusChecked.bind(this, 'PAYMENTS_WRITE')} type="checkbox" />
                                  <label htmlFor="styled-checkbox-4">
                                    <svg className="styled-checkbox__icon">
                                      <use href={`${sprite}#edit-icon`}></use>
                                    </svg>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <input className="styled-checkbox" id="styled-checkbox-5" checked={this.state.authorities && this.state.authorities.indexOf('PAYMENTS_DELETE') != -1} onChange={this.handlerStatusChecked.bind(this, 'PAYMENTS_DELETE')} type="checkbox" />
                                  <label htmlFor="styled-checkbox-5">
                                    <svg className="styled-checkbox__icon">
                                      <use href={`${sprite}#delete-icon`}></use>
                                    </svg>
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="group-access-wgt">
                          <div className="group-access-wgt__inner">
                            <div className="group-access-wgt__headline">Переводы</div>
                            <ul>
                              <li>
                                <div>
                                  <input className="styled-checkbox" id="styled-checkbox-6" checked={this.state.authorities && this.state.authorities.indexOf('PERMISSION_READ') != -1} onChange={this.handlerStatusChecked.bind(this, 'PERMISSION_READ')} type="checkbox" />
                                  <label htmlFor="styled-checkbox-6">
                                    <svg className="styled-checkbox__icon">
                                      <use href={`${sprite}#icon-view`}></use>
                                    </svg>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <input className="styled-checkbox" id="styled-checkbox-7" checked={this.state.authorities && this.state.authorities.indexOf('PERMISSION_WRITE') != -1} onChange={this.handlerStatusChecked.bind(this, 'PERMISSION_WRITE')} type="checkbox" />
                                  <label htmlFor="styled-checkbox-7">
                                    <svg className="styled-checkbox__icon">
                                      <use href={`${sprite}#edit-icon`}></use>
                                    </svg>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <input className="styled-checkbox" id="styled-checkbox-8" checked={this.state.authorities && this.state.authorities.indexOf('PERMISSION_DELETE') != -1} onChange={this.handlerStatusChecked.bind(this, 'PERMISSION_DELETE')} type="checkbox" />
                                  <label htmlFor="styled-checkbox-8">
                                    <svg className="styled-checkbox__icon">
                                      <use href={`${sprite}#delete-icon`}></use>
                                    </svg>
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="group-access-wgt">
                          <div className="group-access-wgt__inner">
                            <div className="group-access-wgt__headline">Поддержка</div>
                            <ul>
                              <li>
                                <div>
                                  <input className="styled-checkbox" id="styled-checkbox-9" checked={this.state.authorities && this.state.authorities.indexOf('SUPPORT_READ') != -1} onChange={this.handlerStatusChecked.bind(this, 'SUPPORT_READ')} type="checkbox" />
                                  <label htmlFor="styled-checkbox-9">
                                    <svg className="styled-checkbox__icon">
                                      <use href={`${sprite}#icon-view`}></use>
                                    </svg>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <input className="styled-checkbox" id="styled-checkbox-10" checked={this.state.authorities && this.state.authorities.indexOf('SUPPORT_WRITE') != -1} onChange={this.handlerStatusChecked.bind(this, 'SUPPORT_WRITE')} type="checkbox" />
                                  <label htmlFor="styled-checkbox-10">
                                    <svg className="styled-checkbox__icon">
                                      <use href={`${sprite}#edit-icon`}></use>
                                    </svg>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <input className="styled-checkbox" id="styled-checkbox-11" checked={this.state.authorities && this.state.authorities.indexOf('SUPPORT_DELETE') != -1} onChange={this.handlerStatusChecked.bind(this, 'SUPPORT_DELETE')} type="checkbox" />
                                  <label htmlFor="styled-checkbox-11">
                                    <svg className="styled-checkbox__icon">
                                      <use href={`${sprite}#delete-icon`}></use>
                                    </svg>
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>                <div className="field-wrap__submit">
                  <button className="btn btn_size_lg" type="button" onClick={this.handlerSubmit.bind(this)} disabled={this.state.isLoadedSubmit ? true : false}>Сохранить{this.state.isLoadedSubmit && <Loading />}</button>
                </div>
              </div>            </div>

    }
    return <>
      <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
      <AccountHeader status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
      <div className="account-wrap account-wrap__admin">
        <Navigation status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
        <div className="account-content">
          <div className="account-content__inner">
            <div className="account-content__header">
              <div className="account-content__header-inner">
                <Link className="account-content__header-back-link" to="/controlpanel/group">
                  <svg className="account-content__header-back-icon">
                    <use href={`${sprite}#arrow-back`}></use>
                  </svg>
                </Link>
                <h1 className="account-content__headline">Додати</h1>
              </div>
              <a className="header__language header__language-content" href="#">RU</a>
            </div>
            {authoritiesData}
          </div>
        </div>
      </div>
    </>
  }
}
export default (props) => <ControlPanelAccessGroupEdit {...useParams()} {...props} />
