import React from "react";
import GetEmoji from './components/GetEmoji';
import { Scrollbars } from 'react-custom-scrollbars-2';
import AccountHeader from './components/AccountHeader';
import AccountContentHeader from './components/AccountContentHeader';
import AccountNav from './components/AccountNav';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import Loading from './components/Loading';
import { useParams, Navigate } from "react-router-dom";
import ResultErrors from './components/ResultErrors';
import Select from 'react-select';
import CalendarDropdown from './components/CalendarDropdown';
import sprite from './media/icons.svg';
import member_1 from './media/avatar-1.png';
import { getAuth } from "firebase/auth";
let arrayMoveImmutable;
if (typeof window !== 'undefined') {
  const { arrayMoveImmutable: clientArrayMoveImmutable } = require('array-move');
  arrayMoveImmutable = clientArrayMoveImmutable;
}
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const repeatChoose = [
  { value: '1', label: 'Ежемесячно' },
  { value: '2', label: 'Еженедельно' },
  { value: '3', label: 'Ежедневно' }
];
const choosePoint = [
  { value: 'RATE', label: 'Оценка от 1 до 5' },
  { value: 'YES_NO', label: 'Да / Нет' },
  { value: 'YES_NO_DONT_KNOW', label: 'Да / Нет / Незнаю' },
  { value: 'FREE', label: 'Поле ввода' }
];
const option = {
      "answerType": "SINGLE",
      "options": [
        {
          "answerOptionID": 0,
          "answerOptionType": "RATE",
          "name": "string",
          "priority": 0,
          "userAnswers": [
            {
              "answer": "string",
              "optionID": 0,
              "userID": 0,
              "userName": "string"
            }
          ]
        }
      ],
      "priority": 0,
      "question": ""
    };
    const DragHandle = SortableHandle(() => <button className="question-wgt-item__nav-btn" type="button">
      <svg className="question-wgt-item__nav-icon question-wgt-item__nav-icon_sort">
        <use href={`${sprite}#lines-icon`}></use>
      </svg>
    </button>)
    const DragHandleQuestion = SortableHandle(() => <button className="question-wgt-item__nav-btn" type="button">
      <svg className="question-wgt-item__nav-icon question-wgt-item__nav-icon_sort">
        <use href={`${sprite}#lines-icon`}></use>
      </svg>
    </button>)
    const DragHandleEdit = SortableHandle(() => <button className="question-wgt-item__nav-btn" type="button">
      <svg className="question-wgt-item__nav-icon question-wgt-item__nav-icon_sort">
        <use href={`${sprite}#lines-icon`}></use>
      </svg>
    </button>)
    const SortableItem = SortableElement(({item, value, type, handlerOnChange, handlerRemove, getTranslate}) => <div className="field-wrap question-form__points-item" key={item}>
                        {type === 'SINGLE' ?
                        <div className="question-form__points-item-icon">
                        <svg className="question-form__points-item-checked-icon">
                          <use href={`${sprite}#radio-icon`}></use>
                        </svg>
                        </div> :
                        <div className="question-form__points-item-icon">
                          <svg className="question-form__points-item-checked-icon">
                            <use href={`${sprite}#checkbox-icon`}></use>
                          </svg>
                        </div>}
                          <input className="field-wrap__input" type="text" value={value.name} onChange={handlerOnChange.bind(this, item)} placeholder={getTranslate('polls-QuestionOptionItemPlaceholder')} />
                          <div className="question-form__points-item-nav">
                            <button className="question-wgt-item__nav-btn" type="button" onClick={handlerRemove.bind(this, item)}>
                              <svg className="question-wgt-item__nav-icon question-wgt-item__nav-icon_close">
                                <use href={`${sprite}#close-icon`}></use>
                              </svg>
                            </button>
                            <DragHandle />
                          </div>
                        </div>);

                        const SortableItemEdit = SortableContainer(({index, itemOption, value, handlerEditQuestionOptions, handlerDeleteEdit, item, getTranslate}) => {
                        return (
                          <div className="field-wrap question-form__points-item" key={index}>
                          {value.answerType === 'SINGLE' ?
                                  <div className="question-form__points-item-icon">
                                    <svg className="question-form__points-item-checked-icon">
                                      <use href={`${sprite}#radio-icon`}></use>
                                    </svg>
                                  </div> :
                                  <div className="question-form__points-item-icon">
                                    <svg className="question-form__points-item-checked-icon">
                                      <use href={`${sprite}#checkbox-icon`}></use>
                                    </svg>
                                  </div>}
                                  <input className="field-wrap__input" type="text" value={itemOption.name} onChange={handlerEditQuestionOptions.bind(this, item, index)} placeholder={getTranslate('polls-QuestionOptionItemPlaceholder')} />
                                  <div className="question-form__points-item-nav">
                                    <button className="question-wgt-item__nav-btn" type="button" onClick={handlerDeleteEdit.bind(this, item, index)}>
                                      <svg className="question-wgt-item__nav-icon question-wgt-item__nav-icon_close">
                                        <use href={`${sprite}#close-icon`}></use>
                                      </svg>
                                    </button>
                                    <DragHandleEdit />
                                  </div>
                                </div>
                            )});

                        const SortableListEdit = SortableContainer(({value, handlerEditQuestionOptions, handlerDeleteEdit, item, getTranslate}) => {
                          return (
                            <>
                            {value.options.map((itemOption, index) => {
                            return <SortableItemEdit key={index} index={index} itemOption={itemOption} value={value} handlerEditQuestionOptions={handlerEditQuestionOptions} handlerDeleteEdit={handlerDeleteEdit} item={item} getTranslate={getTranslate} />
                            })}
                            </>
                          );
                        });

                        const SortableItemQuestions = SortableElement(({item,
                          value,
                          removeQuestionNormal,
                          handlerEditQuestion,
                          activeQuestions,
                          handlerAddDescriptionEdit,
                          descriptionEditIds,
                          addDefaultPointEdit,
                          handlerEditQuestionHeadline,
                          handlerEditQuestionDescription,
                          handlerEditQuestionOptions,
                          handlerDropdownEdit,
                          dropDownEditIds,
                          handlerAnswerTypeEdit,
                          handlerDeleteEdit,
                          getTranslate}) =>
                            <div>{activeQuestions.indexOf(item) !== -1 ?
                              <div className="question-form question-form__border">
                                <div className="question-form__header">
                                  <div className="question-form__num">№{item + 1}</div>
                                </div>
                                <div className="field-wrap">
                                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="question-headline">{getTranslate('polls-QuestionOptionTitleLabel')}</label>
                                  <input id="question-headline" className="field-wrap__input" type="text" value={value.question} onChange={handlerEditQuestionHeadline.bind(this, item)} placeholder={getTranslate('polls-QuestionOptionTitlePlaceholder')} />
                                </div>
                                {descriptionEditIds.findIndex(x => x === value.questionID) !== -1 || value.description ? <div className="field-wrap">
                                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="question-description">{getTranslate('polls-QuestionOptionDescriptionLabel')}</label>
                                  <textarea id="question-description" className="field-wrap__input field-wrap__input_questions" value={value.description} onChange={handlerEditQuestionDescription.bind(this, item)} placeholder={getTranslate('polls-QuestionOptionDescriptionPlaceholder')} />
                                </div> : ''}
                                <button className="question-form__btn" type="button" onClick={handlerAddDescriptionEdit.bind(this, value.questionID)}>{descriptionEditIds.findIndex(x => x === value.questionID !== -1) || value.description ? getTranslate('polls-QuestionOptionDescriptionRemove') : getTranslate('polls-QuestionOptionDescriptionAdd')}</button>
                                <div className="question-form__points">
                                  <div className="question-form__points-header">
                                    <div className="question-form__points-header-inner">
                                      <label className="field-wrap__label field-wrap__label_theme_gray">{getTranslate('polls-QuestionOptionDropdownLabel')}</label>
                                      <div className="question-list-type">
                                      <label className="field-wrap__label field-wrap__label_theme_gray field-wrap__label-questions">{getTranslate('polls-QuestionOptionDropdownType')}</label>
                                      <div className="dropdown dropdown__account-survey dropdown__questions">
                                        <button className="dropdown__btn" type="button" onClick={handlerDropdownEdit.bind(this, value.questionID)}>
                                          <div className="dropdown__btn-name">{value.answerType === 'SINGLE' ? getTranslate('polls-QuestionOptionDropdownTypeSingle') : getTranslate('polls-QuestionOptionDropdownTypeMultiple')}</div>
                                          <svg className="dropdown__icon">
                                            <use href={`${sprite}#arrow-down`}></use>
                                          </svg>
                                        </button>
                                        <div className="tooltip-info">
                                          <span className="tooltip-info__icon">?</span>
                                          <div className="tooltip-info__content">
                                            <div className="arrow"></div>
                                            <p>{getTranslate('polls-QuestionOptionTooltipType')}</p>
                                          </div>
                                        </div>
                                        <div className={`dropdown-content ${dropDownEditIds.findIndex(x => x === value.questionID) !== -1 ? 'active' : ''}`}>
                                          <ul className="dropdown-nav">
                                            <li className="dropdown-nav__item">
                                              <button className={`dropdown-nav__link ${value.answerType === 'SINGLE' ? 'active' : ''}`} onClick={handlerAnswerTypeEdit.bind(this, 'SINGLE', item, value.questionID)} type="button">{getTranslate('polls-QuestionOptionDropdownTypeSingle')}</button>
                                            </li>
                                            <li className="dropdown-nav__item">
                                              <button className={`dropdown-nav__link ${value.answerType === 'MULTIPLE' ? 'active' : ''}`} onClick={handlerAnswerTypeEdit.bind(this, 'MULTIPLE', item, value.questionID)} type="button">{getTranslate('polls-QuestionOptionDropdownTypeMultiple')}</button>
                                            </li>
                                          </ul>
                                        </div>
                                        </div>
                                      </div>
                                    </div>
                                    <SortableListEdit value={value} handlerEditQuestionOptions={handlerEditQuestionOptions} handlerDeleteEdit={handlerDeleteEdit} item={item} getTranslate={getTranslate} />

                                    <button className="question-form__btn" type="button" onClick={addDefaultPointEdit.bind(this, value.questionID)}>Добавить вариант</button>
                                  </div>
                                </div>
                              </div>
                               :
                            <div className="question-wgt-item" onClick={handlerEditQuestion.bind(this, item)}>
                              <div className="question-wgt-item__name">№{item + 1} {value.question}</div>
                              <div className="question-wgt-item__nav">
                              <button className="question-wgt-item__nav-btn" type="button" onClick={removeQuestionNormal.bind(this, item)}>
                                <svg className="question-wgt-item__nav-icon question-wgt-item__nav-icon_delete">
                                  <use href={`${sprite}#delete`}></use>
                                </svg>
                              </button>
                              <DragHandleQuestion />
                              </div>
                            </div>}</div>);


    const SortableList = SortableContainer(({items, type, handlerOnChange, handlerRemove, getTranslate}) => {
      return (
        <div>
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} item={index} index={index} value={value} type={type} handlerOnChange={handlerOnChange} handlerRemove={handlerRemove} getTranslate={getTranslate} />
          ))}
        </div>
      );
    });
    const SortableListQuestions = SortableContainer(({items,
      removeQuestionNormal,
      handlerEditQuestion,
      activeQuestions,
      handlerAddDescriptionEdit,
      descriptionEditIds,
      addDefaultPointEdit,
      handlerEditQuestionHeadline,
      handlerEditQuestionDescription,
      handlerEditQuestionOptions,
      handlerDropdownEdit,
      dropDownEditIds,
      handlerAnswerTypeEdit,
      handlerDeleteEdit,
      getTranslate}) => {
      return (
        <div>
          {items.map((value, index) => (
            (items.length) !== index ? <SortableItemQuestions key={`item-${index}`}
            item={index}
            index={index}
            value={value}
            removeQuestionNormal={removeQuestionNormal}
            handlerEditQuestion={handlerEditQuestion}
            activeQuestions={activeQuestions}
            handlerAddDescriptionEdit={handlerAddDescriptionEdit}
            descriptionEditIds={descriptionEditIds}
            addDefaultPointEdit={addDefaultPointEdit}
            handlerEditQuestionHeadline={handlerEditQuestionHeadline}
            handlerEditQuestionDescription={handlerEditQuestionDescription}
            handlerEditQuestionOptions={handlerEditQuestionOptions}
            handlerDropdownEdit={handlerDropdownEdit}
            dropDownEditIds={dropDownEditIds}
            handlerAnswerTypeEdit={handlerAnswerTypeEdit}
            handlerDeleteEdit={handlerDeleteEdit}
            getTranslate={getTranslate} /> : ''
          ))}
        </div>
      );
    });
class SurveyEditDefault extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      successSubmit: false,
      getAllUsers: false,
      allUsers: false,
      getUsersIds: [],
      usersIds: [],
      isLoaded: false,
      isLoadedSubmitAddSurvey: false,
      dateModal: false,
      date: new Date(),
      fullYear: null,
      month: null,
      currentDate: new Date(),
      dates: [],
      countFirstElems: null,
      countEndedElems: null,
      pushElemsTotal: null,
      startDate: null,
      endDate:null,
      finishStartDate: null,
      finishEndDate: null,
      startTime: null,
      endTime: null,
      hoverDate: null,
      addQuestion: true,
      modalDisabled: false,
      modal: false,
      modalAddMembers: false,
      chooseMembers: [],
      chooseMembers2: [],
      memberItems: '',
      chooseDateFull: null,
      pointsElem: [1],
      pointsLength: false,
      surveyName: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("surveyName") ? localStorage.getItem("surveyName") : '') : '',
      surveyBonus: '',
      surveyDescription: '',
      chooseAllMembers: false,
      chooseMembersIds: [],
      users: [],
      usersGet: [],
      errorUser: null,
      isLoadedUser: false,
      anonymousSurvey: false,
      options: [option],
      totalUsers: null,
      addDescription: false,
      answerType: 'SINGLE',
      optionsDefault: [{
                "answerOptionID": 0,
                "answerOptionType": "CHECKBOX",
                "name": '',
                "priority": 0,
                "userAnswers": [
                  {
                    "answer": "string",
                    "optionID": 0,
                    "userID": 0,
                    "userName": "string"
                  }
                ]
              }],
      resultData: {},
      questionHeadline: '',
      questionDescription: '',
      optionName: '',
      searchValue: '',
      usersSearch: [],
      openEditQuestions: [],
      addDescriptionEditIds: [],
      dropDownEditIds: [],
      currencyCompany: (typeof window !== "undefined" && typeof document !== 'undefined') ? localStorage.getItem('currencyMt') : '',
      iterStart: null,
      iterStartSave: null,
      iterHover: null,
      countMonth: 1,
      countMonthSave: null,
      iterFinish: null,
      countMonthFinish: null,
      errors: null,
      error: false,
      diffInMonths: null,
      setInput: false,
      chooseDateType: 'finish',
      startDateCalendar: null,
      endDateCalendar: null,
      startTimeCalendar: '00:00',
      endTimeCalendar: '22:00',
      iterStartCalendar: null,
      iterFinishCalendar: null,
      weeklyDay: null,
      nthWeekday: null,
      dateNum: null,
      monthNum: null,
      invitationsData: []
    }
  }
  getNameDay(num){
    const days = [
      'неділя',
      'понеділок',
      'вівторок',
      'середа',
      'четвер',
      'п\'ятниця',
      'субота'
    ];
    return days[num];
  }
  getMonthsName(num){
    const monthes = [
      'січня',
      'лютого',
      'березня',
      'квітня',
      'травня',
      'червня',
      'липня',
      'серпня',
      'вересня',
      'жовтня',
      'листопада',
      'грудня'
    ];
    return monthes[num];
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handlerDropdownEdit(id, event){
    let arr = this.state.dropDownEditIds;
    let indexArr = arr.findIndex(x => x === id);
    if(indexArr !== -1){
      arr.splice(indexArr, 1);
    } else {
      arr = [...arr, id];
    }
    this.setState(prevState => ({
      dropDownEditIds: arr,
    }));
  }
  onSortEnd = ({oldIndex, newIndex}) => {
    let defaultOptions = arrayMoveImmutable(this.state.optionsDefault, oldIndex, newIndex)
    defaultOptions.map((item, index) => {
      item.priority = index
    });
    this.setState(prevState => ({
      optionsDefault: defaultOptions
    }));
  }
  onSortEndQuestions = ({oldIndex, newIndex}) => {
    let defaultOptions = this.state.resultData;
    let questions = defaultOptions.questions;
    let options = arrayMoveImmutable(questions, oldIndex, newIndex);
    options.map((item, index) => {
      item.priority = index
    });
    defaultOptions.questions = options;
    this.setState(prevState => ({
      resultData: defaultOptions
    }));
  }
  dateFormating(start, end){
    let dateStart = new Date(Date.parse(start));
    let dateEnd = new Date(Date.parse(end));
    let valueDateStart = dateStart.getDate();
    let valueMonthStart = dateStart.getMonth() + 1;
    let valueYearStart = dateStart.getFullYear();
    let valueHoursStart = dateStart.getHours();
    let valueMinutesStart = dateStart.getMinutes();
    let valueDateEnd = dateEnd.getDate();
    let valueMonthEnd = dateEnd.getMonth() + 1;
    let valueYearEnd = dateEnd.getFullYear();
    let valueHoursEnd = dateEnd.getHours();
    let valueMinutesEnd = dateEnd.getMinutes();
    if(valueDateStart < 10){
      valueDateStart = '0' + valueDateStart;
    }
    if(valueMonthStart < 10){
      valueMonthStart = '0' + valueMonthStart;
    }
    if(valueHoursStart < 10){
      valueHoursStart = '0' + valueHoursStart;
    }
    if(valueMinutesStart < 10){
      valueMinutesStart = '0' + valueMinutesStart;
    }
    if(valueDateEnd < 10){
      valueDateEnd = '0' + valueDateEnd;
    }
    if(valueMonthEnd < 10){
      valueMonthEnd = '0' + valueMonthEnd;
    }
    if(valueHoursEnd < 10){
      valueHoursEnd = '0' + valueHoursEnd;
    }
    if(valueMinutesEnd < 10){
      valueMinutesEnd = '0' + valueMinutesEnd;
    }
    if(end){
      return valueDateStart + '.' + valueMonthStart + '.' + valueYearStart + ' ' + valueHoursStart + ':' + valueMinutesStart + ' - ' + valueDateEnd + '.' + valueMonthEnd + '.' + valueYearEnd + ' ' + valueHoursEnd + ':' + valueMinutesEnd;
    } else {
      return valueDateStart + '.' + valueMonthStart + '.' + valueYearStart + ' ' + valueHoursStart + ':' + valueMinutesStart;
    }
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/surveys/${that.props.id}`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            errors: data.errors,
            error: true
          });
        })
      } else {
        res.json().then(function(data) {
          let startDate = new Date(data.startTime);
          let startDateFull = startDate.getDate() + '.' + (startDate.getMonth() + 1) + '.' + startDate.getFullYear();
          let weeklyDayStart = startDate.getDay();
          let nthWeekdayStart = that.getNthWeekdayOfMonth(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));

          let finishDate = new Date(data.endTime);
          let finishDateFull = finishDate.getDate() + '.' + (finishDate.getMonth() + 1) + '.' + finishDate.getFullYear();
          let weeklyDayFinish = finishDate.getDay();
          let nthWeekdayFinish = that.getNthWeekdayOfMonth(new Date(finishDate.getFullYear(), finishDate.getMonth(), finishDate.getDate()));

          that.setState( {
            startDateCalendar: startDateFull,
            endDateCalendar: finishDateFull,
            startTimeCalendar: (startDate.getHours() < 10 ? '0' + startDate.getHours() : startDate.getHours()) + ':' + (startDate.getMinutes() < 10 ? '0' + startDate.getMinutes() : startDate.getMinutes()),
            endTimeCalendar: (finishDate.getHours() < 10 ? '0' + finishDate.getHours() : finishDate.getHours()) + ':' + (finishDate.getMinutes() < 10 ? '0' + finishDate.getMinutes() : finishDate.getMinutes()),
            weeklyDay: weeklyDayStart,
            nthWeekday: nthWeekdayStart - 1,
            dateNum: startDate.getDate(),
            monthNum: startDate.getMonth()
          } );
          let resultObj = data;
          let re = /-/gi;
            that.setState({
              isLoaded: true,
              resultData: data,
              allUsers: false,
              chooseDateFull: that.dateFormating(data.startTime, data.endTime),
              endTime: data.endTime,
              startTime: data.startTime,
              oldDateEnd: data.endTime,
              oldDateStart: data.startTime,
              invitationsData: data.invitations
            });
            fetch(`${that.props.queryLink}/api/v1/teams/search/users`, {
              method: 'GET',
              headers: myHeaders
            }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors
                  });
                })
              } else {
                res.json().then(function(data) {
                  let users = data.map(x => x.users);
                  let usersIDs = users.map(x => {
                    return x.map(id => {
                      return {id: id.userID, name: id.name};
                    })
                  });
                  var result2 = [].concat(...usersIDs);
                  var usersActive = result2.map(user => {
                    if(resultObj.users.indexOf(user.id) !== -1){
                      return user;
                    }
                  });
                  usersActive = usersActive.filter(function(x) {
                       return x !== undefined;
                  });
                  that.setState({
                    usersGet: usersActive,
                    getUsersIds: usersActive
                  });
                })
              }
            })
            })
      }
    })
  })
  }
  handlerAddMembers (event) {
    event.preventDefault();
    let that = this;
    if(event.target.tagName !== 'SPAN' && event.target.tagName !== 'svg'){
      this.setState({
        modal: true,
        modalAddMembers: true,
        users: [],
        isLoadedUser: false,
        errorUsers: null
      });
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/teams/search/users`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              isLoadedUser: true,
              errorUsers: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              isLoadedUser: true,
              users: data
            })
          });
        }
      })
    })
    }
  }
  handlerGetDate (event) {
    event.preventDefault();
    let dateFull = new Date(Date.parse(this.state.oldDateEnd));
    let dateFull2 = new Date(Date.parse(this.state.oldDateStart));

    let dateEnd = new Date(this.state.endTime);
    let dateStart = new Date(this.state.startTime);
    const diffInMonths = (dateFull.getFullYear() - dateFull2.getFullYear()) * 12 + (dateFull.getMonth() - dateFull2.getMonth());
    let fullYear = dateFull.getFullYear();
    let month = dateFull.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      diffInMonths: diffInMonths,
      dateModal: !prevState.dateModal,
      dates: arr,
      fullYear: fullYear,
      pushElemsTotal: pushElemsNum,
      countFirstElems: unshiftElemsNum,
      date: new Date(dateFull.getFullYear(), dateFull.getMonth(), dateFull.getDate(), dateFull.getHours(), dateFull.getMinutes(), dateFull.getSeconds(), '0'),
      iterStart: diffInMonths === 0 ? unshiftElemsNum + dateFull2.getDate() : 1,
      iterStartSave: diffInMonths === 0 ? unshiftElemsNum + dateFull2.getDate() : 1,
      iterFinish: unshiftElemsNum + dateFull.getDate(),
      countMonth: diffInMonths === 0 ? 1 : diffInMonths,
      countMonthFinish: diffInMonths === 0 ? 1 : diffInMonths,
      countMonthSave: diffInMonths === 0 ? 1 : 0,
      month: month,
      finishStartDate: (dateFull2.getDate() < 10 ? '0' + dateFull2.getDate() : dateFull2.getDate()) + '.' + (dateFull2.getMonth() + 1 < 10 ? '0' + (dateFull2.getMonth() + 1) : dateFull2.getMonth() + 1) + '.' + dateFull2.getFullYear(),
      finishEndDate:  (dateFull.getDate() < 10 ? '0' + dateFull.getDate() : dateFull.getDate()) + '.' + (dateFull.getMonth() + 1 ? '0' + (dateFull.getMonth() + 1) : dateFull.getMonth() + 1) + '.' + dateFull.getFullYear(),
      startTime: (dateFull2.getHours() < 10 ? '0' + dateFull2.getHours() : dateFull2.getHours()) + ':' + (dateFull2.getMinutes() < 10 ? '0' + dateFull2.getMinutes() : dateFull2.getMinutes()),
      endTime: (dateFull.getHours() < 10 ? '0' + dateFull.getHours() : dateFull.getHours())  + ':' + (dateFull.getMinutes() < 10 ? '0' + dateFull.getMinutes() : dateFull.getMinutes()),
    }));
  }
  getLastDayOfMonth (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDate();
  }
  getUnshiftElemsNum (year, month) {
    let jsDayNum = this.getFirstWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return realDayNum - 1;
  }
  getPushElemsNum (year, month) {
    let jsDayNum = this.getLastWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return 7 - realDayNum;
  }
  createArr (from, to) {
    const arr = [];
    for(let i = from; i <= to; i++) {
      arr.push(i);
    }
    return arr;
  }
  unshiftElems (num, lastDayPrevMonth, arr) {
    this.setState(prevState => ({
      countFirstElems: num
    }));
    let count =  lastDayPrevMonth - num;
    for(let i = lastDayPrevMonth; i > count; i--) {
      arr.unshift(i);
    }
    return arr;
  }
  pushElems (num, arr) {
    this.setState(prevState => ({
      countEndedElems: num
    }));
    for(let i = 1; i <= num; i++) {
      arr.push(i);
    }
    return arr;
  }
  chunkArr (num, arr) {
    let result = [];
    let chunk = [];
    let iterCount = arr.length / num;
    for(let i = 0; i <iterCount; i++) {
      chunk = arr.splice(0, num);
      result.push(chunk);
    }
    return result;
  }
  getRealDayOfWeekNum (jsNumOfDay) {
    if(jsNumOfDay === 0){
      return 7;
    } else {
      return jsNumOfDay;
    }
  }
  getFirstWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month, 1);
    return date.getDay();
  }
  getLastWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDay();
  }
  getMonthName (num) {
    const monthes = [
      this.props.getTranslate('modal-calendarMonthNameJan'),
      this.props.getTranslate('modal-calendarMonthNameFeb'),
      this.props.getTranslate('modal-calendarMonthNameMar'),
      this.props.getTranslate('modal-calendarMonthNameApr'),
      this.props.getTranslate('modal-calendarMonthNameMay'),
      this.props.getTranslate('modal-calendarMonthNameJun'),
      this.props.getTranslate('modal-calendarMonthNameJul'),
      this.props.getTranslate('modal-calendarMonthNameAug'),
      this.props.getTranslate('modal-calendarMonthNameSep'),
      this.props.getTranslate('modal-calendarMonthNameOct'),
      this.props.getTranslate('modal-calendarMonthNameNov'),
      this.props.getTranslate('modal-calendarMonthNameDec')
    ];
    return monthes[num];
  }
  handlerCloseGetDate (event) {
    event.preventDefault();
    this.setState(prevState => ({
      dateModal: false
    }));
  }
  handlerPrevMonth (event) {
    event.preventDefault();
    let dateFull2 = new Date(Date.parse(this.state.oldDateStart));
    let dateFull = new Date(Date.parse(this.state.oldDateEnd));
    let fullYear2 = dateFull2.getFullYear();
    let month2 = dateFull2.getMonth() - 1;
    let unshiftElemsNum2 = this.getUnshiftElemsNum(fullYear2, month2);
    let date = this.state.date;
    let fullYear = date.getFullYear();
    let month = date.getMonth() - 1;
    let dateDay = 1;
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    if(this.state.diffInMonths === 0){
      this.setState(prevState => ({
        iterStart: unshiftElemsNum + dateFull2.getDate(),
        iterStartSave: unshiftElemsNum + dateFull2.getDate(),
      }))
    } else {
      this.setState(prevState => ({
        iterStart: prevState.countMonth > prevState.countMonthFinish ? 1 : unshiftElemsNum + dateFull2.getDate(),
        iterStartSave: prevState.countMonth > prevState.countMonthFinish ? 1 : unshiftElemsNum + dateFull2.getDate(),
      }))
    }
    this.setState(prevState => ({
      date: new Date(fullYear, month, dateDay),
      dates: arr,
      fullYear: fullYear,
      month: month,
      countMonth: Number(prevState.countMonth) - 1
    }));
  }
  handlerNextMonth (event) {
    event.preventDefault();
    let dateFull2 = new Date(Date.parse(this.state.oldDateStart));
    let dateFull = new Date(Date.parse(this.state.oldDateEnd));
    let date = new Date(this.state.date.getFullYear(), this.state.date.getMonth() + 1, this.state.date.getDate());
    let fullYear = date.getFullYear();
    let month = date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    if(this.state.diffInMonths === 0){
      this.setState(prevState => ({
        iterStart: unshiftElemsNum + dateFull2.getDate(),
        iterStartSave: unshiftElemsNum + dateFull2.getDate(),
      }))
    } else {
      this.setState(prevState => ({
        iterStart: prevState.countMonth > prevState.countMonthFinish ? 1 : unshiftElemsNum + dateFull2.getDate(),
        iterStartSave: prevState.countMonth > prevState.countMonthFinish ? 1 : unshiftElemsNum + dateFull2.getDate(),
      }))
    }
    this.setState(prevState => ({
      date: date,
      dates: arr,
      fullYear: fullYear,
      month: month,
      countMonth: Number(prevState.countMonth) + 1,
      iterStart: Number(prevState.countMonth) >= Number(prevState.countMonthSave) ? '0' : Number(prevState.iterStartSave)
    }));
  }
  chooseDate (iterStart, nextMonth, prevMonth, event) {
    event.preventDefault();
    let month = Number(this.state.month) + 1;
    let year = this.state.fullYear;
    if(nextMonth){
      month = Number(month) + 1;
      if(month === 13){
        month = 1;
        year = year + 1;
      }
    }
    if(prevMonth){
      month = Number(month) - 1;
      if(month === 0){
        month = 12;
        year = year - 1;
      }
    }
    if(!this.state.finishStartDate){
      this.setState(prevState => ({
        chooseDateType: 'finish',
        startDate: year + '-' + month + '-' + event.target.innerHTML,
        finishStartDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '.' + (month < 10 ? '0' + month : month) + '.' + year,
        iterStart: iterStart,
        iterStartSave: iterStart,
        countMonthSave: prevState.countMonth,
        oldDateStart: year + '-' + month + '-' + event.target.innerHTML
      }));
    } else if(this.state.chooseDateType === 'start') {
      this.setState(prevState => ({
        chooseDateType: 'finish',
        startDate: year + '-' + month + '-' + event.target.innerHTML,
        finishStartDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '.' + (month < 10 ? '0' + month : month) + '.' + year,
        iterStart: iterStart,
        iterStartSave: iterStart,
        countMonthSave: prevState.countMonth,
        oldDateStart: year + '-' + month + '-' + event.target.innerHTML
      }));
    } else if(this.state.chooseDateType === 'finish'){
      this.setState(prevState => ({
        endDate: new Date(prevState.startDate) > new Date(year + '-' + month + '-' + event.target.innerHTML) ? null : year + '-' + month + '-' + event.target.innerHTML,
        finishEndDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '.' + (month < 10 ? '0' + month : month) + '.' + year,
        iterFinish: iterStart,
        countMonthFinish: prevState.countMonth,
        oldDateEnd: new Date(prevState.startDate) > new Date(year + '-' + month + '-' + event.target.innerHTML) ? null : year + '-' + month + '-' + event.target.innerHTML
      }));
    } else {
      this.setState(prevState => ({
        endDate: new Date(prevState.startDate) > new Date(year + '-' + month + '-' + event.target.innerHTML) ? null : year + '-' + month + '-' + event.target.innerHTML,
        finishEndDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '.' + (month < 10 ? '0' + month : month) + '.' + year,
        iterFinish: iterStart,
        countMonthFinish: prevState.countMonth,
        oldDateEnd: new Date(prevState.startDate) > new Date(year + '-' + month + '-' + event.target.innerHTML) ? null : year + '-' + month + '-' + event.target.innerHTML
      }));
    }
  }
  chooseDateHover (iterHover, event) {
    event.preventDefault();
    if(this.state.startDate){
      this.setState(prevState => ({
        iterHover: iterHover
      }));
    }
  }
  chooseEndHover (event) {
    event.preventDefault();
    this.setState(prevState => ({
      iterHover: null
    }));
  }
  onKeyPress (event) {
    let input = event.target;
    if(event.charCode < 47 || event.charCode > 57) {
      event.preventDefault();
    }
    var len = input.value.length;
    if(len !== 1 || len !== 3) {
      if(event.charCode == 47) {
        event.preventDefault();
      }
    }
    if(len === 2) {
      input.value += '.';
    }
    if(len === 5) {
      input.value += '.';
    }
  }
  onKeyPress2 (event) {
    let input = event.target;
    if(event.charCode < 47 || event.charCode > 57) {
      event.preventDefault();
    }
    var len = input.value.length;
    if(len !== 1 || len !== 3) {
      if(event.charCode == 47) {
        event.preventDefault();
      }
    }
    if(len === 2) {
      input.value += ':';
    }
  }
  handlerAddQuestion (event) {
    event.preventDefault();
    let options =     {
      "answerType": "SINGLE",
      "options": this.state.optionsDefault,
      "priority": this.state.optionsDefault.length,
      "question": this.state.questionHeadline
    };
    let data = this.state.resultData;
    data.questions = [...data.questions, options];
    this.setState(prevState => ({
      addQuestion: true,
      resultData: data,
      questionHeadline: '',
      optionsDefault: [{
                "answerOptionID": 0,
                "answerOptionType": "CHECKBOX",
                "name": '',
                "priority": 0,
                "userAnswers": [
                  {
                    "answer": "string",
                    "optionID": 0,
                    "userID": 0,
                    "userName": "string"
                  }
                ]
              }]
    }));
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalAddMembers: false,
        modalDisabled: false,
        modalData: {}
      }));
    }, 500);
  }
  handlerChooseMember (member, id, event) {
    event.preventDefault();
    let arr = this.state.chooseMembers;
    let arrIds = this.state.chooseMembersIds;
    let index = arr.indexOf(member);
    let indexIds = arrIds.indexOf(id);
    if(index !== -1){
      arr.splice(index, 1);
    } else {
      arr.push(member);
    }
    if(indexIds !== -1){
      arrIds.splice(indexIds, 1);
    } else {
      arrIds.push(id);
    }
    this.setState(prevState => ({
      chooseMembers: arr,
      chooseMembersIds: arrIds
    }));
  }
  setChooseMembers (event) {
    event.preventDefault();
    let arr = this.state.chooseMembers;
    let result = arr.map((item, index) => {
      return <div className="select-modal__item" key={index} onClick={this.removeMember.bind(this, index)}>{item}<span>&#x2716;</span></div>
    });
    this.setState(prevState => ({
      memberItems: result
    }));
    this.handleModalDisabled();
  }

  removeMember (index, event) {
    event.preventDefault();
    let arr = this.state.chooseMembers;
    arr.splice(index, 1);
    let result = arr.map((item, index) => {
      return <div className="select-modal__item" key={index} onClick={this.removeMember.bind(this, index)}>{item}<span>&#x2716;</span></div>
    });
    this.setState(prevState => ({
      memberItems: result
    }));
  }
  handlerStartTime (event) {
    event.preventDefault();
    this.setState({
      startTime: event.target.value
    });
  }
  handlerEndTime (event) {
    event.preventDefault();
    this.setState({
      endTime: event.target.value
    });
  }
  setDateInput (event) {
    event.preventDefault();
    this.setState(prevState => ({
      chooseDateFull: prevState.finishStartDate + ' ' + (prevState.startTime ? prevState.startTime : '00:00') + ' - ' + (prevState.finishEndDate ? prevState.finishEndDate + ' ' + (prevState.endTime ? prevState.endTime : '23:59') : prevState.finishStartDate + ' ' + (prevState.endTime ? prevState.endTime : '23:59')),
      startTime: prevState.startTime ? prevState.startTime : '00:00',
      endTime: prevState.endTime ? prevState.endTime : '23:59',
      dateModal: !prevState.dateModal,
      setInput: true
    }));
    let resultData = this.state.resultData;
    resultData.startTime = this.state.finishStartDate + ' ' + (this.state.startTime ? this.state.startTime : '00:00');
    resultData.endTime = this.state.finishEndDate + ' ' + (this.state.endTime ? this.state.endTime : '23:59');
    this.setState(prevState => ({
      resultData: resultData
    }))
  }
  handlerAddPoint (event) {
    event.preventDefault();
    let arr = this.state.resultData;
    if(arr.questions.length < 4){
      arr.questions = [ ...arr.questions, {
      "answerType": "SINGLE",
      "options": [
        {
          "answerOptionID": 0,
          "answerOptionType": "RATE",
          "name": "string",
          "priority": 0,
          "userAnswers": null,
        }
      ],
      "priority": arr.questions.length,
      "question": ""
    } ];
      this.setState(prevState => ({
        resultData: arr
      }));
    } else {
      this.setState(prevState => ({
        pointsLength: true
      }));
    }
  }
  handlerSurveyName (event) {
    let surveyObj = this.state.resultData;
    surveyObj.title = event.target.value;
    this.setState({
      resultData: surveyObj
    });
  }
  handlerSurveyBonus (event) {
    let surveyObj = this.state.resultData;
    surveyObj.bonus = event.target.value;
    this.setState({
      resultData: surveyObj
    });
  }
  handlerSurveyDescription (event) {
    let surveyObj = this.state.resultData;
    surveyObj.description = event.target.value;
    this.setState({
      resultData: surveyObj
    });
  }
  handlerFinalSurveyDescription(event){
    let surveyObj = this.state.resultData;
    surveyObj.finalDescription = event.target.value;
    this.setState({
      resultData: surveyObj
    });
  }
  handlerAnonymousSurvey = () => {
  this.setState({
    anonymousSurvey: !this.state.anonymousSurvey,
  });
}
  handlerSubmitSurvey (event) {
    event.preventDefault();
    let that = this;
    this.setState({
      isLoadedSubmitAddSurvey: true
    });
    let obj = this.state.resultData;
    let questions = obj.questions;
    questions.map(item => {
      delete item.questionID;
    });
    delete obj.employeeCount;
    delete obj.enabled;
    delete obj.surveyStatus;
    let startDate = '';
    let startTime = '';
    let fullStartDate = '';
    let endDate = '';
    let endTime = '';
    let fullEndDate = '';
      obj.startTime = this.state.startDateCalendar.split('.').reverse().join('-') + ' ' + this.state.startTimeCalendar + ':00';
      obj.endTime = this.state.endDateCalendar ? (this.state.endDateCalendar.split('.').reverse().join('-') + ' ' + this.state.endTimeCalendar + ':00') : (this.state.startDateCalendar.split('.').reverse().join('-') + ' ' + this.state.endTimeCalendar + ':00');
      obj.startTime = new Date(obj.startTime).toISOString();
      obj.endTime = new Date(obj.endTime).toISOString();
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/surveys/${that.props.id}`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(obj)
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoadedSubmitAddSurvey: false,
              errors: data.errors
            });
          })
        } else {
          if(typeof window !== "undefined" && typeof document !== 'undefined') {
            localStorage.setItem("successSurvey", JSON.stringify({status: true, text: 'Успешно сохранено'}))
          }
          that.setState({
            successSubmit: true,
            isLoadedSubmitAddSurvey: false
          });
        }
      })
    })
  }
  handlerOptionName (index, event) {
    let options = this.state.resultData;
    options.questions[index].question = event.target.value;
    this.setState(prevState => ({
      options: options
    }));
  }
  handlerOptionType (index, event) {
    let options = this.state.resultData;
    options.questions[index].answerOptionType = event.value;
    options.questions[index].name = event.label;
    this.setState(prevState => ({
      options: options
    }));
  }
  handlerAddDescription (event) {
    event.preventDefault();
    this.setState(prevState => ({
      addDescription: !prevState.addDescription
    }));
  }
  handlerAnswerType (type, event) {
    let obj = this.state.optionsDefault;
    obj[obj.length - 1].answerType = type;
    this.handlerDropdown();
    this.setState(prevState => ({
      answerType: type
    }));
  }
  handlerDefaultQuestionHeadline (event) {
    this.setState(prevState => ({
      questionHeadline: event.target.value
    }));
  }
  handlerAddDefaultPoint (event) {
    event.preventDefault();
    let options =         {
          "answerOptionID": 0,
          "answerOptionType": "CHECKBOX",
          "name": this.state.optionName,
          "priority": this.state.optionsDefault.length,
          "userAnswers": [
            {
              "answer": "string",
              "optionID": 0,
              "userID": 0,
              "userName": "string"
            }
          ]
        };
    this.setState(prevState => ({
      optionsDefault: [...this.state.optionsDefault, options]
    }));
  }
  handlerPointDefaultName (index, event) {
    let options = this.state.optionsDefault;
    options[index].name = event.target.value;
    this.setState(prevState => ({
      optionsDefault: options
    }));
  }
  handlerToggleForm () {
    this.setState(prevState => ({
      addQuestion: !prevState.addQuestion
    }));
  }
  removeQuestionNormal (index, event) {
    event.preventDefault();
    let data = this.state.resultData;
    data.questions = data.questions.filter((_, i) => i !== index);
    this.setState(prevState => ({
      resultData: data
    }));
  }
  handlerRemoveDefaultPoint (index, event) {
    event.preventDefault();
    this.setState({
      optionsDefault: this.state.optionsDefault.filter((_, i) => i !== index)
    });
  }
  handlerGetAllUsers(){
    this.setState(prevState => ({
      getAllUsers: !prevState.getAllUsers,
      getUsersIds: [],
    }));
  }
  handlerToggleUser(index, name, id, event){
    if(this.state.getAllUsers){
      this.handlerRemoveAllUsers();
    }
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    let invitationsData = this.state.invitationsData;
    let invitationsIndex = invitationsData.findIndex(x => x.userID === id);
    if(invitationsIndex !== -1){
      this.setState(prevState => ({
        invitationsData: prevState.invitationsData.filter((_, i) => i !== invitationsIndex)
      }));
    } else {
      this.setState(prevState => ({
        invitationsData: [...prevState.invitationsData, {userID: id, userName: name}]
      }));
    }
    if(indexArr !== -1){
      this.setState(prevState => ({
        getUsersIds: prevState.getUsersIds.filter((_, i) => i !== indexArr)
      }));
    } else {
      this.setState(prevState => ({
        getUsersIds: [...prevState.getUsersIds, {id: id, name: name}],
      }));
    }
  }
  handlerRemoveAllUsers(){
    let data = this.state.resultData;
    data.allUsers = false;
    data.users = [];
    this.setState(prevState => ({
      getAllUsers: false,
      allUsers: false,
      resultData: data
    }));
  }
  handlerRemoveUser(id, index, event){
    let resultData = this.state.resultData;
    resultData.invitations.splice(index, 1);
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    let ids = [];
    arr.splice(indexArr, 1);
    if(arr){
      ids = [...new Set(arr.map(item => item.id))]
    }
    resultData.users = ids;
    this.setState(prevState => ({
      resultData: resultData,
      getUsersIds: arr,
      usersIDs: ids
    }));
  }
  handlerSetUsers(){
      let ids = [];
      ids = [...new Set(this.state.getUsersIds.map(users => users.id))]
      let data = this.state.resultData;
      let invitations = this.state.resultData.invitations;
      invitations = this.state.invitationsData;
      data.allUsers = this.state.getAllUsers ? true : false;
      data.users = this.state.getUsersIds.map(x => x.id);
      data.invitations = invitations;
      console.log(invitations);
      this.setState(prevState => ({
        allUsers: this.state.getAllUsers ? true : false,
        usersIds: ids,
        usersGet: this.state.getUsersIds,
        resultData: data
      }));
    this.handleModalDisabled();
  }
  handlerSearchUsers(event) {
    let arr = this.state.users;
    let newArr = [];
    this.setState({
      searchValue: event.target.value,
      usersSearch: []
    });
    let that = this;
    arr.map(function(item) {
      item.users.map(function(users) {
        if (users.name.includes(event.target.value)) {
           newArr.push(users);
          that.setState(prevState => ({
            usersSearch: [...prevState.usersSearch, users]
          }));
        }
      })
    });
  }
  handlerEditQuestion(item, event){
    let obj = this.state.resultData;
    let index = this.state.openEditQuestions.indexOf(item);
    if(index !== -1){
      this.setState(prevState => ({
        openEditQuestions: [...prevState.openEditQuestions.slice(0, index), ...prevState.openEditQuestions.slice(index + 1)]
      }));
    } else {
      this.setState(prevState => ({
        openEditQuestions: [...prevState.openEditQuestions, item]
      }));
    }
  }
  handlerAddDescriptionEdit(id, event){
    let arr = this.state.addDescriptionEditIds;
    let indexArr = arr.findIndex(x => x === id);
    if(indexArr !== -1){
      arr.splice(indexArr, 1);
    } else {
      arr = [...arr, id];
    }
    this.setState(prevState => ({
      addDescriptionEditIds: arr
    }));
  }
  handlerAddDefaultPointEdit(id, event){
    let data = this.state.resultData;
    let questions = data.questions;
    let indexArr = questions.findIndex(x => x.questionID === id);
    let question = questions[indexArr];
    let obj = {
          "answerOptionType": "CHECKBOX",
          "name": "",
          "priority": question.options.length > 0 ? (question.options.length - 1) : 0,
          "userAnswer": null,
          "userAnswers": null
        };
    question.options = [...question.options, obj];
    question.options.map((item, index) => {
      item.priority = index
    });
    this.setState(prevState => ({
      resultData: data
    }));
  }
  handlerEditQuestionHeadline(index, event){
    let data = this.state.resultData;
    let questions = data.questions;
    questions[index].question = event.target.value;
    this.setState(prevState => ({
      resultData: data
    }));
  }
  handlerEditQuestionDescription(index, event){
    let data = this.state.resultData;
    let questions = data.questions;
    questions[index].description = event.target.value;
    this.setState(prevState => ({
      resultData: data
    }));
  }
  handlerEditQuestionOptions(questionIndex, optionsIndex, event){
    let data = this.state.resultData;
    let questions = data.questions[questionIndex];
    let option = questions.options[optionsIndex];
    option.name = event.target.value;
    this.setState(prevState => ({
      resultData: data
    }));
  }
  handlerAnswerTypeEdit(type, index, id, event){
    let data = this.state.resultData;
    let questions = data.questions[index];
    questions.answerType = type;
    this.setState(prevState => ({
      resultData: data
    }));
    let arr = this.state.dropDownEditIds;
    let indexArr = arr.findIndex(x => x === id);
    if(indexArr !== -1){
      arr.splice(indexArr, 1);
      this.setState(prevState => ({
        dropDownEditIds: arr
      }));
    }
  }
  handlerDeleteEdit(questionIndex, optionIndex, event){
    let data = this.state.resultData;
    let questions = data.questions[questionIndex];
    let options = questions.options;
    options.splice(optionIndex, 1);
    this.setState(prevState => ({
      resultData: data
    }));
  }
  handlerCloseDropDown(event){
    const dropdown = event.target.closest('.dropdown');
    if(!dropdown){
      if(this.state.dropdownStatus){
        this.setState({
          dropdownStatus: false
        });
      }
      if(this.state.dropDownEditIds.length > 0){
        this.setState({
          dropDownEditIds: []
        });
      }
    }
  }
  chooseDateHandler(choose, event){
    this.setState({
      chooseDateType: choose
    });
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null
    }));
  }

  handlerGetStartDate(iter, day, month, year, next, prev, event){
    event.preventDefault();
    let monthValue = month + 1;
    if(next){
      monthValue = monthValue + 1;
      if(monthValue === 13) {
        monthValue = 1;
      }
    } else if(prev){
      monthValue = monthValue - 1;
      if(monthValue === 0) {
        monthValue = 12;
      }
    } else {
      monthValue = monthValue;
    }
    let nthWeekday = this.getNthWeekdayOfMonth(new Date(year, (monthValue - 1), day));
    this.setState({
      startDateCalendar: day + '.' + monthValue + '.' + year,
      iterStartCalendar: iter,
      weeklyDay: new Date(year, (monthValue - 1), day).getDay(),
      nthWeekday: nthWeekday - 1,
      dateNum: day,
      monthNum: month
    });
  }

  handlerGetStartTime(time, event){
    event.preventDefault();
    this.setState({
      startTimeCalendar: time
    });
  }

  handlerGetEndDate(iter, day, month, year, next, prev, event){
    event.preventDefault();
    let monthValue = month + 1;
    if(next){
      monthValue = monthValue + 1;
      if(monthValue === 13) {
        monthValue = 1;
      }
    } else if(prev){
      monthValue = monthValue - 1;
      if(monthValue === 0) {
        monthValue = 12;
      }
    } else {
      monthValue = monthValue;
    }
    this.setState({
      endDateCalendar: day + '.' + monthValue + '.' + year,
      iterFinishCalendar: iter
    });
  }

  handlerGetEndTime(time, event){
    event.preventDefault();
    this.setState({
      endTimeCalendar: time
    });
  }

  getNameNumberDay(weekday, num){
    const numberNameOne = [
      'перший',
      'другий',
      'третій',
      'чертвертий',
      'п\'ятий'
    ];
    const numberNameSecond = [
      'перша',
      'друга',
      'третя',
      'чертверта',
      'п\'ята'
    ];
    if(weekday == 1 || weekday == 2 || weekday == 4){
      return numberNameOne[num];
    } else {
      return numberNameSecond[num];
    }
  }

  getNthWeekdayOfMonth(date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const weekday = date.getDay();
    let nthWeekday = 0;

    for (let i = 1; i <= date.getDate(); i++) {
      const currentDate = new Date(year, month, i);
      if (currentDate.getDay() === weekday) {
        nthWeekday++;
        if (currentDate.getTime() === date.getTime()) {
          return nthWeekday;
        }
      }
    }

    return null;
  }
  handlerChangDateInfo(status){
    this.setState(prevState => ({
      changeStatus: status
    }));
  }

  handlerGetEmoji(smile, event){
    let data = this.state.resultData;
    data.smile = smile;
    this.setState({
      resultData: data
    });
  }

  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.title = this.props.getTranslate('polls-EditTitle') + ' | ' + this.props.companyName;
    }
    let chooseUsers = '';
    if(this.state.resultData.invitations && this.state.resultData.invitations.length > 0 && !this.state.allUsers){
      chooseUsers = this.state.resultData.invitations.map((item, index) => {
        return <span className="select-users__label" key={index}>{item.userName}<span onClick={this.handlerRemoveUser.bind(this, item.userID, index)}><svg className="select-users__remove">
                                  <use href={`${sprite}#close-icon`}></use>
                                </svg></span></span>
      })
    }
    let users = '';
    if(this.state.errorUsers) {
      users = <div>Ошибка...</div>;
    } else if(!this.state.isLoadedUser) {
      users = <Loading />;
    } else {
      users = this.state.searchValue ?       <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={500}
              ><div className="team-list-modal">
        {this.state.usersSearch.map((elem, index) => {
          let active = this.state.invitationsData.findIndex(x => x.userID === elem.userID) !== -1 ? true : false;
            return <div className={`${active ? 'users-modal__item active' : 'users-modal__item'}`} key={index} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID)}>
                 <div className="users-modal__info">
                   <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                   <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                 </div>
                 {active ? <div className="users-modal__check-wrap">
                   <svg className="users-modal__check">
                     <use href={`${sprite}#check-icon`}></use>
                   </svg>
                 </div> : ''}
               </div>
        })}
      </div></Scrollbars> : <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={500}
              ><div className="team-list-modal">
        <div className={`${this.state.getAllUsers ? 'users-modal__item active' : 'users-modal__item'}`} onClick={this.handlerGetAllUsers.bind(this)}>
          <div className="users-modal__info">
            <div className="users-modal__avatar-icon-wrap">
              <svg className="users-modal__avatar-icon">
                <use href={`${sprite}#all-team-icon`}></use>
              </svg>
            </div>
            <span className="users-modal__name">{this.props.getTranslate('modalAddUser-AllUsersLabel')}</span>
          </div>
          {this.state.getAllUsers && <div className="users-modal__check-wrap">
            <svg className="users-modal__check">
              <use href={`${sprite}#check-icon`}></use>
            </svg>
          </div>}
        </div>
        {this.state.users.map((item, index) => {
         return item.users.length > 0 ? <div key={index}>
         <div className="team-list-modal__label">{item.name ? item.name : this.props.getTranslate('modalAddUser-NoTeamLabel')}</div>
          {item.users.map((elem, i) => {
            let active = this.state.invitationsData.findIndex(x => x.userID === elem.userID) !== -1 ? true : false;
              return <div className={`${active ? 'users-modal__item active' : 'users-modal__item'}`} key={i} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID)}>
                   <div className="users-modal__info">
                     <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                     <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                   </div>
                   {this.state.getAllUsers || active ? <div className="users-modal__check-wrap">
                     <svg className="users-modal__check">
                       <use href={`${sprite}#check-icon`}></use>
                     </svg>
                   </div> : ''}
                 </div>
          })}
        </div> : ''})}
       </div></Scrollbars>;
    }
    let totalItem = this.state.dates.length * 7;
    let totalItemNotPush = totalItem - this.state.pushElemsTotal;
    let firstItems = this.state.countFirstElems;
    let endedItems = totalItem - this.state.countEndedElems;
    let iter = 0;
    let tableDate = this.state.dates.map((item, index) => {
      return <tr key={index}>
      {item.map((elem, i) => {
        iter++;
        if(new Date(this.state.startDate) <= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + elem) && new Date(this.state.hoverDate) >= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + elem)) {
          return <td className="checked" key={i} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)} onMouseLeave={this.chooseEndHover.bind(this)}>{elem}</td>
        } else if(this.state.startDate && (new Date(this.state.startDate).getDate() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getDate()) && (new Date(this.state.startDate).getMonth() - 1 === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getMonth()) && (new Date(this.state.startDate).getFullYear() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getFullYear())) {
          return <td className="checked" key={i} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)} onMouseLeave={this.chooseEndHover.bind(this)}>{elem}</td>
        } else if(this.state.iterStart && (this.state.countMonth >= this.state.countMonthSave && this.state.iterStart <= iter && this.state.iterHover >= iter)) {
          return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
        } else if(this.state.iterStart && this.state.iterFinish && (this.state.countMonth <= this.state.countMonthFinish && this.state.countMonth >= this.state.countMonthSave) && (this.state.iterStart <= iter && this.state.iterFinish >= iter)) {
          return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
        } else if(this.state.iterStart && this.state.iterFinish && this.state.countMonth < this.state.countMonthFinish && this.state.countMonth > this.state.countMonthSave){
          return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
        } else if((this.state.iterStart && this.state.iterFinish) && (this.state.countMonthSave !== this.state.countMonthFinish) && (this.state.countMonth === this.state.countMonthSave) && iter > this.state.iterStart) {
          return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
        } else {
          return <td key={i} onMouseEnter={this.chooseDateHover.bind(this, iter)} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
        }
      })}
      </tr>
    });
    let resultContent = '';
    if(this.state.error) {
      resultContent = <div>Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultContent = <Loading />;
    } else {
      resultContent =             <form className="questions-form" onSubmit={this.handlerSubmitSurvey.bind(this)}>
              {this.state.addQuestion && this.state.resultData.surveyType === 'NORMAL' ? <>
                <div className="question-info" onClick={this.handlerToggleForm.bind(this)}>
                  <h2 className="question-info__headline">{this.state.resultData.title}</h2>
                  <div className="question-info__content">
                    <div className="question-info__wgt">{this.state.resultData.allUsers ? this.state.totalUsers : this.state.chooseMembersIds.length} {this.props.getTranslate('polls-EmployeesCount')}</div>
                    <div className="question-info__wgt">{this.state.chooseDateFull}</div>
                    <div className="question-info__wgt">{this.state.resultData.anonymous ? this.props.getTranslate('polls-Anonim') : this.props.getTranslate('surveyModal-TypePublicValue')}</div>
                  </div>
                </div>
                </> : <div className="questions-form__inner questions-form__inner-edit">

                <div className="field-wrap">
                  <div className="field-wrap__label-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="headline">{this.props.getTranslate('polls-QuestionCaption')}</label>
                    <div className="tooltip-info">
                      <span className="tooltip-info__icon">?</span>
                      <div className="tooltip-info__content">
                        <div className="arrow"></div>
                          <p>{this.props.getTranslate('tooltipSurvey-TitleSurvey')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="field-wrap__smile">
                      <input id="headline" className="field-wrap__input field-wrap__input_width_75" type="text" value={this.state.resultData.title} onChange={this.handlerSurveyName.bind(this)} placeholder="Введите заголовок" />
                      <GetEmoji
                        tooltipValue={this.props.getTranslate('event-tooltipEmoji')}
                        onChangeHandler={this.handlerGetEmoji.bind(this)}
                        value={this.state.resultData.smile}
                      />
                  </div>
                </div>
                <div className="field-wrap">
                  <div className="field-wrap__label-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="start-description">{this.props.getTranslate('polls-MainText')}</label>
                    <div className="tooltip-info">
                      <span className="tooltip-info__icon">?</span>
                      <div className="tooltip-info__content">
                        <div className="arrow"></div>
                        <p>{this.props.getTranslate('tooltipSurvey-StartDescriptionStandartSurvey')}</p>
                      </div>
                    </div>
                  </div>
                  <textarea id="start-description" className="field-wrap__input field-wrap__input_questions" value={this.state.resultData.description} onChange={this.handlerSurveyDescription.bind(this)} placeholder={this.props.getTranslate('polls-MainTextFieldCaption')} />
                </div>
                {this.state.resultData.surveyType === 'NORMAL' && <div className="field-wrap">
                  <div className="field-wrap__label-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="finish-description">{this.props.getTranslate('polls-Finaltext')}</label>
                    <div className="tooltip-info">
                      <span className="tooltip-info__icon">?</span>
                      <div className="tooltip-info__content">
                        <div className="arrow"></div>
                          <p>{this.props.getTranslate('tooltipSurvey-FinalDescriptionStandartSurvey')}</p>
                        </div>
                      </div>
                    </div>
                  <textarea id="finish-description" className="field-wrap__input field-wrap__input_questions" value={this.state.resultData.finalDescription} onChange={this.handlerFinalSurveyDescription.bind(this)} placeholder={this.props.getTranslate('polls-FinaltextFieldCaption')} />
                </div>}
                <div className="questions-form__row">
                    <CalendarDropdown
                      label={this.props.getTranslate('events-Date')}
                      handlerGetStartDate={this.handlerGetStartDate.bind(this)}
                      handlerGetEndDate={this.handlerGetEndDate.bind(this)}
                      handlerGetStartTime={this.handlerGetStartTime.bind(this)}
                      handlerGetEndTime={this.handlerGetEndTime.bind(this)}
                      startDate={this.state.startDateCalendar}
                      startTime={this.state.startTimeCalendar}
                      endDate={this.state.endDateCalendar}
                      endTime={this.state.endTimeCalendar}
                      iterStartCalendar={this.state.iterStartCalendar}
                      iterFinishCalendar={this.state.iterFinishCalendar}
                      editCalendar={true}
                      handlerChangeDateForInfo={this.handlerChangDateInfo.bind(this)}
                    />
                  {this.state.resultData.surveyType === 'QUICK' && <div className="field-wrap questions-form__repeat">
                    <label className="field-wrap__label field-wrap__label_theme_gray">Повторение</label>
                    <Select
                      styles={customStyles}
                      options={repeatChoose}
                      placeholder='Выберите из списка'
                    />
                  </div>}
                  <div className="field-wrap questions-form__bonus">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="bonus">{this.props.getTranslate('polls-BonusForSurvey')}</label>
                    <div className="questions-form__bonus-inner">
                      <input id="bonus" className="field-wrap__input questions-form__bonus-field" type="text" value={this.state.resultData.bonus} onChange={this.handlerSurveyBonus.bind(this)} placeholder={this.props.getTranslate('polls-BonusFieldCaption')} />
                      {this.props.currency ? <span className="questions-form__bonus-icon">{this.props.currency}</span> : ''}
                    </div>
                  </div>
                </div>
                {this.state.resultData.surveyType === 'NORMAL' && <div className="questions-form__row">
                      <div className="field-wrap field-wrap__tooltip">
                        <div className="unlimited-product"><div><input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" defaultChecked={this.state.anonymousSurvey} onChange={this.handlerAnonymousSurvey} /><label htmlFor="styled-checkbox-1"></label></div><span>{this.props.getTranslate('polls-Anonim')}</span></div>
                        <div className="tooltip-info">
                          <span className="tooltip-info__icon">?</span>
                          <div className="tooltip-info__content">
                            <div className="arrow"></div>
                            <p>{this.props.getTranslate('tooltipSurvey-AnonymousSurvey')}</p>
                          </div>
                        </div>
                      </div>
                </div>}
                <div className="field-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('polls-Users')}</label>
                  <div className="select-modal" onClick={this.handlerAddMembers.bind(this)}>
                  {this.state.allUsers ?
                  <div className="select-users__input">
                    {this.state.allUsers ? <span className="select-users__label">{this.props.getTranslate('getEmployees-FieldAllPlaceholder')}<span onClick={this.handlerRemoveAllUsers.bind(this)}><svg className="select-users__remove">
                                              <use href={`${sprite}#close-icon`}></use>
                                            </svg></span></span> : <div className="select-users__placeholder">{this.props.getTranslate('polls-UsersFieldCaption')}</div>}
                  </div> : <div className="select-users__input">{chooseUsers}</div>}
                  </div>
                </div>
              </div>}
              {this.state.resultData.questions ? <SortableListQuestions
                items={this.state.resultData.questions}
                onSortEnd={this.onSortEndQuestions}
                axis={'xy'}
                removeQuestionNormal={this.removeQuestionNormal.bind(this)}
                handlerEditQuestion={this.handlerEditQuestion.bind(this)}
                activeQuestions={this.state.openEditQuestions}
                handlerAddDescriptionEdit={this.handlerAddDescriptionEdit.bind(this)}
                descriptionEditIds={this.state.addDescriptionEditIds}
                addDefaultPointEdit={this.handlerAddDefaultPointEdit.bind(this)}
                handlerEditQuestionHeadline={this.handlerEditQuestionHeadline.bind(this)}
                handlerEditQuestionDescription={this.handlerEditQuestionDescription.bind(this)}
                handlerEditQuestionOptions={this.handlerEditQuestionOptions.bind(this)}
                handlerDropdownEdit={this.handlerDropdownEdit.bind(this)}
                dropDownEditIds={this.state.dropDownEditIds}
                handlerAnswerTypeEdit={this.handlerAnswerTypeEdit.bind(this)}
                handlerDeleteEdit={this.handlerDeleteEdit.bind(this)}
                getTranslate={this.props.getTranslate.bind(this)}
                useDragHandle /> : ''}
              {this.state.resultData.questions && this.state.resultData.surveyType === 'NORMAL' ? <div className="question-form question-form__border">
                <div className="question-form__header">
                  <div className="question-form__num">№{this.state.resultData.questions.length + 1}</div>
                </div>
                <div className="field-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="question-headline">{this.props.getTranslate('polls-QuestionOptionTitleLabel')}</label>
                  <input id="question-headline" className="field-wrap__input" type="text" value={this.state.questionHeadline} onChange={this.handlerDefaultQuestionHeadline.bind(this)} placeholder={this.props.getTranslate('polls-QuestionOptionTitlePlaceholder')} />
                </div>
                {this.state.addDescription ? <div className="field-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="question-description">{this.props.getTranslate('polls-QuestionOptionDescriptionLabel')}</label>
                  <textarea id="question-description" className="field-wrap__input field-wrap__input_questions" placeholder={this.props.getTranslate('polls-QuestionOptionDescriptionPlaceholder')} />
                </div> : ''}
                <button className="question-form__btn" type="button" onClick={this.handlerAddDescription.bind(this)}>{this.state.addDescription ? this.props.getTranslate('polls-QuestionOptionDescriptionRemove') : this.props.getTranslate('polls-QuestionOptionDescriptionAdd')}</button>
                <div className="question-form__points">
                  <div className="question-form__points-header">
                    <div className="question-form__points-header-inner">
                      <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('polls-QuestionOptionDropdownLabel')}</label>
                      <div className="question-list-type">
                      <label className="field-wrap__label field-wrap__label_theme_gray field-wrap__label-questions">{this.props.getTranslate('polls-QuestionOptionDropdownType')}</label>
                      <div className="dropdown dropdown__account-survey dropdown__questions">
                        <button className="dropdown__btn" type="button" onClick={this.handlerDropdown.bind(this)}>
                          <div className="dropdown__btn-name">{this.state.answerType === 'SINGLE' ? this.props.getTranslate('polls-QuestionOptionDropdownTypeSingle') : this.props.getTranslate('polls-QuestionOptionDropdownTypeMultiple')}</div>
                          <svg className="dropdown__icon">
                            <use href={`${sprite}#arrow-down`}></use>
                          </svg>
                        </button>
                        <div className="tooltip-info">
                          <span className="tooltip-info__icon">?</span>
                          <div className="tooltip-info__content">
                            <div className="arrow"></div>
                            <p>{this.props.getTranslate('polls-QuestionOptionTooltipType')}</p>
                          </div>
                        </div>
                        <div className={`dropdown-content ${this.state.dropdownStatus ? 'active' : ''}`}>
                          <ul className="dropdown-nav">
                            <li className="dropdown-nav__item">
                              <button className={`dropdown-nav__link ${this.state.answerType === 'SINGLE' ? 'active' : ''}`} type="button" onClick={this.handlerAnswerType.bind(this, 'SINGLE')}>{this.props.getTranslate('polls-QuestionOptionDropdownTypeSingle')}</button>
                            </li>
                            <li className="dropdown-nav__item">
                              <button className={`dropdown-nav__link ${this.state.answerType === 'MULTIPLE' ? 'active' : ''}`} type="button" onClick={this.handlerAnswerType.bind(this, 'MULTIPLE ')}>{this.props.getTranslate('polls-QuestionOptionDropdownTypeMultiple')}</button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      </div>
                    </div>
                    <SortableList items={this.state.optionsDefault} onSortEnd={this.onSortEnd} axis={'xy'} type={this.state.answerType} handlerOnChange={this.handlerPointDefaultName.bind(this)} handlerRemove={this.handlerRemoveDefaultPoint.bind(this)} getTranslate={this.props.getTranslate.bind(this)} useDragHandle />
                    <button className="question-form__btn" type="button" onClick={this.handlerAddDefaultPoint.bind(this)}>{this.props.getTranslate('polls-AnswersAddButton')}</button>
                  </div>
                </div>
              </div> : ''}


              {this.state.resultData.surveyType === 'NORMAL' && <div className="questions-form__add-wrap">
                <button className="questions-form__add" type="button" onClick={this.handlerAddQuestion.bind(this)}>{this.props.getTranslate('polls-AddQuestionButton')}</button>
              </div>}
              {this.state.resultData.surveyType === 'QUICK' && <div className="quick-question-points">
                <div className="quick-question-points__inner">
                  <h2 className="quick-question-points__headline">Вопросы</h2>
                  <div className="quick-question-points__description">Список вопросов</div>
                  {this.state.resultData.questions.map((item, index) => {
                    return                   <div className="quick-question-points__point" key={index}>
                                                                      <div className="field-wrap quick-question-points__field">
                                                                        <input className="field-wrap__input" type="text" value={item.question} onChange={this.handlerOptionName.bind(this, index)} />
                                                                      </div>
                                                                      <div className="quick-question-points__nav">
                                                                        <div className="field-wrap">
                                                                          <Select
                                                                            styles={customStyles}
                                                                            options={choosePoint}
                                                                            onChange={this.handlerOptionType.bind(this, index)}
                                                                            placeholder='Выберите из списка'
                                                                          />
                                                                        </div>
                                                                        <div className="question-form__points-item-nav">
                                                                          <button className="question-wgt-item__nav-btn" type="button" onClick={this.removeQuestionNormal.bind(this, index)}>
                                                                            <svg className="question-wgt-item__nav-icon question-wgt-item__nav-icon_close">
                                                                              <use href={`${sprite}#close-icon`}></use>
                                                                            </svg>
                                                                          </button>
                                                                          <button className="question-wgt-item__nav-btn" type="button">
                                                                            <svg className="question-wgt-item__nav-icon question-wgt-item__nav-icon_sort">
                                                                              <use href={`${sprite}#lines-icon`}></use>
                                                                            </svg>
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                  })}
                  <div className="quick-question-points__add-btn">
                    <button className="btn-default" onClick={this.handlerAddPoint.bind(this)} type="button">Добавить вариант</button>
                    {this.state.pointsLength && <div className="quick-question-points__max-item">Максимальное количество вопросов 4</div>}
                  </div>
                </div>
              </div>}
              <div className="questions-form__footer">
                <div className="field-wrap__submit">
                  <button className="btn btn_size_lg" type="submit" disabled={this.state.isLoadedSubmitAddSurvey ? true : false}>{this.props.getTranslate('polls-SaveButton')}{this.state.isLoadedSubmitAddSurvey && <Loading />}</button>
                </div>
              </div>
            </form>
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successSubmit && <Navigate to={this.props.getPermalink("/account/surveys/default")} replace={true} />) : ''}
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap" onClick={this.handlerCloseDropDown.bind(this)}>
      <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
      <div className="account-content">
        <div className="account-content__inner">

          {/* Account header component */}
          <AccountContentHeader
            handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
            languageActive={this.props.chooseLanguage}
            backBtn={true}
            backBtnType="link"
            backBtnClass="button-back_theme_gray"
            backBtnHandler={this.props.getPermalink("/account/surveys/default")}
            backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
          />

          <div className="account-content__header-title">
            <h1 className="account-content__headline">{this.props.getTranslate('polls-EditTitle')}</h1>
          </div>
          <div className="questions-form-wrap">
            {resultContent}
          </div>
        </div>
      </div>
    </div>
    {this.state.modal && this.state.modalAddMembers ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.props.getTranslate('modal-addUsersTitle')}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
      <div className="field-wrap field-wrap__search-members">
        <input className="field-wrap__input" type="text" value={this.state.searchValue} onChange={this.handlerSearchUsers.bind(this)} placeholder={this.props.getTranslate('modal-addUsersSearchPlaceholder')} />
        <svg className="field-wrap__search-icon">
          <use href={`${sprite}#search-icon`}></use>
        </svg>
      </div>
      <div className="question-members">
        <div className="question-members-wgt">
          {users}
        </div>
          <div className="field-wrap__submit">
            <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="button" onClick={this.handlerSetUsers.bind(this)}>{this.props.getTranslate('modal-addUsersBtnSubmit')}</button>
          </div>
      </div>
    </div>
  </div> : ''}
    {this.state.dateModal && <div className="date-modal">
      <div className="date-modal__overlay" onClick={this.handlerCloseGetDate.bind(this)}></div>
      <div className="date-modal__content">
        <div className="date-modal__header">
          <div className="date-modal__headline">{this.props.getTranslate('polls-Date')}</div>
          <button className="date-modal__close-modal" type="button" onClick={this.handlerCloseGetDate.bind(this)}>
            <svg className="date-modal__close-modal-icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <div className="date-fields">
          <div className={`date-field ${this.state.chooseDateType === 'start' ? 'date-field__border' : ''}`} onClick={this.chooseDateHandler.bind(this, 'start')}>
            <input className="date-field__date" type="text" maxLength="10" placeHolder="dd.mm.yyyy" value={this.state.finishStartDate ? this.state.finishStartDate : ''} onKeyPress={this.onKeyPress.bind(this)} />
            <input className="date-field__time" type="text" maxLength="5" defaultValue={this.state.startTime} placeholder="00:00" onChange={this.handlerStartTime.bind(this)} onKeyPress={this.onKeyPress2.bind(this)} />
          </div>
          <div className={`date-field ${this.state.chooseDateType === 'finish' ? 'date-field__border' : ''}`} onClick={this.chooseDateHandler.bind(this, 'finish')}>
            <input className="date-field__date" type="text" maxLength="10" placeHolder="dd.mm.yyyy" value={this.state.finishEndDate ? this.state.finishEndDate : ''} onKeyPress={this.onKeyPress.bind(this)} />
            <input className="date-field__time" type="text" maxLength="5" defaultValue={this.state.endTime} placeholder="00:00" onChange={this.handlerEndTime.bind(this)} onKeyPress={this.onKeyPress2.bind(this)} />
          </div>
        </div>
        <div id="calendar" className="calendar">
          <div className="calendar__info">
            <button className="calendar__month-nav" type="button" onClick={this.handlerPrevMonth.bind(this)}>
              <svg className="calendar__month-nav-icon">
                <use href={`${sprite}#chevron-left`}></use>
              </svg>
            </button>
            <div className="calendar__month-year">{this.getMonthName(this.state.date.getMonth())}, {this.state.date.getFullYear()}</div>
            <button className="calendar__month-nav" type="button" onClick={this.handlerNextMonth.bind(this)}>
              <svg className="calendar__month-nav-icon">
                <use href={`${sprite}#chevron-right`}></use>
              </svg>
            </button>
          </div>
          <table className="calendar-table">
            <thead className="calendar-table__head">
              <tr>
                <th>{this.props.getTranslate('modal-calendarDayWeekMonday')}</th>
                <th>{this.props.getTranslate('modal-calendarDayWeekTuesday')}</th>
                <th>{this.props.getTranslate('modal-calendarDayWeekWednesday')}</th>
                <th>{this.props.getTranslate('modal-calendarDayWeekThursday')}</th>
                <th>{this.props.getTranslate('modal-calendarDayWeekFriday')}</th>
                <th>{this.props.getTranslate('modal-calendarDayWeekSutarday')}</th>
                <th>{this.props.getTranslate('modal-calendarDayWeekSunday')}</th>
              </tr>
            </thead>
            <tbody className="calendar-table__dates">{tableDate}</tbody>
          </table>
        </div>
        <div className="form-nav__submit">
          <button className="btn btn_width_100 form-nav__submit" type="button" onClick={this.setDateInput.bind(this)}>{this.props.getTranslate('modal-calendarAddBtn')}</button>
        </div>
      </div>
    </div>}
    </>;
  }
}
export default (props) => <SurveyEditDefault {...useParams()} {...props} />
