import React from "react";
import Loading from './components/Loading';
import DateFormating from './components/DateFormating';
import { Link } from "react-router-dom";
import AccountHeader from './components/AccountHeader';
import Navigation from './components/Navigation';
import Pagination from './components/Pagination';
import sprite from './media/icons.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
class ControlPanelGlossary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      modalDisabled: false,
      modal: false,
      controlRow: null,
      blogData: [],
      currentPage: 0,
      totalPages: null,
      isLoaded: false,
      errors: null,
      indexDelete: null,
      idDelete: null,
      loadingDeleteSumbit: false
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modal: false,
        indexDelete: null,
        idDelete: null
      }));
    }, 500);
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  handlerCloseElements(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      const div = document.querySelector( '.control-wgt');
      document.addEventListener('click', e => {
        const withinBoundaries = e.composedPath().includes(div);
        if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
          if ( ! withinBoundaries ) {
            this.setState( {
              controlRow: null
            } );
          }
        }
      });
    }
  }
  componentDidMount() {
    let that = this;
    let language = 'uk-UA';
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken,
        'Accept-Language': language
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/blogs/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "blogType": "GLOSSARY",
        "direction": "DESC",
        "itemsPerPage": 20,
        "orderField": "modificationDate"
      })
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            blogData: data.data,
            totalPages: data.totalPages,
            isLoaded: true
          });
        })
      } else if(res.status === 401) {
        res.json().then(function(data) {
          that.setState({
            errors: [data.message],
            isLoaded: true,
            error: true
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.message,
            isLoaded: true,
            error: true
          });
        })
      }
    })
  })
  }
  handlerDelete(index, id, event){
    event.preventDefault();
    this.setState({
      modal: true,
      indexDelete: index,
      idDelete: id
    });
  }
  handlerDeleteSubmit(id, index, event){
    event.preventDefault();
    let that = this;
    that.setState({
      loadingDeleteSumbit: true
    })
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/blogs/${id}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if (res.status === 200) {
        let pagesData = that.state.blogData;
        pagesData.splice(index, 1);
        that.setState({
          loadingDeleteSumbit: false,
          blogData: pagesData
        });
        that.handleModalDisabled();
      } else {
        res.json().then(function(data) {
          that.setState({
            loadingDeleteSumbit: false,
          });
        })
      }
    })
  })
  }
  handlerStatusSubmit(id, index, event){
    event.preventDefault();
    let that = this;
    that.setState({
      loadingDeleteSumbit: true
    })
    let blogData = that.state.blogData;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/blogs/${id}/hide`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        "hidden": !blogData[index].hidden
      })
    }).then(res => {
      if (res.status === 200) {
        blogData[index].hidden = !blogData[index].hidden;
        that.setState({
          blogData: blogData
        });
        that.handleModalDisabled();
      } else {
        res.json().then(function(data) {
          that.setState({
            loadingDeleteSumbit: false,
          });
        })
      }
    })
  })
  }
  render() {
    let blogDataTable = '';
    if(this.state.error) {
      blogDataTable = 'Помилка';
    } else if(!this.state.isLoaded){
      blogDataTable = <Loading />;
    } else {
      blogDataTable = <table className="standart-table">
        <thead>
          <tr>
            <th>Назва статті</th>
            <th>Категорія</th>
            <th>Дата створення</th>
            <th></th>
          </tr>
        </thead>
      <tbody>
      {this.state.blogData.map((item, index) => {
        return <tr className="standart-table__row" key={index}>
               <td>{item.name}</td>
               <td><span className="bo-blog-category bo-blog-category_theme_green">{item.category}</span></td>
               <td><DateFormating start={item.modificationDate} time="off" /></td>
               <td>
               <div className="team-table__control">
                 <div className="control-wgt">
                   <button className="control-btn" onClick={this.handlerControl.bind(this, index)}>
                     <svg className="control-btn__icon">
                       <use href={`${sprite}#more-icon`}></use>
                     </svg>
                     </button>
                     <div className={`control-content ${this.state.controlRow === index ? 'active' : ''}`}>
                       <ul className="control-content__list">
                         <li>
                           <button className="control-content__btn" type="button" onClick={this.handlerStatusSubmit.bind(this, item.blogID, index)}>
                             <svg className="control-content__icon">
                               <use href={`${sprite}#view-icon-stroke`}></use>
                             </svg>{item.hidden ? 'Показати' : 'Приховати'}</button>
                         </li>
                         <li>
                           <Link className="control-content__btn" to={`/controlpanel/glossary/edit/${item.blogID}`}>
                             <svg className="control-content__icon">
                               <use href={`${sprite}#edit-icon2`}></use>
                             </svg>Редагувати</Link>
                         </li>
                         <li>
                           <button className="control-content__btn control-content__btn_delete" onClick={this.handlerDelete.bind(this, index, item.blogID)}>
                             <svg className="control-content__icon">
                               <use href={`${sprite}#delete-red`}></use>
                             </svg>Видалити</button>
                         </li>
                       </ul>
                     </div>
                     </div>
                 </div>
               </td>
             </tr>
      })}
      </tbody>
    </table>;
    }
    return <>
      <AccountHeader status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
      <div className="account-wrap account-wrap__admin" onClick={this.handlerCloseElements.bind(this)}>
        <Navigation status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
        <div className="account-content">
          <div className="account-content__inner">
            <div className="account-content__header">
              <div className="account-content__header-inner">
                <h1 className="account-content__headline">Глосарій</h1>
              </div>
              <a className="header__language header__language-content" href="#">RU</a>
            </div>
            <div className="team-nav">
              <Link className="btn team-nav__btn" to="/controlpanel/glossary/create">+ Додати статтю</Link>
            </div>
            <div className="account-content__content">
            <div className="account-wgt account-wgt__blog">
              {blogDataTable}
              </div>
            </div>
          </div>
        </div>
      </div>
      {this.state.modal ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">Видалити запис</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
          <div className="delete-category-form__description">Після підтвердження всі дані запису будуть видалені</div>
          <div className="field-wrap__submit">
            <button className="btn btn_size_lg btn_theme_remove btn_width_100 user-info__nav-btn" type="button" disabled={this.state.loadingDeleteSumbit? true : false} onClick={this.handlerDeleteSubmit.bind(this, this.state.idDelete, this.state.indexDelete)}>Видалити{this.state.loadingDeleteSumbit && <Loading />}</button>
          </div>
      </div>
      </div> : ''}
    </>
  }
}
export default ControlPanelGlossary;
