import React from "react";
class ServiceQuestions extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <section className="service-questions">
      <div className="wrapper">
        <div className="service-questions__inner">
          <div className="service-questions__col">
            <div className="service-questions__headline-wrap">
              <h3 className="service-questions__headline">{this.props.headline}</h3>
            </div>
            <div className="service-questions__description-wrap">
              <p>{this.props.description}</p>
            </div>
          </div>
          <div className="service-questions__col">
            {this.props.btns ? <div className="service-questions__nav">
              {this.props.btns.map((item, index) => {
                return <div className="service-questions__nav-col" key={index}>{item}</div>
              })}
            </div> : ''}
          </div>
        </div>
      </div>
    </section>
  }
}
export default ServiceQuestions;
