import React from "react";
import styles from './TabItems.css';
import { Link, useLocation } from "react-router-dom";

function withLocation(Component) {
  return function WrappedComponent(props) {
    const location = useLocation();
    const currentPath = location.pathname;

    return <Component {...props} currentPath={currentPath} />;
  };
}

class TabItems extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <div className="tab-items">
            <div className="tab-items__inner">
              {this.props.items.map((item, index) => {
                if(item.disabled){
                  return <span key={index} className="tab-items__link disabled">{item.label}{item.amount ? <span className="tab-items__link-amount">0</span> : ''}</span>
                } else {
                  if(item.handle){
                    return <button key={index} className={`tab-items__link ${item.className ? item.className : ''} ${this.props.activeHandle === item.nameTab ? 'active' : ''}`} onClick={item.handle.bind(this, item.nameTab)}>{item.label}{item.amount ? <span className="tab-items__link-amount">{item.amount}</span> : ''}</button>
                  } else {
                    return <Link key={index} className={`tab-items__link ${item.className ? item.className : ''} ${this.props.currentPath === item.path ? 'active' : ''}`} to={item.path}>{item.label}{item.amount ? <span className="tab-items__link-amount">{item.amount}</span> : ''}</Link>
                  }
                }
              })}
            </div>
          </div>
  }
}
const MyComponentWithLocation = withLocation(TabItems);
export default MyComponentWithLocation;
