import React from "react";
import sprite from '../media/icons.svg';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
class Pagination extends React.Component {
  constructor(props) {
    super(props);
  }
  hanlderPagination (i, event) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      let data = {
        "itemsPerPage": that.props.perPage,
        "page": i
      }
      let params = that.props.otherParams;
      if(params) {
        data = {...data, ...params}
      }
      fetch(`${that.props.path}`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data)
      }).then(res => {
        if (res.status === 200) {
          res.json().then(function(data) {
            that.props.items(data.data, data.page);
          })
        }
      })
    })
  }
  handlerPaginationPrev (event) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      let data = {
        "itemsPerPage": that.props.perPage,
        "page": that.props.currentPage - 1
      }
      let params = that.props.otherParams;
      if(params) {
        data = {...data, ...params}
      }
      fetch(`${that.props.path}`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data)
      }).then(res => {
        if (res.status === 200) {
          res.json().then(function(data) {
            that.props.items(data.data, that.props.currentPage - 1);
          })
        }
      })
    })
  }
  handlerPaginationNext (event) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      let data = {
        "itemsPerPage": that.props.perPage,
        "page": that.props.currentPage + 1
      }
      let params = that.props.otherParams;
      if(params) {
        data = {...data, ...params}
      }
      fetch(`${that.props.path}`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data)
      }).then(res => {
        if (res.status === 200) {
          res.json().then(function(data) {
            that.props.items(data.data, that.props.currentPage + 1);
          })
        }
      })
    })
  }
  render() {
    let itemsPagination = [];
    for(let i = 0; i < this.props.total; i++){
      itemsPagination.push(<li className="pagination__item" key={i}>
            <button className={`pagination__link ${this.props.currentPage === i ? 'active' : '' }`} type="button" onClick={this.hanlderPagination.bind(this, i)}>{i + 1}</button>
          </li>);
    }
    return <>
{this.props.total > 1 && <nav className="pagination-nav">
  <ul className="pagination">
    <li className="pagination__item">
      {this.props.currentPage > 0 ? <button className="pagination__link pagination__link_prev" onClick={this.handlerPaginationPrev.bind(this)} type="button">
        <svg>
          <use href={`${sprite}#left-icon`}></use>
        </svg>
      </button> : <button className="pagination__link pagination__link_prev" type="button">
        <svg>
          <use href={`${sprite}#left-icon`}></use>
        </svg>
      </button>}
    </li>
    {itemsPagination}
    <li className="pagination__item">
      {this.props.total - 1 > this.props.currentPage ? <button className="pagination__link pagination__link_next" onClick={this.handlerPaginationNext.bind(this)} type="button">
        <svg>
          <use href={`${sprite}#right-icon`}></use>
        </svg>
      </button> : <button className="pagination__link pagination__link_next" type="button">
        <svg>
          <use href={`${sprite}#right-icon`}></use>
        </svg>
      </button>}
    </li>
  </ul>
</nav>}
</>
}
}
export default Pagination;
