import React from "react";
import Loading from './components/Loading';
import Select from 'react-select';
import Header from './components/Header';
import Footer from './components/Footer';
import Button from "./components/Button";
import sprite from './media/icons.svg';
import contacts_img from './media/contacts.svg';
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex',
    backgroundColor: '#fff'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const customStylesError = {
  control: () => ({
    height: '42px',
    border: '1px solid #ff0000',
    borderRadius: '5px',
    display: 'flex',
    backgroundColor: '#fff'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
function validateEmail(email) {
  var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(email).toLowerCase());
}
class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      themeValues: {},
      nameValue: '',
      emailValue: '',
      themeValue: '',
      messageValue: '',
      submitSuccess: false,
      errorFields: false,
      errorOnlySpecialName: false
    }
  }
  handlerNameValue(event){
    if(this.state.errorOnlySpecialName){
      this.setState({
        errorOnlySpecialName: false
      });
    }
    this.setState({
      nameValue: event.target.value
    });
  }
  handlerEmailValue(event){
    this.setState({
      emailValue: event.target.value
    });
  }
  handlerThemeValue(event){
    this.setState({
      themeValue: event.value
    });
  }
  handlerMessageValue(event){
    this.setState({
      messageValue: event.target.value
    });
  }
  handlerSubmitForm (event) {
    event.preventDefault();
    let error = false;
    this.setState({
      loading: true,
      errorFields: false,
      errorOnlySpecialName: false
    });
    const nameWithoutSpaces = this.state.nameValue.trim();
    const isValidName = /[a-zA-Z0-9а-яА-Я\s]/.test(nameWithoutSpaces);
    if(nameWithoutSpaces === ''){
      this.setState({
        nameValue: nameWithoutSpaces
      });
    }
    const themeWithoutSpaces = this.state.themeValue.trim();
    if(themeWithoutSpaces === ''){
      this.setState({
        themeValue: themeWithoutSpaces
      });
    }
    if(!isValidName){
      this.setState({
        errorOnlySpecialName: true,
        errorFields: false
      });
    }
    if(nameWithoutSpaces === '' || !isValidName || this.state.emailValue === '' || (this.state.emailValue !== '' && !validateEmail(this.state.emailValue)) || this.state.messageValue === '' || this.state.themeValue === '') {
      error = true;
    }
    if(!error) {
      let submitObj = {
        "name": nameWithoutSpaces,
        "email": this.state.emailValue,
        "questionType": themeWithoutSpaces,
        "message": this.state.messageValue
      };
      const myHeaders = new Headers({
          'Content-Type': 'application/json'
      });
      fetch(`${this.props.queryLink}/bo/v1/external/configs/appeals`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(submitObj)
      }).then(res => {
        if(res.status === 200) {
          this.setState({
            loading: false,
            submitSuccess: true,
            nameValue: '',
            emailValue: '',
            themeValue: '',
            messageValue: ''
          });
        }
      })
    } else {
      this.setState({
        loading: false,
        errorFields: true
      });
    }
  }
  handlerSubmitMore(){
    this.setState({
      submitSuccess: false
    });
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      window.scrollTo(0, 0);
    }
    let that = this;
    const myHeaders = new Headers({
        'Content-Type': 'application/json'
    });
    fetch(`${this.props.queryLink}/bo/v1/external/configs/appeals/question-types`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {

      } else {
        res.json().then(function(data) {
          let values = data;
          let valuesObj = [];
          data.forEach((item, i) => {
            if(item === 'TECHNICAL_SUPPORT'){
              valuesObj.push({ value: item, label: 'Технічна підтримка' });
            } else if(item === 'REQUEST_A_DEMO') {
              valuesObj.push({ value: item, label: 'Запит на демо' });
            } else if(item === 'BECOME_A_PARTNER'){
              valuesObj.push({ value: item, label: 'Стати партнером' });
            } else {
              valuesObj.push({ value: item, label: 'Інше' });
            }
          });
          that.setState({
            themeValues: valuesObj
          });
        })
      }
    })
  }
  render() {
    let title = this.props.getTranslate('contactsPage-Title');
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = title ? title : 'MateSpace.io';
      const metaDescription = document.querySelector('meta[name="description"]');
      let description = this.props.getTranslate('contactsPage-Description');
      if (metaDescription) {
        metaDescription.setAttribute('content', description ? description : 'MateSpace.io');
      }
    }
    return <>
    {this.props.PageWithCanonical}
    <Header getPermalink={this.props.getPermalink} logged={this.props.logged} userPhoto={this.props.userPhoto} userName={this.props.userName} handlerChangeLanguage={this.props.handlerChangeLanguage} chooseLanguage={this.props.chooseLanguage} getTranslate={this.props.getTranslate} />
    <main>
      <section className="contacts">
        <div className="wrapper">
          <div className="contacts__inner">
            <div className="contacts__form">
              <h1 className="contacts__headline">{this.props.getTranslate('contacts-Title')}</h1>
              <div className="contacts__description" dangerouslySetInnerHTML={{__html: this.props.getTranslate('contacts-SubTitle')}}></div>
              {this.state.submitSuccess ? <div className="contact-form-success">
                <div className="contact-form-success__inner">
                  <div className="contact-form-success__icon-wrap">
                    <svg className="contact-form-success__icon">
                      <use href={`${sprite}#form-success`}></use>
                    </svg>
                  </div>
                  <div className="contact-form-success__headline">{this.props.getTranslate('contacts-SuccessTitle')}</div>
                  <div className="contact-form-success__description">{this.props.getTranslate('contacts-SuccessDescription')}</div>
                  <Button type="button" text="Відправити ще"  handler={this.handlerSubmitMore.bind(this)} btnSize="large" />
                </div>
              </div> : <form className="contact-form" onSubmit={this.handlerSubmitForm.bind(this)}>
                <div className="contact-form__header">
                  <div className="contact-form__headline">{this.props.getTranslate('contacts-FormTitle')}</div>
                  <p className="contact-form__description" dangerouslySetInnerHTML={{__html: this.props.getTranslate('contacts-FormSubTitle')}}></p>
                </div>
                <div className="contact-form__inner">
                  <div className="field-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="name">{this.props.getTranslate('contacts-Name')}</label>
                    <input id="name" className={`field-wrap__input ${(this.state.errorFields && (!this.state.nameValue) || (this.state.errorOnlySpecialName && this.state.nameValue)) ? 'field-wrap__input_error' : ''}`} placeholder={this.props.getTranslate('contacts-NameFieldCaption')} value={this.state.nameValue} onChange={this.handlerNameValue.bind(this)}/>
                    {this.state.errorFields && this.state.nameValue === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                    {this.state.errorFields && this.state.errorOnlySpecialName && this.state.nameValue !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpecialField')}</div> : ''}
                </div>
                  <div className="field-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="email">{this.props.getTranslate('contacts-Email')}</label>
                    <input id="email" className={`field-wrap__input ${(this.state.errorFields && (!this.state.emailValue || !validateEmail(this.state.emailValue))) ? 'field-wrap__input_error' : ''}`} placeholder={this.props.getTranslate('contacts-EmailFieldCaption')} value={this.state.emailValue} onChange={this.handlerEmailValue.bind(this)}/>
                    {this.state.errorFields && this.state.emailValue === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                    {this.state.errorFields && (!validateEmail(this.state.emailValue) && this.state.emailValue !== '') ? <div className="validation-error">{this.props.getTranslate('validation-IncorrectEmail')}</div> : ''}
                  </div>
                  <div className="field-wrap questions-form__repeat">
                    <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('contacts-Theme')}</label>
                    <Select
                      className="field-wrap__select"
                      styles={this.state.errorFields && this.state.themeValue === '' ? customStylesError : customStyles}
                      options={this.state.themeValues}
                      placeholder={this.props.getTranslate('contacts-ThemeFieldCaption')}
                      onChange={this.handlerThemeValue.bind(this)}
                    />
                    {this.state.errorFields && this.state.themeValue === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                  </div>
                  <div className="field-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="message">{this.props.getTranslate('contacts-Theme')}</label>
                    <textarea id="message" className={`field-wrap__input field-wrap__input_questions ${(this.state.errorFields && !this.state.messageValue) ? 'field-wrap__input_error' : ''}`} placeholder={this.props.getTranslate('contacts-MessageFieldCaption')} value={this.state.messageValue} onChange={this.handlerMessageValue.bind(this)} />
                    {this.state.errorFields && this.state.messageValue === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                  </div>
                  <div className="field-wrap__submit contacts-submit">
                    <button className="btn btn_width_100 btn_size_lg" type="submit" disabled={this.state.loading ? true : false}>{this.props.getTranslate('contacts-SendButton')}{this.state.loading && <Loading />}</button>
                  </div>
                </div>
              </form>}
            </div>
          </div>
        </div>
        <div className="contacts__img">
          <img src={contacts_img} alt={this.props.getTranslate('contacts-Title')} />
        </div>
      </section>
    </main>
    <Footer getPermalink={this.props.getPermalink} getTranslate={this.props.getTranslate} />
    </>;
  }
}
export default Contacts;
