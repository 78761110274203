import React from "react";
import ServiceFunctionWgt from './ServiceFunctionWgt';
import ServiceNoteWgt from './ServiceNoteWgt';
import manage_img_1 from '../../media/manage-img3.png';
let Swiper;
let SwiperSlide;

if (typeof window !== 'undefined') {
  // Асинхронно загружаем Swiper и его компоненты
  Swiper = require('swiper').default;
  SwiperSlide = require('swiper').default.SwiperSlide;

  // Асинхронно загружаем стили Swiper
  require('swiper/swiper-bundle.css');
}
class ServiceNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderData: [
        {
          to: "#",
          headline: 'В админ панели есть возможность просматривать итоги',
          thumb: manage_img_1
        },
        {
          to: "#",
          headline: 'В админ панели есть возможность просматривать итоги',
          thumb: manage_img_1
        },
        {
          to: "#",
          headline: 'В админ панели есть возможность просматривать итоги',
          thumb: manage_img_1
        },
        {
          to: "#",
          headline: 'В админ панели есть возможность просматривать итоги',
          thumb: manage_img_1
        }
      ]
    }
  }
  componentDidMount() {
  if (typeof window !== 'undefined') {
    this.swiper = new Swiper(".swiper-notes-wgts", {
      spaceBetween: 30,
      slidesPerView: 1,
      pagination: {
        clickable: true
      },
      breakpoints: {
        992: {
          slidesPerView: 4
        }
      },
    })
  }
}
  render() {
    return <section className="section-service-notes">
      <div className="wrapper">
        <div className="section-service-notes__headline-wrap">
          <h2 className="section-service-notes__headline">Популярні статті</h2>
        </div>
        <div className="swiper-container swiper-notes-wgts">
          <div className="swiper-wrapper">
            {this.state.sliderData.map((item, index) => {
              return <div className="swiper-slide" key={index}>
                <ServiceNoteWgt
                  to={item.to}
                  headline={item.headline}
                  thumb={item.thumb}
                />
              </div>
            })}
          </div>
        </div>
      </div>
    </section>
  }
}
export default ServiceNotes;
