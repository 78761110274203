import React from "react";
import AccountHeader from './components/AccountHeader';
import Navigation from './components/Navigation';
import Pagination from './components/Pagination';
import Loading from './components/Loading';
import sprite from './media/icons.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
class ControlPanelAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      modalDisabled: false,
      modalInfo: false,
      modalAuthInfo: false,
      modal: false,
      passwordHideOld: true,
      passwordHideNew: true,
      passwordHideRNew: true,
      resultMe: {},
      nameUser: '',
      lastName: '',
      oldPasswordValue: '',
      newPasswordValue: '',
      newPasswordRValue: '',
      token: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("token-admin") ? JSON.parse(localStorage.getItem("token-admin")) : JSON.parse('')) : ''
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modalInfo: false,
        modalAuthInfo: false,
        modal: false
      }));
    }, 500);
  }
  handlerPaginationItems = (companiesItems, current) => {
    this.setState({
      companiesData: companiesItems,
      currentPage: current
    });
  }
  handlerNameUser(event){
    this.setState({
      nameUser: event.target.value
    });
  }
  handlerLastNameUser(event){
    this.setState({
      lastName: event.target.value
    });
  }
  handlerPasswordOld(event){
    this.setState({
      oldPasswordValue: event.target.value
    });
  }
  handlerPasswordNew(event){
    this.setState({
      newPasswordValue: event.target.value
    });
  }
  handlerPasswordRNew(event){
    this.setState({
      newPasswordRValue: event.target.value
    });
  }
  componentDidMount() {
    let that= this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/users/me`, {
      method: 'GET',
      headers: myHeaders
    })
      .then(res => res.json())
      .then((result) => {
        that.setState({
          resultMe: result,
          nameUser: result.name,
          lastName: result.lastName
        });
        },
        (error) => {

        }
      );
    })
  }

  handlerSubmitAccountData(event){
    event.preventDefault();
    let that = this;
    this.setState({
      modalEditLoading: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/users/me?name=${this.state.nameUser}&lastName=${this.state.lastName}`, {
      method: 'PUT',
      headers: myHeaders
    })
    .then(res => res.json())
    .then((result) => {
      if(result){
        this.handleModalDisabled();
        this.setState({
          resultMe: result,
          modalEditLoading: false
        });
      }
      },
      (error) => {
        this.setState({
          modalEditLoading: false
        });
      }
    )
  })
  }

  handlerSubmitPasswordData(event){
    event.preventDefault();
    let that = this;
    this.setState({
      modalEditLoading: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/users/password`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "passwordConfirmation": this.state.newPasswordRValue,
        "password" : this.state.newPasswordValue
      })
    }).then(res => {
      if (res.status !== 200) {
        res.json().then(function(data) {

        });
      } else {
        this.handleModalDisabled();
        this.setState({
          modalEditLoading: false
        });
      }
    })
  })
  }

  handlerModalInfo() {
    this.setState(prevState => ({
      modalInfo: !prevState.modalInfo
    }));
  }
  handlerModalAuthInfo() {
    this.setState(prevState => ({
      modalAuthInfo: !prevState.modalAuthInfo
    }));
  }
  handlerPassword(type){
    if(type === 'old'){
      this.setState(prevState => ({
        passwordHideOld: !prevState.passwordHideOld
      }));
    } else if(type === 'new-pass'){
      this.setState(prevState => ({
        passwordHideNew: !prevState.passwordHideNew
      }));
    } else {
      this.setState(prevState => ({
        passwordHideRNew: !prevState.passwordHideRNew
      }));
    }
  }
  render() {
    return <>
      <AccountHeader status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
      <div className="account-wrap account-wrap__admin">
        <Navigation status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
        <div className="account-content">
          <div className="account-content__inner">
            <div className="account-content__header">
              <div className="account-content__header-inner">
                <h1 className="account-content__headline">Управління акаунтом</h1>
              </div>
              <a className="header__language header__language-content" href="#">RU</a>
            </div>
            <div className="account-content__content">
            {this.state.resultMe ? <div className="account-wgt account-content__wgt">
              <h2 className="account-wgt__header">Персональні дані</h2>
              <div className="account-wgt__inner">
                {this.state.resultMe.name ? <div className="account-item account-wgt__item">
                  <div className="account-item__headline">Ім'я</div>
                  <div className="account-item__value">{this.state.resultMe.name}</div>
                </div> : ''}
                {this.state.resultMe.lastName ? <div className="account-item account-wgt__item">
                  <div className="account-item__headline">Прізвище</div>
                  <div className="account-item__value">{this.state.resultMe.lastName}</div>
                </div> : ''}
              </div>
              <button className="account-wgt__edit-btn" type="button" onClick={this.handlerModalInfo.bind(this)}>Редагувати дані</button>
            </div> : ''}
            <div className="account-wgt account-content__wgt">
              <h2 className="account-wgt__header">Посада</h2>
              <div className="account-wgt__inner">
                <div className="account-item account-wgt__item">
                  <div className="account-item__headline">Посада</div>
                  <div className="account-item__value">HR</div>
                </div>
                <div className="account-item account-wgt__item">
                  <div className="account-item__headline">Доступ</div>
                  <div className="account-item__value">Супер Адмін, Адміністратор, Користувач</div>
                </div>
              </div>
            </div>
            <div className="account-wgt account-content__wgt">
              <h2 className="account-wgt__header">Логін та пароль</h2>
              <div className="account-wgt__inner">
                <div className="account-item account-wgt__item">
                  <div className="account-item__headline">Логін (Електронна пошта)</div>
                  <div className="account-item__value">mail@gmail.com</div>
                </div>
                <div className="account-item account-wgt__item">
                  <div className="account-item__headline">Пароль</div>
                  <div className="account-item__value">***********</div>
                </div>
              </div>
              <button className="account-wgt__edit-btn" type="button" onClick={this.handlerModalAuthInfo.bind(this)}>Редагувати дані</button>
            </div>
            </div>
          </div>
        </div>
      </div>
      {this.state.modalInfo ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">Персональні дані</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <form onSubmit={this.handlerSubmitAccountData.bind(this)}>
        <div className="field-wrap">
          <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="name">Ім'я</label>
          <input id="name" className="field-wrap__input" value={this.state.nameUser} onChange={this.handlerNameUser.bind(this)} type="text" placeholder="Введіть ім'я" />
        </div>
        <div className="field-wrap">
          <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="surname">Прізвище</label>
          <input id="surname" className="field-wrap__input" value={this.state.lastName} onChange={this.handlerLastNameUser.bind(this)} type="text" placeholder="Введіть прізвище" />
        </div>
          <div className="register-form__submit form-nav">
            <div className="form-nav__col">
              <button className="btn btn_width_100 form-nav__submit" disabled={this.state.modalEditLoading? true : false}>Зберегти{this.state.modalEditLoading && <Loading />}</button>
            </div>
            <div className="form-nav__col">
              <button className="btn btn_theme_gray btn_width_100" type="submit" onClick={this.handleModalDisabled}>Закрити</button>
            </div>
          </div>
        </form>
    </div>
  </div> : ''}
  {this.state.modalAuthInfo ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
<div className="modal-background" onClick={this.handleModalDisabled}></div>
  <div className="modal">
    <div className="modal-header">
      <h2 className="modal-headline">Зміна пароля</h2>
      <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
        <svg className="close-modal__icon">
          <use href={`${sprite}#close-icon`}></use>
        </svg>
      </button>
    </div>
    <form onSubmit={this.handlerSubmitPasswordData.bind(this)}>
    <div className="field-wrap">
      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="old-pass">Старий пароль</label>
      <div className="field-wrap__inner">
        <input id="old-pass" className="field-wrap__input" value={this.state.oldPasswordValue} onChange={this.handlerPasswordOld.bind(this)} type={this.state.passwordHideOld ? 'password' : 'text'} placeholder="Введіть пароль" />
        <button className="field-wrap__hide-btn" type="button" onClick={this.handlerPassword.bind(this, 'old')}>
          <svg className="field-wrap__hide-icon">
            <use href={this.state.passwordHideOld ? `${sprite}#hide-pass` : `${sprite}#view-pass`}></use>
          </svg>
        </button>
      </div>
    </div>
    <div className="add-user-form__info add-user-form__info_top">
      <div className="field-wrap">
        <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="new-pass">Новий пароль</label>
        <div className="field-wrap__inner">
          <input id="new-pass" className="field-wrap__input" value={this.state.newPasswordValue} onChange={this.handlerPasswordNew.bind(this)} type={this.state.passwordHideNew ? 'password' : 'text'} placeholder="Введіть пароль" />
          <button className="field-wrap__hide-btn" type="button" onClick={this.handlerPassword.bind(this, 'new-pass')}>
            <svg className="field-wrap__hide-icon">
              <use href={this.state.passwordHideNew ? `${sprite}#hide-pass` : `${sprite}#view-pass`}></use>
            </svg>
          </button>
        </div>
      </div>
      <div className="field-wrap">
        <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="r-new-pass">Новий пароль</label>
        <div className="field-wrap__inner">
          <input id="r-new-pass" className="field-wrap__input" value={this.state.newPasswordRValue} onChange={this.handlerPasswordRNew.bind(this)} type={this.state.passwordHideRNew ? 'password' : 'text'} placeholder="Введіть пароль" />
          <button className="field-wrap__hide-btn" type="button" onClick={this.handlerPassword.bind(this, 'r-new-pass')}>
            <svg className="field-wrap__hide-icon">
              <use href={this.state.passwordHideRNew ? `${sprite}#hide-pass` : `${sprite}#view-pass`}></use>
            </svg>
          </button>
        </div>
      </div>
      </div>
      <div className="register-form__submit form-nav">
        <div className="form-nav__col">
          <button className="btn btn_width_100 form-nav__submit" disabled={this.state.modalEditLoading? true : false}>Зберегти{this.state.modalEditLoading && <Loading />}</button>
        </div>
        <div className="form-nav__col">
          <button className="btn btn_theme_gray btn_width_100" type="button" onClick={this.handleModalDisabled}>Закрити</button>
        </div>
      </div>
    </form>
</div>
</div> : ''}
    </>
  }
}
export default ControlPanelAccount;
