import React from "react";
import Header from './components/Header';
import Footer from './components/Footer';
import success_img from './media/success-reg.png';
import { Link, Navigate, useParams } from "react-router-dom";
class Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false
    }
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      window.scrollTo(0, 0);
    }
    let that = this;
    const myHeaders = new Headers({
        'Content-Type': 'application/json'
    });
      let obj = {
        "code": that.props.code
      };
      fetch(`${that.props.queryLink}/api/v1/users/invites`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(obj)
      }).then(res => {
          if (res.status !== 200) {

          } else {
            localStorage.setItem("successConfirm", JSON.stringify(true));
            that.setState({
              success: true
            });
          }
        })
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.title = this.props.title;
    }
    return <>
    {this.state.success && <Navigate to={{pathname: this.props.getPermalink("/account"), state: {successConfirm: true}}} replace={true} />}
      </>;
  }
}
export default (props) => <Confirm {...useParams()} {...props} />
