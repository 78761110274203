import React from "react";
import Header from './components/Header';
import Footer from './components/Footer';
import Button from './components/Button';
import AboutBanner from './components/AboutPages/AboutBanner';
import AboutInfoWgts from './components/AboutPages/AboutInfoWgts';
import AboutInfoServices from './components/AboutPages/AboutInfoServices';
import AboutIncludeCompany from './components/AboutPages/AboutIncludeCompany';
import AboutApp from './components/AboutPages/AboutApp';
import main_bg from './media/main-bg-about-panel.png';
import main_banner_img from './media/dash-board-banner.png';
import app_index_slider_1 from './media/app-index-slider-1.png';
import app_index_slider_2 from './media/app-index-slider-2.png';
import app_index_slider_3 from './media/app-index-slider-3.png';
import app_index_slider_4 from './media/app-index-slider-4.png';
import app_index_slider_5 from './media/app-index-slider-5.png';
import app_index_slider_6 from './media/app-index-slider-6.png';
import app_index_slider_7 from './media/app-index-slider-7.png';
import adminImg from './media/admin-contacts.png';
class AboutPanel extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      window.scrollTo(0, 0);
    }
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      let title = this.props.getTranslate('aboutAdminPage-Title');
      document.title = title ? title : 'MateSpace.io';
      const metaDescription = document.querySelector('meta[name="description"]');
      let description = this.props.getTranslate('aboutAdminPage-Description');
      if (metaDescription) {
        metaDescription.setAttribute('content', description ? description : 'MateSpace.io');
      }
    }
    return <>
    {this.props.PageWithCanonical}
    <Header getPermalink={this.props.getPermalink} logged={this.props.logged} userPhoto={this.props.userPhoto} userName={this.props.userName} handlerChangeLanguage={this.props.handlerChangeLanguage} chooseLanguage={this.props.chooseLanguage} getTranslate={this.props.getTranslate} />
    <main>
      <AboutBanner
        bg={main_bg}
        headline={this.props.getTranslate('aboutPanel-BannerHeadline')}
        description={this.props.getTranslate('aboutPanel-BannerDescription')}
        btn={<Button className="about-banner__btn btn_size_large" type="link" text={this.props.getTranslate('about-RegCompanyButton')} handler="#" btnSize="large" />}
        img={main_banner_img}
      />
      <AboutInfoWgts getTranslate={this.props.getTranslate} />
      <AboutInfoServices getTranslate={this.props.getTranslate} />
      <AboutIncludeCompany
        headline={this.props.getTranslate('about-AboutIncludeCompanyHeadline')}
        description={this.props.getTranslate('about-AboutIncludeCompanyDescription')}
        navs={[
          <Button className="btn_size_large" type="link" text="Запросити демо" handler="#" btnWidth="full" btnSize="large" btnTheme="white-transparent" />,
          <Button className="btn_size_large" type="link" text={this.props.getTranslate('about-RegCompanyButton')} handler="#" btnWidth="full" btnSize="large" btnTheme="white" />
        ]}
        img={adminImg}
      />
      <AboutApp getTranslate={this.props.getTranslate} getPermalink={this.props.getPermalink} />
    </main>
    <Footer getPermalink={this.props.getPermalink} getTranslate={this.props.getTranslate} />
    </>;
  }
}
export default AboutPanel;
