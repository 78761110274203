import React from "react";
import DateFormating from './components/DateFormating';
import { useParams, Navigate } from "react-router-dom";
import Loading from './components/Loading';
import Select from 'react-select';
import ResultErrors from './components/ResultErrors';
import sprite from './media/icons.svg';
import no_product_img from './media/no-product-image.jpg';
import AccountContentHeader from './components/AccountContentHeader';
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
let qw;
if(typeof window !== "undefined" && typeof document !== 'undefined'){
  qw = window.location.protocol;
}
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
class OrdersEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      startDate: null,
      isLoadedSubmitOrderEdit: false,
      errorSubmitOrderEdit: null,
      isLoadedOrder: false,
      errorOrder: null,
      userNameValue: '',
      nameValue: '',
      dateValue: '',
      priceValue: 0,
      quantityValue: 0,
      statusValue: '',
      descriptionValue: '',
      notesValue: '',
      productLink: '',
      successSubmit: false,
      currencyCompany: (typeof window !== "undefined" && typeof document !== 'undefined') ? localStorage.getItem('currencyMt') : '',
      errors: null,
      quantityValueNumError: false,
      statusValueInfo: false
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handlerDate (event) {
    this.setState(prevState => ({
      startDate: event
    }))
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token()
    });
    fetch(`${that.props.queryLink}/api/v1/orders/${this.props.id}`, {
      method: 'GET',
      headers: myHeaders,
    }).then(res => {
      if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoadedOrder: true,
              errorOrder: data.errors,
              errors: data.errors
            })
          });
      } else {
        res.json().then(function(data) {
          console.log(data);
            that.setState({
              isLoadedOrder: true,
              userNameValue: data.userName,
              dateValue: data.createdDate,
              priceValue: data.totalPrice,
              quantityValue: data.quantity,
              statusValue: data.orderStatus,
              nameValue: data.productName,
              productPhoto: data.productPhoto,
              descriptionValue: data.productDescription,
              createDate: data.createdDate,
              notesValue: data.notes,
              productLink: data.productLink
            });
        })
      }
    })
  }
  handlerPriceValue (event) {
    this.setState({
      priceValue: event.target.value
    });
  }
  handlerQuantityValue (event) {
    const inputValue = event.target.value;
    this.setState({
      quantityValueNumError: false
    });
    if(this.state.zeroValueNumError){
      this.setState({
        zeroValueNumError: false
      });
    }
    if(/^\d*$/.test(inputValue)) {
      if(inputValue === '' || parseInt(inputValue, 10) >= 1){
        this.setState({
          quantityValue: inputValue
        });
      } else {
        this.setState({
          zeroValueNumError: true
        });
      }
    } else {
      this.setState({
        quantityValueNumError: true
      });
    }
  }
  handlerStatusValue (event) {
    let status = event.value;
    this.setState({
      statusValue: status,
      statusValueInfo: status === 'DECLINED'
    });
  }
  handlerDescriptionValue(event) {
    this.setState(prevState => ({
      notesValue: prevState.notesValue ? prevState.notesValue : event.target.value
    }));
  }
  handlerSubmitEditOrder (event) {
    let that = this;
    event.preventDefault();
    let location;
    let language = 'uk-UA';
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      location = document.location.pathname;
      const isEnglish = location.startsWith('/en');
      if (isEnglish) {
        language = 'en-US'
      }
    }
    this.setState({
      isLoadedSubmitOrderEdit: true
    });
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token(),
        'Accept-Language': language
    });
    fetch(`${that.props.queryLink}/api/v1/orders/${this.props.id}`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        "notes": this.state.notesValue,
        "orderID": this.props.id,
        "orderStatus": this.state.statusValue,
        "price": this.state.priceValue,
        "quantity": this.state.quantityValue
      })
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoadedSubmitOrderEdit: false,
            errorSubmitOrderEdit: data.errors,
            errors: data.errors
          });
        })
      } else {
        res.json().then(function(data) {
          if(typeof window !== "undefined" && typeof document !== 'undefined') {
            localStorage.setItem("successOrder", JSON.stringify({status: true, text: 'Успешно сохранено'}))
          }
          that.setState({
            isLoadedSubmitOrderEdit: false,
            successSubmit: true
          });
          setTimeout(() => {
            that.setState({
              successSubmit: false
            });
          }, 5000);
        })
      }
    })
  }
  handlerDeleteOrder (event) {
    event.preventDefault();
    let that = this;
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token()
    });
    fetch(`${that.props.queryLink}/api/v1/orders/${this.props.id}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        })
      } else {
        that.setState({
          successSubmit: true
        });
        if(typeof window !== "undefined" && typeof document !== 'undefined') {
          localStorage.setItem("successOrder", JSON.stringify({status: true, text: 'Успешно удалено'}))
        }
      }
    })
  }
  handlerCloseSuccess (event) {
    event.preventDefault();
    this.setState(prevState => ({
      successSubmit: false
    }));
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errorSubmit: null
    }));
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.title = this.props.getTranslate('orders-EditTitle') + ' | ' + this.props.companyName;
    }
    let optionsStatus = [
      { value: 'NEW', label: this.props.getTranslate('orders-StatusNew') },
      { value: 'IN_PROGRESS', label: this.props.getTranslate('orders-StatusAccepted') },
      { value: 'DONE', label: this.props.getTranslate('orders-StatusDelivered') },
      { value: 'DECLINED', label: 'Скасувати' }
    ];
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successSubmit && <Navigate to="/account/orders" replace={true} />) : ''}
    {this.state.successSubmit && <div className="response-submit">
    <div className="response-submit__item response-submit__item_success">Успешно сохранено!
      <button className="response-submit__close" type="button" onClick={this.handlerCloseSuccess.bind(this)}>
      <svg><use href={`${sprite}#close-icon`}></use></svg>
      </button></div></div>}
      {this.state.errorSubmit && <div className="response-submit">
      <div className="response-submit__item response-submit__item_error">Ошибка!
        <button className="response-submit__close" type="button" onClick={this.handlerCloseError.bind(this)}>
        <svg><use href={`${sprite}#close-icon`}></use></svg>
        </button></div></div>}
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap">
      <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
      <div className="account-content">
        <div className="account-content__inner">

        {/* Account header component */}
        <AccountContentHeader
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
          backBtn={true}
          backBtnType="link"
          backBtnClass="button-back_theme_gray"
          backBtnHandler={this.props.getPermalink("/account/orders")}
          backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
        />

        <div className="account-content__header-title">
          <h1 className="account-content__headline">{this.props.getTranslate('orders-EditTitle')}</h1>
        </div>
          <div className="edit-form-wrap">
            <div className="order-product">
              <div className="edit-info__label">{this.props.getTranslate('orders-EditLabelProduct')}</div>
              <div className="order-product__inner">
                <div className="order-product__col order-product__col-info">
                  <div className="edit-info">
                    <div className="edit-info__value">{this.state.nameValue}</div>
                    <div className="order-product__col-description">{this.state.descriptionValue}</div>
                  </div>
                </div>
                {this.state.productPhoto ? <div className="order-product__col">
                  <img className="order-product__img" src={this.state.productPhoto ? qw === 'http:' ? this.state.productPhoto.replace( /https:/g, "http:" ) : this.state.productPhoto : no_product_img} alt="" />
                </div> : ''}
              </div>
            </div>
            {this.state.isLoadedOrder ? <form className="edit-user-form" onSubmit={this.handlerSubmitEditOrder.bind(this)}>
            <div className="edit-user-form__inner">
              <div className="edit-user-form__row">
                <div className="edit-user-form__col edit-user-form__col_size_3">
                  <div className="edit-info">
                    <div className="edit-info__label">{this.props.getTranslate('orders-EditLabelBuyer')}:</div>
                    <div className="edit-info__value">{this.state.userNameValue}</div>
                  </div>
                </div>
                {this.state.dateValue ? <div className="edit-user-form__col edit-user-form__col_size_3">
                  <div className="edit-info">
                    <div className="edit-info__label">{this.props.getTranslate('orders-EditLabelDate')}:</div>
                    <div className="edit-info__value"><DateFormating start={this.state.dateValue} /></div>
                  </div>
                </div> : ''}
              </div>
              <div className="edit-user-form__row">
                <div className="edit-user-form__col edit-user-form__col_size_3">
                  <div className="field-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="price">{this.props.getTranslate('orders-EditLabelPrice')}</label>
                    <div className="field-wrap__currency-name">
                      <input id="price" className="field-wrap__input" value={this.state.priceValue} onChange={this.handlerPriceValue.bind(this)}/>
                      {this.props.currency ? <label className="field-wrap__currency-name-item" htmlFor="price">{this.props.currency}</label> : ''}
                    </div>
                  </div>
                </div>
                <div className="edit-user-form__col edit-user-form__col_size_3">
                  <div className="field-wrap">
                    <label className="field-wrap__label" htmlFor="amount">{this.props.getTranslate('orders-EditLabelAmount')}</label>
                    <input id="amount" className={`field-wrap__input ${this.state.quantityValueNumError || this.state.zeroValueNumError ? 'field-wrap__input_error' : ''}`} value={this.state.quantityValue} onChange={this.handlerQuantityValue.bind(this)} />
                    {this.state.quantityValueNumError ? <div className="validation-error">В полі повинні бути тільки цифри</div> : ''}
                    {this.state.zeroValueNumError ? <div className="validation-error">Кількість не може бути менше 1</div> : ''}
                  </div>
                </div>
               </div>
               <div className="edit-user-form__row">
                   <div className="field-wrap" style={{width: '100%'}}>
                   <div style={{width: '33.33333%', padding: '0 10px'}}>
                    <label className="field-wrap__label">{this.props.getTranslate('orders-EditLabelStatus')}</label>
                    {this.state.statusValue && <Select
                      styles={customStyles}
                      options={optionsStatus}
                      value={optionsStatus[optionsStatus.map(el => el.value).indexOf(this.state.statusValue)]}
                      onChange={this.handlerStatusValue.bind(this)}
                      placeholder={this.props.getTranslate('orders-EditLabelStatus')}
                      isSearchable={false}
                      isDisabled={this.state.statusValue === 'DECLINED' ? true : false}
                    />}
                    </div>
                    {this.state.statusValueInfo ? <div className="edit-info-label">Після скасування замовлення, юзеру Сергій Лядовий буде поверненно на баланс 600 coin та змінити статус замовлення на інший буде неможливо.</div> : ''}
                  </div>
               </div>
               <div className="edit-user-form__row">
               {this.state.productLink ? <div className="edit-user-form__col edit-user-form__col_size_3">
                 <div className="edit-info">
                   <div className="edit-info__label">Посилання:</div>
                   <div className="edit-info__value">
                    <a href={this.state.productLink} target="_blank">{this.state.productLink}</a>
                  </div>
                 </div>
               </div> : ''}
               </div>
               <div className="field-wrap">
                 <label className="field-wrap__label" htmlFor="message">{this.state.notesValue ? this.props.getTranslate('orders-EditLabelMessageDate') + ' ' : ''}{this.state.notesValue && <DateFormating start={this.state.createDate} />} {!this.state.notesValue ? this.props.getTranslate('orders-EditLabelMessage') : ''}</label>
                 <textarea id="message" className="field-wrap__input field-wrap__input_accrual" value={this.state.notesValue} onChange={this.handlerDescriptionValue.bind(this)} disabled></textarea>
               </div>
                 </div>
                   <div className="field-wrap__submit">
                     <button className="btn btn_size_lg" type="submit" disabled={this.state.isLoadedSubmitOrderEdit ? true : false}>{this.props.getTranslate('orders-EditBtnSubmit')}{this.state.isLoadedSubmitOrderEdit && <Loading />}</button>
                   </div>
            </form> : <Loading />}
          </div>
        </div>
      </div>
    </div>
    </>;
  }
}
export default (props) => <OrdersEdit {...useParams()} {...props} />
