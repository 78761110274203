import React from "react";
import { Link } from "react-router-dom";
import Button from '../Button';
import img_about_card from '../../media/img-about-card.png';
import sprite from '../../media/icons.svg';
import app_index_slider_1 from '../../media/app-index-slider-1.png';
import app_index_slider_2 from '../../media/app-index-slider-2.png';
import app_index_slider_3 from '../../media/app-index-slider-3.png';
import app_index_slider_4 from '../../media/app-index-slider-4.png';
import app_index_slider_5 from '../../media/app-index-slider-5.png';
import app_index_slider_6 from '../../media/app-index-slider-6.png';
import app_index_slider_7 from '../../media/app-index-slider-7.png';
let Swiper;
if (typeof window !== 'undefined') {
  Swiper = require('swiper').Swiper;
  require('swiper/css');
  require('swiper/css/navigation');
  require('swiper/css/pagination');
  require('swiper/css/scrollbar');
}
class AboutApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        sliderData: [
          {
            status: true,
            img: app_index_slider_1
          },
          {
            status: false,
            img: app_index_slider_2
          },
          {
            status: false,
            img: app_index_slider_3
          },
          {
            status: false,
            img: app_index_slider_4
          },
          {
            status: false,
            img: app_index_slider_5
          },
          {
            status: false,
            img: app_index_slider_6
          }
        ],
        dashboardManagerImg: 0,
        appActiveMob: 0,
        activeSlide: 0
    }
  }
  handleSlideTo = (index) => {
    if (this.swiper) {
      this.swiper.slideTo(index);
    }
  };
  handlerGetImgDashboard(index, event){
    this.setState({
      dashboardManagerImg: index,
      appActiveMob: index
    })
  }
  componentDidMount() {
  // Initialize Swiper after mounting the component
  if (typeof window !== 'undefined') {
    this.swiper = new Swiper(".swiper-dashboard-wgts", {
    // Configuration options here
    direction: "vertical",
    slidesPerView: 1,
    spaceBetween: 30,
    on: {
      slideChange: () => {
        this.setState({ activeSlide: this.swiper.activeIndex });
      },
    },
  });
  setInterval(() => {
    if(this.state.activeSlide === (this.state.sliderData.length - 1)){
      this.swiper.slideTo(0);
      this.setState({ activeSlide: 0});
    } else {
      this.setState({ activeSlide: (this.swiper.activeIndex + 1) });
      this.swiper.slideTo(this.swiper.activeIndex + 1);
    }
  }, 2500);
}
}
  render() {
    let appTags = [
      this.props.getTranslate('aboutPanel-AboutAppHeadlineFirst'),
      this.props.getTranslate('aboutPanel-AboutAppHeadlineSecond'),
      this.props.getTranslate('aboutPanel-AboutAppHeadlineThird'),
      this.props.getTranslate('aboutPanel-AboutAppHeadlineFour'),
      this.props.getTranslate('aboutPanel-AboutAppHeadlineFive'),
      this.props.getTranslate('aboutPanel-AboutAppHeadlineSix')
    ]
    return <>
      <section className="about-info-app">
        <div className="wrapper">
          <div className="about-info-app__inner about-info-app__inner_theme_dark">
            <div className="about-info-app__col">
              <h2 className="about-info-app__headline">{this.props.getTranslate('aboutPanel-AboutAppHeadline')}</h2>
              <ul className="about-info-app__list">
              {this.state.sliderData.map((item, index) => {
                return <li key={index} className="about-info-app__list-item" onClick={() => this.handlerGetImgDashboard(index)}>
                  <button className={`about-info-app__list-btn about-info-app__list-btn-desctop${index === this.state.activeSlide ? ' active' : ''}`} type="button" onClick={() => this.handleSlideTo(index)}>
                    <span>
                      <svg className="about-info-app__list-btn-icon">
                        <use href={`${sprite}#arrow-right`}></use>
                      </svg>
                    </span>{appTags[index]}
                  </button>
                  <button className={`about-info-app__list-btn about-info-app__list-btn-mob${index === this.state.appActiveMob ? ' active' : ''}`} type="button" onClick={() => this.handleSlideTo(index)}>
                    <span>
                      <svg className="about-info-app__list-btn-icon">
                        <use href={`${sprite}#arrow-right`}></use>
                      </svg>
                    </span>{appTags[index]}
                  </button>
                  <div className="about-info-app__list-item-thumbnail-wrap">
                    {index === this.state.appActiveMob ? <img className="about-info-app__list-item__thumbnail" src={item.img} alt={appTags[index]} /> : ''}
                  </div>
                </li>
              })}
              </ul>
              <div className="about-info-app__footer">
                <Button className="btn_size_large" type="link" text={this.props.getTranslate('aboutPanel-AboutAppBtnMore')} handler={this.props.getPermalink('/about')} btnSize="large" />
              </div>
            </div>
            <div className="about-info-app__col">
              <div className="about-info-app__col-thumbnail">
              <div className="swiper-container swiper-dashboard-wgts">
                <div className="swiper-wrapper">
                {this.state.sliderData.map((item, index) => {
                return <div className="swiper-slide">
                  <img className="about-info-app__thumbnail about-info-app__thumbnail-about-app" src={item.img} alt={appTags[index]} />
                </div>
                })}
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>;
  }
}
export default AboutApp;
