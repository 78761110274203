import React from "react";
import { Link } from "react-router-dom";
import sprite from '../../media/icons.svg';
class ServiceBanner extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <section className="service-banner">
      <div className="service-banner__inner" style={{ backgroundColor: this.props.bg }}>
        <div className="wrapper">
          <div className="breadcrumbs">
            <Link className="breadcrumbs__item breadcrumbs__link" to="/">Головна</Link>
            <span className="breadcrumbs__sep">
              <svg className="breadcrumbs__sep-icon">
                <use href={`${sprite}#right-icon`}></use>
              </svg>
            </span>
            <span className="breadcrumbs__item breadcrumbs__active">Назва сторінки</span>
          </div>
          <div className="service-banner__content">
            <div className="service-banner__col">
              <div className="service-banner__headline-wrap">
                <h1 className="service-banner__headline">{this.props.headline}</h1>
              </div>
              <div className="service-banner__description-wrap">
                <p>{this.props.description}</p>
              </div>
              <div className="service-banner__btn">{this.props.btn}</div>
            </div>
            <div className="service-banner__col">
              <div className="service-banner__img">
                <img src={this.props.img} alt="" />
              </div>
            </div>
          </div>
          <div className="service-banner__btn-mob">{this.props.btn}</div>
        </div>
      </div>
    </section>
  }
}
export default ServiceBanner;
