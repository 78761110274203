import React from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Loading from './components/Loading';
import Header from './components/Header';
import Footer from './components/Footer';
import Button from "./components/Button";
import sprite from './media/icons.svg';
import forgotPassModal from './media/ForgotPassModal.png';
function validateEmail(email) {
  var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(email).toLowerCase());
}
class Forgot extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        loading: false,
        email: '',
        errorEmail: false,
        emptyEmail: false,
        errorUser: false,
        errorFields: false,
        modalDisabled: false,
        modalSend: false,
        errorCode: ''
      };
    }
    handleModalDisabled = () => {
      document.querySelector('body').style.overflow = "";
      this.setState(prevState => ({
        modalDisabled: true
      }));
      setTimeout(() => {
        this.setState(prevState => ({
          modalSend: false,
          modalDisabled: false
        }));
      }, 500);
    }
    handlerEmail (event) {
      this.setState({
        email: event.target.value
      });
    }
    handlerSubmitForgot(event){
      let that = this;
      event.preventDefault();
      this.setState({
        errorFields: false,
        errorEmail: false,
        errorUser: false,
        errorCode: ''
      });
      if(this.state.email !== '' && validateEmail(this.state.email)) {
        this.setState({
          loading: true
        });
        let location;
        let language = 'uk-UA';
        if(typeof window !== "undefined" && typeof document !== 'undefined') {
          location = document.location.pathname;
          const isEnglish = location.startsWith('/en');
          if (isEnglish) {
            language = 'en-US'
          }
        }
        const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Accept-Language': language
        });
        fetch(`${that.props.queryLink}/api/v1/users/password/reset`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({"email": this.state.email})
        }).then(res => {
          if(res.status === 200) {
            this.setState({
              modalSend: true,
              loading: false
            });
          } else if(res.status === 404) {
            this.setState({
              errorUser: true,
              loading: false
            });
          } else {
            this.setState({
              loading: false
            });
          }
        });
      }
      if(this.state.email && !validateEmail(this.state.email)) {
        this.setState({
          errorEmail: true,
          loading: false
        });
      }
      if(this.state.email === '') {
        this.setState({
          errorFields: true
        });
      }
    }
    componentDidMount() {
      if(typeof window !== "undefined" && typeof document !== 'undefined'){
        window.scrollTo(0, 0);
      }
    }
  render() {
    let title = this.props.getTranslate('forgotPage-Title');
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = title ? title : 'MateSpace.io';
    }
    return <>
    <Header getPermalink={this.props.getPermalink} logged={this.props.logged} userPhoto={this.props.userPhoto} userName={this.props.userName} handlerChangeLanguage={this.props.handlerChangeLanguage} chooseLanguage={this.props.chooseLanguage} getTranslate={this.props.getTranslate} />
    <main>
      <section className="forgot">
        <div className="wrapper">
          <div className="auth-form-wgt">
            <h2 className="auth-form-wgt__headline">{this.props.getTranslate('remindpass-Title')}</h2>
            <form className="auth-form" onSubmit={this.handlerSubmitForgot.bind(this)}>
            <div className="field-wrap">
              <label className="field-wrap__label" htmlFor="email">{this.props.getTranslate('remindpass-EnterEmail')}</label>
              <input id="email" className={`field-wrap__input ${(this.state.errorFields && !this.state.email) || (this.state.email && this.state.errorEmail) || this.state.errorUser ? 'field-wrap__input_error' : ''}`} type="text" placeholder={this.props.getTranslate('remindpass-EnterEmailFieldCaption')} value={this.state.email} onChange={this.handlerEmail.bind(this)} />
              {this.state.errorFields && this.state.email === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
              {this.state.errorEmail && this.state.email ? <div className="validation-error">{this.props.getTranslate('validation-IncorrectEmail')}</div> : ''}
              {this.state.errorUser ? <div className="validation-error">{this.props.getTranslate('validation-ExistAccount')}</div> : ''}
            </div>
            <div className="field-wrap__submit">
              <button className="btn btn_size_lg btn_width_100" type="submit" disabled={this.state.loading ? true : false}>{this.props.getTranslate('remindpass-Button')}{this.state.loading && <Loading />}</button>
            </div>
            </form>
          </div>
        </div>
      </section>
    </main>
    <Footer getPermalink={this.props.getPermalink} getTranslate={this.props.getTranslate} />
    {this.state.modalSend ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.props.getTranslate('remindpass-SuccessTitle')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <div className="modal-forgot-success">
          <div className="modal-forgot-success__img-wrap">
            <img className="modal-forgot-success__img" src={forgotPassModal} alt="" />
          </div>
          <div className="modal-forgot-success__headline">{this.props.getTranslate('remindpass-SuccessDescription')}</div>
          <div className="modal-forgot-success__mail">{this.state.email}</div>
          <div className="field-wrap__submit">
            <Button type="button" text={this.props.getTranslate('remindpass-SuccessBtnClose')} btnWidth="full" handler={this.handleModalDisabled} btnSize="large" btnWidth="full" typeBtn="submit" />
          </div>
        </div></div></div> : ''}
    </>;
  }
}
export default Forgot;
