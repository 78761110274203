import React from "react";
import Header from './components/Header';
import Footer from './components/Footer';
import { Link } from "react-router-dom";
import notFound from './media/not-found.png';
class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false
    }
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errorSubmit: null
    }));
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return <>
      <Header getPermalink={this.props.getPermalink} logged={this.props.logged} userPhoto={this.props.userPhoto} userName={this.props.userName} handlerChangeLanguage={this.props.handlerChangeLanguage} chooseLanguage={this.props.chooseLanguage} getTranslate={this.props.getTranslate} />
      <main className="not-found">
        <div className="wrapper">
          <div className="not-found__inner">
            <img className="not-found__img" src={notFound} alt="" />
            <div className="not-found__headline">Помилка 404.</div>
            <div className="not-found__headline">Немає такої сторінки.</div>
            <p className="not-found__description">Спробуйте перезавантажити сторінку або зверніться до служби підтримки</p>
            <ul className="not-found__nav">
              <li>
                <Link className="btn btn_size_lg btn_width_100 btn__border" to={this.props.getPermalink("/contacts")}>Написати в підтримку</Link>
              </li>
              <li>
                <Link className="btn btn_theme_transparent btn_size_lg btn_width_100" to={this.props.getPermalink("/")}>Вернуться на главную</Link>
              </li>
            </ul>
          </div>
        </div>
      </main>
      <Footer getPermalink={this.props.getPermalink} getTranslate={this.props.getTranslate} />
    </>;
  }
}
export default NotFound;
