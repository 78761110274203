import React from "react";
import AccountHeader from './components/AccountHeader';
import DateFormating from './components/DateFormating';
import Navigation from './components/Navigation';
import Pagination from './components/Pagination';
import Loading from './components/Loading';
import Select from 'react-select';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import sprite from './media/icons.svg';
function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength - 3) + "...";
  } else {
    return text;
  }
}
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
class ControlPanelDemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitLoaded: false,
      mobileMenuStart: true,
      mobileMenu: false,
      modalDisabled: false,
      modal: false,
      controlRow: null,
      demoRequestID: null,
      demoRequestIndex: null,
      demoRequestResolution: false,
      demoData: [],
      resolutionValue: '',
      statusSelectItems: [
        { value: 'NEW', label: 'Нове' },
        { value:  'DONE', label: 'Опрацьоване'}
      ],
      statusValueModal: null,
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalDisabled: false,
        messageModal: false,
        answerModal: false,
        demoRequestResolution: false,
        demoRequestID: null,
        demoRequestIndex: null,
        modal: false
      }));
    }, 500);
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  handlerCloseElements(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      const div = document.querySelector( '.control-wgt');
      document.addEventListener('click', e => {
        const withinBoundaries = e.composedPath().includes(div);
        if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
          if ( ! withinBoundaries ) {
            this.setState( {
              controlRow: null
            } );
          }
        }
      });
    }
  }
  componentDidMount() {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/demo-requests/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({"itemsPerPage": 20, "orderField": "modificationDate"})
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            demoData: data.data,
            totalPages: data.totalPages,
            isLoaded: true
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: [data.message],
            isLoaded: true,
            error: true
          });
        })
      }
    })
  })
  }
  handlerChangeStatusModal(event){
    this.setState({
      statusValueModal: event.value
    });
  }
  handlerResolutionValue(event){
    this.setState({
      resolutionValue: event.target.value
    });
  }
  handlerModalAddResolution(id, index, event){
    this.setState({
      demoRequestID: id,
      demoRequestIndex: index,
      demoRequestResolution: true,
      statusValueModal: this.state.demoData[index].status,
    });
  }
  handlerSubmitResolution(event){
    event.preventDefault();
    let that = this;
    this.setState({
      submitLoaded: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/bo/v1/configs/demo-requests/${that.state.demoRequestID}/resolutions`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
          resolution: that.state.resolutionValue,
          status: that.state.statusValueModal
        })
      }).then(res => {
        if (res.status === 200) {
          res.json().then(function(data) {
            let messages = that.state.demoData;
            messages[that.state.demoRequestIndex] = data;
            that.setState({
              demoData: messages,
              submitLoaded: false
            });
          })
          that.handleModalDisabled();
        } else {
          res.json().then(function(data) {
            that.setState({
              errors: data.message,
              submitLoaded: false
            });
          })
        }
      })
    })
  }
  handlerMessageType(status, event){
    let that = this;
    this.setState({
      isLoaded: false
    });
    let obj = {
      "direction": "DESC",
      "itemsPerPage": 20,
      "orderField": "modificationDate"
    };
    if(status !== ''){
      obj.status = status;
    }
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/demo-requests/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(obj)
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            demoData: data.data,
            totalPages: data.totalPages,
            isLoaded: true
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.message,
            isLoaded: true,
            error: true
          });
        })
      }
    })
  })
  }
  handlerStatus(id, index, status, event){
    let that = this;
    this.setState({
      isLoaded: false
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/demo-requests/${id}/status`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({status: status})
    }).then(res => {
      if (res.status === 200) {
        let messages = that.state.demoData;
        messages[index].status = status;
        res.json().then(function(data) {
          that.setState({
            demoData: messages,
            isLoaded: true,
            controlRow: null
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.message,
            isLoaded: true
          });
        })
      }
    })
  })
  }
  render() {
    let employeeCount = '';
    if(this.state.demoRequestID && this.state.demoData){
      if(this.state.demoData[this.state.demoRequestIndex].employeeCountType === 'TO_10'){
        employeeCount = 'До 10';
      } else if(this.state.demoData[this.state.demoRequestIndex].employeeCountType === 'FROM_10_TO_30'){
        employeeCount = '10 - 30';
      } else if(this.state.demoData[this.state.demoRequestIndex].employeeCountType === 'FROM_30_TO_50'){
        employeeCount = '30 - 50';
      } else {
        employeeCount = '50+';
      }
    }
    let demoDataTable = '';
    if(this.state.error) {
      demoDataTable = 'Помилка';
    } else if(!this.state.isLoaded){
      demoDataTable = <Loading />;
    } else {
      demoDataTable = <table className="standart-table standart-table__demo">
        <thead>
          <tr>
            <th>ПІБ</th>
            <th>Контакти</th>
            <th>Назва компанії</th>
            <th>Коментар</th>
            <th>Дата</th>
            <th>Статус</th>
            <th></th>
          </tr>
        </thead>
      <tbody>
      {this.state.demoData.map((item, index) => {
        return <><tr className="standart-table__row" key={index}>
                <td>{item.name}</td>
                <td>{item.contacts}</td>
                <td>{item.companyName}</td>
                <td><div className="standart-table__msg">{truncateText(item.message, 35)}</div></td>
                <td><DateFormating start={item.modificationDate} time="off" /></td>
                <td>
                <div className="control-wgt control-wgt__message">
                  <button className="control-btn control-btn__message" onClick={this.handlerControl.bind(this, `status-${index}`)}>
                  {item.status === 'NEW' ? <span className="bo-status-label">Нове</span> : <span className="bo-status-label bo-status-label_theme_green">Опрацьовано</span>}
                  </button>
                  <div className={`control-content ${this.state.controlRow === `status-${index}` ? 'active' : ''}`}>
                    <ul className="control-content__list">
                      <li>
                        <button className="control-content__btn" type="button" onClick={this.handlerStatus.bind(this, item.appealID, `status-${index}`, 'NEW')}>Нове</button>
                      </li>
                      <li>
                        <button className="control-content__btn" type="button" onClick={this.handlerStatus.bind(this, item.appealID, `status-${index}`, 'DONE')}>Опрацьовано</button>
                      </li>
                    </ul>
                  </div>
                </div>
                </td>
                <td>
                <div className="team-table__control">
                  <div className="control-wgt">
                    <button className="control-btn" onClick={this.handlerControl.bind(this, index)}>
                      <svg className="control-btn__icon">
                        <use href={`${sprite}#more-icon`}></use>
                      </svg>
                      </button>
                      <div className={`control-content ${this.state.controlRow === index ? 'active' : ''}`}>
                        <ul className="control-content__list">
                          <li>
                            <button className="control-content__btn" type="button" onClick={this.handlerModalAddResolution.bind(this, item.demoRequestID, index)}>
                              <svg className="control-content__icon">
                                <use href={`${sprite}#message-icon-v2`}></use>
                              </svg>Додати коментар</button>
                          </li>
                        </ul>
                      </div>
                      </div>
                  </div>
                </td>
              </tr>
              <tr>
                {item.resolution ? <td className="standart-table__msg-resolution-cell" colspan="7">
                  <div className="standart-table__msg-resolution">
                    <div className="standart-table__msg-resolution-header">
                      <div className="standart-table__msg-resolution-author">Комментар: Иванов А.</div>
                      <div className="standart-table__msg-resolution-date"><DateFormating start={item.resolutionDate} time="off" /></div>
                    </div>
                    <div className="standart-table__msg-resolution-txt">{item.resolution}</div>
                  </div>
                </td> : ''}
              </tr></>
            })}
      </tbody>
    </table>;
    }
    return <>
      <AccountHeader status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
      <div className="account-wrap account-wrap__admin" onClick={this.handlerCloseElements.bind(this)}>
        <Navigation status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
        <div className="account-content">
          <div className="account-content__inner">
            <div className="account-content__header">
              <div className="account-content__header-inner">
                <h1 className="account-content__headline">Запити на демо</h1>
              </div>
              <a className="header__language header__language-content" href="#">RU</a>
            </div>
            <div className="team-nav">
            <div className="control-wgt bo-control-wgt__message bo-control-wgt__message-demo">
              <button className="control-btn" onClick={this.handlerControl.bind(this, 'status')}>Всі повідомлення</button>
                <div className={`control-content ${this.state.controlRow === 'status' ? 'active' : ''}`}>
                  <ul className="control-content__list">
                    <li>
                      <button className="control-content__btn" type="button" onClick={this.handlerMessageType.bind(this, '')}>Всі повідомлення</button>
                    </li>
                    <li>
                      <button className="control-content__btn" type="button" onClick={this.handlerMessageType.bind(this, 'NEW')}>Нове</button>
                    </li>
                    <li>
                      <button className="control-content__btn" type="button" onClick={this.handlerMessageType.bind(this, 'DONE')}>Опрацьовано</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="account-content__content">
              <div className="account-wgt">
                {demoDataTable}
              </div>
            </div>

          </div>
        </div>
      </div>

      {this.state.demoRequestResolution ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal modal__appeal">
          <div className="modal-header">
            <h2 className="modal-headline">Додати коментар | {this.state.demoData[this.state.demoRequestIndex].companyName + ', ' + employeeCount}</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          <div className="bo-appeal-content">
            <div className="bo-appeal-content__text">
            <div>{this.state.demoData[this.state.demoRequestIndex].name + ' ' + this.state.demoData[this.state.demoRequestIndex].contacts}</div>
              <div>{this.state.demoData[this.state.demoRequestIndex].message}</div>
            </div>
            <form onSubmit={this.handlerSubmitResolution.bind(this)}>
              <div className="field-wrap">
                <textarea className="field-wrap__input field-wrap__input_textarea" value={this.state.resolutionValue} onChange={this.handlerResolutionValue.bind(this)} placeholder="Введіть текст нотаток" />
              </div>
              <div className="bo-appeal-content__field-status">
                <div className="field-wrap">
                  <Select
                    styles={customStyles}
                    options={this.state.statusSelectItems}
                    value={this.state.statusSelectItems[this.state.statusSelectItems.findIndex(el => el.value === this.state.statusValueModal)]}
                    onChange={this.handlerChangeStatusModal.bind(this)}
                    isSearchable={false}
                  />
                </div>
              </div>
              <div className="field-wrap__submit">
                 <button className="btn btn_size_lg" type="submit" disabled={this.state.submitLoaded ? true : false}>Додати нотатки{this.state.submitLoaded && <Loading />}</button>
              </div>
            </form>
          </div>
      </div>
    </div> : ''}

    </>
  }
}
export default ControlPanelDemo;
