import React from "react";
import Loading from '../components/Loading';
import ResultErrors from './components/ResultErrors';
import { Navigate } from "react-router-dom";
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import sprite from '../media/icons.svg';
import logo from '../mate_logo.svg';
const firebaseConfig = {
  apiKey: "AIzaSyCiErL0xg1EQ-FODI8KjJZbn0j73t6WcxU"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
class ControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      login: false,
      loginEmail: '',
      loginPass: '',
      passwordHide: true,
      token: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("token-admin") ? localStorage.getItem("token-admin") : '') : '',
      errorFields: false,
      errorEmail: false,
      errorRoles: false,
      errors: null,
      errorsCode: ''
    };
  }
  handlerLoginEmail (event) {
    this.setState({
      loginEmail: event.target.value
    });
  }
  handlerPasswordEmail (event) {
    this.setState({
      loginPass: event.target.value
    });
  }
  handlerPassword (event) {
    this.setState(prevState => ({
      passwordHide: !prevState.passwordHide
    }));
  };
  handlerSignIn (event) {
    event.preventDefault();
    let that = this;
    this.setState({
      loading: true
    });
    this.setState({
      errorFields: false,
      errorEmail: false,
      errorRoles: false,
      errorCode: '',
      errors: null
    });
    const auth = getAuth();
    signInWithEmailAndPassword(auth, this.state.loginEmail, this.state.loginPass)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        if(typeof window !== "undefined" && typeof document !== 'undefined'){
          const myHeaders = new Headers({
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + user.accessToken
          });
          fetch(`${that.props.queryLink}/bo/v1/configs/auth/after-login`, {
            method: 'GET',
            headers: myHeaders
          }).then(res => {
            if(res.status === 200){
                  that.setState({
                    login: true
                  });
                  localStorage.setItem("token-admin", JSON.stringify(user.accessToken))
            } else {
                  auth.signOut().then(() => {
                    that.setState({
                      loading: false
                    });
                  });
                  that.setState({
                    errorRoles: true
                  });
            }
          })

        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(error);
        this.setState({
          errorCode: errorCode,
          errors: [errorMessage],
          loading: false
        });
      });
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null,
      errorRoles: false,
      errorCode: ''
    }));
  }
  render() {
    return <>
    {this.state.errorRoles ? <ResultErrors errors={['У вас немає доступу']} closeHandler={this.handlerCloseError.bind(this)} /> : ''}
    {this.state.errorCode === 'auth/user-disabled' ? <ResultErrors errors={['Користувач не активний']} closeHandler={this.handlerCloseError.bind(this)} /> : ''}
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.login && <Navigate to="/controlpanel/dashboard" replace={true} />) : ''}
     <div className="login-admin">
      <div className="login-admin__inner">
        <img className="login-admin__logo" src={logo} alt="" />
        <form className="login-admin-form" onSubmit={this.handlerSignIn.bind(this)}>
          <div className="field-wrap">
            <label className="field-wrap__label" htmlFor="email">E-mail:</label>
            <input id="email" className="field-wrap__input" type="text" placeholder="Enter email" value={this.state.loginEmail} onChange={this.handlerLoginEmail.bind(this)} />
          </div>
          <div className="field-wrap">
            <label className="field-wrap__label" htmlFor="register-pass">Пароль:</label>
            <div className="field-wrap__inner">
              <input id="register-pass" className="field-wrap__input" type={this.state.passwordHide ? 'password' : 'text'} value={this.state.loginPass} onChange={this.handlerPasswordEmail.bind(this)} placeholder="Enter password" />
              <button className="field-wrap__hide-btn" type="button" onClick={this.handlerPassword.bind(this)}>
                <svg className="field-wrap__hide-icon">
                  <use href={this.state.passwordHide ? `${sprite}#hide-pass` : `${sprite}#view-pass`}></use>
                </svg>
              </button>
              {this.state.errors && (this.state.errorCode === 'auth/user-not-found' || this.state.errorCode === 'auth/wrong-password') ? <div className="validation-error">Не правильний e-mail або пароль</div> : ''}
              {this.state.errorFields && !this.state.loginPass ? <div className="validation-error">Обов'язкове поле'</div> : ''}
            </div>
          </div>
          <div className="login-admin__submit">
            <button className="btn btn_theme_login btn_size_lg btn_width_100" type="submit" disabled={this.state.loading ? true : false}>Log In{this.state.loading && <Loading />}</button>
          </div>
        </form>
      </div>
    </div></>
  }
}
export default ControlPanel;
