import React from "react";
import sprite from '../media/icons.svg';
import { Link } from "react-router-dom";
class ButtonIcon extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    if(this.props.theme === 'edit'){
      if(this.props.type === 'link'){
        return  <Link className={`btn-edit ${this.props.className ? this.props.className : ''}`} to={this.props.path}>
                    <svg className="btn-edit__icon">
                      <use href={`${sprite}#edit-icon`}></use>
                    </svg>
                  </Link>;
      } else {
        return <button className={`btn-edit ${this.props.className ? this.props.className : ''}`} onClick={this.props.handler.bind(this)}>
                <svg className="btn-edit__icon">
                  <use href={`${sprite}#edit-icon`}></use>
                </svg>
               </button>
      }
    } else if(this.props.theme === 'delete'){
      return  <button className={`btn-edit btn-edit_theme_remove ${this.props.className ? this.props.className : ''}`} onClick={this.props.handler.bind(this)}>
                <svg className="btn-edit__icon">
                  <use href={`${sprite}#delete-red`}></use>
                </svg>
              </button>
    } else {
      return '';
    }
  }
}
export default ButtonIcon;
