import React from "react";
import AccountHeader from './components/AccountHeader';
import AccountContentHeader from './components/AccountContentHeader';
import AccountNav from './components/AccountNav';
import ResultErrors from './components/ResultErrors';
import Loading from './components/Loading';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { useParams, Navigate } from "react-router-dom";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import sprite from './media/icons.svg';
import img from './media/img-event-2.png';
import { getAuth } from "firebase/auth";
let Editor;
let EditorState;
let ContentState;
let convertFromHTML;
let stateToHTML;

if (typeof window !== 'undefined') {
  Editor = require('react-draft-wysiwyg').Editor;
  EditorState = require('draft-js').EditorState;
  ContentState = require('draft-js').ContentState;
  convertFromHTML = require('draft-js').convertFromHTML;
  stateToHTML = require('draft-js-export-html').stateToHTML;

  require('../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
}
let arrayMoveImmutable;
if (typeof window !== 'undefined') {
  // Выполняем импорт только в клиентской среде
  const { arrayMoveImmutable: clientArrayMoveImmutable } = require('array-move');
  arrayMoveImmutable = clientArrayMoveImmutable;
}
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const type = [
  { value: 'AD', label: 'Обьявление' }
];
const SortableItem = SortableElement(({item, value, handlerDelete}) => <div className="edit-product-img-wrap"><button className="edit-product-img__remove" type="button" onClick={handlerDelete.bind(this, value.photoID)}><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><span className="product-num">{item + 1}</span>{item < 1 ? <span className="product-label">Обложка</span> : ''}<img src={value.url} alt="" /></div>);

const SortableList = SortableContainer(({items, handlerDelete}) => {
  return (
    <div className="edit-product-img">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} item={index} index={index} value={value} handlerDelete={handlerDelete} />
      ))}
    </div>
  );
});
class AdsEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      isLoadedSubmitEditAd: false,
      errorEditAd: [],
      cardList: [img],
      inputHeadline: '',
      textDescription: '',
      selectType: '',
      statusAd: '',
      inputPrice: 0,
      photos: [],
      errorFields: false,
      successSubmit: false,
      formData: null,
      percentValue: 0,
      adID: null,
      currencyValue: '',
      contacts: [],
      errors: null,
      error: false,
      currencyItems: [],
      errorFormatImgs: false,
      errorSizeImgs: false,
      statusItems: [
        { value: 'ACTIVE', label: this.props.getTranslate('marketplace-StatusActive') },
        { value:  'HIDDEN', label: this.props.getTranslate('marketplace-StatusNotActive')}
      ],
      contactsItems: [
        { value: 'PHONE', label: this.props.getTranslate('marketplace-contactItemPhone')},
        { value: 'EMAIL', label: this.props.getTranslate('marketplace-contactItemEmail')},
        { value: 'TELEGRAM', label: 'Telegram'},
        { value: 'WATS_APP ', label: 'WatsApp'}
      ]
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(prevState => ({
      photos: arrayMoveImmutable(prevState.photos, oldIndex, newIndex)
    }));
  }
  handlerInputHeadline (event) {
    if(this.state.errorOnlySpecialHeadline){
      this.setState({
        errorOnlySpecialHeadline: false
      });
    }
    if(this.state.errorFields){
      this.setState({
        errorFields: false
      });
    }
    if(event.target.value.length <= 1000){
      this.setState({
        inputHeadline: event.target.value,
        inputHeadlineLength: false
      });
    } else {
      this.setState({
        inputHeadlineLength: true
      });
    }

  }
  handlerTextDescription (event) {
    this.setState({
      textDescription: event.target.value
    });
  }
  handlerRepeatAd (event) {
    this.setState({
      selectType: event.value
    });
  }
  handlerInputPrice (event) {
    this.setState({
      inputPrice: event.target.value
    });
  }
  handlerSubmitEditBoard (event) {
    event.preventDefault();
    let that = this;
    if(this.state.errorFields){
      this.setState({
        errorFields: false
      });
    }
    if(this.state.inputHeadline) {
      this.setState({
        isLoadedSubmitEditAd: true
      });
      let obj = {
        "adID": this.props.id,
        "adType": this.state.selectType,
        "currency": this.state.currencyValue,
        "description": (typeof window !== "undefined" && typeof document !== 'undefined') ? stateToHTML(this.state.contentState.getCurrentContent()) : '',
        "price": this.state.inputPrice,
        "title": this.state.inputHeadline,
        "status": this.state.statusAd,
        "contacts": this.state.contacts
      };
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/ads/${that.props.id}`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(obj)
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoadedSubmitEditAd: false,
              errors: data.message
            })
          });
        } else {
          if(!that.state.formData){
            if(typeof window !== "undefined" && typeof document !== 'undefined'){
              localStorage.setItem("successAds", JSON.stringify({status: true, text: that.props.getTranslate('successStatus-Save')}));
            }
            that.setState({
              successSubmit: true,
              isLoadedSubmitEditAd: false,
            });
            setTimeout(() => {
              that.setState({
                successSubmit: false
              });
            }, 5000);
          }
          if(that.state.formData){
            const myHeaders = new Headers({
              'Authorization': 'Bearer ' + idToken
            });
            let formData = new FormData();
            let i = that.state.formData.length;
            let countPercent = 100 / i;
            for (let file of that.state.formData) {
              formData.append('file', file);
              fetch(`${that.props.queryLink}/api/v1/ads/${that.props.id}/photos`, {
                method: 'POST',
                headers: myHeaders,
                body: formData
              }).then(res => {
                if(res.status !== 200) {
                  res.json().then(function(data) {
                    that.setState({
                      errors: data.errors
                    })
                  })
                } else {
                  res.json().then(function(data) {
                    that.setState(prevState => ({
                      percentValue: (Math.ceil(prevState.percentValue + countPercent))
                    }));
                    i--;
                    if(i < 1){
                      if(typeof window !== "undefined" && typeof document !== 'undefined'){
                        localStorage.setItem("successAds", JSON.stringify({status: true, text: that.props.getTranslate('successStatus-Save')}))
                      }
                      that.setState({
                        isLoadedSubmitEditAd: false,
                        successSubmit: true
                      });
                      setTimeout(() => {
                        that.setState({
                          successSubmit: false
                        });
                      }, 5000);
                    }
                  })
                }
              })
              formData.delete('file');
            }
            Promise.all(that.state.formData).then(function() {
              console.log (i);
            });
          };
        }
      })
    })
      } else {
        this.setState({
          errorFields: true
        });
      }
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    this.setState({
      adID: this.props.id
    })
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/ads/${that.props.id}`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            errors: data.message,
            error: true
          })
        });
      } else {
        res.json().then(function(data) {
          that.setState(prevState => {
            // Инициализируем начальные валютные значения
            const initialCurrencyItems = [
              { value: 'USD', label: 'USD' },
              { value: 'UAH', label: 'ГРН' },
              { value: 'EUR', label: 'EUR' },
              { value: that.props.currency, label: that.props.currency }
            ];

            // Проверяем наличие валюты в предыдущем состоянии
            const isCurrencyExists = initialCurrencyItems.some(item => item.value === data.currency);

            // Если валюта указана и не существует, добавляем ее
            const updatedCurrencyItems = isCurrencyExists ? initialCurrencyItems : [
              ...initialCurrencyItems,
              { value: data.currency, label: data.currency }
            ];

            // Обновляем состояние с обновленным списком валют
            return { currencyItems: updatedCurrencyItems };
          });
          const index = type.map(e => e.value).indexOf(data.adType);
          that.setState({
            isLoaded: true,
            inputHeadline: data.title,
            contentState: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(data.description)
              )
            ),
            selectType: data.adType,
            inputPrice: data.price,
            currencyValue: data.currency,
            contacts: data.contacts,
            statusAd: data.adStatus,
            photos: data.adPhotos,
            selectTypeElem: <Select
              defaultValue={type[index]}
              styles={customStyles}
              options={type}
              onChange={that.handlerRepeatAd.bind(that)}
              isSearchable={false}
              placeholder='Выберите из списка'
            />
          });
        });
      }
    })
  })
  }
  onEditorStateChange = (contentState) => {
    this.setState({
      contentState: contentState
    });
  };
  hanlderRemoveProductImage(id, event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/ads/${that.props.id}/photos/${id}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          })
        });
      } else {
        let photos = this.state.photos;
        let index = photos.map(el => el.photoID).indexOf(id);
        photos.splice(index, 1);
        this.setState(prevState => ({
          prevState: [...prevState.photos, photos]
        }));
      }
    })
  })
  }
  handlerAddPhotos(event){
    let error = false;
    let files = event.target.files;
    if(files){
      for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileName = file.name.toLowerCase();
          const extension = fileName.split('.').pop();
          const fileSizeInMB = file.size / (1024 * 1024);
          if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png' && extension !== 'webp') {
              error = true;
              this.setState(prevState => ({
                errorFormatImgs: true
              }));
          }
          if (fileSizeInMB > 20) {
              error = true;
              this.setState(prevState => ({
                errorSizeImgs: true
              }));
          }
      }
    }
    if(!error){
      let formData = this.state.formData;
      if(formData){
        const dt = new DataTransfer();
        for (let file of formData) {
          dt.items.add(file)
        }
        for (let file of files) {
          dt.items.add(file)
        }
        this.setState(prevState => ({
          formData: Array.from(dt.files)
        }));
      } else {
        this.setState(prevState => ({
          formData: Array.from(files)
        }));
      }
    }
  }
  handleStatus(event){
    this.setState({
      statusAd: event.value
    });
  }
  handlerInputContact(index, event){
    let contacts = this.state.contacts;
    let item = contacts[index];
    item.contact = event.target.value;
    this.setState(prevState => ({
      contacts: contacts
    }));
  }
  handleChangeContact(index, event){
    let contacts = this.state.contacts;
    let item = contacts[index];
    item.type = event.value;
    this.setState(prevState => ({
      contacts: contacts
    }));
  }
  handleChangeCurrency(event){
    if(event !== null){
      this.setState({
        currencyValue: event.value
      });
    } else {
      this.setState({
        currencyValue: ''
      });
    }
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerAddContact(event){
    this.setState(prevState => ({
      contacts: [...prevState.contacts, {contact: '', type: 'PHONE'}]
    }));
  }
  handlerRemoveContact(index, event){
    const newArray = this.state.contacts;
    newArray.splice(index, 1);
    this.setState({ contacts: newArray });
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('marketplace-EditTitle') + ' | ' + this.props.companyName;
    }
    const statusItems = [
      { value: 'ACTIVE', label: this.props.getTranslate('marketplace-StatusActive') },
      { value:  'HIDDEN', label: this.props.getTranslate('marketplace-StatusNotActive')}
    ];
    const contactsItems = [
      { value: 'PHONE', label: this.props.getTranslate('marketplace-contactItemPhone')},
      { value: 'EMAIL', label: this.props.getTranslate('marketplace-contactItemEmail')},
      { value: 'TELEGRAM', label: 'Telegram'},
      { value: 'WATS_APP ', label: 'WatsApp'}
    ];
    const formatCreateLabel = (inputValue) => {
      return `Додати "${inputValue}"`;
    };
    let resultContent = '';
    if(this.state.error) {
      resultContent = <div className="errors-content">Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultContent = <Loading />;
    } else {
      resultContent =           <div className="board-form-wrap">
            <form className="board-form" onSubmit={this.handlerSubmitEditBoard.bind(this)}>
              <div className="board-form__inner">
                <div className="field-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="headline">{this.props.getTranslate('marketplace-Caption')}</label>
                  <input id="headline" className={`field-wrap__input field-wrap__input_width_75 ${this.state.inputHeadlineLength || (this.state.errorFields && !this.state.inputHeadline) ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.inputHeadline} onChange={this.handlerInputHeadline.bind(this)} placeholder={this.props.getTranslate('marketplace-CaptionFiledCaption')} autocomplete="off" />
                  {this.state.errorFields && !this.state.inputHeadline ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                  {this.state.inputHeadlineLength ? <div className="validation-error">{this.props.getTranslate('validation-marketplaceTitleLength')}</div> : ''}
                </div>
                <div className="field-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="description">{this.props.getTranslate('marketplace-Description')}</label>
                  {(typeof window !== "undefined" && typeof document !== 'undefined') ? <Editor
                    toolbar={{
                      options: ['inline', 'blockType', 'list'],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline']
                      },
                      blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      list: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['unordered', 'ordered']
                      }
                    }}
                    wrapperClassName="wrapper-class editor-field"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class editor-toolbar"
                    editorState={this.state.contentState}
                    onEditorStateChange={this.onEditorStateChange}
                  /> : ''}
                </div>
                <div className="board-form__row">
                  <div className="field-wrap">
                    <div className="field-wrap__label-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="price">{this.props.getTranslate('marketplace-Price')}</label>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>{this.props.getTranslate('tooltipAds-PriceAd')}</p>
                        </div>
                      </div>
                    </div>
                    <input id="price" className="field-wrap__input" type="text" value={this.state.inputPrice} onChange={this.handlerInputPrice.bind(this)} placeholder={this.props.getTranslate('marketplace-PriceFieldCaption')} />
                  </div>
                  <div className="field-wrap">
                    <div className="field-wrap__label-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('marketplace-Currency')}</label>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>{this.props.getTranslate('tooltipAds-CurrencyAd')}</p>
                        </div>
                      </div>
                    </div>
                    <CreatableSelect
                      styles={customStyles}
                      options={this.state.currencyItems}
                      value={this.state.currencyItems[this.state.currencyItems.findIndex(el => el.value === this.state.currencyValue)]}
                      onChange={this.handleChangeCurrency.bind(this)}
                      placeholder={this.props.getTranslate('marketplace-CurrencyCaption')}
                      formatCreateLabel={formatCreateLabel}
                    />
                  </div>
                </div>
                <div className="field-wrap">
                    <div className="field-wrap__label-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('marketplace-Contacts')}</label>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>{this.props.getTranslate('tooltipAds-ContactsAd')}</p>
                        </div>
                      </div>
                    </div>
                  {this.state.contacts ? <>
                  {this.state.contacts.map((item, index) => {
                    return <div className="board-form__row" key={index}>
                      <div className="field-wrap">
                        <Select
                          styles={customStyles}
                          options={contactsItems}
                          value={contactsItems[contactsItems.findIndex(el => el.value === item.type)]}
                          onChange={this.handleChangeContact.bind(this, index)}
                          placeholder={this.props.getTranslate('marketplace-ContactsCaption')}
                          isSearchable={false}
                        />
                      </div>
                      <div className="field-wrap field-wrap__remove">
                        <input className="field-wrap__input" type="text" value={item.contact} onChange={this.handlerInputContact.bind(this, index)} placeholder={this.props.getTranslate('marketplace-ContactsItemCaption')} />
                        {this.state.contacts.length > 1 ?<button className="ads-delete-contact" type="button" onClick={this.handlerRemoveContact.bind(this, index)}>
                          <svg>
                            <use href={`${sprite}#delete-red`}></use>
                          </svg>
                        </button> : ''}
                      </div>
                    </div>
                  })}</> : <div className="board-form__row">
                    <div className="field-wrap">
                      <Select
                        styles={customStyles}
                        options={contactsItems}
                        placeholder={this.props.getTranslate('marketplace-ContactsCaption')}
                        isSearchable={false}
                      />
                    </div>
                    <div className="field-wrap">
                      <input className="field-wrap__input" type="text" placeholder={this.props.getTranslate('marketplace-ContactsItemCaption')} />
                    </div>
                  </div>}
                  <button className="account-wgt__edit-btn" type="button" onClick={this.handlerAddContact.bind(this)}>{this.props.getTranslate('marketplace-ContactsAddItem')}</button>
                </div>
                <div className="edit-user-form__imgs">
                  <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('marketplace-Photo')}</label>
                  <div className="edit-user-form__imgs-inner">
                  <div className={`edit-user-upload${this.state.errorFormatImgs || this.state.errorSizeImgs ? ' edit-user-upload-error' : ''}`}>
                    <input type="file" id="upload" multiple onChange={this.handlerAddPhotos.bind(this)} />
                    <label htmlFor="upload">
                      <svg className="upload__icon">
                        <use href={`${sprite}#img-upload`}></use>
                      </svg>
                    {this.props.getTranslate('marketplace-AddPhoto')} {this.state.formData && <span style={{marginTop: '5px'}}>{this.props.getTranslate('loadingImg-AmountTitle')} {this.state.formData.length}</span>}</label>
                  </div>
                  {this.state.formData && this.state.isLoadedSubmitEditAd ? <div className="edit-user-upload">
                    <div className="edit-user-upload__percent">
                      <span>{this.props.getTranslate('loadingImg-PercentTitle')} {this.state.percentValue}%</span>
                      <div className="edit-user-upload__bar">
                        <div className="edit-user-upload__bar-inner" style={{width: this.state.percentValue + '%'}}></div>
                      </div>
                    </div>
                  </div> : ''}
                    {this.state.photos ? <SortableList items={this.state.photos} onSortEnd={this.onSortEnd} axis={'xy'} handlerDelete={this.hanlderRemoveProductImage.bind(this)} /> : ''}
                  </div>
                </div>
                {this.state.errorFormatImgs ? <div className="validation-error">Формат зображень повинен бути jpg png або webp</div> : ''}
                {this.state.errorSizeImgs ? <div className="validation-error">Файл не повинен перевищувати 20 мегабайт</div> : ''}
              </div>
              <div className="field-wrap__submit">
                <button className="btn btn_size_lg field-wrap__submit-send" type="submit" disabled={this.state.isLoadedSubmitEditAd ? true : false}>{this.props.getTranslate('marketplace-SaveButton')}{this.state.isLoadedSubmitEditAd && <Loading />}</button>
              </div>
            </form>
          </div>
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {this.state.successSubmit && <Navigate to={this.props.getPermalink('/account/marketplace')} replace={true} />}
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} token={this.props.token()} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap">
      <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
      <div className="account-content">
        <div className="account-content__inner">

          {/* Account header component */}
          <AccountContentHeader
            handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
            languageActive={this.props.chooseLanguage}
            backBtn={true}
            backBtnType="link"
            backBtnClass="button-back_theme_gray"
            backBtnHandler={this.props.getPermalink("/account/marketplace")}
            backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
          />

          <div className="account-content__header-title">
            <h1 className="account-content__headline">{this.props.getTranslate('marketplace-EditTitle')}</h1>
          </div>
          {resultContent}
        </div>
      </div>
    </div>
    </>;
  }
}
export default (props) => <AdsEdit {...useParams()} {...props} />
