import React from "react";
import DateFormating from './components/DateFormating';
import AccountContentHeader from './components/AccountContentHeader';
import { useParams } from "react-router-dom";
import Loading from './components/Loading';
import Pagination from './components/Pagination';
import ResultErrors from './components/ResultErrors';
import sprite from './media/icons.svg';
import AccountNav from './components/AccountNav';
import AccountHeader from './components/AccountHeader';
import { getAuth } from "firebase/auth";
class Deposits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      totalPages: null,
      currentPage: 0,
      errorDeposits: null,
      isLoadedDeposits: false,
      isLoadedReturn: false,
      userDeposits: [],
      modalDisabled: false,
      modalReturn: false,
      idReturn: null,
      user: [],
      successSubmit: false,
      errorSubmit: null,
      errors: null
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handleModalDisabled = () => {
    document.querySelector('body').style.overflow = "";
    this.setState(prevState => ({
      modalDisabled: true,
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalReturn: false,
        modalDisabled: false
      }));
    }, 500);
  }
  handlerReturnBalance (id, event) {
      this.setState(prevState => ({
        modalReturn: !prevState.modalReturn,
        idReturn: id
      }))
  }
  handlerReturnTransactionSubmit(event){
    let that = this;
    if(this.state.idReturn){
      this.setState({
        isLoadedReturn: true
      });
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.token()
      });
      fetch(`${that.props.queryLink}/api/v1/deposits/${this.state.idReturn}`, {
        method: 'DELETE',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoadedReturn: false,
              modalEditLoading: false,
              errors: data.errors
            })
          });
        } else {
          that.handleModalDisabled();
          that.setState({
            isLoadedReturn: false,
            successSubmit: true
          });
          setTimeout(() => {
            that.setState({
              successSubmit: false
            });
          }, 5000);
        }
      })
    }
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/transactions/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "transactionType": "DEPOSIT",
        "itemsPerPage": 20,
        "userID": that.props.id
      })
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoadedDeposits: true,
            errors: data.errors
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            isLoadedDeposits: true,
            userDeposits: data.data,
            totalPages: data.totalPages
          });
        })
        fetch(`${that.props.queryLink}/api/v1/users/${that.props.id}/short-info`, {
          method: 'GET',
          headers: myHeaders
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              });
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                userName: (data.name ? data.name + ' ' : '') + (data.middleName ? data.middleName + ' ' : '') + (data.lastName ? data.lastName : '')
              });
            })
          }
        })
      }
    })
  })
  }
  handlerPaginationItems = (historyItems, current) => {
    this.setState({
      userDeposits: historyItems,
      currentPage: current
    });
  }
  handlerCloseSuccess (event) {
    event.preventDefault();
    this.setState(prevState => ({
      successSubmit: false
    }));
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.title = this.props.getTranslate('deposits-Title') + ' ' + (this.state.userName ? this.state.userName : '') + ' | ' + this.props.companyName;
    }
    let resultDeposit = '';
    if(this.state.errorDeposits) {
      resultDeposit = <div>Ошибка...</div>;
    } else if(!this.state.isLoadedDeposits) {
      resultDeposit = <Loading />;
    } else {
      resultDeposit = <div className="history-balance-wrap">
        <table className="history-balance">
          <thead>
            <tr>
              <th>{this.props.getTranslate('deposits-TableDate')}</th>
              <th>{this.props.getTranslate('deposits-TableSender')}</th>
              <th>{this.props.getTranslate('deposits-TableBalance')}</th>
              <th>{this.props.getTranslate('deposits-TableMessage')}</th>
            </tr>
          </thead>
          <tbody>
      {this.state.userDeposits.map((item, index) => {
        let typeInfo = '';
        if(item.transactionType === 'CORRECTION'){
          typeInfo = <span className="history-balance__balance-edit">{this.props.getTranslate('deposits-EditFromAdministrator')}</span>;
        } else if(item.transactionType === 'ORDER') {
          typeInfo = 'Замовлення №' + item.description;
          if(item.notes === 'COMPANY_SHOP'){
            typeInfo = typeInfo + ' каталог компанії ';
          } else if(item.notes === 'CUSTOM_SHOP') {
            typeInfo = typeInfo + ' з зовнішнього магазину';
          } else {
            typeInfo = item.description;
          }
        } else {
          typeInfo = item.description;
        }
        return <tr key={index}>
          <td><DateFormating start={item.creationDate} />{item.createdByAdmin ? ' (Admin)' : ''}</td>
          <td>{item.createdBy}</td>
          <td className="history-balance__balance">{String(item.transactionAmount).indexOf('-') !== -1 ? <span className="history-balance__balance-minus">{item.transactionAmount}{this.props.currency ? ' ' + this.props.currency : ''}</span> : <span className="history-balance__balance-plus">{item.transactionAmount}{this.props.currency ? ' ' + this.props.currency : ''}</span>}
          </td>
          <td>{typeInfo}</td>
        </tr>}
    )}
    </tbody>
  </table>
  </div>}
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {this.state.successSubmit && <div className="response-submit">
    <div className="response-submit__item response-submit__item_success">Успешно сохранено!
      <button className="response-submit__close" type="button" onClick={this.handlerCloseSuccess.bind(this)}>
      <svg><use href={`${sprite}#close-icon`}></use></svg>
      </button></div></div>}
      <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
      <div className="account-wrap">
        <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
        <div className="account-content">
          <div className="account-content__inner">
            <div>
            {/* Account header component */}
            <AccountContentHeader
              handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
              languageActive={this.props.chooseLanguage}
              backBtn={true}
              backBtnType="link"
              backBtnClass="button-back_theme_gray"
              backBtnHandler={this.props.getPermalink(`/account/users/view/${this.props.id}`)}
              backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
            />
                <div className="account-content__headline-wrap">
                  <h1 className="account-content__headline">{this.state.userName ? <>{this.props.getTranslate('deposits-Title')} {this.state.userName}</> : ''}</h1>
                </div>
              </div>
            <div className="account-content__content">
            <div className="account-wgt account-wgt__history">
              {resultDeposit}
            </div>
            </div>
            <div className="account-content__footer">
              <Pagination total={this.state.totalPages} perPage="20" path={`${this.props.queryLink}/api/v1/transactions/search`} currentPage={this.state.currentPage} otherParams={{"userID": this.props.id}} items={this.handlerPaginationItems} />
            </div>
          </div>
        </div>
      </div>;
      {this.state.modalReturn ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.props.getTranslate('modal-deleteDepositsTitle')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
          <div className="history-balance-return-label">{this.props.getTranslate('modal-deleteDepositsDescription')}</div>
          <button className="btn btn_size_lg btn_theme_remove btn_width_100 user-info__nav-btn form-nav__submit" type="button" disabled={this.state.isLoadedReturn ? true : false} onClick={this.handlerReturnTransactionSubmit.bind(this)}>{this.props.getTranslate('removeModal-DepositsButtonSubmit')}{this.state.isLoadedReturn && <Loading />}</button>
      </div>
      </div> : ''}
    </>;
  }
}
export default (props) => <Deposits {...useParams()} {...props} />
