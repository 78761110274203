import React from "react";
class DashboardStatisticWgt extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <div className="dashboard-content__statistics-wgt">
            <div className="dashboard-content__statistics-value">{this.props.value}</div>
            <div className="dashboard-content__statistics-name">{this.props.headline}</div>
           </div>
  }
}
export default DashboardStatisticWgt;
