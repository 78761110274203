import React from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { Link, Navigate } from "react-router-dom";
import sprite from '../media/icons.svg';
import logo_nav from '../logo_dark.svg';
class AccountNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : false) : '',
      roles: null,
      name: '',
      company: '',
      countMessages: null,
      countOrders: null,
      signOut: false,
      subMenuItems: [],
      dropdownMenuStatus: false,
      dropdownMenuId: null,
      clickedInside: false,
      completed: (typeof window !== "undefined" && typeof document !== 'undefined') ? localStorage.getItem("complateUser") : null,
      sendConfirm: false
    }
  }
  handlerDropdown (event) {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handlerGetDropdown(id, event){
    console.log(event);
    this.setState({
      dropdownMenuStatus: event.target.classList.contains('header__main-menu-btn-dropdown') && this.state.dropdownMenuStatus && id !== this.state.dropdownMenuId ? true : !this.state.dropdownMenuStatus,
      dropdownMenuId: id
    });
  }
  handlerClickInside(event){
    this.setState({
      dropdownMenuStatus: false,
      dropdownMenuId: null
    });
  }

  handleClickOutside(event) {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.setState({
          dropdownMenuStatus: false,
          dropdownMenuId: null
        });
      }
    }
  }

  setWrapperRef(node) {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      this.wrapperRef = node;
    }
  }
  signOut(e){
    e.preventDefault();
    this.setState( {
        signOut: true
    } );
    const auth = getAuth();
    signOut(auth).then(() => {
      localStorage.removeItem("token");
    }).catch((error) => {
      console.log(error);
    });
  }

  componentWillUnmount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }
componentDidMount() {
  if (typeof window !== "undefined" && typeof document !== 'undefined') {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  let that = this;
  let currentUser = getAuth().currentUser;
  if (currentUser) {
    currentUser.getIdToken().then(function (idToken) {
      const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/me`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if (res.status === 200) {
          res.json().then(function (data) {
            if (data.photo !== null) {
              localStorage.setItem("navPhoto", data.photo);
            }
            localStorage.setItem("navName", data.name);
            that.setState({
              roles: data.roles,
              name: data.name,
              photo: data.photo,
              completed: data.completed
            })
            if (data.completed && !JSON.parse(localStorage.getItem('complateUser'))) {
              localStorage.setItem("complateUser", JSON.stringify(data.completed));
            }
          })
        }
      })
      fetch(`${that.props.queryLink}/api/v1/companies`, {
        method: 'GET',
        headers: myHeaders
      })
        .then(res => res.json())
        .then((result) => {

          that.setState({
            company: result.name
          })
          fetch(`${that.props.queryLink}/api/v1/users/dashboards`, {
            method: 'GET',
            headers: myHeaders
          }).then(res => {
            if (res.status === 200) {
              res.json().then(function (data) {
                if (localStorage.getItem('countMessage') !== data.messages || !localStorage.getItem('countMessage')) {
                  localStorage.setItem('countMessage', data.messages);
                }
                if (localStorage.getItem('countOrders') !== data.newOrders || !localStorage.getItem('countOrders')) {
                  localStorage.setItem('countOrders', data.newOrders);
                }
              })
            }
          })
        },
          (error) => {

          }
        )
    })
  }
}

  handlerSubMenu(item, event){
    let items = this.state.subMenuItems;
    let indexIsset = items.indexOf(item);
    if(indexIsset !== -1){
      this.setState(prevState => ({
        subMenuItems: prevState.subMenuItems.filter(el => el !== item)
      }))
    } else {
      this.setState(prevState => ({
        subMenuItems: [...prevState.subMenuItems, item]
      }));
    }
  }

  handlerConfirmationSend(event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/confirmation/send`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status === 200) {
          that.setState({
            sendConfirm: true
          });
        }
      })
    })
  }

  render() {
    var role = '';
    if(this.state.roles){
      if(this.state.roles.indexOf('ROLE_ADMINISTRATOR') !== -1){
        role = 'Admin';
      } else {
        role = 'User';
      }
      if(typeof window !== "undefined" && typeof document !== 'undefined'){
        localStorage.setItem("navRoles", role);
      }
    }
    let location;
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      location = window.location.pathname;
    }
    let isEnglish;

    let targetPath;
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      isEnglish = location.startsWith('/en');

      if (isEnglish) {
        targetPath = location === '/en' ? '/' : location.substring('/en'.length);
      } else {
        targetPath = `/en${location}`;
      }
    }
    return <>
    {this.state.signOut ? <Navigate to="/login" replace={true} /> : ''}
    {this.state.completed ? '' : <div className="completed-status">
      <div className="completed-status__inner">
        <div>{this.props.getTranslate('login-ConfirmMessage')}</div>
        {this.state.sendConfirm ? <span className="completed-status__send">{this.props.getTranslate('login-ConfirmMessageRepeat')}</span> : <button className="completed-status__send" type="button" onClick={this.handlerConfirmationSend.bind(this)}>{this.props.getTranslate('login-ConfirmMessageBtn')}</button>}
      </div>
    </div>}
    {this.props.status && <div className="account-nav__background" onClick={this.props.handler.bind(this, false)}></div>}
    <div className={`${this.state.completed ? '' : 'account-wrap__completed'} ${this.props.status ? 'account-nav active' : !this.props.statusStart ? 'account-nav disabled' : 'account-nav'}`}>
    <div className="account-nav__inner">
  <div className="account-nav__header">
    {this.props.status ? <button className="account-nav__close-btn" type="button" onClick={this.props.handler.bind(this, !this.props.status)}>
      <svg className="account-nav__close-icon">
        <use href={`${sprite}#close-icon`}></use>
      </svg>
    </button> : ''}
    <Link to={this.props.getPermalink('/account')}>
      <img className="account-nav__logo" src={logo_nav} alt="" />
    </Link>
    <form onSubmit={this.signOut.bind(this)}>
        <button className="account-nav__log-out" type="submit">
          <svg>
            <use href={`${sprite}#log-out`}></use>
          </svg>
        </button>
    </form>
  </div>
  <div className="account-nav__content">
    <div className="account-nav-list-wrap">
      <div className="account-nav-list-headline">{this.props.getTranslate('accMenu-SubTitleServices')}</div>
      <ul className="account-nav-list">
        {this.props.configsApp && this.props.configsApp.shop === true ? <li className="account-nav-list__item">
          <Link className={location === this.props.getPermalink('/account/products') ? 'account-nav-list__link active' : 'account-nav-list__link'} to={this.props.getPermalink('/account/products')}>
            <span className="account-nav-list__name">
            <svg className="account-nav-list__icon">
              <use href={`${sprite}#nav-shop`}></use>
            </svg>{this.props.getTranslate('accMenu-Catalog')}</span>
          </Link>
        </li> : ''}
        <li className="account-nav-list__item">
          <Link className={location === this.props.getPermalink('/account/orders') ? 'account-nav-list__link active' : 'account-nav-list__link'} to={this.props.getPermalink('/account/orders')}>
            <span className="account-nav-list__name">
              <svg className="account-nav-list__icon">
                <use href={`${sprite}#nav-orders`}></use>
              </svg>{this.props.getTranslate('accMenu-Orders')}</span>
              {(typeof window !== "undefined" && typeof document !== 'undefined') ? ((this.state.countOrders || localStorage.getItem('countOrders') && this.state.countOrders > 0 || localStorage.getItem('countOrders') > 0)  ? <span className="account-nav-list__label">{localStorage.getItem('countOrders') ? localStorage.getItem('countOrders') : this.state.countOrders}</span> : '') : ''}
          </Link>
        </li>
        {this.props.configsApp && this.props.configsApp.event === true ? <li className="account-nav-list__item">
          <Link className={location === this.props.getPermalink('/account/events') ? 'account-nav-list__link active' : 'account-nav-list__link'} to={this.props.getPermalink('/account/events')}>
            <span className="account-nav-list__name">
              <svg className="account-nav-list__icon">
                <use href={`${sprite}#nav-events`}></use>
              </svg>{this.props.getTranslate('accMenu-Events')}</span>
          </Link>
        </li> : ''}
        {this.props.configsApp && this.props.configsApp.survey === true ? <li className="account-nav-list__item">
          <Link className={location === this.props.getPermalink('/account/surveys/default') || location === this.props.getPermalink('/account/surveys/quick') ? 'account-nav-list__link active' : 'account-nav-list__link'} to={this.props.getPermalink('/account/surveys/default')}>
            <span className="account-nav-list__name">
              <svg className="account-nav-list__icon">
                <use href={`${sprite}#nav-polls`}></use>
              </svg>{this.props.getTranslate('accMenu-Polls')}</span>
          </Link>
        </li> : ''}
        {this.props.configsApp && this.props.configsApp.ad === true ? <li className="account-nav-list__item">
          <Link className={location === this.props.getPermalink('/account/marketplace') ? 'account-nav-list__link active' : 'account-nav-list__link'} to={this.props.getPermalink('/account/marketplace')}>
            <span className="account-nav-list__name">
              <svg className="account-nav-list__icon">
                <use href={`${sprite}#nav-ads`}></use>
              </svg>{this.props.getTranslate('accMenu-Marketplace')}</span>
          </Link>
        </li> : ''}
        {this.props.configsApp && this.props.configsApp.loyalty === true ? <li className="account-nav-list__item">
          <Link className={location === this.props.getPermalink('/account/benefits') ? 'account-nav-list__link active' : 'account-nav-list__link'} to={this.props.getPermalink('/account/benefits')}>
            <span className="account-nav-list__name">
              <svg className="account-nav-list__icon">
                <use href={`${sprite}#nav-bonuses`}></use>
              </svg>{this.props.getTranslate('accMenu-Bonuses')}</span>
          </Link>
        </li> : ''}
        {this.props.configsApp && this.props.configsApp.library === true ? <li className="account-nav-list__item">
          <Link className={location === this.props.getPermalink('/account/library') ? 'account-nav-list__link active' : 'account-nav-list__link'} to={this.props.getPermalink('/account/library')}>
            <span className="account-nav-list__name">
              <svg className="account-nav-list__icon">
                <use href={`${sprite}#nav-library`}></use>
              </svg>{this.props.getTranslate('accMenu-Library')}</span>
              {(this.props.newReview && this.props.newReview > 0) ? <span className="account-nav-list__label">{this.props.newReview}</span> : ''}
          </Link>
        </li> : ''}
      </ul>
    </div>
    <div className="account-nav-list-wrap">
      <div className="account-nav-list-headline">{this.props.getTranslate('accMenu-SubTitleCompany')}</div>
      <ul className="account-nav-list">
        <li className="account-nav-list__item">
        <Link className={location === this.props.getPermalink('/account/users') || location === this.props.getPermalink('/account/teams') ? 'account-nav-list__link active' : 'account-nav-list__link'} to={this.props.getPermalink('/account/users')}>
            <span className="account-nav-list__name">
            <svg className="account-nav-list__icon">
              <use href={`${sprite}#nav-users`}></use>
            </svg>{this.props.getTranslate('accMenu-Employees')}</span>
          </Link>
        </li>
        <li className="account-nav-list__item">
          <Link className={location === this.props.getPermalink('/account/accrual') ? 'account-nav-list__link active' : 'account-nav-list__link'} to={this.props.getPermalink('/account/accrual')}>
            <span className="account-nav-list__name">
            <svg className="account-nav-list__icon">
              <use href={`${sprite}#nav-benefits`}></use>
            </svg>{this.props.getTranslate('accMenu-Accruals')}</span>
          </Link>
        </li>
        <li className="account-nav-list__item">
          <Link className={location === this.props.getPermalink('/account/messages') ? 'account-nav-list__link active' : 'account-nav-list__link'} to={this.props.getPermalink('/account/messages')}>
            <span className="account-nav-list__name">
            <svg className="account-nav-list__icon">
              <use href={`${sprite}#nav-messages`}></use>
            </svg>{this.props.getTranslate('accMenu-Messages')}</span>
            {(typeof window !== "undefined" && typeof document !== 'undefined') ? ((this.state.countMessages || localStorage.getItem('countMessage') && this.state.countMessages > 0 || localStorage.getItem('countMessage') > 0)  ? <span className="account-nav-list__label">{localStorage.getItem('countMessage') ? localStorage.getItem('countMessage') : this.state.countMessages}</span> : '') : ''}
          </Link>
        </li>
        <li className="account-nav-list__item">
          <Link className={location === this.props.getPermalink('/account/faq') ? 'account-nav-list__link active' : 'account-nav-list__link'} to={this.props.getPermalink('/account/faq')}>
            <span className="account-nav-list__name">
              <svg className="account-nav-list__icon">
                <use href={`${sprite}#nav-onboarding`}></use>
              </svg>{this.props.getTranslate('accMenu-Onboarding')}</span>
          </Link>
        </li>
        <li className="account-nav-list__item">
          <Link className={location === this.props.getPermalink('/account/reminder') ? 'account-nav-list__link active' : 'account-nav-list__link'} to={this.props.getPermalink('/account/reminder')}>
            <span className="account-nav-list__name">
              <svg className="account-nav-list__icon">
                <use href={`${sprite}#nav-reminder`}></use>
              </svg>{this.props.getTranslate('accMenu-Reminder')}</span>
          </Link>
        </li>
        <li className="account-nav-list__item">
          <Link className={location === this.props.getPermalink('/account/rewards') ? 'account-nav-list__link active' : 'account-nav-list__link'} to={this.props.getPermalink('/account/rewards')}>
            <span className="account-nav-list__name">
              <svg className="account-nav-list__icon">
                <use href={`${sprite}#nav-rewards`}></use>
              </svg>{this.props.getTranslate('accMenu-Rewards')}</span>
          </Link>
        </li>
      </ul>
    </div>
    <div className="account-nav-list-wrap">
      <div className="account-nav-list-headline">{this.props.getTranslate('accMenu-SubTitleSettings')}</div>
      <ul className="account-nav-list">
        <li className="account-nav-list__item">
        {(typeof window !== "undefined" && typeof document !== 'undefined') ?
          <Link className={location === this.props.getPermalink('/account/user') ? 'account-nav-list__link account-nav-list__link_role active' : 'account-nav-list__link account-nav-list__link_role'} to={this.props.getPermalink('/account/user')}>
            <span className="account-nav-list__name">
            {localStorage.getItem("navPhoto") || this.state.photo ? <img src={localStorage.getItem("navPhoto") ? localStorage.getItem("navPhoto") : this.state.photo} alt="" /> : ''}
            <svg className="account-nav-list__icon" style={{"display": localStorage.getItem("navPhoto") || this.state.photo ? 'none' : 'block'}}>
              <use href={`${sprite}#nav-user`}></use>
            </svg>{localStorage.getItem("navName") ? localStorage.getItem("navName") : this.state.name}</span>
            <span className="account-nav-list__role">{localStorage.getItem("navRoles") ? localStorage.getItem("navRoles") : role}</span>
          </Link> : ''}
        </li>
        <li className="account-nav-list__item">
          <Link className={location === this.props.getPermalink('/account/company') ? 'account-nav-list__link active' : 'account-nav-list__link'} to={this.props.getPermalink('/account/company')}>
            <span className="account-nav-list__name">
            <svg className="account-nav-list__icon">
              <use href={`${sprite}#nav-company`}></use>
            </svg>{this.props.companyName}</span>
          </Link>
        </li>
        <li className={`${this.state.subMenuItems.indexOf('settings') !== -1 || location === this.props.getPermalink('/account/settings') || location === this.props.getPermalink('/account/notification') ? "account-nav-list__item active" : "account-nav-list__item"}`}>
          <span className="account-nav-list__link" onClick={this.handlerSubMenu.bind(this, 'settings')}>
            <span className="account-nav-list__name">
              <svg className="account-nav-list__icon">
                <use href={`${sprite}#nav-settings`}></use>
              </svg>{this.props.getTranslate('accMenu-CategoriesSett')}</span>
              <svg className="account-nav-list__arrow">
                <use href={`${sprite}#arrow-down`}></use>
              </svg>
          </span>
          <ul className="account-nav-list__sub">
            <li className="account-nav-list__item">
              <Link className={location === this.props.getPermalink('/account/settings') ? 'account-nav-list__link active' : 'account-nav-list__link'} to={this.props.getPermalink('/account/settings')}>
                <span className="account-nav-list__name">{this.props.getTranslate('accMenu-CategoriesSettSections')}</span>
              </Link>
            </li>
            <li className="account-nav-list__item">
              <Link className={location === this.props.getPermalink('/account/notification') ? 'account-nav-list__link active' : 'account-nav-list__link'} to={this.props.getPermalink('/account/notification')}>
                <span className="account-nav-list__name">{this.props.getTranslate('accMenu-CategoriesSettNotification')}</span>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div className="account-nav__footer">
    <div className="dropdown dropdown__account-nav">
      <button className="dropdown__btn" onClick={this.handlerDropdown.bind(this)}>
        <div className="dropdown__btn-name">
          <svg className="account-nav-list__icon">
            <use href={`${sprite}#lang-icon`}></use>
          </svg>{this.props.getTranslate('interface-Lang')}: {isEnglish ? 'English' : 'Українська'}
        </div>
        <svg className="dropdown__icon">
          <use href={`${sprite}#arrow-down`}></use>
        </svg>
      </button>
      <div className={`dropdown-content ${this.state.dropdownStatus ? 'active' : ''}`}>
        <ul className="dropdown-nav">
          <li className="dropdown-nav__item">
            {!isEnglish ? <span className="dropdown-nav__link">Українська</span> : <Link className="dropdown-nav__link" to={`/`}>Українська</Link>}
          </li>
          <li className="dropdown-nav__item">
            {isEnglish ? <span className="dropdown-nav__link">English</span> : <Link className="dropdown-nav__link" to={`/en${location}`}>English</Link>}
          </li>
        </ul>
      </div>
    </div>
    </div>
  </div>
</div></>
}
}
export default AccountNav;
