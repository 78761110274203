import React from "react";
import Button from './Button';
import ButtonIcon from './ButtonIcon';
import sprite from '../media/icons.svg';
import shopPhoneIcon from '../media/shop-phone-icon.png';
class NoteComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverPhone: false
    }
  }
  handlerHoverPhoneOn(){
    this.setState( {hoverPhone: true} )
  }
  handlerHoverPhoneOff(){
    this.setState( {hoverPhone: false} )
  }
  render() {
      return <div className="account-wgt__catalog-notes">
              {this.props.data ? <div className="account-wgt__catalog-notes-wgt">
                                  <div className="account-wgt__catalog-notes-wgt-content">
                                    <div className="account-wgt__catalog-notes-wgt-headline">{this.props.title}</div>
                                    <div className="account-wgt__catalog-notes-wgt-description">{this.props.description}</div>
                                  </div>
                                  <div className="account-wgt__catalog-notes-wgt-nav">
                                    <ButtonIcon theme="edit" type="button" handler={this.props.handlerEditNote.bind(this)} />
                                    <ButtonIcon theme="delete" handler={this.props.handlerDeleteNote.bind(this)} />
                                  </div>
                                </div> : <div className="add-notes-btn">
                                  <Button
                                    type="button"
                                    icon={true}
                                    iconItem="#plus-icon"
                                    text={this.props.btn}
                                    addClass="btn_theme_secondary"
                                    handler={this.props.handlerAddNote.bind(this)}
                                  />
                                  <div className="add-notes-btn__phone-wrap" onMouseEnter={this.handlerHoverPhoneOn.bind(this)} onMouseLeave={this.handlerHoverPhoneOff.bind(this)}>
                                      <svg className="add-notes-btn__phone">
                                        <use href={`${sprite}#phone-company`}></use>
                                      </svg>
                                      {this.state.hoverPhone ? <div className="add-notes-btn__phone-img">
                                        <img src={shopPhoneIcon} alt="" />
                                      </div> : ''}
                                  </div>
                                  </div>}
                                </div>
  }
}
export default NoteComment;
