import React from "react";
import { Link } from "react-router-dom";
import sprite from '../../media/icons.svg';
import img_dashboard_1 from '../../media/img-dashboard.png';
import img_dashboard_2 from '../../media/img-dashboard-2.png';
import img_dashboard_3 from '../../media/img-dashboard-3.png';
import img_dashboard_4 from '../../media/img-dashboard-4.png';
import img_dashboard_5 from '../../media/img-dashboard-5.png';
class HomeManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appActive: 0,
      appActiveMob: 0,
      showImage: true,
      tagsArr: [
        {
          status: true,
          img: img_dashboard_1
        },
        {
          status: false,
          img: img_dashboard_2
        },
        {
          status: false,
          img: img_dashboard_3
        },
        {
          status: false,
          img: img_dashboard_4
        },
        {
          status: false,
          img: img_dashboard_5
        }
      ]
    };
  }
  componentDidMount() {
    this.startAutoSwitching();
  }

  componentWillUnmount() {
    this.stopAutoSwitching();
  }

  startAutoSwitching = () => {
    this.interval = setInterval(() => {
      const tagsArr = this.state.tagsArr;
      const index = (this.state.appActive + 1) % tagsArr.length;
      tagsArr[this.state.appActive].status = false;
      tagsArr[index].status = true;
      this.setState({
        appActive: index,
        showImage: false,
        tagsArr: tagsArr
      });
      setTimeout(() => {
        this.setState({
          showImage: true,
        });
      }, 300);
    }, 5000);
  };

  stopAutoSwitching = () => {
    clearInterval(this.interval);
  };

  handerGetTag = (index, event) => {
    const tagsArr = this.state.tagsArr;
      this.stopAutoSwitching();
      tagsArr[this.state.appActive].status = false;
      tagsArr[index].status = true;
      this.setState(
        {
          appActive: index,
          appActiveMob: index,
          showImage: false
        },
        () => {
          setTimeout(() => {
            this.setState({
              showImage: true,
            });
          }, 100);
          this.startAutoSwitching(); // Запускаем автоматическое переключение после клика
        }
      );
  };
  render() {
    let tagHeadlines = [
      this.props.getTranslate('homeManager-TagDashboard'),
      this.props.getTranslate('homeManager-TagShopAndOrders'),
      this.props.getTranslate('homeManager-TagEventsAndSurveys'),
      this.props.getTranslate('homeManager-TagBonuses'),
      this.props.getTranslate('homeManager-TagSettings')
    ];
    return <section className="home-manager">
      <div className="wrapper">
        <div className="home-manager__inner">
          <div className="home-manager__col">
            <div className="main-headline-wrap">
              <h2 className="main-headline">{this.props.getTranslate('homeManager-Title')}</h2>
            </div>
            <div className="home-manager_description">
              <p>{this.props.getTranslate('homeManager-Description')}</p>
            </div>
            <Link className="main-link" to="/about-panel">{this.props.getTranslate('linkMore-Name')}
              <svg className="main-link__icon">
                <use href={`${sprite}#link-chevron`}></use>
              </svg>
            </Link>
            <div className="home-manager__tags">
              <ul className="home-manager-tags-list">
              {this.state.tagsArr.map((item, index) => {
                return <li className="home-manager-tags-list__item" key={index}>
                    <button className={`home-manager-tags-list__btn${index === this.state.appActive ? ' active' : ''}`} onClick={() => this.handerGetTag(index)}>{tagHeadlines[index]}</button>
                  </li>
              })}
              </ul>
            </div>
          </div>
          <div className="home-manager__col home-manager__img-wrap">
            <img
              src={this.state.tagsArr[this.state.appActive].img}
              alt={tagHeadlines[this.state.appActive]}
              className={`home-manager__img fade-in-out-image ${
                this.state.showImage ? 'fade-in' : 'fade-out'
              }`}
            />
          </div>
        </div>
        <ul className="about-info-app__list about-info-app__list-manager">
          {this.state.tagsArr.map((item, index) => {
            return                 <li className="about-info-app__list-item" key={index}>
                              <button className={`about-info-app__list-btn${index === this.state.appActiveMob ? ' active' : ''}`} type="button" onClick={() => this.handerGetTag(index)}>
                                <span>                      <svg className="about-info-app__list-btn-icon">
                                                        <use href={`${sprite}#arrow-right`}></use>
                                                      </svg></span>{tagHeadlines[index]}
                              </button>
                              <div className={`about-info-app__list-content${index === this.state.appActiveMob ? ' active' : ''}`}>
                                  <img
                                    src={this.state.tagsArr[index].img}
                                    alt={tagHeadlines[index]}
                                    className="about-info-app__list-item__thumbnail"
                                  />
                              </div>
                            </li>
          })}
        </ul>
      </div>
    </section>
  }
}
export default HomeManager;
