import React from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import smile from '../media/emoji-icon.svg';
class GetEmoji extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getSmileModal: false,
      smileValue: null,
      clickedInside: false,
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    this.setState({
      smileValue: this.props.smileValue
    })
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClick() {
    this.setState((prevState) => ({
      getSmileModal: !prevState.getSmileModal,
      clickedInside: true,
    }));
  }

  handlerOpen(event) {
    this.setState(prevState => ({
      getSmileModal: !prevState.getSmileModal
    }))
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && !this.state.clickedInside) {
      this.setState({
        getSmileModal: false,
      });
    }
    this.setState({ clickedInside: false });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setWrapperRefButton(node){
    this.wrapperRefButton = node;
  }

  handlerGetEmojies(event){
    this.setState(prevState => ({
      getSmileModal: !prevState.getSmileModal
    }));
  }

  handlerGetEmoji(smile, event) {
      this.setState({
        smileValue: smile,
        getSmileModal: false
      });
      if (typeof this.props.onChangeHandler === 'function') {
        this.props.onChangeHandler(smile);
      }
  }

  render() {
      const arr = ['&#128512;', '&#128515;', '&#128516;', '&#128513;', '&#128518;',
      '&#128517;', '&#129315;', '&#128514;', '&#128578;', '&#128579;', '&#128521;',
      '&#128522;', '&#128519;', '&#129392;', '&#128525;', '&#129321;', '&#128536;',
      '&#128535;', '&#128538;', '&#128537;', '&#128523;', '&#128539;', '&#128540;',
      '&#129322;', '&#128541;', '&#129297;', '&#129303;', '&#129325;', '&#129323;',
      '&#129300;', '&#129296;', '&#129320;', '&#128528;', '&#128529;', '&#128566;',
      '&#128527;', '&#128530;', '&#128580;', '&#128556;', '&#129317;', '&#128524;',
      '&#128532;', '&#128554;', '&#129316;', '&#128564;', '&#128567;', '&#129298;',
      '&#129301;', '&#129314;', '&#129326;', '&#129319;', '&#129397;', '&#129398;',
      '&#129396;', '&#128565;', '&#129327;', '&#129312;', '&#129395;', '&#128526;',
      '&#129299;', '&#129488;', '&#128533;', '&#128543;', '&#128577;', '&#128558;',
      '&#128559;', '&#128562;', '&#128563;', '&#129402;', '&#128550;', '&#128551;',
      '&#128552;', '&#128560;', '&#128549;', '&#128546;', '&#128557;', '&#128561;',
      '&#128534;', '&#128547;', '&#128542;', '&#128531;', '&#128553;', '&#128555;',
      '&#128548;', '&#128545;', '&#128544;', '&#129324;', '&#128520;', '&#128127;',
      '&#128128;', '&#128169;', '&#129313;', '&#128121;', '&#128122;', '&#128123;',
      '&#128125;', '&#128126;', '&#129302;', '&#128570;', '&#128568;', '&#128569;',
      '&#128571;', '&#128572;', '&#128573;', '&#128576;', '&#128575;', '&#128574;',
      '&#128584;', '&#128585;', '&#128586;', '&#128139;', '&#128140;', '&#128152;',
      '&#128157;', '&#128150;', '&#128151;', '&#128147;', '&#128158;', '&#128149;',
      '&#128159;', '&#10083;', '&#128148;', '&#10084;', '&#129505;', '&#128155;',
      '&#128154;', '&#128153;', '&#128156;', '&#128420;', '&#128175;', '&#128162;',
      '&#128165;', '&#128171;', '&#128166;', '&#128168;', '&#128371;', '&#128163;',
      '&#128172;', '&#128488;', '&#128495;', '&#128173;', '&#128164;', '&#128075;',
      '&#129306;', '&#128400;', '&#9995;', '&#128406;', '&#128076;', '&#9996;',
      '&#129310;', '&#129311;', '&#129304;', '&#129305;', '&#128072;', '&#128073;',
      '&#128070;', '&#128405;', '&#128071;', '&#9757;', '&#128077;', '&#128078;',
      '&#9994;', '&#128074;', '&#129307;', '&#129308;', '&#128079;', '&#128588;',
      '&#128080;', '&#129330;', '&#129309;', '&#128591;', '&#9997;', '&#128133;',
      '&#129331;', '&#128170;', '&#129461;', '&#129462;', '&#128066;', '&#128067;',
      '&#129504;', '&#129463;', '&#129460;', '&#128064;', '&#128065;', '&#128069;',
      '&#128068;', '&#128118;', '&#129490;', '&#128102;', '&#128103;', '&#129489;',
      '&#128113;', '&#128104;', '&#128113;&#8205;&#9794;&#65039;', '&#128104;&#8205;&#129456;', '&#128104;&#8205;&#129457;', '&#128104;&#8205;&#129459;',
      '&#128104;&#8205;&#129458;', '&#129492;', '&#128105;', '&#128113;&#8205;&#9792;&#65039;', '&#128105;&#8205;&#129456;', '&#128105;&#8205;&#129457;',
      '&#128105;&#8205;&#129459;', '&#128105;&#8205;&#129458;', '&#129491;', '&#128116;', '&#128117;', '&#128589;',
      '&#128589;&#8205;&#9794;&#65039;', '&#128589;&#8205;&#9792;&#65039;', '&#128590;', '&#128590;&#8205;&#9794;&#65039;', '&#128590;&#8205;&#9792;&#65039;', '&#128581;',
      '&#128581;&#8205;&#9794;&#65039;', '&#128581;&#8205;&#9792;&#65039;', '&#128582;', '&#128582;&#8205;&#9794;&#65039;', '&#128582;&#8205;&#9792;&#65039;', '&#128129;',
      '&#128129;&#8205;&#9794;&#65039;', '&#128129;&#8205;&#9792;&#65039;', '&#128587;', '&#128587;&#8205;&#9794;&#65039;', '&#128587;&#8205;&#9792;&#65039;', '&#128583;',
      '&#128583;&#8205;&#9794;&#65039;', '&#128583;&#8205;&#9792;&#65039;', '&#129318;', '&#129318;&#8205;&#9794;&#65039;', '&#129318;&#8205;&#9792;&#65039;', '&#129335;',
      '&#129335;&#8205;&#9794;&#65039;', '&#129335;&#8205;&#9792;&#65039;', '&#128104;&#8205;&#9877;&#65039;', '&#128105;&#8205;&#9877;&#65039;', '&#128104;&#8205;&#127891;', '&#128105;&#8205;&#127891;',
      '&#128104;&#8205;&#127979;', '&#128105;&#8205;&#127979;', '&#128104;&#8205;&#9878;&#65039;', '&#128105;&#8205;&#9878;&#65039;', '&#128104;&#8205;&#127806;', '&#128105;&#8205;&#127806;',
      '&#128104;&#8205;&#127859;', '&#128105;&#8205;&#127859;', '&#128104;&#8205;&#128295;', '&#128105;&#8205;&#128295;', '&#128104;&#8205;&#127981;', '&#128105;&#8205;&#127981;',
      '&#128104;&#8205;&#128188;', '&#128105;&#8205;&#128188;', '&#128104;&#8205;&#128300;', '&#128105;&#8205;&#128300;', '&#128104;&#8205;&#128187;', '&#128105;&#8205;&#128187;',
      '&#128104;&#8205;&#127908;', '&#128105;&#8205;&#127908;', '&#128104;&#8205;&#127912;', '&#128105;&#8205;&#127912;', '&#128104;&#8205;&#9992;&#65039;', '&#128105;&#8205;&#9992;&#65039;',
      '&#128104;&#8205;&#128640;', '&#128105;&#8205;&#128640;', '&#128104;&#8205;&#128658;', '&#128105;&#8205;&#128658;', '&#128110;', '&#128110;&#8205;&#9794;&#65039;',
      '&#128110;&#8205;&#9792;&#65039;', '&#128373;', '&#128373;&#65039;&#8205;&#9794;&#65039;', '&#128373;&#65039;&#8205;&#9792;&#65039;', '&#128130;', '&#128130;&#8205;&#9794;&#65039;',
      '&#128130;&#8205;&#9792;&#65039;', '&#128119;', '&#128119;&#8205;&#9794;&#65039;', '&#128119;&#8205;&#9792;&#65039;', '&#129332;', '&#128120;',
      '&#128115;', '&#128115;&#8205;&#9794;&#65039;', '&#128115;&#8205;&#9792;&#65039;', '&#128114;', '&#129493;', '&#129333;',
      '&#128112;', '&#129328;', '&#129329;', '&#128124;', '&#127877;', '&#129334;',
      '&#129464;', '&#129464;&#8205;&#9794;&#65039;', '&#129464;&#8205;&#9792;&#65039;', '&#129465;&#8205;&#9794;&#65039;', '&#129465;&#8205;&#9792;&#65039;', '&#129497;',
      '&#129497;&#8205;&#9794;&#65039;', '&#129497;&#8205;&#9792;&#65039;', '&#129498;', '&#129498;&#8205;&#9794;&#65039;', '&#129498;&#8205;&#9792;&#65039;', '&#129499;',
      '&#129499;&#8205;&#9794;&#65039;', '&#129499;&#8205;&#9792;&#65039;', '&#129500;', '&#129500;&#8205;&#9794;&#65039;', '&#129500;&#8205;&#9792;&#65039;', '&#129501;',
      '&#129501;&#8205;&#9794;&#65039;', '&#129501;&#8205;&#9792;&#65039;', '&#129502;', '&#129502;&#8205;&#9794;&#65039;', '&#129502;&#8205;&#9792;&#65039;', '&#129503;',
      '&#129503;&#8205;&#9794;&#65039;', '&#129503;&#8205;&#9792;&#65039;', '&#128134;', '&#128134;&#8205;&#9794;&#65039;', '&#128134;&#8205;&#9792;&#65039;', '&#128135;',
      '&#128135;&#8205;&#9794;&#65039;', '&#128135;&#8205;&#9792;&#65039;', '&#128694;', '&#128694;&#8205;&#9794;&#65039;', '&#128694;&#8205;&#9792;&#65039;', '&#127939;',
      '&#127939;&#8205;&#9794;&#65039;', '&#127939;&#8205;&#9792;&#65039;', '&#128131;', '&#128378;', '&#128372;', '&#128111;',
      '&#128111;&#8205;&#9794;&#65039;', '&#128111;&#8205;&#9792;&#65039;', '&#129494;', '&#129494;&#8205;&#9794;&#65039;', '&#129494;&#8205;&#9792;&#65039;', '&#129495;',
      '&#129495;&#8205;&#9794;&#65039;', '&#129495;&#8205;&#9792;&#65039;', '&#129338;', '&#127943;', '&#127938;', '&#127948;',
      '&#127948;&#65039;&#8205;&#9794;&#65039;', '&#127948;&#65039;&#8205;&#9792;&#65039;', '&#127940;', '&#127940;&#8205;&#9794;&#65039;', '&#127940;&#8205;&#9792;&#65039;', '&#128675;',
      '&#128675;&#8205;&#9794;&#65039;', '&#128675;&#8205;&#9792;&#65039;', '&#127946;', '&#127946;&#8205;&#9794;&#65039;', '&#127946;&#8205;&#9792;&#65039;', '&#9977;',
      '&#9977;&#65039;&#8205;&#9794;&#65039;', '&#9977;&#65039;&#8205;&#9792;&#65039;', '&#127947;', '&#127947;&#65039;&#8205;&#9794;&#65039;', '&#127947;&#65039;&#8205;&#9792;&#65039;', '&#128692;',
      '&#128692;&#8205;&#9792;&#65039;', '&#128693;', '&#128693;&#8205;&#9792;&#65039;', '&#129336;', '&#129336;&#8205;&#9794;&#65039;', '&#129340;',
      '&#129340;&#8205;&#9792;&#65039;', '&#129341;', '&#129342;', '&#129342;&#8205;&#9792;&#65039;', '&#129337;', '&#129496;',
      '&#129496;&#8205;&#9794;&#65039;', '&#128704;', '&#128716;', '&#128109;', '&#128107;', '&#128143;',
      '&#128104;&#8205;&#10084;&#65039;&#8205;&#128139;&#8205;&#128104;', '&#128145;', '&#128104;&#8205;&#10084;&#65039;&#8205;&#128104;', '&#128105;&#8205;&#10084;&#65039;&#8205;&#128105;', '&#128106;', '&#128104;&#8205;&#128105;&#8205;&#128102;',
      '&#128104;&#8205;&#128105;&#8205;&#128103;', '&#128104;&#8205;&#128105;&#8205;&#128103;&#8205;&#128102;', '&#128104;&#8205;&#128105;&#8205;&#128102;&#8205;&#128102;', '&#128104;&#8205;&#128105;&#8205;&#128103;&#8205;&#128103;', '&#128104;&#8205;&#128104;&#8205;&#128102;', '	&#128104;&#8205;&#128104;&#8205;&#128103;',
      '&#128104;&#8205;&#128104;&#8205;&#128103;&#8205;&#128102;', '&#128104;&#8205;&#128104;&#8205;&#128102;&#8205;&#128102;', '&#128104;&#8205;&#128104;&#8205;&#128103;&#8205;&#128103;', '&#128105;&#8205;&#128105;&#8205;&#128102;', '&#128105;&#8205;&#128105;&#8205;&#128103;', '&#128105;&#8205;&#128105;&#8205;&#128103;&#8205;&#128102;',
      '&#128105;&#8205;&#128105;&#8205;&#128102;&#8205;&#128102;', '&#128105;&#8205;&#128105;&#8205;&#128103;&#8205;&#128103;', '&#128104;&#8205;&#128102;', '&#128104;&#8205;&#128102;&#8205;&#128102;', '&#128104;&#8205;&#128103;', '&#128104;&#8205;&#128103;&#8205;&#128102;',
      '&#128104;&#8205;&#128103;&#8205;&#128103;', '&#128105;&#8205;&#128102;', '&#128105;&#8205;&#128102;&#8205;&#128102;', '&#128105;&#8205;&#128103;', '&#128105;&#8205;&#128103;&#8205;&#128102;', '&#128105;&#8205;&#128103;&#8205;&#128103;',
      '&#128053;', '&#128018;', '&#129421;', '&#128054;', '&#128021;', '&#128041;',
      '&#128058;', '&#129418;', '&#129437;', '&#128049;', '&#128008;', '&#129409;',
      '&#128047;', '&#128005;', '&#128006;', '&#128052;', '&#128014;', '&#129412;',
      '&#129427;', '&#129420;', '&#128046;', '&#128002;', '&#128003;', '&#128004;',
      '&#128055;', '&#128022;', '&#128023;', '&#128061;', '&#128015;', '&#128017;',
      '&#128016;', '&#128042;', '&#128043;', '&#129433;', '&#129426;', '&#128024;',
      '&#129423;', '&#129435;', '&#128045;', '&#128001;', '&#128000;', '&#128057;',
      '&#128048;', '&#128007;', '&#128063;', '&#129428;', '&#129415;', '&#128059;',
      '&#128040;', '&#128060;', '&#129432;', '&#129441;', '&#128062;', '&#129411;',
      '&#128020;', '&#128019;', '&#128035;', '&#128036;', '&#128037;', '&#128038;',
      '&#128039;', '&#128330;', '&#129413;', '&#129414;', '&#129442;', '&#129417;',
      '&#129434;', '&#129436;', '&#128056;', '&#128010;', '&#128034;', '&#129422;',
      '&#128013;', '&#128050;', '&#128009;', '&#129429;', '&#129430;', '&#128051;',
      '&#128011;', '&#128044;', '&#128031;', '&#128032;', '&#128033;', '&#129416;',
      '&#128025;', '&#128026;', '&#128012;', '&#129419;', '&#128027;', '&#128028;',
      '&#128029;', '&#128030;', '&#129431;', '&#128375;', '&#128376;', '&#129410;',
      '&#129439;', '&#129440;', '&#128144;', '&#127800;', '&#128174;', '&#127989;',
      '&#127801;', '&#129344;', '&#127802;', '&#127803;', '&#127804;', '&#127799;',
      '&#127793;', '&#127794;', '&#127795;', '&#127796;', '&#127797;', '&#127806;',
      '&#127807;', '&#127808;', '&#127809;', '&#127810;', '&#127811;', '&#127815;',
      '&#127816;', '&#127817;', '&#127818;', '&#127819;', '&#127820;', '&#127821;',
      '&#129389;', '&#127822;', '&#127823;', '&#127824;', '&#127825;', '&#127826;',
      '&#127827;', '&#129373;', '&#127813;', '&#129381;', '&#129361;', '&#127814;',
      '&#129364;', '&#129365;', '&#127805;', '&#127798;', '&#129362;', '&#129388;',
      '&#129382;', '&#127812;', '&#129372;', '&#127792;', '&#127838;', '&#129360;',
      '&#129366;', '&#129384;', '&#129391;', '&#129374;', '&#129472;', '&#127830;',
      '&#127831;', '&#129385;', '&#129363;', '&#127828;', '&#127839;', '&#127829;',
      '&#127789;', '&#129386;', '&#127790;', '&#127791;', '&#129369;', '&#129370;',
      '&#127859;', '&#129368;', '&#127858;', '&#129379;', '&#129367;', '&#127871;',
      '&#129474;', '&#129387;', '&#127857;', '&#127832;', '&#127833;', '&#127834;',
      '&#127835;', '&#127836;', '&#127837;', '&#127840;', '&#127842;', '&#127843;',
      '&#127844;', '&#127845;', '&#129390;', '&#127841;', '&#129375;', '&#129376;',
      '&#129377;', '&#129408;', '&#129438;', '&#129424;', '&#129425;', '&#127846;',
      '&#127847;', '&#127848;', '&#127849;', '&#127850;', '&#127874;', '&#127856;',
      '&#129473;', '&#129383;', '&#127851;', '&#127852;', '&#127853;', '&#127854;',
      '&#127855;', '&#127868;', '&#129371;', '&#9749;', '&#127861;', '&#127862;',
      '&#127870;', '&#127863;', '&#127864;', '&#127865;', '&#127866;', '&#127867;',
      '&#129346;', '&#129347;', '&#129380;', '&#129378;', '&#127869;', '&#127860;',
      '&#129348;', '&#128298;', '&#127994;', '&#127757;', '&#127758;', '&#127759;',
      '&#127760;', '&#128506;', '&#128510;', '&#129517;', '&#127956;', '&#9968;',
      '&#127755;', '&#128507;', '&#127957;', '&#127958;', '&#127964;', '&#127965;',
      '&#127966;', '&#127967;', '&#127963;', '&#127959;', '&#129521;', '&#127960;',
      '&#127962;', '&#127968;', '&#127969;', '&#127970;', '&#127971;', '&#127972;',
      '&#127973;', '&#127974;', '&#127976;', '&#127977;', '&#127978;', '&#127979;',
      '&#127980;', '&#127981;', '&#127983;', '&#127984;', '&#128146;', '&#128508;',
      '&#128509;', '&#9962;', '&#128332;', '&#128333;', '&#9961;', '&#128331;',
      '&#9970;', '&#9978;', '&#127745;', '&#127747;', '&#127961;', '&#127748;',
      '&#127749;', '&#127750;', '&#127751;', '&#127753;', '&#9832;', '&#127756;',
      '&#127904;', '&#127905;', '&#127906;', '&#128136;', '&#127914;', '&#128642;',
      '&#128643;', '&#128644;', '&#128645;', '&#128646;', '&#128647;', '&#128648;',
      '&#128649;', '&#128650;', '&#128669;', '&#128670;', '&#128651;', '&#128652;',
      '&#128653;', '&#128654;', '&#128656;', '&#128657;', '&#128658;', '&#128659;',
      '&#128660;', '&#128661;', '&#128662;', '&#128663;', '&#128664;', '&#128665;',
      '&#128666;', '&#128667;', '&#128668;', '&#127950;', '&#127949;', '&#128757;',
      '&#128690;', '&#128756;', '&#128761;', '&#128655;', '&#128739;', '&#128740;',
      '&#128738;', '&#9981;', '&#128680;', '&#128677;', '&#128678;', '&#128721;',
      '&#128679;', '&#9875;', '&#9973;', '&#128758;', '&#128676;', '&#128755;',
      '&#9972;', '&#128741;', '&#128674;', '&#9992;', '&#128745;', '&#128747;',
      '&#128748;', '&#128186;', '&#128641;', '&#128671;', '&#128672;', '&#128673;',
      '&#128752;', '&#128640;', '&#128760;', '&#128718;', '&#129523;', '&#8987;',
      '&#9203;', '&#8986;', '&#9200;', '&#9201;', '&#9202;', '&#128368;',
      '&#128347;', '&#128359;', '&#128336;', '&#128348;', '&#128337;', '&#128349;',
      '&#128338;', '&#128350;', '&#128339;', '&#128351;', '&#128340;', '&#128352;',
      '&#128341;', '&#128353;', '&#128342;', '&#128354;', '&#128343;', '&#128355;',
      '&#128344;', '&#128356;', '&#128345;', '&#128357;', '&#128346;', '&#128358;',
      '&#127761;', '&#127762;', '&#127763;', '&#127764;', '&#127765;', '&#127766;',
      '&#127767;', '&#127768;', '&#127769;', '&#127770;', '&#127771;', '&#127772;',
      '&#127777;', '&#9728;', '&#127773;', '&#127774;', '&#11088;', '&#127775;',
      '&#127776;', '&#9729;', '&#9925;', '&#9928;', '&#127780;', '&#127781;',
      '&#127782;', '&#127783;', '&#127784;', '&#127785;', '&#127786;', '&#127787;',
      '&#127788;', '&#127744;', '&#127752;', '&#127746;', '&#9730;', '&#9748;',
      '&#9969;', '&#9889;', '&#10052;', '&#9731;', '&#9924;', '&#9732;',
      '&#128293;', '&#128167;', '&#127754;', '&#127875;', '&#127876;', '&#127878;',
      '&#127879;', '&#129512;', '&#10024;', '&#127880;', '&#127881;', '&#127882;',
      '&#127883;', '&#127885;', '&#127886;', '&#127887;', '&#127888;', '&#127889;',
      '&#129511;', '&#127872;', '&#127873;', '&#127895;', '&#127903;', '&#127915;',
      '&#127894;', '&#127942;', '&#127941;', '&#129351;', '&#129352;', '&#129353;',
      '&#9917;', '&#9918;', '&#129358;', '&#127936;', '&#127952;', '&#127944;',
      '&#127945;', '&#127934;', '&#129359;', '&#127923;', '&#127951;', '&#127953;',
      '&#127954;', '&#129357;', '&#127955;', '&#127992;', '&#129354;', '&#129355;',
      '&#129349;', '&#9971;', '&#9976;', '&#127907;', '&#127933;', '&#127935;',
      '&#128759;', '&#129356;', '&#127919;', '&#127921;', '&#128302;', '&#129535;',
      '&#127918;', '&#128377;', '&#127920;', '&#127922;', '&#129513;', '&#129528;',
      '&#9824;', '&#9829;', '&#9830;', '&#9827;', '&#9823;', '&#127183;',
      '&#126980;', '&#127924;', '&#127917;', '&#128444;', '&#127912;', '&#129525;',
      '&#129526;', '&#128083;', '&#128374;', '&#129405;', '&#129404;', '&#128084;',
      '&#128085;', '&#128086;', '&#129507;', '&#129508;', '&#129509;', '&#129510;',
      '&#128087;', '&#128088;', '&#128089;', '&#128090;', '&#128091;', '&#128092;',
      '&#128093;', '&#128717;', '&#127890;', '&#128094;', '&#128095;', '&#129406;',
      '&#129407;', '&#128096;', '&#128097;', '&#128098;', '&#128081;', '&#128082;',
      '&#127913;', '&#127891;', '&#129506;', '&#9937;', '&#128255;', '&#128132;',
      '&#128141;', '&#128142;', '&#128263;', '&#128264;', '&#128265;', '&#128266;',
      '&#128226;', '&#128227;', '&#128239;', '&#128276;', '&#128277;', '&#127932;',
      '&#127925;', '&#127926;', '&#127897;', '&#127898;', '&#127899;', '&#127908;',
      '&#127911;', '&#128251;', '&#127927;', '&#127928;', '&#127929;', '&#127930;',
      '&#127931;', '&#129345;', '&#128241;', '&#128242;', '&#9742;', '&#128222;',
      '&#128223;', '&#128224;', '&#128267;', '&#128268;', '&#128187;', '&#128421;',
      '&#128424;', '&#9000;', '&#128433;', '&#128434;', '&#128189;', '&#128190;',
      '&#128191;', '&#128192;', '&#129518;', '&#127909;', '&#127902;', '&#128253;',
      '&#127916;', '&#128250;', '&#128247;', '&#128248;', '&#128249;', '&#128252;',
      '&#128269;', '&#128270;', '&#128367;', '&#128161;', '&#128294;', '&#127982;',
      '&#128212;', '&#128213;', '&#128214;', '&#128215;', '&#128216;', '&#128217;',
      '&#128218;', '&#128211;', '&#128210;', '&#128195;', '&#128220;', '&#128196;',
      '&#128240;', '&#128478;', '&#128209;', '&#128278;', '&#127991;', '&#128176;',
      '&#128180;', '&#128181;', '&#128182;', '&#128183;', '&#128184;', '&#128179;',
      '&#129534;', '&#128185;', '&#128177;', '&#128178;', '&#9993;', '&#128231;',
      '&#128232;', '&#128233;', '&#128228;', '&#128229;', '&#128230;', '&#128235;',
      '&#128234;', '&#128236;', '&#128237;', '&#128238;', '&#128499;', '&#9999;',
      '&#10002;', '&#128395;', '&#128394;', '&#128396;', '&#128397;', '&#128221;',
      '&#128188;', '&#128193;', '&#128194;', '&#128450;', '&#128197;', '&#128198;',
      '&#128466;', '&#128467;', '&#128199;', '&#128200;', '&#128201;', '&#128202;',
      '&#128203;', '&#128204;', '&#128205;', '&#128206;', '&#128391;', '&#128207;',
      '&#128208;', '&#9986;', '&#128451;', '&#128452;', '&#128465;', '&#128274;',
      '&#128275;', '&#128271;', '&#128272;', '&#128273;', '&#128477;', '&#128296;',
      '&#9935;', '&#9874;', '&#128736;', '&#128481;', '&#9876;', '&#128299;',
      '&#127993;', '&#128295;', '&#128297;', '&#9881;', '&#128476;', '&#9878;',
      '&#128279;', '&#9939;', '&#129520;', '&#129522;', '&#129514;', '&#129515;',
      '&#129516;', '&#128300;', '&#128301;', '&#128225;', '&#128137;', '&#128138;',
      '&#128682;', '&#128719;', '&#128715;', '&#128701;', '&#128703;', '&#128705;',
      '&#129524;', '&#129527;', '&#129529;', '&#129530;', '&#129531;', '&#129532;',
      '&#129533;', '&#129519;', '&#128722;', '&#128684;', '&#128511;', '&#127975;',
      '&#128686;', '&#128688;', '&#9855;', '&#128697;', '&#128698;', '&#128699;',
      '&#128700;', '&#128702;', '&#128706;', '&#128707;', '&#128708;', '&#128709;',
      '&#9888;', '&#128696;', '&#9940;', '&#128683;', '&#128691;', '&#128685;',
      '&#128687;', '&#128689;', '&#128695;', '&#128245;', '&#128286;', '&#9762;',
      '&#9763;', '&#11014;', '&#8599;', '&#10145;', '&#8600;', '&#11015;',
      '&#8601;', '&#11013;', '&#8598;', '&#8597;', '&#8596;', '&#8617;',
      '&#8618;', '&#10548;', '&#10549;', '&#128259;', '&#128260;', '&#128281;',
      '&#128282;', '&#128283;', '&#128284;', '&#128285;', '&#128720;', '&#9883;',
      '&#128329;', '&#10017;', '&#9784;', '&#9775;', '&#10013;', '&#9766;',
      '&#9770;', '&#9774;', '&#128334;', '&#128303;', '&#9800;', '&#9801;',
      '&#9802;', '&#9803;', '&#9804;', '&#9805;', '&#9806;', '&#9807;',
      '&#9808;', '&#9809;', '&#9810;', '&#9811;', '&#9934;', '&#128256;',
      '&#128257;', '&#128258;', '&#9654;', '&#9193;', '&#9197;', '&#9199;',
      '&#9664;', '&#9194;', '&#9198;', '&#128316;', '&#9195;', '&#128317;',
      '&#9196;', '&#9208;', '&#9209;', '&#9210;', '&#9167;', '&#127910;',
      '&#128261;', '&#128262;', '&#128246;', '&#128243;', '&#128244;', '&#9792;',
      '&#9794;', '&#9877;', '&#9854;', '&#9851;', '&#9884;', '&#128305;',
      '&#128219;', '&#128304;', '&#11093;', '&#9989;', '&#9745;', '&#10004;',
      '&#10006;', '&#10060;', '&#10062;', '&#10133;', '&#10134;', '&#10135;',
      '&#10160;', '&#10175;', '&#12349;', '&#10035;', '&#10036;', '&#10055;',
      '&#8252;', '&#8265;', '&#10067;', '&#10068;', '&#10069;', '&#10071;',
      '&#12336;', '&#169;', '&#174;', '&#8482;', '&#35;&#65039;&#8419;', '&#42;&#65039;&#8419;',
      '&#48;&#65039;&#8419;', '&#49;&#65039;&#8419;', '&#50;&#65039;&#8419;', '&#51;&#65039;&#8419;', '&#52;&#65039;&#8419;', '&#53;&#65039;&#8419;',
      '&#54;&#65039;&#8419;', '&#55;&#65039;&#8419;', '&#56;&#65039;&#8419;', '&#57;&#65039;&#8419;', '&#128287;', '&#128288;',
      '&#128289;', '&#128290;', '&#128291;', '&#128292;', '&#127344;', '&#127374;',
      '&#127345;', '&#127377;', '&#127378;', '&#127379;', '&#8505;', '&#127380;',
      '&#9410;', '&#127381;', '&#127382;', '&#127358;', '&#127383;', '&#127359;',
      '&#127384;', '&#127385;', '&#127386;', '&#127489;', '&#127490;', '&#127543;',
      '&#127542;', '&#127535;', '&#127568;', '&#127545;', '&#127514;', '&#127538;',
      '&#127569;', '&#127544;', '&#127540;', '&#127539;', '&#12951;', '&#12953;',
      '&#127546;', '&#127541;', '&#128308;', '&#128309;', '&#9898;', '&#9899;',
      '&#11036;', '&#11035;', '&#9724;', '&#9723;', '&#9725;', '&#9726;',
      '&#9643;', '&#9642;', '&#128310;', '&#128311;', '&#128312;', '&#128313;',
      '&#128314;', '&#128315;', '&#128160;', '&#128280;', '&#128306;', '&#128307;',
      '&#127937;', '&#128681;', '&#127884;', '&#127988;', '&#127987;', '&#127987;&#65039;&#8205;&#127752;',
      '&#127988;&#8205;&#9760;&#65039;', '&#127462;&#127464;', '&#127462;&#127465;', '&#127462;&#127466;', '&#127462;&#127467;', '&#127462;&#127468;',
      '&#127462;&#127470;', '&#127462;&#127473;', '&#127462;&#127474;', '&#127462;&#127476;', '&#127462;&#127478;', '&#127462;&#127479;',
      '&#127462;&#127480;', '&#127462;&#127481;', '&#127462;&#127482;', '&#127462;&#127484;', '&#127462;&#127485;', '&#127462;&#127487;',
      '&#127463;&#127462;', '&#127463;&#127463;', '&#127463;&#127465;', '&#127463;&#127466;', '&#127463;&#127467;', '&#127463;&#127468;',
      '&#127463;&#127469;', '&#127463;&#127470;', '&#127463;&#127471;', '&#127463;&#127473;', '&#127463;&#127474;', '&#127463;&#127475;',
      '&#127463;&#127476;', '&#127463;&#127478;', '&#127463;&#127479;', '&#127463;&#127480;', '&#127463;&#127481;', '&#127463;&#127483;',
      '&#127463;&#127484;', '&#127463;&#127486;', '&#127463;&#127487;', '&#127464;&#127462;', '&#127464;&#127464;', '&#127464;&#127465;',
      '&#127464;&#127467;', '&#127464;&#127468;', '&#127464;&#127469;', '&#127464;&#127470;', '&#127464;&#127472;', '&#127464;&#127473;',
      '&#127464;&#127474;', '&#127464;&#127475;', '&#127464;&#127476;', '&#127464;&#127477;', '&#127464;&#127479;', '&#127464;&#127482;',
      '&#127464;&#127483;', '&#127464;&#127484;', '&#127464;&#127485;', '&#127464;&#127486;', '&#127464;&#127487;', '&#127465;&#127466;',
      '&#127465;&#127468;', '&#127465;&#127471;', '&#127465;&#127472;', '&#127465;&#127474;', '&#127465;&#127476;', '&#127465;&#127487;',
      '&#127466;&#127462;', '&#127466;&#127464;', '&#127466;&#127466;', '&#127466;&#127468;', '&#127466;&#127469;', '&#127466;&#127479;',
      '&#127466;&#127480;', '&#127466;&#127481;', '&#127466;&#127482;', '&#127467;&#127470;', '&#127467;&#127471;', '&#127467;&#127472;',
      '&#127467;&#127474;', '&#127467;&#127476;', '&#127467;&#127479;', '&#127468;&#127462;', '&#127468;&#127463;', '&#127468;&#127465;',
      '&#127468;&#127466;', '&#127468;&#127467;', '&#127468;&#127468;', '&#127468;&#127469;', '&#127468;&#127470;', '&#127468;&#127473;',
      '&#127468;&#127474;', '&#127468;&#127475;', '&#127468;&#127477;', '&#127468;&#127478;', '&#127468;&#127479;', '&#127468;&#127480;',
      '&#127468;&#127481;', '&#127468;&#127482;', '&#127468;&#127484;', '&#127468;&#127486;', '&#127469;&#127472;', '&#127469;&#127474;',
      '&#127469;&#127475;', '&#127469;&#127479;', '&#127469;&#127481;', '&#127469;&#127482;', '&#127470;&#127464;', '&#127470;&#127465;',
      '&#127470;&#127466;', '&#127470;&#127473;', '&#127470;&#127474;', '&#127470;&#127475;', '&#127470;&#127476;', '&#127470;&#127478;',
      '&#127470;&#127479;', '&#127470;&#127480;', '&#127470;&#127481;', '&#127471;&#127466;', '&#127471;&#127474;', '&#127471;&#127476;',
      '&#127471;&#127477;', '&#127472;&#127466;', '&#127472;&#127468;', '&#127472;&#127469;', '&#127472;&#127470;', '&#127472;&#127474;',
      '&#127472;&#127475;', '&#127472;&#127477;', '&#127472;&#127479;', '&#127472;&#127484;', '&#127472;&#127486;', '&#127472;&#127487;',
      '&#127473;&#127462;', '&#127473;&#127463;', '&#127473;&#127464;', '&#127473;&#127470;', '&#127473;&#127472;', '&#127473;&#127479;',
      '&#127473;&#127480;', '&#127473;&#127481;', '&#127473;&#127482;', '&#127473;&#127483;', '&#127473;&#127486;', '&#127474;&#127462;',
      '&#127474;&#127464;', '&#127474;&#127465;', '&#127474;&#127466;', '&#127474;&#127467;', '&#127474;&#127468;', '&#127474;&#127469;',
      '&#127474;&#127472;', '&#127474;&#127473;', '&#127474;&#127474;', '&#127474;&#127475;', '&#127474;&#127476;', '&#127474;&#127477;',
      '&#127474;&#127478;', '&#127474;&#127479;', '&#127474;&#127480;', '&#127474;&#127481;', '&#127474;&#127482;', '&#127474;&#127483;',
      '&#127474;&#127484;', '&#127474;&#127485;', '&#127474;&#127486;', '&#127474;&#127487;', '&#127475;&#127462;', '&#127475;&#127464;',
      '&#127475;&#127466;', '&#127475;&#127467;', '&#127475;&#127468;', '&#127475;&#127470;', '&#127475;&#127473;', '&#127475;&#127476;',
      '&#127475;&#127477;', '&#127475;&#127479;', '&#127475;&#127482;', '&#127475;&#127487;', '&#127476;&#127474;', '&#127477;&#127462;',
      '&#127477;&#127466;', '&#127477;&#127467;', '&#127477;&#127468;', '&#127477;&#127469;', '&#127477;&#127472;', '&#127477;&#127473;',
      '&#127477;&#127474;', '&#127477;&#127475;', '&#127477;&#127479;', '&#127477;&#127480;', '&#127477;&#127481;', '&#127477;&#127484;',
      '&#127477;&#127486;', '&#127478;&#127462;', '&#127479;&#127466;', '&#127479;&#127476;', '&#127479;&#127480;', '&#127479;&#127482;',
      '&#127479;&#127484;', '&#127480;&#127462;', '&#127480;&#127463;', '&#127480;&#127464;', '&#127480;&#127465;', '&#127480;&#127466;',
      '&#127480;&#127468;', '&#127480;&#127469;', '&#127480;&#127470;', '&#127480;&#127471;', '&#127480;&#127472;', '&#127480;&#127473;',
      '&#127480;&#127474;', '&#127480;&#127475;', '&#127480;&#127476;', '&#127480;&#127479;', '&#127480;&#127480;', '&#127480;&#127481;',
      '&#127480;&#127483;', '&#127480;&#127485;', '&#127480;&#127486;', '&#127480;&#127487;', '&#127481;&#127462;', '&#127481;&#127464;',
      '&#127481;&#127465;', '&#127481;&#127467;', '&#127481;&#127468;', '&#127481;&#127469;', '&#127481;&#127471;', '&#127481;&#127472;',
      '&#127481;&#127473;', '&#127481;&#127474;', '&#127481;&#127475;', '&#127481;&#127476;', '&#127481;&#127479;', '&#127481;&#127481;',
      '&#127481;&#127483;', '&#127481;&#127484;', '&#127481;&#127487;', '&#127482;&#127462;', '&#127482;&#127468;', '&#127482;&#127474;',
      '&#127482;&#127475;', '&#127482;&#127480;', '&#127482;&#127486;', '&#127482;&#127487;', '&#127483;&#127462;', '&#127483;&#127464;',
      '&#127483;&#127466;', '&#127483;&#127468;', '&#127483;&#127470;', '&#127483;&#127475;', '&#127483;&#127482;', '&#127484;&#127467;',
      '&#127484;&#127480;', '&#127485;&#127472;', '&#127486;&#127466;', '&#127486;&#127481;', '&#127487;&#127462;', '&#127487;&#127474;',
      '&#127487;&#127484;', '&#127988;&#917607;&#917602;&#917605;&#917614;&#917607;&#917631;'];
      return <div className={`field-wrap-smile ${this.props.status? 'field-wrap-smile__error' : ''}`} ref={this.setWrapperRef}>
              <div className="tooltip-info">
                <span className="tooltip-info__icon">?</span>
                  <div className="tooltip-info__content">
                    <div className="arrow"></div>
                      <p>{this.props.tooltipValue}</p>
                    </div>
                  </div>
                                <div className="field-wrap-smile__inner"  onClick={this.handlerGetEmojies.bind(this)}>
                                  {this.state.smileValue ? <div dangerouslySetInnerHTML={{__html: this.state.smileValue}}></div> : <img src={smile} alt="" />}
                                </div>
                                  {this.state.getSmileModal ? <div className="emoji-content"><Scrollbars
                                        renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                                        renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                                        renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                                          autoHeight
                                          autoHeightMin={0}
                                          autoHeightMax={225}
                                          renderView={props => <div {...props} className="emoji-content__inner"/>}>
                                  {arr.map((item, index) => {
                                    return <div key={index} className="emoji-content__item" onClick={this.handlerGetEmoji.bind(this, item)} dangerouslySetInnerHTML={{__html: item}}></div>
                                  })}</Scrollbars></div> : ''}
                                </div>;
  }
}
export default GetEmoji;
