import React from "react";
import Loading from './Loading';
import sprite from '../media/icons.svg';
class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalStatus: false,
      modalDisabled: false,
      submitStatus: false
    }
  }
  componentDidMount() {
    document.querySelector('body').style.overflow = "hidden";
    this.setState(prevState => ({
      modalStatus: true
    }));
  }
  handlerModalDisabled(event) {
    document.querySelector('body').style.overflow = "";
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalStatus: false
      }));
      this.props.handlerClose();
    }, 500);
  }
  render() {
    if(this.props.submitStatus){
      document.querySelector('body').style.overflow = "";
      setTimeout(() => {
        this.setState(prevState => ({
          modalDisabled: true
        }));
      }, 10);
      setTimeout(() => {
        this.setState(prevState => ({
          modalStatus: false,
          submitStatus: false
        }));
      }, 500);
    }
    if(this.state.modalStatus){
      let nav = '';
      if(this.props.type === 'delete') {
        nav = <div className="field-wrap__submit">
          <button className="btn btn_size_lg btn_theme_remove btn_width_100 user-info__nav-btn" type="button" disabled={this.props.submitLoading ? true : false} onClick={this.props.submitHandler.bind(this)}>{this.props.buttonSubmitText}{this.props.submitLoading && <Loading />}</button>
        </div>;
      } else if(this.props.type === 'formSubmit'){
        nav = <div className="field-wrap__submit">
          <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="button" disabled={this.props.submitLoading ? true : false} onClick={this.props.submitHandler.bind(this)}>{this.props.buttonSubmitText}{this.props.submitLoading && <Loading />}</button>
        </div>;
      }
      return <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
        <div className="modal-background" onClick={this.handlerModalDisabled.bind(this)}></div>
          <div className="modal">
            <div className="modal-header">
              <h2 className="modal-headline">{this.props.title ? this.props.title : ''}</h2>
              <button className="close-modal" type="button" onClick={this.handlerModalDisabled.bind(this)}>
                <svg className="close-modal__icon">
                  <use href={`${sprite}#close-icon`}></use>
                </svg>
              </button>
            </div>
            {this.props.content}
            {nav}
        </div>
      </div>;
    } else {
      return '';
    }

  }
}
export default Modal;
