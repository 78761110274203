import React from "react";
import DateFormating from './components/DateFormating';
import { Link, Navigate } from "react-router-dom";
import Loading from './components/Loading';
import Pagination from './components/Pagination';
import ResultErrors from './components/ResultErrors';
import AccountHeader from './components/AccountHeader';
import AccountContentHeader from './components/AccountContentHeader';
import AccountNav from './components/AccountNav';
import ratingStar from './media/star-rating.svg';
import star from './media/star.svg';
import sprite from './media/icons.svg';
import { getAuth } from "firebase/auth";
let qw;
if(typeof window !== "undefined" && typeof document !== 'undefined'){
  qw = window.location.protocol;
}
class AccountLibraryReviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      reviews: [],
      totalPages: null,
      currentPage: 0,
      isLoaded: false,
      totalItemsLibrary: null,
      totalItemsReviews: null,
      totalItemsRequests: null,
      reviewOpen: [],
      errors: null,
      error: false
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handlerPaginationItems = (bonusesItems, current) => {
    this.setState({
      reviews: bonusesItems,
      currentPage: current
    });
  }
  handlerReviewDropDown(id, event){
    let arr = this.state.reviewOpen;
    let indexArr = arr.findIndex(x => x === id);
    if(indexArr !== -1){
      arr.splice(indexArr, 1);
    } else {
      arr = [...arr, id];
    }
    this.setState(prevState => ({
      reviewOpen: arr
    }));
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    that.props.handlerUpdateReviews();
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/library/books/reviews/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "itemsPerPage": 20
      })
    }).then(res => {
      if(res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            reviews: data.data,
            totalPages: data.totalPages,
            totalItemsReviews: data.totalItems,
            isLoaded: true
          });
        })
        fetch(`${that.props.queryLink}/api/v1/library/books/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "itemsPerPage": 20
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              });
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                totalItemsLibrary: data.totalItems
              });
            })
            fetch(`${that.props.queryLink}/api/v1/library/books/requests/search`, {
              method: 'POST',
              headers: myHeaders,
              body: JSON.stringify({
                "itemsPerPage": 20
              })
            }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors
                  });
                })
              } else {
                res.json().then(function(data) {
                  that.setState({
                    isLoadedReviews: true,
                    totalItemsRequests: data.totalItems
                  });
                })
              }
            })
          }
        })
      } else if(res.status === 401) {
        res.json().then(function(data) {
          that.setState({
            errors: [data.message],
            error: true,
            isLoaded: true
          })
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            error: true,
            isLoaded: true
          });
        })
      }
    })
    })
  }
  handlerReviewStatus(reviewID, bookID, status, event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/library/books/${bookID}/reviews/${reviewID}/status`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        "reviewStatus": status
      })
    }).then(res => {
      if (res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        })
      } else {
        that.setState(prevState => ({reviews: prevState.reviews.map(x => x.reviewID === reviewID ? {...x, status: status} : x)}));
      }
    })
  })
  }
  handlerReviewRemove(reviewID, bookID, event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/library/books/${bookID}/reviews/${reviewID}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if (res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        })
      } else {
        fetch(`${that.props.queryLink}/api/v1/library/books/reviews/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "itemsPerPage": 20
          })
        }).then(res => {
          if (res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                error: true
              });
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                isLoadedReviews: true,
                reviews: data.data,
                totalPages: data.totalPages,
                totalItemsReviews: data.totalItems
              });
            })
          }
        })
      }
    })
  })
  }
  closeElements(event){
    if(this.state.reviewOpen.length > 0 || (event.target.closest('.dropdown__review-book') && this.state.reviewOpen.length > 0)){
      this.setState(prevState => ({
        reviewOpen: []
      }));
    }
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('library-Title') + ' | ' + this.props.companyName;
    }
    let resultContent = '';
    if(this.state.error) {
      resultContent = <div>Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultContent = <Loading />;
    } else {
      resultContent = <>
      <div className="account-wgt account-wgt__library"><div className="library-reviews-list">
                {this.state.reviews.map((item, index) => {
                  let ratingReviews = '';
                  for (var i = 0; i < 5; i++) {
                    let rating = false;
                    if(i < item.rating){
                      rating = true;
                    }
                    ratingReviews += `<li><img src=${rating ? ratingStar : star} alt="" /></li>`;
                  }
                  return           <div className={`library-review-wgt ${item.status === 'NEW' ? 'library-review-wgt_new' : ''}`} key={index}>
                                    <div className="library-review-wgt__header">
                                      <div className="library-review-wgt__header-info">
                                        {item.bookPhoto ? <img className="library-review-wgt__thumbnail" src={qw === 'http:' ? item.bookPhoto.replace( /https:/g, "http:" ) : item.bookPhoto} alt="" /> : ''}
                                        <div className="library-review-wgt__header-info-inner">
                                          <div className="library-review-wgt__user-name">{item.userName}</div>
                                          <div className="library-review-book-name">{item.bookName}</div>
                                        </div>
                                      </div>
                                      <div className="library-review-wgt__header-right">
                                        <div className="library-review-wgt__header-date"><DateFormating start={item.creationDate} /></div>
                                        <div className="library-reviw-wgt__header-rating">
                                          <ul dangerouslySetInnerHTML={{__html: ratingReviews}}></ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="library-review-wgt__content">{item.review}</div>
                                    <div className="library-review-wgt__footer">
                                      {item.status === 'NEW' ? <div className="library-review-wgt__nav">
                                        <div className="library-review-wgt__nav-col">
                                          <button className="btn btn_theme_green btn_size_lg btn_width_100" type="button" onClick={this.handlerReviewStatus.bind(this, item.reviewID, item.bookID, 'ACTIVE')}>{this.props.getTranslate('reviewLibrary-btnConfirm')}</button>
                                        </div>
                                        <div className="library-review-wgt__nav-col">
                                          <button className="btn btn_theme_red btn_size_lg btn_width_100" type="button" onClick={this.handlerReviewStatus.bind(this, item.reviewID, item.bookID, 'HIDDEN')}>{this.props.getTranslate('reviewLibrary-btnReject')}</button>
                                        </div>
                                      </div> :                     <div className="dropdown dropdown__review-book">
                                                            <button className="dropdown__btn dropdown__btn-book-review" type="button" onClick={this.handlerReviewDropDown.bind(this, item.reviewID)}>
                                                            <div className="dropdown__btn-name">{item.status === 'ACTIVE' ? this.props.getTranslate('reviewLibrary-btnDropDownPublished') : this.props.getTranslate('reviewLibrary-btnDropDownRejected')}</div>
                                                              <svg className="dropdown__icon">
                                                                <use href={`${sprite}#arrow-down`}></use>
                                                              </svg>
                                                            </button>
                                                            <div className={`dropdown-content ${this.state.reviewOpen.indexOf(item.reviewID) !== -1 ? 'active' : ''}`}>
                                                              <ul className="dropdown-nav">
                                                                <li className="dropdown-nav__item">
                                                                  <button className="dropdown-nav__link" type="button" onClick={this.handlerReviewStatus.bind(this, item.reviewID, item.bookID, 'ACTIVE')}>{this.props.getTranslate('reviewLibrary-btnDropDownPublished')}</button>
                                                                </li>
                                                                <li className="dropdown-nav__item">
                                                                  <button className="dropdown-nav__link" type="button" onClick={this.handlerReviewStatus.bind(this, item.reviewID, item.bookID, 'HIDDEN')}>{this.props.getTranslate('reviewLibrary-btnDropDownRejected')}</button>
                                                                </li>
                                                              </ul>
                                                            </div>
                                                          </div>}
                                    </div>
                                  </div>
                })}
              </div></div></>
    }
    return <>
    {this.state.reviews.length < 1 && this.state.isLoaded ? <Navigate to="/account/library" replace={true} /> : ''}
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap" onClick={this.closeElements.bind(this)}>
      <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
      <div className="account-content">
        <div className="account-content__inner">

          {/* Account header component */}
          <AccountContentHeader
            title={this.props.getTranslate('library-Title')}
            handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
            languageActive={this.props.chooseLanguage}
          />

          <div className="account-content__content">
          {this.state.isLoaded ? <div className="users-tab">
              <div className="users-tab__inner">
                {this.state.totalItemsLibrary === 0 ? <span className="users-tab__link disabled">{this.props.getTranslate('library-ReferencesTab')}<span className="users-tab__link-amount">0</span></span> : <Link className="users-tab__link" to={this.props.getPermalink('/account/library')}>{this.props.getTranslate('library-ReferencesTab')}<span className="users-tab__link-amount">{this.state.totalItemsLibrary}</span></Link>}
                <Link className={`${this.state.totalItemsReviews === 0 ? 'users-tab__link disabled' : 'users-tab__link active' } ${this.props.newReview && this.props.newReview > 0 ? 'tab_theme_green' : ''}`} to={this.props.getPermalink('/account/library/reviews')}>{this.props.getTranslate('library-ReviewTab')}<span className="users-tab__link-amount">{this.state.totalItemsReviews}</span></Link>
                {this.state.totalItemsRequests === 0 ? <span className="users-tab__link disabled">{this.props.getTranslate('library-RequestTab')}<span className="users-tab__link-amount">0</span></span> : <Link className="users-tab__link" to={this.props.getPermalink('/account/library/request')}>{this.props.getTranslate('library-RequestTab')}<span className="users-tab__link-amount">{this.state.totalItemsRequests}</span></Link>}
              </div>
            </div> : ''}
              {resultContent}
          </div>
          <div className="account-content__footer">
            <Pagination total={this.state.totalPages} perPage="20" path={`${this.props.queryLink}/api/v1/library/books/reviews/search`} currentPage={this.state.currentPage} items={this.handlerPaginationItems} />
          </div>
        </div>
      </div>
    </div>
    </>;
  }
}
export default AccountLibraryReviews;
