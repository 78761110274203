import React from "react";
class BlogHeader extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <section className="blog-page-header">
      <div className="wrapper">
        <div className="blog-page-header__inner">
          <h1 className="blog-page-header__headline">{this.props.type === 'blog' ? 'Блог' : 'Глосарій'}</h1>
          {this.props.categories ? <ul className="blog-page-header__list">
            {this.props.categories.map((item, index) => {
              return <li key={index}>
                <button type="button" onClick={this.props.handlerSortCategories.bind(this, item)}>{item}</button>
              </li>
            })}
          </ul> : ''}
        </div>
      </div>
    </section>;
  }
}
export default BlogHeader;
