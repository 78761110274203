import React from "react";
import { Link } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import sprite from '../media/icons.svg';
import logo from '../mate_logo.svg';
class AccountHeader extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <>
      <header className="header header__account">
        <div className="wrapper">
          <div className="header__inner">
            <div className="header__collapse">
              <button className="header__collapse-btn" type="button" onClick={this.props.handler.bind(this, !this.props.status)}>
                <svg>
                  <use href={`${sprite}#menu-icon`}></use>
                </svg>
              </button>
            </div>
            <div className="header__logo">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className="header__nav">
            {(typeof window !== "undefined" && typeof document !== 'undefined') ?
              <Link className="btn login-btn" to="/account">
                {localStorage.getItem("navPhoto") ? <img src={localStorage.getItem("navPhoto")} alt="" /> : <svg><use href={`${sprite}#login`}></use></svg>}
              </Link> : ''}
            </div>
          </div>
        </div>
      </header>
    </>;
  }
}
export default AccountHeader;
