import React from "react";
import DemoModal from '../../components/DemoModal';
import { Link } from "react-router-dom";
import image_banner from '../../media/main-banner.png';
import pizza_icon from '../../media/bitmap-pizza.png';
import tenis_icon from '../../media/bitmap-tenis.png';
import book_icon from '../../media/bitmap-book.png';
import survey_icon from '../../media/bitmap-survey.png';
import photo_desctop_icon from '../../media/bitmap_desctop.png';
import t_shirt from '../../media/t-shirt.png';
import icon_umbrella from '../../media/icon_umbrella.png';
import avatar_13 from '../../media/avatar_13.png';
import avatar_3 from '../../media/avatar_3.png';
import avatar_5 from '../../media/avatar_5.png';
import bitmap_t_shirt from '../../media/bitmap-t-shirt.png';
import bitmap_happy from '../../media/bitmap-happy.png';
import item_16 from '../../media/item-16.png';

class HomeBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      outAnimation: false,
      animationElems: [{
        first: [
          <div className="home-banner-wgt home-banner-wgt_theme_first">
                <div className="home-banner-wgt__inner">
                  <div>
                    <div className="home-banner-wgt__headline">Pizza Day</div>
                    <div className="home-banner-wgt__description">22.10, 12:00 – 5 учасників</div>
                  </div>
                  <img className="home-banner-wgt__icon" src={pizza_icon} alt="" />
                </div>
              </div>,
              <div className="home-banner-wgt home-banner-wgt_theme_first">
                    <div className="home-banner-wgt__inner">
                      <img className="home-banner-wgt__icon home-banner-wgt__icon_reverse" src={book_icon} alt="" />
                      <div>
                        <div className="home-banner-wgt__headline">Corporate culture in our company</div>
                      </div>
                    </div>
                  </div>,
                  <div className="home-banner-wgt home-banner-wgt_v3 home-banner-wgt_theme_first">
                    <div className="home-banner-wgt__headline">+125</div>
                    <div className="home-banner-wgt__description">Bonuses have been accrued</div>
                  </div>
        ]
      },
      {
        second: [
          <div className="home-banner-wgt home-banner-wgt_theme_second">
                <div className="home-banner-wgt__inner">
                  <div>
                    <div className="home-banner-wgt__headline">Pin-Pong Event</div>
                    <div className="home-banner-wgt__description">17.10, 18:00 – 8 учасників</div>
                  </div>
                  <img className="home-banner-wgt__icon" src={tenis_icon} alt="" />
                </div>
              </div>,
              <div className="home-banner-wgt home-banner-wgt_theme_second">
                    <div className="home-banner-wgt__inner">
                      <div>
                        <div className="home-banner-wgt__headline">Нове опитування</div>
                        <div className="home-banner-wgt__description">5 учасників</div>
                      </div>
                      <img className="home-banner-wgt__icon" src={survey_icon} alt="" />
                    </div>
                  </div>,
                  <div className="home-banner-wgt home-banner-wgt_theme_second">
                        <div className="home-banner-wgt__inner">
                          <div>
                            <div className="home-banner-wgt__headline">Happy Birthday !!!</div>
                            <div className="home-banner-wgt__description">Anna B.</div>
                          </div>
                          <img className="home-banner-wgt__icon" src={bitmap_happy} alt="" />
                        </div>
                      </div>
        ]
      },
      {
        third: [
          <div className="home-banner-wgt home-banner-wgt_v2 home-banner-wgt_theme_third">
            <img className="home-banner-wgt__img" src={photo_desctop_icon} alt="" />
            <div className="home-banner-wgt__headline">Laptop</div>
            <div className="home-banner-wgt__description">$ 900</div>
          </div>,
          <div className="home-banner-wgt home-banner-wgt_theme_third">
                <div className="home-banner-wgt__inner">
                  <img className="home-banner-wgt__icon home-banner-wgt__icon_first" src={t_shirt} alt="" />
                  <div>
                    <div className="home-banner-wgt__headline">New Order</div>
                    <div className="home-banner-wgt__description">New Order</div>
                  </div>
                  <img className="home-banner-wgt__icon" src={avatar_3} alt="" />
                </div>
              </div>,
              <div className="home-banner-wgt home-banner-wgt_theme_third">
                    <div className="home-banner-wgt__inner">
                      <div>
                        <div className="home-banner-wgt__headline">+150 Bonuses</div>
                        <div className="home-banner-wgt__description">Received from Olena K.</div>
                      </div>
                      <img className="home-banner-wgt__icon" src={avatar_5} alt="" />
                    </div>
                  </div>
        ]
      },
      {
        four: [
          <div className="home-banner-wgt home-banner-wgt_v3 home-banner-wgt_theme_four">
            <div className="home-banner-wgt__headline">+25</div>
            <div className="home-banner-wgt__description">Bonuses have been accrued</div>
          </div>,
          <div className="home-banner-wgt home-banner-wgt_theme_four">
                <div className="home-banner-wgt__inner">
                  <div>
                    <div className="home-banner-wgt__headline">Health Insurance</div>
                    <div className="home-banner-wgt__description">01.01.2023 – 31.12.2024</div>
                  </div>
                  <img className="home-banner-wgt__icon" src={icon_umbrella} alt="" />
                </div>
              </div>,
              <div className="home-banner-wgt home-banner-wgt_theme_four">
                    <div className="home-banner-wgt__inner">
                      <div>
                        <div className="home-banner-wgt__headline">Charity event</div>
                        <div className="home-banner-wgt__description">8 учасників</div>
                      </div>
                      <img className="home-banner-wgt__icon" src={item_16} alt="" />
                    </div>
                  </div>
        ]
      },
      {
        five: [
          <div className="home-banner-wgt home-banner-wgt_theme_five">
                <div className="home-banner-wgt__inner">
                  <div>
                    <div className="home-banner-wgt__headline">Happy Birthday !!!</div>
                    <div className="home-banner-wgt__description">Vladislav K.</div>
                  </div>
                  <img className="home-banner-wgt__icon" src={avatar_13} alt="" />
                </div>
              </div>,
              <div className="home-banner-wgt home-banner-wgt_v2 home-banner-wgt_theme_five">
                <img className="home-banner-wgt__img" src={bitmap_t_shirt} alt="" />
                <div className="home-banner-wgt__headline">Corporate hoodie</div>
              </div>,
              <div className="home-banner-wgt home-banner-wgt_theme_five">
                    <div className="home-banner-wgt__inner">
                      <div>
                        <div className="home-banner-wgt__headline home-banner-wgt__headline_theme_green">+150 Bonuses</div>
                        <div className="home-banner-wgt__description">Received from Olena K.</div>
                      </div>
                      <img className="home-banner-wgt__icon" src={avatar_5} alt="" />
                    </div>
                  </div>
        ]
      }
  ],
      randomFirst: 0,
      randomSecond: 0,
      randomThird: 0,
      randomFour: 0,
      randomFive: 0,
      activeSlide: 0
    }
  }
  getRandomNumber() {
  return Math.floor(Math.random() * 3);
}
componentDidMount() {
  const states = ["randomFirst", "randomSecond", "randomThird", "randomFour", "randomFive"];
  let currentIndex = 0;
  let prevIndex = states.length - 1; // Initialize prevIndex with the last index

  setInterval(() => {
    // Set the activeSlide to 'out' after 3 seconds
    this.setState(prevState => ({
      activeSlide: { index: currentIndex, class: 'out' }
    }));

    setTimeout(() => {
      let nextIndex = (currentIndex + 1) % states.length;
      if (nextIndex === prevIndex) {
        // If nextIndex is equal to the previous index, increment it again
        nextIndex = (nextIndex + 1) % states.length;
      }

      // Update the random number for the current slide
      this.setState(prevState => ({
        [states[currentIndex]]: this.getRandomNumber(),
      }));

      // Move to the next slide
      currentIndex = nextIndex;

      // Set the activeSlide to 'in' after 3.2 seconds
      this.setState(prevState => ({
        activeSlide: { index: currentIndex, class: 'in' }
      }));

      prevIndex = currentIndex; // Update prevIndex with the current index
    }, 200);
  }, 3500);
}


  componentWillUnmount() {
    clearInterval(this.animationInterval);
  }

  toggleAnimation = () => {
    this.setState((prevState) => ({
      outAnimation: !prevState.outAnimation
    }));

    // Toggle the state again after 1 or 5 seconds based on the current value of outAnimation.
    if (this.state.outAnimation) {
      setTimeout(() => {
        this.setState({
          outAnimation: false
        });
      }, 1000);
    } else {
      setTimeout(() => {
        this.setState({
          outAnimation: true
        });
      }, 5000);
    }
  };
  render() {
    let homePageTitle = this.props.getTranslate('homePage-Title');
    return <section className="home-banner">
      <div className="wrapper">
        <div className="home-banner__inner">
          <div className="home-banner__col">
            <div className="home-banner__info">
              <div className="home-banner__headline-wrap">
                <h1 className="home-banner__headline">{homePageTitle}</h1>
              </div>
              <div className="home-banner__images home-banner__images_position_mob">
                <img className="home-banner__image" src={image_banner} alt={homePageTitle} />
                <div className={`${this.state.activeSlide.index === 0 ? this.state.activeSlide.class : ''}`}>
                  {this.state.animationElems[0].first[this.state.randomFirst]}
                </div>
                <div className={`${this.state.activeSlide.index === 1 ? this.state.activeSlide.class : ''}`}>
                  {this.state.animationElems[1].second[this.state.randomSecond]}
                </div>
                <div className={`${this.state.activeSlide.index === 2 ? this.state.activeSlide.class : ''}`}>
                  {this.state.animationElems[2].third[this.state.randomThird]}
                </div>
                <div className={`${this.state.activeSlide.index === 3 ? this.state.activeSlide.class : ''}`}>
                  {this.state.animationElems[3].four[this.state.randomFour]}
                </div>
                <div className={`${this.state.activeSlide.index === 4 ? this.state.activeSlide.class : ''}`}>
                  {this.state.animationElems[4].five[this.state.randomFive]}
                </div>
              </div>
              <div className="home-banner__description-wrap">
                <p className="home-banner__description">{this.props.getTranslate('homePage-Description')}</p>
              </div>
              <div className="home-banner__nav">
                <div className="home-banner__nav-col">
                  <Link className="home-banner__btn home-banner__btn_theme_add-company" to="/registration">{this.props.getTranslate('index-RegCompanyButton')}</Link>
                </div>
                <div className="home-banner__nav-col">
                  <DemoModal
                    openBtnClass="home-banner__btn home-banner__btn_theme_add-demo"
                    getTranslate={this.props.getTranslate}
                    queryLink={this.props.queryLink}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="home-banner__col">
            <div className="home-banner__images home-banner__images_position_desctop">
            <div className={`${this.state.activeSlide.index === 0 ? this.state.activeSlide.class : ''}`}>
              {this.state.animationElems[0].first[this.state.randomFirst]}
            </div>
            <div className={`${this.state.activeSlide.index === 1 ? this.state.activeSlide.class : ''}`}>
              {this.state.animationElems[1].second[this.state.randomSecond]}
            </div>
            <div className={`${this.state.activeSlide.index === 2 ? this.state.activeSlide.class : ''}`}>
              {this.state.animationElems[2].third[this.state.randomThird]}
            </div>
            <div className={`${this.state.activeSlide.index === 3 ? this.state.activeSlide.class : ''}`}>
              {this.state.animationElems[3].four[this.state.randomFour]}
            </div>
            <div className={`${this.state.activeSlide.index === 4 ? this.state.activeSlide.class : ''}`}>
              {this.state.animationElems[4].five[this.state.randomFive]}
            </div>

              <img className="home-banner__image" src={image_banner} alt={homePageTitle} />
            </div>
          </div>
        </div>
      </div>
    </section>
  }
}
export default HomeBanner;
