import React from "react";
import { Link } from "react-router-dom";
import sprite from '../../media/icons.svg';
import manage_img1 from '../../media/manage-img1.png';
import manage_img2 from '../../media/manage-img2.png';
import manage_img3 from '../../media/manage-img3.png';
import manage_img4 from '../../media/manage-img4.png';
import admin_1 from '../../media/admin-1.png';
import admin_2 from '../../media/admin-2.png';
import admin_3 from '../../media/admin-3.png';
import admin_4 from '../../media/admin-4.png';
import admin_5 from '../../media/admin-5.png';
import AboutInfoService from './AboutInfoService';
class AboutInfoServices extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <section className="about-info-services">
      <div className="wrapper">
        <AboutInfoService
          headline={this.props.getTranslate('aboutPanel-AboutInfoServiceWgtFirstHeadline')}
          description={this.props.getTranslate('aboutPanel-AboutInfoServiceWgtFirstDescription')}
          link="#"
          linkTxt={this.props.getTranslate('aboutPanel-AboutInfoServiceLinkTxt')}
          thumbnail={admin_1}
        />
        <AboutInfoService
          headline={this.props.getTranslate('aboutPanel-AboutInfoServiceWgtSecondHeadline')}
          description={this.props.getTranslate('aboutPanel-AboutInfoServiceWgtSecondDescription')}
          link="#"
          linkTxt={this.props.getTranslate('aboutPanel-AboutInfoServiceLinkTxt')}
          thumbnail={admin_2}
          reverse={true}
        />
        <AboutInfoService
          headline={this.props.getTranslate('aboutPanel-AboutInfoServiceWgtThirdHeadline')}
          description={this.props.getTranslate('aboutPanel-AboutInfoServiceWgtThirdDescription')}
          link="#"
          linkTxt={this.props.getTranslate('aboutPanel-AboutInfoServiceLinkTxt')}
          thumbnail={admin_3}
        />
        <AboutInfoService
          headline={this.props.getTranslate('aboutPanel-AboutInfoServiceWgtFourHeadline')}
          description={this.props.getTranslate('aboutPanel-AboutInfoServiceWgtFourDescription')}
          link="#"
          linkTxt={this.props.getTranslate('aboutPanel-AboutInfoServiceLinkTxt')}
          thumbnail={admin_4}
          reverse={true}
        />
        <AboutInfoService
          headline={this.props.getTranslate('aboutPanel-AboutInfoServiceWgtFiveHeadline')}
          description={this.props.getTranslate('aboutPanel-AboutInfoServiceWgtFiveDescription')}
          link="#"
          linkTxt={this.props.getTranslate('aboutPanel-AboutInfoServiceLinkTxt')}
          thumbnail={admin_5}
        />
      </div>
    </section>;
  }
}
export default AboutInfoServices;
