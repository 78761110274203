import React from "react";
import { Link } from "react-router-dom";
import sprite from '../../media/icons.svg';
class BlogViewContent extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <section className="blog-page-content">
      <div className="wrapper">
        <h1 className="blog-page-content__headline">{this.props.headline}</h1>
        <div className="blog-page-content__post" dangerouslySetInnerHTML={{__html: this.props.content}}></div>
      </div>
    </section>
  }
}
export default BlogViewContent;
