import React, { useRef, useEffect } from 'react';
const InfiniteScrollingList = (props) => {
  const listRef = useRef(null);
  const listItemWidthRef = useRef(0);
  const requestIdRef = useRef(null);
  const lastTimeRef = useRef(0);
  const scrollDistanceRef = useRef(0);

  useEffect(() => {
    const list = listRef.current;
    const listItems = list.children;
    const listItemWidth = listItems[0].clientWidth;
    listItemWidthRef.current = listItemWidth;

    const animateScroll = (time) => {
      if (lastTimeRef.current !== 0) {
        const deltaTime = time - lastTimeRef.current;
        scrollDistanceRef.current += (deltaTime / 1000) * 50; // Меняем значение 50 для скорости анимации

        if (scrollDistanceRef.current >= listItemWidth) {
          scrollDistanceRef.current = 0;
          list.appendChild(listItems[0]);
        }

        list.style.transform = `translateX(-${scrollDistanceRef.current}px)`;
      }

      lastTimeRef.current = time;
      requestIdRef.current = requestAnimationFrame(animateScroll);
    };

    requestIdRef.current = requestAnimationFrame(animateScroll);

    return () => {
      cancelAnimationFrame(requestIdRef.current);
    };
  }, []);

  return (
    <div className="infinite-scroll-container">
      <ul className={`infinite-scroll-list ${props.className}`} ref={listRef}>
        {renderListItems(props.items)}
      </ul>
    </div>
  );
};

const renderListItems = (items) => {
  return (
    <>
    {items.map((item, index) => {
      return <li key={index}>
              <img src={item} alt="" />
            </li>
    })}
    </>
  );
};

export default InfiniteScrollingList;
