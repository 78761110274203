import React from "react";
class SetValueFromModal extends React.Component {
  constructor(props) {
    super(props);
    this.state= {
      openStatus: false,
      clickedInside: false,
      valueField: ''
    }
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handlerChange(event){
    this.setState({
      valueField: event.target.value
    });
  }
  handlerOpen(){
    this.setState(prevState => ({
      openStatus: !prevState.openStatus
    }))
  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        openStatus: false,
      });
    }
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  render() {
    return <div className="modal-order-set-form" ref={this.setWrapperRef}>
      <button className="modal-order-set-form__button" type="button" onClick={this.handlerOpen.bind(this)}>{this.props.buttonText}</button>
      <div className={`modal-order-set-form__content ${this.state.openStatus ? 'active' : ''}`}>
        <div className="field-wrap">
          <label className="field-wrap__label" htmlFor={this.props.name}>{this.props.label}</label>
          <div className="field-wrap__currency-name">
            <input id={this.props.name} className="field-wrap__input" placeholder={this.props.placeholder} value={this.state.valueField} onChange={this.handlerChange.bind(this)} />
            {this.props.labelInto ? <label className="field-wrap__currency-name-item" htmlFor={this.props.name}>{this.props.labelInto}</label> : ''}
          </div>
        </div>
        <div className="field-wrap__submit">
          <button className="btn btn_width_100 btn_size_lg" type="button" onClick={this.props.handler.bind(this, this.state.valueField)}>{this.props.submitButtonText}</button>
        </div>
      </div>
    </div>
  }
}
export default SetValueFromModal;
