import React from "react";
import { Link } from "react-router-dom";
import sprite from '../../media/icons.svg';
class AboutInfoWgt extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <>
      <div className="about-info-wgt">
        <div className="about-info-wgt__inner">
          <div className="about-info-wgt__content">
            <div className="about-info-wgt__headline">{this.props.headline}</div>
            <div className="about-info-wgt__description" dangerouslySetInnerHTML={{__html: this.props.description}}></div>
            <Link className="main-link" to={this.props.link}>{this.props.linkTxt}</Link>
          </div>
          <div className="about-info-wgt__icon-wrap">
            <svg className="about-info-wgt__icon">
              <use href={`${sprite + '#' + this.props.icon}`}></use>
            </svg>
          </div>
          <div className="about-info-wgt__line" style={{backgroundColor: this.props.colorLine}}></div>
        </div>
      </div>
    </>;
  }
}
export default AboutInfoWgt;
