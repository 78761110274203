import React from "react";
import { Link } from "react-router-dom";
import Loading from './components/Loading';
import AccountHeader from './components/AccountHeader';
import Navigation from './components/Navigation';
import ResultErrors from './components/ResultErrors';
import sprite from './media/icons.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
class ControlPanelDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      modalDisabled: false,
      modal: false,
      companiesCount: null,
      countInfo: null,
      periodActive: 'TODAY',
      isLoaded: false,
      errors: null,
      error: false,
      token: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("token-admin") ? JSON.parse(localStorage.getItem("token-admin")) : JSON.parse('')) : ''
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modal: false
      }));
    }, 500);
  }
  componentDidMount() {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });

    fetch(`${that.props.queryLink}/bo/v1/configs/dashboard`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            countInfo: data,
            isLoaded: true
          });
        })
      } else if(res.status === 401) {
        res.json().then(function(data) {
          that.setState({
            errors: [data.message],
            isLoaded: true,
            error: true
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            isLoaded: true,
            error: true
          });
        })
      }
    })
    })
  }
  handlerDashboardInfo(period, event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/dashboard?periodType=${period}`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          console.log(data);
          that.setState({
            countInfo: data,
            periodActive: period,
            isLoaded: true
          });
        })
      } else if(res.status === 401) {
        res.json().then(function(data) {
          that.setState({
            errors: [data.message],
            isLoaded: true,
            error: true
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            isLoaded: true,
            error: true
          });
        })
      }
    })
  })
}
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  render() {
    let resultContent = '';
    if(this.state.error) {
      resultContent = <div className="errors-content">Ошибка...</div>;
    } else if(!this.state.isLoaded){
      resultContent = <Loading />;
    } else {
      resultContent =             <div className="account-content__content">
              <div className="account-wgt account-wgt__dashboard">
                <div className="account-content__navs">
                  <ul className="bo-dashboard-navs">
                    <li className="bo-dashboard-navs__item">
                      <button className={`bo-btn ${this.state.periodActive === 'TODAY' ? 'active' : ''}`} type="button" onClick={this.handlerDashboardInfo.bind(this, 'TODAY')}>Сьогодні</button>
                    </li>
                    <li className="bo-dashboard-navs__item">
                      <button className={`bo-btn ${this.state.periodActive === 'YESTERDAY' ? 'active' : ''}`} type="button" onClick={this.handlerDashboardInfo.bind(this, 'YESTERDAY')}>Вчора</button>
                    </li>
                    <li className="bo-dashboard-navs__item">
                      <button className={`bo-btn ${this.state.periodActive === 'LAST_30_DAYS' ? 'active' : ''}`} type="button" onClick={this.handlerDashboardInfo.bind(this, 'LAST_30_DAYS')}>Останні 30 днів</button>
                    </li>
                    <li className="bo-dashboard-navs__item">
                      <button className={`bo-btn ${this.state.periodActive === 'THIS_MONTH' ? 'active' : ''}`} type="button" onClick={this.handlerDashboardInfo.bind(this, 'THIS_MONTH')}>За цей місяць</button>
                    </li>
                  </ul>
                </div>
                <div className="bo-dashboard-content__statistics">
                  <div className="bo-dashboard-content__statistics-inner">
                    <div className="bo-dashboard-content__statistics-wgt">
                      <div className="bo-dashboard-content__statistics-wgt-inner">
                        <div className="bo-dashboard-content__statistics-value">{this.state.countInfo.newCompanyCount}</div>
                        <div className="bo-dashboard-content__statistics-name">Додано компаній</div>
                      </div>
                    </div>
                    <div className="bo-dashboard-content__statistics-wgt">
                      <div className="bo-dashboard-content__statistics-wgt-inner">
                        <div className="bo-dashboard-content__statistics-value">{this.state.countInfo.newUserCount}</div>
                        <div className="bo-dashboard-content__statistics-name">Зареєстровано юзерів</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bo-dashboard-content__statistics">
                  <h2 className="account-content__headline">Активні запити</h2>
                  <div className="bo-dashboard-content__statistics-inner">
                    <div className="bo-dashboard-content__statistics-wgt">
                      <div className="bo-dashboard-content__statistics-wgt-inner">
                        <div className="bo-dashboard-content__statistics-value">{this.state.countInfo.appealCount}</div>
                        <div className="bo-dashboard-content__statistics-name">Запитів в підтримку</div>
                      </div>
                    </div>
                    <div className="bo-dashboard-content__statistics-wgt">
                      <div className="bo-dashboard-content__statistics-wgt-inner">
                        <div className="bo-dashboard-content__statistics-value">{this.state.countInfo.demoCount}</div>
                        <div className="bo-dashboard-content__statistics-name">Запитів на демо</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    }
    return <>
      <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
      <AccountHeader status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
      <div className="account-wrap account-wrap__admin">
        <Navigation status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
        <div className="account-content">
          <div className="account-content__inner">
            <div className="account-content__header">
              <div className="account-content__header-inner">
                <h1 className="account-content__headline">Статистика</h1>
              </div>
              <a className="header__language header__language-content" href="#">RU</a>
            </div>
            {resultContent}
          </div>
        </div>
      </div>
    </>
  }
}
export default ControlPanelDashboard;
