import React from "react";
import { Helmet } from 'react-helmet';
import Header from './components/Header';
import Footer from './components/Footer';
import BlogViewHeader from './components/BlogPage/BlogViewHeader';
import BlogViewThumb from './components/BlogPage/BlogViewThumb';
import BlogViewContent from './components/BlogPage/BlogViewContent';
import BlogViewOthers from './components/BlogPage/BlogViewOthers';
import { Link, useParams, Navigate } from "react-router-dom";
import sprite from './media/icons.svg';
class BlogView extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        thumb: null,
        name: null,
        content: null,
        category: null,
        blogData: []
      };
    }
    handlerUpdateLocal(local, event) {
      let that = this;
      const blogView = async (formData) => {
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Accept-Language': local
      });
      fetch(`${that.props.queryLink}/bo/v1/external/configs/blogs/links/${this.props.code}`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if (res.status === 200) {
          res.json().then(function(data) {
            that.setState({
              thumb: data.mainPhoto,
              name: data.name,
              content: data.content,
              category: data.category,
            });
          })
        }
      })
      }
      const blogOthers = async (formData) => {
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Accept-Language': local
      });
      fetch(`${that.props.queryLink}/bo/v1/external/configs/blogs/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "blogType": "BLOG",
          "direction": "DESC",
          "itemsPerPage": 4
        })
      }).then(res => {
        if (res.status === 200) {
          res.json().then(function(data) {
            that.setState({
              blogData: data.data
            });
          })
        }
      })
      }

      const getPostView = async () => {
      await blogView();
      };
      const getOthers = async () => {
      await blogOthers();
      };
      getPostView()
      .then(() => {
      return getOthers();
      })
      .catch((error) => {
      console.error('Error:', error);
      });
    }
    componentDidMount() {
      if (typeof window !== "undefined" && typeof document !== 'undefined') {
        window.scrollTo(0, 0);
      } else {
        this.setState({
          thumb: this.props.dataComponent.mainPhoto,
          name: this.props.dataComponent.name,
          content: this.props.dataComponent.content,
          category: this.props.dataComponent.category,
        });
      }

      let location;
      let language = 'uk-UA';
      if (typeof window !== "undefined" && typeof document !== 'undefined') {
        location = document.location.pathname;
        const isEnglish = location.startsWith('/en');
        if (isEnglish) {
          language = 'en-US';
        }
      }

      const fetchBlogView = async () => {
        const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Accept-Language': language,
        });

        const response = await fetch(`${this.props.queryLink}/bo/v1/external/configs/blogs/links/${this.props.code}`, {
          method: 'GET',
          headers: myHeaders,
        });

        if (response.status === 200) {
          const data = await response.json();
          let imageUrl = data.socialNetworkPhoto;
          fetch(imageUrl)
            .then(response => {
              // Check if the response is OK
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.blob(); // Convert the response to a blob
            })
            .then(blob => {
              // Create a URL for the blob
              const imageObjectURL = URL.createObjectURL(blob);

              // Create an image element
              const img = document.createElement('img');
              img.src = imageObjectURL;

              // Optionally set attributes for the image
              img.alt = 'Fetched Image';
              img.style.width = '100px'; // Set desired width
              img.style.height = 'auto'; // Maintain aspect ratio

              // Append the image to the document body or any other container
              document.body.appendChild(img);
            })
            .catch(error => {
              console.error('There was a problem with the fetch operation:', error);
            });

          this.setState({
            thumb: data.mainPhoto,
            name: data.name,
            content: data.content,
            category: data.category,
            socialNetworkName: data.socialNetworkName,
            socialNetworkPhoto: data.socialNetworkPhoto,
            socialNetworkDescription: data.socialNetworkDescription,
          });

          const ogTitle = document.querySelector('meta[property="og:title"]');
          if (ogTitle) {
            ogTitle.setAttribute('content', data.socialNetworkName);
          }
          const ogImage = document.querySelector('meta[property="og:image"]');
          if (ogImage) {
            ogImage.setAttribute('content', data.socialNetworkPhoto);
          }
          const ogDescription = document.querySelector('meta[property="og:description"]');
          if (ogDescription) {
            ogDescription.setAttribute('content', data.socialNetworkDescription);
          }
          const ogUrl = document.querySelector('meta[property="og:url"]');
          if (ogUrl) {
            ogUrl.setAttribute('content', data.link);
          }
        }
      };

      const fetchBlogOthers = async () => {
        const myHeaders = new Headers({
          'Content-Type': 'application/json',
        });

        const response = await fetch(`${this.props.queryLink}/bo/v1/external/configs/blogs/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "blogType": "BLOG",
            "direction": "DESC",
            "itemsPerPage": 4,
          }),
        });

        if (response.status === 200) {
          const data = await response.json();
          this.setState({
            blogData: data.data,
          });
        }
      };

      const getPostView = async () => {
        await fetchBlogView();
      };
      const getOthers = async () => {
        await fetchBlogOthers();
      };

      getPostView()
        .then(() => {
          return getOthers();
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  render() {
    let location;
    let isEnglish;

    let targetPath;
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      location = window.location.pathname;
      isEnglish = location.startsWith('/en');

      if (isEnglish) {
        targetPath = location === '/en' ? '/' : location.substring('/en'.length);
      } else {
        targetPath = `/en${location}`;
      }
    }
    return <>
    {this.props.PageWithCanonical}
    <Header getPermalink={this.props.getPermalink} logged={this.props.logged} userPhoto={this.props.userPhoto} userName={this.props.userName} handlerUpdateLocal={this.handlerUpdateLocal.bind(this)} handlerChangeLanguage={this.props.handlerChangeLanguage} chooseLanguage={this.props.chooseLanguage} getTranslate={this.props.getTranslate} />
    <main className="blog-page">
      <div className="blog-page__inner">
        <BlogViewHeader getPermalink={this.props.getPermalink} headline={this.state.name} category={this.state.category} />
        <BlogViewThumb headline={this.state.name} thumb={this.state.thumb} />
        <BlogViewContent headline={this.state.name} content={this.state.content} />
      </div>
      <BlogViewOthers getPermalink={this.props.getPermalink} blogData={this.state.blogData} isEnglish={isEnglish} />
    </main>
    <Footer getPermalink={this.props.getPermalink} getTranslate={this.props.getTranslate} />
    </>;
  }
}
export default (props) => <BlogView {...useParams()} {...props} />
