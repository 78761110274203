import React, { Component } from 'react';

class PageWithCanonical extends Component {
  componentDidMount() {
    this.updateCanonicalLink();
  }

  componentDidUpdate() {
    this.updateCanonicalLink();
  }

  updateCanonicalLink() {
    const currentUrl = window.location.href;
    const head = document.head;
    let canonicalLink = document.getElementById('canonical-link');

    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.id = 'canonical-link';
      canonicalLink.rel = 'canonical';
      head.appendChild(canonicalLink);
    }

    canonicalLink.href = currentUrl;
  }

  render() {
    return null; // Return null as we don't need to render any component
  }
}

export default PageWithCanonical;
