import React from "react";
import Button from './Button';
import sprite from '../media/icons.svg';
class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortOpen: false,
      clickedInside: false
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClick() {
    this.setState((prevState) => ({
      sortOpen: !prevState.sortOpen,
      clickedInside: true,
    }));
  }

  handlerOpen(event) {
    let targetElement = event.target;
    while (targetElement && !targetElement.classList.contains('current-filter__btn')) {
      targetElement = targetElement.parentElement;
    }
    if (!targetElement) {
      this.setState(prevState => ({
        sortOpen: !prevState.sortOpen
      }));
      if (this.props.openQuery) {
        this.props.openQuery();
      }
    }
  }


  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && !this.state.clickedInside) {
      this.setState({
        sortOpen: false,
      });
    }
    this.setState({ clickedInside: false });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setWrapperRefButton(node){
    this.wrapperRefButton = node;
  }

  render() {
    return  <div className={`control-wgt ${this.props.className ? this.props.className : ''}`} ref={this.setWrapperRef}>

                <Button
                  className={this.props.currentFilterVal ? `filter-current_${this.props.currentFilterVal.toLowerCase()}` : ''}
                  type="button"
                  btnTheme="secondary"
                  icon={this.props.iconItemFalse ? false : true}
                  iconItem={this.props.iconItemFalse ? '' : "#filter-icon"}
                  iconClass={this.props.iconItemFalse ? '' : "control-btn-orders__icon"}
                  text={this.props.labelDisabled ? '' : this.props.buttonName}
                  handler={this.handlerOpen.bind(this)}
                  headlerRestore={this.props.handler.bind(this, null, '')}
                  currentFilter={this.props.currentFilterVal ? this.props.currentFilter : ''}
                />

                <div className={`control-content control-content_align_right ${this.state.sortOpen ? 'active' : ''}`}>
                  <ul className="control-content__list">
                    {this.props.dataSort.map((item, index) => {
                      return <li key={index} onClick={this.handleClick}>
                              <button className="control-content__btn" type="button" onClick={this.props.handler.bind(this, item.value ? item.value : item.type, item.name)}>{item.name}</button>
                            </li>;
                    })}
                  </ul>
                </div>
              </div>
  }
}
export default Filter;
