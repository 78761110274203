import React from "react";
import { Link } from "react-router-dom"
import HomeScrolling from './HomeScrolling';
import sprite from '../../media/icons.svg';
import avatar_7 from '../../media/avatar_7.png';

import item_1 from '../../media/bonuses-img/item-1.png';
import item_2 from '../../media/bonuses-img/item-2.png';
import item_3 from '../../media/bonuses-img/item-3.png';
import item_4 from '../../media/bonuses-img/item-4.png';
import item_5 from '../../media/bonuses-img/item-5.png';
import item_6 from '../../media/bonuses-img/item-6.png';
import item_7 from '../../media/bonuses-img/item-7.png';
import item_8 from '../../media/bonuses-img/item-8.png';
import item_9 from '../../media/bonuses-img/item-9.png';
import item_10 from '../../media/bonuses-img/item-10.png';
import item_11 from '../../media/bonuses-img/item-11.png';
import item_12 from '../../media/bonuses-img/item-12.png';
import item_13 from '../../media/bonuses-img/item-13.png';
import item_14 from '../../media/bonuses-img/item-14.png';
import item_15 from '../../media/bonuses-img/item-15.png';
import item_16 from '../../media/bonuses-img/item-16.png';
import item_17 from '../../media/bonuses-img/item-17.png';
import item_18 from '../../media/bonuses-img/item-18.png';
import item_19 from '../../media/bonuses-img/item-19.png';
import item_20 from '../../media/bonuses-img/item-20.png';
import item_21 from '../../media/bonuses-img/item-21.png';
import item_23 from '../../media/bonuses-img/item-23.png';
import item_24 from '../../media/bonuses-img/item-24.png';
import item_25 from '../../media/bonuses-img/item-25.png';
import item_26 from '../../media/bonuses-img/item-26.png';
import item_27 from '../../media/bonuses-img/item-27.png';
import item_28 from '../../media/bonuses-img/item-28.png';
class HomeBonuses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countCart: 0,
      targetValue: 0,
      min: 2000,
      max: 2300,
      step: 10
    }
  }

  componentDidMount() {
    // Генерируем случайное начальное число и желаемое значение
    const initialCount = Math.floor(Math.random() * (this.state.max - this.state.min + 1)) + this.state.min;
    const initialTarget = Math.floor(Math.random() * (this.state.max - this.state.min + 1)) + this.state.min;

    this.setState(
      {
        countCart: initialCount,
        targetValue: initialTarget,
      },
      () => {
        // Запускаем первое изменение числа после установки начальных значений
        this.updateCounter();
      }
    );
  }

  componentWillUnmount() {
    // Очищаем таймаут при размонтировании компонента
    clearTimeout(this.timeout);
  }

  updateCounter = () => {
    // Получаем текущее значение числа, желаемое значение и шаг
    const { countCart, targetValue, step } = this.state;

    // Определяем направление изменения числа (увеличение или уменьшение)
    const direction = targetValue > countCart ? 1 : -1;

    // Вычисляем новое значение числа с учетом шага и направления
    const newCount = countCart + direction * step;

    // Если новое значение числа не достигло желаемого, продолжаем обновлять его
    if ((direction === 1 && newCount <= targetValue) || (direction === -1 && newCount >= targetValue)) {
      // Обновляем состояние компонента с новым числом
      this.setState({ countCart: newCount });

      // Запускаем следующий таймаут для обновления числа
      this.timeout = setTimeout(this.updateCounter, 10);
    } else {
      // Если новое значение числа достигло желаемого, обновляем желаемое значение
      this.setState({ countCart: targetValue }, () => {
        // Запускаем таймаут для установки нового случайного значения через 3 секунды
        this.timeout = setTimeout(this.setRandomValue, 3000);
      });
    }
  };

  setRandomValue = () => {
    // Генерируем случайное число в диапазоне от 500 до 5000
    const randomValue = Math.floor(Math.random() * (this.state.max - this.state.min + 1)) + this.state.min;

    // Обновляем состояние компонента с новым желаемым значением
    this.setState({ targetValue: randomValue }, () => {
      // Запускаем первое изменение числа после установки нового значения
      this.updateCounter();
    });
  };

  formatNumberWithSpaces = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };



  render() {
    const { countCart } = this.state;
    const formattedCount = this.formatNumberWithSpaces(countCart);
    const items_scrolling_first = [
      {
        img: item_1,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt1'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt1'),
        theme: 'green'
      },
      {
        img: item_2,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt2'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt2'),
        theme: 'red'
      },
      {
        img: item_3,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt3'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt3'),
        theme: 'green'
      },
      {
        img: item_4,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt4'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt4'),
        theme: 'red'
      },
      {
        img: item_5,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt5'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt5'),
        theme: 'green'
      },
      {
        img: item_6,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt6'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt6'),
        theme: 'red'
      },
      {
        img: item_7,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt7'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt7'),
        theme: 'green'
      },
      {
        img: item_8,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt8'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt8'),
        theme: 'green'
      },
      {
        img: item_9,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt9'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt9'),
        theme: 'red'
      },
      {
        img: item_10,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt10'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt10'),
        theme: 'green'
      },
      {
        img: item_11,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt11'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt11'),
        theme: 'green'
      },
      {
        img: item_12,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt12'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt12'),
        theme: 'red'
      },
      {
        img: item_13,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt13'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt13'),
        theme: 'green'
      },
      {
        img: item_14,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt14'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt14'),
        theme: 'green'
      },
      {
        img: item_15,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt15'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt15'),
        theme: 'green'
      },
      {
        img: item_16,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt16'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt16'),
        theme: 'red'
      },
      {
        img: item_17,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt17'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt17'),
        theme: 'green'
      },
      {
        img: item_18,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt18'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt18'),
        theme: 'green'
      },
      {
        img: item_19,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt19'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt19'),
        theme: 'red'
      },
      {
        img: item_20,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt20'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt20'),
        theme: 'green'
      },
      {
        img: item_21,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt21'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt21'),
        theme: 'red'
      },
      {
        img: item_13,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt13'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt13'),
        theme: 'green'
      },
      {
        img: item_23,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt23'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt23'),
        theme: 'green'
      },
      {
        img: item_24,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt24'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt24'),
        theme: 'green'
      },
      {
        img: item_25,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt25'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt25'),
        theme: 'red'
      },
      {
        img: item_26,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt26'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt26'),
        theme: 'red'
      },
      {
        img: item_27,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt27'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt27'),
        theme: 'red'
      },
      {
        img: item_28,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt28'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt28'),
        theme: 'red'
      },
    ];
    const items_scrolling_second = [
      {
        img: item_5,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt5'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt5'),
        theme: 'green'
      },
      {
        img: item_6,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt6'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt6'),
        theme: 'red'
      },
      {
        img: item_1,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt1'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt1'),
        theme: 'green'
      },
      {
        img: item_7,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt7'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt7'),
        theme: 'green'
      },
      {
        img: item_8,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt8'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt8'),
        theme: 'green'
      },
      {
        img: item_4,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt4'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt4'),
        theme: 'red'
      },
      {
        img: item_9,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt9'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt9'),
        theme: 'red'
      },
      {
        img: item_10,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt10'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt10'),
        theme: 'green'
      },
      {
        img: item_14,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt14'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt14'),
        theme: 'green'
      },
      {
        img: item_11,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt11'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt11'),
        theme: 'green'
      },
      {
        img: item_12,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt12'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt12'),
        theme: 'red'
      },
      {
        img: item_13,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt13'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt13'),
        theme: 'green'
      },
      {
        img: item_28,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt28'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt28'),
        theme: 'red'
      },
      {
        img: item_15,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt15'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt15'),
        theme: 'green'
      },
      {
        img: item_17,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt17'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt17'),
        theme: 'green'
      },
      {
        img: item_18,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt18'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt18'),
        theme: 'green'
      },
      {
        img: item_19,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt19'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt19'),
        theme: 'red'
      },
      {
        img: item_20,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt20'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt20'),
        theme: 'green'
      },
      {
        img: item_21,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt21'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt21'),
        theme: 'red'
      },
      {
        img: item_13,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt13'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt13'),
        theme: 'green'
      },
      {
        img: item_23,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt23'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt23'),
        theme: 'green'
      },
      {
        img: item_16,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt16'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt16'),
        theme: 'red'
      },
      {
        img: item_26,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt26'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt26'),
        theme: 'red'
      },
      {
        img: item_27,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt27'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt27'),
        theme: 'red'
      },
      {
        img: item_24,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt24'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt24'),
        theme: 'green'
      },
      {
        img: item_2,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt2'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt2'),
        theme: 'red'
      },
      {
        img: item_3,
        headline: this.props.getTranslate('sliderBonuse-headlineWgt3'),
        description: this.props.getTranslate('sliderBonuse-descriptionWgt3'),
        theme: 'green'
      },
    ]
    return <section className="home-bonuses">
      <div className="wrapper home-bonuses__inner">
        <div className="main-headline-wrap">
          <h2 className="main-headline">{this.props.getTranslate('homePage-BonusesTitle')}</h2>
        </div>
      </div>
      <div className="wrapper">
        <div className="home-bonuses__wgt">
          <div className="home-bonuses__wgt-card">
            <div className="home-bonuses__wgt-card-headline">
              <div className="home-bonuses__wgt-card-name">Peter Parker</div>
              <img className="home-bonuses__wgt-card-photo" src={avatar_7} alt="" />
            </div>
            <div className="home-bonuses__wgt-card-content">
              <div className="home-bonuses__wgt-card-position">Middle Front-End Developer</div>
              <div className="home-bonuses__wgt-card-team">Front-End Team</div>
            </div>
            <div className="home-bonuses__wgt-card-footer">
              <div className="home-bonuses__wgt-card-price">{formattedCount}</div>
              <div className="home-bonuses__wgt-card-currency">Bonuses</div>
            </div>
          </div>
          <div className="home-bonuses__wgt-inner">
            <div className="home-bonuses__wgt-points">
            <HomeScrolling items={items_scrolling_first}/>
            </div>
            <div className="home-bonuses__wgt-points home-bonuses__wgt-points_second">
              <HomeScrolling items={items_scrolling_second}/>
            </div>
            <div className="home-bonuses__wgt-row">
              <div className="home-bonuses__wgt-col">
                <div className="home-bonuses__wgt-content">
                  <div className="home-bonuses__wgt-headline-wrap">
                    <h3 className="home-bonuses__wgt-headline">{this.props.getTranslate('homePage-BonusesFirstWgtTitle')}</h3>
                  </div>
                  <div className="home-bonuses__wgt-description">{this.props.getTranslate('homePage-BonusesFirstWgtDescription')}</div>
                </div>
              </div>
              <div className="home-bonuses__wgt-col">
                <div className="home-bonuses__wgt-content">
                  <div className="home-bonuses__wgt-headline-wrap">
                    <h3 className="home-bonuses__wgt-headline">{this.props.getTranslate('homePage-BonusesSecondWgtTitle')}</h3>
                  </div>
                  <div className="home-bonuses__wgt-description" dangerouslySetInnerHTML={{__html: this.props.getTranslate('homePage-BonusesSecondWgtDescription')}}></div>
                </div>
              </div>
            </div>
            <div className="home-bonuses__wgt-footer">
              <Link className="main-link" to="#">{this.props.getTranslate('linkMore-Name')}
                <svg className="main-link__icon">
                  <use href={`${sprite}#link-chevron`}></use>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  }
}
export default HomeBonuses;
