import React from "react";
class DateFormating extends React.Component {
  constructor(props) {
    super(props);
  }
  dateFormating(start, end, time, timeEnd, separator){
    let dateStart = new Date(Date.parse(start));
    let dateEnd = new Date(Date.parse(end));
    let valueDateStart = dateStart.getDate();
    let valueMonthStart = dateStart.getMonth() + 1;
    let valueYearStart = dateStart.getFullYear();
    let valueHoursStart = dateStart.getHours();
    let valueMinutesStart = dateStart.getMinutes();
    let valueDateEnd = dateEnd.getDate();
    let valueMonthEnd = dateEnd.getMonth() + 1;
    let valueYearEnd = dateEnd.getFullYear();
    let valueHoursEnd = dateEnd.getHours();
    let valueMinutesEnd = dateEnd.getMinutes();
    if(valueDateStart < 10){
      valueDateStart = '0' + valueDateStart;
    }
    if(valueMonthStart < 10){
      valueMonthStart = '0' + valueMonthStart;
    }
    if(valueHoursStart < 10){
      valueHoursStart = '0' + valueHoursStart;
    }
    if(valueMinutesStart < 10){
      valueMinutesStart = '0' + valueMinutesStart;
    }
    if(valueDateEnd < 10){
      valueDateEnd = '0' + valueDateEnd;
    }
    if(valueMonthEnd < 10){
      valueMonthEnd = '0' + valueMonthEnd;
    }
    if(valueHoursEnd < 10){
      valueHoursEnd = '0' + valueHoursEnd;
    }
    if(valueMinutesEnd < 10){
      valueMinutesEnd = '0' + valueMinutesEnd;
    }
    if(end){
      if(time === 'off' && timeEnd){
        return valueDateStart + '.' + valueMonthStart + '.' + valueYearStart + ' – ' + valueDateEnd + '.' + valueMonthEnd + '.' + valueYearEnd + ', ' + separator + ' ' + valueHoursStart + ':' + valueMinutesStart;
      } else if(time === 'off') {
        return valueDateStart + '.' + valueMonthStart + '.' + valueYearStart + ' – ' + valueDateEnd + '.' + valueMonthEnd + '.' + valueYearEnd;
      } else {
        return valueDateStart + '.' + valueMonthStart + '.' + valueYearStart + ', ' + valueHoursStart + ':' + valueMinutesStart + ' – ' + valueDateEnd + '.' + valueMonthEnd + '.' + valueYearEnd + ', ' + valueHoursEnd + ':' + valueMinutesEnd;
      }
    } else {
      if(time === 'off'){
        return valueDateStart + '.' + valueMonthStart + '.' + valueYearStart;
      } else {
        return valueDateStart + '.' + valueMonthStart + '.' + valueYearStart + ', ' + valueHoursStart + ':' + valueMinutesStart;
      }
    }
  }
  render() {
    return <>{this.dateFormating(this.props.start, this.props.end, this.props.time, this.props.timeEnd, this.props.separator)}</>
  }
}
export default DateFormating;
