import React from "react";
import AccountHeader from './components/AccountHeader';
import DateFormating from './components/DateFormating';
import Navigation from './components/Navigation';
import Pagination from './components/Pagination';
import Loading from './components/Loading';
import ResultErrors from './components/ResultErrors';
import Select from 'react-select';
import sprite from './media/icons.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
class ControlPanelMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      modalDisabled: false,
      modal: false,
      message: [],
      messageModal: false,
      messagesData: [],
      currentPage: 0,
      totalPages: null,
      openedMessage: [],
      isLoadedMessage: false,
      answerModal: false,
      answerField: '',
      isLoadedMessageAnswer: false,
      isLoaded: false,
      errors: null,
      error: false,
      controlRow: null,
      appealID: null,
      appealIndex: null,
      appealResolution: false,
      statusSelectItems: [
        { value: 'NEW', label: 'Нове' },
        { value:  'DONE', label: 'Опрацьоване'}
      ],
      resolutionValue: '',
      statusValueModal: null,
      submitLoaded: false,
      token: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("token-admin") ? JSON.parse(localStorage.getItem("token-admin")) : JSON.parse('')) : ''
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        messageModal: false,
        answerModal: false,
        appealResolution: false,
        appealID: null,
        appealIndex: null,
        modal: false
      }));
    }, 500);
  }
  handlerPaginationItems = (messagesItems, current) => {
    this.setState({
      messagesData: messagesItems,
      currentPage: current
    });
  }
  handlerAnswerField (event) {
    this.setState({
      answerField: event.target.value
    });
  }
  componentDidMount() {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/appeals/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "direction": "DESC",
        "itemsPerPage": 20,
        "orderField": "modificationDate"
      })
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            messagesData: data.data,
            totalPages: data.totalPages,
            isLoaded: true
          });
        })
      } else if(res.status === 401) {
        res.json().then(function(data) {
          that.setState({
            errors: [data.message],
            isLoaded: true,
            error: true
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.message,
            isLoaded: true,
            error: true
          });
        })
      }
    })
  })
  }
  handlerMessageType(status, event){
    let that = this;
    this.setState({
      isLoaded: false
    });
    let obj = {
      "direction": "DESC",
      "itemsPerPage": 20,
      "orderField": "modificationDate"
    };
    if(status !== ''){
      obj.status = status;
    }
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/appeals/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(obj)
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            messagesData: data.data,
            totalPages: data.totalPages,
            isLoaded: true
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.message,
            isLoaded: true,
            error: true
          });
        })
      }
    })
  })
  }
  handlerMessage (id, event) {
    let that = this;
    if (!event.target.classList.contains("message-wgt__answer-btn")) {
      if(this.state.openedMessage.indexOf(id) != -1) {
        let myIndex = this.state.openedMessage.indexOf(id);
        let arr = this.state.openedMessage;
        if (myIndex !== -1) {
            arr.splice(myIndex, 1);
        }
        this.setState(prevState => ({
          openedMessage: arr
        }));
      } else {
        this.setState(prevState => ({
          openedMessage: [...prevState.openedMessage, id]
        }));
      }
    }
    if(event.target.closest('.message-wgt__not-read')){
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/bo/v1/configs/appeals/${id}/viewed`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
          "viewed": true
        })
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.message
            });
          })
        }
      }
      )
    })
    }
  }
  answerHandler (id) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/appeals/${id}`, {
      method: 'GET',
      headers: myHeaders,
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState(prevState => ({
            isLoadedMessage: true,
            message: data,
            modal: true,
            messageModal: false,
            answerModal: true,
            modalClass: null
          }))
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            isLoadedMessage: true,
            errors: data.message
          });
        })
      }
    })
  })
  }
  handlerSubmitAnswer (id, event) {
    event.preventDefault();
    let that = this;
    this.setState({
      isLoadedMessageAnswer: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/appeals/${id}/resolutions`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        "resolution": this.state.answerField,
        "status": "NEW",
        "appealID": id
      })
    }).then(res => {
      if (res.status === 200) {
        that.setState(prevState => ({
          isLoadedMessageAnswer: false,
          answerField: '',
        }));
        that.handleModalDisabled();
        fetch(`${that.props.queryLink}/bo/v1/configs/appeals/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "direction": "DESC",
            "itemsPerPage": 20,
            "orderField": "modificationDate"})
        }).then(res => {
          if (res.status === 200) {
            res.json().then(function(data) {
              that.setState({
                messagesData: data.data
              });
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                errors: data.message
              });
            })
          }
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            isLoadedMessageAnswer: false,
            errors: data.message
          });
        })
      }
    })
  })
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  handlerCloseElements(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      const div = document.querySelector( '.control-wgt');
      document.addEventListener('click', e => {
        const withinBoundaries = e.composedPath().includes(div);
        if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
          if ( ! withinBoundaries ) {
            this.setState( {
              controlRow: null
            } );
          }
        }
      });
    }
  }

  handlerCopyEmail(email, event) {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      const tempTextArea = document.createElement('textarea');
      tempTextArea.value = email;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand('copy');
      document.body.removeChild(tempTextArea);
    }
  }

  handlerStatus(id, index, status, event){
    let that = this;
    this.setState({
      isLoaded: false
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/appeals/${id}/status`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({status: status})
    }).then(res => {
      if (res.status === 200) {
        let messages = that.state.messagesData;
        messages[index].status = status;
        res.json().then(function(data) {
          that.setState({
            messagesData: messages,
            isLoaded: true,
            controlRow: null
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.message,
            isLoaded: true
          });
        })
      }
    })
  })
  }

  handlerModalAddResolution(id, index, event){
    this.setState({
      appealID: id,
      appealIndex: index,
      appealResolution: true,
      statusValueModal: this.state.messagesData[index].status,
    });
  }

  handlerResolutionValue(event){
    this.setState({
      resolutionValue: event.target.value
    });
  }

  handlerChangeStatusModal(event){
    this.setState({
      statusValueModal: event.value
    });
  }

  handlerSubmitResolution(event){
    event.preventDefault();
    let that = this;
    this.setState({
      submitLoaded: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/bo/v1/configs/appeals/${that.state.appealID}/resolutions`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
          resolution: that.state.resolutionValue,
          status: that.state.statusValueModal
        })
      }).then(res => {
        if (res.status === 200) {
          res.json().then(function(data) {
            let messages = that.state.messagesData;
            messages[that.state.appealIndex] = data;
            that.setState({
              messagesData: messages,
              submitLoaded: false
            });
          })
          that.handleModalDisabled();
        } else {
          res.json().then(function(data) {
            that.setState({
              errors: data.message,
              submitLoaded: false
            });
          })
        }
      })
    })
  }

  render() {
    return <>
      <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
      <AccountHeader status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
      <div className="account-wrap account-wrap__admin" onClick={this.handlerCloseElements.bind(this)}>
        <Navigation status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
        <div className="account-content">
          <div className="account-content__inner">
            <div className="account-content__header account-content__header-wrap">
              <div className="account-content__header-inner">
                <h1 className="account-content__headline">Підтримка</h1>
              </div>
              <a className="header__language header__language-content" href="#">RU</a>
            </div>
            <div className="account-content__content">
              <div className="bo-account-wgt__nav">
                <button className="bo-btn" type="button">03.03.2023 – 03.07.2023</button>
                <div className="control-wgt bo-control-wgt__message">
                  <button className="control-btn" onClick={this.handlerControl.bind(this, 'status')}>Всі повідомлення</button>
                    <div className={`control-content ${this.state.controlRow === 'status' ? 'active' : ''}`}>
                      <ul className="control-content__list">
                        <li>
                          <button className="control-content__btn" type="button" onClick={this.handlerMessageType.bind(this, '')}>Всі повідомлення</button>
                        </li>
                        <li>
                          <button className="control-content__btn" type="button" onClick={this.handlerMessageType.bind(this, 'NEW')}>Нове</button>
                        </li>
                        <li>
                          <button className="control-content__btn" type="button" onClick={this.handlerMessageType.bind(this, 'DONE')}>Опрацьовано</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="bo-massages-list">
                {this.state.messagesData.map((item, index) => {
                  return <div className="bo-messages-wgt">
                    <div className="bo-messages-wgt__overlay"></div>
                    <div className="bo-messages-wgt__inner">
                      <div className="bo-messages-wgt__content">
                        <div className="bo-messages-wgt__content-info">
                        <div className="bo-messages-wgt__content-inner">
                            <div className="bo-messages-wgt__name">{item.name}{item.email ? (' | ' + item.email) : ''}</div>
                            <span onClick={this.handlerCopyEmail.bind(this, item.email)}>
                              <svg className="bo-messages-wgt__copy-icon"><use href={`${sprite}#copy-icon`}></use></svg>
                            </span>
                            {item.status === 'DONE' ? <div className="control-wgt">
                              <button className="control-btn" onClick={this.handlerControl.bind(this, index)}>
                                <span className="bo-status-label bo-status-label_theme_green">Опрацьовано</span>
                              </button>
                              <div className={`control-content ${this.state.controlRow === index ? 'active' : ''}`}>
                                <ul className="control-content__list">
                                  <li>
                                    <button className="control-content__btn" type="button" onClick={this.handlerStatus.bind(this, item.appealID, index, 'NEW')}>Нове</button>
                                  </li>
                                  <li>
                                    <button className="control-content__btn" type="button" onClick={this.handlerStatus.bind(this, item.appealID, index, 'DONE')}>Опрацьовано</button>
                                  </li>
                                </ul>
                              </div>
                              </div> : <div className="control-wgt">
                                <button className="control-btn" onClick={this.handlerControl.bind(this, index)}>
                                  <span className="bo-status-label">Нове</span>
                                </button>
                                <div className={`control-content ${this.state.controlRow === index ? 'active' : ''}`}>
                                  <ul className="control-content__list">
                                    <li>
                                      <button className="control-content__btn" type="button" onClick={this.handlerStatus.bind(this, item.appealID, index, 'NEW')}>Нове</button>
                                    </li>
                                    <li>
                                      <button className="control-content__btn" type="button" onClick={this.handlerStatus.bind(this, item.appealID, index, 'DONE')}>Опрацьовано</button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              }
                            {item.operatorName ? <div className="bo-messages-wgt__operator">{item.operatorName}</div> : ''}
                          </div>
                          <div className="bo-messages-wgt__date"><DateFormating start={item.modificationDate} /></div>
                        </div>
                        <p>{item.message}</p>
                        {item.resolution ? <div className="bo-messages-wgt__response">
                          <div className="bo-messages-wgt__response-inner">
                            <div className="bo-messages-wgt__response-info">
                              <div className="bo-messages-wgt__response-name">Комментар: {item.operatorName}</div>
                              <div className="bo-messages-wgt__response-date"><DateFormating start={item.resolutionDate} /></div>
                            </div>{item.resolution}
                          </div>
                        </div> : ''}
                      </div>
                      {!item.resolution ? <button className="bo-btn bo-btn_theme_blue bo-messages-wgt__reply-btn" type="button" onClick={this.handlerModalAddResolution.bind(this, item.appealID, index)}>Додати нотатки</button> : ''}
                    </div>
                  </div>})}
                </div>
              </div>
            <div className="account-content__footer">
              <Pagination total={this.state.totalPages} perPage="20" path={`${this.props.queryLink}/bo/v1/configs/appeals/search`} currentPage={this.state.currentPage} items={this.handlerPaginationItems} otherParams={{"direction": "DESC", "orderField": "modificationDate"}} />
            </div>
          </div>
        </div>
      </div>
      {this.state.modal && this.state.answerModal ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.modalClass}` : `two ${this.state.modalClass}`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <><div className="modal-header">
            <h2 className="modal-headline">Додати коментар</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          <form className="answer-form" onSubmit={this.handlerSubmitAnswer.bind(this, this.state.message.appealID)}>
            <div className="answer-form__label">{this.state.message.message}</div>
            <div className="field-wrap">
              <textarea className="field-wrap__input field-wrap__input_accrual" placeholder="Введите текст сообщения”" value={this.state.answerField} onChange={this.handlerAnswerField.bind(this)} />
            </div>
              <div className="field-wrap__submit">
                <button className="btn btn_size_lg btn_width_100 answer-form__nav-btn" type="submit">Отправить{this.state.isLoadedMessageAnswer && <Loading />}</button>
              </div>
          </form></>
      </div>
    </div> : ''}
      {this.state.appealResolution ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal modal__appeal">
          <div className="modal-header">
            <h2 className="modal-headline">Додати нотатки</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          <div className="bo-appeal-content">
            <div className="bo-appeal-content__text">
              <div className="bo-appeal-content__text-inner">{this.state.messagesData[this.state.appealIndex].message}</div>
            </div>
            <form onSubmit={this.handlerSubmitResolution.bind(this)}>
              <div className="field-wrap">
                <textarea className="field-wrap__input field-wrap__input_textarea" value={this.state.resolutionValue} onChange={this.handlerResolutionValue.bind(this)} placeholder="Введіть текст нотаток" />
              </div>
              <div className="bo-appeal-content__field-status">
                <div className="field-wrap">
                  <Select
                    styles={customStyles}
                    options={this.state.statusSelectItems}
                    value={this.state.statusSelectItems[this.state.statusSelectItems.findIndex(el => el.value === this.state.statusValueModal)]}
                    onChange={this.handlerChangeStatusModal.bind(this)}
                    isSearchable={false}
                  />
                </div>
              </div>
              <div className="field-wrap__submit">
                 <button className="btn btn_size_lg" type="submit" disabled={this.state.submitLoaded ? true : false}>Додати нотатки{this.state.submitLoaded && <Loading />}</button>
              </div>
            </form>
          </div>
      </div>
    </div> : ''}
    </>
  }
}
export default ControlPanelMessages;
