import React from "react";
class LabelStatus extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    if(this.props.type !== 'txt'){
      return <span className={`status-label status-label_theme_${this.props.theme}`}>{this.props.name}</span>;
    } else {
      return <span className={`status-label-txt status-label-txt_theme_${this.props.theme}`}>{this.props.name}</span>;
    }
  }
}
export default LabelStatus;
