import React from "react";
import CalendarDropdownSingle from './components/CalendarDropdownSingle';
import OrderModalDropdownStatusChange from './components/OrderModalDropdownStatusChange';
import OrderModalComment from './components/OrderModalComment';
import InfoModal from './components/InfoModal';
import SetValueFromModal from './components/SetValueFromModal';
import DateFormating from './components/DateFormating';
import AccountHeader from './components/AccountHeader';
import AccountContentHeader from './components/AccountContentHeader';
import AccountNav from './components/AccountNav';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ResultQuery from './components/ResultQuery';
import ResultErrors from './components/ResultErrors';
import Loading from './components/Loading';
import { Link, useParams, Navigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import sprite from './media/icons.svg';
import contactPhone from './media/contacts-phone.png';
import contactEmail from './media/contacts-email.png';
import contactTelegram from './media/contacts-telegram.png';
import contactFacebook from './media/contacts-facebook.png';
import contactInstagram from './media/contacts-instagram.png';
import contactLinkedin from './media/contacts-linkedin.png';
import contactTwitter from './media/contacts-twitter.png';
import product_img_default from './media/no-product-image.jpg';
function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString.split('.').reverse().join('.'));
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
function getInfoDate(num){
  let now = new Date();
  if(num){
    let date = new Date(num.split('.').reverse().join('.'));
    const diffMs = now - date;
    const msPerYear = 1000 * 60 * 60 * 24 * 365.25;
    const msPerMonth = msPerYear / 12;
    const msPerDay = 1000 * 60 * 60 * 24;
    const years = Math.floor(diffMs / msPerYear);
    const months = Math.floor((diffMs % msPerYear) / msPerMonth);
    const days = Math.floor((diffMs % msPerYear % msPerMonth) / msPerDay);
    return [years, months, days];
  }
}
function getNumberOfDays(start, end) {
  const date1 = new Date(start);
  const date2 = new Date(end);
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  const diffInDays = Math.round(diffInTime / oneDay);
  return diffInDays;
}
function areAllValuesEmpty(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]) {
      return false;
    }
  }
  return true;
}
class UsersView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      user: [],
      error: null,
      isLoaded: false,
      successSubmit: false,
      modal: false,
      modalEditBalance: false,
      modalEditSuperBalance: false,
      loadingBalance: false,
      balanceValue: '',
      superBalanceValue: '',
      activitesData: null,
      successDeposit: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("successDeposit") ? JSON.parse(localStorage.getItem("successDeposit")) : null) : '',
      token: this.props.token(),
      currencyCompany: (typeof window !== "undefined" && typeof document !== 'undefined') ? localStorage.getItem('currencyMt') : '',
      errors: null,
      modalAd: false,
      dataAdModal: [],
      modalSurvey: false,
      dataSurveyModal: [],
      statusSurvey: null,
      dataEventModal: [],
      modalEvent: false,
      modalOrder: false,
      statusDropDown: false,
      commentDropDown: false,
      sendMessage: false,
      indexModalOrder: null,
      noteValue: '',
      rewards: [],
      bonuseValueNumError: false,
      bonuseValueCountError: false,
      sendActivityTooltip: false,
      sendSuccessActivity: false,
      balanceData: {},
      startDateCalendar: null,
      iterStartCalendar: null,
      iterFinishCalendar: null,
      weeklyDay: null,
      nthWeekday: null,
      dateNum: null,
      monthNum: null,
      iterStart: null,
      iterStartSave: null,
      iterHover: null,
      countMonth: 1,
      countMonthSave: null,
      iterFinish: null,
      countMonthFinish: null,
      loyaltiesNavigate: null
    }
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }
  handlerEditBalance (type, event) {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
    if(type === 'super'){
      this.setState(prevState => ({
        modalEditSuperBalance: !prevState.modalEditSuperBalance
      }));
    } else {
      this.setState(prevState => ({
        modalEditBalance: !prevState.modalEditBalance
      }));
    }
  }
  handleModalDisabled = () => {
    document.querySelector('body').style.overflow = "";
    this.setState(prevState => ({
      modalDisabled: true,
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalEditBalance: false,
        modalEditSuperBalance: false,
        modalDisabled: false,
        modalAd: false,
        dataAdModal: [],
        modalSurvey: false,
        dataSurveyModal: [],
        dataEventModal: [],
        modalEvent: false,
        modalOrder: false,
        orderHistories: [],
        heightInfo: 'auto',
        statusDropDown: false,
        commentDropDown: false
      }));
    }, 500);
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  /* Handler submit price value */
  handlerSubmitSetPrice(value, event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      fetch(`${that.props.queryLink}/api/v1/orders/${that.state.ordersModalResult.orderID}/data`, {
        method: 'PUT',
        headers: that.props.queryHeaders(idToken),
        body: JSON.stringify({"totalPrice": value, "quantity": that.state.ordersModalResult.quantity})
      }).then(res => {
        if(res.status !== 200) {
          setTimeout(() => {
            that.setState({
              errorSubmit: false
            });
          }, 5000);
        } else {
          that.setState( {
            openSetPrice: false
          });
          res.json().then(function(data) {
            let orderModal = data;
            let orders = that.state.orders;
            orders[that.state.indexModalOrder] = data;
            that.setState({
              orders: orders,
              ordersModalResult: orderModal,
              successSubmit: true,
              successText: 'Успішно додано'
            });
          });
          setTimeout(() => {
            that.setState({
              successSubmit: false,
              successText: null
            });
          }, 5000);
        }
      })
    })
  }
  /* Handler submit quantity value */
  handlerSubmitSetQuantity(value, event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      fetch(`${that.props.queryLink}/api/v1/orders/${that.state.ordersModalResult.orderID}/data`, {
        method: 'PUT',
        headers: that.props.queryHeaders(idToken),
        body: JSON.stringify({"quantity": Number(value), "totalPrice": that.state.ordersModalResult.totalPrice})
      }).then(res => {
        if(res.status !== 200) {
          setTimeout(() => {
            that.setState({
              errorSubmit: false
            });
          }, 5000);
        } else {
          that.setState( {
            openSetQuantity: false
          });
          res.json().then(function(data) {
            let orderModal = data;
            let orders = that.state.orders;
            orders[that.state.indexModalOrder] = data;
            that.setState( {
              orders: orders,
              ordersModalResult: orderModal,
              successSubmit: true,
              successText: 'Успішно додано'
            });
          });
          setTimeout(() => {
            that.setState({
              successSubmit: false,
              successText: null
            });
          }, 5000);
        }
      })
    })
  }
  handlerDropDownStatus(event) {
    this.setState(prevState => ({
      statusDropDown: !prevState.statusDropDown
    }));
    if(this.state.commentDropDown){
      this.setState(prevState => ({
        commentDropDown: false
      }));
    }
  }
  handlerDropDownComment(event) {
    this.setState(prevState => ({
      commentDropDown: !prevState.commentDropDown
    }));
    if(this.state.statusDropDown){
      this.setState(prevState => ({
        statusDropDown: false
      }));
    }
  }
  handlerSendMessage(event) {
    this.setState(prevState => ({
      sendMessage: !prevState.sendMessage
    }));
  }
  handlerChangeStatus(status, id, event){
    let that = this;
    this.setState(prevState => ({
      loadingChangeStatus: true,
      typeChangeStatus: status
    }));
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/orders/${id}/status`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({"status": status})
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              error: true,
              loadingChangeStatus: false,
              typeChangeStatus: null
            });
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              statusDropDown: false,
              ordersModalResult: data,
              loadingChangeStatus: false,
              typeChangeStatus: null
            });
          })
          fetch(`${that.props.queryLink}/api/v1/companies/users/${that.props.id}/activities`, {
            method: 'GET',
            headers: myHeaders
          }).then(res => {
            if (res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                });
              })
            } else {
              res.json().then(function(data) {
                that.setState({
                  activitesData: data
                });
              })
            }
          })
        }
      })
    })
  }
  handlerNoteValue(event){
    this.setState({
      noteValue: event.target.value
    });
  }
  handlerSubmitNote(id, event){
    event.preventDefault();
    let that = this;
    this.setState(prevState => ({
      loadingSubmitNote: true
    }));
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/orders/${id}/notes`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "notes": that.state.noteValue,
          'sendNotification': that.state.sendMessage
        })
      }).then(res => {
        if(res.status !== 200) {
            that.setState(prevState => ({
              loadingSubmitNote: false
            }));
        } else {
          that.setState(prevState => ({
            loadingSubmitNote: false,
            commentDropDown: false,
            noteValue: '',
            sendMessage: false
          }));
        }
      })
    })
  }
  handlerGetModalItem(type, id, event){
    let that = this;
    if(this.state.loyaltiesNavigate){
      that.setState({
        loyaltiesNavigate: null
      });
    }
    if(type === 'marketplace'){
        getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/ads/${id}`, {
          method: 'GET',
          headers: myHeaders,
        }).then(res => {
          if (res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState(prevState => ({
                dataAdModal: data,
                modalAd: true
              }));
            })
          }
        })
      })
    } else if(type === 'survey'){
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/surveys/${id}`, {
          method: 'GET',
          headers: myHeaders,
        }).then(res => {
          if (res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              let statusTextModal = '';
              if(data.surveyStatus === 'ACTIVE'){
                statusTextModal = 'Активный';
              } else if (data.surveyStatus === 'NEW') {
                statusTextModal = 'В ожидании';
              } else if (data.surveyStatus === 'DELETED') {
                statusTextModal = 'Удален';
              } else {
                statusTextModal = 'Закончился';
              }
              that.setState(prevState => ({
                statusSurvey: statusTextModal,
                dataSurveyModal: data,
                modalSurvey: true
              }));
            })
          }
        })
      })
    } else if(type === 'event'){
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/events/${id}`, {
          method: 'GET',
          headers: myHeaders,
        }).then(res => {
          if (res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState(prevState => ({
                dataEventModal: data,
                modalEvent: true
              }));
            })
          }
        })
      })
    } else if(type === 'order'){
        getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/orders/${id}`, {
          method: 'GET',
          headers: myHeaders,
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              });
            });
          } else {
            res.json().then(function(data) {
              that.setState(prevState => ({
                ordersModalResult: data,
                modalOrder: !prevState.modalOrder
              }));
              setTimeout(function() {
                let infoDiv = (typeof window !== "undefined" && typeof document !== 'undefined') ? document.querySelector('.modal__order-content-col-info') : '';
                let height = infoDiv.clientHeight;
                that.setState(prevState => ({
                  orderHistories: data.histories,
                  heightInfo: height
                }));
              }, 500);
            })
          }
        })
      })
    } else if(type === 'loyalties'){
      that.setState({
        loyaltiesNavigate: id
      });
    }
  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        statusDropDown: false,
        commentDropDown: false
      });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setWrapperRefButton(node){
    this.wrapperRefButton = node;
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  componentDidMount() {
    if(document.body.classList.contains('preview')){
      document.body.classList.remove('preview');
    }
    let nowDate = new Date();
    let nowDateFull = nowDate.getDate() + '.' + (nowDate.getMonth() + 1) + '.' + nowDate.getFullYear();
    let weeklyDay = nowDate.getDay();
    let nthWeekday = this.getNthWeekdayOfMonth(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate()));
    this.setState( {
      startDateCalendar: nowDateFull,
      endDateCalendar: nowDateFull,
      weeklyDay: weeklyDay,
      nthWeekday: nthWeekday - 1,
      dateNum: nowDate.getDate(),
      monthNum: nowDate.getMonth()
    } );
    document.addEventListener('mousedown', this.handleClickOutside);
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/${that.props.id}`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              error: true,
              isLoaded: true
            });
          })
        } else {
          res.json().then(function(data) {
            that.setState({
              user: data,
              balanceValue: data.balance,
              isLoaded: true
            });
          });
          fetch(`${that.props.queryLink}/api/v1/encouragement/rewards/user-rewards/users/${that.props.id}`, {
            method: 'GET',
            headers: myHeaders
          }).then(res => {
            if (res.status !== 200) {

            } else {
              res.json().then(function(data) {
                that.setState({
                  rewards: data,
                });
              });

              fetch(`${that.props.queryLink}/api/v1/companies/users/${that.props.id}/activities`, {
                method: 'GET',
                headers: myHeaders
              }).then(res => {
                if (res.status !== 200) {
                  res.json().then(function(data) {
                    that.setState({
                      errors: data.errors
                    });
                  })
                } else {
                  res.json().then(function(data) {
                    that.setState({
                      activitesData: data
                    });
                  })

                  fetch(`${that.props.queryLink}/api/v1/accounting/users/${that.props.id}/balance`, {
                    method: 'GET',
                    headers: myHeaders
                  }).then(res => {
                    if (res.status !== 200) {
                      res.json().then(function(data) {
                        that.setState({
                          errors: data.errors
                        });
                      })
                    } else {
                      res.json().then(function(data) {
                        that.setState({
                          balanceValue: data.balance,
                          superBalanceValue: data.superBalance,
                          balanceData: data
                        });
                      })
                    }
                  })

                }
              })

            }
          })
        }
      })
    })
  }
  addUsersId(id, name, event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      let obj = {id: id, name: name};
      localStorage.setItem("usersIds", JSON.stringify([obj]));
    }
  }
  handlerUserStatus (event) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/${that.props.id}/status`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"userStatus": that.state.user.status === 'ACTIVE' ? 'NOT_ACTIVE' : 'ACTIVE'})
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            });
          })
        } else {
          that.setState(prevState => ({
            user: { ...prevState.user, status: prevState.user.status === 'NOT_ACTIVE' || !prevState.user.status ? 'ACTIVE' : 'NOT_ACTIVE' }
          }));
        }
      })
    })
  }
  hanlderUserDelete (event) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/${that.props.id}`, {
        method: 'DELETE',
        headers: myHeaders
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            });
          })
        } else {
          if(typeof window !== "undefined" && typeof document !== 'undefined'){
            localStorage.setItem("successDeposit", JSON.stringify({status: true, text: 'Успішно видалено'}))
          }
          that.setState({
            successSubmit: true
          });
        }
      })
    })
  }

  handlerBalanceValue(type, event) {
    if(type === 'default') {
      const inputValue = event.target.value;
      if (inputValue === "") {
          this.setState({
              balanceValue: "",
              bonuseValueNumError: false,
              bonuseValueCountError: false
          });
          return;
      }
      if (/^\d+(\.\d{0,2})?$/.test(inputValue)) {
          const floatValue = parseFloat(inputValue);
          if (!isNaN(floatValue)) {
              if (floatValue > 999999999.99) {
                  this.setState({
                      bonuseValueCountError: true,
                      bonuseValueNumError: false
                  });
              } else {
                  this.setState({
                      balanceValue: inputValue,
                      bonuseValueNumError: false,
                      bonuseValueCountError: false
                  });
              }
          } else {
              this.setState({
                  bonuseValueCountError: false,
                  bonuseValueNumError: true
              });
          }
      } else {
        const floatValue = parseFloat(inputValue);
        if (!isNaN(floatValue)) {
          this.setState({
              bonuseValueCountError: true
          });
        } else {
          this.setState({
              bonuseValueNumError: true
          });
        }
      }
    } else {
      const inputValue = event.target.value;
      if (inputValue === "") {
          this.setState({
              superBalanceValue: "",
              bonuseValueNumError: false,
              bonuseValueCountError: false
          });
          return;
      }
      if (/^\d+(\.\d{0,2})?$/.test(inputValue)) {
          const floatValue = parseFloat(inputValue);
          if (!isNaN(floatValue)) {
              if (floatValue > 999999999.99) {
                  this.setState({
                      bonuseValueCountError: true,
                      bonuseValueNumError: false
                  });
              } else {
                  this.setState({
                      superBalanceValue: inputValue,
                      bonuseValueNumError: false,
                      bonuseValueCountError: false
                  });
              }
          } else {
              this.setState({
                  bonuseValueCountError: false,
                  bonuseValueNumError: true
              });
          }
      } else {
        const floatValue = parseFloat(inputValue);
        if (!isNaN(floatValue)) {
          this.setState({
              bonuseValueCountError: true
          });
        } else {
          this.setState({
              bonuseValueNumError: true
          });
        }
      }
    }
  }

  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successDeposit: null
    }));
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      localStorage.removeItem('successDeposit');
    }
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errorSubmit: null
    }));
  }
  handlerSubmitEditBalance(type, event){
    event.preventDefault();
    let that = this;
    this.setState({
      loadingBalance: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/accounting/users/${that.props.id}/balance`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({"userID": that.props.id, "balance": that.state.balanceValue, "superBalance": that.state.superBalanceValue})
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              loadingBalance: false
            });
          })
        } else {
          res.json().then(function(data) {
            that.handleModalDisabled();
            let arr = that.state.balanceData;
            arr.balance = that.state.balanceValue;
            arr.superBalance = that.state.superBalanceValue;
            that.setState({
              balanceData: arr,
              loadingBalance: false
            });
          })
        }
      })
    })
  }

  handlerRemoveModalOrder(id, event){
    let that = this;
    this.setState(prevState => ({
      loadingRemoveOrderModal: true
    }));
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/orders/${id}`, {
        method: 'DELETE',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              loadingRemoveOrderModal: false
            });
          });
        } else {
          fetch(`${that.props.queryLink}/api/v1/companies/users/${that.props.id}/activities`, {
            method: 'GET',
            headers: myHeaders
          }).then(res => {
            if (res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                });
              })
            } else {
              res.json().then(function(data) {
                that.setState({
                  activitesData: data
                });
              })
            }
          })
        }
      })
    })
  }
  handerOpenSendActivity(event){
    this.setState(prevState => ({
      sendActivityTooltip: !prevState.sendActivityTooltip
    }));
  }
  handerSubmitSendActivity(event){
    let that = this;
    if(!this.state.sendSuccessActivity){
      that.setState({
        loadingVerify: true
      });
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/users/${that.state.user.userID}/invites`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "companyLogo": that.props.companyLogo,
            "companyName": that.props.companyName,
            "email": that.state.user.email,
            "userID": that.state.user.userID
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
              errors: data.errors,
              loadingVerify: false
            })
          });
        } else {
          that.setState({
            sendSuccessActivity: true,
            loadingVerify: false
          });
        }
        })
      })
    }
  }

  handlerChangDateInfo(status){
    this.setState(prevState => ({
      changeStatus: status
    }));
  }

  handlerGetStartDate(iter, day, month, year, next, prev, event){
    event.preventDefault();
    let monthValue = month + 1;
    if(next){
      monthValue = monthValue + 1;
      if(monthValue === 13) {
        monthValue = 1;
      }
    } else if(prev){
      monthValue = monthValue - 1;
      if(monthValue === 0) {
        monthValue = 12;
      }
    } else {
      monthValue = monthValue;
    }
    let nthWeekday = this.getNthWeekdayOfMonth(new Date(year, (monthValue - 1), day));
    this.setState({
      startDateCalendar: day + '.' + monthValue + '.' + year,
      iterStartCalendar: iter,
      weeklyDay: new Date(year, (monthValue - 1), day).getDay(),
      nthWeekday: nthWeekday - 1,
      dateNum: day,
      monthNum: month
    });
  }

  handlerGetStartTime(time, event){
    event.preventDefault();
    this.setState({
      startTimeCalendar: time
    });
  }

  handlerGetEndDate(iter, day, month, year, next, prev, event){
    event.preventDefault();
    let monthValue = month + 1;
    if(next){
      monthValue = monthValue + 1;
      if(monthValue === 13) {
        monthValue = 1;
      }
    } else if(prev){
      monthValue = monthValue - 1;
      if(monthValue === 0) {
        monthValue = 12;
      }
    } else {
      monthValue = monthValue;
    }
    this.setState({
      endDateCalendar: day + '.' + monthValue + '.' + year,
      iterFinishCalendar: iter
    });
  }

  handlerGetEndTime(time, event){
    event.preventDefault();
    this.setState({
      endTimeCalendar: time
    });
  }

  getNameNumberDay(weekday, num){
    const numberNameOne = [
      'перший',
      'другий',
      'третій',
      'чертвертий',
      'п\'ятий'
    ];
    const numberNameSecond = [
      'перша',
      'друга',
      'третя',
      'чертверта',
      'п\'ята'
    ];
    if(weekday == 1 || weekday == 2 || weekday == 4){
      return numberNameOne[num];
    } else {
      return numberNameSecond[num];
    }
  }

  getNthWeekdayOfMonth(date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const weekday = date.getDay();
    let nthWeekday = 0;

    for (let i = 1; i <= date.getDate(); i++) {
      const currentDate = new Date(year, month, i);
      if (currentDate.getDay() === weekday) {
        nthWeekday++;
        if (currentDate.getTime() === date.getTime()) {
          return nthWeekday;
        }
      }
    }

    return null;
  }

  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = (this.state.user.name ? this.state.user.name + ' ' : '') + '' + (this.state.user.middleName ? this.state.user.middleName + ' ' : '') + '' + (this.state.user.lastName ? this.state.user.lastName + ' ' : '') + ' | ' + this.props.companyName;
    }
    if(this.state.successDeposit){
      setTimeout(() => {
        this.handlerCloseSuccess();
      }, 5000);
    }

    let emptyContacts = areAllValuesEmpty(this.state.user.contacts);
    let emptySocial = areAllValuesEmpty(this.state.user.socialNetworks);

    let statusModal = '';
    let statusClass = '';
    if(this.state.ordersModalResult){
      if(this.state.ordersModalResult.orderStatus === 'NEW'){
        statusModal = 'Новий';
        statusClass = 'order-wgt__content-wgt-value_status_new';
      } else if(this.state.ordersModalResult.orderStatus === 'DONE') {
        statusModal = 'Доставлений';
        statusClass = 'order-wgt__content-wgt-value_status_delivered';
      } else if(this.state.ordersModalResult.orderStatus === 'IN_PROGRESS') {
        statusModal = 'Прийнятий';
        statusClass = 'order-wgt__content-wgt-value_status_accepted';
      } else {
        statusModal = 'Відхилено';
        statusClass = 'order-wgt__content-wgt-value_status_rejected';
      }
    }

    let maritalStatus;
    if(this.state.user){
      if(this.state.user.gender === 'MALE'){
        if(this.state.user.maritalStatus === 'SINGLE'){
          maritalStatus = this.props.getTranslate('employees-UserMaritalStatusManNo');
        } else {
          maritalStatus = this.props.getTranslate('employees-UserMaritalStatusManYes');
        }
      } else {
        if(this.state.user.maritalStatus === 'SINGLE'){
          maritalStatus = this.props.getTranslate('employees-UserMaritalStatusWomanNo');
        } else {
          maritalStatus = this.props.getTranslate('employees-UserMaritalStatusWomanYes');
        }
      }
    }
    let resultContent = '';
    if(this.state.error) {
      resultContent = <div className="errors-content">Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultContent = <Loading />;
    } else {
      resultContent = <div className="account-content__content account-content__content-user">
          <div className="account-wgt account-content__wgt user-statistic">
            <div className="user-statistic__info">
              <div className="user-statistic__info-left">
                <img className="user-statistic__info-avatar" src={this.state.user.photo} alt="" />
                <div>
                  <h2 className="user-statistic__info-name">{this.state.user.name} {this.state.user.middleName} {this.state.user.lastName}</h2>
                  <div className="user-statistic__info-wrap">
                    <ul className="user-statistic__info-position">
                      {this.state.user.position && <li>{this.state.user.position}</li>}
                      {this.state.user.team && <li>{this.state.user.team.name}</li>}
                    </ul>
                    {!this.state.user.completed ? <div className="user-statistic__status-label">
                    <div className="user-statistic__status-label-inner" onClick={this.handerOpenSendActivity.bind(this)}>
                      <svg className="user-statistic__status-label-icon">
                        <use href={`${sprite}#reload-icon`}></use>
                      </svg>
                      <span className="user-statistic__status-label-txt">{this.props.getTranslate('accountActive-DontActive')}</span>
                      </div>
                      {this.state.sendActivityTooltip ? <div className="user-statistic__status-label-content">
                        <div className="user-statistic__status-label-content-headline">{this.props.getTranslate('accountActive-DontActiveSendWaiting')}</div>
                        <div className="user-statistic__status-label-content-link" onClick={this.handerSubmitSendActivity.bind(this)}>{this.props.getTranslate('accountActive-DontActiveSendAgain')}
                          {this.state.sendSuccessActivity && !this.state.loadingVerify ? <svg className="user-statistic__status-complated-icon">
                            <use href={`${sprite}#success-send-complated`}></use>
                          </svg> : ''}
                          {this.state.loadingVerify ? <Loading /> : ''}
                        </div>
                      </div> : ''}
                    </div> : ''}
                  </div>
                </div>
              </div>
              <div className="user-statistic__info-right user-statistic__info-right-desktop">
                <div className="user-statistic__info-nav">
                  <Link className="user-statistic__info-nav-item btn-edit" to={this.props.getPermalink(`/account/users/edit/${this.props.id}`)} state={{from: this.props.getPermalink(`/account/users/view/${this.props.id}`)}}>
                    <svg className="btn-edit__icon">
                      <use href={`${sprite}#edit-icon`}></use>
                    </svg>
                  </Link>
                  <button className="user-statistic__info-nav-item btn-edit btn-edit_theme_remove" type="button" onClick={this.hanlderUserDelete.bind(this)}>
                    <svg className="btn-edit__icon">
                      <use href={`${sprite}#delete-red`}></use>
                    </svg>
                  </button>
                  <div className="tg-list-item">
                    <input className="tgl tgl-light" id={`user${this.props.id}`} defaultValue={`user${this.props.id}`} type="checkbox" defaultChecked={this.state.user.status === 'ACTIVE' ? true : false} onChange={this.handlerUserStatus.bind(this)} /><label className="tgl-btn" htmlFor={`user${this.props.id}`}></label>
                  </div>
                </div>
              </div>
            </div>
            <div className="user-statistic__info-wgt">
              <h3 className="user-statistic__info-wgt-headline">{this.props.getTranslate('employees-UserInformationTitle')}</h3>
              <div className="user-statistic-table">
                <div className="user-statistic-table__left">
                  <table>
                    <tbody>
                      {this.state.user.email && <tr>
                        <td>{this.props.getTranslate('employees-UserEmail')}:</td>
                        <td>{this.state.user.email}</td>
                      </tr>}
                      <tr>
                        <td>{this.props.getTranslate('employees-UserDate')}:</td>
                        <td>
                          {this.state.user.dateOfBirth ? (
                            <>
                              {this.state.user.dateOfBirth} ({getAge(this.state.user.dateOfBirth)})
                            </>
                          ) : (
                            <CalendarDropdownSingle
                              label={this.props.getTranslate('events-Date')}
                              handlerGetStartDate={this.handlerGetStartDate.bind(this)}
                              startDate={this.state.startDateCalendar}
                              iterStartCalendar={this.state.iterStartCalendar}
                              iterFinishCalendar={this.state.iterFinishCalendar}
                              handlerChangeDateForInfo={this.handlerChangDateInfo.bind(this)}
                            />
                          )}
                        </td>
                      </tr>
                      {this.state.user.maritalStatus && <tr>
                        <td>{this.props.getTranslate('employees-UserMaritalStatus')}:</td>
                        <td>{maritalStatus}</td>
                      </tr>}
                      <tr>
                        <td>{this.props.getTranslate('employees-UserWorking')}:</td>
                        <td>
                        {this.state.user.workStartDate ? (
                          <>
                            (
                            {getInfoDate(this.state.user.workStartDate)[0]} {this.props.getTranslate('employees-UserYearLabel')}
                            {getInfoDate(this.state.user.workStartDate)[1]} {this.props.getTranslate('employees-UserMonthLabel')},
                            {getInfoDate(this.state.user.workStartDate)[2]} {this.props.getTranslate('employees-UserDayLabel')}
                            )
                          </>
                        ) : (
                          <CalendarDropdownSingle
                            label={this.props.getTranslate('events-Date')}
                            handlerGetStartDate={this.handlerGetStartDate.bind(this)}
                            startDate={this.state.startDateCalendar}
                            iterStartCalendar={this.state.iterStartCalendar}
                            iterFinishCalendar={this.state.iterFinishCalendar}
                            handlerChangeDateForInfo={this.handlerChangDateInfo.bind(this)}
                          />
                        )}
                        </td>
                      </tr>
                      {this.state.user.lastLoginDate && <tr>
                        <td>{this.props.getTranslate('employees-UserLastVisit')}:</td>
                        <td>{this.state.user.lastLoginDate}</td>
                      </tr>}
                    </tbody>
                  </table>
                </div>
                <div className="user-statistic-table__right">
                    {this.state.user.hobby && this.state.user.hobby.length > 0 ? <div>
                      <div className="user-info__label">{this.props.getTranslate('employees-UserHobbie')}:</div>
                      <div className="user-info__tags">
                        {this.state.user.hobby.map((item, index) => {
                          return <span key={index}>{item}</span>})}
                      </div></div> : ''}
                      {!emptyContacts || !emptySocial ? <div className="user-statistic-table__contacts-wrap">
                        {!emptyContacts && this.state.user.contacts ? <div className="user-statistic-table__contacts">
                          <div className="user-info__label">{this.props.getTranslate('employees-UserContacts')}:</div>
                          {this.state.user.contacts.phone ? <div className="user-info-contact">
                            <img className="user-info-contact__icon" src={contactPhone} alt="" />
                            <a className="user-info-contact__val" href={`tel:${this.state.user.contacts.phone}`}>{this.state.user.contacts.phone}</a>
                          </div> : ''}
                          {this.state.user.contacts.secondaryPhone ? <div className="user-info-contact">
                            <img className="user-info-contact__icon" src={contactPhone} alt="" />
                            <a className="user-info-contact__val" href={`tel:${this.state.user.contacts.secondaryPhone}`}>{this.state.user.contacts.secondaryPhone}</a>
                          </div> : ''}
                          {this.state.user.contacts.telegram ? <div className="user-info-contact">
                            <img className="user-info-contact__icon" src={contactTelegram} alt="" />
                            <a className="user-info-contact__val" href={this.state.user.contacts.telegram} target="_blank">{this.state.user.contacts.telegram}</a>
                          </div> : ''}
                          {this.state.user.contacts.email ? <div className="user-info-contact">
                            <img className="user-info-contact__icon" src={contactEmail} alt="" />
                            <a className="user-info-contact__val" href={`mailto:${this.state.user.contacts.email}`}>{this.state.user.contacts.email}</a>
                          </div> : ''}
                        </div> : ''}
                        {!emptySocial ? <div className="user-statistic-table__networks">
                          <div className="user-info__label">{this.props.getTranslate('employees-UserNetworks')}:</div>
                          {this.state.user.socialNetworks.faceBook ? <div className="user-info-contact">
                            <img className="user-info-contact__icon" src={contactFacebook} alt="" />
                            <a className="user-info-contact__val" href={this.state.user.socialNetworks.faceBook} target="_blank">{this.state.user.socialNetworks.faceBook}</a>
                          </div> : ''}
                          {this.state.user.socialNetworks.instagram ? <div className="user-info-contact">
                            <img className="user-info-contact__icon" src={contactInstagram} alt="" />
                            <a className="user-info-contact__val" href={this.state.user.socialNetworks.instagram} target="_blank">{this.state.user.socialNetworks.instagram}</a>
                          </div> : ''}
                          {this.state.user.socialNetworks.linkedin ? <div className="user-info-contact">
                            <img className="user-info-contact__icon" src={contactLinkedin} alt="" />
                            <a className="user-info-contact__val" href={this.state.user.socialNetworks.linkedin} target="_blank">{this.state.user.socialNetworks.linkedin}</a>
                          </div> : ''}
                          {this.state.user.socialNetworks.twitter ? <div className="user-info-contact">
                            <img className="user-info-contact__icon" src={contactTwitter} alt="" />
                            <a className="user-info-contact__val" href={this.state.user.socialNetworks.twitter} target="_blank">{this.state.user.socialNetworks.twitter}</a>
                          </div> : ''}
                        </div> : ''}
                      </div> : ''}
                  </div>
              </div>
            </div>

            {this.state.rewards && this.state.rewards.length > 0 ? <div className="user-statistic__info-wgt">
              <div className="user-statistic__info-wgt-header">
                <h3 className="user-statistic__info-wgt-headline">Нагороди</h3>
                <Link className="user-statistic__info-wgt-link" to={this.props.getPermalink(`/account/rewards/create`)}>{this.props.getTranslate('employees-UserAddRewardBtn')}</Link>
              </div>
               <div className="user-statistic__info-rewards">
                {this.state.rewards.map((item, index) => {
                  let rewardName = item.rewardName;
                  let slicedRewardName = rewardName.slice(0, 20);
                  if (slicedRewardName.length < rewardName.length) {
                    slicedRewardName += '...';
                  }
                  return <div className="user-statistic-rewards" key={index}>
                    <Link to={this.props.getPermalink(`/account/rewards/view/${item.rewardID}`)}>
                      <div className="user-statistic-rewards__inner">
                        <img className="user-statistic-rewards__icon" src={item.rewardPhoto} alt="" />
                        <div className="user-statistic-rewards__headline">{slicedRewardName}</div>
                      </div>
                    </Link>
                  </div>
                })}
              </div>
            </div> : ''}

            <div className="user-statistic__info-wgt">
              <div className="user-statistic__info-wgt-header">
                <h3 className="user-statistic__info-wgt-headline">{this.props.getTranslate('employees-UserBalanceTitle')}</h3>
                <Link className="user-statistic__info-wgt-link" to={this.props.getPermalink(`/account/deposits/${this.state.user.userID}`)}>{this.props.getTranslate('employees-UserHistoryIncome')}</Link>
              </div>
              <div className="user-statistic-balance">
                <div className="user-statistic-balance__left">
                  <div className="user-statistic-balance__label">{this.props.getTranslate('employees-UserBalancelabel')}</div>
                  <div className="user-statistic-balance__wrap">
                    <div className="user-statistic-balance__inner">
                      <div className="user-statistic-balance__value">{this.props.number_format(this.state.balanceData.balance, 0, '', ' ')}{this.props.currency ? ' ' + this.props.currency : ''}</div>
                      <button className="user-statistic__info-nav-item btn-edit btn-edit__transparent" onClick={this.handlerEditBalance.bind(this, 'default')} type="button">
                        <svg className="btn-edit__icon">
                          <use href={`${sprite}#edit-icon`}></use>
                        </svg>
                      </button>
                    </div>
                    <div className="user-statistic-balance__inner">
                      <div className="user-statistic-balance__value">{this.props.number_format(this.state.balanceData.superBalance, 0, '', ' ')} Super Coin</div>
                      <button className="user-statistic__info-nav-item btn-edit btn-edit__transparent" onClick={this.handlerEditBalance.bind(this, 'super')} type="button">
                        <svg className="btn-edit__icon">
                          <use href={`${sprite}#edit-icon`}></use>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="user-statistic-balance__right">
                  <Link className="btn btn-header btn_size_lg" to={this.props.getPermalink("/account/deposits/create/")} onClick={this.addUsersId.bind(this, this.props.id, this.state.user.name + ' ' + this.state.user.lastName)}>
                    <svg className="btn-header__icon-mob">
                      <use href={`${sprite}#add-bonuses-icon-white`}></use>
                    </svg>
                    <span className="btn-header__inner">{this.props.getTranslate('employees-UserBalanceButton')}</span>
                 </Link>
                </div>
              </div>
            </div>
            </div>
            {this.state.activitesData && Object.values(this.state.activitesData).some(array => Array.isArray(array) && array.length > 0) ?
              <div className="user-activites">
                <div className="user-activites__headline">{this.props.getTranslate('employees-UserActivitiesTitle')}</div>
                {Object.keys(this.state.activitesData).map((activity, activityIndex) => {
                  let activityName = '';
                  switch(activity) {
                    case 'ads':
                      activityName = this.props.getTranslate('employees-UserMarketplaceTitle')
                      break;
                    case 'orders':
                      activityName = this.props.getTranslate('employees-UserOrdersTitle')
                      break;
                    case 'surveys':
                      activityName = this.props.getTranslate('employees-UserPollsTitle')
                      break;
                    case 'events':
                      activityName = this.props.getTranslate('employees-UserEventsTitle')
                      break;
                    case 'loyalties':
                      activityName = this.props.getTranslate('employees-UserBonusesTitle')
                      break;
                    case 'bookings':
                      activityName = this.props.getTranslate('employees-UserLibraryTitle')
                      break;
                    default:
                      activityName = activity
                      break;
                  }
                   return this.state.activitesData && this.state.activitesData[activity] && this.state.activitesData[activity].length > 0 ? <div className="user-statistic__info-wgt user-statistic__info-wgt-activites" key={activityIndex}>
                   <div className="user-statistic__info-wgt-wrap">
                    <div className="user-statistic__info-wgt-header">
                      <h3 className="user-statistic__info-wgt-headline">{activityName}</h3>
                    </div>
                    {this.state.activitesData[activity].map((item, index) => {
                      let id = null;
                      let handler = '';
                      let content = '';
                      let name = '';
                      switch (activity) {
                        case 'loyalties':
                          id = item.userLoyaltyRO;
                          handler = 'loyalties';
                          name = item.name;
                          content = <div className="user-statistic-wgt__content"><DateFormating start={item.startDate} end={item.endDate} /><span className="user-statistic-wgt__content-day">{getNumberOfDays(item.startDate, item.endDate)} {this.props.getTranslate('employees-countDays')}</span></div>
                          break;
                        case 'surveys':
                          id = item.surveyID;
                          handler = 'survey';
                          name = item.title;
                          content = <div className="user-statistic-wgt__content"><DateFormating start={item.endTime} /></div>
                          break;
                        case 'ads':
                          id = item.adID;
                          handler = 'marketplace';
                          name = item.title;
                          content = <div className="user-statistic-wgt__content"></div>
                          break;
                        case 'events':
                          id = item.eventID;
                          handler = 'event';
                          name = item.title;
                          content = <div className="user-statistic-wgt__content"><DateFormating start={item.startDate} /></div>
                          break;
                        case 'bookings':
                            id = item.bookID;
                            handler = 'library';
                            name = item.bookName;
                            content = <div className="user-statistic-wgt__content"><DateFormating start={item.bookingDate} /></div>
                            break;
                        default:
                          let status = item.status;
                          let labelStatus = '';
                          let labelColor = '';
                          if(status === 'NEW'){
                            labelStatus = this.props.getTranslate('orders-StatusNew');
                            labelColor = 'blue';
                          } else if(status === 'DONE'){
                            labelStatus = this.props.getTranslate('orders-StatusDelivered');
                            labelColor = 'green';
                          } else if(status === 'IN_PROGRESS') {
                            labelStatus = this.props.getTranslate('orders-StatusAccepted');
                            labelColor = 'yellow';
                          } else {
                            labelStatus = this.props.getTranslate('orders-StatusDeclined');
                            labelColor = 'red';
                          }
                          id = item.orderID;
                          handler = 'order';
                          name = item.productName;
                          content = <div className="user-statistic-wgt__content user-statistic-wgt__content-flex">
                            <div className="user-statistic-wgt__content-order-date">
                              <DateFormating start={item.orderDate} time="off" />
                            </div>
                            <div className={`status-label-txt status-label-txt_theme-${labelColor}`}>{labelStatus}</div>
                          </div>
                          break;
                      }
                      return <div className="user-statistic-wgt" key={index} onClick={this.handlerGetModalItem.bind(this, handler, id)}>
                              <div className="user-statistic-wgt__name">{name}</div>
                                {content}
                              </div>
                    })}</div>
                  </div> : ''
                })}
              </div> : ''}
        </div>;
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {this.state.successDeposit && <ResultQuery success={this.state.successDeposit.status} resultText={this.state.successDeposit.text} handlerSuccess={this.handlerCloseSuccess} handlerError={this.handlerCloseError} />}
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successSubmit && <Navigate to={this.props.getPermalink("/account/users")} replace={true} />) : ''}
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.loyaltiesNavigate && <Navigate to={this.props.getPermalink(`/account/benefits/view/${this.state.loyaltiesNavigate}`)} replace={true} />) : ''}
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap">
      <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
      <div className="account-content">
      <div className="account-content__inner">
      <div className="account-content__header-user">

        {/* Account header component */}
        <AccountContentHeader
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
          backBtn={true}
          backBtnType="link"
          backBtnClass="button-back_theme_gray"
          backBtnHandler={this.props.getPermalink("/account/users")}
          backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
        />

          <div className="user-statistic__info-right-mob">
            <div className="user-statistic__info-nav">
              <Link className="user-statistic__info-nav-item btn-edit" to={this.props.getPermalink(`/account/users/edit/${this.props.id}`)} state={{from: this.props.getPermalink(`/account/users/view/${this.props.id}`)}}>
                <svg className="btn-edit__icon">
                  <use href={`${sprite}#edit-icon`}></use>
                </svg>
              </Link>
              <button className="user-statistic__info-nav-item btn-edit btn-edit_theme_remove" type="button" onClick={this.hanlderUserDelete.bind(this)}>
                <svg className="btn-edit__icon">
                  <use href={`${sprite}#delete-red`}></use>
                </svg>
              </button>
              <div className="tg-list-item">
                <input className="tgl tgl-light" id={`user-mob${this.props.id}`} defaultValue={`user${this.props.id}`} type="checkbox" checked={this.state.user.status === 'ACTIVE' ? true : false} onChange={this.handlerUserStatus.bind(this)} /><label className="tgl-btn" htmlFor={`user-mob${this.props.id}`}></label>
              </div>
            </div>
          </div>

        </div>

        {resultContent}
      </div>
      </div>
    </div>
    {this.state.modal && this.state.modalEditBalance ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.props.getTranslate('editBalance-Title')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <form className="add-user-form" onSubmit={this.handlerSubmitEditBalance.bind(this, 'default')}>
          <div className="field-wrap">
            <label className="field-wrap__label" htmlFor="amount">{this.props.getTranslate('employees-AddBonusCountTitle')}</label>
            <div className="field-wrap__currency-name">
              <input id="amount" className={`field-wrap__input ${this.state.bonuseValueNumError || this.state.bonuseValueCountError ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.balanceValue} onChange={this.handlerBalanceValue.bind(this, 'default')} placeholder={this.props.getTranslate('employees-AddBonusCountFieldCaption')} />
              <label className="field-wrap__currency-name-item" for="amount">{this.props.currency ? ' ' + this.props.currency : ''}</label>
            </div>
            {this.state.bonuseValueNumError && !this.state.bonuseValueCountError ? <div className="validation-error">В полі повинні бути тільки цифри</div> : ''}
            {!this.state.bonuseValueNumError && this.state.bonuseValueCountError ? <div className="validation-error">Не повинно перевищувати 999 999 999.99</div> : ''}
          </div>
            <div className="field-wrap__submit">
              <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="submit" disabled={this.state.loadingBalance ? true : false}>{this.props.getTranslate('employees-AddBonusSendButton')}{this.state.loadingBalance && <Loading />}</button>
            </div>
        </form>
        </div>
    </div> : ''}

    {this.state.modal && this.state.modalEditSuperBalance ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.props.getTranslate('editBalance-Title')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <form className="add-user-form" onSubmit={this.handlerSubmitEditBalance.bind(this, 'super')}>
          <div className="field-wrap">
            <label className="field-wrap__label" htmlFor="amount2">{this.props.getTranslate('employees-AddBonusCountTitle')}</label>
            <div className="field-wrap__currency-name">
              <input id="amount2" className={`field-wrap__input ${this.state.bonuseValueNumError || this.state.bonuseValueCountError ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.superBalanceValue} onChange={this.handlerBalanceValue.bind(this, 'super')} placeholder={this.props.getTranslate('employees-AddBonusCountFieldCaption')} />
              <label className="field-wrap__currency-name-item" for="amount2">{this.props.currency ? ' ' + this.props.currency : ''}</label>
            </div>
            {this.state.bonuseValueNumError && !this.state.bonuseValueCountError ? <div className="validation-error">В полі повинні бути тільки цифри</div> : ''}
            {!this.state.bonuseValueNumError && this.state.bonuseValueCountError ? <div className="validation-error">Не повинно перевищувати 999 999 999.99</div> : ''}
          </div>
            <div className="field-wrap__submit">
              <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="submit" disabled={this.state.loadingBalance ? true : false}>{this.props.getTranslate('employees-AddBonusSendButton')}{this.state.loadingBalance && <Loading />}</button>
            </div>
        </form>
        </div>
    </div> : ''}

    {this.state.modalAd ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.state.dataAdModal.title}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
        <div className="modal-ad-content">
          <div className="modal-ad-content__inner">
            <div className="modal-ad-content__info">
              <div className="modal-library-content__headline">{this.props.getTranslate('marketplaceModal-CreateDateTitle')}:</div>
              <div className="modal-library-content__value"><DateFormating start={this.state.dataAdModal.creationDate} /></div>
              <div className="modal-library-content__headline">{this.props.getTranslate('marketplaceModal-AuthorTitle')}:</div>
              <div className="modal-library-content__value">{this.state.dataAdModal.author}</div>
              <div className="modal-library-content__headline">{this.props.getTranslate('marketplaceModal-PriceTitle')}:</div>
              <div className="modal-library-content__value">{this.state.dataAdModal.price} {this.props.currency}</div>
            </div>
            <div className="modal-ad-content__img">
              {this.state.dataAdModal && this.state.dataAdModal.photo ? <img src={this.state.dataAdModal.photo} /> : ''}
            </div>
          </div>
            <div className="modal-library-content__headline">{this.props.getTranslate('marketplaceModal-DescriptionTitle')}:</div>
            <Scrollbars
                renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                renderThumbVertical={props => <div {...props} className="thumb-vertical thumb-vertical-scroll"/>}
                autoHeight
                autoHeightMin={0}
                autoHeightMax={150}
                  renderView={props => <div {...props} />}>
            <div className="modal-library-content__value" dangerouslySetInnerHTML={{__html: this.state.dataAdModal.description}} style={{paddingRight: "10px"}}></div>
            </Scrollbars>
        </div>
        <div className="product-info__nav">
          <div className="product-info__nav-col field-wrap__submit">
            <Link className="btn btn_theme_secondary btn_size_lg btn_width_100 product-info__nav-btn" to={this.props.getPermalink(`/account/marketplace/edit/${this.state.dataAdModal.adID}`)}>{this.props.getTranslate('marketplaceModal-SubmitButton')}</Link>
          </div>
          <div className="product-info__nav-col field-wrap__submit">
            <button className="btn btn_theme_remove btn_size_lg btn_width_100 product-info__nav-btn" type="button">{this.props.getTranslate('marketplaceModal-RemoveButton')}</button>
          </div>
        </div>
        </div>
    </div> : ''}



    {this.state.modalSurvey ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.state.dataSurveyModal.title}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
        {this.state.modalSurvey.surveyType === 'NORMAL' ? <div className="modal-event-content">
          <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-DateTitle')}:</div>
          <div className="modal-event-content__value">{this.state.dataSurveyModal.startTime.split(' ')[0].replace(/-/gi, '.') + ', ' + this.state.dataSurveyModal.startTime.split(' ')[1].substring(0,this.state.dataSurveyModal.startTime.split(' ')[1].lastIndexOf(':'))} - {this.state.dataSurveyModal.endTime.split(' ')[0].replace(/-/gi, '.') + ', ' + this.state.dataSurveyModal.endTime.split(' ')[1].substring(0,this.state.dataSurveyModal.endTime.split(' ')[1].lastIndexOf(':'))}</div>
          <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-UsersCountTitle')}:</div>
          <div className="modal-event-content__value">{this.state.dataSurveyModal.users.length}</div>
          <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-CompletedUsersTitle')}:</div>
          <div className="modal-event-content__value"></div>
          <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-RepeatTitle')}:</div>
          <div className="modal-event-content__value">{this.state.dataSurveyModal.anonymous ? this.props.getTranslate('surveyModal-TypeAnonymousValue') : this.props.getTranslate('surveyModal-TypeAnonymousValue')}</div>
          <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-QuestionsCountTitle')}:</div>
          <div className="modal-event-content__value">{this.state.dataSurveyModal.questions ? this.state.dataSurveyModal.questions.length : 0}</div>
          <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-BonuseTitle')}:</div>
          <div className="modal-event-content__value">{this.state.dataSurveyModal.bonus}</div>
          <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-StatusTitle')}:</div>
          <div className="modal-event-content__value">{this.state.statusSurvey}</div>
        </div> :         <div className="modal-event-content">
                  <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-DateTitle')}:</div>
                  <div className="modal-event-content__value">{this.state.dataSurveyModal.startTime.split(' ')[0].replace(/-/gi, '.') + ', ' + this.state.dataSurveyModal.startTime.split(' ')[1].substring(0,this.state.dataSurveyModal.startTime.split(' ')[1].lastIndexOf(':'))} - {this.state.dataSurveyModal.endTime.split(' ')[0].replace(/-/gi, '.') + ', ' + this.state.dataSurveyModal.endTime.split(' ')[1].substring(0,this.state.dataSurveyModal.endTime.split(' ')[1].lastIndexOf(':'))}</div>
                  <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-UsersCountTitle')}:</div>
                  <div className="modal-event-content__value">{this.state.dataSurveyModal.users.length}</div>
                  <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-RepeatTitle')}:</div>
                  <div className="modal-event-content__value"></div>
                  <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-RepeatTitle')}:</div>
                  <div className="modal-event-content__value">{this.state.dataSurveyModal.anonymous ? this.props.getTranslate('surveyModal-TypeAnonymousValue') : this.props.getTranslate('surveyModal-TypeAnonymousValue')}</div>
                  <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-BonuseTitle')}:</div>
                  <div className="modal-event-content__value">{this.state.dataSurveyModal.bonus}</div>
                  <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-StatusTitle')}:</div>
                  <div className="modal-event-content__value">{this.state.statusSurvey}</div>
                </div>}
        <Link className="questions-table__result questions-table__result_lg" to={this.props.getPermalink(`/account/surveys/result/${this.state.dataSurveyModal.surveyID}`)}>{this.props.getTranslate('surveyModal-ResultButton')}</Link>
        <div className="product-info__nav">
          <div className="product-info__nav-col field-wrap__submit">
            <Link className="btn btn_theme_secondary btn_size_lg btn_width_100 product-info__nav-btn" to={this.props.getPermalink(`/account/surveys/edit/default/${this.state.dataSurveyModal.surveyID}`)}>{this.props.getTranslate('surveyModal-SubmitButton')}</Link>
          </div>
          <div className="product-info__nav-col">
            <button className="btn btn_theme_remove btn_size_lg btn_width_100 product-info__nav-btn" type="button">{this.props.getTranslate('surveyModal-RemoveButton')}</button>
          </div>
        </div>
        </div>
    </div> : ''}

    {this.state.modalEvent ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.state.dataEventModal.title}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
        <div className="modal-event-content">
          {this.state.dataEventModal && this.state.dataEventModal.photos ? <div className="modal-event-content__img">
            <img src={this.state.dataEventModal.photos[0].url} alt="" />
          </div> : ''}
          <div className="modal-event-content__headline">{this.props.getTranslate('eventModal-DateTitle')}:</div>
          <div className="modal-event-content__value"><DateFormating start={this.state.dataEventModal.startTime} end={this.state.dataEventModal.endTime} /></div>
          <div className="modal-event-content__headline">{this.props.getTranslate('eventModal-OrganizerTitle')}:</div>
          <div className="modal-event-content__value">{this.state.dataEventModal.author}</div>
          <div className="modal-event-content__headline">{this.props.getTranslate('eventModal-PlaceTitle')}:</div>
          <div className="modal-event-content__value">{this.state.dataEventModal.place}</div>
          <div className="modal-event-content__headline">{this.props.getTranslate('eventModal-PeopleCountTitle')}:</div>
          <div className="modal-event-content__value">{this.state.dataEventModal.invitations ? this.state.dataEventModal.invitations.length : 0}</div>
          <div className="modal-event-content__headline">{this.props.getTranslate('eventModal-CommentsCountTitle')}:</div>
          <div className="modal-event-content__value">{this.state.dataEventModal.commentCount}</div>
        </div>
        <div className="product-info__nav">
          <div className="product-info__nav-col field-wrap__submit">
            <Link className="btn btn_theme_secondary btn_size_lg btn_width_100 product-info__nav-btn" to={this.props.getPermalink(`/account/events/edit/${this.state.dataEventModal.eventID}`)}>{this.props.getTranslate('eventModal-SubmitButton')}</Link>
          </div>
          <div className="product-info__nav-col">
            <button className="btn btn_theme_remove btn_size_lg btn_width_100 product-info__nav-btn" type="button">{this.props.getTranslate('eventModal-RemoveButton')}</button>
          </div>
        </div>
        </div>
    </div> : ''}


    {/* Modal Order Info */}
    {this.state.modalOrder ? <InfoModal
      orderModal={true}
      orderNavDropDownStatus={<OrderModalDropdownStatusChange
        title={this.props.getTranslate('orders-StatusTitle')}
        id={this.state.ordersModalResult.orderID}
        activeStatus={this.state.ordersModalResult.orderStatus}
        statusItems={{
        'NEW': this.props.getTranslate('orders-StatusNew'),
        'IN_PROGRESS': this.props.getTranslate('orders-StatusAccepted'),
        'DONE': this.props.getTranslate('orders-StatusDelivered'),
        'DECLINED': this.props.getTranslate('ordersFilter-StatusDeclined')
      }}
      restoreFromSubmit={this.state.successSubmitChangeStatus}
      handler={this.handlerChangeStatus.bind(this)} />}
      orderAddComment={<OrderModalComment
        title={this.props.getTranslate('orders-CommentTitle')}
        id={this.state.ordersModalResult.orderID}
        userName={this.state.ordersModalResult.userName}
        checkboxLabel={this.props.getTranslate('orders-CommentCheckboxLabel')}
        buttonSubmitText={this.props.getTranslate('orders-CommentButtonSubmitText')}
        loader={this.state.loadingSubmitNote}
        handler={this.handlerSubmitNote.bind(this)}
        restoreFromSubmit={this.state.successSubmitNoteStatus}
      />}
      itemID={this.state.ordersModalResult.orderID}
      title={`${this.props.getTranslate('orders-modalTitle')} №${this.state.ordersModalResult.orderNumber}`}
      infoTitle={this.props.getTranslate('orders-modalInfoTitle')}
      infoUnTitle={this.state.ordersModalResult.productName}
      infoUnImg={this.state.ordersModalResult.productPhoto ? this.state.ordersModalResult.productPhoto : product_img_default}
      listTitle={this.props.getTranslate('orders-modalHistoryTitle')}
      listAmount={this.state.orderHistories && this.state.orderHistories.length > 0 ? this.state.orderHistories.length : '0'}
      dataContent={[this.state.ordersModalResult.shopName || this.state.ordersModalResult.shopName ?
        [{
          title: this.props.getTranslate('orders-modalLabelNameShop'),
          value: this.state.ordersModalResult.shopName,
        },
        {
          title: this.props.getTranslate('orders-modalLabellinkShop'),
          value: this.state.ordersModalResult.productLink,
          type: 'link'
        }] : '',
        [{
          title: this.props.getTranslate('orders-modalLabelPriceShop'),
          value: this.state.ordersModalResult.totalPrice ? this.state.ordersModalResult.totalPrice + ' ' + (this.props.currency && this.state.ordersModalResult.totalPrice ? this.props.currency : '') : (this.state.ordersModalResult.orderType !== 'COMPANY_SHOP' && !this.state.ordersModalResult.quantity ? <SetValueFromModal
            buttonText={this.props.getTranslate('orders-modalDropdownSetPriceButton')}
            label={`${this.props.getTranslate('orders-modalDropdownSetPriceLabel')} ${this.props.currency}`}
            labelInto={this.props.currency}
            placeholder={this.props.getTranslate('orders-modalDropdownSetPricePlaceholder')}
            name="price"
            submitButtonText={this.props.getTranslate('orders-modalDropdownSetPriceButtonSubmit')}
            handler={this.handlerSubmitSetPrice.bind(this)}
          /> : '')
        },
        {
          title: this.props.getTranslate('orders-modalLabelAmountShop'),
          value: this.state.ordersModalResult.quantity ? this.state.ordersModalResult.quantity : (this.state.ordersModalResult.orderType !== 'COMPANY_SHOP' && !this.state.ordersModalResult.quantity ? <SetValueFromModal
            buttonText={this.props.getTranslate('orders-modalDropdownSetAmountButton')}
            label={this.props.getTranslate('orders-modalDropdownSetAmountLabel')}
            placeholder={this.props.getTranslate('orders-modalDropdownSetAmountPlaceholder')}
            name="amount"
            submitButtonText={this.props.getTranslate('orders-modalDropdownSetAmountButtonSubmit')}
            handler={this.handlerSubmitSetQuantity.bind(this)}
          /> : '')
        }],
        [{
          title: this.props.getTranslate('orders-modalLabelBuyerShop'),
          value: this.state.ordersModalResult.userName
        },
        {
          title: this.props.getTranslate('orders-modalLabelDateShop'),
          value: <DateFormating start={this.state.ordersModalResult.createdDate} />
        },
        {
          title: this.props.getTranslate('orders-modalLabelStatusShop'),
          value: this.state.ordersModalResult.orderStatus
        }]
      ]}
      noteTitle={this.props.getTranslate('orders-modalLabelCommentShop')}
      noteValue={this.state.ordersModalResult.notes}
      orderData={{
        orderType: this.state.ordersModalResult.orderType,
        orderProductTitle: this.state.ordersModalResult.productName,
        orderProductPhoto: this.state.ordersModalResult.productPhoto ? this.state.ordersModalResult.productPhoto : product_img_default
      }}
      listData={this.state.orderHistories}
      orderListStatusTranslation={{
        'NEW': this.props.getTranslate('orders-modalHistoryStatusNew'),
        'DONE': this.props.getTranslate('orders-modalHistoryStatusDone'),
        'IN_PROGRESS': this.props.getTranslate('orders-modalHistoryStatusAccepted'),
        'DECLINED': this.props.getTranslate('orders-modalHistoryStatusRejected')
      }}
      translationValueItems={{
          'NEW': this.props.getTranslate('orders-StatusNew'),
          'DONE': this.props.getTranslate('orders-StatusDelivered'),
          'IN_PROGRESS': this.props.getTranslate('orders-StatusAccepted'),
          'DECLINED': this.props.getTranslate('ordersFilter-StatusDeclined')
        }}
        addCommentTitle={this.props.getTranslate('orders-StatusComment')}
      navigation={true}
      navigationEditPath={this.props.getPermalink(`/account/orders/edit/${this.state.ordersModalResult.orderID}`)}
      labelsStatus={{'NEW': 'blue', 'DONE': 'green', 'IN_PROGRESS': 'yellow', 'DECLINED': 'red'}}
      handlerClose={this.handleModalDisabled.bind(this)}
    /> : ''}

    </>;
  }
}
export default (props) => <UsersView {...useParams()} {...props} />
