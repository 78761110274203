import React from "react";
import Button from '../Button';
import dash_board_include_company from '../../media/dash-board-include-company.png';
class AboutIncludeCompany extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <section className={`about-include-company${this.props.reverse ? ' reverse' : ''}`}>
      <div className="wrapper">
        <div className="about-include-company__inner">
          <div className={`about-include-company__col about-include-company__col-thumb`}>
            <img className={`about-include-company__thumbnail${this.props.imgScale ? ' about-include-company__thumbnail_absolute' : ''}`} src={this.props.img ? this.props.img : dash_board_include_company} alt={this.props.headline} />
          </div>
          <div className={`about-include-company__col about-include-company__col-content${this.props.imgScale ? ' about-include-company__col-content_absolute' : ''}`}>
            <div className="about-include-company__content">
              <h2 className="about-include-company__headline" dangerouslySetInnerHTML={{__html: this.props.headline}}></h2>
              <div className="about-include-company__description">{this.props.description}</div>
              <div className="about-include-company__navs">
                {this.props.navs.map((item, index) => {
                  return <div key={index} className="about-include-company__navs-col">{item}</div>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>;
  }
}
export default AboutIncludeCompany;
