import React from "react";
import DateFormating from './components/DateFormating';
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import ResultQuery from './components/ResultQuery';
import Loading from './components/Loading';
import OrderModalDropdownStatusChange from './components/OrderModalDropdownStatusChange';
import OrderModalComment from './components/OrderModalComment';
import Table from './components/Table';
import Pagination from './components/Pagination';
import ResultErrors from './components/ResultErrors';
import InfoModal from './components/InfoModal';
import SetValueFromModal from './components/SetValueFromModal';
import AccountContentHeader from './components/AccountContentHeader';
import AccountContentEmpty from './components/AccountContentEmpty';
import Filter from './components/Filter';
import FilterSearch from './components/FilterSearch';
import sprite from './media/icons.svg';
import product_img from './media/product.png';
import search_empty from './media/search.png';
import product_img_default from './media/no-product-image.jpg';
import { getAuth } from "firebase/auth";
class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      loadingRemove: false,
      mobileMenu: false,
      dropdownStatus: false,
      ordersModalResult: [],
      isLoadedOrder: false,
      isLoadedOrders: false,
      ordersStatusLoading: false,
      orders: [],
      totalPages: null,
      currentPage: 0,
      modalDisabled: false,
      searchValue: '',
      searchEmpty: false,
      currencyCompany: (typeof window !== "undefined" && typeof document !== 'undefined') ? localStorage.getItem('currencyMt') : '',
      errors: null,
      error: false,
      modalOrder: false,
      sendMessage: false,
      indexModalOrder: null,
      noteValue: '',
      idDeleteOrder: null,
      loadingChangeStatus: false,
      typeChangeStatus: null,
      loadingSubmitNote: false,
      loadingRemoveOrderModal: false,
      heightInfo: 'auto',
      orderHistories: [],
      controlRow: null,
      openSetPrice: false,
      openSetQuantity: false,
      priceValueModal: '',
      quantityValueModal: '',
      heightThumb: null,
      successSubmit: false,
      successText: null,
      successSubmitChangeStatus: false,
      successSubmitNoteStatus: false,
      statusModalAccept: false,
      paginationParams: {},
      currentFilterName: '',
      currentFilterVal: ''
    }
  }

  /* Handler for get mobile menu */
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };

  /* Handler for dropdown */
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }

  /* Handler get data mount component */
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      fetch(`${that.props.queryLink}/api/v1/orders/search`, {
        method: 'POST',
        headers: that.props.queryHeaders(idToken),
        body: JSON.stringify({"itemsPerPage": 20, "orderField": "modificationDate"})
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoadedOrders: true,
              errors: data.errors,
              error: true
            });
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              isLoadedOrders: true,
              totalPages: data.totalPages,
              orders: data.data
            });
          })
        }
      })
    })
  }

  /* Handler get order filter data */
  handlerGetOrdersStatus(status, name, event){
    let that = this;
    that.setState({
      isLoadedOrders: false,
      currentFilterName: name,
      currentFilterVal: status
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      fetch(`${that.props.queryLink}/api/v1/orders/search`, {
        method: 'POST',
        headers: that.props.queryHeaders(idToken),
        body: JSON.stringify({"itemsPerPage": 20, "orderField": "creationDate", "orderStatus": status})
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoadedOrders: true,
              errors: data.errors
            });
          });
        } else {
          let paginationParams = that.state.paginationParams;
          paginationParams.orderStatus = status;
          res.json().then(function(data) {
            that.setState({
              isLoadedOrders: true,
              totalPages: data.totalPages,
              orders: data.data,
              paginationParams: paginationParams
            });
          })
        }
      })
    })
  }

  /* Handler get order search data */
  handlerSearch(event){
    let that = this;
    this.setState({
      orders: [],
      isLoadedOrders: false,
      searchValue: event.target.value,
      searchEmpty: false
    });
    setTimeout(() => {
      getAuth().currentUser.getIdToken().then(function(idToken){
        fetch(`${that.props.queryLink}/api/v1/orders/search`, {
          method: 'POST',
          headers: that.props.queryHeaders(idToken),
          body: JSON.stringify({"itemsPerPage": 20, "orderField": "creationDate", "query": that.state.searchValue})
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoadedOrders: true,
                errors: data.errors
              });
            });
          } else {
            res.json().then(function(data) {
              let paginationParams = that.state.paginationParams;
              paginationParams.query = that.state.searchValue;
              that.setState({
                isLoadedOrders: true,
                totalPages: data.totalPages,
                orders: data.data,
                paginationParams: paginationParams
              });
              if(data.data.length < 1){
                that.setState({
                  searchEmpty: true
                });
              }
            })
          }
        })
      })
    }, 1000);
  }

  /* Handler get modal order data */
  handleModalOrder(id, index, event){
    let that = this;
    if(!event.target.closest('.control-wgt')){
      getAuth().currentUser.getIdToken().then(function(idToken){
        fetch(`${that.props.queryLink}/api/v1/orders/${id}`, {
          method: 'GET',
          headers: that.props.queryHeaders(idToken),
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoadedOrder: true,
                errors: data.errors
              });
            });
          } else {
            res.json().then(function(data) {
              that.setState(prevState => ({
                ordersModalResult: data,
                isLoadedOrder: true,
                modalOrder: !prevState.modalOrder,
                indexModalOrder: index
              }));
              let infoDiv = (typeof window !== "undefined" && typeof document !== 'undefined') ? document.querySelector('.modal__order-content-col-info') : '';
              let height = infoDiv.clientHeight;
              that.setState(prevState => ({
                orderHistories: data.histories,
                heightInfo: height,
              }));
              if(data.histories){
                setTimeout(() => {
                  let thumb = (typeof window !== "undefined" && typeof document !== 'undefined') ? document.querySelector('.thumb-vertical') : '';
                  let thumbHeight = thumb.clientHeight;
                  that.setState(prevState => ({
                    heightThumb: thumbHeight
                  }));
                }, 100)
              }
            })
          }
        })
      })
    }
  }

handlerDeclineStatus(event){
  let that = this;
  getAuth().currentUser.getIdToken().then(function(idToken){
    fetch(`${that.props.queryLink}/api/v1/orders/${that.state.ordersModalResult.orderID}/status`, {
      method: 'PUT',
      headers: that.props.queryHeaders(idToken),
      body: JSON.stringify({"status": 'DECLINED'})
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            loadingChangeStatus: false,
            typeChangeStatus: null
          });
        });
        setTimeout(() => {
          that.setState({
            errorSubmit: false
          });
        }, 5000);
      } else {
        res.json().then(function(data) {
          let orders = that.state.orders;
          orders[that.state.indexModalOrder] = data;
          let orderModal = data;
          that.setState({
            orders: orders,
            ordersModalResult: orderModal,
            orderHistories: data.histories,
            loadingChangeStatus: false,
            typeChangeStatus: null,
            successSubmitChangeStatus: true,
            successSubmit: true,
            successText: 'Успішно змінено',
            statusModalAccept: false
          });
        })
        setTimeout(() => {
          that.setState({
            successSubmitChangeStatus: false
          });
        }, 10);
        setTimeout(() => {
          that.setState({
            successSubmit: false,
            successText: null,
          });
        }, 5000);
      }
    })
  })
}

  /* Handler change order status and restore data */
  handlerChangeStatus(id, status, event){
    if(status === 'DECLINED'){
      this.setState(prevState => ({
        statusModalAccept: true
      }));
    } else {
      let that = this;
      this.setState(prevState => ({
        loadingChangeStatus: true,
        typeChangeStatus: status
      }));
      getAuth().currentUser.getIdToken().then(function(idToken){
        fetch(`${that.props.queryLink}/api/v1/orders/${id}/status`, {
          method: 'PUT',
          headers: that.props.queryHeaders(idToken),
          body: JSON.stringify({"status": status})
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                loadingChangeStatus: false,
                typeChangeStatus: null
              });
            });
            setTimeout(() => {
              that.setState({
                errorSubmit: false
              });
            }, 5000);
          } else {
            res.json().then(function(data) {
              let orders = that.state.orders;
              orders[that.state.indexModalOrder] = data;
              let orderModal = data;
              that.setState({
                orders: orders,
                ordersModalResult: orderModal,
                orderHistories: data.histories,
                loadingChangeStatus: false,
                typeChangeStatus: null,
                successSubmitChangeStatus: true,
                successSubmit: true,
                successText: 'Успішно змінено',
                statusModalAccept: false
              });
            })
            setTimeout(() => {
              that.setState({
                successSubmitChangeStatus: false
              });
            }, 10);
            setTimeout(() => {
              that.setState({
                successSubmit: false,
                successText: null,
              });
            }, 5000);
          }
        })
      })
    }
  }

  /* Handler change note value */
  handlerNoteValue(event){
    this.setState({
      noteValue: event.target.value
    });
  }

  /* Handler set order note and restore data */
  handlerSubmitNote(id, note, checkbox, event){
    event.preventDefault();
    let that = this;
    this.setState(prevState => ({
      loadingSubmitNote: true
    }));
    getAuth().currentUser.getIdToken().then(function(idToken){
      fetch(`${that.props.queryLink}/api/v1/orders/${id}/notes`, {
        method: 'POST',
        headers: that.props.queryHeaders(idToken),
        body: JSON.stringify({
          "notes": note,
          'sendNotification': checkbox
        })
      }).then(res => {
        if(res.status !== 200) {
          that.setState(prevState => ({
            loadingSubmitNote: false
          }));
          setTimeout(() => {
            that.setState({
              errorSubmit: false
            });
          }, 5000);
        } else {
          res.json().then(function(data) {
            that.setState(prevState => ({
              orderHistories: [...prevState.orderHistories, data]
            }));
            setTimeout(function() {
              let infoDiv;
              let height;
              if(typeof window !== "undefined" && typeof document !== 'undefined') {
                infoDiv = document.querySelector('.modal__order-content-col-info');
                height = infoDiv.clientHeight;
              }
              that.setState(prevState => ({
                heightInfo: height
              }));
            }, 500);
          })
          that.setState(prevState => ({
            loadingSubmitNote: false,
            noteValue: '',
            sendMessage: false,
            successSubmit: true,
            successText: 'Успішно додано',
            successSubmitNoteStatus: true
          }));
          setTimeout(() => {
            that.setState({
              successSubmitNoteStatus: false
            });
          }, 10);
          setTimeout(() => {
            that.setState({
              successSubmit: false,
              successText: null
            });
          }, 5000);
        }
      })
    })
  }

  /* Handler submit price value */
  handlerSubmitSetPrice(value, event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      fetch(`${that.props.queryLink}/api/v1/orders/${that.state.ordersModalResult.orderID}/data`, {
        method: 'PUT',
        headers: that.props.queryHeaders(idToken),
        body: JSON.stringify({"totalPrice": value, "quantity": that.state.ordersModalResult.quantity})
      }).then(res => {
        if(res.status !== 200) {
          setTimeout(() => {
            that.setState({
              errorSubmit: false
            });
          }, 5000);
        } else {
          that.setState( {
            openSetPrice: false
          });
          res.json().then(function(data) {
            let orderModal = data;
            let orders = that.state.orders;
            orders[that.state.indexModalOrder] = data;
            that.setState({
              orders: orders,
              ordersModalResult: orderModal,
              successSubmit: true,
              successText: 'Успішно додано'
            });
          });
          setTimeout(() => {
            that.setState({
              successSubmit: false,
              successText: null
            });
          }, 5000);
        }
      })
    })
  }

  /* Handler submit quantity value */
  handlerSubmitSetQuantity(value, event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      fetch(`${that.props.queryLink}/api/v1/orders/${that.state.ordersModalResult.orderID}/data`, {
        method: 'PUT',
        headers: that.props.queryHeaders(idToken),
        body: JSON.stringify({"quantity": value, "totalPrice": that.state.ordersModalResult.totalPrice})
      }).then(res => {
        if(res.status !== 200) {
          setTimeout(() => {
            that.setState({
              errorSubmit: false
            });
          }, 5000);
        } else {
          that.setState( {
            openSetQuantity: false
          });
          res.json().then(function(data) {
            let orderModal = data;
            let orders = that.state.orders;
            orders[that.state.indexModalOrder] = data;
            that.setState( {
              orders: orders,
              ordersModalResult: orderModal,
              successSubmit: true,
              successText: 'Успішно додано'
            });
          });
          setTimeout(() => {
            that.setState({
              successSubmit: false,
              successText: null
            });
          }, 5000);
        }
      })
    })
  }

  /* Handler restore data info modal */
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalOrder: false,
        modalDisabled: false,
        idDeleteOrder: null,
        orderHistories: [],
        heightInfo: 'auto',
        statusModalAccept: false
      }));
    }, 500);
  }

  handlerModalDeclineDisabled = () => {
    this.setState(prevState => ({
      statusModalAccept: false
    }));
  }

  /* Pagination handler */
  handlerPaginationItems = (orderItems, current) => {
    this.setState({
      orders: orderItems,
      currentPage: current
    });
  }

  /* Handler for close success labels */
  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successSubmit: false
    }));
  }

  /* Handler for close errors labels */
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }

  /* Render component */
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.title = this.props.getTranslate('orders-Title') + ' | ' + this.props.companyName;
    }
    let resultOrders = '';
    if(this.state.error) {
      resultOrders = <div>{this.props.getTranslate('loading-ErrorRenderContent')}</div>;
    } else if(!this.state.isLoadedOrders) {
      resultOrders = <Loading />;
    } else {
      resultOrders = <>
      <div className="account-wgt account-wgt__orders">

      {/* Table component */}
      <Table
        header={[
          this.props.getTranslate('orders-ItemName'),
          'Номер замовлення',
          this.props.getTranslate('orders-Type'),
          this.props.getTranslate('orders-Client'),
          this.props.getTranslate('orders-Price'),
          this.props.getTranslate('orders-Date'),
          this.props.getTranslate('orders-Status'),
          ''
        ]}
        handlerRow={this.handleModalOrder.bind(this)}
        bodyData={this.state.orders}
        bodyDataNames={['productName', 'orderNumber', 'orderType', 'userName', 'totalPrice', 'createdDate', 'orderStatus']}
        shopName={'shopName'}
        translationValueItems={{
            'CUSTOM_SHOP': this.props.getTranslate('orders-TypeValueRequestShop'),
            'COMPANY_SHOP': this.props.getTranslate('orders-TypeValueCustomShop'),
            'NEW': this.props.getTranslate('orders-StatusNew'),
            'DONE': this.props.getTranslate('orders-StatusDelivered'),
            'IN_PROGRESS': this.props.getTranslate('orders-StatusAccepted'),
            'DECLINED': this.props.getTranslate('orders-StatusDeclined')
          }}
        currencyLabel={{
          'totalPrice': this.props.currency
        }}
        dateValueFormat={{
          start: 'createdDate',
          end: false
        }}
        labelsStatus={{
          'orderStatus': {
            'NEW': 'blue',
            'DONE': 'green',
            'IN_PROGRESS': 'yellow',
            'DECLINED': 'red'
          }
        }}
        controlItemsStatus={true}
        controlItems={{
          'enabled': false,
          'editButton': false,
          'editLink': {
            'status': true,
            'handler': this.props.getPermalink('/account/orders/edit/'),
            'label': this.props.getTranslate('tableDropdown-EditLabel')
          },
          'delete': false
        }}
        itemIDName={'orderID'}
        hightlightStatus={{
          'orderStatus': ['NEW']
        }}
        hightlightsCol={['totalPrice']}
        enabledMobileContent={true}
        mobileContentData={['productName', ['userName', 'createdDate'], 'orderStatus']}
      />

      </div></>;
    }
    return <>

    {/* Error handler */}
    <ResultErrors
      errors={this.state.errors}
      closeHandler={this.handlerCloseError.bind(this)}
    />

    {/* Query result handler */}
    <ResultQuery
      success={this.state.successSubmit}
      resultText={this.state.successText}
      handlerSuccess={this.handlerCloseSuccess}
      handlerError={this.handlerCloseError}
    />

    {/* Header component */}
    <AccountHeader
      queryLink={this.props.queryLink}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      getTranslate={this.props.getTranslate.bind(this)}
      completed={this.props.completed}
    />

    <div className="account-wrap">

    {/* Navigation component */}
    <AccountNav
      getPermalink={this.props.getPermalink}
      queryLink={this.props.queryLink}
      configsApp={this.props.configsApp}
      newReview={this.props.newReview}
      companyName={this.props.companyName}
      token={this.props.token()}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      dropdownStatus={this.state.dropdownStatus}
      dropdownHandler={this.handlerDropdown}
      getTranslate={this.props.getTranslate}
      completed={this.props.completed}
    />

    <div className="account-content">
      <div className="account-content__inner">

        {/* Account header component */}
        <AccountContentHeader
          title={this.props.getTranslate('orders-Title')}
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
        />

        <div className="team-nav team-nav_space_between">

          {/* Filter component */}
          <Filter
            className="control-wgt_align_left"
            buttonName={this.props.getTranslate('ordersFilter-Title')}
            dataSort={[
              {
                name: this.props.getTranslate('ordersFilter-StatusAll'),
                value: null
              },
              {
                name: this.props.getTranslate('ordersFilter-StatusNew'),
                value: 'NEW'
              },
              {
                name: this.props.getTranslate('ordersFilter-StatusAccepted'),
                value: 'IN_PROGRESS'
              },
              {
                name: this.props.getTranslate('ordersFilter-StatusDone'),
                value: 'DONE'
              },
              {
                name: this.props.getTranslate('ordersFilter-StatusDeclined'),
                value: 'DECLINED'
              }
            ]}
            handler={this.handlerGetOrdersStatus.bind(this)}
            currentFilter={this.state.currentFilterName}
            currentFilterVal={this.state.currentFilterVal}
          />

          <div>

          {/* Filter search input component */}
          <FilterSearch
            placeholder={this.props.getTranslate('orders-SearchPlaceholder')}
            value={this.state.searchValue}
            handler={this.handlerSearch.bind(this)}
          />

          </div>
        </div>
        {this.state.orders.length < 1 && this.state.isLoadedOrders && !this.state.error ? <div className="account-content__content account-content__content_empty"><div className="empty-wrapper">

          {/* If the response is empty */}
          <AccountContentEmpty
            img={this.state.searchEmpty ? search_empty : product_img}
            title={this.state.searchEmpty ? this.props.getTranslate('orders-NullSearchTitle') : this.props.getTranslate('orders-NullTitle')}
            description={this.state.searchEmpty ? this.props.getTranslate('orders-NullSearchDescription') : this.props.getTranslate('orders-SubTitle')}
          />

        </div></div> : <div className="account-content__content">

            {/* Query result */}
            {resultOrders}

        </div>}
        <div className="account-content__footer">

          {/* Pagination component */}
          <Pagination
            total={this.state.totalPages}
            perPage="20"
            path={`${this.props.queryLink}/api/v1/orders/search`}
            currentPage={this.state.currentPage}
            items={this.handlerPaginationItems}
            otherParams={this.state.paginationParams}
          />

        </div>
      </div>
    </div>
    </div>

    {this.state.statusModalAccept ?
      <div id="modal-decline" className={this.props.modalDisabledStatus ? `two disabled ${this.props.modalDisabledClass}` : `two ${this.props.modalDisabledClass}`}>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">Скаcування замовлення</h2>
          <button className="close-modal" type="button" onClick={this.handlerModalDeclineDisabled.bind(this)}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
          <div className="delete-category-form__description">Після скасування замовлення, юзеру {this.state.ordersModalResult.userName} буде поверненно на баланс {this.state.ordersModalResult.totalPrice} {this.props.currency} та змінити статус замовлення на інший буде неможливо.</div>
            <div className="user-info__nav user-info__nav_decline">
              <div className="user-info__nav-col user-info__nav-col_decline field-wrap__submit">
                <button className="btn btn_size_lg btn_theme_remove btn_width_100 user-info__nav-btn" type="button" onClick={this.handlerDeclineStatus.bind(this)}>Скасувати</button>
              </div>
              <div className="user-info__nav-col user-info__nav-col_decline">
                <button className="btn btn_size_lg btn_theme_secondary btn_width_100 user-info__nav-btn" type="button" onClick={this.handlerModalDeclineDisabled.bind(this)}>Відмінити</button>
              </div>
            </div>
        </div>
      </div> : ''}

    {/* Modal Order Info */}
    {this.state.modalOrder ? <InfoModal
      orderModal={true}
      orderNavDropDownStatus={<OrderModalDropdownStatusChange
        title={this.props.getTranslate('orders-StatusTitle')}
        id={this.state.ordersModalResult.orderID}
        activeStatus={this.state.ordersModalResult.orderStatus}
        statusItems={{
        'NEW': this.props.getTranslate('orders-StatusNew'),
        'IN_PROGRESS': this.props.getTranslate('orders-StatusAccepted'),
        'DONE': this.props.getTranslate('orders-StatusDelivered'),
        'DECLINED': this.props.getTranslate('ordersFilter-StatusDeclined')
      }}
      restoreFromSubmit={this.state.successSubmitChangeStatus}
      handler={this.handlerChangeStatus.bind(this)} />}
      orderAddComment={<OrderModalComment
        title={this.props.getTranslate('orders-CommentTitle')}
        id={this.state.ordersModalResult.orderID}
        userName={this.state.ordersModalResult.userName}
        checkboxLabel={this.props.getTranslate('orders-CommentCheckboxLabel')}
        buttonSubmitText={this.props.getTranslate('orders-CommentButtonSubmitText')}
        loader={this.state.loadingSubmitNote}
        handler={this.handlerSubmitNote.bind(this)}
        restoreFromSubmit={this.state.successSubmitNoteStatus}
      />}
      itemID={this.state.ordersModalResult.orderID}
      title={`${this.props.getTranslate('orders-modalTitle')} №${this.state.ordersModalResult.orderNumber}`}
      infoTitle={this.props.getTranslate('orders-modalInfoTitle')}
      infoUnTitle={this.state.ordersModalResult.productName}
      infoUnImg={this.state.ordersModalResult.productPhoto ? this.state.ordersModalResult.productPhoto : product_img_default}
      listTitle={this.props.getTranslate('orders-modalHistoryTitle')}
      listAmount={this.state.orderHistories && this.state.orderHistories.length > 0 ? this.state.orderHistories.length : '0'}
      dataContent={[this.state.ordersModalResult.shopName || this.state.ordersModalResult.shopName ?
        [{
          title: this.props.getTranslate('orders-modalLabelNameShop'),
          value: this.state.ordersModalResult.shopName,
        },
        {
          title: this.props.getTranslate('orders-modalLabellinkShop'),
          value: this.state.ordersModalResult.productLink,
          type: 'link'
        }] : '',
        [{
          title: this.props.getTranslate('orders-modalLabelPriceShop'),
          value: this.state.ordersModalResult.totalPrice ? this.state.ordersModalResult.totalPrice + ' ' + (this.props.currency && this.state.ordersModalResult.totalPrice ? this.props.currency : '') : (this.state.ordersModalResult.orderType !== 'COMPANY_SHOP' && !this.state.ordersModalResult.quantity ? <SetValueFromModal
            buttonText={this.props.getTranslate('orders-modalDropdownSetPriceButton')}
            label={`${this.props.getTranslate('orders-modalDropdownSetPriceLabel')} ${this.props.currency}`}
            labelInto={this.props.currency}
            placeholder={this.props.getTranslate('orders-modalDropdownSetPricePlaceholder')}
            name="price"
            submitButtonText={this.props.getTranslate('orders-modalDropdownSetPriceButtonSubmit')}
            handler={this.handlerSubmitSetPrice.bind(this)}
          /> : '')
        },
        {
          title: this.props.getTranslate('orders-modalLabelAmountShop'),
          value: this.state.ordersModalResult.quantity ? this.state.ordersModalResult.quantity : (this.state.ordersModalResult.orderType !== 'COMPANY_SHOP' && !this.state.ordersModalResult.quantity ? <SetValueFromModal
            buttonText={this.props.getTranslate('orders-modalDropdownSetAmountButton')}
            label={this.props.getTranslate('orders-modalDropdownSetAmountLabel')}
            placeholder={this.props.getTranslate('orders-modalDropdownSetAmountPlaceholder')}
            name="amount"
            submitButtonText={this.props.getTranslate('orders-modalDropdownSetAmountButtonSubmit')}
            handler={this.handlerSubmitSetQuantity.bind(this)}
          /> : '')
        }],
        [{
          title: this.props.getTranslate('orders-modalLabelBuyerShop'),
          value: this.state.ordersModalResult.userName
        },
        {
          title: this.props.getTranslate('orders-modalLabelDateShop'),
          value: <DateFormating start={this.state.ordersModalResult.createdDate} />
        },
        {
          title: this.props.getTranslate('orders-modalLabelStatusShop'),
          value: this.state.ordersModalResult.orderStatus
        }]
      ]}
      noteTitle={this.props.getTranslate('orders-modalLabelCommentShop')}
      noteValue={this.state.ordersModalResult.notes}
      orderData={{
        orderType: this.state.ordersModalResult.orderType,
        orderProductTitle: this.state.ordersModalResult.productName,
        orderProductPhoto: this.state.ordersModalResult.productPhoto ? this.state.ordersModalResult.productPhoto : product_img_default
      }}
      listData={this.state.orderHistories}
      orderListStatusTranslation={{
        'NEW': this.props.getTranslate('orders-modalHistoryStatusNew'),
        'DONE': this.props.getTranslate('orders-modalHistoryStatusDone'),
        'IN_PROGRESS': this.props.getTranslate('orders-modalHistoryStatusAccepted'),
        'DECLINED': this.props.getTranslate('orders-modalHistoryStatusRejected')
      }}
      translationValueItems={{
          'NEW': this.props.getTranslate('orders-StatusNew'),
          'DONE': this.props.getTranslate('orders-StatusDelivered'),
          'IN_PROGRESS': this.props.getTranslate('orders-StatusAccepted'),
          'DECLINED': this.props.getTranslate('ordersFilter-StatusDeclined')
        }}
        addCommentTitle={this.props.getTranslate('orders-StatusComment')}
      navigation={true}
      navigationEditPath={this.props.getPermalink(`/account/orders/edit/${this.state.ordersModalResult.orderID}`)}
      labelsStatus={{'NEW': 'blue', 'DONE': 'green', 'IN_PROGRESS': 'yellow', 'DECLINED': 'red'}}
      handlerClose={this.handleModalDisabled.bind(this)}
    /> : ''}
    </>;
  }
}
export default Orders;
