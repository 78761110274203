import React from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import DateFormating from './components/DateFormating';
import Loading from './components/Loading';
import ResultQuery from './components/ResultQuery';
import ResultErrors from './components/ResultErrors';
import AccountContentHeader from './components/AccountContentHeader';
import AccountContentEmpty from './components/AccountContentEmpty';
import DeleteModal from './components/DeleteModal';
import { Link } from "react-router-dom";
import Button from './components/Button';
import Table from './components/Table';
import Pagination from './components/Pagination';
import Filter from './components/Filter';
import FilterSearch from './components/FilterSearch';
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import EmptyFilterContent from './components/EmptyFilterContent';
import ads_img from './media/ads.png';
import sprite from './media/icons.svg';
import { getAuth } from "firebase/auth";
class Ads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      error: false,
      isLoaded: false,
      indexRowEvent: null,
      removeIndex: null,
      successAdsSubmit: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("successAds") ? JSON.parse(localStorage.getItem("successAds")) : null) : '',
      ads: [],
      dataAdModal: [],
      modalAd: false,
      errors: null,
      modalDeleteAds: false,
      idDeleteAds: null,
      currentPage: 0,
      totalPages: null,
      controlRow: null,
      isLoadedSearch: false,
      searchAdsValue: '',
      searchFilter: false,
      searchEmpty: false,
      isEnglish: (typeof window !== "undefined" && typeof document !== 'undefined') ? window.location.pathname.startsWith('/en') : false
    }
  }
  /* Handler for get mobile menu */
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };

  /* Handler for dropdown */
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }

  /* Pagination handler */
  handlerPaginationItems = (categoriesItems, current) => {
    this.setState({
      ads: categoriesItems,
      currentPage: current
    });
  }

  /* Handler for close success labels */
  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successAdsSubmit: null
    }));
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      localStorage.removeItem('successAds');
    }
  }

  /* Handler for close errors labels */
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }

  /* Handler get data mount component */
  componentDidMount() {
    let that = this;
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/ads/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(
        {
          "itemsPerPage": 20,
          "orderField": "creationDate"
        }
      )
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            ads: data.data,
            totalPages: data.totalPages
          })
        });
      } else if(res.status === 401) {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            errors: [data.message],
            error: true
          })
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            errors: data.errors,
            error: true
          })
        });
      }
    })
  })
  }

  /* Handler search items */
  handlerSearch(event) {
    let that = this;
      this.setState({
      isLoadedSearch: true,
      searchAdsValue: event.target.value,
      searchEmpty: false
    });
    let timeoutId = setTimeout(() => {
      getAuth().currentUser.getIdToken().then(function(idToken) {
              const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/ads/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({"itemsPerPage": 20, "query": that.state.searchAdsValue})
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoadedSearch: false
              });
            });
          } else {
            res.json().then(function(data) {
              that.setState({
                isLoadedSearch: false,
                ads: data.data,
                totalPages: data.totalPages
              });
              if(data.data.length < 1){
                that.setState({
                  searchEmpty: true
                });
              }
            });
          }
        });
      });
    }, 500);
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = timeoutId;
  }

  /* Ads modal delete handler */
  handlerDeleteAdModal(id, index, event){
    this.setState({
      modalDeleteAds: true,
      removeIndex: index,
      idDeleteAds: id
    });
  }

  /* Ads delete handler for submit */
  handlerDeleteAd(id, event){
    let that = this;
    that.setState({
      loadingDeleteSumbit: true
    })
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/ads/${id}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
      } else {
        that.handleModalDisabled();
        that.setState({
          indexRowEvent: that.state.removeIndex
        });
        fetch(`${that.props.queryLink}/api/v1/ads/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({"itemsPerPage": 20, "page": that.state.ads.length < 2 ? (that.state.currentPage - 1) : that.state.currentPage})
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                error: true
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState({
                indexRowEvent: null,
                removeIndex: null,
                ads: data.data,
                totalPages: data.totalPages,
                loadingDeleteSumbit: false
              });
            })
          }
        })
      }
    })
  })
  }

  /* Ads open modal info */
  handlerModalGetAd(id, index, event) {
      let that = this;
      if(!event.target.closest('.control-wgt')) {
        getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/ads/${id}`, {
          method: 'GET',
          headers: myHeaders,
        }).then(res => {
          if (res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState(prevState => ({
                dataAdModal: data,
                modalAd: true,
                removeIndex: index
              }));
            })
          }
        })
      })
    }
  }

  /* Ads disabled modal */
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
      dataAdModal: []
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modalAd: false,
        modalDeleteAds: false,
        idDeleteAds: null
      }));
    }, 500);
  }

  /* Handler for change status */
  handlerEnabled(id, index, event){
    let that = this;
    let arr = that.state.ads;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/ads/${id}/status`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({"adStatus": arr[index].adStatus === 'HIDDEN' ? 'ACTIVE' : 'HIDDEN'})
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            arr[index] = data;
            that.setState({
              ads: arr
            })
          })
        }
      })
    });
  }

  /* Handler for get ads status */
  handlerGetAdsStatus(status, event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/ads/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(
        {
          "itemsPerPage": 20,
          "adStatus": status
        }
      )
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            ads: data.data,
            totalPages: data.totalPages,
            searchFilter: true
          })
        });
      } else if(res.status === 401) {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            errors: [data.message],
            error: true
          })
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            errors: data.errors,
            error: true
          })
        });
      }
    })
  })
  }

  handlerRestoreSearch(event){
    that.setState({
      searchAdsValue: ''
    });
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/ads/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(
        {
          "itemsPerPage": 20,
          "orderField": "creationDate"
        }
      )
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            ads: data.data,
            totalPages: data.totalPages
          })
        });
      } else if(res.status === 401) {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            errors: [data.message],
            error: true
          })
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            errors: data.errors,
            error: true
          })
        });
      }
    })
  })
  }

  /* Render component */
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.title = this.props.getTranslate('catalog-Title') + ' | ' + this.props.companyName;
    }
    if(this.state.successAdsSubmit){
      setTimeout(() => {
        this.handlerCloseSuccess();
      }, 5000);
    }
    let resultAds = '';
    if(this.state.error) {
      resultAds = <div>{this.props.getTranslate('loading-ErrorRenderContent')}</div>;
    } else if(!this.state.isLoaded) {
      resultAds = <Loading />;
    } else {
      resultAds = <>
      <div className="account-wgt account-wgt__board">

          {/* Table component */}
            <Table
              header={[
                this.props.getTranslate('marketplace-Name'),
                this.props.getTranslate('marketplace-User'),
                this.props.getTranslate('marketplace-Date'),
                this.props.getTranslate('marketplace-Status'),
                ''
              ]}
              handlerRow={this.handlerModalGetAd.bind(this)}
              bodyData={this.state.ads}
              bodyDataNames={['title', 'author', 'creationDate', 'adStatus']}
              controlItemsStatus={true}
              controlItems={{
                'enabled': {'status': true, 'handler': this.handlerEnabled.bind(this), 'label': {'hide': this.props.getTranslate('tableDropdown-StatusLabel'), 'show': this.props.getTranslate('tableDropdown-StatusLabelShow')}},
                'editButton': false,
                'editLink': {
                  'status': true,
                  'handler': this.props.getPermalink(`/account/marketplace/edit/`),
                  'label': this.props.getTranslate('tableDropdown-EditLabel')
                },
                'delete': {
                  'status': true,
                  'handler': this.handlerDeleteAdModal.bind(this),
                  'label': this.props.getTranslate('tableDropdown-EditRemove')
                }
              }}
              translationValueItems={{
                  'ACTIVE': this.props.getTranslate('ads-StatusActive'),
                  'HIDDEN': this.props.getTranslate('ads-StatusNotActive'),
                  'SOLD': this.props.getTranslate('ads-StatusSold')
                }}
              dateValueFormat={{
                start: 'creationDate',
                end: false
              }}
              labelsStatus={{
                'adStatus': {
                  'ACTIVE': 'blue',
                  'SOLD': 'green',
                  'HIDDEN': 'red'
                }
              }}
              itemIDName={'adID'}
              itemEnableNameForStatus={'HIDDEN'}
              itemEnableNameForStatusData={'adStatus'}
              enableTooltipMessage={this.props.getTranslate('tableEnable-TooltipMessage')}
              enabledMobileContent={true}
              mobileContentType={'block'}
              mobileContentHeader={[
                this.props.getTranslate('marketplace-User'),
                this.props.getTranslate('marketplace-Date'),
                this.props.getTranslate('marketplace-Status')
              ]}
              mobileContentData={['title', 'author', 'creationDate', 'adStatus']}
              removeIndex={this.state.removeIndex}
            />
          </div>
      </>
    }

    return <>
    {/* Error handler */}
    <ResultErrors
      errors={this.state.errors}
      closeHandler={this.handlerCloseError.bind(this)}
    />

    {/* Query result handler */}
    {this.state.successAdsSubmit ? <ResultQuery
      success={this.state.successAdsSubmit}
      resultText={this.state.successAdsSubmit.text}
      handlerSuccess={this.handlerCloseSuccess}
      handlerError={this.handlerCloseError}
    /> : ''}

    {/* Header component */}
    <AccountHeader
      queryLink={this.props.queryLink}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      getTranslate={this.props.getTranslate.bind(this)}
      completed={this.props.completed}
    />

    <div className="account-wrap">

    {/* Navigation component */}
    <AccountNav
      getPermalink={this.props.getPermalink}
      queryLink={this.props.queryLink}
      configsApp={this.props.configsApp}
      newReview={this.props.newReview}
      companyName={this.props.companyName}
      token={this.props.token()}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      dropdownStatus={this.state.dropdownStatus}
      dropdownHandler={this.handlerDropdown}
      getTranslate={this.props.getTranslate}
      completed={this.props.completed}
    />

    <div className="account-content">
      <div className="account-content__inner">

        {/* Account header component */}
        <AccountContentHeader
          title={this.props.getTranslate('marketplace-Title')}
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
        />

        <div className="team-nav team-nav_space_between">

          {/* Button for add category */}
          <Button
            type="link"
            icon={true}
            iconItem="#plus-icon"
            text={this.props.getTranslate('marketplace-AddButton')}
            addClass="header-content-nav__btn"
            handler={this.props.getPermalink('/account/marketplace/create')}
          />

          {/* Filter component */}
          <Filter
            className="library-filter-dropdown"
            buttonName={this.props.getTranslate('adsFilter-Title')}
            dataSort={[
              {
                name: this.props.getTranslate('adsFilterStatus-All'),
                value: null
              },
              {
                name: this.props.getTranslate('adsFilterStatus-Active'),
                value: 'ACTIVE'
              },
              {
                name: this.props.getTranslate('adsFilterStatus-Hidden'),
                value: 'HIDDEN'
              }
            ]}
          handler={this.handlerGetAdsStatus.bind(this)}
          labelDisabled={true}
          />

          {/* Filter search input component */}
          <FilterSearch
          placeholder={this.props.getTranslate('adsSearch-Placeholder')}
          value={this.state.searchAdsValue}
          handler={this.handlerSearch.bind(this)}
          />
        </div>

        {this.state.ads.length < 1 && this.state.searchAdsValue && this.state.searchFilter && this.state.isLoaded && !this.state.error ? <>
          <div className="account-content__content account-content__content_empty">
            <div className="empty-wrapper">

              {/* If the response is empty */}
              {!this.state.searchAdsValue && !this.state.searchFilter ? <AccountContentEmpty
                img={ads_img}
                title={this.props.getTranslate('ads-EmptyAdsTitle')}
                description={this.props.getTranslate('ads-EmptyAdsDescription')}
                buttonType="link"
                buttonText={this.props.getTranslate('marketplace-AddButton')}
                buttonIcon="#plus-icon"
                buttonHandler={this.props.getPermalink('/account/marketplace/create')}
              /> : ''}

            </div>
            </div>

            </> : <><div className="account-content__content">

              {/* Query result */}
              {resultAds}
              {this.state.isLoaded && this.state.searchFilter && this.state.ads.length < 1 ?
              <EmptyFilterContent
                headline="Нічого не знайдено"
                btnHeadline="Очистити фільтр"
                handleRestore={this.handlerGetAdsStatus.bind(this, null)}
              /> : ''}
              {this.state.isLoaded && this.state.searchAdsValue !== '' && this.state.ads.length < 1 ?
                <EmptyFilterContent
                  headline="За вашим запитом нічого не знайдено"
                  btnHeadline="Очистити пошук"
                  handleRestore={this.handlerGetAdsStatus.bind(this, null)}
              /> : ''}
            </div></>}
            <div className="account-content__footer">

              {/* Pagination component */}
              <Pagination
                total={this.state.totalPages}
                perPage="20"
                path={`${this.props.queryLink}/api/v1/ads/search`}
                currentPage={this.state.currentPage}
                items={this.handlerPaginationItems}
              />

            </div>
          <div>
        </div>
      </div>
    </div>
    </div>
    {this.state.modalAd ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.state.dataAdModal.title}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
        <div className="modal-ad-content">
          <div className="modal-ad-content__inner">
            <div className="modal-ad-content__info">
              <div className="modal-library-content__headline">{this.props.getTranslate('marketplaceModal-CreateDateTitle')}:</div>
              <div className="modal-library-content__value"><DateFormating start={this.state.dataAdModal.creationDate} /></div>
              <div className="modal-library-content__headline">{this.props.getTranslate('marketplaceModal-AuthorTitle')}:</div>
              <div className="modal-library-content__value">{this.state.dataAdModal.author}</div>
              <div className="modal-library-content__headline">{this.props.getTranslate('marketplaceModal-PriceTitle')}:</div>
              <div className="modal-library-content__value">{this.state.dataAdModal.price} {this.props.currency}</div>
            </div>
            <div className="modal-ad-content__img">
              {this.state.dataAdModal && this.state.dataAdModal.photo ? <img src={this.state.dataAdModal.photo} /> : ''}
            </div>
          </div>
            <div className="modal-library-content__headline">{this.props.getTranslate('marketplaceModal-DescriptionTitle')}:</div>
            <Scrollbars
                renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                renderThumbVertical={props => <div {...props} className="thumb-vertical thumb-vertical-scroll"/>}
                autoHeight
                autoHeightMin={0}
                autoHeightMax={150}
                  renderView={props => <div {...props} />}>
            <div className="modal-library-content__value" dangerouslySetInnerHTML={{__html: this.state.dataAdModal.description}} style={{paddingRight: "10px"}}></div>
            </Scrollbars>
        </div>
        <div className="product-info__nav">
          <div className="product-info__nav-col field-wrap__submit">
            <Link className="btn btn_theme_secondary btn_size_lg btn_width_100 product-info__nav-btn" to={this.props.getPermalink(`/account/marketplace/edit/${this.state.dataAdModal.adID}`)}>{this.props.getTranslate('marketplaceModal-SubmitButton')}</Link>
          </div>
          <div className="product-info__nav-col field-wrap__submit">
            <button className="btn btn_theme_remove btn_size_lg btn_width_100 product-info__nav-btn" type="button" disabled={this.state.loadingDeleteSumbit? true : false} onClick={this.handlerDeleteAdModal.bind(this, this.state.dataAdModal.adID, this.state.removeIndex)}>{this.props.getTranslate('marketplaceModal-RemoveButton')}{this.state.loadingDeleteSumbit && <Loading />}</button>
          </div>
        </div>
        </div>
    </div> : ''}

      {/* Delete item modal */}
      <DeleteModal
        modalStatus={this.state.modalDeleteAds}
        modalDisabledStatus={this.state.modalDisabled}
        modalDisabledClass={this.state.addClass}
        handlerModalDisabled={this.handleModalDisabled}
        modalTitle={this.props.getTranslate('modal-deleteAdsTitle')}
        modalDescription={this.props.getTranslate('modal-deleteAdsDescription')}
        modalHandlerDelete={this.handlerDeleteAd.bind(this, this.state.idDeleteAds)}
        modalHandlerBtnTxt={this.props.getTranslate('removeModal-ButtonSubmit')}
        modalHandlerBtnLoading={this.state.loadingDeleteSumbit}
      />

    </>;
  }
}
export default Ads;
