import React from "react";
import Loading from './Loading';
import sprite from '../media/icons.svg';
import { Link } from "react-router-dom";
class Button extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let theme = '';
    if(this.props.btnTheme){
      if(this.props.btnTheme === 'secondary'){
        theme = 'btn_theme_secondary';
      } else if(this.props.btnTheme === 'white') {
        theme = 'btn_theme_white';
      } else if(this.props.btnTheme === 'white-transparent') {
        theme = 'btn_theme_white-transparent';
      }
    }
    if(this.props.type === 'button'){
      return <button className={`btn ${this.props.className ? this.props.className : ''} ${this.props.btnWidth === 'full' ? 'btn_width_100' : '' } ${this.props.btnTheme ? theme : ''} ${this.props.icon ? 'btn_with_icon' : ''} ${this.props.btnSize === 'large' ? 'btn_size_lg' : ''} ${this.props.addClass ? this.props.addClass : ''}`} type={this.props.typeBtn === 'submit' ? 'submit' : 'button'} onClick={this.props.handler.bind(this)}>
              {this.props.icon ? <svg className={`${this.props.iconClass ? this.props.iconClass : 'btn__icon-add'}`}>
                <use href={sprite + this.props.iconItem}></use>
              </svg> : ''}{this.props.text}{this.props.loader ? <Loading /> : ''}
              {this.props.currentFilter ? <span className="current-filter">
                <span className="current-filter__inner">{this.props.currentFilter}
                <span className="current-filter__btn" onClick={this.props.headlerRestore.bind(this)}>
                  <svg className={`current-filter__restore`}>
                    <use href={`${sprite}#close-icon`}></use>
                  </svg>
                </span>
                </span>
              </span> : ''}
              {this.props.reset ? <span className="btn-close-element" onClick={this.props.reset}><svg className={`btn-close-icon`}>
                <use href={`${sprite}#close-icon`}></use>
              </svg></span> : ''}
              </button>
    } else {
      return <Link className={`btn ${this.props.className ? this.props.className : ''} ${this.props.btnWidth === 'full' ? 'btn_width_100' : '' } ${this.props.btnTheme ? theme : ''} ${this.props.icon ? 'btn_with_icon' : ''} ${this.props.btnSize === 'large' ? 'btn_size_lg' : ''} ${this.props.addClass ? this.props.addClass : ''}`} to={this.props.handler}>
              {this.props.icon ? <svg className={`${this.props.iconClass ? this.props.iconClass : 'btn__icon-add'}`}>
                <use href={`${sprite}#plus-icon`}></use>
              </svg> : ''}{this.props.text}</Link>
    }
  }
}
export default Button;
