import React from "react";
import { Link } from "react-router-dom";
import sprite from '../../media/icons.svg';
import thumb from '../../media/news-thumbnail.png';
class BlogViewThumb extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <div className="blog-page-thumb">
      <div className="wrapper">
        <img className="blog-page-thumb__img" src={this.props.thumb} alt={this.props.headline} />
      </div>
    </div>
  }
}
export default BlogViewThumb;
