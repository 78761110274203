import React, { useRef, useEffect } from 'react';
import avatar_5 from '../../media/avatar_7.png';

import avatar_7 from '../../media/avatar_7.png';
import avatar_9 from '../../media/avatar-9.png';
import tenis_icon from '../../media/bitmap-tenis.png';
import pizza_icon from '../../media/bitmap-pizza.png';
import icon_umbrella from '../../media/icon_umbrella.png';
import fire_icon from '../../media/fire_icon.png';
import photo_desctop_icon from '../../media/cards-possibilities-shop.png';
const HomeScrolling = (props) => {
  const listRef = useRef(null);
  const listItemWidthRef = useRef(0);
  const requestIdRef = useRef(null);
  const lastTimeRef = useRef(0);
  const scrollDistanceRef = useRef(0);

  useEffect(() => {
    const list = listRef.current;
    const listItems = list.children;
    const listItemWidth = listItems[0].clientWidth;
    listItemWidthRef.current = listItemWidth;

    const animateScroll = (time) => {
      if (lastTimeRef.current !== 0) {
        const deltaTime = time - lastTimeRef.current;
        scrollDistanceRef.current += (deltaTime / 1000) * 50; // Меняем значение 50 для скорости анимации

        if (scrollDistanceRef.current >= listItemWidth) {
          scrollDistanceRef.current = 0;
          list.appendChild(listItems[0]);
        }

        list.style.transform = `translateX(-${scrollDistanceRef.current}px)`;
      }

      lastTimeRef.current = time;
      requestIdRef.current = requestAnimationFrame(animateScroll);
    };

    requestIdRef.current = requestAnimationFrame(animateScroll);

    return () => {
      cancelAnimationFrame(requestIdRef.current);
    };
  }, []);

  return (
      <div className={`infinite-scroll-list`} ref={listRef}>
        {renderListItems(props.items)}
      </div>
  );
};

const renderListItems = (items) => {
  return (
    <>
    {items.map((item, index) => {
      return     <div className="home-bonuses__wgt-point" key={index}>
            <div className="home-bonuses__wgt-point-inner">
              <div className="home-bonuses__wgt-point-content">
                <div className={`home-bonuses__wgt-point-headline home-bonuses__wgt-point-headline_theme_${item.theme}`}>{item.headline}</div>
                <div className="home-bonuses__wgt-point-description">{item.description}</div>
              </div>
              <div className="home-bonuses__wgt-point-icon">
                <img src={item.img} alt="" />
              </div>
            </div>
          </div>
    })}
    </>
  );
};

export default HomeScrolling;
