import React from "react";
import sprite from '../media/icons.svg';
    class ResultQuery extends React.Component {
      constructor(props) {
        super(props);
      }
      render() {
        return <>
          {this.props.success && <div className="response-submit">
          <div className="response-submit__item response-submit__item_success">{this.props.resultText}
            <button className="response-submit__close" type="button" onClick={this.props.handlerSuccess.bind(this)}>
            <svg><use href={`${sprite}#close-icon`}></use></svg>
            </button></div></div>}
            {this.props.error && <div className="response-submit">
            <div className="response-submit__item response-submit__item_error">{this.props.resultText ? this.props.resultText : 'Ошибка!'}
              <button className="response-submit__close" type="button" onClick={this.props.handlerError.bind(this)}>
              <svg><use href={`${sprite}#close-icon`}></use></svg>
              </button></div></div>}
        </>
      }
    }
    export default ResultQuery;
