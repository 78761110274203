import React from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link } from "react-router-dom";
import Loading from './components/Loading';
import AccountContentHeader from './components/AccountContentHeader';
import ResultQuery from './components/ResultQuery';
import ResultErrors from './components/ResultErrors';
import TabItems from './components/TabItems';
import Table from './components/Table';
import DeleteModal from './components/DeleteModal';
import Pagination from './components/Pagination';
import FilterSearch from './components/FilterSearch';
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import sprite from './media/icons.svg';
import member_1 from './media/avatar-1.png';
import member_3 from './media/avatar-13.png';
import { getAuth } from "firebase/auth";
class Teams extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: this.props.token(),
      modalCreateTeam: false,
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      indexRowTeam: null,
      removeIndex: null,
      totalPages: null,
      currentPage: 0,
      errorTeams: null,
      isLoadedTeams: false,
      modalDisabled: false,
      modal: false,
      modalAddTeam: false,
      modalGetTeam: false,
      modalGetUsers: false,
      modalData: {},
      teamName: '',
      teamMembers: [],
      teamsList: [],
      isLoadedSubmitTeam: false,
      updateId: null,
      addNewMember: false,
      users: [],
      errorUser: null,
      isLoadedUser: false,
      addMembersByCreateTeam: [],
      successSubmit: false,
      submitLabel: null,
      errorSubmit: null,
      isRemoveMemberLoading: false,
      removeMemberIndex: null,
      totalItems: '',
      totalItemUsers: '',
      errorFields: false,
      getUsersIds: [],
      searchValue: '',
      usersSearch: [],
      errors: null,
      error: false,
      modalDeleteTeam: false,
      idDeleteTeam: null,
      controlRow: null,
      teamNameModal: '',
      searchTeamValue: '',
      errorOnlySpecial: false,
      heightThumb: null
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  handlerCloseElements(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      const div = document.querySelector( '.control-wgt');
      document.addEventListener('click', e => {
        const withinBoundaries = e.composedPath().includes(div);
        if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
          if ( ! withinBoundaries ) {
            this.setState( {
              controlRow: null
            } );
          }
        }
      });
    }
  }
  handlerTeamName (event) {
    if(this.state.errorFields){
      this.setState({
        errorFields: false
      });
    }
    this.setState({
      teamName: event.target.value
    });
  }
  handlerToggleUser(index, name, id, photo, event){
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.userID === id);
    if(indexArr !== -1){
      arr.splice(indexArr, 1);
    } else {
      arr = [...arr, {userID: id, name: name, photo: photo}];
    }
    this.setState(prevState => ({
      getUsersIds: arr
    }));
  }
  handlerCreateTeamSubmit (event) {
    event.preventDefault();
    let that = this;
    let error = false;
    this.setState({
      isLoadedSubmitTeam: true,
      errorFields: false
    });
    const teamNameWithoutSpaces = this.state.teamName.trim();
    const isValidTeamName = /[a-zA-Z0-9а-яА-Я\s]/.test(teamNameWithoutSpaces);
    if(teamNameWithoutSpaces === '' || !isValidTeamName){
      error = true;
    }
    if(teamNameWithoutSpaces === ''){
      this.setState({
        teamName: teamNameWithoutSpaces
      });
    }
    if(!isValidTeamName){
      this.setState({
        errorOnlySpecial: true,
        errorFields: false
      });
    }
    if(!error){
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
        fetch(`${that.props.queryLink}/api/v1/teams`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "name": teamNameWithoutSpaces,
            "users": that.state.teamMembers
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoadedSubmitTeam: false,
                errors: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState(prevState => ({
                isLoadedSubmitTeam: false,
                teamName: '',
                totalPages: data.totalPages,
                successSubmit: true,
                submitLabel: that.props.getTranslate('CreateTeam-SuccessLabel'),
                errorFields: false,
                totalItems: prevState.totalItems < 1 ? 1 : (prevState.totalItems + 1)
              }));
              that.handleModalDisabled();
              setTimeout(() => {
                that.setState({
                  successSubmit: false,
                  submitLabel: null
                });
              }, 5000);
              Promise.all(that.state.addMembersByCreateTeam).then(values => {
                values.map((item, index) => {
                  fetch(`${that.props.queryLink}/api/v1/teams/${data.teamID}/assign`, {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify({
                      "userID": item
                    })
                  }).then(res => {
                    if(res.status !== 200) {
                      res.json().then(function(data) {
                        that.setState({
                          errors: data.errors
                        })
                      });
                    }
                  })
                })
              });
                        fetch(`${that.props.queryLink}/api/v1/teams/search`, {
                          method: 'POST',
                          headers: myHeaders,
                          body: JSON.stringify({
                            "itemsPerPage": 20
                          })
                        }).then(res => {
                          if(res.status !== 200) {
                            res.json().then(function(data) {
                              that.setState({
                                errors: data.errors
                              })
                            });
                          } else {
                            res.json().then(function(data) {
                              that.setState({
                                teamsList: data.data
                              })
                            });
                          }
                        })
            })
          }
        })
      })
      } else {
        this.setState({
          isLoadedSubmitTeam: false,
          errorFields: true
        });
      }
    }
  handlerUpdateTeam (event) {
    event.preventDefault();
    let that = this;
    if(!this.state.teamName){
      this.setState({
        errorFields: true
      });
    }
    if(this.state.teamName){
    this.setState({
      isLoadedSubmitTeam: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/teams/${that.state.updateId}`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        "name": that.state.teamName,
        "teamID": that.state.updateId,
        "users": that.state.getUsersIds
      })
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoadedSubmitTeam: false,
            errors: data.errors
          })
        });
      } else {
        that.setState({
          isLoadedSubmitTeam: false,
          successSubmit: true,
          submitLabel: that.props.getTranslate('UpdateTeam-SuccessLabel'),
          errorFields: false
        });
        that.handleModalDisabled();
        setTimeout(() => {
          that.setState({
            successSubmit: false,
            submitLabel: null
          });
        }, 5000);
        fetch(`${that.props.queryLink}/api/v1/teams/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "itemsPerPage": 20
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              });
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                teamsList: data.data
              });
            })
          }
        })
      }
    })
  })
  }
}
  handlerCreateTeamModal () {
    this.setState(prevState => ({
      updateId: null,
      modalCreateTeam: !prevState.modalCreateTeam
    }));
  }
  handlerGetTeam (index, id, event) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/teams/${id}?includeUsers=true`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState(prevState => ({
              teamMembers: data.users,
              getUsersIds: data.users
            }));
          });
                that.setState(prevState => ({
        modalGetTeam: !prevState.modalGetTeam,
        teamName: prevState.teamsList[index].name,
        updateId: id
      }));
        }
      })
    })
    }
  handleModalDisabled = () => {
    document.querySelector('body').style.overflow = "";
    this.setState(prevState => ({
      modalDisabled: true,
      addNewMember: false
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalCreateTeam: false,
        modalGetTeam: false,
        modalGetUsers: false,
        modalDisabled: false,
        getUsersIds: [],
        searchValue: '',
        usersSearch: [],
        teamName: '',
        teamMembers: [],
        modalDeleteTeam: false,
        idDeleteTeam: null,
        teamNameModal: '',
        heightThumb: null,
        errorFields: false,
        errorOnlySpecial: false
      }));
    }, 500);
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/teams/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "itemsPerPage": 20
      })
    }).then(res => {
      if(res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            isLoadedTeams: true,
            teamsList: data.data,
            totalPages: data.totalPages,
            totalItems: data.totalItems
          });
        })
      fetch(`${that.props.queryLink}/api/v1/users/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"itemsPerPage": 20})
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.error
            });
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              totalItemUsers: data.totalItems
            });
          });
        }
      })
      } else if(res.status === 401){
        res.json().then(function(data) {
          that.setState({
            isLoadedTeams: true,
            errors: [data.message],
            error: true
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            isLoadedTeams: true,
            errors: data.errors,
            error: true
          });
        })
      }
    })
  })
  }

  handlerSearch(event) {
    let that = this;
      this.setState({
      isLoadedSearch: true,
      searchTeamValue: event.target.value,
      searchEmpty: false
    });
    let timeoutId = setTimeout(() => {
      getAuth().currentUser.getIdToken().then(function(idToken) {
              const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/teams/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "itemsPerPage": 20,
            "name": that.state.searchTeamValue
          })
        }).then(res => {
          if(res.status === 200) {
            res.json().then(function(data) {
              that.setState({
                isLoadedSearch: false,
                teamsList: data.data,
                totalPages: data.totalPages,
                totalItems: data.totalItems
              });
            })
          }
        })
      });
    }, 500);
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = timeoutId;
  }

  handlerDeleteTeamModal(id, index, name, event){
    this.setState({
      modalDeleteTeam: true,
      removeIndex: index,
      idDeleteTeam: id,
      teamNameModal: name
    });
  }
  handlerRemoveTeam (event) {
    let that = this;
    that.setState({
      loadingDeleteSumbit: true
    })
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/teams/${that.state.idDeleteTeam}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            loadingDeleteSumbit: false
          });
        });
      } else {
        that.setState(prevState => ({
          indexRowTeam: that.state.removeIndex,
          totalItems: (prevState.totalItems - 1)
        }));
        fetch(`${that.props.queryLink}/api/v1/teams/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({"itemsPerPage": 20, "page": that.state.teamsList.length < 2 ? 1 : that.state.currentPage})
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              });
            });
          } else {
            res.json().then(function(data) {
              that.setState({
                indexRowTeam: null,
                removeIndex: null,
                teamsList: data.data,
                modalDeleteTeam: false,
                loadingDeleteSumbit: false
              });
            })
          }
        })
      }
    })
  })
  }
  handlerAddUserIds(){
    let ids = this.state.getUsersIds.map((x => x.userID));
    this.setState({
      teamMembers: ids
    });
    this.setState(prevState => ({
      modalDisabled: true,
      addNewMember: false
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalCreateTeam: false,
        modalGetTeam: false,
        modalGetUsers: false,
        modalDisabled: false
      }));
    }, 500);
    setTimeout(() => {
      this.setState(prevState => ({
        modalCreateTeam: true
      }));
    }, 500);
  }
  handlerAddNewMember (event) {
    event.preventDefault();
    let that = this;
    this.setState(prevState => ({
      modalDisabled: true,
      addNewMember: false
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalCreateTeam: false,
        modalGetTeam: false,
        modalGetUsers: false,
        modalDisabled: false
      }));
    }, 500);
    setTimeout(() => {
      this.setState(prevState => ({
        modalGetUsers: true,
        addNewMember: true
      }));
    }, 500);
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/teams/search/users`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoadedUser: true,
            errorUsers: data.errors
          })
        });
      } else {
        res.json().then(function(data) {
          that.setState({
            isLoadedUser: true,
            users: data
          })
        });
          setTimeout(() => {
            let thumb = document.querySelector('.thumb-vertical');
            if(thumb){
              let thumbHeight = thumb.clientHeight;
              that.setState(prevState => ({
                heightThumb: thumbHeight
              }));
            }
          }, 500)
      }
    })
  })
  }
  addMembersByCreateTeam (user, event) {
    event.preventDefault();
    this.setState(prevState => ({
      addMembersByCreateTeam: prevState.addMembersByCreateTeam.indexOf(user) !== -1 ? prevState.addMembersByCreateTeam.filter(el => el !== user) : [...prevState.addMembersByCreateTeam, user]
    }));
  }
  removeMember (id, index, event) {
    event.preventDefault();
    let that = this;
    this.setState(prevState => ({
      isRemoveMemberLoading: true,
      removeMemberIndex: index
    }));
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/teams/${that.state.updateId}/unassign`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "userID": id
      })
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isRemoveMemberLoading: false,
            removeMemberIndex: null,
            errors: data.errors
          })
        });
      } else {
          that.setState(prevState => ({
            isRemoveMemberLoading: false,
            removeMemberIndex: null,
            teamMembers: prevState.teamMembers.filter(el => el.userID !== id)
          }));
      }
    })
  })
  }
  handlerCloseSuccess (event) {
    event.preventDefault();
    this.setState(prevState => ({
      successSubmit: false,
      submitLabel: null
    }));
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerPaginationItems = (userItems, current) => {
    this.setState({
      teamsList: userItems,
      currentPage: current
    });
  }
  handlerRemoveUserTeam(id){
    let membersArr = this.state.teamMembers;
    let memberIndex = membersArr.indexOf(id);
    let usersArr = this.state.getUsersIds;
    let userIndex = usersArr.findIndex(x => x.userID === id);
    if (memberIndex !== -1) {
        membersArr.splice(memberIndex, 1);
    }
    if (userIndex !== -1) {
        usersArr.splice(userIndex, 1);
    }
    this.setState({
      teamMembers: membersArr,
      getUsersIds: usersArr
    });
  }
  handlerSearchUsers(event) {
    let arr = this.state.users;
    let newArr = [];
    this.setState({
      searchValue: event.target.value,
      usersSearch: []
    });
    let that = this;
    arr.map(function(item) {
      item.users.map(function(users) {
        if (users.name.includes(event.target.value)) {
           newArr.push(users);
          that.setState(prevState => ({
            usersSearch: [...prevState.usersSearch, users]
          }));
        }
      })
    });
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.title = this.props.getTranslate('employees-ListTeamTitle') + ' | ' + this.props.companyName;
    }
    let users = '';
    if(this.state.errorUsers) {
      users = <div>Ошибка...</div>;
    } else if(!this.state.isLoadedUser) {
      users = <Loading />;
    } else {
      users = this.state.searchValue ? <div className="team-list-modal">
        {this.state.usersSearch.map((elem, index) => {
          let active = this.state.getUsersIds.findIndex(x => x.userID === elem.userID) !== -1 ? true : false;
            return <div className={`${active ? 'users-modal__item active' : 'users-modal__item'}`} key={index} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID, elem.photo)}>
                 <div className="users-modal__info">
                   <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                   <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                 </div>
                 {active ? <div className="users-modal__check-wrap">
                   <svg className="users-modal__check">
                     <use href={`${sprite}#check-icon`}></use>
                   </svg>
                 </div> : ''}
               </div>
        })}
      </div> : <Scrollbars
      renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
      renderTrackVertical={props => <div {...props} className={`track-vertical ${this.state.heightThumb > 0 ? 'track-vertical__active' : ''}`} />}
      renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
      renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
      renderView={props => <div {...props} className="view"/>}
        autoHeight
        autoHeightMin={0}
        autoHeightMax={500}
        ><div className={`team-list-modal ${this.state.heightThumb > 0 ? 'team-list-modal__thumb' : ''}`}>
        {this.state.users.map((item, index) => {
         return item.users.length > 0 ? <div key={index}>
         <div className="team-list-modal__label">{item.name ? item.name : this.props.getTranslate('employees-AddUserTeamTitle')}</div>
          {item.users.map((elem, i) => {
            let active = this.state.getUsersIds.findIndex(x => x.userID === elem.userID) !== -1 ? true : false;
              return <div className={`${active ? 'users-modal__item active' : 'users-modal__item'}`} key={i} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID, elem.photo)}>
                   <div className="users-modal__info">
                     <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                     <div className="users-modal__name">{elem.name ? elem.name : ''}{elem.position ? <div className="users-modal__name-position">{elem.position}</div> : ''}</div>
                   </div>
                   {active ? <div className="users-modal__check-wrap">
                     <svg className="users-modal__check">
                       <use href={`${sprite}#check-icon`}></use>
                     </svg>
                   </div> : ''}
                 </div>
          })}
        </div> : ''})}
       </div></Scrollbars>;
    }
    let resultTeams = '';
    if(this.state.error) {
      resultTeams = <div>Ошибка...</div>;
    } else if(!this.state.isLoadedTeams) {
      resultTeams = <Loading />;
    } else {
      resultTeams = <div className="account-wgt account-wgt__users">

            {/* Table component */}
              <Table
                nameWidth="75%"
                header={[
                  this.props.getTranslate('employees-TeamsNameTitle'),
                  this.props.getTranslate('employees-TeamsCountTitle'),
                  ''
                ]}
                bodyData={this.state.teamsList}
                bodyDataNames={['name', 'users']}
                controlItemsStatus={true}
                controlItems={{
                  'enabled': false,
                  'editButton': {
                    'status': true,
                    'handler': this.handlerGetTeam.bind(this),
                    'label': this.props.getTranslate('tableDropdown-EditLabel')
                  },
                  'editLink': false,
                  'delete': {
                    'status': true,
                    'handler': this.handlerDeleteTeamModal.bind(this),
                    'label': this.props.getTranslate('tableDropdown-EditRemove')
                  }
                }}
                itemIDName={'teamID'}
                removeIndex={this.state.removeIndex}
              />

      </div>
    }
    return <>

      {/* Error handler */}
      <ResultErrors
        errors={this.state.errors}
        closeHandler={this.handlerCloseError.bind(this)}
      />

      {/* Query result handler */}
      <ResultQuery
        success={this.state.successSubmit}
        resultText={this.state.submitLabel}
        handlerSuccess={this.handlerCloseSuccess.bind(this)}
        handlerError={this.handlerCloseError}
      />

      {/* Header component */}
      <AccountHeader
        queryLink={this.props.queryLink}
        status={this.state.mobileMenu}
        statusStart={this.state.mobileMenuStart}
        handler={this.handlerMobileMenu}
        getTranslate={this.props.getTranslate.bind(this)}
        completed={this.props.completed}
      />

    <div className="account-wrap">

      {/* Navigation component */}
      <AccountNav
        getPermalink={this.props.getPermalink}
        queryLink={this.props.queryLink}
        configsApp={this.props.configsApp}
        newReview={this.props.newReview}
        companyName={this.props.companyName}
        token={this.props.token()}
        status={this.state.mobileMenu}
        statusStart={this.state.mobileMenuStart}
        handler={this.handlerMobileMenu}
        dropdownStatus={this.state.dropdownStatus}
        dropdownHandler={this.handlerDropdown}
        getTranslate={this.props.getTranslate}
        completed={this.props.completed}
      />

      <div className="account-content">
        <div className="account-content__inner">

          {/* Account header component */}
          <AccountContentHeader
            title={this.props.getTranslate('employees-ListTeamTitle')}
            handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
            languageActive={this.props.chooseLanguage}
          />

          {/* Tabs component */}
          <TabItems items={[
            {
              label: this.props.getTranslate('employees-TabList'),
              path: this.props.getPermalink("/account/users"),
              amount: this.state.totalItemUsers
            },
            {
              label: this.props.getTranslate('employees-TabTeams'),
              path: this.props.getPermalink("/account/teams"),
              amount: this.state.totalItems
            }
          ]} />

          <div className="tab-navs">
            <div className="tab-navs__inner">
            <button className="btn btn-header btn_with_icon team-nav__btn-add-team" type="button" onClick={this.handlerCreateTeamModal.bind(this)}>
              <span className="btn-header__inner">
                <svg className="btn__icon-add">
                  <use href={`${sprite}#plus-icon`}></use>
                </svg>{this.props.getTranslate('employees-AddTeamTitle')}
              </span>
              <svg className="btn-header__icon-mob">
                <use href={`${sprite}#add-team-icon`}></use>
              </svg>
            </button>
            <Link className="btn btn-header btn_theme_secondary team-nav__btn" to={this.props.getPermalink("/account/deposits/create/")}><span className="btn-header__inner">{this.props.getTranslate('employees-ListAddBonusButton')}</span>              <svg className="btn-header__icon-mob">
                            <use href={`${sprite}#add-bonuses-icon`}></use>
                          </svg></Link>
                          </div>
            {/* Filter search input component */}
            <FilterSearch
              placeholder={this.props.getTranslate('teamsSearch-Placeholder')}
              value={this.state.searchTeamValue}
              handler={this.handlerSearch.bind(this)}
            />
          </div>

          <div className="account-content__content">

              {/* Query result */}
              {resultTeams}

          </div>

          <div className="account-content__footer">

            {/* Pagination component */}
            <Pagination
              total={this.state.totalPages}
              perPage="20"
              path={`${this.props.queryLink}/api/v1/teams/search`}
              currentPage={this.state.currentPage}
              items={this.handlerPaginationItems}
            />

          </div>
        </div>
      </div>
    </div>
    {this.state.modalCreateTeam ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.state.updateId ? this.props.getTranslate('employees-EditTeamTitle') : this.props.getTranslate('employees-AddTeamTitle')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <form className="add-user-form" onSubmit={this.state.updateId ? this.handlerUpdateTeam.bind(this) : this.handlerCreateTeamSubmit.bind(this)}>
         <div className="field-wrap">
           <input className={`field-wrap__input ${(this.state.errorFields && !this.state.teamName) || (this.state.errorFields && this.state.errorOnlySpecial && this.state.teamName !== '') ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.teamName} onChange={this.handlerTeamName.bind(this)} placeholder={this.props.getTranslate('employees-AddTeamModalNamePlaceholder')} />
           {this.state.errorFields && this.state.teamName === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
           {this.state.errorFields && this.state.errorOnlySpecial && this.state.teamName !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpecialField')}</div> : ''}
         </div>
         <button className="btn btn_with_icon btn_size_lg btn_width_100 btn_theme_secondary" type="button" onClick={this.handlerAddNewMember.bind(this)}>
         <svg className="btn__icon-add">
           <use href={`${sprite}#plus-icon`}></use>
         </svg>{this.props.getTranslate('employees-AddTeamModalBtnAddUser')}</button>
          {this.state.getUsersIds.length > 0 ? this.state.getUsersIds.map((item, index) => {
            return <div className="users-modal__item" key={index}>
                 <div className="users-modal__info">
                   <img className="users-modal__avatar" src={item.photo ? item.photo : member_1} alt="" />
                   <div className="users-modal__name">{item.name ? item.name : ''}</div>
                 </div>
                 <button className="team-table__nav-btn" type="button" onClick={this.handlerRemoveUserTeam.bind(this, item.userID)}>
                   <svg>
                     <use href={`${sprite}#delete`}></use>
                   </svg>
                 </button>
               </div>
          }) : ''}
           <div className="field-wrap__submit">
             <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="submit" disabled={this.state.isLoadedSubmitTeam ? true : false}>{this.props.getTranslate('employees-EditTeamModalAddBtnSubmit')}{this.state.isLoadedSubmitTeam && <Loading />}</button>
           </div>
        </form>
      </div>
    </div> : ''}
    {this.state.modalGetUsers ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.props.getTranslate('employees-AddUserTitle')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <div className="field-wrap field-wrap__search-members">
          <input className="field-wrap__input field-wrap__input_bg" type="text" placeholder={this.props.getTranslate('employees-AddUserSearchFieldCaption')} value={this.state.searchValue} onChange={this.handlerSearchUsers.bind(this)} />
        </div>
        <div className="question-members">
          <div className="question-members-wgt">
            {users}
          </div>
            <div className="field-wrap__submit">
              <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="button" onClick={this.handlerAddUserIds.bind(this)}>{this.props.getTranslate('employees-AddUserAddButton')}</button>
            </div>
        </div>
      </div>
    </div> : ''}
    {this.state.modalGetTeam ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.props.getTranslate('employees-EditUserTeam')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <form className="add-user-form" onSubmit={this.handlerUpdateTeam.bind(this)}>
         <div className="field-wrap">
           <input className={`field-wrap__input ${this.state.errorFields && !this.state.teamName ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.teamName} onChange={this.handlerTeamName.bind(this)} placeholder={this.props.getTranslate('employees-AddTeamNameFiledCaption')} />
           {this.state.errorFields && !this.state.teamName ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
         </div>
         <button className="btn btn_with_icon btn_size_lg btn_width_100 btn_theme_secondary" type="button" onClick={this.handlerAddNewMember.bind(this)}>
         <svg className="btn__icon-add">
           <use href={`${sprite}#plus-icon`}></use>
         </svg>{this.props.getTranslate('employees-AddTeamModalBtnAddUser')}</button>
         {this.state.addNewMember ? users : ''}
         {this.state.teamMembers.length > 0 ? <div className="team-list-modal">
          <label className="team-list-modal__label">{this.props.getTranslate('employees-AddTeamUserList')}</label>
          {this.state.teamMembers.map((item, index) => <div className="team-list-modal__item" key={index}>
            <div className="team-list-modal__info">
              <img className="team-list-modal__avatar-member" src={item.photo ? item.photo : member_3} alt="" />
              <div className="team-list-modal__name-member">{item.name}{item.position ? <div className="team-list-modal__name-member-position">{item.position}</div> : ''}</div>
            </div>
            {this.state.isRemoveMemberLoading && this.state.removeMemberIndex === index ? <Loading /> : <button className="team-table__nav-btn" type="button" onClick={this.removeMember.bind(this, item.userID, index)}>
              <svg>
                <use href={`${sprite}#delete`}></use>
              </svg>
            </button>}
          </div>)}
         </div> : ''}
          <div className="field-wrap__submit">
            <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="submit" disabled={this.state.isLoadedSubmitTeam ? true : false}>{this.props.getTranslate('employees-EditTeamModalAddBtnSubmit')}{this.state.isLoadedSubmitTeam && <Loading />}</button>
          </div>
        </form>
      </div>
    </div> : ''}

    {/* Delete item modal */}
    <DeleteModal
      modalStatus={this.state.modalDeleteTeam}
      modalDisabledStatus={this.state.modalDisabled}
      modalDisabledClass={this.state.addClass}
      handlerModalDisabled={this.handleModalDisabled}
      modalTitle={this.props.getTranslate('removeModal-teamTitle')}
      modalDescription={this.props.getTranslate('removeModal-teamDescriptionBefore') + ' ' + this.state.teamNameModal + ' ' + this.props.getTranslate('removeModal-teamDescriptionAfter')}
      modalHandlerDelete={this.handlerRemoveTeam.bind(this)}
      modalHandlerBtnTxt={this.props.getTranslate('removeModal-ButtonSubmit')}
      modalHandlerBtnLoading={this.state.loadingDeleteSumbit}
    />

    </>;
  }
}
export default Teams;
