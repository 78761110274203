import React from "react";
import { Link } from "react-router-dom";
import avatar_7 from '../../media/bitmap-avatar.png';
import sprite from '../../media/icons.svg';
import google_play from '../../media/google-play-btn.png';
import apple_store from '../../media/apple-store-btn.png';
import apps_img_1 from '../../media/apps_img_1.png';
import cards_possibilities_event from '../../media/cards-possibilities-event.png';
import cards_possibilities_shop from '../../media/cards-possibilities-shop.png';
import icon_umbrella from '../../media/icon_umbrella.png';
import icon_progressbar from '../../media/icon_progressbar.png';
import icon_survey from '../../media/icon_survey.png';
import ratingStar from '../../media/star-rating.svg';
import img_about_card from '../../media/img-about-card.png';
import bicycle from '../../media/bicycle.png';
import ticket from '../../media/ticket.png';
import app_index_slider_1 from '../../media/app-index-slider-1.png';
import app_index_slider_2 from '../../media/app-index-slider-2.png';
import app_index_slider_3 from '../../media/app-index-slider-3.png';
import app_index_slider_4 from '../../media/app-index-slider-4.png';
import app_index_slider_5 from '../../media/app-index-slider-5.png';
import app_index_slider_6 from '../../media/app-index-slider-6.png';
import app_index_slider_7 from '../../media/app-index-slider-7.png';
import star from '../../media/star.svg';
import users from '../../media/users.png';
class HomePossibilities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isElementInView: false,
      appActive: 0,
      appActiveMob: 0,
      showImage: true,
      tagsArr: [
            {
              status: true,
              img: app_index_slider_1
            },
            {
              status: false,
              img: app_index_slider_2
            },
            {
              status: false,
              img: app_index_slider_3
            },
            {
              status: false,
              img: app_index_slider_4
            },
            {
              status: false,
              img: app_index_slider_5
            },
            {
              status: false,
              img: app_index_slider_6
            },
            {
              status: false,
              img: app_index_slider_7
            },
          ]
    };
    this.elementRef = React.createRef();
  }
  handleIntersection = (entries) => {
  const element = this.targetElementRef.current;
  if (!element) return;

  const isInView = entries[0].isIntersecting;
  this.setState({
    isElementInView: isInView,
  });
};
  componentDidMount() {
    this.startAutoSwitching();
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Порог видимости элемента, от 0 до 1
    };

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        // Когда элемент пересекает порог видимости
        if (entry.isIntersecting) {
          this.setState({ isElementInView: true });
          // Отключаем наблюдение, если нужно сработать только один раз
          this.observer.unobserve(entry.target);
        }
      });
    }, options);

    // Начинаем наблюдение за элементом
    if (this.elementRef.current) {
      this.observer.observe(this.elementRef.current);
    }

  }

  componentWillUnmount() {
    this.stopAutoSwitching();
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  startAutoSwitching = () => {
    this.interval = setInterval(() => {
      const tagsArr = this.state.tagsArr;
      const index = (this.state.appActive + 1) % tagsArr.length;
      tagsArr[this.state.appActive].status = false;
      tagsArr[index].status = true;
      this.setState({
        appActive: index,
        showImage: false,
        tagsArr: tagsArr
      });
      setTimeout(() => {
        this.setState({
          showImage: true,
        });
      }, 300);
    }, 5000);
  };

  stopAutoSwitching = () => {
    clearInterval(this.interval);
  };

  handerGetTag = (index, event) => {
    const tagsArr = this.state.tagsArr;
      this.stopAutoSwitching();
      tagsArr[this.state.appActive].status = false;
      tagsArr[index].status = true;
      this.setState(
        {
          appActiveMob: index,
          appActive: index,
          showImage: false
        },
        () => {
          setTimeout(() => {
            this.setState({
              showImage: true,
            });
          }, 100);
          this.startAutoSwitching();
        }
      );
  };
  render() {
    let tagHeadlines = [
      this.props.getTranslate('homePossibilities-TagBonuses'),
      this.props.getTranslate('homePossibilities-TagLoyalty'),
      this.props.getTranslate('homePossibilities-TagStore'),
      this.props.getTranslate('homePossibilities-TagMarketplace'),
      this.props.getTranslate('homePossibilities-TagEvents'),
      this.props.getTranslate('homePossibilities-TagSurveys'),
      this.props.getTranslate('homePossibilities-TagLibrary')
    ];
    return <section className="home-possibilities" ref={this.elementRef}>
      <div className="wrapper">
        <div className="home-possibilities__inner">
          <div className="home-possibilities__col">
            <div className="home-possibilities__info">
              <div className="main-headline-wrap">
                <h2 className="main-headline">{this.props.getTranslate('homePossibilities-Title')}</h2>
              </div>
              <div className="home-possibilities__description">
                <p>{this.props.getTranslate('homePossibilities-Description')}</p>
              </div>
              <Link className="main-link" to="/about">{this.props.getTranslate('linkMore-Name')}
                <svg className="main-link__icon">
                  <use href={`${sprite}#link-chevron`}></use>
                </svg>
              </Link>
              {this.state.tagsArr ? <ul className="about-info-app__list about-info-app__list-home-possibilities">
                {this.state.tagsArr.map((item, index) => {
                  return                 <li className="about-info-app__list-item" key={index}>
                                    <button className={`about-info-app__list-btn${index === this.state.appActiveMob ? ' active' : ''}`} type="button" onClick={() => this.handerGetTag(index)}>
                                      <span>                      <svg className="about-info-app__list-btn-icon">
                                                              <use href={`${sprite}#arrow-right`}></use>
                                                            </svg></span>{tagHeadlines[index]}
                                    </button>
                                    <div className={`about-info-app__list-content${index === this.state.appActiveMob ? ' active' : ''}`}>
                                        <img
                                          src={this.state.tagsArr[index].img}
                                          alt={tagHeadlines[index]}
                                          className="about-info-app__list-item__thumbnail"
                                        />
                                    </div>
                                  </li>
                })}
              </ul> : ''}
            </div>
            {this.state.tagsArr ? <div className="home-possibilities__tags">
              {this.state.tagsArr.map((item, index) => {
                return <div className="home-possibilities__tag" key={index}>
                  <div
                    className={`home-possibilities__tag-inner ${
                      item.status ? 'active' : ''
                    }`}
                    onClick={() => this.handerGetTag(index)}
                  >
                    {tagHeadlines[index]}
                  </div>
                </div>
              })}
            </div> : ''}
            <div className="home-possibilities__apps">
              <div className="home-possibilities__app">
                <a href="#">
                  <img src={apple_store} alt="" />
                </a>
              </div>
              <div className="home-possibilities__app">
                <a href="#">
                  <img src={google_play} alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="home-possibilities__col home-possibilities__col-image">
            <div className="home-possibilities__image">
              {this.state.tagsArr.length > 0 ? <img
                src={this.state.tagsArr[this.state.appActive].img}
                alt={tagHeadlines[this.state.appActive]}
                className={`fade-in-out-image ${
                  this.state.showImage ? 'fade-in' : 'fade-out'
                }`}
              /> : ''}
            </div>
          </div>
        </div>
        <div className={`home-possibilities__cards${this.state.isElementInView ? ' home-possibilities__cards-view' : ''}`}>
          <div className="home-possibilities__cards-inner">
            <div className="home-possibilities__cards-left">
              <div className="home-possibilities__cards-left-top">
                <div className={`home-possibilities__cards-left-top-left${this.state.isElementInView ? ' home-possibilities-cards-wgt_animation-left' : ''}`}>
                  <div className="home-possibilities-cards-wgt">
                    <div className="home-possibilities-cards-wgt__inner">
                      <img className="home-possibilities-cards-wgt__event-img" src={cards_possibilities_shop} alt={this.props.getTranslate('homePossibilities-WgtFirstTitle')} />
                      <div className="home-possibilities-cards-wgt__content">
                        <div className="home-possibilities-cards-wgt__headline">{this.props.getTranslate('homePossibilities-WgtFirstTitle')}</div>
                        <div className="home-possibilities-cards-wgt__item home-possibilities-cards-wgt__item_gray">150 Bonuses</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="home-possibilities__cards-left-top-center">
                  <div className={`home-possibilities-cards-wgt home-possibilities-cards-wgt_h_50 home-possibilities-cards-wgt_h_50_left ${this.state.isElementInView ? ' home-possibilities-cards-wgt_h_50-left' : ''}`}>
                    <div className="home-possibilities-cards-wgt__inner home-possibilities-cards-wgt__inner-flex">
                      <img className="home-possibilities-cards-wgt__icon-umbrella" src={avatar_7} alt={this.props.getTranslate('homePossibilities-WgtSecondTitle')} />
                      <div className="home-possibilities-cards-wgt__content">
                        <div className="home-possibilities-cards-wgt__headline home-possibilities-cards-wgt__headline_lg">{this.props.getTranslate('homePossibilities-WgtSecondTitle')}</div>
                        <div className="home-possibilities-cards-wgt__item home-possibilities-cards-wgt__item_gray">{this.props.getTranslate('homePossibilities-WgtSecondDescription')}</div>
                      </div>
                    </div>
                  </div>
                  <div className="home-possibilities__cards-left-top-center-bottom home-possibilities-cards-wgt_h_50">
                    <div className={`home-possibilities-cards-wgt home-possibilities-cards-wgt-v2${this.state.isElementInView ? ' home-possibilities-cards-wgt-left-v2' : ''}`}>
                      <div className="home-possibilities-cards-wgt__inner home-possibilities-cards-wgt__inner-flex">
                        <div className="home-possibilities-cards-wgt__count">+80</div>
                        <div className="home-possibilities-cards-wgt__content">
                          <div className="home-possibilities-cards-wgt__headline home-possibilities-cards-wgt__headline_lg">{this.props.getTranslate('homePossibilities-WgtThirdTitle')}</div>
                        </div>
                      </div>
                    </div>
                    <div className={`home-possibilities-cards-wgt home-possibilities-cards-wgt-v3${this.state.isElementInView ? ' home-possibilities-cards-wgt-left-v3' : ''}`}>
                      <div className="home-possibilities-cards-wgt__inner home-possibilities-cards-wgt__inner-flex">
                        <div className="home-possibilities-cards-wgt__count">+50</div>
                        <div className="home-possibilities-cards-wgt__content">
                          <div className="home-possibilities-cards-wgt__headline home-possibilities-cards-wgt__headline_lg">{this.props.getTranslate('homePossibilities-WgtFourTitle')}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="home-possibilities__cards-left-top-right">
                  <div className={`home-possibilities-cards-wgt home-possibilities-cards-wgt-top-right${this.state.isElementInView ? ' home-possibilities-cards-wgt_top_right' : ''}`}>
                    <div className="home-possibilities-cards-wgt__inner">
                      <div className="home-possibilities-cards-wgt__headline home-possibilities-cards-wgt__headline_lg home-possibilities-cards-wgt__headline-flex">
                        <span>{this.props.getTranslate('homePossibilities-WgtFiveTitle')}</span>
                        <img className="home-possibilities-cards-wgt__icon-umbrella" src={icon_umbrella} alt={this.props.getTranslate('homePossibilities-WgtFiveTitle')} />
                      </div>
                      <div className="home-possibilities-cards-wgt__footer">
                        <div className="home-possibilities-cards-wgt__label">Insurance Group</div>
                        <div className="home-possibilities-cards-wgt__item">01.01.2023 – 31.01.2024</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-possibilities__cards-left-bottom">
                <div className={`home-possibilities-cards-wgt home-possibilities-cards-wgt_bottom_35${this.state.isElementInView ? ' home-possibilities-cards-wgt_bottom_35-left' : ''}`}>
                  <div className="home-possibilities-cards-wgt__inner home-possibilities-cards-wgt__progress-inner">
                    <div className="home-possibilities-cards-wgt__progress-content">
                      <div className="home-possibilities-cards-wgt__headline home-possibilities-cards-wgt__headline_lg">{this.props.getTranslate('homePossibilities-WgtSixTitle')}</div>
                      <div className="home-possibilities-cards-wgt__item home-possibilities-cards-wgt__item_gray">{this.props.getTranslate('homePossibilities-WgtSixDescription')}</div>
                    </div>
                    <div className="home-possibilities-cards-wgt__progress">
                      <img className="home-possibilities-cards-wgt__progress-img" src={icon_progressbar} alt={this.props.getTranslate('homePossibilities-WgtSixTitle')} />
                    </div>
                  </div>
                </div>
                <div className={`home-possibilities-cards-wgt home-possibilities-cards-wgt_bottom_20${this.state.isElementInView ? ' home-possibilities-cards-wgt_bottom_20-v1' : ''}`}>
                  <div className="home-possibilities-cards-wgt__inner">
                    <img className="home-possibilities-cards-wgt__event-img" src={bicycle} alt={this.props.getTranslate('homePossibilities-WgtSevenTitle')} />
                    <div className="home-possibilities-cards-wgt__content">
                      <div className="home-possibilities-cards-wgt__headline">{this.props.getTranslate('homePossibilities-WgtSevenTitle')}</div>
                      <div className="home-possibilities-cards-wgt__item home-possibilities-cards-wgt__item_gray">1 500 $</div>
                    </div>
                  </div>
                </div>
                <div className={`home-possibilities-cards-wgt home-possibilities-cards-wgt_bottom_25${this.state.isElementInView ? ' home-possibilities-cards-wgt_bottom_25-v2' : ''}`}>
                  <div className="home-possibilities-cards-wgt__inner">
                    <div className="home-possibilities-cards-wgt__survey-header">
                      <img className="home-possibilities-cards-wgt__survey-img" src={icon_survey} alt={this.props.getTranslate('homePossibilities-WgtEightTitle')} />
                      <div className="home-possibilities-cards-wgt__label">{this.props.getTranslate('homePossibilities-WgtEightDescription')}</div>
                    </div>
                    <div className="home-possibilities-cards-wgt__survey-content">
                      <div className="home-possibilities-cards-wgt__headline home-possibilities-cards-wgt__headline_lg">{this.props.getTranslate('homePossibilities-WgtEightTitle')}</div>
                      <ul className="home-possibilities-cards-wgt__survey-list">
                        <li>
                          <img src={ratingStar} alt="" />
                        </li>
                        <li>
                          <img src={ratingStar} alt="" />
                        </li>
                        <li>
                          <img src={ratingStar} alt="" />
                        </li>
                        <li>
                          <img src={ratingStar} alt="" />
                        </li>
                        <li>
                          <img src={star} alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={`home-possibilities-cards-wgt home-possibilities-cards-wgt_bottom_20${this.state.isElementInView ? ' home-possibilities-cards-wgt_bottom_20-v3' : ''}`}>
                  <div className="home-possibilities-cards-wgt__inner">
                    <img className="home-possibilities-cards-wgt__event-img" src={ticket} alt={this.props.getTranslate('homePossibilities-WgtNineTitle')} />
                    <div className="home-possibilities-cards-wgt__content">
                      <div className="home-possibilities-cards-wgt__headline">{this.props.getTranslate('homePossibilities-WgtNineTitle')}</div>
                      <div className="home-possibilities-cards-wgt__item home-possibilities-cards-wgt__item_gray">230 Bonuses</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-possibilities__cards-right">
              <div className={`home-possibilities-cards-wgt home-possibilities-cards-wgt__event home-possibilities-cards-wgt_animation_right${this.state.isElementInView ? ' home-possibilities-cards-wgt_animation-right' : ''}`}>
                <div className="home-possibilities-cards-wgt__inner">
                  <img className="home-possibilities-cards-wgt__event-img" src={cards_possibilities_event} alt={this.props.getTranslate('homePossibilities-WgtTenTitle')} />
                  <div className="home-possibilities-cards-wgt__content">
                    <div className="home-possibilities-cards-wgt__headline">{this.props.getTranslate('homePossibilities-WgtTenTitle')}</div>
                    <div className="home-possibilities-cards-wgt__item home-possibilities-cards-wgt__item-flex">
                      <span>18:00–24:00</span>
                      <span>{this.props.getTranslate('homePossibilities-WgtTenLabel')}</span>
                    </div>
                    <div className="home-possibilities-cards-wgt__item">300 Bonuses</div>
                  </div>
                  <div className="home-possibilities-cards-wgt__content">
                    <div className="home-possibilities-cards-wgt__headline">{this.props.getTranslate('homePossibilities-WgtElevenTitle')}</div>
                    <div className="home-possibilities-cards-wgt__event-list-users">
                      <img className="home-possibilities-cards-wgt__event-list-users-img" src={users} alt={this.props.getTranslate('homePossibilities-WgtElevenTitle')} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  }
}
export default HomePossibilities;
