import React from "react";
import { Link } from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import sprite from './media/icons.svg';
class Price extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeBasic: true,
      activeBussines: false,
      welcomeActive: true
    }
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      window.scrollTo(0, 0);
    }
  }
  handlerTabs(tab, event) {
    event.preventDefault();
    if(tab === 'basic'){
      this.setState({
        activeBasic: true,
        activeBussines: false
      })
    } else {
      this.setState({
        activeBasic: false,
        activeBussines: true
      })
    }
  }
  render() {
    let title = this.props.getTranslate('pricePage-Title');
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = title ? title : 'MateSpace.io';
      const metaDescription = document.querySelector('meta[name="description"]');
      let description = this.props.getTranslate('pricePage-Description');
      if (metaDescription) {
        metaDescription.setAttribute('content', description ? description : 'MateSpace.io');
      }
    }
    return <>
    {this.props.PageWithCanonical}
    <Header getPermalink={this.props.getPermalink} logged={this.props.logged} userPhoto={this.props.userPhoto} userName={this.props.userName} handlerChangeLanguage={this.props.handlerChangeLanguage} chooseLanguage={this.props.chooseLanguage} getTranslate={this.props.getTranslate} />
    <main>
      <section className="price">
        <div className="wrapper">
          <div className="price__header">
            <h1 className="price__headline" dangerouslySetInnerHTML={{__html: this.props.getTranslate('price-Title')}}></h1>
            <div className="price__description" dangerouslySetInnerHTML={{__html: this.props.getTranslate('price-SubTitle')}}></div>
          </div>
          {this.state.welcomeActive ? <div className="price__info">
            <div className={`price-wgt price-wgt__business ${this.state.activeBussines && 'active'}`}>
              <div className="price-wgt__inner">
                <div className="price-wgt__header">
                  <div className="price-wgt__header-inner">
                    <div className="price-wgt__headline">Enterprice</div>
                  </div>
                  <p className="price-wgt__description">{this.props.getTranslate('price-enterpriceDescription')}</p>
                </div>
                <div className="price-wgt__content">
                  <div className="price-wgt__label">{this.props.getTranslate('price-enterpriceLabel')}</div>
                  <ul className="price__list">
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff1st')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff2nd')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff3nd')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff4th')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff5th')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff6th')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff7th')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff8th')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff9th')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff10th')}
                    </li>
                  </ul>
                </div>
                <div className="price-wgt__footer">
                  <Link className="btn btn_width_100 btn_size_lg home-wgt__add-company-btn" to={this.props.getPermalink('/registration')}>{this.props.getTranslate('price-RegCompanyButton')}</Link>
                </div>
              </div>
            </div>
          </div> : <div className="price__info">
            <div className={`price-wgt price-wgt__basic ${this.state.activeBasic && 'active'}`}>
              <div className="price-wgt__inner">
                <div className="price-wgt__header">
                  <div className="price-wgt__header-inner">
                    <div className={`price-wgt__headline ${this.state.activeBasic && 'active'}`}>Start-Up</div>
                    <div className="price-wgt__headline price-wgt__headline-bussines" onClick={this.handlerTabs.bind(this, 'bussines')}>Start-Up</div>
                  </div>
                  <p className="price-wgt__description">{this.props.getTranslate('price-startupDescription')}</p>
                </div>
                <div className="price-wgt__content">
                  <div className="price-wgt__label">{this.props.getTranslate('price-startupLabel')}</div>
                  <ul className="price__list">
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff1st')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff2nd')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff3nd')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff4th')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff5th')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff6th')}
                    </li>
                    <li className="price__disabled">
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff7th')}
                    </li>
                    <li className="price__disabled">
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff8th')}
                    </li>
                    <li className="price__disabled">
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff9th')}
                    </li>
                    <li className="price__disabled">
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff10th')}
                    </li>
                  </ul>
                </div>
                <div className="price-wgt__footer">
                  <Link className="btn btn_width_100 btn_size_lg home-wgt__add-company-btn" to={this.props.getPermalink('/registration')}>{this.props.getTranslate('price-RegCompanyButton')}</Link>
                </div>
              </div>
            </div>
            <div className={`price-wgt price-wgt_center price-wgt__basic ${this.state.activeBasic && 'active'}`}>
              <div className="price-wgt__inner">
                <div className="price-wgt__header">
                  <div className="price-wgt__header-inner">
                    <div className={`price-wgt__headline ${this.state.activeBasic && 'active'}`}>Bussines</div>
                    <div className="price-wgt__headline price-wgt__headline-bussines" onClick={this.handlerTabs.bind(this, 'bussines')}>Bussines</div>
                  </div>
                  <p className="price-wgt__description">{this.props.getTranslate('price-bussinesDescription')}</p>
                </div>
                <div className="price-wgt__content">
                  <div className="price-wgt__label">{this.props.getTranslate('price-bussinesLabel')}</div>
                  <ul className="price__list">
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff1st')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff2nd')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff3nd')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff4th')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff5th')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff6th')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff7th')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff8th')}
                    </li>
                    <li className="price__disabled">
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff9th')}
                    </li>
                    <li className="price__disabled">
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff10th')}
                    </li>
                  </ul>
                </div>
                <div className="price-wgt__footer">
                  <Link className="btn btn_width_100 btn_size_lg home-wgt__add-company-btn" to={this.props.getPermalink('/registration')}>{this.props.getTranslate('price-RegCompanyButton')}</Link>
                </div>
              </div>
            </div>
            <div className={`price-wgt price-wgt__business ${this.state.activeBussines && 'active'}`}>
              <div className="price-wgt__inner">
                <div className="price-wgt__header">
                  <div className="price-wgt__header-inner">
                    <div className="price-wgt__headline price-wgt__headline-basic" onClick={this.handlerTabs.bind(this, 'basic')}>Enterprice</div>
                    <div className={`price-wgt__headline ${this.state.activeBussines && 'active'}`}>Enterprice</div>
                  </div>
                  <p className="price-wgt__description">{this.props.getTranslate('price-enterpriceDescription')}</p>
                </div>
                <div className="price-wgt__content">
                  <div className="price-wgt__label">{this.props.getTranslate('price-enterpriceLabel')}</div>
                  <ul className="price__list">
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff1st')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff2nd')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff3nd')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff4th')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff5th')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff6th')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff7th')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff8th')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff9th')}
                    </li>
                    <li>
                      <span className="price__icon-wrap">
                        <svg className="price__icon">
                          <use href={`${sprite}#check-icon`}></use>
                        </svg>
                      </span>{this.props.getTranslate('price-FirstTariff10th')}
                    </li>
                  </ul>
                </div>
                <div className="price-wgt__footer">
                  <Link className="btn btn_width_100 btn_size_lg home-wgt__add-company-btn" to={this.props.getPermalink('/registration')}>{this.props.getTranslate('price-RegCompanyButton')}</Link>
                </div>
              </div>
            </div>
          </div>}
        </div>
      </section>
    </main>
    <Footer getPermalink={this.props.getPermalink} getTranslate={this.props.getTranslate} />
    </>;
  }
}
export default Price;
