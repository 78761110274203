import React from "react";
import { Link, useParams } from "react-router-dom";
import DateFormating from './components/DateFormating';
import AccountHeader from './components/AccountHeader';
import Navigation from './components/Navigation';
import Loading from '../components/Loading';
import sprite from './media/icons.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
class ControlPanelCompanyUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      modalDisabled: false,
      modal: false,
      isLoaded: false,
      errors: null,
      companyData: null,
      configData: {}
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modal: false,
        removeIndex: null,
      }));
    }, 500);
  }
  componentDidMount() {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/bo/v1/companies/${that.props.id}`, {
        method: 'GET',
        headers: myHeaders
      }).then(companyRes => {
        companyRes.json().then(function(companyData) {
          that.setState({
            isLoaded: true,
            companyData: companyData
          });
        });

        fetch(`${that.props.queryLink}/bo/v1/companies/${that.props.id}/config`, {
          method: 'GET',
          headers: myHeaders
        }).then(configRes => {
          configRes.json().then(function(configData) {
            console.log(configData);
            that.setState({
              configData: configData
            });
          });
        }).catch(function(error) {
          that.setState({
            isLoaded: true,
            errors: [error.message]
          });
        })

      }).catch(function(error) {
        that.setState({
          isLoaded: true,
          errors: [error.message]
        });
      })
    });
  }

hanlderConfig(config, event) {
  let that = this;
  let companyConfig = that.state.configData;
  companyConfig[config] = !companyConfig[config];
  event.preventDefault();
  getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/companies/${that.props.id}/config`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(companyConfig)
    }).then(companyRes => {
      companyRes.json().then(function(companyData) {
        that.setState({
          configData: companyConfig
        });
      });
    }).catch(function(error) {
      that.setState({
        isLoaded: true,
        errors: [error.message]
      });
    })
  });
}

handlerImpersonate(event){
  let that = this;
  getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/companies/${that.props.id}/impersonate `, {
      method: 'GET',
      headers: myHeaders
    }).then(impersonateRes => {
      impersonateRes.json().then(function(impersonateData) {
        console.log(impersonateData);
      });
    }).catch(function(error) {
      that.setState({
        isLoaded: true,
        errors: [error.message]
      });
    })
  });
}

handlerStatus(event){
  let that = this;
  let companyData = that.state.companyData;
  let status = companyData.status;
  getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/companies/${that.props.id}/status `, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        "status": status === 'ACTIVE' ? 'DEACTIVATED' : 'ACTIVE'
      })
    }).then(statusRes => {
      if(statusRes.status === 200){
        if(status === 'ACTIVE'){
          companyData.status = 'DEACTIVATED';
        } else {
          companyData.status = 'ACTIVE';
        }
        that.setState({
          companyData: companyData
        });
      }
    }).catch(function(error) {
      that.setState({
        isLoaded: true,
        errors: [error.message]
      });
    })
  });
}

  render() {
    let statusCompany = null;
    let statusCompanyClass = null;
    if(this.state.companyData){
      if(this.state.companyData.status === 'ACTIVE'){
        statusCompany = 'Активна';
        statusCompanyClass = ' bo-dashboard-content__statistics-value-active';
      } else {
        statusCompany = 'Не активна';
        statusCompanyClass = ' bo-dashboard-content__statistics-value-noactive';
      }
    }
    let result = '';
    if(this.state.error) {
      result = <div>Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      result = <Loading />;
    } else {
      result = <>
      <div className="account-wgt account-content__wgt account-content__wgt-company account-content__wgt_n_b">
        <div className="account-content__nav-company">
          <button className="btn" type="button" onClick={this.handlerImpersonate.bind(this)}>Увійти як менеджер</button>
          <button className="btn btn_theme_remove btn_company_status" type="button" onClick={this.handlerStatus.bind(this)}>
            {this.state.companyData.status === 'ACTIVE' ? <><svg className="btn_company_status-icon">
              <use href={`${sprite}#hide-icon`}></use>
            </svg>
            Заблокувати</> : <><svg className="btn_company_status-icon">
              <use href={`${sprite}#show-icon`}></use>
            </svg>
            Розблокувати</>}
          </button>
        </div>
      </div>
  <div className="account-wgt account-content__wgt account-content__wgt-company account-content__wgt_n_b">
    <div className="account-wgt__inner">
      {this.state.companyData && this.state.companyData.name ? <div className="account-item account-wgt__item">
        <div className="account-item__headline">Назва компанії</div>
        <div className="account-item__value">{this.state.companyData.name}</div>
      </div> : ''}
      {this.state.companyData && this.state.companyData.creationDate ? <div className="account-item account-wgt__item">
        <div className="account-item__headline">Дата створення</div>
        <div className="account-item__value"><DateFormating start={this.state.companyData.creationDate} time="off" /></div>
      </div> : ''}
      {this.state.companyData && (this.state.companyData.ownerName || this.state.companyData.ownerEmail) ? <div className="account-item account-wgt__item">
        <div className="account-item__headline">Власник аккаунта</div>
        <div className="account-item__value">{this.state.companyData.ownerName ? this.state.companyData.ownerName + ' ' : ''}{this.state.companyData.ownerEmail ? '(' + this.state.companyData.ownerEmail + ')' : ''}</div>
      </div> : ''}
    </div>
    {this.state.companyData ? <div className="bo-dashboard-content__statistics">
      <div className="bo-dashboard-content__statistics-inner">
        <div className="bo-dashboard-content__statistics-wgt">
          <div className="bo-dashboard-content__statistics-wgt-inner">
            <div className="bo-dashboard-content__statistics-value">{this.state.companyData.balance ? this.state.companyData.balance : 0} {this.state.companyData.currency ? this.state.companyData.currency : ''}</div>
            <div className="bo-dashboard-content__statistics-name">Баланс</div>
          </div>
        </div>
        <div className="bo-dashboard-content__statistics-wgt">
          <div className="bo-dashboard-content__statistics-wgt-inner">
            <div className="bo-dashboard-content__statistics-value">
              <Link className="bo-dashboard-content__statistics-value-link" to={`/controlpanel/companies/${this.state.companyData.companyID}/users`}>{this.state.companyData.employeeCount ? this.state.companyData.employeeCount : 0}</Link>
            </div>
            <div className="bo-dashboard-content__statistics-name">Юзери</div>
          </div>
        </div>
        <div className="bo-dashboard-content__statistics-wgt">
          <div className="bo-dashboard-content__statistics-wgt-inner">
            <div className="bo-dashboard-content__statistics-value">{this.state.companyData.subscription}</div>
            <div className="bo-dashboard-content__statistics-name">Тариф</div>
          </div>
        </div>
        <div className="bo-dashboard-content__statistics-wgt">
          <div className="bo-dashboard-content__statistics-wgt-inner">
            <div className={`bo-dashboard-content__statistics-value${statusCompanyClass}`}>{statusCompany}</div>
            <div className="bo-dashboard-content__statistics-name">Статус</div>
          </div>
        </div>
      </div>
    </div> : ''}
  </div>
  {this.state.configData ? <div className="bo-services-content bo-services-content_company">
    <div className="bo-services-content__inner">
      <div className="bo-services-content-item">
        <div className="bo-services-content-item__inner">
          <div className="bo-services-content-item__headline">Магазин</div>
          <div className="tg-list-item">
            <input className="tgl tgl-light" id="service-1" type="checkbox" checked={this.state.configData.shop} onChange={this.hanlderConfig.bind(this, 'shop')} />
            <label className="tgl-btn" htmlFor="service-1"></label>
          </div>
        </div>
      </div>
      <div className="bo-services-content-item">
        <div className="bo-services-content-item__inner">
          <div className="bo-services-content-item__headline">Події</div>
          <div className="tg-list-item">
            <input className="tgl tgl-light" id="service-2" type="checkbox" checked={this.state.configData.event} onChange={this.hanlderConfig.bind(this, 'event')} />
            <label className="tgl-btn" htmlFor="service-2"></label>
          </div>
        </div>
      </div>
      <div className="bo-services-content-item">
        <div className="bo-services-content-item__inner">
          <div className="bo-services-content-item__headline">Опитування</div>
          <div className="tg-list-item">
            <input className="tgl tgl-light" id="service-3" type="checkbox" checked={this.state.configData.survey} onChange={this.hanlderConfig.bind(this, 'survey')} />
            <label className="tgl-btn" htmlFor="service-3"></label>
          </div>
        </div>
      </div>
      <div className="bo-services-content-item">
        <div className="bo-services-content-item__inner">
          <div className="bo-services-content-item__headline">Бенефіти</div>
          <div className="tg-list-item">
            <input className="tgl tgl-light" id="service-4" type="checkbox" checked={this.state.configData.reward} onChange={this.hanlderConfig.bind(this, 'reward')} />
            <label className="tgl-btn" htmlFor="service-4"></label>
          </div>
        </div>
      </div>
      <div className="bo-services-content-item">
        <div className="bo-services-content-item__inner">
          <div className="bo-services-content-item__headline">Маркетплейс</div>
          <div className="tg-list-item">
            <input className="tgl tgl-light" id="service-5" type="checkbox" checked={this.state.configData.ad} onChange={this.hanlderConfig.bind(this, 'ad')} />
            <label className="tgl-btn" htmlFor="service-5"></label>
          </div>
        </div>
      </div>
      <div className="bo-services-content-item">
        <div className="bo-services-content-item__inner">
          <div className="bo-services-content-item__headline">Бібліотека</div>
          <div className="tg-list-item">
            <input className="tgl tgl-light" id="service-6" type="checkbox" checked={this.state.configData.library} onChange={this.hanlderConfig.bind(this, 'library')} />
            <label className="tgl-btn" htmlFor="service-6"></label>
          </div>
        </div>
      </div>
    </div>
  </div> : ''}
</>;

    }

    return <>
      <AccountHeader status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
      <div className="account-wrap account-wrap__admin">
        <Navigation status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} replace={true} />
        <div className="account-content">
          <div className="account-content__inner">
            <div className="account-content__header">
              <div className="account-content__header-inner">
                {this.state.isLoaded ? <h1 className="account-content__headline">{this.state.companyData && this.state.companyData.name ? this.state.companyData.name : 'Компанія'}</h1> : ''}
              </div>
              <a className="header__language header__language-content" href="#">RU</a>
            </div>
            <div className="account-content__content account-content__content-company">

              {/* Query result */}
              {result}

            </div>

          </div>
        </div>
      </div>
    </>
  }
}
export default (props) => <ControlPanelCompanyUsers {...useParams()} {...props} />
