import React from "react";
class ServiceInfoWgt extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <div className={`service-info-wgt ${this.props.reverse ? 'reverse' : ''}`}>
      <div className="service-info-wgt__inner">
        <div className="service-info-wgt__col service-info-wgt__col-image">
          <div className="service-info-wgt__image">
            <img src={this.props.img} alt="" />
          </div>
        </div>
        <div className="service-info-wgt__col service-info-wgt__col-content">
          <div className="service-info-wgt__headline-wrap">
            <h3 className="service-info-wgt__headline">{this.props.headline}</h3>
          </div>
          <div className="service-info-wgt__description-wrap">
            <p>{this.props.description}</p>
          </div>
        </div>
      </div>
    </div>
  }
}
export default ServiceInfoWgt;
