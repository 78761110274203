import React from "react";
import { Link } from "react-router-dom";
class ServiceFunctionWgt extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <div className="service-function-wgt">
      <div className="service-function-wgt__inner">
        <div className="service-function-wgt__col">
          <div className="service-function-wgt__headline-wrap">
            <h3 className="service-function-wgt__headline">{this.props.headline}</h3>
          </div>
          <div className="service-function-wgt__thumb-mob">
            <img src={this.props.thumb} alt="" />
          </div>
          <div className="service-function-wgt__excerpt">
            <p>{this.props.excerpt}</p>
          </div>
          <Link className="main-link" to={this.props.to}>Детальніше</Link>
        </div>
        <div className="service-function-wgt__col">
          <div className="service-function-wgt__thumb">
            <img src={this.props.thumb} alt="" />
          </div>
        </div>
      </div>
    </div>

  }
}
export default ServiceFunctionWgt;
