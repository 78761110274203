import React from "react";
import AccountHeader from './components/AccountHeader';
import AccountContentHeader from './components/AccountContentHeader';
import AccountContentEmpty from './components/AccountContentEmpty';
import AccountNav from './components/AccountNav';
import MessagesList from './components/MessagesList';
import Loading from './components/Loading';
import { Navigate } from "react-router-dom";
import Pagination from './components/Pagination';
import DateFormating from './components/DateFormating';
import Filter from './components/Filter';
import FilterSearch from './components/FilterSearch';
import ResultErrors from './components/ResultErrors';
import messages_img from './media/messages.png';
import { getAuth } from "firebase/auth";
import sprite from './media/icons.svg';
class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      error: null,
      isLoaded: false,
      messages: [],
      totalPages: null,
      currentPage: 0,
      isLoadedMessage: false,
      errorMessage: null,
      message: [],
      modalClass: null,
      modalDisabled: false,
      modal: false,
      answerModal: false,
      messageModal: false,
      modalData: {},
      openedMessage: [],
      answerField: '',
      isLoadedMessageAnswer: false,
      messageSubmitSuccess: false,
      errors: null,
      error: false,
      typeFilterActive: false,
      isLoadedSearch: false,
      searchMessagesValue: '',
      searchEmpty: false,
      redirectDeposit: false
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        messageModal: false,
        answerModal: false,
        modalDisabled: false
      }));
    }, 500);
  }
  handlerMessage (id, viewed, index, event) {
    let that = this;
    if(!event.target.closest('.control-wgt')){
      if(this.state.openedMessage.indexOf(id) !== -1) {
        let myIndex = this.state.openedMessage.indexOf(id);
        let arr = this.state.openedMessage;
        if (myIndex !== -1) {
            arr.splice(myIndex, 1);
        }
        this.setState(prevState => ({
          openedMessage: arr
        }));
      } else {
        this.setState(prevState => ({
          openedMessage: [...prevState.openedMessage, id]
        }));
      }
      if(!viewed){
        getAuth().currentUser.getIdToken().then(function(idToken){
          const myHeaders = new Headers({
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + idToken
          });
          fetch(`${that.props.queryLink}/api/v1/messages/${id}/viewed`, {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify({
              "viewed": true
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                });
              })
            } else {
              let messages = that.state.messages;
              messages[index].viewed = true;
              that.setState(prevState => ({
                messages: messages
              }));
            }

          })
        })
      }
    }
  }
  handlerSubmitAnswer (id, event) {
    event.preventDefault();
    let that = this;
    this.setState({
      isLoadedMessageAnswer: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/messages/${id}`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        "response": that.state.answerField,
        "messageStatus": "NEW",
        "messageID": id
      })
    }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoadedMessageAnswer: false,
                errors: data.errors
              });
            })
          } else {
            res.json().then(function(data) {
      that.setState(prevState => ({
        isLoadedMessageAnswer: false,
        messageSubmitSuccess: true,
        answerField: '',
      }));
      that.handleModalDisabled();
      fetch(`${that.props.queryLink}/api/v1/messages/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"itemsPerPage": 20})
      }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              });
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                messages: data.data
              });
            })
          }
      })
            })
          }
    })
  })
  }
  handlerAnswerField (event) {
    this.setState({
      answerField: event.target.value
    });
  }
  answerHandler (id) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/messages/${id}`, {
        method: 'GET',
        headers: myHeaders,
      }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  isLoadedMessage: true,
                  errors: data.errors,
                  error: true
                });
              })
            } else {
              res.json().then(function(data) {
                that.setState(prevState => ({
                  isLoadedMessage: true,
                  message: data,
                  modal: true,
                  messageModal: false,
                  answerModal: true,
                  modalClass: null
                }))
              })
            }
          })
    })
  }
  handlerMessageModal (id, viewed, index, event) {
    let that = this;
    if(!event.target.classList.contains('messages-wgt__reply-btn-mob')){
      if(this.state.openedMessage.indexOf(id) !== -1) {
        let myIndex = this.state.openedMessage.indexOf(id);
        let arr = this.state.openedMessage;
        if (myIndex !== -1) {
            arr.splice(myIndex, 1);
        }
        this.setState(prevState => ({
          openedMessage: arr
        }));
      } else {
        this.setState(prevState => ({
          openedMessage: [...prevState.openedMessage, id]
        }));
      }
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/messages/${id}`, {
        method: 'GET',
        headers: myHeaders,
      }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                });
              })
            } else {
              res.json().then(function(data) {
                that.setState(prevState => ({
                  message: data,
                  modal: !prevState.modal,
                  messageModal: !prevState.messageModal
                }));
                if(!viewed) {
                fetch(`${that.props.queryLink}/api/v1/messages/${id}/viewed`, {
                  method: 'PUT',
                  headers: myHeaders,
                  body: JSON.stringify({
                    "viewed": true
                  })
                }).then(res => {
                  if(res.status !== 200) {
                    res.json().then(function(data) {
                      that.setState({
                        errors: data.errors
                      });
                    })
                  } else {
                    let messages = that.state.messages;
                    messages[index].viewed = true;
                    data.setState(prevState => ({
                      messages: messages
                    }));
                  }
                })
              }
              })
            }
          })
    })
    }
  }
  handlerPaginationItems = (messagesItems, current) => {
    this.setState({
      messages: messagesItems,
      currentPage: current
    });
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/messages/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"itemsPerPage": 20})
      }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors,
                  isLoadedMessages: true,
                  error: true
                });
              })
            } else {
              res.json().then(function(data) {
                console.log(data);
               that.setState({
                isLoadedMessages: true,
                totalPages: data.totalPages,
                messages: data.data
              });
              })
            }
      })
    })
  }

  handlerSearch(event) {
    let that = this;
      this.setState({
      isLoadedSearch: true,
      searchMessagesValue: event.target.value,
      searchEmpty: false
    });
    let timeoutId = setTimeout(() => {
      getAuth().currentUser.getIdToken().then(function(idToken) {
              const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/messages/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({"itemsPerPage": 20, "query": that.state.searchMessagesValue})
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoadedSearch: false
              });
            });
          } else {
            res.json().then(function(data) {
              that.setState({
                isLoadedSearch: false,
                totalPages: data.totalPages,
                messages: data.data
              });
              if(data.data.length < 1){
                that.setState({
                  searchEmpty: true
                });
              }
            });
          }
        });
      });
    }, 500);
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = timeoutId;
  }

  handlerFilterMessage (type, event) {
    let that = this;
    let data = {
      "itemsPerPage": 20
    };
    if(type){
      data.messageType = type;
    }
      this.setState({
        isLoadedMessages: false,
        typeFilterActive: type
      });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/messages/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data)
      }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  isLoadedMessages: true,
                  errors: data.errors,
                  error: true
                });
              })
            } else {
              res.json().then(function(data) {
                that.setState({
                  isLoadedMessages: true,
                  totalPages: data.totalPages,
                  messages: data.data
                });
              })
            }
          })
      })
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null
    }));
  }

  handlerGetMessageStatus(status, event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken) {
            const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/messages/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"itemsPerPage": 20, "viewed": status})
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoadedSearch: false
            });
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              isLoadedSearch: false,
              totalPages: data.totalPages,
              messages: data.data
            });
            if(data.data.length < 1){
              that.setState({
                searchEmpty: true
              });
            }
          });
        }
      });
    });
  }

  handlerDepositUser(id, name, event){

    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken) {
            const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/${id}`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {

        } else {
          res.json().then(function(data) {
            let fullName = (data.name ? data.name + ' ' : '') + '' + (data.lastName ? data.lastName : '');
            let arr = [{id: id, name: fullName}];
            if(arr.length > 0){
              localStorage.setItem("usersIds", JSON.stringify(arr));
              that.setState(prevState => ({
                redirectDeposit: true
              }));
            }
          });
        }
      });
    });
  }

  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('messages-Title') + ' | ' + this.props.companyName;
    }
    let resultMessages = '';
    if(this.state.error) {
      resultMessages = <div>Ошибка...</div>;
    } else if(!this.state.isLoadedMessages) {
      resultMessages = <Loading />;
    } else {
      resultMessages = <MessagesList
        messagesData={this.state.messages}
        messagesType={{
            'MESSAGE': {
              icon: '#messages-wgt-message',
              tooltip: this.props.getTranslate('messages-TooltipMessage')
            },
            'NOMINATION': {
              icon: '#messages-wgt-money',
              tooltip: this.props.getTranslate('messages-TooltipNomination')
            }
        }}
        handlerOpenMessage={this.handlerMessage.bind(this)}
        handlerOpenMessageModal={this.handlerMessageModal.bind(this)}
        openedMessage={this.state.openedMessage}
        answerBtnTxt={this.props.getTranslate('messages-AnswerButton')}
        answerHandler={this.answerHandler.bind(this)}
        handlerDepositUser={this.handlerDepositUser.bind(this)}
      />;
    }
    return <>

    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.redirectDeposit && <Navigate
        to={this.props.getPermalink(`/account/deposits/create`)}
        state={{ message: true }}
        replace
      />

    ) : ''}

    {/* Error handler */}
    <ResultErrors
      errors={this.state.errors}
      closeHandler={this.handlerCloseError.bind(this)}
    />

    {/* Header component */}
    <AccountHeader
      queryLink={this.props.queryLink}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      getTranslate={this.props.getTranslate.bind(this)}
      completed={this.props.completed}
    />

    <div className="account-wrap">

    {/* Navigation component */}
    <AccountNav
      getPermalink={this.props.getPermalink}
      queryLink={this.props.queryLink}
      configsApp={this.props.configsApp}
      newReview={this.props.newReview}
      companyName={this.props.companyName}
      token={this.props.token()}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      dropdownStatus={this.state.dropdownStatus}
      dropdownHandler={this.handlerDropdown}
      getTranslate={this.props.getTranslate}
      completed={this.props.completed}
    />

    <div className="account-content">
      <div className="account-content__inner">

        {/* Account header component */}
        <AccountContentHeader
          title={this.props.getTranslate('messages-Title')}
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
        />

        <div className="account-content__tags">
        <div className="message-tags-wrap">
                    <ul className="message-tags">
                      <li className="message-tags__item">
                        <button className={`message-tags__btn ${!this.state.typeFilterActive ? 'active' : ''}`} onClick={this.handlerFilterMessage.bind(this, '')}>{this.props.getTranslate('messages-TagAll')}</button>
                      </li>
                      <li className="message-tags__item">
                        <button className={`message-tags__btn message-tags__btn_message ${this.state.typeFilterActive === 'MESSAGE' ? 'active' : ''}`} onClick={this.handlerFilterMessage.bind(this, 'MESSAGE')}>{this.props.getTranslate('messages-TagMessage')}</button>
                      </li>
                      <li className="message-tags__item">
                        <button className={`message-tags__btn message-tags__btn_bonus ${this.state.typeFilterActive === 'NOMINATION' ? 'active' : ''}`} onClick={this.handlerFilterMessage.bind(this, 'NOMINATION')}>{this.props.getTranslate('messages-TagBonuses')}</button>
                      </li>
                    </ul>

                    {/* Filter component */}
                    <Filter
                      buttonName={this.props.getTranslate('ordersFilter-Title')}
                      dataSort={[
                        {
                          name: this.props.getTranslate('messageFilterStatus-All'),
                          value: null
                        },
                        {
                          name: this.props.getTranslate('messageFilterStatus-Reading'),
                          value: true
                        },
                        {
                          name: this.props.getTranslate('messageFilterStatus-NoReading'),
                          value: false
                        },
                      ]}
                    handler={this.handlerGetMessageStatus.bind(this)}
                  />

                </div>

                  {/* Filter search input component */}
                  <FilterSearch
                    placeholder={this.props.getTranslate('messageSearch-Placeholder')}
                    value={this.state.searchMessagesValue}
                    handler={this.handlerSearch.bind(this)}
                  />

                </div>


                {this.state.messages.length < 1 && this.state.isLoadedMessages && !this.state.error ? <>
                  <div className="account-content__content account-content__content_empty">
                    <div className="empty-wrapper">

                      {/* If the response is empty */}
                      <AccountContentEmpty
                        img={messages_img}
                        title={this.props.getTranslate('messages-EmptyTitle')}
                        description={this.props.getTranslate('messages-EmptyDescription')}
                      />

                    </div>
                    </div></> : <><div className="account-content__content">

                      {/* Query result */}
                      {resultMessages}

                    </div></>}



        <div className="account-content__footer">

          {/* Pagination component */}
          <Pagination
            total={this.state.totalPages}
            perPage="20" path={`${this.props.queryLink}/api/v1/messages/search`}
            currentPage={this.state.currentPage}
            items={this.handlerPaginationItems}
          />

        </div>

      </div>
    </div>
    </div>
    {this.state.modal && this.state.answerModal ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.modalClass}` : `two ${this.state.modalClass}`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <><div className="modal-header">
          <h2 className="modal-headline">{this.props.getTranslate('messages-AnswerTitile')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <form className="answer-form" onSubmit={this.handlerSubmitAnswer.bind(this, this.state.message.messageID)}>
          <div className="answer-form__label">{this.state.message.message}</div>
          <div className="field-wrap">
            <textarea className="field-wrap__input field-wrap__input_accrual" placeholder={this.props.getTranslate('messages-AnswerFieldCaption')} value={this.state.answerField} onChange={this.handlerAnswerField.bind(this)} />
          </div>
            <div className="field-wrap__submit">
              <button className="btn btn_size_lg btn_width_100 answer-form__nav-btn" type="submit">{this.props.getTranslate('messages-AnswerButton')}{this.state.isLoadedMessageAnswer && <Loading />}</button>
            </div>
        </form></>
    </div>
  </div> : ''}
  {this.state.modal && this.state.messageModal ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
  <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.props.getTranslate('messages-ModalMessageTitle')}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
      <div className="message-wgt-popup">
        <ul className="massage-tag massage-tag__mob">
          {this.state.message.messageType === 'MESSAGE' ? <li className="message-tag__item">
            <span className="message-tag__label message-tag__label_message">{this.props.getTranslate('messages-TagMessage')}</span>
          </li> : ''}
          {this.state.message.messageType === 'NOMINATION' ? <li className="message-tag__item">
            <span className="message-tag__label message-tag__label_bonus">{this.props.getTranslate('messages-TagBonuses')}</span>
          </li> : ''}
          {this.state.message.response && <li className="message-tag__item"><span className="message-tag__label message-tag__label_answer">{this.props.getTranslate('messages-TagAnswer')}</span></li>}
        </ul>
        <div className="message-wgt-item">
          <div className="message-wgt-item__name">{this.state.message.userName}</div>
          <p className="message-wgt-item__description">{this.state.message.message}</p>
          {this.state.message.response && <div className="message-wgt-item__answer">
            <div className="message-wgt-item__answer-header">
              <div className="message-wgt-item__answer-label">{this.props.getTranslate('messages-Answer')}</div>
              <div className="message-wgt-item__answer-date">{<DateFormating start={this.state.message.responseDate} />}</div>
            </div>
            <p className="message-wgt-item__answer-text">{this.state.message.response}</p>
          </div>}
        </div>
        <div className="message-wgt-popup__nav">
          {!this.state.message.response && this.state.openedMessage.indexOf(this.state.message.messageID) ? <button className="btn btn_size_lg btn_width_100 message-wgt-popup__nav-btn" type="button" onClick={this.answerHandler.bind(this, this.state.message.messageID)}>{this.props.getTranslate('messages-ModalMessageSendBtn')}</button> : ''}
          <button className="btn btn_theme_gray btn_size_lg btn_width_100 message-wgt-popup__nav-btn" type="button" onClick={this.handleModalDisabled}>{this.props.getTranslate('messages-ModalMessageCloseBtn')}</button>
        </div>
      </div>
  </div>
</div> : ''}
    </>;
  }
}
export default Messages;
