import React from "react";
import { Link } from "react-router-dom";
import sprite from '../../media/icons.svg';
class DashboardWgt extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return  this.props.status ? <div className="dashboard-wgt">
      <Link to={this.props.to}>
        <div className="dashboard-wgt__inner">
          {this.props.newValueCount ? <span className="dashboard-wgt__label">{this.props.newValueCount}</span> : ''}
          <svg className="dashboard-wgt__icon">
            <use href={`${sprite + this.props.icon}`}></use>
          </svg>
          <div className="dashboard-wgt__content">
            <h2 className="dashboard-wgt__headline">{this.props.headline}</h2>
            <div className="dashboard-wgt__value">{this.props.value || this.props.value === 0 ? this.props.value : ''} {this.props.valueHeadline}</div>
          </div>
        </div>
      </Link>
    </div> : ''
  }
}
export default DashboardWgt;
