import React from "react";
import sprite from '../media/icons.svg';
import { useNavigate } from "react-router-dom";
function PrevPage(props) {
    const navigate  = useNavigate();
      return <button className="button-back button-back_theme_gray" onClick={() => navigate(-1)}>
              <svg>
                <use href={`${sprite}#arrow-back`}></use>
              </svg>{props.text}
            </button>
}
export default PrevPage;
