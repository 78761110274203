import React from "react";
import Button from '../Button';
class AboutBanner extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <section className="about-banner">
      <div className='about-banner__inner' style={{ backgroundImage: `url(${this.props.bg})` }}>
        <div className="wrapper">
          <div className="about-banner__content">
            {this.props.headline ? <h1 className="about-banner__headline">{this.props.headline}</h1> : ''}
            {this.props.description ? <div className="about-banner__description">{this.props.description}</div> : ''}
            {this.props.btn ? this.props.btn : ''}
          </div>
        </div>
      </div>
      {this.props.img ? <img className="about-banner__img" src={this.props.img} alt={this.props.headline} /> : ''}
    </section>
  }
}
export default AboutBanner;
