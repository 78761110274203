import React from "react";
import { Link, Navigate } from "react-router-dom";
import Loading from './components/Loading';
import Select from 'react-select';
import AccountHeader from './components/AccountHeader';
import Navigation from './components/Navigation';
import Pagination from './components/Pagination';
import sprite from './media/icons.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
let Editor;
let EditorState;
let convertToRaw;
let ContentState;
let convertFromHTML;
let draftToHtml;
let stateToHTML;

if (typeof window !== 'undefined') {
  Editor = require('react-draft-wysiwyg').Editor;
  EditorState = require('draft-js').EditorState;
  convertToRaw = require('draft-js').convertToRaw;
  ContentState = require('draft-js').ContentState;
  convertFromHTML = require('draft-js').convertFromHTML;
  draftToHtml = require('draftjs-to-html');
  stateToHTML = require('draft-js-export-html').stateToHTML;

  require('../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
}
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const customStylesError = {
  control: () => ({
    height: '42px',
    border: '1px solid #ff0000',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
class ControlPanelPagesCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      modalDisabled: false,
      modal: false,
      headlineValue: '',
      slugValue: '',
      titleUAValue: '',
      titleENValue: '',
      descriptionUAValue: '',
      descriptionENValue: '',
      contentStateUA: (typeof window !== "undefined" && typeof document !== 'undefined') ? EditorState.createEmpty() : '',
      contentStateEN: (typeof window !== "undefined" && typeof document !== 'undefined') ? EditorState.createEmpty() : '',
      successSubmit: false
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modal: false
      }));
    }, 500);
  }
  handlerHeadlineValue(event){
    this.setState({
      headlineValue: event.target.value
    });
  }
  handlerTitleUAValue(event){
    this.setState({
      titleUAValue: event.target.value
    });
  }
  handlerTitleENValue(event){
    this.setState({
      titleENValue: event.target.value
    });
  }
  handlerDescriptionUAValue(event){
    this.setState({
      descriptionUAValue: event.target.value
    });
  }
  handlerDescriptionENValue(event){
    this.setState({
      descriptionENValue: event.target.value
    });
  }
  handlerSlugValue(event){
    this.setState({
      slugValue: event.target.value
    });
  }
  onEditorStateChangeUA = (contentState) => {
    this.setState({
      contentStateUA: contentState
    });
  };
  onEditorStateChangeEN = (contentState) => {
    this.setState({
      contentStateEN: contentState
    });
  };

  handlerSubmit(event){
    event.preventDefault();
    this.setState({
      isLoadedSubmit: true
    });
    let that = this;
    const editorStateEN = this.state.contentStateEN;
    const editorStateUA = this.state.contentStateUA;
    let objSubmit = {
        "contentEng": (typeof window !== "undefined" && typeof document !== 'undefined') ? draftToHtml(convertToRaw(editorStateEN.getCurrentContent())) : '',
        "contentUA": (typeof window !== "undefined" && typeof document !== 'undefined') ? draftToHtml(convertToRaw(editorStateUA.getCurrentContent())) : '',
        "hidden": true,
        "link": this.state.slugValue,
        "name": this.state.headlineValue,
        "seoDescriptionEng": this.state.descriptionENValue,
        "seoDescriptionUA": this.state.descriptionUAValue,
        "seoTitleEng": this.state.titleENValue,
        "seoTitleUA": this.state.titleUAValue
    };
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/bo/v1/configs/systempages`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(objSubmit)
      }).then(res => {
        if (res.status === 200) {
          that.setState({
            isLoadedSubmit: false,
            successSubmit: true
          });
        } else {
          that.setState({
            isLoadedSubmit: false
          });
        }
      })
    })
  }
  render() {
    return <>
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successSubmit && <Navigate to="/controlpanel/pages" replace={true} />) : ''}
    <AccountHeader status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
    <div className="account-wrap account-wrap__admin">
      <Navigation status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
      <div className="account-content">
        <div className="account-content__inner">
          <div className="account-content__header account-content__header-wrap">
            <div className="account-content__header-inner">
              <Link className="button-back button-back_theme_gray" type="button" to="/controlpanel/pages">
                <svg>
                  <use href={`${sprite}#arrow-back`}></use>
                </svg>Повернутися назад
              </Link>
            </div>
            <a className="header__language header__language-content" href="#">RU</a>
          </div>
          <div className="account-content__content">
            <div className="account-wgt account-wgt__blog-form">
              <h1 className="account-content__headline">Створення сторінки</h1>
              <div className="field-wrap field-wrap__input_width_75">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="headline">Назва (для адмінки)</label>
                <input id="headline" className="field-wrap__input" type="text" placeholder="Введіть назву сторінки" value={this.state.headlineValue} onChange={this.handlerHeadlineValue.bind(this)} />
              </div>
            </div>
            <div className="account-wgt account-wgt__blog-form">
              <h2 className="account-content__headline">SEO оптимізація</h2>
              <div className="field-wrap field-wrap__input_width_75">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="title-ua">Title (UA)</label>
                <input id="title-ua" className="field-wrap__input" type="text" placeholder="Введіть title" value={this.state.titleUAValue} onChange={this.handlerTitleUAValue.bind(this)} />
              </div>
              <div className="field-wrap field-wrap__input_width_75">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="title-en">Title (EN)</label>
                <input id="title-en" className="field-wrap__input" type="text" placeholder="Введіть title" value={this.state.titleENValue} onChange={this.handlerTitleENValue.bind(this)} />
              </div>
              <div className="field-wrap field-wrap__input_width_75">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="title-ua">Description (UA)</label>
                <input id="title-ua" className="field-wrap__input" type="text" placeholder="Введіть description" value={this.state.descriptionUAValue} onChange={this.handlerDescriptionUAValue.bind(this)} />
              </div>
              <div className="field-wrap field-wrap__input_width_75">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="title-ua">Description (EN)</label>
                <input id="title-en" className="field-wrap__input" type="text" placeholder="Введіть description" value={this.state.descriptionENValue} onChange={this.handlerDescriptionENValue.bind(this)} />
              </div>
            </div>
            <div className="account-wgt account-wgt__blog-form">
              <h2 className="account-content__headline">Посилання</h2>
              <div className="field-wrap field-wrap__input_width_75 field-wrap__link">
                <label className="field-wrap__label field-wrap__label-link" htmlFor="link">https://matespace.io/</label>
                <input id="link" className="field-wrap__input" type="text" placeholder="введіть назву" value={this.state.slugValue} onChange={this.handlerSlugValue.bind(this)} />
              </div>
            </div>
            <div className="account-wgt account-wgt__blog-form">
              <h2 className="account-content__headline">Контент</h2>
              <div className="field-wrap">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="description">Контент (UA)</label>
                {(typeof window !== "undefined" && typeof document !== 'undefined') ? <Editor
                  wrapperClassName="wrapper-class editor-field"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class editor-toolbar"
                  placeholder="Текст сторінки"
                  editorState={this.state.contentStateUA}
                  onEditorStateChange={this.onEditorStateChangeUA}
                /> : ''}
              </div>
              <div className="field-wrap">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="description">Контент (EN)</label>
                {(typeof window !== "undefined" && typeof document !== 'undefined') ? <Editor
                  wrapperClassName="wrapper-class editor-field"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class editor-toolbar"
                  placeholder="Текст сторінки"
                  editorState={this.state.contentStateEN}
                  onEditorStateChange={this.onEditorStateChangeEN}
                /> : ''}
              </div>
                <div className="field-wrap__submit">
                  <button className="btn btn_size_lg product-info__nav-btn" type="button" onClick={this.handlerSubmit.bind(this)} disabled={this.state.isLoadedSubmit ? true : false}>Зберегти{this.state.isLoadedSubmit && <Loading />}</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  }
}
export default ControlPanelPagesCreate;
