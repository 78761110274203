import React from "react";
import ServiceFunctionWgt from './ServiceFunctionWgt';
import manage_img_1 from '../../media/manage-img3.png';
import sprite from '../../media/icons.svg';
let Swiper;
let SwiperSlide;

if (typeof window !== 'undefined') {
  // Асинхронно загружаем Swiper и его компоненты
  Swiper = require('swiper').default;
  SwiperSlide = require('swiper').default.SwiperSlide;

  // Асинхронно загружаем стили Swiper
  require('swiper/swiper-bundle.css');
}
class ServiceOtherFunctions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderData: [
        {
          to: "#",
          headline: 'В админ панели есть возможность просматривать итоги',
          excerpt: "В админ панели есть возможность просматривать итоги по всем активностям, которые предлагает Mate, в рамках своей компании. активностям, которые предлагает Mate, в рамках своей компании…",
          thumb: manage_img_1
        },
        {
          to: "#",
          headline: 'В админ панели есть возможность просматривать итоги',
          excerpt: "В админ панели есть возможность просматривать итоги по всем активностям, которые предлагает Mate, в рамках своей компании. активностям, которые предлагает Mate, в рамках своей компании…",
          thumb: manage_img_1
        },
        {
          to: "#",
          headline: 'В админ панели есть возможность просматривать итоги',
          excerpt: "В админ панели есть возможность просматривать итоги по всем активностям, которые предлагает Mate, в рамках своей компании. активностям, которые предлагает Mate, в рамках своей компании…",
          thumb: manage_img_1
        },
        {
          to: "#",
          headline: 'В админ панели есть возможность просматривать итоги',
          excerpt: "В админ панели есть возможность просматривать итоги по всем активностям, которые предлагает Mate, в рамках своей компании. активностям, которые предлагает Mate, в рамках своей компании…",
          thumb: manage_img_1
        },
        {
          to: "#",
          headline: 'В админ панели есть возможность просматривать итоги',
          excerpt: "В админ панели есть возможность просматривать итоги по всем активностям, которые предлагает Mate, в рамках своей компании. активностям, которые предлагает Mate, в рамках своей компании…",
          thumb: manage_img_1
        }
      ]
    }
  }
  componentDidMount() {
  if (typeof window !== 'undefined') {
    this.swiper = new Swiper(".swiper-services-functions", {
      spaceBetween: 30,
      slidesPerView: 1,
      pagination: {
        clickable: true
      },
      breakpoints: {
        768: {
          slidesPerView: 2
        }
      },
    })
  }
}
swiperRef = React.createRef();
goPrev = () => {
  if (this.swiperRef && this.swiperRef.current && this.swiperRef.current.swiper) {
    this.swiperRef.current.swiper.slidePrev(); // Go to the previous slide
  }
};

goNext = () => {
  if (this.swiperRef && this.swiperRef.current && this.swiperRef.current.swiper) {
    this.swiperRef.current.swiper.slideNext(); // Go to the next slide
  }
};
  render() {
    return <section className="section-service-functions">
      <div className="wrapper">
        <div className="section-service-functions__headline-wrap">
          <h2 className="section-service-functions__headline">Інші функції сервісу</h2>
          <div className="section-service-functions-nav">
            <button type="button" onClick={this.goPrev}>
              <svg className="section-service-functions-nav__icon">
                <use href={`${sprite}#arrow-left-long`}></use>
              </svg>
            </button>
            <button type="button" onClick={this.goNext}>
              <svg className="section-service-functions-nav__icon">
                <use href={`${sprite}#arrow-right-long`}></use>
              </svg>
            </button>
          </div>
        </div>
        <div className="swiper-container swiper-services-functions" ref={this.swiperRef}>
          <div className="swiper-wrapper">
            {this.state.sliderData.map((item, index) => {
              return <div className="swiper-slide" key={index}>
                <ServiceFunctionWgt
                  headline={item.headline}
                  excerpt={item.excerpt}
                  to={item.to}
                  thumb={item.thumb}
                />
              </div>
            })}
          </div>
        </div>
      </div>
    </section>
  }
}
export default ServiceOtherFunctions;
