import React from "react";
import AccountHeader from './components/AccountHeader';
import AccountContentHeader from './components/AccountContentHeader';
import AccountNav from './components/AccountNav';
import Loading from './components/Loading';
import ResultErrors from './components/ResultErrors';
import sprite from './media/icons.svg';
import { getAuth } from "firebase/auth";
class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      configsApp: {},
      modalStatus: false,
      nameStatus: null,
      isLoadedStatus: false,
      errors: null,
      error: false,
      isLoaded: false
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handleModalDisabled = () => {
    if (typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
      modalStatus: false
    }));
    setTimeout(() => {
      if(this.props.modalStatus){
        this.props.modalDisabledSettingStatus();
      }
      this.setState(prevState => ({
        modalStatus: false,
        modalDisabled: false
      }));
    }, 500);
  }
  componentDidMount() {
    if (typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/companies/config`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            error: true,
            isLoaded: true
          });
        })
      } else {
        res.json().then(function(data) {          
          that.setState({
            configsApp: data,
            isLoaded: true
          });
        })
      }
    })
  })
  }
  handlerSubmitStatus(event){
    let that = this;
    event.preventDefault();
    let obj = this.state.configsApp;
    this.setState({
      isLoadedStatus: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    obj[that.state.nameStatus] = !obj[that.state.nameStatus];
    fetch(`${that.props.queryLink}/api/v1/companies/config`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(obj)
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        })
      } else {
        res.json().then(function(data) {
          let configsApp = data;
          configsApp.customShop = true;
          that.setState({
            isLoadedStatus: false
          });
        });
        that.handleModalDisabled();
      }
    })
  })
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null
    }));
  }
  render() {
    if (typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('settings-Title') + ' | ' + this.props.companyName;
    }
    let resultContent = '';
    if(this.state.error) {
      resultContent = <div className="errors-content">Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultContent = <Loading />;
    } else {
      resultContent =         <div className="account-content__content">
          <div className="account-wgt account-wgt__settings">
            <div className="account-wgt__attention-wrap">
              <div className="account-wgt__attention"><div><span>{this.props.getTranslate('settings-AttentionBold')}</span> {this.props.getTranslate('settings-AttentionDescription')}</div>
                <div className="account-wgt__attention-icon">!</div>
              </div>
            </div>
            <table className="team-table table-settings">
              <thead>
                <tr>
                  <th>{this.props.getTranslate('settings-TableName')}</th>
                  <th>{this.props.getTranslate('settings-TableSettings')}</th>
                </tr>
              </thead>
              <tbody>
                <tr className={`team-table__row ${this.props.configsApp.loyalty ? '' : 'team-table__row_status_disabled'}`}>
                  <td className="team-table__name">{this.props.getTranslate('settings-TableValueBonuses')}</td>
                  <td>
                    <div className="team-table__nav">
                      <div className="tg-list-item">
                        <input className="tgl tgl-light" id="loyalty" checked={this.props.configsApp.loyalty} type="checkbox" onChange={this.props.handlerStatusSetting.bind(this, 'loyalty')} /><label className="tgl-btn" htmlFor="loyalty"></label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className={`team-table__row ${this.props.configsApp.shop ? '' : 'team-table__row_status_disabled'}`}>
                  <td className="team-table__name">{this.props.getTranslate('settings-TableValueStore')}</td>
                  <td>
                    <div className="team-table__nav">
                      <div className="tg-list-item">
                        <input className="tgl tgl-light" id="shop" checked={this.props.configsApp.shop} type="checkbox" onChange={this.props.handlerStatusSetting.bind(this, 'shop')} /><label className="tgl-btn" htmlFor="shop"></label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className={`team-table__row ${this.props.configsApp.event ? '' : 'team-table__row_status_disabled'}`}>
                  <td className="team-table__name">{this.props.getTranslate('settings-TableValueEvents')}</td>
                  <td>
                    <div className="team-table__nav">
                      <div className="tg-list-item">
                        <input className="tgl tgl-light" id="event" checked={this.props.configsApp.event} type="checkbox" onChange={this.props.handlerStatusSetting.bind(this, 'event')} /><label className="tgl-btn" htmlFor="event"></label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className={`team-table__row ${this.props.configsApp.survey ? '' : 'team-table__row_status_disabled'}`}>
                  <td className="team-table__name">{this.props.getTranslate('settings-TableValuePolls')}</td>
                  <td>
                    <div className="team-table__nav">
                      <div className="tg-list-item">
                        <input className="tgl tgl-light" id="survey" checked={this.props.configsApp.survey} type="checkbox" onChange={this.props.handlerStatusSetting.bind(this, 'survey')} /><label className="tgl-btn" htmlFor="survey"></label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className={`team-table__row ${this.props.configsApp.ad ? '' : 'team-table__row_status_disabled'}`}>
                  <td className="team-table__name">{this.props.getTranslate('settings-TableValueAds')}</td>
                  <td>
                    <div className="team-table__nav">
                      <div className="tg-list-item">
                        <input className="tgl tgl-light" id="ad" checked={this.props.configsApp.ad} type="checkbox" onChange={this.props.handlerStatusSetting.bind(this, 'ad')} /><label className="tgl-btn" htmlFor="ad"></label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className={`team-table__row ${this.props.configsApp.library ? '' : 'team-table__row_status_disabled'}`}>
                  <td className="team-table__name">{this.props.getTranslate('settings-TableValueLibrary')}</td>
                  <td>
                    <div className="team-table__nav">
                      <div className="tg-list-item">
                        <input className="tgl tgl-light" id="library" checked={this.props.configsApp.library} type="checkbox" onChange={this.props.handlerStatusSetting.bind(this, 'library')} /><label className="tgl-btn" htmlFor="library"></label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className={`team-table__row ${this.props.configsApp.superCoin ? '' : 'team-table__row_status_disabled'}`}>
                  <td className="team-table__name">{this.props.getTranslate('settings-TableValueSuperCoins')}</td>
                  <td>
                    <div className="team-table__nav">
                      <div className="tg-list-item">
                        <input className="tgl tgl-light" id="superCoin" checked={this.props.configsApp.superCoin} type="checkbox" onChange={this.props.handlerStatusSetting.bind(this, 'superCoin')} /><label className="tgl-btn" htmlFor="superCoin"></label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap">
    <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
    <div className="account-content">
      <div className="account-content__inner">

        {/* Account header component */}
        <AccountContentHeader
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
          title={this.props.getTranslate('settings-Title')}
        />

        {resultContent}
      </div>
    </div>
    </div>
    {this.props.modalStatus ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.props.getTranslate('settings-DeactivatedTitle')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <div className="delete-category-form">
          <div className="delete-category-form__description">{this.props.getTranslate('settings-DeactivatedDescritpion')}</div>
            <div className="field-wrap__submit">
              <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="submit" onClick={this.props.handlerStatusSetting.bind(this, this.state.nameStatus)} disabled={this.props.modalStatusLoading ? true : false}>{this.props.getTranslate('settings-DeactivatedButtonSubmit')}{this.props.modalStatusLoading && <Loading />}</button>
            </div>
        </div>
      </div>
    </div> : ''}
    </>;
  }
}
export default Settings;
