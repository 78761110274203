import React from "react";
import Header from './components/Header';
import Footer from './components/Footer';
import Button from './components/Button';
import ServiceBanner from './components/ServicePage/ServiceBanner';
import ServiceInfo from './components/ServicePage/ServiceInfo';
import ServiceQuestions from './components/ServicePage/ServiceQuestions';
import ServiceOtherFunctions from './components/ServicePage/ServiceOtherFunctions';
import ServiceNotes from './components/ServicePage/ServiceNotes';
import banner_img from './media/dash-board.png';
class ServicePages extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let title = this.props.getTranslate('servicesPage-Title');
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.title = title ? title : 'MateSpace.io';
      const metaDescription = document.querySelector('meta[name="description"]');
      let description = this.props.getTranslate('servicesPage-Description');
      if (metaDescription) {
        metaDescription.setAttribute('content', description ? description : 'MateSpace.io');
      }
    }
    return <>
    <Header getPermalink={this.props.getPermalink} logged={this.props.logged} userPhoto={this.props.userPhoto} userName={this.props.userName} handlerChangeLanguage={this.props.handlerChangeLanguage} chooseLanguage={this.props.chooseLanguage} getTranslate={this.props.getTranslate} />
    <main className="service-page">
      <ServiceBanner
        bg="#dae0d0"
        headline="Креативная экосистема внутри вашей компании"
        description="Вовлекайте всех участников вашей команды в жизнь компании с помощью интерактива и уникальной бонусной программы. Укрепляйте внутреннюю корпоративную связь, используя возможности сервиса Mate"
        btn={<Button className="btn_size_large" type="link" text="Підключити компанію" handler="#" btnSize="large" />}
        img={banner_img}
      />
      <ServiceInfo />
      <ServiceQuestions
        headline="Залишились питання?"
        description="Начисляйте бонусы сотруднику на внутреннюю бонусную карту за активное участие."
        btns={[
          <Button className="btn_size_large" type="link" text="Запросити демо" handler="#" btnSize="large" btnWidth="full" />,
          <Button className="btn_theme_transparent btn_size_large" type="link" text="Підключити компанію" handler="#" btnSize="large" btnWidth="full" />
        ]}
      />
      <ServiceOtherFunctions />
      <ServiceNotes />
    </main>
    <Footer getPermalink={this.props.getPermalink} getTranslate={this.props.getTranslate} />
    </>;
  }
}
export default ServicePages;
