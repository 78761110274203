import React from "react";
import sprite from '../media/icons.svg';
    class ResultErrors extends React.Component {
      constructor(props) {
        super(props);
      }
      render() {
        return <>
          {this.props.errors ? <div className="response-submit">
            {this.props.errors.map((item, index) => {
              return <div key={index} className="response-submit__item response-submit__item_error">{item}
                    <button className="response-submit__close" type="button" onClick={this.props.closeHandler.bind(this)}>
                    <svg><use href={`${sprite}#close-icon`}></use></svg>
                    </button></div>
            })}</div>: ''}
        </>
      }
    }
    export default ResultErrors;
