import React from "react";
import Loading from './components/Loading';
import { Link } from "react-router-dom";
import AccountHeader from './components/AccountHeader';
import ResultErrors from './components/ResultErrors';
import Navigation from './components/Navigation';
import Pagination from './components/Pagination';
import sprite from './media/icons.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
class ControlPanelAccessGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      modalDisabled: false,
      modal: false,
      rolesData: [],
      errors: null,
      error: false,
      isLoaded: false,
      totalItems: null,
      totalItemsUser: null,
      token: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("token-admin") ? JSON.parse(localStorage.getItem("token-admin")) : JSON.parse('')) : ''
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modal: false
      }));
    }, 500);
  }
  componentDidMount() {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/users/roles/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({"itemsPerPage": 20})
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            rolesData: data.data,
            isLoaded: true,
            totalItems: data.totalItems
          });
        })

        fetch(`${that.props.queryLink}/bo/v1/users/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({})
        }).then(res => {
          if (res.status === 200) {
            res.json().then(function(data) {
              that.setState({
                totalItemsUser: data.totalItems
              });
            })
          }
        });

      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            error: true,
            isLoaded: true
          });
        })
      }
    })
  })
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  render() {
    let resultUsers = '';
    if(this.state.error) {
      resultUsers = <div>Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultUsers = <Loading />;
    } else {
      resultUsers = <table className="data-table"><thead><tr><th>Название</th><th>Категории</th><th>Управление</th></tr></thead><tbody>
        {this.state.rolesData.map((role, index) => {
          return <tr className="standart-table__row" key={index}>
            <td>{role.name}</td>
            {role.categories ? <td>
              {role.categories.map((categories, roleIndex) => {
                return <span className="group-label" key={roleIndex}>{categories}</span>;
              })}
            </td> : <td></td>}
            <td>
              <div className="standart-table__nav">
                <Link className="standart-table__nav-btn" to={`/controlpanel/group/edit/${role.roleID}`}>
                  <svg>
                    <use href={`${sprite}#edit`}></use>
                  </svg>
                </Link>
              </div>
            </td>
          </tr>
        })}
      </tbody></table>
    }
    return <>
      <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
      <AccountHeader status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
      <div className="account-wrap account-wrap__admin">
        <Navigation status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
        <div className="account-content">
          <div className="account-content__inner">
            <div className="account-content__header">
              <div className="account-content__header-inner">
                <h1 className="account-content__headline">Доступы</h1>
              </div>
              <a className="header__language header__language-content" href="#">RU</a>
            </div>
            <div className="team-nav">
              <Link className="btn team-nav__btn" to="/controlpanel/group/edit">Створити групу</Link>
            </div>
            <div className="users-tab">
              <div className="users-tab__inner">
                <Link className="users-tab__link" to="/controlpanel/access">Користувачі<span className="users-tab__link-amount">{this.state.totalItemsUser}</span></Link>
                <Link className="users-tab__link active" to="/controlpanel/group">Група<span className="users-tab__link-amount">{this.state.totalItems}</span></Link>
              </div>
            </div>
            <div className="account-content__content">
              <div className="account-wgt account-wgt__users">
                {resultUsers}
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  }
}
export default ControlPanelAccessGroup;
