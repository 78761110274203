import React from "react";
import DateFormating from './DateFormating';
import sprite from '../media/icons.svg';
class MessagesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controlRow: null
    }
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  componentDidMount() {
    const div = document.querySelector('.control-wgt');
    if (div) {
      document.addEventListener('click', (e) => {
        const isControlBtn = e.target.closest('.control-content__btn');
        if (isControlBtn) {
          this.setState({
            controlRow: null
          });
        } else {
          const withinBoundaries = e.composedPath().includes(div);
           if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
            if (!withinBoundaries) {
              this.setState({
                controlRow: null
              });
            }
          }
        }
      });
    }
  }
  render() {
      return <div className="massages-list">
        {this.props.messagesData.map((item, index) => {
          return <div className={`messages-wgt ${item.viewed ? 'messages-wgt__read' : ''}`} key={index}>
            <div className="messages-wgt__overlay" onClick={this.props.handlerOpenMessageModal.bind(this, item.messageID, item.viewed, index)}></div>
            <div className="messages-wgt__inner" onClick={this.props.handlerOpenMessage.bind(this, item.messageID, item.viewed, index)}>
              <div className="messages-wgt__icon-wrap">
                <div className="tooltip-info">
                  <span className="tooltip-info__icon">
                    <svg className="messages-wgt__icon"><use href={`${sprite + '' + this.props.messagesType[item.messageType].icon}`}></use></svg>
                  </span>
                  <div className="tooltip-info__content">
                    <div className="arrow"></div>
                    <p>{this.props.messagesType[item.messageType].tooltip}</p>
                  </div>
                </div>
              </div>
              <div className="messages-wgt__content">
                <div className="messages-wgt__content-info">
                  <div className="messages-wgt__name">
                  <div className="messages-wgt__mob-icon">
                    <svg className="messages-wgt__icon"><use href={`${sprite + '' + this.props.messagesType[item.messageType].icon}`}></use></svg>
                  </div>
                  {item.userName}
                    {item.response ? <svg className="messages-wgt__answer-icon"><use href={`${sprite}#messages-icon`}></use></svg> : ''}
                  </div>
                  <div className="messages-wgt__date"><DateFormating start={item.createdDate} /></div>
                </div>
                <div className="messages-wgt__content-txt">
                  <div className="messages-wgt__content-txt-inner">
                    <div>{this.props.openedMessage.indexOf(item.messageID) !== -1 ? item.message : (item.message.length > 200 ? item.message.slice(0, 200) + '...' : item.message)}</div>
                  </div>
                  <div className="control-wgt">
                    <button className="control-btn control-btn__message" onClick={this.handlerControl.bind(this, index)}>
                      <svg className="control-btn__icon">
                        <use href={`${sprite}#more-icon`}></use>
                      </svg>
                    </button>
                    <div className={`control-content ${this.state.controlRow === index ? 'active' : ''}`}>
                      <ul className="control-content__list">
                        <li>
                          <button className="control-content__btn" onClick={this.props.handlerDepositUser.bind(this, item.nominatedUserID, 'dd')}>
                            <svg className="control-content__icon">
                              <use href={`${sprite}#deposit-icon`}></use>
                            </svg>Зробити нарахування
                          </button>
                        </li>
                        {!item.response ? <li>
                          <button className="control-content__btn" onClick={this.props.answerHandler.bind(this, item.messageID)}>
                            <svg className="control-content__icon">
                              <use href={`${sprite}#message-icon-v2`}></use>
                            </svg>{this.props.answerBtnTxt}
                          </button>
                        </li> : ''}
                      </ul>
                    </div>
                  </div>
                </div>
                {item.response ? <div className={this.props.openedMessage.indexOf(item.messageID) !== -1 ? 'messages-wgt__response active' : 'messages-wgt__response'}>
                  <div className="messages-wgt__response-inner">
                    <div className="messages-wgt__response-info">
                      <div className="messages-wgt__response-name">{item.userName}</div>
                      <div className="messages-wgt__response-date"><DateFormating start={item.responseDate} /></div>
                    </div>
                    {item.response}
                  </div>
                </div> : ''}
              </div>
            </div>
          </div>
        })}
      </div>;
  }
}
export default MessagesList;
