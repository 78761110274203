import React from "react";
import { Link, useParams, Navigate } from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import NotFound from './NotFound';
import sprite from './media/icons.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
class StandartPages extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        pageData: {}
      };
    }
  componentDidMount() {
    let that = this;
    let currentURL;
    let origin;
    let extractedPath;
    that.setState({
      pageData: this.props.pageData ? this.props.pageData : {}
    })
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
    window.scrollTo(0, 0);
    currentURL = window.location.href;
    origin = window.location.origin;

    if (currentURL.includes("en/")) {
      extractedPath = currentURL.split("en/")[1].replace(/\/+$/, '');
    } else {
      extractedPath = currentURL.split(origin)[1].replace(/^\/+/, '').replace(/\/+$/, '');
    }
  }
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
      });
      fetch(`${that.props.queryLink}/bo/v1/external/configs/systempages/links?link=${extractedPath}`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if (res.status === 200) {
          res.json().then(function(data) {
            that.setState({
              pageData: data
            })
          })
        } else if(res.status === 404) {
          that.setState({
            statusEmpty: true
          })
        } else {
          res.json().then(function(data) {
            that.setState({
              statusEmpty: true,
              errors: data.errors,
              isLoaded: true,
              error: true
            });
          })
        }
      })
  }
  render() {
    let location;
    let isEnglish;
    let title;
    let content;
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      location = window.location.pathname;
      isEnglish = location.startsWith('/en');
      title = isEnglish ? this.state.pageData.seoTitleEng : this.state.pageData.seoTitleUA;
      document.title = title;
      content = isEnglish ? this.state.pageData.contentEng : this.state.pageData.contentUA;
      const metaDescription = document.querySelector('meta[name="description"]');
      let description = isEnglish ? this.state.pageData.contentEng : this.state.pageData.contentUA;
      if (metaDescription) {
        metaDescription.setAttribute('content', description ? description : 'MateSpace.io');
      }
    }
    return <>
    {this.props.PageWithCanonical}
    {this.state.statusEmpty ? <NotFound getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} logged={this.props.logged} userPhoto={this.props.userPhoto} userName={this.props.userName} handlerChangeLanguage={this.props.handlerChangeLanguage} chooseLanguage={this.props.chooseLanguage} getTranslate={this.props.getTranslate} />
     : <><Header getPermalink={this.props.getPermalink} logged={this.props.logged} userPhoto={this.props.userPhoto} userName={this.props.userName} handlerChangeLanguage={this.props.handlerChangeLanguage} chooseLanguage={this.props.chooseLanguage} getTranslate={this.props.getTranslate}  />

    <main>
      <section className="section-terms">
        <div className="wrapper">
          <h1 className="section-terms__headline">{title}</h1>
          <div className="terms-content" dangerouslySetInnerHTML={{__html: content}}></div>
        </div>
      </section>
    </main>
    <Footer getPermalink={this.props.getPermalink} getTranslate={this.props.getTranslate} /></>}
    </>;
  }
}
export default (props) => <StandartPages {...useParams()} {...props} />
