import React from "react";
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import AccountContentHeader from './components/AccountContentHeader';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ResultErrors from './components/ResultErrors';
import Loading from './components/Loading';
import ResultQuery from './components/ResultQuery';
import { Link } from "react-router-dom";
import Pagination from './components/Pagination';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import faq_img from './media/faq.png';
import sprite from './media/icons.svg';
import { getAuth } from "firebase/auth";
require('../node_modules/react-accessible-accordion/dist/fancy-example.css');
let arrayMoveImmutable;
if (typeof window !== 'undefined') {
  // Выполняем импорт только в клиентской среде
  const { arrayMoveImmutable: clientArrayMoveImmutable } = require('array-move');
  arrayMoveImmutable = clientArrayMoveImmutable;
}
class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      totalPages: null,
      currentPage: 0,
      isLoadedFaq: false,
      itemsFaq: [],
      errorFaq: null,
      modalClass: null,
      modalDisabled: false,
      modal: false,
      modalData: {},
      dropDownAccordion: null,
      dropDownActive: false,
      categoryName: '',
      editCategoryId: null,
      editCategoryIndex: null,
      modalEditCategory: false,
      modalDeleteCategory: false,
      isLoadedSubmitCategoryNoteDelete: false,
      companyData: [],
      errorSubmit: null,
      editCategoryNameInput: null,
      editCategoryNameInputValue: '',
      successText: this.props.getTranslate('successStatus-Save'),
      successSubmit: (typeof window !== "undefined" && typeof document !== 'undefined') ? localStorage.getItem("successFaqCreate") : '',
      faqEditObj: [],
      successSubmitCategory: false,
      modalEditCategories: false,
      errors: null,
      error: false,
      isLoaded: false,
      editCategoriesData: [],
      editCategoriesForSubmit: [],
      errorSubmitAccept: false,
      errorSubmitEmpty: false,
      modalDeleteNote: false,
      deleteNoteId: null,
      categoryNameModal: '',
      controlRow: null,
      approxLineCounts: [],
      controlRowWgts: null,
      categoriesNameLength: false,
      errorFields: false,
      errorOnlySpecial: false,
      errorEmptyField: false,
      errorNewCategoryLength: false,
      idDeleteCategory: null,
      indexDeleteCategory: null
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalEditCategory: false,
        modalDeleteCategory: false,
        modalDisabled: false,
        modalData: {},
        modalEditCategories: false,
        modalDeleteNote: false,
        deleteNoteId: null,
        categoryNameModal: ''
      }));
    }, 500);
  }
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(prevState => ({
      itemsFaq: arrayMoveImmutable(prevState.itemsFaq, oldIndex, newIndex)
    }));
  }
  handlerAccordionNav (i) {
    this.setState(prevState => ({
      dropDownAccordion: i,
      dropDownActive: prevState.dropDownAccordion !== i ? true : !prevState.dropDownActive
    }))
  }
  handlerAccordionNavClose(){
    this.setState(prevState => ({
      dropDownAccordion: null,
      dropDownActive: false
    }))
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    let location;
    let language = 'uk-UA';
    getAuth().currentUser.getIdToken().then(function(idToken){
      if(typeof window !== "undefined" && typeof document !== 'undefined') {
        location = document.location.pathname;
        const isEnglish = location.startsWith('/en');
        if (isEnglish) {
          language = 'en-US'
        }
      }
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken,
        'Accept-Language': language
    });
    fetch(`${that.props.queryLink}/api/v1/companies`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status === 200) {
        res.json().then(function(data) {
         that.setState({
            companyData: data
          });
          if(data) {
          fetch(`${that.props.queryLink}/api/v1/companies/${data.companyID}/notes/search`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
              "companyID": data.companyID,
              "itemsPerPage": 10
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  isLoaded: true,
                  errors: data.errors,
                  error: true
                });
              })
            } else {
              res.json().then(function(data) {
                that.setState({
                  isLoaded: true,
                  totalPages: data.totalPages,
                  itemsFaq: data.data
                });

                const div = document.querySelector('.control-wgt');
                if (div) {
                  document.addEventListener('click', (e) => {
                    const isControlBtn = e.target.closest('.control-content__btn');
                    if (isControlBtn) {
                      that.setState({
                        controlRow: null,
                        controlRowWgts: null
                      });
                    } else {
                      const withinBoundaries = e.composedPath().includes(div);
                       if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
                        if (!withinBoundaries) {
                          that.setState({
                            controlRow: null,
                            controlRowWgts: null
                          });
                        }
                      }
                    }
                  });
                }

              })
            }
          })
          }
        })
      } else if(res.status === 401) {
        res.json().then(function(data) {
          that.setState({
            errors: [data.message],
            error: true,
            isLoaded: true
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        })
      }
    })
  })
  }
  handlerEditCategory (id, index, event) {
    this.setState(prevState => ({
      modal: !prevState.modal,
      modalEditCategory: !prevState.modalEditCategory,
      modalClass: null,
      categoryName: prevState.itemsFaq[index].name,
      editCategoryId: id,
      editCategoryIndex: index
    }));
  }
  handlerNameCategory (event) {
    this.setState({
      categoryName: event.target.value
    });
  }
  handlerRemoveCategory (id, name, index, event) {
    this.setState(prevState => ({
      modal: !prevState.modal,
      categoryNameModal: name,
      modalDeleteCategory: !prevState.modalDeleteCategory,
      modalClass: null,
      editCategoryId: id,
      editCategoryIndex: index
    }))
  }
  handlerPaginationItems = (faqItems, current) => {
    this.setState({
      itemsFaq: faqItems,
      currentPage: current
    });
  }
  hanlderSubmitDeleteCategory (event) {
    let that = this;
    event.preventDefault();
    this.setState({
      isLoadedSubmitCategoryEdit: true
    });
    let location;
    let language = 'uk-UA';
    getAuth().currentUser.getIdToken().then(function(idToken){
      if(typeof window !== "undefined" && typeof document !== 'undefined') {
        location = document.location.pathname;
        const isEnglish = location.startsWith('/en');
        if (isEnglish) {
          language = 'en-US'
        }
      }
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken,
        'Accept-Language': language
    });
    fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/categories/${that.state.editCategoryId}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoadedSubmitCategoryEdit: false,
              errors: data.errors
            });
          });
      } else {
        that.setState({
          isLoadedSubmitCategoryEdit: false
        });
        fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/notes/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "companyID": that.state.companyData.companyID,
            "itemsPerPage": 10
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                error: true
              });
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                totalPages: data.totalPages,
                itemsFaq: data.data
              });
            })
            that.handleModalDisabled();
          }
        })
      }
    })
  })
  }
  handlerDeleteNoteModal(id, event){
    this.setState({
      modalDeleteNote: true,
      deleteNoteId: id
    });
  }
  handlerDeleteNote (event) {
    let that = this;
    this.setState({
      isLoadedSubmitCategoryNoteDelete: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/notes/${that.state.deleteNoteId}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoadedSubmitCategoryNoteDelete: false,
            deleteNoteId: null,
            errors: data.errors
          });
        });
      } else {
         that.handleModalDisabled();
         that.setState({
            isLoadedSubmitCategoryNoteDelete: false,
            deleteNoteId: null
          });
          fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/notes/search`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
              "companyID": that.state.companyData.companyID,
              "itemsPerPage": 10
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                });
              })
            } else {
              res.json().then(function(data) {
                that.setState({
                  totalPages: data.totalPages,
                  itemsFaq: data.data
                });
              })
            }
          })
      }
    })
  })
  }
  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successSubmit: false
    }));
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      localStorage.removeItem('successFaqCreate');
    }
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null
    }));
  }

  handlerOpenEditCategories(){
    let that = this;
    this.setState(prevState => ({
        modal: !prevState.modal,
        modalEditCategories: true
    }));
        getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/categories`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {

        });
      } else {
        res.json().then(function(data) {
          that.setState(prevState => ({
            editCategoriesData: data
          }));
        })
      }
    })
  })
  }
  handlerCreateNewCategory(event){
    this.setState(prevState => ({
      editCategoriesData: [{"color": "#000", "name": "", status: true}, ...prevState.editCategoriesData]
    }));
  }
  handlerEditCategoriesValue(index, event){
    let categories = this.state.editCategoriesData;
    let value = event.target.value;
    if(value.length <= 40){
      categories[index].name = value;
    }
    if(categories[index].empty || categories[index].special){
      delete categories[index].empty;
      delete categories[index].special;
    }
    this.setState(prevState => ({
      editCategoriesData: categories
    }));
  }
  handlerSetStatusEditCategory(index, event){
    let categories = this.state.editCategoriesData;
    categories[index].status = true;
    this.setState(prevState => ({
      editCategoriesData: categories
    }));
  }
  handlerSetAcceptStatusCategory(index, event){
    let emptyError = false;
    let specialError = false;
    let categories = this.state.editCategoriesData;
    delete categories[index].empty;
    delete categories[index].special;
    let name = categories[index].name.trim();
    const isValidCurrencyName = /[a-zA-Z0-9а-яА-Я\s]/.test(name);
    if(name === ''){
      emptyError= true;
      categories[index].empty = true;
    }
    if(!isValidCurrencyName){
      specialError= true;
      categories[index].special = true;
    }
    if(!emptyError && !specialError){
      delete categories[index].status;
      delete categories[index].empty;
      delete categories[index].special;
      categories[index].submit = true;
    }
    this.setState(prevState => ({
      editCategoriesData: categories
    }));
  }
  handlerSubmitEditCategories(event){
    let that = this;
    let emptyError = false;
    let specialError = false;
    let errorSubmitAccept = false;
    this.setState({
      modalEditLoading: true,
      errorSubmitAccept: false
    });
    let categories = this.state.editCategoriesData;
    categories.map((item, index) => {
      let name = item.name.trim();
      const isValidCurrencyName = /[a-zA-Z0-9а-яА-Я\s]/.test(name);
      if(name === ''){
        emptyError= true;
        categories[index].empty = true;
        this.setState({
          modalEditLoading: false
        });
        return false;
      }
      if(!isValidCurrencyName){
        specialError= true;
        categories[index].special = true;
        this.setState({
          modalEditLoading: false
        });
        return false;
      }
      if(item.status){
        errorSubmitAccept = true;
        this.setState({
          modalEditLoading: false,
          errorSubmitAccept: true
        });
        return false;
      }
    });
    if(!emptyError && !specialError && !errorSubmitAccept){
    let submitCategories = categories.filter(element => element.submit);
    if(submitCategories.length > 0){
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      async function asyncAwaitWay(that) {
        for (let category of submitCategories) {
          if(category.categoryID){
          fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/categories/${category.categoryID}`, {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify({
              "categoryID": category.categoryID,
              "color": category.color,
              "companyID": category.companyID,
              "name": category.name
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                });
              })
            } else {
              submitCategories.shift();
              if(submitCategories.length < 1){
                that.handleModalDisabled();
          fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/notes/search`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
              "companyID": that.state.companyData.companyID,
              "itemsPerPage": 10
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  isLoaded: true,
                  errors: data.errors,
                  error: true
                });
              })
            } else {
              res.json().then(function(data) {
                that.setState({
                  isLoaded: true,
                  totalPages: data.totalPages,
                  itemsFaq: data.data
                });
              })
                that.setState({
                  modalEditLoading: false
                });
            }
          })
              }
            }
          })
          } else {
          fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/categories`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
              "color": '#000',
              "name": category.name
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                });
              })
            } else {
              submitCategories.shift();
              if(submitCategories.length < 1){
                that.handleModalDisabled();
          fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/notes/search`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
              "companyID": that.state.companyData.companyID,
              "itemsPerPage": 10
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  isLoaded: true,
                  errors: data.errors,
                  error: true
                });
              })
            } else {
              res.json().then(function(data) {
                that.setState({
                  isLoaded: true,
                  totalPages: data.totalPages,
                  itemsFaq: data.data
                });
              })
                that.setState({
                  modalEditLoading: false
                });
            }
          })
              }
            }
          })
          }
        }
      }
      asyncAwaitWay(that);
    })
  } else {
    this.setState({
      modalEditLoading: false
    });
    that.handleModalDisabled();
    }
  }
  }
  handlerDeleteCategoryByIdModal(id, index, event){
    let that = this;
    that.setState({
      idDeleteCategory: id ? id : 'empty',
      indexDeleteCategory: index
    });
    if(id){
      that.handleModalDisabled();
      setTimeout(function() {
        that.setState({
          modal: true,
          modalDeleteCategory: true
        });
      }, 500);
    } else {
      let categories = that.state.editCategoriesData;
      categories.splice(index, 1);
        that.setState(prevState => ({
          editCategoriesData: categories
        }));
    }
  }
  handlerDeleteCategoryById(event){
    event.preventDefault();
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    if(that.state.idDeleteCategory !== 'empty'){
    fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/categories/${that.state.idDeleteCategory}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            });
          });
      } else {
        fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/notes/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "companyID": that.state.companyData.companyID,
            "itemsPerPage": 10
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                error: true
              });
            })
          } else {
            let categories = that.state.editCategoriesData;
            categories.splice(that.state.indexDeleteCategory, 1);
              that.setState(prevState => ({
                editCategoriesData: categories
              }));
            res.json().then(function(data) {
              that.setState({
                totalPages: data.totalPages,
                itemsFaq: data.data
              });
            });
            that.handleModalDisabled();
            setTimeout(function() {
              that.setState({
                idDeleteCategory: null,
                indexDeleteCategory: null,
                modal: true,
                modalEditCategories: true
              });
            }, 500);
          }
        })
      }
    })
    } else {
            let categories = that.state.editCategoriesData;
            categories.splice(that.state.indexDeleteCategory, 1);
              that.setState(prevState => ({
                editCategoriesData: categories
              }));
              that.handleModalDisabled();
              setTimeout(function() {
                that.setState({
                  idDeleteCategory: null,
                  indexDeleteCategory: null,
                  modal: true,
                  modalEditCategories: true
                });
              }, 500);
    }
      })

  }
  handlerNewNameCategory(event){
    let value = event.target.value;
    if(value.length <= 100){
      this.setState({
        categoryName: event.target.value,
        errorNewCategoryLength: false
      });
    } else {
      this.setState({
        errorNewCategoryLength: true
      });
    }
    this.setState({
      errorFields: false
    });
  }
  handlerSubmitNewNameCategory(event){
    event.preventDefault();
    let that = this;
    let error = false;
    this.setState({
      modalEditLoading: true,
      errorFields: false,
      errorOnlySpecial: false,
      errorEmptyField: false
    });
    const companyNameWithoutSpaces = this.state.categoryName.trim();
    const isValidCompanyName = /[a-zA-Z0-9а-яА-Я\s]/.test(companyNameWithoutSpaces);
    if(companyNameWithoutSpaces === '' || !isValidCompanyName){
      error = true;
    }
    if(!isValidCompanyName){
      this.setState({
        errorOnlySpecial: true,
        errorFields: false
      });
    }
    this.setState({
      categoryName: companyNameWithoutSpaces
    });
    if(!error){
      getAuth().currentUser.getIdToken().then(function(idToken){
  const myHeaders = new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + idToken
  });
        fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/categories/${that.state.editCategoryId}`, {
          method: 'PUT',
          headers: myHeaders,
          body: JSON.stringify({
            "categoryID": that.state.editCategoryId,
            "companyID": that.state.companyData.companyID,
            "color": '#000',
            "name": companyNameWithoutSpaces
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                modalEditLoading: false
              });
            })
          } else {
            fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/notes/search`, {
              method: 'POST',
              headers: myHeaders,
              body: JSON.stringify({
                "companyID": that.state.companyData.companyID,
                "itemsPerPage": 10
              })
            }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    isLoaded: true,
                    errors: data.errors,
                    error: true,
                    modalEditLoading: false
                  });
                })
              } else {
                res.json().then(function(data) {
                  that.setState({
                    isLoaded: true,
                    totalPages: data.totalPages,
                    itemsFaq: data.data,
                    modalEditLoading: false
                  });
                  that.handleModalDisabled();
                })
              }
            })
          }
        });
      });
    } else {
      that.setState({
        modalEditLoading: false,
        errorFields: true
      });
    }
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }


  handlerControlWgt(index, indexWgt, event){
    if(!this.state.controlRowWgts || this.state.controlRowWgts['itemIndex'] !== index || this.state.controlRowWgts['wgtIndex'] !== indexWgt){
      this.setState({
        controlRowWgts: {'itemIndex': index, 'wgtIndex': indexWgt}
      });
    } else {
      this.setState({
        controlRowWgts: null
      });
    }
  }

  calculateLineCount(indexItem, event) {
    let that = this;
    const textBlock = event.target;
    const textBlockParent = textBlock.closest('.accordion__item');
    const textBlockItems = textBlockParent.querySelectorAll('.faq-item__content');
    this.setState({
      approxLineCounts: []
    });
    setTimeout(() => {
      textBlockItems.forEach((item, i) => {
        if (item) {
          const lineHeight = parseInt(window.getComputedStyle(item).lineHeight, 10);
          const clientHeight = item.clientHeight;
          const approxLineCount = Math.round(clientHeight / lineHeight);
          if (approxLineCount > 3) {
            const deffLine = approxLineCount - 3;
            const deffHeight = lineHeight * deffLine;
            const resultHeight = clientHeight - deffHeight;
            that.setState(prevState => {
              const existingItemIndex = prevState.approxLineCounts.findIndex(obj => obj.hasOwnProperty(indexItem));

              if (existingItemIndex !== -1) {
                // Update existing object
                const updatedArray = [...prevState.approxLineCounts];
                const existingArray = updatedArray[existingItemIndex][indexItem];

                // Add 'index' and 'height' if they don't exist
                const updatedItem = existingArray.find(entry => entry.index === i);
                if (!updatedItem) {
                  updatedArray[existingItemIndex][indexItem] = [
                    ...existingArray,
                    { 'index': i, 'height': resultHeight }
                  ];
                }
                return { approxLineCounts: updatedArray };
              } else {
                // Add new object
                return {
                  approxLineCounts: [
                    ...prevState.approxLineCounts,
                    { [indexItem]: [{ 'index': i, 'height': resultHeight }] }
                  ]
                };
              }
            });
          }
        }
      });
    }, 10);
  }
  handlerItemMore(indexItem, index, event) {
    const approxLineCounts = this.state.approxLineCounts;
    const targetIndex = approxLineCounts[0][indexItem].findIndex(item => item.index === index);
    const targetIndexElem = approxLineCounts[0][indexItem][targetIndex];
    targetIndexElem.height = 'auto';
    this.setState({
      approxLineCounts: approxLineCounts
    });
  }

  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('onboarding-Title') + ' | ' + this.props.companyName;
    }
    if(this.state.successSubmit){
      setTimeout(() => {
        this.handlerCloseSuccess();
      }, 5000);
    }
    let resultFaq = '';
    if(this.state.error) {
      resultFaq = <div className="errors-content">Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultFaq = <Loading />;
    } else {
      resultFaq = <div className="account-content__content account-content__content__question">
        <Accordion className="faq-accordion" allowZeroExpanded>
        {this.state.itemsFaq.map((item, index) =>
          <AccordionItem key={index}>
            {item.categoryID ? <div className="control-wgt control-wgt__faq">
              <button className="control-btn" onClick={this.handlerControl.bind(this, index)}>
                <svg className="control-btn__icon">
                  <use href={`${sprite}#more-icon`}></use>
                </svg>
              </button>
              <div className={`control-content ${this.state.controlRow === index ? 'active' : ''}`}>
                <ul className="control-content__list">
                  <li>
                    <button className="control-content__btn" onClick={this.handlerEditCategory.bind(this, item.categoryID, index)}>
                      <svg className="control-content__icon">
                        <use href={`${sprite}#edit-icon`}></use>
                      </svg>{this.props.getTranslate('onboarding-Edit')}
                    </button>
                  </li>
                  <li>
                    <button className="control-content__btn control-content__btn_delete" onClick={this.handlerRemoveCategory.bind(this, item.categoryID, item.name, index)}>
                      <svg className="control-content__icon">
                        <use href={`${sprite}#delete-red`}></use>
                      </svg>{this.props.getTranslate('onboarding-Delete')}
                    </button>
                  </li>
                </ul>
              </div>
            </div> : ''}
            <AccordionItemHeading onClick={this.calculateLineCount.bind(this, index)}>
              <AccordionItemButton className={`accordion__button ${item.notes.length < 1 ? 'accordion__button_empty' : ''}`}>
                <div className="accordion__button-inner">
                  <span>{item.name}</span>
                  <span className="users-tab__link-amount">{item.notes.length}</span>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            {item.notes.length > 0 ? <AccordionItemPanel>
            {item.notes.map((wgt, i) => {
              const exists = this.state.approxLineCounts.some(obj => obj.hasOwnProperty(index));

              let existsApproxLine = null;
              let height = 'auto';

              if (exists) {
                const approxLineCounts = this.state.approxLineCounts;
                const indexObject = approxLineCounts.find(obj => obj.hasOwnProperty(index));
                const entry = indexObject[index] && indexObject[index].find(entry => entry.index === i);

                if (indexObject[index] && indexObject[index].some(entry => entry.index === i)) {
                  existsApproxLine = true;
                }
                const targetIndex = approxLineCounts[0][index].findIndex(item => item.index === i);
                const targetIndexElem = approxLineCounts[0][index][targetIndex];
                if(targetIndexElem){
                  height = targetIndexElem['height'];
                }
              }
                return <div className="faq-item" key={i}>
                  <div className="faq-item__headline">{wgt.name}
                    <div className="control-wgt">
                      <button className="control-btn" onClick={this.handlerControlWgt.bind(this, index, i)}>
                        <svg className="control-btn__icon">
                          <use href={`${sprite}#more-icon`}></use>
                        </svg>
                      </button>
                      <div className={`control-content${this.state.controlRowWgts && this.state.controlRowWgts['itemIndex'] === index && this.state.controlRowWgts['wgtIndex'] === i ? ' active' : ''}`}>
                        <ul className="control-content__list">
                          <li>
                            <Link className="control-content__btn faq-item-nav__link_edit" to={this.props.getPermalink(`/account/faq/edit/${wgt.companyNoteID}`)}>
                              <svg className="control-content__icon">
                                <use href={`${sprite}#edit-icon`}></use>
                              </svg>{this.props.getTranslate('onboarding-Edit')}
                            </Link>
                          </li>
                          <li>
                            <button className="control-content__btn control-content__btn_delete" type="button" onClick={this.handlerDeleteNoteModal.bind(this, wgt.companyNoteID)}>
                              <svg className="control-content__icon">
                                <use href={`${sprite}#delete-red`}></use>
                              </svg>{this.props.getTranslate('onboarding-Delete')}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="faq-item__content" dangerouslySetInnerHTML={{ __html: wgt.description }} style={{height: height}}></div>
                  {existsApproxLine && height !== 'auto' ? <ul className="faq-item-nav">
                  <li className="faq-item-nav__item">
                      <button className="faq-item-nav__link faq-item-nav__link_edit" onClick={this.handlerItemMore.bind(this, index, i)}>Детальніше</button>
                    </li>
                  </ul> : ''}
                </div>
              })}
            </AccordionItemPanel> : ''}
          </AccordionItem>
        )}
        </Accordion>
      </div>;
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    <ResultQuery success={this.state.successSubmit} resultText={this.state.successText} handlerSuccess={this.handlerCloseSuccess} handlerError={this.handlerCloseError} />
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap">
    <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
    <div className="account-content">
      <div className="account-content__inner">

        {/* Account header component */}
        <AccountContentHeader
          title={this.props.getTranslate('onboarding-Title')}
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
        />

         <div className="header-nav">
          <div className="header-nav__col">
              <Link className="btn btn_with_icon" to={this.props.getPermalink("/account/faq/create")}>
                <svg className="btn__icon-add">
                  <use href={`${sprite}#plus-icon`}></use>
                </svg>
              {this.props.getTranslate('onboarding-AddButton')}</Link>
            </div>
            <div className="header-nav__col">
              <button className="btn" type="button" onClick={this.handlerOpenEditCategories.bind(this)}>{this.props.getTranslate('onboarding-EditCategory')}</button>
            </div>
        </div>
        {this.state.itemsFaq.length < 1 && this.state.isLoaded && !this.state.error ? <div className="account-content__content account-content__content_empty"><div className="empty-wrapper">
          <div className="empty-wrapper__inner">
            <img className="empty-wrapper__img" src={faq_img} alt="" />
            <h1 className="empty-wrapper__headline">{this.props.getTranslate('onboarding-NullTitle')}</h1>
            <p className="empty-wrapper__description">{this.props.getTranslate('onboarding-NullDescription')}</p>
            <Link className="btn btn_with_icon btn_size_lg" to={this.props.getPermalink("/account/faq/create")}>
              <svg className="btn__icon-add">
                <use href={`${sprite}#plus-icon`}></use>
              </svg>
            {this.props.getTranslate('onboarding-AddButton')}</Link>
          </div>
        </div></div> : resultFaq}
        <div className="account-content__footer">
        <Pagination total={this.state.totalPages} perPage="10" path={`${this.props.queryLink}/api/v1/companies/${this.state.companyData.companyID}/notes/search`} currentPage={this.state.currentPage} items={this.handlerPaginationItems} otherParams={{"companyID": this.state.companyID}} />
        </div>
      </div>
    </div>
    </div>
    {this.state.modal && this.state.modalDeleteCategory ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.props.getTranslate('modal-deleteOnboardingCategoryTitle')}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
      <form className="delete-category-form" onSubmit={this.state.idDeleteCategory ? this.handlerDeleteCategoryById.bind(this) : this.hanlderSubmitDeleteCategory.bind(this)}>
        <div className="delete-category-form__description">{this.props.getTranslate('modal-deleteOnboardingCategoryDescriptionBefore')} «{this.state.categoryNameModal}» {this.props.getTranslate('modal-deleteOnboardingCategoryDescriptionAfter')}</div>
          <div className="field-wrap__submit">
            <button className="btn btn_size_lg btn_theme_remove btn_width_100 user-info__nav-btn" type="submit" disabled={this.state.isLoadedSubmitCategoryEdit ? true : false}>{this.props.getTranslate('removeModal-ButtonSubmit')}{this.state.isLoadedSubmitCategoryEdit && <Loading />}</button>
          </div>
      </form>
    </div>
    </div> : ''}


          {this.state.modalEditCategories ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <div className="modal-header">
            <h2 className="modal-headline">{this.props.getTranslate('onboarding-EditTitle')}</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          <div>
          <div className="edit-category__add-category">
            <button className="btn btn_width_100 btn_theme_secondary btn_size_lg" onClick={this.handlerCreateNewCategory.bind(this)}>{this.props.getTranslate('onboarding-AddCategory')}</button>
          </div>
          {this.state.editCategoriesData ? <>
            <Scrollbars
                  renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                  renderTrackVertical={props => <div {...props} className="track-vertical-scroll track-vertical" />}
                  renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                  renderThumbVertical={props => <div {...props} className="thumb-vertical-scroll thumb-vertical"/>}
                    autoHeight
                    autoHeightMin={0}
                    autoHeightMax={300}
                    renderView={props => <div {...props} />}>
                    <div style={{paddingRight: '15px'}}>
          {this.state.editCategoriesData.map((item, index) => {
            return     <div className="categories-faq-wgt" key={index}>
                      <div className="categories-faq-wgt__inner">
                        {item.status ? <input className={`categories-faq-wgt__input ${item.empty || (item.special && !item.empty) || item.name.length >= 100 ? 'field-wrap__input_error' : ''}`} value={item.name} onChange={this.handlerEditCategoriesValue.bind(this, index)} placeholder="Введіть назву" /> : <div className="categories-faq-wgt__name">{item.name.length > 40 ? item.name.substring(0, 40) + "…" : item.name}</div>}
                        <div className="categories-faq-wgt__nav">
                        {item.status ? <button className={`categories-faq-wgt__nav-btn categories-faq-wgt__nav-btn-add`} type="button" onClick={this.handlerSetAcceptStatusCategory.bind(this, index)}>
                          <svg>
                            <use href={`${sprite}#check-icon`}></use>
                          </svg>
                        </button> : ''}
                          {!item.status ? <button className="categories-faq-wgt__nav-btn" type="button" onClick={this.handlerSetStatusEditCategory.bind(this, index)}>
                            <svg>
                              <use href={`${sprite}#edit`}></use>
                            </svg>
                          </button> : ''}
                          <button className="categories-faq-wgt__nav-btn" type="button" onClick={this.handlerDeleteCategoryByIdModal.bind(this, item.categoryID, index)}>
                            <svg className={`${item.categoryID ? 'categories-faq-wgt__nav-btn-delete' : 'categories-faq-wgt__nav-btn-close'}`}>
                              <use href={`${sprite}#${item.categoryID ? 'delete' : 'close-icon-category'}`}></use>
                            </svg>
                          </button>
                        </div>
                        </div>
                        {item.name.length >= 100 && item.status ? <div className="validation-error">{this.props.getTranslate('validation-categoryNameLengthField')}</div> : ''}
                        {item.empty ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                        {item.special && !item.empty ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpecialField')}</div> : ''}
                      </div>
          })}</div></Scrollbars></> : ''}
              <div className="field-wrap__submit">
                <button className="btn btn_size_lg btn_width_100 product-info__nav-btn" type="button" onClick={this.handlerSubmitEditCategories.bind(this)} disabled={this.state.modalEditLoading}>{this.props.getTranslate('onboarding-CreateButton')}{this.state.modalEditLoading ? <Loading /> : ''}</button>
              </div>
          </div>
        </div>
        </div> : ''}

          {this.state.modalEditCategory ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <div className="modal-header">
            <h2 className="modal-headline">{this.props.getTranslate('modal-EditOnboardingCategoryTitle')}</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          <div>
            <div className="field-wrap">
              <label className="field-wrap__label" htmlFor="category-name">{this.props.getTranslate('modal-EditOnboardingCategoryLabel')}</label>
              <input id="category-name" className={`field-wrap__input ${(this.state.errorNewCategoryLength && this.state.categoryName.length >= 100) || (this.state.errorFields && this.state.categoryName === '') || (this.state.errorFields && this.state.errorOnlySpecial && this.state.categoryName !== '') ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.categoryName} onChange={this.handlerNewNameCategory.bind(this)} placeholder={this.props.getTranslate('modal-EditOnboardingCategoryPlaceholder')} />
              {this.state.errorNewCategoryLength && this.state.categoryName.length >= 100 ? <div className="validation-error">{this.props.getTranslate('validation-categoryNameLengthField')}</div> : ''}
              {this.state.errorFields && this.state.categoryName === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
              {this.state.errorFields && this.state.errorOnlySpecial && this.state.categoryName !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpecialField')}</div> : ''}
            </div>
              <div className="field-wrap__submit">
                <button className="btn btn_size_lg btn_width_100 product-info__nav-btn" type="button" onClick={this.handlerSubmitNewNameCategory.bind(this)}>{this.props.getTranslate('modal-EditOnboardingCategorySave')}{this.state.modalEditLoading ? <Loading /> : ''}</button>
              </div>
          </div>
        </div>
        </div> : ''}

        {this.state.modalDeleteNote ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <div className="modal-header">
            <h2 className="modal-headline">{this.props.getTranslate('modal-deleteOnboardingNoteTitle')}</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
            <div className="delete-category-form__description">{this.props.getTranslate('modal-deleteOnboardingNoteDescription')}</div>
              <div className="field-wrap__submit">
                <button className="btn btn_size_lg btn_theme_remove btn_width_100 user-info__nav-btn" type="button" onClick={this.handlerDeleteNote.bind(this)}>{this.props.getTranslate('removeModal-ButtonSubmit')}</button>
              </div>
        </div>
        </div> : ''}

    </>;
  }
}
export default Faq;
