import React from "react";
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import AccountContentHeader from './components/AccountContentHeader';
import Loading from './components/Loading';
import PrevPage from './components/PrevPage';
import ResultQuery from './components/ResultQuery';
import { useParams, Navigate, useLocation } from "react-router-dom";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { getAuth } from "firebase/auth";
import sprite from './media/icons.svg';
import contactPhone from './media/contacts-phone.png';
import contactEmail from './media/contacts-email.png';
import contactTelegram from './media/contacts-telegram.png';
import contactFacebook from './media/contacts-facebook.png';
import contactInstagram from './media/contacts-instagram.png';
import contactLinkedin from './media/contacts-linkedin.png';
import contactTwitter from './media/contacts-twitter.png';
import contactViber from './media/contacts-viber.png';
import contactMessanger from './media/contacts-messanger.png';
import ResultErrors from './components/ResultErrors';
import { IMaskInput } from 'react-imask';
const customStyles = {
  menu: (provided, state) => ({
     ...provided,
     zIndex: 1000,
   }),
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const RedirectPage = () => {
    const location = useLocation();
    return (
      <Navigate to={location.state.from} replace={true} />
    );
};
class UsersEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFieldDate: null,
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      firstNameValue: '',
      lastNameValue: '',
      middleNameValue: '',
      gender: '',
      positionValue: '',
      emailValue: '',
      birthday: null,
      birthdayValue: null,
      errorUser: null,
      isLoadedUser: false,
      user: [],
      role: [],
      roles: [],
      teamOptions: [{'value': null, 'label': 'Без відділу'}],
      hobbies: null,
      isLoadedSubmit: false,
      successSubmit: false,
      maritalStatusValue: null,
      date: new Date(),
      token: this.props.token(),
      chooseDateFull: null,
      startDate: null,
      endDate:null,
      finishStartDate: null,
      finishEndDate: null,
      startTime: null,
      endTime: null,
      hoverDate: null,
      dateModal: false,
      fullYear: null,
      month: null,
      currentDate: new Date(),
      dates: [],
      countFirstElems: null,
      countEndedElems: null,
      teamValue: null,
      completed: false,
      loadingVerify: false,
      successSubmitVerify: false,
      errors: null,
      birthdayDateValue: null,
      workStartDateValue: null,
      errorDateBirth: false,
      errorDateWork: false,
      phoneFirstContactValue: '',
      phoneSecondContactValue: '',
      emailContactValue: '',
      telegramContactValue: '',
      viberContactValue: '',
      watsUpContactValue: '',
      messagerContactValue: '',
      facebookContactValue: '',
      instagramContactValue: '',
      linkedinContactValue: '',
      twitterContactValue: '',
      inputHobbiesValue: '',
      errorFields: false,
      birthdayDateValueCount: -1,
      birthdayDateValueCountPress: 0,
      workStartDateValueCount: -1,
      workStartDateValueCountPress: 0
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }

  validItemEmpty = (item) => {
    let itemValue = this.state[item];
    if (itemValue !== null && itemValue !== undefined && typeof itemValue === 'string') {
      let onlySpace = /^\s*$/.test(itemValue);
      let itemCorrect;
      if(onlySpace){
        itemCorrect = itemValue;
      } else {
        itemCorrect = itemValue.trim();
      }
      this.setState({ [item]: itemCorrect });
      return itemCorrect;
    }

    return itemValue;
  }

  handlerGetDate (field, event) {
    event.preventDefault();
    let fullYear = this.state.date.getFullYear();
    let month = this.state.date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      activeFieldDate: field,
      dateModal: !prevState.dateModal,
      dates: arr,
      fullYear: fullYear,
      pushElemsTotal: pushElemsNum,
      month: month
    }));
  }
  getLastDayOfMonth (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDate();
  }
  getUnshiftElemsNum (year, month) {
    let jsDayNum = this.getFirstWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return realDayNum - 1;
  }
  getPushElemsNum (year, month) {
    let jsDayNum = this.getLastWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return 7 - realDayNum;
  }
  createArr (from, to) {
    const arr = [];
    for(let i = from; i <= to; i++) {
      arr.push(i);
    }
    return arr;
  }
  unshiftElems (num, lastDayPrevMonth, arr) {
    this.setState(prevState => ({
      countFirstElems: num
    }));
    let count =  lastDayPrevMonth - num;
    for(let i = lastDayPrevMonth; i > count; i--) {
      arr.unshift(i);
    }
    return arr;
  }
  pushElems (num, arr) {
    this.setState(prevState => ({
      countEndedElems: num
    }));
    for(let i = 1; i <= num; i++) {
      arr.push(i);
    }
    return arr;
  }
  chunkArr (num, arr) {
    let result = [];
    let chunk = [];
    let iterCount = arr.length / num;
    for(let i = 0; i <iterCount; i++) {
      chunk = arr.splice(0, num);
      result.push(chunk);
    }
    return result;
  }
  getRealDayOfWeekNum (jsNumOfDay) {
    if(jsNumOfDay === 0){
      return 7;
    } else {
      return jsNumOfDay;
    }
  }
  getFirstWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month, 1);
    return date.getDay();
  }
  getLastWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDay();
  }
  getMonthName (num) {
    const monthes = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'];
    return monthes[num];
  }
  handlerCloseGetDate (event) {
    event.preventDefault();
    this.setState(prevState => ({
      dateModal: false
    }));
  }
  handlerPrevMonth (event) {
    event.preventDefault();
    let date = new Date(this.state.date.getFullYear(), this.state.date.getMonth() - 1, this.state.date.getDate());
    let fullYear = date.getFullYear();
    let month = date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      date: date,
      dates: arr,
      fullYear: fullYear,
      month: month
    }));
  }
  handlerNextMonth (event) {
    event.preventDefault();
    let date = new Date(this.state.date.getFullYear(), this.state.date.getMonth() + 1, this.state.date.getDate());
    let fullYear = date.getFullYear();
    let month = date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      date: date,
      dates: arr,
      fullYear: fullYear,
      month: month
    }));
  }
  chooseDate (nextMonth, event) {
    event.preventDefault();
    this.setState(prevState => ({
      startDate: prevState.fullYear + '-' + prevState.month + '-' + event.target.innerHTML,
      finishStartDate: prevState.fullYear + '-' + ((prevState.month + 1) < 10 ? '0' + (prevState.month + 1) : prevState.month + 1) + '-' + (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML)
    }));
  }
  chooseEndHover (event) {
    event.preventDefault();
    this.setState(prevState => ({
      hoverDate: null
    }));
  }
  onKeyPress (event) {
    let input = event.target;
    if(event.charCode < 47 || event.charCode > 57) {
      event.preventDefault();
    }
    var len = input.value.length;
    if(len !== 1 || len !== 3) {
      if(event.charCode === 47) {
        event.preventDefault();
      }
    }
    if(len === 2) {
      input.value += '.';
    }
    if(len === 5) {
      input.value += '.';
    }
  }
  onKeyPress2 (event) {
    let input = event.target;
    if(event.charCode < 47 || event.charCode > 57) {
      event.preventDefault();
    }
    var len = input.value.length;
    if(len !== 1 || len !== 3) {
      if(event.charCode === 47) {
        event.preventDefault();
      }
    }
    if(len === 2) {
      input.value += ':';
    }
  }
  setDateInput (event) {
    event.preventDefault();
    if(this.state.activeFieldDate === 'date'){
      this.setState(prevState => ({
        chooseDateFull: prevState.finishStartDate
      }));
    } else if(this.state.activeFieldDate === 'birthday'){
      this.setState(prevState => ({
        birthday: prevState.finishStartDate
      }));
    }
    this.setState(prevState => ({
      dateModal: !prevState.dateModal
    }));
  }

  componentDidMount() {
    if((typeof window !== "undefined" && typeof document !== 'undefined')){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/roles`, {
        method: 'GET',
        headers: myHeaders
      })
      .then(res => res.json())
      .then((result) => {
          that.setState(prevState => ({
            roles: result
          }));
        fetch(`${that.props.queryLink}/api/v1/users/${that.props.id}`, {
          method: 'GET',
          headers: myHeaders,
        })
          .then(res => res.json())
          .then((result) => {
            let arr = result.roles.map((item, index) => {
              return that.state.roles.find(o => o.value === item);
            });
            that.setState({
              isLoadedUser: true,
              firstNameValue: result.name,
              lastNameValue: result.lastName,
              middleNameValue: result.middleName,
              positionValue: result.position,
              maritalStatusValue: result.maritalStatus,
              gender: result.gender ? result.gender : 'MALE',
              role: arr,
              birthdayDateValue: result.dateOfBirth ? result.dateOfBirth.split('-').reverse().join('.') : '',
              emailValue: result.email,
              hobbies: result.hobby,
              workStartDateValue: result.workStartDate ? result.workStartDate.split('-').reverse().join('.') : '',
              teamValue: result.team,
              completed: result.completed,
              emailContactValue: result.contacts.email,
              messagerContactValue: result.contacts.messenger,
              phoneFirstContactValue: result.contacts.phone,
              phoneSecondContactValue: result.contacts.secondaryPhone,
              telegramContactValue: result.contacts.telegram,
              viberContactValue: result.contacts.viber,
              watsUpContactValue: result.contacts.watsUp,
              facebookContactValue: result.socialNetworks.faceBook,
              instagramContactValue: result.socialNetworks.instagram,
              linkedinContactValue: result.socialNetworks.linkedin,
              twitterContactValue: result.socialNetworks.twitter
            });
            },
            (error) => {
              that.setState({
                isLoadedUser: true,
                errorUser: error
              });
            }
          );
        },
        (error) => {

        }
      );
      fetch(`${that.props.queryLink}/api/v1/teams/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "itemsPerPage": 20
        })
      })
        .then(res => res.json())
        .then((result) => {
          let teams = result.data.map((item, index) => {
            return {value: item.teamID, label: item.name}
          });
          that.setState(prevState => ({
            teamOptions: [...prevState.teamOptions, ...teams]
          }));
          },
          (error) => {

          }
        );
      })
  }
  handlerFirstNameValue(event){
    this.setState({
      firstNameValue: event.target.value
    });
  }
  handlerMiddleNameValue(event){
    this.setState({
      middleNameValue: event.target.value
    });
  }
  handlerLastNameValue(event){
    this.setState({
      lastNameValue: event.target.value
    });
  }
  handlerGender(gender, event){
    this.setState({
      gender: gender
    });
  }
  handlerPositionValue(event){
    this.setState({
      positionValue: event.target.value
    });
  }
  handlerRoleValue(event){
    this.setState(prevState => ({
      role: event.map(item => item)
    }));
  }
  handlerBirthday(event){
    this.setState({
      birthday: event
    });
  }
  handlerEmailValue(event){
    this.setState({
      emailValue: event.target.value
    });
  }
  handlerHobbies(event){
    this.setState({
      inputHobbiesValue: event
    });
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      if (event.target.value.trim() !== '') {
        const updatedHobbies = [...(this.state.hobbies || []), event.target.value];
        this.setState({ hobbies: updatedHobbies, inputHobbiesValue: '' });
      }
      event.preventDefault();
    }
  };
  handlerMaritalStatus(event) {
    this.setState({
      maritalStatusValue: event.value
    });
  }
  handlerTeamValue(event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      if(event.value){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/teams/${event.value}`, {
          method: 'GET',
          headers: myHeaders
        })
          .then(res => res.json())
          .then((result) => {
            that.setState({
              teamValue: result
            });
            },
            (error) => {

            }
          )
      } else {
        that.setState({
          teamValue: null
        });
      }
    })
  }
  handlerEditContacts = (field, event) => {
    let value = event.target.value;
    let isError = value.length > 80;

    // Ограничение на 80 символов
    if (isError) {
      value = value.slice(0, 80);
    }

    this.setState((prevState) => ({
      ...prevState,
      [field]: value,
      [`errorLength_${field}`]: isError,
    }));
  }


  handlerEditUserSubmit (event) {
    event.preventDefault();
    let that= this;
    let error = false;
    this.setState({
      isLoadedSubmit: true,
      errorDateBirth: false,
      errorDateWork: false,
      errorFields: false
    });
    if(that.state.birthdayDateValue !== '..' && that.state.birthdayDateValue !== ''){
      let currentDate = new Date();
      let dateComponents = that.state.birthdayDateValue.split('.');
      let specifiedDate = new Date(dateComponents[2], dateComponents[1] - 1, dateComponents[0]);
      if(currentDate <= specifiedDate){
        error = true;
        this.setState({
          errorDateBirth: true
        });
      }
    }
    if(that.state.workStartDateValue !== '..' && that.state.workStartDateValue !== ''){
      let currentDate = new Date();
      let dateComponents = that.state.workStartDateValue.split('.');
      let specifiedDate = new Date(dateComponents[2], dateComponents[1] - 1, dateComponents[0]);
      if(currentDate <= specifiedDate){
        error = true;
        this.setState({
          errorDateWork: true
        });
      }
    }
    var datePattern = /^\d{2}\.\d{2}\.\d{4}$/;
    if(!datePattern.test(that.state.birthdayDateValue) && that.state.birthdayDateValue !== '' && that.state.birthdayDateValue !== '..'){
      error = true;
      this.setState({
        errorDateBirth: true
      });
    }
    if(!datePattern.test(that.state.workStartDateValue) && that.state.workStartDateValue !== '' && that.state.workStartDateValue !== '..'){
      error = true;
      this.setState({
        errorDateWork: true
      });
    }
    if((that.state.birthdayDateValue !== '' && that.state.birthdayDateValue !== '..') && (that.state.workStartDateValue !== '' && that.state.workStartDateValue !== '..')){
      if(datePattern.test(that.state.birthdayDateValue) && datePattern.test(that.state.workStartDateValue)){
        const [day1, month1, year1] = that.state.birthdayDateValue.split('.');
        const [day2, month2, year2] = that.state.workStartDateValue.split('.');
        const date1 = new Date(`${year1}-${month1}-${day1}`);
        const date2 = new Date(`${year2}-${month2}-${day2}`);
        if(date1 > date2){
          error = true;
        }
      }
    }
    let firstName = that.validItemEmpty('firstNameValue');
    let middleName = that.validItemEmpty('middleNameValue');
    let lastName = that.validItemEmpty('lastNameValue');
    let positionValue = that.validItemEmpty('positionValue');
    let emailContactValue = that.validItemEmpty('emailContactValue');
    if(/^\s*$/.test(firstName) && firstName !== ''){
      error = true;
      this.setState({errorFirstNameOnlySpace: true });
    }
    if(/^\s*$/.test(middleName) && middleName !== ''){
      error = true;
      this.setState({errorMiddleNameOnlySpace: true });
    }
    if(/^\s*$/.test(lastName) && lastName !== ''){
      error = true;
      this.setState({errorLastNameOnlySpace: true });
    }
    if(/^\s*$/.test(positionValue) && positionValue !== ''){
      error = true;
      this.setState({errorPositionOnlySpace: true });
    }
    let messagerContactValue = that.validItemEmpty('messagerContactValue');
    if(/^\s*$/.test(messagerContactValue) && messagerContactValue !== ''){
      error = true;
      this.setState({errorMessagerOnlySpace: true });
    }
    let phoneFirstContactValue = that.validItemEmpty('phoneFirstContactValue');
    if(/^\s*$/.test(phoneFirstContactValue) && phoneFirstContactValue !== ''){
      error = true;
      this.setState({errorPhoneOnlySpace: true });
    }
    let phoneSecondContactValue = that.validItemEmpty('phoneSecondContactValue');
    if(/^\s*$/.test(phoneSecondContactValue) && phoneSecondContactValue !== ''){
      error = true;
      this.setState({errorSecondaryPhoneOnlySpace: true });
    }
    let telegramContactValue = that.validItemEmpty('telegramContactValue');
    if(/^\s*$/.test(telegramContactValue) && telegramContactValue !== ''){
      error = true;
      this.setState({errorTelegramOnlySpace: true });
    }
    let viberContactValue = that.validItemEmpty('viberContactValue');
    if(/^\s*$/.test(viberContactValue) && viberContactValue !== ''){
      error = true;
      this.setState({errorViberOnlySpace: true });
    }
    let watsUpContactValue = that.validItemEmpty('watsUpContactValue');
    if(/^\s*$/.test(watsUpContactValue) && watsUpContactValue !== ''){
      error = true;
      this.setState({errorWatsUpOnlySpace: true });
    }
    let facebookContactValue = that.validItemEmpty('facebookContactValue');
    if(/^\s*$/.test(facebookContactValue) && facebookContactValue !== ''){
      error = true;
      this.setState({errorFacebookOnlySpace: true });
    }
    let instagramContactValue = that.validItemEmpty('instagramContactValue');
    if(/^\s*$/.test(instagramContactValue) && instagramContactValue !== ''){
      error = true;
      this.setState({errorInstagramOnlySpace: true });
    }
    let linkedinContactValue = that.validItemEmpty('linkedinContactValue');
    if(/^\s*$/.test(linkedinContactValue) && linkedinContactValue !== ''){
      error = true;
      this.setState({errorLinkedinOnlySpace: true });
    }
    let twitterContactValue = that.validItemEmpty('twitterContactValue');
    if(/^\s*$/.test(twitterContactValue) && twitterContactValue !== ''){
      error = true;
      this.setState({errorTwitterOnlySpace: true });
    }
    if(firstName === '' || lastName === ''){
      error = true;
    }
    if(!error) {
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        let objSubmit = {
          "contacts": {
            "email": emailContactValue,
            "messenger": messagerContactValue,
            "phone": phoneFirstContactValue,
            "secondaryPhone": phoneSecondContactValue,
            "telegram": telegramContactValue,
            "viber": viberContactValue,
            "watsUp": watsUpContactValue
          },
          "dateOfBirth": that.state.birthdayDateValue === '..' || that.state.birthdayDateValue === '' ? null : that.state.birthdayDateValue,
          "name": firstName,
          "middleName": middleName,
          "lastName": lastName,
          "maritalStatus": that.state.maritalStatusValue,
          "gender": that.state.gender,
          "roles": that.state.role.map(x => x),
          "socialNetworks": {
            "faceBook": facebookContactValue,
            "instagram": instagramContactValue,
            "linkedin": linkedinContactValue,
            "twitter": twitterContactValue
          },
          "hobby": that.state.hobbies,
          "position": positionValue,
          "email": that.state.emailValue,
          "userID": that.props.id,
          "status": "ACTIVE",
          "team": that.state.teamValue,
          "workStartDate": that.state.workStartDateValue === '..' || that.state.workStartDateValue === '' ? null : that.state.workStartDateValue
        };
        fetch(`${that.props.queryLink}/api/v1/users/${that.props.id}`, {
          method: 'PUT',
          headers: myHeaders,
          body: JSON.stringify(objSubmit)
        }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors,
                  isLoadedSubmit: false
                });
              });
            } else {
              res.json().then(function(data) {
                if((typeof window !== "undefined" && typeof document !== 'undefined')){
                  localStorage.setItem("successDeposit", JSON.stringify({status: true, text: that.props.getTranslate('successStatus-Save')}))
                }
                that.setState({
                  successSubmit: true,
                  isLoadedSubmit: false
                });
              })
            }
        })
      })
    } else {
      this.setState({
        isLoadedSubmit: false,
        errorFields: true
      });
    }
  }
  handlerSendVerify(event){
    let that = this;
    that.setState({
      loadingVerify: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
      });
      let date = new Date(that.state.birthday);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      fetch(`${that.props.queryLink}/api/v1/users/${that.props.id}`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
          "dateOfBirth": that.state.birthdayDateValue.split('.').reverse().join('-'),
          "name": that.state.firstNameValue,
          "middleName": that.state.middleNameValue,
          "lastName": that.state.lastNameValue,
          "maritalStatus": that.state.maritalStatusValue,
          "gender": that.state.gender,
          "roles": that.state.role.map(x => x.value),
          "hobby": that.state.hobbies,
          "position": that.state.positionValue,
          "email": that.state.emailValue,
          "userID": that.props.id,
          "status": "ACTIVE",
          "team": that.state.teamValue,
          "workStartDate": that.state.workStartDateValue.split('.').reverse().join('-')
        })
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              loadingVerify: false
            })
          });
        } else {
          fetch(`${that.props.queryLink}/api/v1/users/${that.props.id}/invites`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
              "companyName": that.props.companyName,
              "email": that.state.emailValue,
              "userID": that.props.id
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors,
                  loadingVerify: false
                })
              });
            } else {
             that.setState({
                successSubmitVerify: true,
                loadingVerify: false
              });
            }
      })
        }
      })
    })
  }
  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successSubmitVerify: false
    }));
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerBirthdayDate(event){
    if(this.state.errorDateBirth){
      this.setState(prevState => ({
        errorDateBirth: false
      }));
    }
    let birthdayDateValueCount = this.state.birthdayDateValueCount + 1;
    this.setState(prevState => ({
      birthdayDateValue: event,
      birthdayDateValueCount: birthdayDateValueCount
    }));
  }
  handlerBirthdayDateError(event){
    let birthdayDateValueCount = this.state.birthdayDateValueCount;
    let birthdayDateValueCountPress = this.state.birthdayDateValueCountPress;
    if(event.code === 'Backspace'){
      birthdayDateValueCountPress = 0;
      birthdayDateValueCount = 0;
      this.setState(prevState => ({
        errorDateBirth: false
      }));
    } else {
      birthdayDateValueCountPress = this.state.birthdayDateValueCountPress + 1;
    }
    this.setState(prevState => ({
      birthdayDateValueCountPress: birthdayDateValueCountPress,
      birthdayDateValueCount: birthdayDateValueCount
    }));
    if(birthdayDateValueCount < birthdayDateValueCountPress){
      this.setState(prevState => ({
        errorDateBirth: true
      }));
    } else {
      this.setState(prevState => ({
        errorDateBirth: false
      }));
    }
  }
  handlerWorkStartDate(event){
    if(this.state.errorDateWork){
      this.setState(prevState => ({
        errorDateWork: false
      }));
    }
    let workStartDateValueCount = this.state.workStartDateValueCount + 1;
    this.setState(prevState => ({
      workStartDateValue: event,
      workStartDateValueCount: workStartDateValueCount
    }));
  }
  handlerWorkStartDateError(event){
    let workStartDateValueCount = this.state.workStartDateValueCount;
    let workStartDateValueCountPress = this.state.workStartDateValueCountPress;
    if(event.code === 'Backspace'){
      workStartDateValueCountPress = 0;
      workStartDateValueCount = 0;
      this.setState(prevState => ({
        errorDateWork: false
      }));
    } else {
      workStartDateValueCountPress = this.state.workStartDateValueCountPress + 1;
    }
    this.setState(prevState => ({
      workStartDateValueCountPress: workStartDateValueCountPress,
      workStartDateValueCount: workStartDateValueCount
    }));
    if(workStartDateValueCount < workStartDateValueCountPress){
      this.setState(prevState => ({
        errorDateWork: true
      }));
    } else {
      this.setState(prevState => ({
        errorDateWork: false
      }));
    }
  }
  handleRemoveHobby = (removedHobby) => {
    const updatedHobbies = this.state.hobbies.filter((hobby) => hobby !== removedHobby.value);
    this.setState({ hobbies: updatedHobbies });
  };
  render() {
    console.log(this.state);
    if((typeof window !== "undefined" && typeof document !== 'undefined')){
      document.title = this.props.getTranslate('employees-EditUserTitle') + ' | ' + this.props.companyName;
    }
    let roles = this.state.roles.map((item) => {
      if (item === 'ROLE_ADMINISTRATOR') {
        return { label: this.props.getTranslate('employees-userAccessAdminLabel'), value: item };
      } else {
        return { label: this.props.getTranslate('employees-userAccessEmployeesLabel'), value: item };
      }
    });
    let maritalStatusMale = [
      {'label': this.props.getTranslate('employees-UserMaritalStatusManYes'), 'value': 'MARRIED'},
      {'label': this.props.getTranslate('employees-UserMaritalStatusManNo'), 'value': 'SINGLE'}
    ];
    let maritalStatusFemale = [
      {'label': this.props.getTranslate('employees-UserMaritalStatusWomanYes'), 'value': 'MARRIED'},
      {'label': this.props.getTranslate('employees-UserMaritalStatusWomanNo'), 'value': 'SINGLE'}
    ];
    let totalItem = this.state.dates.length * 7;
    let totalItemNotPush = totalItem - this.state.pushElemsTotal;
    let endedItems = totalItem - this.state.countEndedElems;
    let iter = 0;
    let tableDate = this.state.dates.map((item, index) => {
      return <tr key={index}>
        {item.map((elem, i) => {
          iter++;
          if((this.state.date > this.state.currentDate && this.state.date.getMonth() !== this.state.currentDate.getMonth()) || (index === 0 && this.state.countFirstElems > i && this.state.month === this.state.currentDate.getMonth()) || endedItems < iter){
            return <td className="ended" key={i}>{elem}</td>
          } else if(this.state.date <= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + elem) && (this.state.date.getMonth() + 1) >= (this.state.currentDate.getMonth() + 1) && this.state.date.getFullYear() >= this.state.currentDate.getFullYear()){
            return <td className="ended" key={i}>{elem}</td>
          } else if(iter <= this.state.countFirstElems){
            return <td className="ended" key={i}>{elem}</td>
          } else if((new Date(this.state.startDate).getDate() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getDate() && new Date(this.state.startDate).getMonth() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getMonth() && new Date(this.state.startDate).getFullYear() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getFullYear()) || (this.state.fullYear + '.' + this.state.month + '.' + elem === this.state.startDate) || (this.state.fullYear + '.' + this.state.month + '.' + elem === this.state.endDate)) {
            return <td className="checked" key={i} onClick={this.chooseDate.bind(this, iter > totalItemNotPush ? true : false)}>{elem}</td>
          } else if((new Date(this.state.startDate) <= new Date(this.state.fullYear + '.' + this.state.month + '.' + elem) && new Date(this.state.endDate) >= new Date(this.state.fullYear + '.' + this.state.month + '.' + elem)) || new Date(this.state.startDate) <= new Date(this.state.fullYear + '.' + this.state.month + '.' + elem) && new Date(this.state.hoverDate) >= new Date(this.state.fullYear + '.' + this.state.month + '.' + elem)) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter > totalItemNotPush ? true : false)}>{elem}</td>
          } else if(iter <= this.state.countFirstElems && (this.state.endDate || this.state.hoverDate)){
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter > totalItemNotPush ? true : false)}>{elem}</td>
          } else {
            return <td key={i} onClick={this.chooseDate.bind(this, iter > totalItemNotPush ? true : false)}>{elem}</td>
          }
        })}
      </tr>
    });
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    <ResultQuery success={this.state.successSubmitVerify} resultText={`Підтвердження відправлено на ${this.state.emailValue}`} handlerSuccess={this.handlerCloseSuccess} handlerError={this.handlerCloseError} />
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap">
      <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
      {this.state.successSubmit && <RedirectPage />}
      {this.state.isLoadedUser ? <div className="account-content">
        <div className="account-content__inner">

          {/* Account header component */}
          <AccountContentHeader
            handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
            languageActive={this.props.chooseLanguage}
            prevPageBtn={<PrevPage text={this.props.getTranslate('buttonBack-buttonText')} />}
          />

          <div className="account-content__header-title">
            <h1 className="account-content__headline">{this.props.getTranslate('employees-EditUserTitle')}</h1>
          </div>
          <div className="edit-user-form-wrap">
            <form className="edit-user-form" onSubmit={this.handlerEditUserSubmit.bind(this)}>
            <div className="edit-user-form__inner">
              <div className="edit-user-form__row">
                <div className="edit-user-form__col edit-user-form__col_size_3">
                  <div className="field-wrap">
                    <label className="field-wrap__label" htmlFor="surname">{this.props.getTranslate('employees-EditUserLastName')}</label>
                    <input id="surname" className={`field-wrap__input ${(this.state.errorFields && this.state.lastNameValue === '') || this.state.errorLastNameOnlySpace ? 'field-wrap__input_error' : ''}`} onChange={this.handlerLastNameValue.bind(this)} value={this.state.lastNameValue ? this.state.lastNameValue : ''} placeholder={this.props.getTranslate('employees-EditUserLastNameFieldCaption')} />
                    {this.state.errorFields && this.state.lastNameValue === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                    {this.state.errorLastNameOnlySpace ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                  </div>
                </div>
                <div className="edit-user-form__col edit-user-form__col_size_3">
                  <div className="field-wrap">
                    <label className="field-wrap__label" htmlFor="firstname">{this.props.getTranslate('employees-EditUserName')}</label>
                    <input id="firstname" className={`field-wrap__input ${(this.state.errorFields && this.state.firstNameValue === '') || this.state.errorFirstNameOnlySpace ? 'field-wrap__input_error' : ''}`} onChange={this.handlerFirstNameValue.bind(this)} value={this.state.firstNameValue ? this.state.firstNameValue : ''} placeholder={this.props.getTranslate('employees-EditUserNameFieldCaption')} />
                    {this.state.errorFields && this.state.firstNameValue === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                    {this.state.errorFirstNameOnlySpace ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                  </div>
                </div>
                <div className="edit-user-form__col edit-user-form__col_size_3">
                  <div className="field-wrap">
                    <label className="field-wrap__label" htmlFor="lastname">{this.props.getTranslate('employees-EditUserMidlName')}</label>
                    <input id="lastname" className={`field-wrap__input ${this.state.errorMiddleNameOnlySpace ? 'field-wrap__input_error' : ''}`} onChange={this.handlerMiddleNameValue.bind(this)} value={this.state.middleNameValue ? this.state.middleNameValue : ''} placeholder={this.props.getTranslate('employees-EditUserMIdlNameFieldCaption')} />
                    {this.state.errorMiddleNameOnlySpace ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                  </div>
                </div>
               </div>
               <div className="edit-user-form__row">
                 <div className="edit-user-form__col edit-user-form__col_size_3">
                   <div className="field-wrap questions-form__date">
                     <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="birthday">{this.props.getTranslate('employees-EditUserDate')}</label>
                     <div className="questions-form__date-inner">
                       <svg className="questions-form__date-icon">
                         <use href={`${sprite}#date-icon`}></use>
                       </svg>
                        <IMaskInput
                          className={`field-wrap__input questions-form__date-field ${this.state.errorDateBirth ? 'field-wrap__input_error' : ''}`}
                          mask={Date}
                          min={new Date(1900, 0, 1)}
                          max={new Date()}
                          lazy={false}
                          unmask={true}
                          value={this.state.birthdayDateValue}
                          onAccept={this.handlerBirthdayDate.bind(this)}
                          onKeyUp={this.handlerBirthdayDateError.bind(this)}
                        />
                     </div>
                     {this.state.errorDateBirth ? <div className="validation-error">{this.props.getTranslate('validation-errorDateBirth')}</div> : ''}
                   </div>
                 </div>
               </div>
               <div className="edit-user-form__row">
                 <div className="edit-user-form__col edit-user-form__col_size_3">
                   <div className="field-wrap">
                    <label className="field-wrap__label">{this.props.getTranslate('employees-EditUserMaritalStatus')}</label>
                    <Select
                      className="field-wrap__select"
                      styles={customStyles}
                      options={this.state.gender === 'MALE' ? maritalStatusMale : maritalStatusFemale}
                      placeholder={this.props.getTranslate('employees-EditUserMaritalStatusPlaceholder')}
                      value={this.state.gender === 'MALE' ? maritalStatusMale[maritalStatusMale.findIndex(el => el.value === this.state.maritalStatusValue)] : maritalStatusFemale[maritalStatusFemale.findIndex(el => el.value === this.state.maritalStatusValue)]}
                      isSearchable={false}
                      promptTextCreator={() => 'Search'}
                      onChange={this.handlerMaritalStatus.bind(this)}
                    />
                    </div>
                  </div>
                 <div className="edit-user-form__col edit-user-form__col_size_3">
                   <div className="field-wrap">
                     <label className="field-wrap__label">{this.props.getTranslate('employees-EditUserGender')}</label>
                      <div className="edit-user-form__radio">
                        <div className="edit-user-form__radio-item">
                          <input type="radio" id="f-option" name="gender" onChange={this.handlerGender.bind(this, 'MALE')} checked={this.state.gender === 'MALE' ? true : false} />
                          <label htmlFor="f-option">{this.props.getTranslate('employees-EditUserGenderMale')}</label>
                          <div className="check" onClick={this.handlerGender.bind(this, 'MALE')}></div>
                        </div>
                        <div className="edit-user-form__radio-item">
                          <input type="radio" id="s-option" name="gender" onChange={this.handlerGender.bind(this, 'FEMALE')} checked={this.state.gender === 'FEMALE' ? true : false} />
                          <label htmlFor="s-option">{this.props.getTranslate('employees-EditUserGenderFemale')}</label>
                          <div className="check" onClick={this.handlerGender.bind(this, 'FEMALE')}></div>
                        </div>
                      </div>
                   </div>
                 </div>
                </div>
               <div className="edit-user-form__row">
                 <div className="edit-user-form__col edit-user-form__col_size_3">
                   <div className="field-wrap">
                     <label className="field-wrap__label" htmlFor="position">{this.props.getTranslate('employees-EditUserPosition')}</label>
                     <input id="position" className={`field-wrap__input ${this.state.errorPositionOnlySpace ? 'field-wrap__input_error' : ''}`} onChange={this.handlerPositionValue.bind(this)} value={this.state.positionValue ? this.state.positionValue : ''} placeholder={this.props.getTranslate('employees-EditUserPositionFieldCaption')} />
                     {this.state.errorPositionOnlySpace ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                   </div>
                 </div>
                 <div className="edit-user-form__col edit-user-form__col_size_3">
                   <div className="field-wrap">
                    <label className="field-wrap__label">{this.props.getTranslate('employees-EditUserTeam')}</label>
                    {this.state.teamOptions ? <Select
                      className="field-wrap__select"
                      styles={customStyles}
                      options={this.state.teamOptions}
                      value={this.state.teamValue ? {value: this.state.teamValue.teamID, label: this.state.teamValue.name} : {label: this.props.getTranslate('employees-EditWithoutTeam'), value: null}}
                      onChange={this.handlerTeamValue.bind(this)}
                      placeholder={this.props.getTranslate('employees-EditUserTeamSelect')}
                      isSearchable={false}
                    /> : ''}
                    </div>
                  </div>
                 <div className="edit-user-form__col edit-user-form__col_size_3">
                   <div className="field-wrap">
                    <label className="field-wrap__label">{this.props.getTranslate('employees-EditUserAccess')}</label>
                    {this.state.role && this.state.roles ? <Select
                      className="field-wrap__select"
                      styles={customStyles}
                      options={roles}
                      defaultValue={this.state.role}
                      onChange={this.handlerRoleValue.bind(this)}
                      placeholder={this.props.getTranslate('employees-EditUserAccessPlaceholder')}
                      isSearchable={false}
                      isClearable={false}
                      isMulti
                    /> : ''}
                    </div>
                  </div>
                  <div className="edit-user-form__col edit-user-form__col_size_3">
                    <div className="field-wrap questions-form__date">
                      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="date">{this.props.getTranslate('employees-EditUserWorkDate')}</label>
                      <div className="questions-form__date-inner">
                        <svg className="questions-form__date-icon">
                          <use href={`${sprite}#date-icon`}></use>
                        </svg>
                        <IMaskInput
                          className={`field-wrap__input questions-form__date-field ${this.state.errorDateWork ? 'field-wrap__input_error' : ''}`}
                          mask={Date}
                          min={new Date(1900, 0, 1)}
                          max={new Date()}
                          lazy={false}
                          unmask={true}
                          value={this.state.workStartDateValue}
                          onAccept={this.handlerWorkStartDate.bind(this)}
                          onKeyUp={this.handlerWorkStartDateError.bind(this)}
                        />
                      </div>
                      {this.state.errorDateWork ? <div className="validation-error">{this.props.getTranslate('validation-errorDateWork')}</div> : ''}
                    </div>
                  </div>
               </div>
               <div className="edit-user-form__row">
                <div className="edit-user-form__col edit-user-form__col_size_3">
                  <label className="field-wrap__label">{this.props.getTranslate('employees-EditUserContacts')}</label>
                  <div className="field-wrap">
                    <div className="field-wrap__input-contacts">
                      <input className={`field-wrap__input ${this.state.errorPhoneOnlySpace || this.state.errorLength_phoneFirstContactValue ? 'field-wrap__input_error' : ''}`} placeholder="Phone number" onChange={(event) => this.handlerEditContacts('phoneFirstContactValue', event)} value={this.state.phoneFirstContactValue} />
                      <img className="field-wrap__input-contacts-icon" src={contactPhone} alt="" />
                    </div>
                    {this.state.errorPhoneOnlySpace ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                    {this.state.errorLength_phoneFirstContactValue ? <div className="validation-error">Довжина не може перевищувати 80 символів</div> : ''}
                  </div>
                  <div className="field-wrap">
                    <div className="field-wrap__input-contacts">
                      <input className={`field-wrap__input ${this.state.errorSecondaryPhoneOnlySpace || this.state.errorLength_phoneSecondContactValue ? 'field-wrap__input_error' : ''}`} placeholder="Second Phone number" onChange={(event) => this.handlerEditContacts('phoneSecondContactValue', event)} value={this.state.phoneSecondContactValue} />
                      <img className="field-wrap__input-contacts-icon" src={contactPhone} alt="" />
                    </div>
                    {this.state.errorSecondaryPhoneOnlySpace ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                    {this.state.errorLength_phoneSecondContactValue ? <div className="validation-error">Довжина не може перевищувати 80 символів</div> : ''}
                  </div>
                  <div className="field-wrap">
                    <div className="field-wrap__input-contacts">
                      <input className={`field-wrap__input ${this.state.errorEmailOnlySpace || this.state.errorLength_emailContactValue ? 'field-wrap__input_error' : ''}`} placeholder="E-mail" onChange={(event) => this.handlerEditContacts('emailContactValue', event)} value={this.state.emailContactValue} />
                      <img className="field-wrap__input-contacts-icon" src={contactEmail} alt="" />
                    </div>
                    {this.state.errorEmailOnlySpace ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                    {this.state.errorLength_emailContactValue ? <div className="validation-error">Довжина не може перевищувати 80 символів</div> : ''}
                  </div>
                  <div className="field-wrap">
                    <div className="field-wrap__input-contacts">
                      <input className={`field-wrap__input ${this.state.errorTelegramOnlySpace || this.state.errorLength_telegramContactValue ? 'field-wrap__input_error' : ''}`} placeholder="Telegram" onChange={(event) => this.handlerEditContacts('telegramContactValue', event)} value={this.state.telegramContactValue} />
                      <img className="field-wrap__input-contacts-icon" src={contactTelegram} alt="" />
                    </div>
                    {this.state.errorTelegramOnlySpace ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                    {this.state.errorLength_telegramContactValue ? <div className="validation-error">Довжина не може перевищувати 80 символів</div> : ''}
                  </div>
                  <div className="field-wrap">
                    <div className="field-wrap__input-contacts">
                      <input className={`field-wrap__input ${this.state.errorViberOnlySpace || this.state.errorLength_viberContactValue ? 'field-wrap__input_error' : ''}`} placeholder="Viber" onChange={(event) => this.handlerEditContacts('viberContactValue', event)} value={this.state.viberContactValue} />
                      <img className="field-wrap__input-contacts-icon" src={contactViber} alt="" />
                    </div>
                    {this.state.errorViberOnlySpace ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                    {this.state.errorLength_viberContactValue ? <div className="validation-error">Довжина не може перевищувати 80 символів</div> : ''}
                  </div>
                  <div className="field-wrap">
                    <div className="field-wrap__input-contacts">
                      <input className={`field-wrap__input ${this.state.errorMessagerOnlySpace || this.state.errorLength_messagerContactValue ? 'field-wrap__input_error' : ''}`} placeholder="Messenger" onChange={(event) => this.handlerEditContacts('messagerContactValue', event)} value={this.state.messagerContactValue} />
                      <img className="field-wrap__input-contacts-icon" src={contactMessanger} alt="" />
                    </div>
                    {this.state.errorMessagerOnlySpace ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                    {this.state.errorLength_messagerContactValue ? <div className="validation-error">Довжина не може перевищувати 80 символів</div> : ''}
                  </div>
                </div>
                <div className="edit-user-form__col edit-user-form__col_size_3">
                  <label className="field-wrap__label">{this.props.getTranslate('employees-EditUserSocialNetworks')}</label>
                  <div className="field-wrap">
                    <div className="field-wrap__input-contacts">
                      <input className={`field-wrap__input ${this.state.errorFacebookOnlySpace || this.state.errorLength_facebookContactValue ? 'field-wrap__input_error' : ''}`} placeholder="Facebook" onChange={(event) => this.handlerEditContacts('facebookContactValue', event)} value={this.state.facebookContactValue} />
                      <img className="field-wrap__input-contacts-icon" src={contactFacebook} alt="" />
                    </div>
                    {this.state.errorFacebookOnlySpace ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                    {this.state.errorLength_facebookContactValue ? <div className="validation-error">Довжина не може перевищувати 80 символів</div> : ''}
                  </div>
                  <div className="field-wrap">
                    <div className="field-wrap__input-contacts">
                      <input className={`field-wrap__input ${this.state.errorInstagramOnlySpace || this.state.errorLength_instagramContactValue ? 'field-wrap__input_error' : ''}`} placeholder="Instagram" onChange={(event) => this.handlerEditContacts('instagramContactValue', event)} value={this.state.instagramContactValue} />
                      <img className="field-wrap__input-contacts-icon" src={contactInstagram} alt="" />
                    </div>
                    {this.state.errorInstagramOnlySpace ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                    {this.state.errorLength_instagramContactValue ? <div className="validation-error">Довжина не може перевищувати 80 символів</div> : ''}
                  </div>
                  <div className="field-wrap">
                    <div className="field-wrap__input-contacts">
                      <input className={`field-wrap__input ${this.state.errorLinkedinOnlySpace || this.state.errorLength_linkedinContactValue ? 'field-wrap__input_error' : ''}`} placeholder="LinkedIn" onChange={(event) => this.handlerEditContacts('linkedinContactValue', event)} value={this.state.linkedinContactValue} />
                      <img className="field-wrap__input-contacts-icon" src={contactLinkedin} alt="" />
                    </div>
                    {this.state.errorLinkedinOnlySpace ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                    {this.state.errorLength_linkedinContactValue ? <div className="validation-error">Довжина не може перевищувати 80 символів</div> : ''}
                  </div>
                  <div className="field-wrap">
                    <div className="field-wrap__input-contacts">
                      <input className={`field-wrap__input ${this.state.errorTwitterOnlySpace || this.state.errorLength_twitterContactValue ? 'field-wrap__input_error' : ''}`} placeholder="Twitter (X)" onChange={(event) => this.handlerEditContacts('twitterContactValue', event)} value={this.state.twitterContactValue} />
                      <img className="field-wrap__input-contacts-icon" src={contactTwitter} alt="" />
                    </div>
                    {this.state.errorTwitterOnlySpace ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                    {this.state.errorLength_twitterContactValue ? <div className="validation-error">Довжина не може перевищувати 80 символів</div> : ''}
                  </div>
                </div>
               </div>
               <div className="edit-user-form__row">
               <div className="edit-user-form__col edit-user-form__col_size_1">
                <div className="field-wrap">
                <label className="field-wrap__label">{this.props.getTranslate('employees-EditUserHobbie')}</label>
                {this.state.hobbies ? <CreatableSelect
                  className="field-wrap__select"
                  onChange={(selectedOptions, actionMeta) => {
                    if (actionMeta.action === 'remove-value' || actionMeta.action === 'pop-value') {
                      this.handleRemoveHobby(actionMeta.removedValue);
                    }
                  }}
                  styles={customStyles}
                  placeholder={this.props.getTranslate('employees-EditUserHobbieCaption')}
                  value={this.state.hobbies.reduce((acc, n, i) => ([ ...acc, {label: n, value: this.state.hobbies[i]} ]), [])}
                  onInputChange={this.handlerHobbies.bind(this)}
                  onKeyDown={this.handleKeyDown.bind(this)}
                  isClearable={false}
                  menuIsOpen={false}
                  components={{DropdownIndicator: null}}
                  inputValue={this.state.inputHobbiesValue}
                  isMulti
                /> : <CreatableSelect
                  className="field-wrap__select"
                  onChange={(selectedOptions, actionMeta) => {
                    if (actionMeta.action === 'remove-value' || actionMeta.action === 'pop-value') {
                      this.handleRemoveHobby(actionMeta.removedValue);
                    }
                  }}
                  styles={customStyles}
                  placeholder={this.props.getTranslate('employees-EditUserHobbieCaption')}
                  onInputChange={this.handlerHobbies.bind(this)}
                  onKeyDown={this.handleKeyDown.bind(this)}
                  isClearable={false}
                  menuIsOpen={false}
                  inputValue={this.state.inputHobbiesValue}
                  components={{DropdownIndicator: null}}
                  isMulti
                />}
                 </div>
                 </div>
                 </div>
                 <div className="edit-user-form__data-login">
                   <div className="edit-user-form__headline">{this.props.getTranslate('employees-EditUserLoginData')}</div>
                   <div className="edit-user-form__row">
                      {this.state.completed ? <div className="edit-user-form__col edit-user-form__col_size_3">
                        <div className="field-wrap">
                          <label className="field-wrap__label" htmlFor="email">Email</label>
                          <input id="email" className="field-wrap__input" onChange={this.handlerEmailValue.bind(this)} value={this.state.emailValue} />
                        </div>
                      </div> : ''}
                      {!this.state.completed ? <div className="edit-user-form__col edit-user-form__col_size_3 edit-user-form__activation-wrap">
                        <div>
                          <div className="edit-user-form__activation">
                            <div>{this.props.getTranslate('employees-EditUserWaitActivation')}</div>
                            <svg className="edit-user-form__activation-icon">
                              <use href={`${sprite}#reload-icon`}></use>
                            </svg>
                          </div>
                          <div className="activation-verify-btn">
                            <button className="edit-user-form__send" type="button" onClick={this.handlerSendVerify.bind(this)}>{this.props.getTranslate('employees-EditUserSendVerify')}</button>
                            {this.state.loadingVerify ? <Loading /> : ''}
                          </div>
                        </div>
                      </div> : ''}
                   </div>
                  </div>
                 </div>
                 <div className="field-wrap__submit field-wrap__submit-padding-none">
                   <button className="btn btn_size_lg" type="submit" disabled={this.state.isLoadedSubmit ? true : false}>{this.props.getTranslate('employees-EditSubmit')}{this.state.isLoadedSubmit && <Loading />}</button>
                 </div>
            </form>
          </div>
        </div>
      </div> : <div className="account-content"><Loading /></div>}
    </div>
    {this.state.dateModal && <div className="date-modal">
      <div className="date-modal__overlay" onClick={this.handlerCloseGetDate.bind(this)}></div>
      <div className="date-modal__content">
        <div className="date-modal__header">
          <div className="date-modal__headline">{this.props.getTranslate('employees-EditUserDateHolding')}</div>
          <button className="date-modal__close-modal" type="button" onClick={this.handlerCloseGetDate.bind(this)}>
            <svg className="date-modal__close-modal-icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <div id="calendar" className="calendar">
          <div className="calendar__info">
            <button className="calendar__month-nav" type="button" onClick={this.handlerPrevMonth.bind(this)}>
              <svg className="calendar__month-nav-icon">
                <use href={`${sprite}#chevron-left`}></use>
              </svg>
            </button>
            <div className="calendar__month-year">{this.getMonthName(this.state.date.getMonth())}, {this.state.date.getFullYear()}</div>
            <button className="calendar__month-nav" type="button" onClick={this.handlerNextMonth.bind(this)}>
              <svg className="calendar__month-nav-icon">
                <use href={`${sprite}#chevron-right`}></use>
              </svg>
            </button>
          </div>
          <table className="calendar-table">
            <thead className="calendar-table__head">
              <tr>
                <th>ПН</th>
                <th>ВТ</th>
                <th>СР</th>
                <th>ЧТ</th>
                <th>ПТ</th>
                <th>СБ</th>
                <th>ВС</th>
              </tr>
            </thead>
            <tbody className="calendar-table__dates">{tableDate}</tbody>
          </table>
        </div>
        <div className="date-modal__nav">
          <div className="date-modal__nav-item">
            <button className="btn btn_width_100 form-nav__submit" type="button" onClick={this.setDateInput.bind(this)}>Добавить</button>
          </div>
          <div className="date-modal__nav-item">
            <button className="btn btn_width_100 btn_theme_gray" type="button" onClick={this.handlerCloseGetDate.bind(this)}>Закрыть</button>
          </div>
        </div>
      </div>
    </div>}
    </>;
  }
}
export default (props) => <UsersEdit {...useParams()} {...props} />
