import React from "react";
import sprite from '../media/icons.svg';
class FilterSearch extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <form className={`form-search-orders ${this.props.className ? this.props.className : ''}`}>
            <div className="search-field-wrap">
              <button className="search-submit" type="submit">
                <svg className="form-search-orders__icon"><use href={`${sprite}#search-icon`}></use></svg>
              </button>
              <input className="form-search-orders__field" placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.handler.bind(this)} type="text" />
            </div>
          </form>
  }
}
export default FilterSearch;
