import React from "react";
import Loading from './components/Loading';
import DateFormating from './components/DateFormating';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link } from "react-router-dom";
import ModalAddUsers from './components/ModalAddUsers';
import ResultQuery from './components/ResultQuery';
import ResultErrors from './components/ResultErrors';
import AccountContentHeader from './components/AccountContentHeader';
import AccountContentEmpty from './components/AccountContentEmpty';
import DeleteModal from './components/DeleteModal';
import Button from './components/Button';
import Table from './components/Table';
import Pagination from './components/Pagination';
import Filter from './components/Filter';
import FilterSearch from './components/FilterSearch';
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import sprite from './media/icons.svg';
import events_img from './media/events.png';
import { getAuth } from "firebase/auth";
class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      events: [],
      totalPages: null,
      currentPage: 0,
      errorEvents: null,
      dataEventModal: [],
      modalEvent: false,
      isLoadedEvents: false,
      indexRowEvent: null,
      removeIndex: null,
      successEventSubmit: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("successEventSubmit") ? JSON.parse(localStorage.getItem("successEventSubmit")) : null) : '',
      errors: null,
      error: false,
      isLoaded: false,
      modalDeleteEvents: false,
      idDeleteEvents: null,
      heightInfo: 'auto',
      modalTab: 'USERS',
      controlRow: null,
      addUserModalStatus: false,
      addBonusesUserModalStatus: false,
      addUserLoading: false,
      successAddUser: false,
      successText: null,
      dataEventModalIndex: null,
      isLoadedSearch: false,
      searchEventValue: '',
      searchEmpty: false,
      addUserData: [],
      searchUserValue: '',
      addUserIndex: null,
      addUserContent: false,
      clickedInside: false,
      scrollPageAddUser: 1,
      heightThumb: null,
      scrollBarThumb: false
    }
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidUpdate() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      setTimeout(() => {
        let thumbVertical = document.querySelector('.scrollbar-users .thumb-vertical');
        if (thumbVertical) {
          let heightStyle = window.getComputedStyle(thumbVertical).height;
          let heightValue = parseFloat(heightStyle);

          if (heightValue > 0) {
            this.setState(prevState => ({
              scrollBarThumb: true
            }));
          }
        }
      }, 10)
    }
  }

  /* Handler for get mobile menu */
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };

  /* Handler for dropdown */
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }

  /* Pagination handler */
  handlerPaginationItems = (eventsItems, current) => {
    this.setState({
      events: eventsItems,
      currentPage: current
    });
  }

  /* Handler for close success labels */
  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successEventSubmit: null,
      successAddUser: false,
      successText: null
    }));
  }

  /* Handler for close errors labels */
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }

  /* Events disabled modal */
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
      dataEventModal: []
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modalEvent: false,
        modalDeleteEvents: false,
        idDeleteEvents: null,
        modalTab: 'USERS',
        heightInfo: 'auto',
        addUserContent: false,
        heightThumb: null
      }));
    }, 500);
  }

  /* Handler get data mount component */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/events/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "eventType": "EVENT",
          "itemsPerPage": 20,
          "orderField": "creationDate"
        })
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoaded: true,
              errors: data.errors,
              error: true
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              isLoaded: true,
              events: data.data,
              totalPages: data.totalPages
            });
          })
        }
      })
    })
  }

  /* Handler search items */
  handlerSearch(event) {
    let that = this;
      this.setState({
      isLoadedSearch: true,
      searchEventValue: event.target.value,
      searchEmpty: false
    });
    let timeoutId = setTimeout(() => {
      getAuth().currentUser.getIdToken().then(function(idToken) {
              const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/events/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({"itemsPerPage": 20, "query": that.state.searchEventValue})
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoadedSearch: false
              });
            });
          } else {
            res.json().then(function(data) {
              that.setState({
                isLoadedSearch: false,
                events: data.data,
                totalPages: data.totalPages
              });
              if(data.data.length < 1){
                that.setState({
                  searchEmpty: true
                });
              }
            });
          }
        });
      });
    }, 500);
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = timeoutId;
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handlerClickInside(event){
    this.setState({
      addUserContent: false
    });
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        addUserContent: false
      });
    }
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setWrapperRefButton(node){
    this.wrapperRefButton = node;
  }

  /* Event open modal info */
  handlerModalGetEvent(id, index, event) {
      let that = this;
      this.setState(prevState => ({
        scrollBarThumb: false
      }));
      if(!event.target.closest('.control-wgt')) {
        getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/events/${id}`, {
          method: 'GET',
          headers: myHeaders,
        }).then(res => {
          if (res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState(prevState => ({
                dataEventModal: data,
                dataEventModalIndex: index,
                modalEvent: true
              }));
              setTimeout(function() {
                if(typeof window !== "undefined" && typeof document !== 'undefined') {
                  let infoDiv = document.querySelector('.modal__order-content-col-info');
                  let height = infoDiv.clientHeight;
                  that.setState(prevState => ({
                    heightInfo: height
                  }));
                }
              }, 500);
            })
          }
        })
      })
    }
  }

  handlerDeleteEventModal(id, index, event){
    if(this.state.modalEvent){
      if(typeof window !== "undefined" && typeof document !== 'undefined') {
        document.querySelector('body').style.overflow = "";
      }
        this.setState(prevState => ({
          modalDisabled: true
        }));
        setTimeout(() => {
          this.setState(prevState => ({
            modalDisabled: false,
            modalEvent: false,
            modalDeleteEvents: true
          }));
        }, 500);
    }
    this.setState({
      modalDeleteEvents: true,
      removeIndex: index,
      idDeleteEvents: id
    });
  }

  handlerEnabled(id, index, event){
    let that = this;
    let arr = that.state.events;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/events/${id}/status`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
          "eventStatus": arr[index].eventStatus === 'NOT_ACTIVE' ? 'ACTIVE' : 'NOT_ACTIVE'
        })
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            let count = arr[index].acceptedCount;
            let employeeCount = arr[index].employeeCount;
            let dataResponce = data;
            dataResponce.acceptedCount = count;
            dataResponce.employeeCount = employeeCount;
            arr[index] = dataResponce;
            that.setState({
              events: arr
            })
          })
        }
      })
    })
  }

  handlerStatusCanceled(id, status, index, event){
    event.preventDefault();
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/events/${id}/status`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
          "eventStatus": status
        })
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            let events = that.state.events;
            events[index].eventStatus = status;
            that.setState({
              events: events,
              dataEventModal: data
            })
          })
        }
      })
    })
  }

  handlerDeleteEvent(id, event){
    let that = this;
    that.setState({
      loadingDeleteSumbit: true
    })
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/events/${id}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          })
        });
      } else {
        that.handleModalDisabled();
        that.setState({
          indexRowEvent: that.state.removeIndex,
          controlRow: null
        });
        fetch(`${that.props.queryLink}/api/v1/events/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({"itemsPerPage": 20, "page": that.state.events.length < 2 ? (that.state.currentPage - 1) : that.state.currentPage})
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                error: true
              })
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                indexRowEvent: null,
                removeIndex: null,
                events: data.data,
                totalPages: data.totalPages,
                loadingDeleteSumbit: false
              });
            })
          }
        })
      }
    })
    })
  }

  handlerOpenAddUsersModal(){
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modalEvent: false,
        addUserModalStatus: true
      }));
    }, 500);
  }
  handlerCloseAddUsersModal() {
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modalEvent: true,
        addUserModalStatus: false
      }));
    }, 500);
  }
  handlerOpenAddBonusesUsersModal(){
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modalEvent: false,
        addBonusesUserModalStatus: true
      }));
    }, 500);
  }
  handlerCloseAddBonusesUsersModal() {
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modalEvent: true,
        addBonusesUserModalStatus: false
      }));
    }, 500);
  }
  handlerTabModal(tab, event){
    this.setState({
      modalTab: tab
    });
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  handlerToCountBonuses(ids, event){
    let that = this;
    this.setState( {
      addUserLoading: true
    } );
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/events/${that.state.dataEventModal.eventID}/bonus`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "userIds": ids
        })
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              addUserLoading: false
            });
            setTimeout(() => {
              that.setState({
                errors: false
              });
            }, 5000);
          })
        } else {
          that.setState( {
            successAddUser: true,
            addUserLoading: false,
            successText: 'Успішно нараховано'
          } );
          that.setState(prevState => ({
            modalDisabled: true
          }));
          setTimeout(() => {
            that.setState(prevState => ({
              modalDisabled: false,
              modalEvent: true,
              addBonusesUserModalStatus: false
            }));
          }, 500);
          setTimeout(() => {
              that.setState({
                successAddUser: false,
                successText: null
              });
            }, 5000);
        }
      })
    })
  }
  handlerAddUsersToEvent(ids, allUsers, event){
    let that = this;
    let data = this.state.dataEventModal;
    data.allUsers = allUsers;
    data.userIds = ids;
    let obj = {
      "address": data.address,
      "allUsers": data.allUsers,
      "description": data.description,
      "endTime": data.endTime,
      "eventType": 'EVENT',
      "eventID": data.eventID,
      "openEvent": data.openEvent,
      "place": data.place,
      "smile": data.smile,
      "status": data.status,
      "recurringType": data.recurringType,
      "startTime": data.startTime,
      "title": data.title,
      "userIds": data.userIds
    };
    this.setState( {
      addUserLoading: true
    } );
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/events/${data.eventID}`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(obj)
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              addUserLoading: false
            });
            setTimeout(() => {
              that.setState({
                errors: false
              });
            }, 5000);
          })
        } else {
          fetch(`${that.props.queryLink}/api/v1/events/${data.eventID}`, {
            method: 'GET',
            headers: myHeaders,
          }).then(res => {
            if (res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                })
              });
            } else {
              res.json().then(function(data) {
                let eventsData = that.state.events;
                eventsData[that.state.dataEventModalIndex].employeeCount = data.invitations.length;
                that.setState(prevState => ({
                  events: eventsData,
                  dataEventModal: data
                }));
                setTimeout(function() {
                  let infoDiv = document.querySelector('.modal__order-content-col-info');
                  let height = infoDiv.clientHeight;
                  that.setState(prevState => ({
                    heightInfo: height
                  }));
                }, 500);
              })
            }
          })
          res.json().then(function(data) {
            that.setState( {
                successAddUser: true,
                addUserLoading: false,
                successText: 'Успішно додано'
              } );
            that.setState(prevState => ({
              modalDisabled: true
            }));
          setTimeout(() => {
            that.setState(prevState => ({
              modalDisabled: false,
              modalEvent: true,
              addUserModalStatus: false
            }));
          }, 500);
          setTimeout(() => {
              that.setState({
                successAddUser: false,
                successText: null
              });
            }, 5000);
          })
        }
      })
    })
  }
  handlerGetEventStatus(status, event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken) {
        const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/events/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"itemsPerPage": 20, "eventStatus": status})
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoadedSearch: false
            });
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              isLoadedSearch: false,
              events: data.data,
              totalPages: data.totalPages
            });
            if(data.data.length < 1){
              that.setState({
                searchEmpty: true
              });
            }
          });
        }
      });
    });
  }
  handlerSearchUsers(event){
    let that = this;
    this.setState({
      searchUserValue: event.target.value
    });
    let timeoutId = setTimeout(() => {
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(
          {
            "itemsPerPage": 20,
            "query": that.state.searchUserValue
          }
        )
      }).then(res => {
        if(res.status === 200) {
          res.json().then(function(data) {
            that.setState({
              addUserData: data.data,
            })
          });
        }
      })
    })
    }, 500);
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = timeoutId;
  }
  handlerAddUsers(event){
    let that = this;
    this.setState(prevState => ({
      addUserContent: !prevState.addUserContent
    }));
    if(this.state.addUserData.length < 1){
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/users/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(
            {
              "itemsPerPage": 20
            }
          )
        }).then(res => {
          if(res.status === 200) {
            res.json().then(function(data) {
              that.setState(prevState => ({
                addUserData: data.data
              }));
            });
          } else if(res.status === 401) {
            res.json().then(function(data) {

            });
          } else {
            res.json().then(function(data) {

            });
          }
        })
      })
    }
  }
  handlerAddUser(id, index, event){
    let that = this;
    that.setState({
      addUserIndex: index
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/events/${that.state.dataEventModal.eventID}/invitations`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "userID": id
        })
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function (data) {
            that.setState({
              errors: data.errors,
              addUserIndex: null
            });
          });
        } else {
          let users = that.state.addUserData;
          users.splice(index, 1);
          let dataModal = that.state.dataEventModal;
          res.json().then(function (data) {
            dataModal.invitations.unshift(data);
            that.setState({
              addUserIndex: null,
              addUserData: users,
              dataEventModal: dataModal
            });
          });
        }
      });
    })
  }
  handleScrollAddUsers = ({ scrollTop, scrollHeight, clientHeight }) => {
    let that = this;
    if (scrollTop + clientHeight === scrollHeight) {
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(
          {
            "itemsPerPage": 20,
            "page": that.state.scrollPageAddUser
          }
        )
      }).then(res => {
        if(res.status === 200) {
          res.json().then(function(data) {
            let usersData = that.state.addUserData;
            let newUsers = usersData.concat(data.data);
            usersData.push(data.data);
            that.setState(prevState => ({
              addUserData: newUsers,
              scrollPageAddUser: prevState.scrollPageAddUser + 1
            }));
          });
        } else if(res.status === 401) {
          res.json().then(function(data) {

          });
        } else {
          res.json().then(function(data) {

          });
        }
      })
    })

    }
  }

  /* Render component */
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.title = this.props.getTranslate('events-Title') + ' | ' + this.props.companyName;
    }
    if(this.state.successEventSubmit){
      setTimeout(() => {
        this.handlerCloseSuccess();
      }, 5000);
    }
    let resultEvents = '';
      if(this.state.error) {
        resultEvents = <div>{this.props.getTranslate('loading-ErrorRenderContent')}</div>;
      } else if(!this.state.isLoaded) {
        resultEvents = <Loading />;
      } else {
        resultEvents = <div className="account-wgt account-wgt__events">

        {/* Table component */}
          <Table
            header={[
              this.props.getTranslate('events-Name'),
              this.props.getTranslate('events-Author'),
              this.props.getTranslate('events-Count'),
              this.props.getTranslate('events-Repeat'),
              this.props.getTranslate('events-Date'),
              this.props.getTranslate('events-Status'),
              ''
            ]}
            handlerRow={this.handlerModalGetEvent.bind(this)}
            bodyData={this.state.events}
            bodyDataNames={['title', 'author', 'acceptedCount', 'recurring', ['startTime', 'endTime'], 'eventStatus']}
            controlItemsStatus={true}
            controlItems={{
              'enabled': {'status': true, 'handler': this.handlerEnabled.bind(this), 'label': {'hide': this.props.getTranslate('tableDropdown-StatusLabel'), 'show': this.props.getTranslate('tableDropdown-StatusLabelShow')}},
              'editButton': false,
              'editLink': {
                'status': true,
                'handler': this.props.getPermalink(`/account/events/edit/`),
                'label': this.props.getTranslate('tableDropdown-EditLabel')
              },
              'delete': {
                'status': true,
                'handler': this.handlerDeleteEventModal.bind(this),
                'label': this.props.getTranslate('tableDropdown-EditRemove')
              }
            }}
            translationValueItems={{
                'ACTIVE': this.props.getTranslate('events-StatusActive'),
                'NEW': this.props.getTranslate('events-StatusNew'),
                'DONE': this.props.getTranslate('events-StatusDone'),
                'NOT_ACTIVE': this.props.getTranslate('events-StatusNotActive'),
                'DELETE': this.props.getTranslate('events-StatusDelete'),
                'DAILY': this.props.getTranslate('events-RepeatDay'),
                'WEEKLY': this.props.getTranslate('events-RepeatWeek'),
                'MONTHLY': this.props.getTranslate('events-RepeatMonth')
              }}
              reccuringNull={this.props.getTranslate('events-RepeaNull')}
              dateValueFormat={{
                start: 'startTime',
                end: 'endTime'
              }}
            labelsStatus={{
              'eventStatus': {
                'ACTIVE': 'blue',
                'NEW': 'blue',
                'DONE': 'green',
                'NOT_ACTIVE': 'red',
                'DELETE': 'red'
              }
            }}
            itemIDName={'eventID'}
            itemEnableNameForStatus={'NOT_ACTIVE'}
            itemEnableNameForStatusData={'eventStatus'}
            enabledMobileContent={true}
            mobileContentType={'block'}
            enableTooltipMessage={this.props.getTranslate('tableEnable-TooltipMessage')}
            mobileContentHeader={[
              this.props.getTranslate('events-Count'),
              this.props.getTranslate('events-Date'),
              this.props.getTranslate('events-Repeat'),
              this.props.getTranslate('events-Status')
            ]}
            mobileContentData={['title', 'acceptedCount', ['startTime', 'endTime'], 'recurring', 'eventStatus']}
            removeIndex={this.state.removeIndex}
          />

        </div>
      }

    return <>
    {/* Error handler */}
    <ResultErrors
      errors={this.state.errors}
      closeHandler={this.handlerCloseError.bind(this)}
    />

    {/* Query result handler */}
    {this.state.successAddUser && <ResultQuery
      success={this.state.successAddUser}
      resultText={this.state.successText}
      handlerSuccess={this.handlerCloseSuccess}
      handlerError={this.handlerCloseError}
    />}

    {/* Header component */}
    <AccountHeader
      queryLink={this.props.queryLink}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      getTranslate={this.props.getTranslate.bind(this)}
      completed={this.props.completed}
    />

    <div className="account-wrap">

    {/* Navigation component */}
    <AccountNav
      getPermalink={this.props.getPermalink}
      queryLink={this.props.queryLink}
      configsApp={this.props.configsApp}
      newReview={this.props.newReview}
      companyName={this.props.companyName}
      token={this.props.token()}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      dropdownStatus={this.state.dropdownStatus}
      dropdownHandler={this.handlerDropdown}
      getTranslate={this.props.getTranslate}
      completed={this.props.completed}
    />

    <div className="account-content">
      <div className="account-content__inner">

        {/* Account header component */}
        <AccountContentHeader
          title={this.props.getTranslate('events-Title')}
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
        />

      <div className="team-nav team-nav_space_between">

          {/* Button for add event */}
          <Button
            type="link"
            icon={true}
            iconItem="#plus-icon"
            text={this.props.getTranslate('events-CreatEvent')}
            addClass="header-content-nav__btn"
            handler={this.props.getPermalink("/account/events/create")}
          />

          {/* Filter component */}
          <Filter
            className="library-filter-dropdown"
            buttonName={this.props.getTranslate('ordersFilter-Title')}
            dataSort={[
              {
                name: this.props.getTranslate('eventsFilterStatus-All'),
                value: null
              },
              {
                name: this.props.getTranslate('eventsFilterStatus-Active'),
                value: 'ACTIVE'
              },
              {
                name: this.props.getTranslate('eventsFilterStatus-NotActive'),
                value: 'NOT_ACTIVE'
              },
              {
                name: this.props.getTranslate('eventsFilterStatus-Done'),
                value: 'DONE'
              },
              {
                name: this.props.getTranslate('eventsFilterStatus-Canceled'),
                value: 'CANCELED'
              },
            ]}
            handler={this.handlerGetEventStatus.bind(this)}
            labelDisabled={true}
          />

            {/* Filter search input component */}
            <FilterSearch
              placeholder={this.props.getTranslate('eventsSearch-Placeholder')}
              value={this.state.searchAdsValue}
              handler={this.handlerSearch.bind(this)}
            />
          </div>

          {this.state.events.length < 1 && this.state.isLoaded && !this.state.error ? <>
            <div className="account-content__content account-content__content_empty">
              <div className="empty-wrapper">

                {/* If the response is empty */}
                <AccountContentEmpty
                  img={events_img}
                  title={this.props.getTranslate('event-EmptyEventTitle')}
                  description={this.props.getTranslate('event-EmptyEventDescription')}
                  buttonType="link"
                  buttonText={this.props.getTranslate('events-CreatEvent')}
                  buttonIcon="#plus-icon"
                  buttonHandler={this.props.getPermalink("/account/events/create")}
                />

              </div>
              </div></> : <><div className="account-content__content">

              {/* Query result */}
              {resultEvents}

            </div></>}
            <div className="account-content__footer">

              {/* Pagination component */}
              <Pagination
                total={this.state.totalPages}
                perPage="20"
                path={`${this.props.queryLink}/api/v1/events/search`}
                currentPage={this.state.currentPage}
                items={this.handlerPaginationItems}
              />

              </div>
            <div>
          </div>
        </div>
      </div>
    </div>
    {this.state.modalEvent ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal modal__order">
      <div className="modal-header">
        <h2 className="modal-headline">{this.state.dataEventModal.title}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
      <div className="modal-nav">
        {this.state.dataEventModal.bonus && this.state.dataEventModal.bonusConfirmation && this.state.dataEventModal.eventStatus === 'DONE' ?
          <div className="modal-nav__item">
            <button className="btn btn_theme_secondary btn_size_lg" type="button" onClick={this.handlerOpenAddBonusesUsersModal.bind(this)}>{this.props.getTranslate('eventModal-BonusesConfirmButton')}</button>
          </div> : ''}
        <div className="modal-nav__item">
          <button className="btn btn_theme_secondary btn_size_lg" type="button" onClick={this.handlerOpenAddUsersModal.bind(this)}>{this.props.getTranslate('eventModal-BonusesAddMembersButton')}</button>
        </div>
        {this.state.dataEventModal.eventStatus === 'CANCELED' ? <div className="modal-nav__item">
          <div className="modal-status modal-status_theme_red">
            <div className="modal-status__label modal-status__label_theme_red">{this.props.getTranslate('eventModal-StatusCancelled')}</div>
            <button className="modal-status__btn" type="button" onClick={this.handlerStatusCanceled.bind(this, this.state.dataEventModal.eventID, 'ACTIVE', this.state.dataEventModalIndex)}>{this.props.getTranslate('eventModal-RestoreButton')}</button>
          </div>
        </div> : <>{this.state.dataEventModal.eventStatus !== 'DONE' ? <div className="modal-nav__item">
          <button className="btn btn_theme_remove btn_size_lg" type="button" onClick={this.handlerStatusCanceled.bind(this, this.state.dataEventModal.eventID, 'CANCELED', this.state.dataEventModalIndex)}>{this.props.getTranslate('eventModal-CancelButton')}</button>
        </div> : ''}</>}
        <div className="modal-nav__item">
          <Link className="btn-edit" to={this.props.getPermalink(`/account/events/edit/${this.state.dataEventModal.eventID}`)}>
            <svg className="btn-edit__icon">
              <use href={`${sprite}#edit-icon`}></use>
            </svg>
          </Link>
        </div>
        <div className="modal-nav__item">
          <button className="btn-edit btn-edit_theme_remove" onClick={this.handlerDeleteEventModal.bind(this, this.state.dataEventModal.eventID, this.state.dataEventModalIndex)}>
            <svg className="btn-edit__icon">
              <use href={`${sprite}#delete-red`}></use>
            </svg>
          </button>
        </div>
      </div>
        <div className="modal__order-content">
        <div className="modal__order-content-col modal__order-content-col-info">
          <div className={`${this.state.dataEventModal.photos ? '' : 'modal__order-info-headline'}`}>{this.props.getTranslate('eventModal-modalInfoTitle')}</div>
          {this.state.dataEventModal && this.state.dataEventModal.photos ? <div className="modal-event-content__img">
            <img src={this.state.dataEventModal.photos[0].url} />
          </div> : ''}
          <div className="modal-event-content">
            <div className="modal-event-content__inner">
              <div className="modal-event-content__headline">{this.props.getTranslate('eventModal-DateTitle')}:</div>
              <div className="modal-event-content__value"><DateFormating start={this.state.dataEventModal.startTime} end={this.state.dataEventModal.endTime} /></div>
              <div className="modal-event-content__headline">{this.props.getTranslate('eventModal-OrganizerTitle')}:</div>
              <div className="modal-event-content__value">{this.state.dataEventModal.author}</div>
              <div className="modal-event-content__headline">{this.props.getTranslate('eventModal-PlaceTitle')}:</div>
              <div className="modal-event-content__value">{this.state.dataEventModal.place}</div>
              <div className="modal-event-content__headline">{this.props.getTranslate('eventModal-PeopleCountTitle')}:</div>
              <div className="modal-event-content__value">{this.state.dataEventModal.invitations ? this.state.dataEventModal.invitations.length : 0}</div>
              {this.state.dataEventModal.bonus ? <><div className="modal-event-content__headline">{this.props.getTranslate('eventModal-BonusesTitle')}:</div>
              <div className="modal-event-content__value">{this.state.dataEventModal.bonus + ' ' + this.props.currency + ', ' + (this.state.dataEventModal.bonusConfirmation ? this.props.getTranslate('eventModal-BonusesTitleValueYes') : this.props.getTranslate('eventModal-BonusesTitleValueNot'))}</div></> : ''}
              {this.state.dataEventModal.bonus && this.state.dataEventModal.bonusConfirmation && this.state.dataEventModal.eventStatus === 'DONE' ? <button className="btn btn_theme_secondary" type="button" onClick={this.handlerOpenAddBonusesUsersModal.bind(this)}>{this.props.getTranslate('eventModal-BonusesConfirmButton')}</button> : ''}
            </div>
          </div>
        </div>

        {this.state.heightInfo !== 'auto' ? <div className="modal__order-content-col modal__order-content-col-change">
          <div className="modal__tab-nav modal__tab-nav-users-wrap">
            <div className="modal__tab-nav-users">
              <button className={`modal__tab-btn ${this.state.modalTab === 'USERS' ? 'active' : ''}`} type="button" onClick={this.handlerTabModal.bind(this, 'USERS')}>{this.props.getTranslate('eventModal-modalMembersTitle')}<span className="modal__tab-btn-count">{this.state.dataEventModal.invitations ? this.state.dataEventModal.invitations.length : 0}</span></button>
              <div className="modal-add-user"><button className="modal-add-user__btn" type="button" onClick={this.handlerAddUsers.bind(this)}><span className="modal-add-user__icon">
                <svg>
                  <use href={`${sprite}#plus-icon`}></use>
                </svg>
              </span></button>
                <div className={`modal-add-user__content${this.state.addUserContent ? ' active' : ''}`} ref={this.setWrapperRef}>
                <div className="field-wrap__search-members-wrap">
                  <div className="field-wrap field-wrap__search-members field-wrap__search-members-dropdown">
                    <input className="field-wrap__input" type="text" value={this.state.searchUserValue} onChange={this.handlerSearchUsers.bind(this)} placeholder={this.props.getTranslate('modal-addUsersSearchPlaceholder')} />
                    <svg className="field-wrap__search-icon">
                      <use href={`${sprite}#search-icon`}></use>
                    </svg>
                  </div>
                </div>

                <Scrollbars
                    onScrollFrame={this.handleScrollAddUsers}
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                    renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                      autoHeight
                      renderView={props => <div {...props} />}>
                {this.state.addUserData.map((item, index) => {
                  if(this.state.dataEventModal.invitations){
                    const fullName = (item.name ? (item.name + ' ') : '') + '' + (item.middleName ? (item.middleName + ' ') : '') + '' + (item.lastName ? item.lastName : '');
                    const userExists = this.state.dataEventModal.invitations.find(user => user.userID === item.userID);
                    if(!userExists){
                      return <div className="user-modal-wgt__info" key={index} onClick={this.handlerAddUser.bind(this, item.userID, index)}>
                      <img className="user-modal-wgt__img" src={item.photo} alt="" />
                      <span className="user-modal-wgt__name">{fullName}</span>
                      {this.state.addUserIndex === index ? <Loading /> : ''}
                    </div>
                  }
                  }
              })}</Scrollbars>

              </div>
              </div>
              </div>

            <button className={`modal__tab-btn ${this.state.modalTab === 'COMMENTS' ? 'active' : ''}`} type="button" onClick={this.handlerTabModal.bind(this, 'COMMENTS')}>{this.props.getTranslate('eventModal-modalCommentsTitle')}<span className="modal__tab-btn-count">{this.state.dataEventModal.commentROs ? this.state.dataEventModal.commentROs.length : 0}</span></button>
          </div>
          <div className={`team-list-modal ${this.state.heightThumb > 0 ? 'team-list-modal__thumb' : ''}`} style={{height: this.state.heightInfo}}>
            <Scrollbars
                  className="scrollbar-users"
                  renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                  renderTrackVertical={props => <div {...props} className="track-vertical" />}
                  renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                  renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                    autoHeight
                    autoHeightMin={0}
                    autoHeightMax={this.state.heightInfo}
                    renderView={props => <div {...props} />}>
            <div className={`modal__tab ${this.state.modalTab === 'USERS' ? 'active' : ''}`}>
            <div className={`modal__scroll-content${this.state.scrollBarThumb ? ' modal__scroll-content-thumb' : ''}`}>
              {this.state.dataEventModal.invitations ? <>
                {this.state.dataEventModal.invitations.map((item, index) => {
                  let iconStatus = '';
                  if(item.invitationStatus === 'ACCEPTED'){
                    iconStatus = <div className="users-modal__check-wrap tooltip-wgt"><svg className="users-modal__check"><use href={`${sprite}#check-icon`}></use></svg>
                        <div className="tooltip-wgt__content">
                          <p>Підтвердив</p>
                      </div>
                    </div>;
                  } else if(item.invitationStatus === 'REJECTED') {
                    iconStatus = <div className="tooltip-wgt"><svg className="users-modal__check"><use href={`${sprite}#status-modal-rejected`}></use></svg>
                      <div className="tooltip-wgt__content">
                          <p>Відхилений</p>
                      </div>
                    </div>;
                  } else if(item.invitationStatus === 'WAITING'){
                    iconStatus = <div className="tooltip-wgt"><svg className="users-modal__check"><use href={`${sprite}#status-modal-waiting`}></use></svg>
                      <div className="tooltip-wgt__content">
                        <p>Очікує</p>
                      </div>
                    </div>;
                  } else {
                    iconStatus = <div className="tooltip-wgt"><svg className="users-modal__check"><use href={`${sprite}#status-modal-dont`}></use></svg>
                    <div className="tooltip-wgt__content">
                      <p>Можливо</p>
                    </div>
                  </div>;
                  }
                  return <div className="users-modal-wgt" key={index}>
                    <div className="users-modal-wgt__inner">
                      <div className="users-modal-wgt__info">
                        <img className="user-modal-wgt__img" src={item.userPhoto} alt="" />
                        <span className="user-modal-wgt__name">{item.userName}</span>
                      </div>
                      <div className="users-modal-wgt__nav">
                        {iconStatus}
                      </div>
                    </div>
                  </div>
                })}
                </> : ''}
                </div>
            </div>
            <div className={`modal__tab ${this.state.modalTab === 'COMMENTS' ? 'active' : ''}`}>
                      {this.state.dataEventModal.commentROs ? <>
                        {this.state.dataEventModal.commentROs.map((item, index) => {
                          return <div className="comment-modal-wgt" key={index}>
                            <div className="comment-modal-wgt__inner">
                              <div className="comment-modal-wgt__avatar">
                                <Link to={this.props.getPermalink(`/account/users/view/${item.userID}`)}>
                                  <img className="comment-modal-wgt__avatar-img" src={item.photo} alt="" />
                                </Link>
                              </div>
                              <div className="comment-modal-wgt__content">
                                <div className="comment-modal-wgt__content-name">
                                  <Link className="comment-modal-wgt__content-link" to={this.props.getPermalink(`/account/users/view/${item.userID}`)}>{item.userName}</Link>
                                  <div className="comment-modal-wgt__content-date"><DateFormating start={item.creationDate} /></div>
                                  <div className="comment-modal-wgt__content-txt">{item.comment}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        })}</> : ''}
            </div>
            </Scrollbars>
          </div>
        </div> : <Loading />}

        </div>
        </div>
    </div> : ''}

    {/* Delete item modal */}
    <DeleteModal
      modalStatus={this.state.modalDeleteEvents}
      modalDisabledStatus={this.state.modalDisabled}
      modalDisabledClass={this.state.addClass}
      handlerModalDisabled={this.handleModalDisabled}
      modalTitle={this.props.getTranslate('modal-deleteEventTitle')}
      modalDescription={this.props.getTranslate('modal-deleteEventDescription')}
      modalHandlerDelete={this.handlerDeleteEvent.bind(this, this.state.idDeleteEvents)}
      modalHandlerBtnTxt={this.props.getTranslate('removeModal-ButtonSubmit')}
      modalHandlerBtnLoading={this.state.loadingDeleteSumbit}
    />

    {this.state.addUserModalStatus ?<ModalAddUsers
      queryLink={this.props.queryLink}
      handlerClose={this.handlerCloseAddUsersModal.bind(this)}
      title={this.props.getTranslate('modal-AddMembersTitle')}
      submitButtonTitle={this.props.getTranslate('modal-AddMembersButtonSubmit')}
      submitButtonLoading={this.state.addUserLoading}
      getCompanyUsers={true}
      handlerSubmit={this.handlerAddUsersToEvent.bind(this)}
      dataUsers={this.state.dataEventModal.invitations}
    /> : ''}

    {this.state.addBonusesUserModalStatus ?<ModalAddUsers
      queryLink={this.props.queryLink}
      handlerClose={this.handlerCloseAddBonusesUsersModal.bind(this)}
      title={this.props.getTranslate('modal-AddCalculationTitle')}
      submitButtonTitle={this.props.getTranslate('modal-AddCalculationButtonSubmit')}
      submitButtonLoading={this.state.addUserLoading}
      getCompanyUsers={false}
      type="event-bonuses"
      handlerSubmit={this.handlerToCountBonuses.bind(this)}
      dataInvitations={this.state.dataEventModal.invitations}
    /> : ''}

    </>;
  }
}
export default Events;
