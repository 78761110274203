import React from "react";
import main_icon_1 from '../../media/main-icon-1.svg';
import main_icon_2 from '../../media/main-icon-2.svg';
import main_icon_3 from '../../media/main-icon-3.svg';
class HomeWgtsInfo extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let infoFirst = this.props.getTranslate('homePage-InfoWgtFirst');
    let infoSecond = this.props.getTranslate('homePage-InfoWgtSecond');
    let infoThird = this.props.getTranslate('homePage-InfoWgtThird');
    return <section className="home-wgts-info">
      <div className="wrapper home-wgts-info__inner">
        <div className="main-headline-wrap">
          <h2 className="main-headline">{this.props.getTranslate('homePage-InfoTitle')}</h2>
        </div>
        <div className="home-wgts-info__wgts">
          <div className="home-wgts-info__wgt">
            <div className="home-wgts-info__wgt-inner">
              <div className="home-wgts-info__wgt-icon-wrap">
                <img className="home-wgts-info__wgt-icon" src={main_icon_1} alt={infoFirst} />
              </div>
              <h3 className="home-wgts-info__wgt-headline">{infoFirst}</h3>
            </div>
          </div>
          <div className="home-wgts-info__wgt">
            <div className="home-wgts-info__wgt-inner">
              <div className="home-wgts-info__wgt-icon-wrap">
                <img className="home-wgts-info__wgt-icon" src={main_icon_2} alt={infoSecond} />
              </div>
              <h3 className="home-wgts-info__wgt-headline">{infoSecond}</h3>
            </div>
          </div>
          <div className="home-wgts-info__wgt">
            <div className="home-wgts-info__wgt-inner">
              <div className="home-wgts-info__wgt-icon-wrap">
                <img className="home-wgts-info__wgt-icon" src={main_icon_3} alt={infoThird} />
              </div>
              <h3 className="home-wgts-info__wgt-headline">{infoThird}</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  }
}
export default HomeWgtsInfo;
