import React from "react";
import { Link, Navigate } from "react-router-dom";
import ResultErrors from './components/ResultErrors';
import AccountHeader from './components/AccountHeader';
import Navigation from './components/Navigation';
import Pagination from './components/Pagination';
import Loading from './components/Loading';
import Filter from '../components/Filter';
import FilterSearch from '../components/FilterSearch';
import sprite from './media/icons.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
class ControlPanelCompanies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      modalDisabled: false,
      modal: false,
      companiesData: [],
      currentPage: 0,
      totalPages: null,
      isLoaded: false,
      errors: null,
      error: null,
      controlRow: null,
      sortTR: null,
      isLoadedSearch: false,
      searchCompaniesValue: '',
      searchEmpty: false,
      statusCompanies: null,
      statusCompanyModal: false,
      statusCompanyModalObj: {},
      totalItemsAll: null,
      totalItemsArchive: null,
      companyId: null,
      statusesCompany: [],
      directionSort: 'ASC',
      token: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("token-admin") ? JSON.parse(localStorage.getItem("token-admin")) : JSON.parse('')) : ''
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        statusCompanyModal: false,
        statusCompanyModalObj: {}
      }));
    }, 500);
  }
  handlerPaginationItems = (companiesItems, current) => {
    this.setState({
      companiesData: companiesItems,
      currentPage: current
    });
  }
  handlerMessageModal () {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }
  requestCompanies(status, query, sort, type){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    let bodyObj = {
      "itemsPerPage": 20
    };
    if(status){
      bodyObj.status = status;
    }
    if(sort){
      bodyObj.orderField = sort;
    }
    if(that.state.directionSort){
      bodyObj.direction = that.state.directionSort;
    }
    if(query){
      bodyObj.query = query;
    }
    if(type){
      bodyObj.type = type;
    }
    fetch(`${that.props.queryLink}/bo/v1/companies/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(bodyObj)
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            companiesData: data.data,
            totalPages: data.totalPages,
            isLoaded: true,
            totalItemsAll: !status ? data.totalItems : null,
            totalItemsArchive: status === 'DELETED' ? data.totalItems : null
          });

          if(!status){
            fetch(`${that.props.queryLink}/bo/v1/companies/search`, {
              method: 'POST',
              headers: myHeaders,
              body: JSON.stringify({
                "status": 'DELETED'
              })
            }).then(res => {
              if (res.status === 200) {
                res.json().then(function(data) {
                  that.setState({
                    totalItemsArchive: data.totalItems ? data.totalItems : ''
                  });
                })
              }
            })
          } else if(status === 'DELETED'){
            fetch(`${that.props.queryLink}/bo/v1/companies/search`, {
              method: 'POST',
              headers: myHeaders,
              body: JSON.stringify({})
            }).then(res => {
              if (res.status === 200) {
                res.json().then(function(data) {
                  that.setState({
                    totalItemsAll: data.totalItems ? data.totalItems : ''
                  });
                })
              }
            })
          }

        })
      } else if(res.status === 401) {
        res.json().then(function(data) {
          that.setState({
            errors: [data.message],
            isLoaded: true,
            error: true
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            isLoaded: true,
            error: true
          });
        })
      }
    })
  })
  }
  handlerGetCompaniesType(type, event){
    event.preventDefault();
    this.requestCompanies(null, null, null, type);
  }
  componentDidMount() {
    let that = this;
    this.requestCompanies();
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/bo/v1/companies/types`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if (res.status === 200) {
          res.json().then(function(data) {
            that.setState({
              typesCompany: data
            });
          })
          fetch(`${that.props.queryLink}/bo/v1/companies/statuses/`, {
            method: 'GET',
            headers: myHeaders
          }).then(res => {
            if (res.status === 200) {
              res.json().then(function(data) {
                that.setState({
                  statusesCompany: data
                });
              })
            }
          })
        }
      })
    });
  }
  handlerDisabledCompany (id, index, status, event) {
    event.preventDefault();
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/companies/${id}/enable`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        "enable": !status
      })
    }).then(res => {
      if (res.status === 200) {
        let data = that.state.companiesData;
        data[index].enable = !status;
          that.setState({
            companiesData: data
          });
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        })
      }
    })
  })
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  handlerCloseElements(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      const div = document.querySelector( '.control-wgt');
      document.addEventListener('click', e => {
        const withinBoundaries = e.composedPath().includes(div);
        if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
          if ( ! withinBoundaries ) {
            this.setState( {
              controlRow: null
            } );
          }
        }
      });
    }
  }
  handlerSortCompanies(sort, event){
    event.preventDefault();
    this.requestCompanies(null, null, sort);
    this.setState(prevState => ({
      sortTR: sort,
      directionSort: prevState.sortTR !== sort ? 'DESC' : (prevState.directionSort === 'ASC' ? 'DESC' : 'ASC')
    }));
  }
  handlerSearch(event){
    let that = this;
    this.setState({
      isLoadedSearch: true,
      searchCompaniesValue: event.target.value,
      searchEmpty: false
    });
    let timeoutId = setTimeout(() => {
      this.requestCompanies(that.state.statusCompanies, event.target.value);
    }, 500);
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = timeoutId;
  }
  handlerStatusCompanyModal(obj, event){
    this.setState(prevState => ({
      statusCompanyModal: !prevState.statusCompanyModal,
      statusCompanyModalObj: obj
    }));
  }
  handlerStatusCompany(id, index, status, event){
    event.preventDefault();
    let objCompany = this.state.statusCompanyModalObj;
    let that = this;
    let statusCompany;
    if(status){
      statusCompany = status;
    } else {
      if(objCompany){
        if(objCompany.status === 'ACTIVE'){
          statusCompany = "DELETED"
        } else if(objCompany.status === 'DELETED') {
          statusCompany = 'ACTIVE'
        }
      }
    }
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/bo/v1/companies/${id ? id : objCompany.id}/status`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
          "status": statusCompany
        })
      }).then(res => {
        if (res.status === 200) {
          let data = that.state.companiesData;
          if(status){
            data[index].companyStatus = status;
          } else {
            data.splice(objCompany.index, 1);
          }
          that.setState({
            companiesData: data
          });
          that.handleModalDisabled();
        } else {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            });
          })
        }
      })
    })
  }
  handlerTabCompanies(status, event){
    this.setState( {
      statusCompanies: status
    } );
    this.requestCompanies(status);
  }
  handlerRedirectCompanyUsers(id, event){
    if(!event.target.closest('.control-wgt')){
      this.setState( {
        companyId: id
      } );
    }
  }
  handlerGetEventStatus(status, event){
    this.setState( {
      statusCompanies: status
    } );
    this.requestCompanies(status);
  }
  render() {
    let translatedTypes = null;
    if(this.state.typesCompany){
      translatedTypes = this.state.typesCompany.map(type => ({
        name: this.props.getTranslate(`Filter_Type_Company_${type}`),
        value: type
      }));
      translatedTypes.unshift({
        name: this.props.getTranslate("Filter_Type_Company_ALL"),
        value: null
      });
    }
    let translatedStatuses = null;
    if(this.state.statusesCompany){
      translatedStatuses = this.state.statusesCompany.map(status => ({
        name: this.props.getTranslate(`Filter_Status_Company_${status}`),
        value: status
      }));
      translatedStatuses.unshift({
        name: this.props.getTranslate("Filter_Status_Company_ALL"),
        value: null
      });
    }
    let companiesDataTable = '';
    if(this.state.error) {
      companiesDataTable = 'Помилка';
    } else if(!this.state.isLoaded){
      companiesDataTable = <Loading />;
    } else {
      companiesDataTable = <table className="data-table">
        <thead>
          <tr>
            <th className={`data-table__sort${this.state.sortTR === 'name' ? ' data-table__sort_active' : ''}`} onClick={this.handlerSortCompanies.bind(this, 'name')}><span>Назва
              {this.state.sortTR === 'name' ? <svg className="data-table__sort-icon">
                <use href={`${sprite}#chevron-down`}></use>
              </svg> : ''}</span>
            </th>
            <th>E-mail менеджера</th>
            <th>Тариф</th>
            <th>Користувачі</th>
            <th className={`data-table__sort${this.state.sortTR === 'lastActivity' ? ' data-table__sort_active' : ''}`} onClick={this.handlerSortCompanies.bind(this, 'lastActivity')}><span>Остання активність
              {this.state.sortTR === 'lastActivity' ? <svg className="data-table__sort-icon">
                <use href={`${sprite}#chevron-down`}></use>
              </svg> : ''}</span>
            </th>
            <th>Статус</th>
            <th></th>
          </tr>
        </thead>
      <tbody>
      {this.state.companiesData.map((item, index) => {
        let statusLabel;
        let classLabel;
        if(item.companyStatus === 'ACTIVE'){
          statusLabel = 'Активний';
          classLabel = ' bo-status-label_theme_green'
        } else if(item.companyStatus === 'DELETED'){
          statusLabel = 'Видалено';
          classLabel = ' bo-status-label_theme_gray'
        } else if(item.companyStatus === 'DEACTIVATED'){
          statusLabel = 'Заблоковано';
          classLabel = ' bo-status-label_theme_red'
        } else {
          statusLabel = 'Не оплачено';
          classLabel = ' bo-status-label_theme_red'
        }
        return <tr key={index} onClick={this.handlerRedirectCompanyUsers.bind(this, item.companyID)}>
                <td className="catalog-table__name">{item.companyName}</td>
                <td>{item.email}</td>
                <td>{item.paymentPlan}</td>
                <td>{item.employeesCount}</td>
                <td>{item.lastActivity}</td>
                <td><span className={`bo-status-label${classLabel}`}>{statusLabel}</span></td>
                <td>
                <div className="team-table__control">
                  <div className="control-wgt">
                    <button className="control-btn" onClick={this.handlerControl.bind(this, index)}>
                      <svg className="control-btn__icon">
                        <use href={`${sprite}#more-icon`}></use>
                      </svg>
                      </button>
                      <div className={`control-content ${this.state.controlRow === index ? 'active' : ''}`}>
                        <ul className="control-content__list">
                          <li>
                            <button className="control-content__btn" type="button" onClick={this.handlerStatusCompany.bind(this, item.companyID, index, (item.companyStatus === 'DEACTIVATED' ? 'ACTIVE' : 'DEACTIVATED'))}>
                              <svg className="control-content__icon">
                                <use href={`${sprite}#view-icon-stroke`}></use>
                              </svg>{item.companyStatus === 'DEACTIVATED' ? 'Розблокувати' : 'Заблокувати'}</button>
                          </li>
                          <li>
                            <Link className="control-content__btn" to={`/controlpanel/companies/${item.companyID}`}>
                              <svg className="control-content__icon">
                                <use href={`${sprite}#edit-icon2`}></use>
                              </svg>Редагувати</Link>
                          </li>
                          <li>
                            <button className={`control-content__btn${item.companyStatus === 'DELETED' ? '' : ' control-content__btn_delete'}`} onClick={this.handlerStatusCompanyModal.bind(this, {id: item.companyID, index: index, status: item.companyStatus})}>
                              <svg className="control-content__icon">
                                <use href={`${sprite}${item.companyStatus === 'DELETED' ? '#re-new-icon' : '#delete-red'}`}></use>
                              </svg>{item.companyStatus === 'DELETED' ? 'Відновити' : 'Видалити'}</button>
                          </li>
                        </ul>
                      </div>
                      </div>
                  </div>
                </td>
              </tr>
      })}
      </tbody>
    </table>;
    }
    return <>
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.companyId && <Navigate to={this.props.getPermalink(`/controlpanel/companies/${this.state.companyId}`)} />) : ''}
      <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
      <AccountHeader status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
      <div className="account-wrap account-wrap__admin" onClick={this.handlerCloseElements.bind(this)}>
        <Navigation status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} replace={true} />
        <div className="account-content">
          <div className="account-content__inner">
            <div className="account-content__header">
              <div className="account-content__header-inner">
                <h1 className="account-content__headline">Компанії</h1>
              </div>
              <a className="header__language header__language-content" href="#">RU</a>
            </div>
            <div className="team-nav team-nav_space_between">

                {/* Filter component */}
                {translatedTypes ? <Filter
                  className="filter-flex-start"
                  buttonName="Фільтрувати"
                  dataSort={translatedTypes}
                  handler={this.handlerGetCompaniesType.bind(this)}
                /> : ''}

                {/* Filter component */}
                {translatedStatuses ? <Filter
                  className="library-filter-dropdown"
                  buttonName={this.props.getTranslate('ordersFilter-Title')}
                  dataSort={translatedStatuses}
                  handler={this.handlerGetEventStatus.bind(this)}
                  labelDisabled={true}
                /> : ''}

                  {/* Filter search input component */}
                  <FilterSearch
                    placeholder="Пошук"
                    value={this.state.searchCompaniesValue}
                    handler={this.handlerSearch.bind(this)}
                  />
                </div>

                <div className="users-tab">
                  <div className="users-tab__inner">
                    <button className={`users-tab__link ${this.state.statusCompanies === null ? ' active' : ''}`} onClick={this.handlerTabCompanies.bind(this, null)}>Активні компанії<span className="users-tab__link-amount">{this.state.totalItemsAll}</span></button>
                    <button className={`users-tab__link ${this.state.statusCompanies === 'DELETED' ? ' active' : ''}`} onClick={this.handlerTabCompanies.bind(this, 'DELETED')}>Архів<span className="users-tab__link-amount">{this.state.totalItemsArchive}</span></button>
                  </div>
                </div>
            <div className="account-content__content">
              <div className="account-wgt">
                {companiesDataTable}
              </div>
            </div>
            <div className="account-content__footer">
              <Pagination total={this.state.totalPages} perPage="20" path={`${this.props.queryLink}/bo/v1/companies/search`} currentPage={this.state.currentPage} items={this.handlerPaginationItems} />
            </div>
          </div>
        </div>
      </div>

      {this.state.statusCompanyModal && this.state.statusCompanyModalObj ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <div className="modal-header">
            <h2 className="modal-headline">{this.state.statusCompanyModalObj.status === 'ACTIVE' ? 'Відправити в архів' : 'Відновити компанію'}</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          {this.state.statusCompanyModalObj.status === 'ACTIVE' ? <div className="delete-category-form__description">Після видалення компанії вона буде перенесена в архів. Всі активності у додатку будуть заблоковані, юзери не зможуть більше авторизуватись.</div> : <div className="delete-category-form__description">При відновленні компанії адміністратор та юзери зможуть знову мати доступ до керування аккаунтом та авторизацією в додатку</div>}
          <div className="field-wrap__submit">
            {this.state.statusCompanyModalObj.status === 'ACTIVE' ?
              <button className="btn btn_size_lg btn_theme_remove btn_width_100 user-info__nav-btn" onClick={this.handlerStatusCompany.bind(this, null, null, null)}>Видалити</button> : <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" onClick={this.handlerStatusCompany.bind(this, null, null, null)}>Відновити</button>
            }
          </div>
        </div>
      </div> : ''}

    </>
  }
}
export default ControlPanelCompanies;
