import React from "react";
import BlogWgt from './BlogWgt';
import { Link } from "react-router-dom";
import thumb from '../../media/news-thumbnail.png';
import sprite from '../../media/icons.svg';
import DateFormating from '../../components/DateFormating';
let Swiper;
let SwiperSlide;

if (typeof window !== 'undefined') {
  // Асинхронно загружаем Swiper и его компоненты
  Swiper = require('swiper').default;
  SwiperSlide = require('swiper').default.SwiperSlide;

  // Асинхронно загружаем стили Swiper
  require('swiper/swiper-bundle.css');
}
class BlogViewOthers extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
  if (typeof window !== 'undefined') {
    this.swiper = new Swiper(".swiper-blog-wgts", {
      spaceBetween: 30,
      slidesPerView: 1,
      pagination: {
        clickable: true
      },
      breakpoints: {
        992: {
          slidesPerView: 4
        }
      },
    })
  }
}
  render() {
    return <section className="blog-page-others">
      <div className="wrapper">
        <h2 className="blog-page-others__headline">Інші статті</h2>
        <div className="blog-page-others__row">
        <div className="swiper-container swiper-blog-wgts">
          <div className="swiper-wrapper">
            {this.props.blogData.map((item, index) => {
              return <div className="swiper-slide" key={index}>
                <BlogWgt
                   to={this.props.getPermalink(`/blog/${item.link}`)}
                   headline={item.name}
                   category={item.category}
                   date={<DateFormating start={item.modificationDate} time="off" />}
                   thumb={item.photoUrl}
                 />
              </div>
            })}
          </div>
        </div>
        </div>
      </div>
    </section>
  }
}
export default BlogViewOthers;
