import React from "react";
import sprite from '../media/icons.svg';
class Cookies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptedCookies: false,
      cookiesModal: false,
      activeTab: 'tab1',
      mobileTabDropdown: false
    };
  }

  saveAcceptanceToLocalStorage() {
    localStorage.setItem("acceptedCookies", "true");
    this.setState({ acceptedCookies: true });
  }
  handlerCookiesModal(event){
    this.setState({ cookiesModal: true });
  }
  handleCookiesModalDisabled(event){
    this.setState({ cookiesModal: false });
  }
  handlerCookiesTab(tab, event){
    this.setState({ activeTab: tab });
  }
  handlerTabDropdown(event){
    this.setState(prevState => ({
      mobileTabDropdown: !prevState.mobileTabDropdown
    }));
  }
  render() {
    if (this.state.acceptedCookies || localStorage.getItem("acceptedCookies")) {
      return null;
    }
    return  <><div className="cookies-accept">
      <div className="wrapper">
        <div className="cookies-accept__inner">
          <div className="cookies-accept__info">
            <p><b>Ми використовуємо файли cookie, щоб запропонувати вам найкращий досвід використання веб-сайту.</b> Це включає файли cookie, які необхідні для роботи веб-сайту та управління нашими корпоративними цілями, а також інші файли cookie, які використовуються виключно для анонімних статистичних цілей і для більш зручного налаштування веб-сайту. Ви можете вирішувати, які категорії ви хочете дозволити.</p>
          </div>
          <div className="cookies-accept__nav">
            <ul className="cookies-accept-nav">
              <li className="cookies-accept-nav__item">
                <button className="btn btn_width_100" type="button" onClick={this.saveAcceptanceToLocalStorage.bind(this)}>Accept cookies</button>
              </li>
              <li className="cookies-accept-nav__item">
                <button className="btn btn_width_100 btn_theme_transparent" type="button" onClick={this.handlerCookiesModal.bind(this)}>Settings</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    {this.state.cookiesModal ? <div className="cookies-modal">
      <div className="cookies-modal__overlay" onClick={this.handleCookiesModalDisabled.bind(this)}></div>
      <div className="cookies-modal__inner">
        <div className="cookies-modal__header">
          <h2 className="cookies-modal__headline">Privacy Preference Center</h2>
          <button className="close-modal" type="button" onClick={this.handleCookiesModalDisabled.bind(this)}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <div className="cookies-modal__content">
          <div className="cookies-modal__nav">
            <button className="cookies-modal__dropdown-btn" type="button" onClick={this.handlerTabDropdown.bind(this)}>Your Privacy</button>
            <ul className={`cookies-modal-nav${this.state.mobileTabDropdown ? ' active' : ''}`}>
              <li className="cookies-modal-nav__item">
                <button className={`cookies-modal-nav__btn${this.state.activeTab === 'tab1' ? ' active' : ''}`} type="button" onClick={this.handlerCookiesTab.bind(this, 'tab1')}>Your Privacy</button>
              </li>
              <li className="cookies-modal-nav__item">
                <button className={`cookies-modal-nav__btn${this.state.activeTab === 'tab2' ? ' active' : ''}`} type="button" onClick={this.handlerCookiesTab.bind(this, 'tab2')}>Strictly Necessary Cookies</button>
              </li>
              <li className="cookies-modal-nav__item">
                <button className={`cookies-modal-nav__btn${this.state.activeTab === 'tab3' ? ' active' : ''}`} type="button" onClick={this.handlerCookiesTab.bind(this, 'tab3')}>Functional Cookies</button>
              </li>
              <li className="cookies-modal-nav__item">
                <button className={`cookies-modal-nav__btn${this.state.activeTab === 'tab4' ? ' active' : ''}`} type="button" onClick={this.handlerCookiesTab.bind(this, 'tab4')}>Performance Cookies</button>
              </li>
              <li className="cookies-modal-nav__item">
                <button className={`cookies-modal-nav__btn${this.state.activeTab === 'tab5' ? ' active' : ''}`} type="button" onClick={this.handlerCookiesTab.bind(this, 'tab5')}>Targeting Cookies</button>
              </li>
            </ul>
          </div>
          <div className="cookies-modal__info">
            <div className={`cookies-modal__tab${this.state.activeTab === 'tab1' ? ' active' : ''}`}>
              <h3 className="cookies-modal__headline">Your Privacy</h3>
              <p>1.1. Заказчик (продавец) – зарегистрированный на Сайте пользователь, заинтересованный в размещении информации о предложениях заведений общего питания и дальнейшей продаже через сервис ZABERU.COM.UA.</p>
              <p>1.2. Клиент – дееспособное физическое лицо, который совершает покупки предложений заведений общего питания.</p>
              <p>1.3. Исполнитель (Лукаш Алексей) – лицо, либо его представитель, оказывающий услуги по размещению на портале ZABERU.COM.UA.</p>
              <p>1.4. Пользователи – Клиент и Заказчик.</p>
              <p>1.5. Администрация сайта – лица, обслуживающие работоспособность сайта и сервисов.</p>
              <p>1.6. Сайт – домен https://www.zaberu.com.ua/ и все поддомены.</p>
              <p>1.7. Пользовательское Соглашение – соглашение, доступное по адресу https://zaberu.com.ua/terms.</p>
              <p>Определяет основные условия использования сайта.</p>
              <p>1.8. Аутентификационные данные - уникальный логин (login) и пароль (password) Клиента и Заказчика, используемые для доступа к сайту. В качестве логина используется адрес электронной почты или телефон.</p>
              <p>1.9. Предложение – страница Сайта, на которой размещена информации, фотографии и другие материалы о заведениях общего питания, еды или напитков.</p>
            </div>
            <div className={`cookies-modal__tab${this.state.activeTab === 'tab2' ? ' active' : ''}`}>
              <h3 className="cookies-modal__headline">Strictly Necessary Cookies</h3>
              <p>1.1. Заказчик (продавец) – зарегистрированный на Сайте пользователь, заинтересованный в размещении информации о предложениях заведений общего питания и дальнейшей продаже через сервис ZABERU.COM.UA.</p>
              <p>1.2. Клиент – дееспособное физическое лицо, который совершает покупки предложений заведений общего питания.</p>
              <p>1.3. Исполнитель (Лукаш Алексей) – лицо, либо его представитель, оказывающий услуги по размещению на портале ZABERU.COM.UA.</p>
              <p>1.4. Пользователи – Клиент и Заказчик.</p>
              <p>1.5. Администрация сайта – лица, обслуживающие работоспособность сайта и сервисов.</p>
              <p>1.6. Сайт – домен https://www.zaberu.com.ua/ и все поддомены.</p>
              <p>1.7. Пользовательское Соглашение – соглашение, доступное по адресу https://zaberu.com.ua/terms.</p>
              <p>Определяет основные условия использования сайта.</p>
              <p>1.8. Аутентификационные данные - уникальный логин (login) и пароль (password) Клиента и Заказчика, используемые для доступа к сайту. В качестве логина используется адрес электронной почты или телефон.</p>
              <p>1.9. Предложение – страница Сайта, на которой размещена информации, фотографии и другие материалы о заведениях общего питания, еды или напитков.</p>
            </div>
            <div className={`cookies-modal__tab${this.state.activeTab === 'tab3' ? ' active' : ''}`}>
              <h3 className="cookies-modal__headline">Functional Cookies</h3>
              <p>1.1. Заказчик (продавец) – зарегистрированный на Сайте пользователь, заинтересованный в размещении информации о предложениях заведений общего питания и дальнейшей продаже через сервис ZABERU.COM.UA.</p>
              <p>1.2. Клиент – дееспособное физическое лицо, который совершает покупки предложений заведений общего питания.</p>
              <p>1.3. Исполнитель (Лукаш Алексей) – лицо, либо его представитель, оказывающий услуги по размещению на портале ZABERU.COM.UA.</p>
              <p>1.4. Пользователи – Клиент и Заказчик.</p>
              <p>1.5. Администрация сайта – лица, обслуживающие работоспособность сайта и сервисов.</p>
              <p>1.6. Сайт – домен https://www.zaberu.com.ua/ и все поддомены.</p>
              <p>1.7. Пользовательское Соглашение – соглашение, доступное по адресу https://zaberu.com.ua/terms.</p>
              <p>Определяет основные условия использования сайта.</p>
              <p>1.8. Аутентификационные данные - уникальный логин (login) и пароль (password) Клиента и Заказчика, используемые для доступа к сайту. В качестве логина используется адрес электронной почты или телефон.</p>
              <p>1.9. Предложение – страница Сайта, на которой размещена информации, фотографии и другие материалы о заведениях общего питания, еды или напитков.</p>
            </div>
            <div className={`cookies-modal__tab${this.state.activeTab === 'tab4' ? ' active' : ''}`}>
              <h3 className="cookies-modal__headline">Performance Cookies</h3>
              <p>1.1. Заказчик (продавец) – зарегистрированный на Сайте пользователь, заинтересованный в размещении информации о предложениях заведений общего питания и дальнейшей продаже через сервис ZABERU.COM.UA.</p>
              <p>1.2. Клиент – дееспособное физическое лицо, который совершает покупки предложений заведений общего питания.</p>
              <p>1.3. Исполнитель (Лукаш Алексей) – лицо, либо его представитель, оказывающий услуги по размещению на портале ZABERU.COM.UA.</p>
              <p>1.4. Пользователи – Клиент и Заказчик.</p>
              <p>1.5. Администрация сайта – лица, обслуживающие работоспособность сайта и сервисов.</p>
              <p>1.6. Сайт – домен https://www.zaberu.com.ua/ и все поддомены.</p>
              <p>1.7. Пользовательское Соглашение – соглашение, доступное по адресу https://zaberu.com.ua/terms.</p>
              <p>Определяет основные условия использования сайта.</p>
              <p>1.8. Аутентификационные данные - уникальный логин (login) и пароль (password) Клиента и Заказчика, используемые для доступа к сайту. В качестве логина используется адрес электронной почты или телефон.</p>
              <p>1.9. Предложение – страница Сайта, на которой размещена информации, фотографии и другие материалы о заведениях общего питания, еды или напитков.</p>
            </div>
            <div className={`cookies-modal__tab${this.state.activeTab === 'tab5' ? ' active' : ''}`}>
              <h3 className="cookies-modal__headline">Targeting Cookies</h3>
              <p>1.1. Заказчик (продавец) – зарегистрированный на Сайте пользователь, заинтересованный в размещении информации о предложениях заведений общего питания и дальнейшей продаже через сервис ZABERU.COM.UA.</p>
              <p>1.2. Клиент – дееспособное физическое лицо, который совершает покупки предложений заведений общего питания.</p>
              <p>1.3. Исполнитель (Лукаш Алексей) – лицо, либо его представитель, оказывающий услуги по размещению на портале ZABERU.COM.UA.</p>
              <p>1.4. Пользователи – Клиент и Заказчик.</p>
              <p>1.5. Администрация сайта – лица, обслуживающие работоспособность сайта и сервисов.</p>
              <p>1.6. Сайт – домен https://www.zaberu.com.ua/ и все поддомены.</p>
              <p>1.7. Пользовательское Соглашение – соглашение, доступное по адресу https://zaberu.com.ua/terms.</p>
              <p>Определяет основные условия использования сайта.</p>
              <p>1.8. Аутентификационные данные - уникальный логин (login) и пароль (password) Клиента и Заказчика, используемые для доступа к сайту. В качестве логина используется адрес электронной почты или телефон.</p>
              <p>1.9. Предложение – страница Сайта, на которой размещена информации, фотографии и другие материалы о заведениях общего питания, еды или напитков.</p>
            </div>
          </div>
        </div>
      </div>
    </div> : ''}
    </>
  }
}
export default Cookies;
