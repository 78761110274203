import React from "react";
           const animationBlock = {
      backgroundColor: "#fff", position: "fixed", top: "0", left: "0", bottom: "0", right: "0", zIndex: "999998"
    };
    const animationStyle = {
        width: "40px",
        height: "40px",
        position: "fixed",
        top: "15px",
        left: "50%",
      animation: "spin 0.6s linear infinite",
      "@keyframes spin": {
        from: {
          transform: "rotate(0deg) translateX(-50%)"
        },
        to: {
          transform: "rotate(360deg) translateX(-50%)"
        }
      }
    };
class LoadingServer extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <div style={animationBlock}>
    <div style={animationStyle}>
      <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px"
         width="40px" height="40px" viewBox="0 0 50 50" xml="preserve">
      <path fill="#364ed4" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
        <animateTransform attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.6s"
          repeatCount="indefinite"/>
        </path>
      </svg></div></div>
  }
}
export default LoadingServer;
