import React from "react";
import { Link } from "react-router-dom";
import sprite from '../../media/icons.svg';
import DateFormating from '../DateFormating';
class BlogViewHeader extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <><div className="blog-page-breadrumbs">
      <div className="wrapper">
        <div className="breadcrumbs breadcrumbs__blog">
          <Link className="breadcrumbs__item breadcrumbs__link" to={this.props.getPermalink("/")}>Головна</Link>
          <span className="breadcrumbs__sep">
            <svg className="breadcrumbs__sep-icon">
              <use href={`${sprite}#right-icon`}></use>
            </svg>
          </span>
          <Link className="breadcrumbs__item breadcrumbs__link" to={this.props.getPermalink("/blog")}>Блог</Link>
          <span className="breadcrumbs__sep">
            <svg className="breadcrumbs__sep-icon">
              <use href={`${sprite}#right-icon`}></use>
            </svg>
          </span>
          <span className="breadcrumbs__item breadcrumbs__active">{this.props.headline}</span>
        </div>
      </div>
    </div>
    <div className="blog-page-info">
      <ul className="blog-page-info__list">
        <li>May. 10, 2023</li>
        <li>5 min read</li>
        <li>{this.props.category}</li>
      </ul>
    </div></>
  }
}
export default BlogViewHeader;
