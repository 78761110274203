import React from "react";
import { Link } from "react-router-dom";
import BlogWgt from './BlogWgt';
import DateFormating from '../DateFormating';
import sprite from '../../media/icons.svg';
class BlogContent extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let blogData = this.props.blogData;
    const blogDataFirst = blogData.slice(0, 4);
    const blogDataSecond = blogData.slice(4, 6);
    const blogDataThird = blogData.slice(6);
    return <section className="blog-page-content">
      <div className="wrapper">
          {blogDataFirst && blogDataFirst.length > 0 ? <div className="blog-page-content__row">
            {blogDataFirst.map((item, index) => {
              return <BlogWgt
                key={index}
                to={this.props.getPermalink(`${this.props.type === 'blog' ? '/blog/' : '/glossary/'}${item.link}`)}
                headline={item.name}
                category={item.category}
                date={<DateFormating start={item.modificationDate} time="off" />}
                thumb={item.photoUrl}
              />
            })}
        </div> : ''}
        {blogDataSecond && blogDataSecond.length > 0 ? <div className="blog-page-content__row blog-page-content__row_lg">
          {blogDataSecond.map((item, index) => {
            return <BlogWgt
              key={index}
              to={this.props.getPermalink(`${this.props.type === 'blog' ? '/blog/' : '/glossary/'}${item.link}`)}
              headline={item.name}
              category={item.category}
              date={<DateFormating start={item.modificationDate} time="off" />}
              thumb={item.photoUrl}
            />
          })}
        </div> : ''}
        {blogDataThird && blogDataThird.length > 0 ? <div className="blog-page-content__row">
          {blogDataFirst.map((item, index) => {
            return <BlogWgt
              key={index}
              to={this.props.getPermalink(`/${this.props.type === 'blog' ? '/blog/' : '/glossary/'}/${item.link}`)}
              headline={item.name}
              category={item.category}
              date={<DateFormating start={item.modificationDate} time="off" />}
              thumb={item.photoUrl}
            />
          })}
      </div> : ''}
        <nav className="pagination-nav pagination-nav__blog">
          <ul className="pagination">
            <li className="pagination__item">
              <button className="pagination__link pagination__link_prev" type="button">
                <svg>
                  <use href={`${sprite}#left-icon`}></use>
                </svg>
              </button>
            </li>
            <li className="pagination__item">
              <button className="pagination__link" type="button">1</button>
            </li>
            <li className="pagination__item">
              <button className="pagination__link" type="button">2</button>
            </li>
            <li className="pagination__item">
              <button className="pagination__link" type="button">3</button>
            </li>
            <li className="pagination__item">
              <button className="pagination__link" type="button">4</button>
            </li>
            <li className="pagination__item">
              <button className="pagination__link pagination__link_next" type="button">
                <svg>
                  <use href={`${sprite}#right-icon`}></use>
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </section>;
  }
}
export default BlogContent;
