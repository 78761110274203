import React from "react";
import Loading from './components/Loading';
import Select from 'react-select';
import Modal from './components/Modal';
import LabelStatus from './components/LabelStatus';
import Filter from './components/Filter';
import FilterSearch from './components/FilterSearch';
import { Link } from "react-router-dom";
import DateFormating from './components/DateFormating';
import ModalAddUsers from './components/ModalAddUsers';
import ResultQuery from './components/ResultQuery';
import ResultErrors from './components/ResultErrors';
import TabItems from './components/TabItems';
import AccountContentHeader from './components/AccountContentHeader';
import AccountContentEmpty from './components/AccountContentEmpty';
import DeleteModal from './components/DeleteModal';
import Button from './components/Button';
import Table from './components/Table';
import Pagination from './components/Pagination';
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import MessagesListBenefits from './components/MessagesListBenefits';
import sprite from './media/icons.svg';
import events_img from './media/events.png';
import { getAuth } from "firebase/auth";
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: '#364ed4',
    ':hover': {
      backgroundColor: '#e8ecff',
      color: '#364ed4',
    },
  })
};
function convertToISO(dateStr, timeStr = "00:00:00.000Z") {
    // Проверка на пустую строку или неверный формат
    if (!dateStr || !/^\d{2}-\d{2}-\d{4}$/.test(dateStr)) {
        console.error("Invalid date string format:", dateStr);
        return null;
    }

    // Разбиваем строку с датой на составляющие
    const [day, month, year] = dateStr.split('-').map(Number);

    // Создаем строку в формате YYYY-MM-DD
    const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

    // Создаем объект Date
    const date = new Date(`${formattedDate}T${timeStr}`);

    // Проверяем, корректна ли дата
    if (isNaN(date.getTime())) {
        console.error("Invalid Date object created:", dateStr);
        return null;
    }

    // Преобразуем в ISO строку
    return date.toISOString();
}
class Benefits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      benefits: [],
      totalPages: null,
      currentPage: 0,
      removeIndex: null,
      errors: null,
      error: false,
      isLoaded: false,
      controlRow: null,
      addUserData: [],
      clickedInside: false,
      activeTab: 'auto',
      dashboardInfo: {},
      modalDeleteAds: false,
      idDeleteAds: null,
      messages: [],
      openedMessage: [],
      histories: [],
      transactions: [],
      transactionTypes: [],
      modalEditCurrency: false,
      isLoadedSubmitCurrency: false,
      modalSubmitStatus: false,
      modalEditSuperCurrency: false,
      statusModal: [{
        label: 'Активний', value: true
      },{
        label: 'Не активний', value: false
      }],
      autoResetModal: [],
      superCurrencyData: {},
      companyData: {},
      chooseDateFull: null,
      startDate: null,
      endDate:null,
      finishStartDate: null,
      finishEndDate: null,
      startTime: null,
      endTime: null,
      hoverDate: null,
      dateModal: false,
      date: new Date(),
      fullYear: null,
      month: null,
      currentDate: new Date(),
      dates: [],
      countFirstElems: null,
      countEndedElems: null,
      iterStart: null,
      iterStartSave: null,
      iterHover: null,
      countMonth: 1,
      countMonthSave: null,
      iterFinish: null,
      countMonthFinish: null,
      dateStartValue: '',
      dateEndValue: '',
      paginationPath: `${this.props.queryLink}/api/v1/accruals/search`,
      labelStatus: false,
      historyTypes: [{
        name: 'Всі', value: null,
      },
      {
        name: 'Нараховано', value: 'SUCCESS',
      },
      {
        name: 'Відхилено', value: 'CANCELED ',
      }]
    }
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }


  handlerGetDate (event) {
    event.preventDefault();
    if (!event.target.closest('.btn-close-element')) {
      let fullYear = this.state.date.getFullYear();
      let month = this.state.date.getMonth();
      let arr = [];
      let firstDateOfMonth = 1;
      let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
      let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
      let pushElemsNum = this.getPushElemsNum(fullYear, month);
      arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
      arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
      arr = this.pushElems(pushElemsNum, arr);
      arr = this.chunkArr(7, arr);
      this.setState(prevState => ({
        dateModal: !prevState.dateModal,
        dates: arr,
        fullYear: fullYear,
        pushElemsTotal: pushElemsNum,
        month: month
      }));
    }
  }
  getLastDayOfMonth (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDate();
  }
  getUnshiftElemsNum (year, month) {
    let jsDayNum = this.getFirstWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return realDayNum - 1;
  }
  getPushElemsNum (year, month) {
    let jsDayNum = this.getLastWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return 7 - realDayNum;
  }
  createArr (from, to) {
    const arr = [];
    for(let i = from; i <= to; i++) {
      arr.push(i);
    }
    return arr;
  }
  unshiftElems (num, lastDayPrevMonth, arr) {
    this.setState(prevState => ({
      countFirstElems: num
    }));
    let count =  lastDayPrevMonth - num;
    for(let i = lastDayPrevMonth; i > count; i--) {
      arr.unshift(i);
    }
    return arr;
  }
  pushElems (num, arr) {
    this.setState(prevState => ({
      countEndedElems: num
    }));
    for(let i = 1; i <= num; i++) {
      arr.push(i);
    }
    return arr;
  }
  chunkArr (num, arr) {
    let result = [];
    let chunk = [];
    let iterCount = arr.length / num;
    for(let i = 0; i <iterCount; i++) {
      chunk = arr.splice(0, num);
      result.push(chunk);
    }
    return result;
  }
  getRealDayOfWeekNum (jsNumOfDay) {
    if(jsNumOfDay === 0){
      return 7;
    } else {
      return jsNumOfDay;
    }
  }
  getFirstWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month, 1);
    return date.getDay();
  }
  getLastWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDay();
  }
  getMonthName (num) {
    const monthes = [
      this.props.getTranslate('modal-calendarMonthNameJan'),
      this.props.getTranslate('modal-calendarMonthNameFeb'),
      this.props.getTranslate('modal-calendarMonthNameMar'),
      this.props.getTranslate('modal-calendarMonthNameApr'),
      this.props.getTranslate('modal-calendarMonthNameMay'),
      this.props.getTranslate('modal-calendarMonthNameJun'),
      this.props.getTranslate('modal-calendarMonthNameJul'),
      this.props.getTranslate('modal-calendarMonthNameAug'),
      this.props.getTranslate('modal-calendarMonthNameSep'),
      this.props.getTranslate('modal-calendarMonthNameOct'),
      this.props.getTranslate('modal-calendarMonthNameNov'),
      this.props.getTranslate('modal-calendarMonthNameDec')
    ];
    return monthes[num];
  }
  handlerCloseGetDate (event) {
    event.preventDefault();
    this.setState(prevState => ({
      dateModal: false
    }));
  }
  handlerPrevMonth (event) {
    event.preventDefault();
    let date = new Date(this.state.date.getFullYear(), this.state.date.getMonth() - 1, this.state.date.getDate());
    let fullYear = date.getFullYear();
    let month = date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      date: date,
      dates: arr,
      fullYear: fullYear,
      month: month,
      countMonth: Number(prevState.countMonth) - 1,
      iterStart: Number(prevState.countMonth) === (Number(prevState.countMonthSave) + 1) ? Number(prevState.iterStartSave) : '0'
    }));
  }
  handlerNextMonth (event) {
    event.preventDefault();
    let date = new Date(this.state.date.getFullYear(), this.state.date.getMonth() + 1, this.state.date.getDate());
    let fullYear = date.getFullYear();
    let month = date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      date: date,
      dates: arr,
      fullYear: fullYear,
      month: month,
      countMonth: Number(prevState.countMonth) + 1,
      iterStart: Number(prevState.countMonth) >= Number(prevState.countMonthSave) ? '0' : Number(prevState.iterStartSave)
    }));
  }
  chooseDate (iterStart, nextMonth, prevMonth, event) {
    event.preventDefault();
    let month = Number(this.state.month) + 1;
    let year = this.state.fullYear;
    if(nextMonth){
      month = Number(month) + 1;
      if(month === 13){
        month = 1;
        year = year + 1;
      }
    }
    if(prevMonth){
      month = Number(month) - 1;
      if(month === 0){
        month = 12;
        year = year - 1;
      }
    }
    if(!this.state.finishStartDate){
      this.setState(prevState => ({
        chooseDateType: 'finish',
        startDate: year + '-' + month + '-' + event.target.innerHTML,
        finishStartDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '-' + (month < 10 ? '0' + month : month) + '-' + year,
        iterStart: iterStart,
        iterStartSave: iterStart,
        countMonthSave: prevState.countMonth,
        dateStartValue: ''
      }));
    } else if(this.state.chooseDateType === 'start') {
      this.setState(prevState => ({
        chooseDateType: 'finish',
        startDate: year + '-' + month + '-' + event.target.innerHTML,
        finishStartDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '-' + (month < 10 ? '0' + month : month) + '-' + year,
        iterStart: iterStart,
        iterStartSave: iterStart,
        countMonthSave: prevState.countMonth,
        dateStartValue: ''
      }));
    } else if(this.state.chooseDateType === 'finish'){
      this.setState(prevState => ({
        endDate: new Date(prevState.startDate) > new Date(year + '-' + month + '-' + event.target.innerHTML) ? null : year + '-' + month + '-' + event.target.innerHTML,
        finishEndDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '-' + (month < 10 ? '0' + month : month) + '-' + year,
        iterFinish: iterStart,
        countMonthFinish: prevState.countMonth,
        dateEndValue: ''
      }));
    } else {
      this.setState(prevState => ({
        endDate: new Date(prevState.startDate) > new Date(year + '-' + month + '-' + event.target.innerHTML) ? null : year + '-' + month + '-' + event.target.innerHTML,
        finishEndDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '-' + (month < 10 ? '0' + month : month) + '-' + year,
        iterFinish: iterStart,
        countMonthFinish: prevState.countMonth,
        dateEndValue: ''
      }));
    }
  }
  chooseDateHover (iterHover, event) {
    event.preventDefault();
    if(this.state.startDate){
      this.setState(prevState => ({
        iterHover: iterHover
      }));
    }
  }
  chooseEndHover (event) {
    event.preventDefault();
    this.setState(prevState => ({
      iterHover: null
    }));
  }
  onKeyPress (event) {
    let input = event.target;
    if(event.charCode < 47 || event.charCode > 57) {
      event.preventDefault();
    }
    var len = input.value.length;
    if(len !== 1 || len !== 3) {
      if(event.charCode == 47) {
        event.preventDefault();
      }
    }
    if(len === 2) {
      input.value += '.';
    }
    if(len === 5) {
      input.value += '.';
    }
  }
  onKeyPress2 (event) {
    let input = event.target;
    if(event.charCode < 47 || event.charCode > 57) {
      event.preventDefault();
    }
    var len = input.value.length;
    if(len !== 1 || len !== 3) {
      if(event.charCode == 47) {
        event.preventDefault();
      }
    }
    if(len === 2) {
      input.value += ':';
    }
  }
  handlerStartTime (event) {
    event.preventDefault();
    this.setState({
      startTime: event.target.value
    });
  }
  handlerEndTime (event) {
    event.preventDefault();
    this.setState({
      endTime: event.target.value
    });
  }

  setDateInput(event) {
      event.preventDefault();

      this.setState(prevState => ({
          chooseDateFull: prevState.finishStartDate.split("-").join(".") + ' - ' +
                          (prevState.finishEndDate ? prevState.finishEndDate.split("-").join(".") : prevState.finishStartDate.split("-").join(".")),
          dateModal: !prevState.dateModal
      }));

      const { finishStartDate, finishEndDate } = this.state;

      // Логируем данные перед конвертацией
      // Конвертация дат в ISO
      const isoDateStart = convertToISO(finishStartDate);
      const isoDateEnd = finishEndDate ? convertToISO(finishEndDate) : isoDateStart;

      // Проверка на успешную конвертацию
      if (!isoDateStart || !isoDateEnd) {
          return;
      }

      let that = this;

      getAuth().currentUser.getIdToken().then(function(idToken){
          const myHeaders = new Headers({
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + idToken
          });

          fetch(`${that.props.queryLink}/api/v1/transactions/search`, {
              method: 'POST',
              headers: myHeaders,
              body: JSON.stringify({
                  "itemsPerPage": 20,
                  "orderField": "creationDate",
                  "from": isoDateStart,
                  "to": isoDateEnd
              })
          }).then(res => {
              if(res.status !== 200) {
                  res.json().then(function(data) {
                      that.setState({
                          errors: data.errors,
                          isLoadedMessages: true,
                          error: true
                      });
                  });
              } else {
                  res.json().then(function(data) {
                      that.setState({
                          isLoadedMessages: true,
                          totalPages: data.totalPages,
                          transactions: data.data
                      });
                  });
              }
          });
      });
  }


  chooseDateHandler(choose, event){
    this.setState({
      chooseDateType: choose
    });
  }

  handlerDateStart(event){
    let value = event.target.value;
    this.setState({
      dateStartValue: value
    });
    if(value === '' && this.state.finishStartDate){
      this.setState({
        dateStartValue: null
      });
    }
  }

  handlerDateEnd(event){
    let value = event.target.value;
    this.setState({
      dateEndValue: value
    });
    if(value === '' && this.state.finishEndDate){
      this.setState({
        finishEndDate: null
      });
    }
  }

  /* Handler for close "Edit modal" */
  handlerCloseEditModal(event){
    setTimeout(() => {
      this.setState({
        modalEditCurrency: false,
        modalEditSuperCurrency: false
      });
    }, 500);
  }

  handlerEditCurrency (event) {
    this.setState(prevState => ({
      modalEditCurrency: !prevState.modalEditCurrency
    }));
    let that = this;
    let location;
    let language = 'uk-UA';
    if (typeof window !== "undefined" && typeof document !== 'undefined') {
      location = document.location.pathname;
      const isEnglish = location.startsWith('/en');
      if (isEnglish) {
        language = 'en-US';
      }
    }
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken,
          'Accept-Language': language
      });
      fetch(`${that.props.queryLink}/api/v1/companies`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              companyData: data
            })
          })
        }
      })
    });

  }

  handlerEditSuperCurrency (event) {
    let that = this;
    let location;
    let language = 'uk-UA';
    if (typeof window !== "undefined" && typeof document !== 'undefined') {
      location = document.location.pathname;
      const isEnglish = location.startsWith('/en');
      if (isEnglish) {
        language = 'en-US';
      }
    }
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken,
          'Accept-Language': language
      });
      fetch(`${that.props.queryLink}/api/v1/accounting/balance/config`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              superCurrencyData: data
            })
          })
        }
      })
    });
    this.setState(prevState => ({
      modalEditSuperCurrency: !prevState.modalEditSuperCurrency
    }))
  }


  componentDidUpdate() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){

    }
  }

  /* Handler for get mobile menu */
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };

  /* Handler for dropdown */
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }

  /* Pagination handler */
  handlerPaginationItems = (eventsItems, current) => {
    if(this.state.activeTab === 'query'){
      this.setState({
        messages: eventsItems
      });
    } else if(this.state.activeTab === 'benefit'){
      this.setState({
        histories: eventsItems
      });
    } else if(this.state.activeTab === 'transaction'){
      this.setState({
        transactions: eventsItems
      });
    } else {
      this.setState({
        accrualsAuto: eventsItems
      });
    }
    this.setState({
      currentPage: current
    });
  }

  /* Handler for close success labels */
  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successEventSubmit: null,
      successAddUser: false,
      successText: null
    }));
  }

  /* Handler for close errors labels */
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }

  /* Events disabled modal */
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
      dataEventModal: [],
      modalDeleteBenefit: false,
      idDeleteBenefit: null
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false
      }));
    }, 500);
  }

  /* Handler get data mount component */
  async componentDidMount() {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const { body } = document;
      if (body.classList.contains("preview")) {
        body.classList.remove("preview");
      }
    }

    try {
      const idToken = await getAuth().currentUser.getIdToken();
      let language = "uk-UA";
      let location;

      if (typeof window !== "undefined" && typeof document !== "undefined") {
        location = document.location.pathname;
        if (location.startsWith("/en")) {
          language = "en-US";
        }
      }

      const myHeaders = new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
        "Accept-Language": language,
      });

      // First request
      const firstResponse = await fetch(
        `${this.props.queryLink}/api/v1/accruals/dashboards`,
        {
          method: "GET",
          headers: myHeaders,
        }
      );

      if (!firstResponse.ok) {
        const firstErrorData = await firstResponse.json();
        this.setState({
          isLoaded: true,
          errors: firstErrorData.errors || [firstErrorData.message],
          error: true,
        });
        return;
      }

      const dashboardInfo = await firstResponse.json();

      this.setState({ dashboardInfo });


      // Super coin request
      const superCoinResponce = await fetch(
        `${this.props.queryLink}/api/v1/companies/config`,
        {
          method: "GET",
          headers: myHeaders,
        }
      );

      if (!superCoinResponce.ok) {
        const superCoinErrorData = await superCoinResponce.json();
        this.setState({
          isLoaded: true,
          errors: superCoinErrorData.errors || [superCoinErrorData.message],
          error: true,
        });
        return;
      }

      const superCoinInfo = await superCoinResponce.json();

      this.setState({ superCoinInfo, labelStatus: superCoinInfo.superCoin });



      // Second request
      const secondResponse = await fetch(
        `${this.props.queryLink}/api/v1/accruals/search`,
        {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            itemsPerPage: 20,
            orderField: "creationDate",
          }),
        }
      );

      if (!secondResponse.ok) {
        const secondErrorData = await secondResponse.json();
        this.setState({
          isLoaded: true,
          errors: secondErrorData.errors || [secondErrorData.message],
          error: true,
        });
        return;
      }

      const accrualsData = await secondResponse.json();
      console.log(accrualsData);
      this.setState({
        isLoaded: true,
        accrualsAuto: accrualsData.data,
        totalPages: accrualsData.totalPages,
      });

      // Third request
      const thirdResponse = await fetch(
        `${this.props.queryLink}/api/v1/accounting/balance/reset-types`,
        {
          method: "GET",
          headers: myHeaders,
        }
      );

      if (thirdResponse.ok) {
        const resetTypesData = await thirdResponse.json();
        const formattedOptions = resetTypesData.map((item) => ({
          value: item.type,
          label: item.name,
        }));

        this.setState({
          autoResetModal: formattedOptions,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
      }
    } catch (error) {
      this.setState({
        isLoaded: true,
        errors: [error.message],
        error: true,
      });
    }
  }



  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handlerClickInside(event){
    this.setState({
      addUserContent: false
    });
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        addUserContent: false
      });
    }
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setWrapperRefButton(node){
    this.wrapperRefButton = node;
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }

  handleTabs(tab, event){
    let that = this;
    this.setState({
      activeTab: tab
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/accruals/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"itemsPerPage": 20})
      }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors,
                  isLoadedMessages: true,
                  error: true
                });
              })
            } else {
              res.json().then(function(data) {
               that.setState({
                isLoadedMessages: true,
                totalPages: data.totalPages,
                benefits: data.data,
                paginationPath: `${that.props.queryLink}/api/v1/accruals/search`
              });
              })
            }
      })
    })
  }

  /* Handler for change status */
  handlerEnabled(id, index, event){
    let that = this;
    let arr = that.state.accrualsAuto;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/accruals/${id}/enable`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({"enable": !arr[index].enabled})
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            arr[index] = data;
            that.setState({
              accrualsAuto: arr
            })
          })
        }
      })
    });
  }

  /* Ads modal delete handler */
  handlerDeleteBenefitModal(id, index, event){
    this.setState({
      modalDeleteBenefit: true,
      removeIndex: index,
      idDeleteBenefit: id
    });
  }


  /* Ads delete handler for submit */
  handlerDeleteBenefit(id, event){
    let that = this;
    that.setState({
      loadingDeleteSumbit: true
    })
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/accruals/${id}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
      } else {
        that.handleModalDisabled();
        that.setState({
          indexRowBenefit: that.state.removeIndex
        });
        fetch(`${that.props.queryLink}/api/v1/accruals/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({"itemsPerPage": 20, "page": that.state.accrualsAuto.length < 2 ? 1 : that.state.currentPage})
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                error: true
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState({
                indexRowBenefit: null,
                removeIndex: null,
                accrualsAuto: data.data,
                totalPages: data.totalPages,
                loadingDeleteSumbit: false
              });
            })
          }
        })
      }
    })
  })
  }

  handleGetOfferBenefits(event){
    let that = this;
    this.setState({
      activeTab: 'query'
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/messages/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"itemsPerPage": 20, "messageType": "NOMINATION"})
      }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors,
                  isLoadedMessages: true,
                  error: true
                });
              })
            } else {
              res.json().then(function(data) {
               that.setState({
                isLoadedMessages: true,
                totalPages: data.totalPages,
                messages: data.data,
                paginationPath: `${that.props.queryLink}/api/v1/messages/search`
              });
              })
            }
      })
    })
  }

  handlerGetHistoryType(type, event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/accruals/histories/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"itemsPerPage": 20, "orderField": "creationDate", "status": type})
      }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors,
                  isLoadedMessages: true,
                  error: true
                });
              })
            } else {
              res.json().then(function(data) {
               that.setState({
                isLoadedMessages: true,
                totalPages: data.totalPages,
                histories: data.data
              });
              })
            }
      })
    })
  }

  handleGetHistoriesBenefits(event){
    let that = this;
    this.setState({
      activeTab: 'benefit'
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/accruals/histories/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"itemsPerPage": 20, "orderField": "creationDate"})
      }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors,
                  isLoadedMessages: true,
                  error: true
                });
              })
            } else {
              res.json().then(function(data) {
                console.log(data);
               that.setState({
                isLoadedMessages: true,
                totalPages: data.totalPages,
                histories: data.data,
                chooseDateFull: null,
                startDate: null,
                endDate:null,
                finishStartDate: null,
                finishEndDate: null,
                startTime: null,
                endTime: null,
                hoverDate: null,
                dateModal: false,
                date: new Date(),
                fullYear: null,
                month: null,
                currentDate: new Date(),
                dates: [],
                countFirstElems: null,
                countEndedElems: null,
                iterStart: null,
                iterStartSave: null,
                iterHover: null,
                countMonth: 1,
                countMonthSave: null,
                iterFinish: null,
                countMonthFinish: null,
                dateStartValue: '',
                dateEndValue: '',
                paginationPath: `${that.props.queryLink}/api/v1/accruals/histories/search`
              });
              })
            }
      })
    })
  }

  handleGetTransactionBenefits(event){
    let that = this;
    this.setState({
      activeTab: 'transaction'
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/transactions/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"itemsPerPage": 20, "orderField": "creationDate"})
      }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors,
                  isLoadedMessages: true,
                  error: true
                });
              })
            } else {
              res.json().then(function(data) {
               that.setState({
                isLoadedMessages: true,
                totalPages: data.totalPages,
                transactions: data.data,
                chooseDateFull: null,
                startDate: null,
                endDate:null,
                finishStartDate: null,
                finishEndDate: null,
                startTime: null,
                endTime: null,
                hoverDate: null,
                dateModal: false,
                date: new Date(),
                fullYear: null,
                month: null,
                currentDate: new Date(),
                dates: [],
                countFirstElems: null,
                countEndedElems: null,
                iterStart: null,
                iterStartSave: null,
                iterHover: null,
                countMonth: 1,
                countMonthSave: null,
                iterFinish: null,
                countMonthFinish: null,
                dateStartValue: '',
                dateEndValue: '',
                paginationPath: `${that.props.queryLink}/api/v1/transactions/search`
              });
              })
            }
      })
    })
  }



  handlerMessage (id, viewed, index, event) {
    let that = this;
    if(!event.target.closest('.control-wgt')){
      if(this.state.openedMessage.indexOf(id) !== -1) {
        let myIndex = this.state.openedMessage.indexOf(id);
        let arr = this.state.openedMessage;
        if (myIndex !== -1) {
            arr.splice(myIndex, 1);
        }
        this.setState(prevState => ({
          openedMessage: arr
        }));
      } else {
        this.setState(prevState => ({
          openedMessage: [...prevState.openedMessage, id]
        }));
      }
      if(!viewed){
        getAuth().currentUser.getIdToken().then(function(idToken){
          const myHeaders = new Headers({
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + idToken
          });
          fetch(`${that.props.queryLink}/api/v1/messages/${id}/viewed`, {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify({
              "viewed": true
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                });
              })
            } else {
              let messages = that.state.messages;
              messages[index].viewed = true;
              that.setState(prevState => ({
                messages: messages
              }));
            }

          })
        })
      }
    }
  }

  handlerMessageModal (id, viewed, index, event) {
    let that = this;
    if(!event.target.classList.contains('messages-wgt__reply-btn-mob')){
      if(this.state.openedMessage.indexOf(id) !== -1) {
        let myIndex = this.state.openedMessage.indexOf(id);
        let arr = this.state.openedMessage;
        if (myIndex !== -1) {
            arr.splice(myIndex, 1);
        }
        this.setState(prevState => ({
          openedMessage: arr
        }));
      } else {
        this.setState(prevState => ({
          openedMessage: [...prevState.openedMessage, id]
        }));
      }
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/messages/${id}`, {
        method: 'GET',
        headers: myHeaders,
      }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                });
              })
            } else {
              res.json().then(function(data) {
                that.setState(prevState => ({
                  message: data,
                  modal: !prevState.modal,
                  messageModal: !prevState.messageModal
                }));
                if(!viewed) {
                fetch(`${that.props.queryLink}/api/v1/messages/${id}/viewed`, {
                  method: 'PUT',
                  headers: myHeaders,
                  body: JSON.stringify({
                    "viewed": true
                  })
                }).then(res => {
                  if(res.status !== 200) {
                    res.json().then(function(data) {
                      that.setState({
                        errors: data.errors
                      });
                    })
                  } else {
                    let messages = that.state.messages;
                    messages[index].viewed = true;
                    data.setState(prevState => ({
                      messages: messages
                    }));
                  }
                })
              }
              })
            }
          })
    })
    }
  }

  answerHandler (id) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/messages/${id}`, {
        method: 'GET',
        headers: myHeaders,
      }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  isLoadedMessage: true,
                  errors: data.errors,
                  error: true
                });
              })
            } else {
              res.json().then(function(data) {
                that.setState(prevState => ({
                  isLoadedMessage: true,
                  message: data,
                  modal: true,
                  messageModal: false,
                  answerModal: true,
                  modalClass: null
                }))
              })
            }
          })
    })
  }

  handlerDepositUser(id, name, event){

    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken) {
            const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/${id}`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {

        } else {
          res.json().then(function(data) {
            let fullName = (data.name ? data.name + ' ' : '') + '' + (data.lastName ? data.lastName : '');
            let arr = [{id: id, name: fullName}];
            if(arr.length > 0){
              localStorage.setItem("usersIds", JSON.stringify(arr));
              that.setState(prevState => ({
                redirectDeposit: true
              }));
            }
          });
        }
      });
    });
  }

  handlerGetTransactionsType(type, event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/transactions/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"itemsPerPage": 20, "orderField": "creationDate", "transactionType": type})
      }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors,
                  isLoadedMessages: true,
                  error: true
                });
              })
            } else {
              res.json().then(function(data) {
               that.setState({
                isLoadedMessages: true,
                totalPages: data.totalPages,
                transactions: data.data
              });
              })
            }
      })
    })
  }

  handlerGetTransactionTypes(event){
    let that = this;
    let location;
    let language = 'uk-UA';
    if (typeof window !== "undefined" && typeof document !== 'undefined') {
      location = document.location.pathname;
      const isEnglish = location.startsWith('/en');
      if (isEnglish) {
        language = 'en-US';
      }
    }
    getAuth().currentUser.getIdToken().then(function(idToken) {
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken,
          'Accept-Language': language
      });
      fetch(`${that.props.queryLink}/api/v1/transactions/types`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {

        } else {
          res.json().then(function(data) {
            that.setState(prevState => ({
              transactionTypes: data
            }));
          });
        }
      });
    });
  }

  handlerGetDateFilter(event){

  }

  handlerSearch(event){

  }

  handleStatusSuperCoin(event) {
    let superCurrencyData = this.state.superCoinInfo;
    superCurrencyData.superCoin = !superCurrencyData.superCoin;
    this.setState(prevState => ({
      superCoinInfo: superCurrencyData
    }));
  }

  handleNameSuperCoin(event){
    let superCurrencyData = this.state.superCurrencyData;
    superCurrencyData.name = event.target.value;
    this.setState(prevState => ({
      superCurrencyData: superCurrencyData
    }));
  }

  handleAutoResetSuperCoin(event){
    let superCurrencyData = this.state.superCurrencyData;
    superCurrencyData.recurringType = event.value;
    this.setState(prevState => ({
      superCurrencyData: superCurrencyData
    }));
  }

  handlerFetchOptions(event) {
    let that = this;
    let location;
    let language = 'uk-UA';

    if (typeof window !== "undefined" && typeof document !== 'undefined') {
      location = document.location.pathname;
      const isEnglish = location.startsWith('/en');
      if (isEnglish) {
        language = 'en-US';
      }
    }
  getAuth().currentUser.getIdToken().then(function(idToken) {
    const myHeaders = new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + idToken,
      'Accept-Language': language
    });
    fetch(`${that.props.queryLink}/api/v1/accounting/balance/reset-types`, {
      method: 'GET',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(data => {

        const formattedOptions = data.map(item => ({
          value: item.type,
          label: item.name
        }));
        // Предполагается, что data — это массив объектов с { value: '1', label: 'Option 1' }
        that.setState({ autoResetModal: formattedOptions, isLoading: false });
      })
      .catch(error => {
        that.setState({ isLoading: false });
      });
    })
  };

  handlerEditCurrencySubmit(event){
    event.preventDefault();
    let that = this;
    let error = false;
    that.setState({ isLoadingSubmit: true });
    if(!error) {
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/companies`, {
          method: 'PUT',
          headers: myHeaders,
          body: JSON.stringify({
            "companyID": that.state.companyData.companyID,
            "email": that.state.companyData.email,
            "name": that.state.companyData.name,
            "phone": that.state.companyData.phone,
            "status": that.state.companyData.status,
            "currency": that.state.companyData.currency,
            "description": that.state.companyData.description,
            "photos": that.state.companyData.photos
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoadingSubmit: false,
                errors: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState(prevState => ({
                successSubmit: true,
                successText: that.props.getTranslate('successStatus-Save'),
                isLoadingSubmit: false,
                modalSubmitStatus: true
              }));
              setTimeout(() => {
                that.setState({ modalSubmitStatus: false });
              }, 500);
            })
          }
        })
      })
    } else {
      this.setState({
        modalEditLoading: false,
        errorFields: true
      });
    }
  }

  handlerEditSuperCurrencySubmit(event){
    let that = this;
    let location;
    let language = 'uk-UA';

    if (typeof window !== "undefined" && typeof document !== 'undefined') {
      location = document.location.pathname;
      const isEnglish = location.startsWith('/en');
      if (isEnglish) {
        language = 'en-US';
      }
    }
    that.setState({ isLoadingSubmit: true });
    getAuth().currentUser.getIdToken().then(function(idToken) {
      let superCurrencyData = that.state.superCurrencyData;
      superCurrencyData.enabled = that.state.superCoinInfo.superCoin;
    const myHeaders = new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + idToken,
      'Accept-Language': language
    });
    fetch(`${that.props.queryLink}/api/v1/accounting/balance/config/${that.state.superCurrencyData.balanceConfigID}`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(that.state.superCurrencyData)
    })
      .then(response => response.json())
      .then(data => {
        fetch(`${that.props.queryLink}/api/v1/companies/config/item`, {
          method: 'PUT',
          headers: myHeaders,
          body: JSON.stringify({
            "value": that.state.superCoinInfo.superCoin,
            "configItem": "SUPER_COIN"
          })
        })
          .then(response => response.json())
          .then(data => {
            that.setState({ isLoadingSubmit: false, modalSubmitStatus: true, superCoinInfo: data, labelStatus: data.superCoin });
            setTimeout(() => {
              that.setState({ modalSubmitStatus: false });
            }, 500);
          })
          .catch(error => {
            that.setState({ isLoadingSubmit: false });
          });

      })
      .catch(error => {
        that.setState({ isLoadingSubmit: false });
      });
    })
  }

  handleNameCoin(event){
    let companyData = this.state.companyData;
    companyData.currency = event.target.value;
    this.setState({ currencyName: companyData });
  }

  handlerResetDate(event){
    let that = this;
    if(this.state.activeTab === 'benefit'){
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/accruals/histories/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({"itemsPerPage": 20, "orderField": "creationDate"})
        }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors,
                    isLoadedMessages: true,
                    error: true
                  });
                })
              } else {
                res.json().then(function(data) {
                  console.log(data);
                 that.setState({
                  isLoadedMessages: true,
                  totalPages: data.totalPages,
                  histories: data.data
                });
                })
              }
        })
      })
    } else {
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/transactions/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({"itemsPerPage": 20, "orderField": "creationDate"})
        }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors,
                    isLoadedMessages: true,
                    error: true
                  });
                })
              } else {
                res.json().then(function(data) {
                 that.setState({
                  isLoadedMessages: true,
                  totalPages: data.totalPages,
                  transactions: data.data
                });
                })
              }
        })
      })
    }
    that.setState({
      chooseDateFull: null,
      startDate: null,
      endDate:null,
      finishStartDate: null,
      finishEndDate: null,
      startTime: null,
      endTime: null,
      hoverDate: null,
      dateModal: false,
      date: new Date(),
      fullYear: null,
      month: null,
      currentDate: new Date(),
      dates: [],
      countFirstElems: null,
      countEndedElems: null,
      iterStart: null,
      iterStartSave: null,
      iterHover: null,
      countMonth: 1,
      countMonthSave: null,
      iterFinish: null,
      countMonthFinish: null,
      dateStartValue: '',
      dateEndValue: ''
    })
  }

  /* Render component */
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.title = 'Нарахування' + ' | ' + this.props.companyName;
    }

    let totalItem = this.state.dates.length * 7;
    let totalItemNotPush = totalItem - this.state.pushElemsTotal;
    let firstItems = this.state.countFirstElems;
    let endedItems = totalItem - this.state.countEndedElems;
    let iter = 0;
    let tableDate = this.state.dates.map((item, index) => {
      return <tr key={index}>
        {item.map((elem, i) => {
          iter++;
          if(new Date(this.state.startDate) <= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + elem) && new Date(this.state.hoverDate) >= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + elem)) {
            return <td className="checked" key={i} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)} onMouseLeave={this.chooseEndHover.bind(this)}>{elem}</td>
          } else if(iter < endedItems && this.state.startDate && (new Date(this.state.startDate).getDate() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getDate()) && (new Date(this.state.startDate).getMonth() - 1 === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getMonth()) && (new Date(this.state.startDate).getFullYear() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getFullYear())) {
            return <td className="checked" key={i} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)} onMouseLeave={this.chooseEndHover.bind(this)}>{elem}</td>
          } else if(this.state.iterStart && (this.state.countMonth >= this.state.countMonthSave && this.state.iterStart <= iter && this.state.iterHover >= iter)) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else if(this.state.iterStart && this.state.iterFinish && (this.state.countMonth <= this.state.countMonthFinish && this.state.countMonth >= this.state.countMonthSave) && (this.state.iterStart <= iter && this.state.iterFinish >= iter)) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else if(this.state.iterStart && this.state.iterFinish && this.state.countMonth < this.state.countMonthFinish && this.state.countMonth > this.state.countMonthSave){
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else if((this.state.iterStart && this.state.iterFinish) && (this.state.countMonthSave !== this.state.countMonthFinish) && (this.state.countMonth === this.state.countMonthSave) && iter > this.state.iterStart) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else {
            return <td key={i} onMouseEnter={this.chooseDateHover.bind(this, iter)} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          }
        })}
      </tr>
    });

    if(this.state.successEventSubmit){
      setTimeout(() => {
        this.handlerCloseSuccess();
      }, 5000);
    }
    let resultBenefits = '';
      if(this.state.error) {
        resultBenefits = <div>{this.props.getTranslate('loading-ErrorRenderContent')}</div>;
      } else if(!this.state.isLoaded) {
        resultBenefits = <Loading />;
      } else {
        if(this.state.activeTab === 'auto') {
          resultBenefits = <div className="account-wgt account-wgt__events">
          {/* Table component */}
            <Table
              header={[
                this.props.getTranslate('accrual-TableName'),
                this.props.getTranslate('accrual-TableType'),
                this.props.getTranslate('accrual-TableUsers'),
                this.props.getTranslate('accrual-TableImplementation'),
                this.props.getTranslate('accrual-TableAmount'),
                ''
              ]}
              bodyData={this.state.accrualsAuto}
              bodyDataNames={['name', 'accrualType', 'usersCount', 'recurringType', 'bonus']}
              controlItemsStatus={true}
              controlItems={{
                'enabled': {'status': true, 'handler': this.handlerEnabled.bind(this), 'label': {'hide': this.props.getTranslate('tableDropdown-StatusLabel'), 'show': this.props.getTranslate('tableDropdown-StatusLabelShow')}},
                'editButton': false,
                'editLink': {
                  'status': true,
                  'handler': this.props.getPermalink(`/account/accrual/edit/`),
                  'label': this.props.getTranslate('tableDropdown-EditLabel')
                },
                'delete': {
                  'status': true,
                  'handler': this.handlerDeleteBenefitModal.bind(this),
                  'label': this.props.getTranslate('tableDropdown-EditRemove')
                }
              }}
              translationValueItems={{
                  'COIN': "Coin",
                  'SUPER_COIN': "Super Coin",
                  'NEVER': "Не повторювати",
                  'SIMPLE': this.props.getTranslate('accrualOptionsType-Simple'),
                  'RECURRING': this.props.getTranslate('accrualOptionsType-Recurring'),
                  'BIRTHDAY': this.props.getTranslate('accrualOptionsType-Birthday')
                }}
              itemIDName={'accrualID'}
              itemEnableName={'enabled'}
              enabledMobileContent={true}
              mobileContentType={'block'}
              mobileContentHeader={[
                this.props.getTranslate('accrual-TableName'),
                this.props.getTranslate('accrual-TableType'),
                this.props.getTranslate('accrual-TableUsers'),
                this.props.getTranslate('accrual-TableImplementation'),
                this.props.getTranslate('accrual-TableAmount'),
              ]}
              mobileContentData={['name', 'bonusType', 'usersCount', 'recurringType', 'bonus']}
              removeIndex={this.state.removeIndex}
            />
          </div>
        } else if(this.state.activeTab === 'query') {
          resultBenefits = <div className="account-wgt account-wgt__events"><MessagesListBenefits
            messagesData={this.state.messages}
            messagesType={{
                'MESSAGE': {
                  icon: '#messages-wgt-message',
                  tooltip: this.props.getTranslate('messages-TooltipMessage')
                },
                'NOMINATION': {
                  icon: '#messages-wgt-money',
                  tooltip: this.props.getTranslate('messages-TooltipNomination')
                }
            }}
            handlerOpenMessage={this.handlerMessage.bind(this)}
            handlerOpenMessageModal={this.handlerMessageModal.bind(this)}
            openedMessage={this.state.openedMessage}
            answerBtnTxt={this.props.getTranslate('messages-AnswerButton')}
            answerHandler={this.answerHandler.bind(this)}
            handlerDepositUser={this.handlerDepositUser.bind(this)}
          /></div>
        } else if(this.state.activeTab === 'benefit'){
          resultBenefits =  <div className="account-wgt account-wgt__events">
          <div className="history-balance-wrap history-balance-wrap__benefits">
            <div className="benefits-nav-wrapper">
              <div className="benefits-nav-wrapper__inner">
              <div className="benefits-nav-wrapper__nav">
                <div className="benefits-nav-wrapper__nav-item">
                  <Button
                    type="button"
                    btnTheme="secondary"
                    text={this.state.chooseDateFull ? this.state.chooseDateFull : this.props.getTranslate('btnDate-AllTime')}
                    handler={this.handlerGetDate.bind(this)}
                    reset={this.state.chooseDateFull ? this.handlerResetDate.bind(this) : false}
                  />

                  {this.state.dateModal &&
                    <div className="date-modal date-modal-dropdown">
                    <div className="date-modal__content">
                      <div className="date-modal__header">
                        <div className="date-modal__headline">{this.props.getTranslate('accruals-Date')}</div>
                        <button className="date-modal__close-modal" type="button" onClick={this.handlerCloseGetDate.bind(this)}>
                          <svg className="date-modal__close-modal-icon">
                            <use href={`${sprite}#close-icon`}></use>
                          </svg>
                        </button>
                      </div>
                      <div className="date-fields date-fields__simple">
                        <div className={`date-field ${this.state.chooseDateType === 'start' ? 'date-field__border' : ''}`} onClick={this.chooseDateHandler.bind(this, 'start')}>
                          <input className="date-field__date" type="text" maxLength="10" placeHolder={this.props.getTranslate('dateModal-FormatDate')} value={this.state.finishStartDate ? this.state.finishStartDate.replace(/-/g, '.') : ''} onKeyPress={this.onKeyPress.bind(this)} onChange={this.handlerDateStart.bind(this)} />
                        </div>
                        <div className={`date-field ${this.state.chooseDateType === 'finish' ? 'date-field__border' : ''}`} onClick={this.chooseDateHandler.bind(this, 'finish')}>
                          <input className="date-field__date" type="text" maxLength="10" placeHolder={this.props.getTranslate('dateModal-FormatDate')} value={this.state.dateEndValue ? this.state.dateEndValue : (this.state.finishEndDate ? this.state.finishEndDate.replace(/-/g, '.') : '')} onKeyPress={this.onKeyPress.bind(this)} onChange={this.handlerDateEnd.bind(this)} />
                        </div>
                      </div>
                      <div id="calendar" className="calendar">
                        <div className="calendar__info">
                          <button className="calendar__month-nav" type="button" onClick={this.handlerPrevMonth.bind(this)}>
                            <svg className="calendar__month-nav-icon">
                              <use href={`${sprite}#chevron-left`}></use>
                            </svg>
                          </button>
                          <div className="calendar__month-year">{this.getMonthName(this.state.date.getMonth())}, {this.state.date.getFullYear()}</div>
                          <button className="calendar__month-nav" type="button" onClick={this.handlerNextMonth.bind(this)}>
                            <svg className="calendar__month-nav-icon">
                              <use href={`${sprite}#chevron-right`}></use>
                            </svg>
                          </button>
                        </div>
                        <table className="calendar-table">
                          <thead className="calendar-table__head">
                            <tr>
                              <th>{this.props.getTranslate('modal-calendarDayWeekMonday')}</th>
                              <th>{this.props.getTranslate('modal-calendarDayWeekTuesday')}</th>
                              <th>{this.props.getTranslate('modal-calendarDayWeekWednesday')}</th>
                              <th>{this.props.getTranslate('modal-calendarDayWeekThursday')}</th>
                              <th>{this.props.getTranslate('modal-calendarDayWeekFriday')}</th>
                              <th>{this.props.getTranslate('modal-calendarDayWeekSutarday')}</th>
                              <th>{this.props.getTranslate('modal-calendarDayWeekSunday')}</th>
                            </tr>
                          </thead>
                          <tbody className="calendar-table__dates">{tableDate}</tbody>
                        </table>
                      </div>
                      <div className="date-modal__nav">
                        <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="button" onClick={this.setDateInput.bind(this)}>{this.props.getTranslate('modal-calendarAddBtn')}</button>
                      </div>
                      </div>
                  </div>}

                </div>
                <div className="benefits-nav-wrapper__nav-item">
                  {/* Filter component */}
                  <Filter
                    className="control-wgt_align_left"
                    buttonName={this.props.getTranslate('ordersFilter-Title')}
                    dataSort={this.state.historyTypes}
                    handler={this.handlerGetHistoryType.bind(this)}
                    iconItemFalse={true}
                  />
                </div>
              </div>
              {/* Filter search input component */}
              <FilterSearch
                placeholder={this.props.getTranslate('SearchHistory-Pleceholder')}
                value={this.state.searchValue}
                handler={this.handlerSearch.bind(this)}
              />
              </div>
            </div>
            <table className="data-table">
              <thead>
                <tr>
                  <th>{this.props.getTranslate('history-TableDate')}</th>
                  <th>{this.props.getTranslate('history-TableTransaction')}</th>
                  <th>{this.props.getTranslate('history-TableUser')}</th>
                  <th>{this.props.getTranslate('history-TableUserWhom')}</th>
                  <th>{this.props.getTranslate('history-TableType')}</th>
                </tr>
              </thead>
              <tbody>
          {this.state.histories.map((item, index) => {
            let status = '';
            if(item.status === "SUCCESS"){
              status = <LabelStatus name="Нараховано" theme="green" />;
            } else {
              status = <LabelStatus name="Відхилено" theme="red" />;
            }
            let coinIcon = <svg className="history-balance__icon">
                              <use href={`${sprite}#coin-icon`}></use>
                          </svg>;
            if(item.bonusType === 'SUPER_COIN'){
              coinIcon = <svg className="history-balance__icon">
                                <use href={`${sprite}#super-coin-icon`}></use>
                            </svg>;
            }
            return <tr key={index}>
              <td><DateFormating start={item.accrualTime} /></td>
              <td className="history-balance__balance"><div className="history-balance__icon-wrap">{coinIcon}{String(item.transactionAmount).indexOf('-') !== -1 ? <span className="history-balance__balance-minus">{item.transactionAmount}{item.bonusType === 'COIN' ? (this.props.currency ? ' ' + this.props.currency : '') : 'super coin'}</span> : <span className="history-balance__balance-plus">{item.transactionAmount}{item.bonusType === 'COIN' ? (this.props.currency ? ' ' + this.props.currency : '') : 'super coin'}</span>}</div></td>
              <td>{item.createdUserName}</td>
              <td>{item.userName ? item.userName : item.usersCount}</td>
              <td>{status}</td>
            </tr>})}
        </tbody>
      </table>
      </div>
      </div>
        } else if(this.state.activeTab === 'transaction'){
          resultBenefits =  <div className="account-wgt account-wgt__events">
          <div className="history-balance-wrap">
            <div className="benefits-nav-wrapper">
              <div className="benefits-nav-wrapper__inner">
              <div className="benefits-nav-wrapper__nav">
                <div className="benefits-nav-wrapper__nav-item">
                  <Button
                    type="button"
                    btnTheme="secondary"
                    text={this.state.chooseDateFull ? this.state.chooseDateFull : this.props.getTranslate('btnDate-AllTime')}
                    handler={this.handlerGetDate.bind(this)}
                    reset={this.state.chooseDateFull ? this.handlerResetDate.bind(this) : false}
                  />

                  {this.state.dateModal &&
                    <div className="date-modal date-modal-dropdown">
                    <div className="date-modal__content">
                      <div className="date-modal__header">
                        <div className="date-modal__headline">{this.props.getTranslate('accruals-Date')}</div>
                        <button className="date-modal__close-modal" type="button" onClick={this.handlerCloseGetDate.bind(this)}>
                          <svg className="date-modal__close-modal-icon">
                            <use href={`${sprite}#close-icon`}></use>
                          </svg>
                        </button>
                      </div>
                      <div className="date-fields date-fields__simple">
                        <div className={`date-field ${this.state.chooseDateType === 'start' ? 'date-field__border' : ''}`} onClick={this.chooseDateHandler.bind(this, 'start')}>
                          <input className="date-field__date" type="text" maxLength="10" placeHolder={this.props.getTranslate('dateModal-FormatDate')} value={this.state.finishStartDate ? this.state.finishStartDate.replace(/-/g, '.') : ''} onKeyPress={this.onKeyPress.bind(this)} onChange={this.handlerDateStart.bind(this)} />
                        </div>
                        <div className={`date-field ${this.state.chooseDateType === 'finish' ? 'date-field__border' : ''}`} onClick={this.chooseDateHandler.bind(this, 'finish')}>
                          <input className="date-field__date" type="text" maxLength="10" placeHolder={this.props.getTranslate('dateModal-FormatDate')} value={this.state.dateEndValue ? this.state.dateEndValue : (this.state.finishEndDate ? this.state.finishEndDate.replace(/-/g, '.') : '')} onKeyPress={this.onKeyPress.bind(this)} onChange={this.handlerDateEnd.bind(this)} />
                        </div>
                      </div>
                      <div id="calendar" className="calendar">
                        <div className="calendar__info">
                          <button className="calendar__month-nav" type="button" onClick={this.handlerPrevMonth.bind(this)}>
                            <svg className="calendar__month-nav-icon">
                              <use href={`${sprite}#chevron-left`}></use>
                            </svg>
                          </button>
                          <div className="calendar__month-year">{this.getMonthName(this.state.date.getMonth())}, {this.state.date.getFullYear()}</div>
                          <button className="calendar__month-nav" type="button" onClick={this.handlerNextMonth.bind(this)}>
                            <svg className="calendar__month-nav-icon">
                              <use href={`${sprite}#chevron-right`}></use>
                            </svg>
                          </button>
                        </div>
                        <table className="calendar-table">
                          <thead className="calendar-table__head">
                            <tr>
                              <th>{this.props.getTranslate('modal-calendarDayWeekMonday')}</th>
                              <th>{this.props.getTranslate('modal-calendarDayWeekTuesday')}</th>
                              <th>{this.props.getTranslate('modal-calendarDayWeekWednesday')}</th>
                              <th>{this.props.getTranslate('modal-calendarDayWeekThursday')}</th>
                              <th>{this.props.getTranslate('modal-calendarDayWeekFriday')}</th>
                              <th>{this.props.getTranslate('modal-calendarDayWeekSutarday')}</th>
                              <th>{this.props.getTranslate('modal-calendarDayWeekSunday')}</th>
                            </tr>
                          </thead>
                          <tbody className="calendar-table__dates">{tableDate}</tbody>
                        </table>
                      </div>
                      <div className="date-modal__nav">
                        <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="button" onClick={this.setDateInput.bind(this)}>{this.props.getTranslate('modal-calendarAddBtn')}</button>
                      </div>
                      </div>
                  </div>}

                </div>
                <div className="benefits-nav-wrapper__nav-item">
                  {/* Filter component */}
                  <Filter
                    openQuery={this.handlerGetTransactionTypes.bind(this)}
                    className="control-wgt_align_left"
                    buttonName={this.props.getTranslate('ordersFilter-Title')}
                    dataSort={this.state.transactionTypes}
                    handler={this.handlerGetTransactionsType.bind(this)}
                    iconItemFalse={true}
                  />
                </div>
              </div>
              {/* Filter search input component */}
              <FilterSearch
                placeholder={this.props.getTranslate('SearchTransaction-Pleceholder')}
                value={this.state.searchValue}
                handler={this.handlerSearch.bind(this)}
              />
              </div>
            </div>
            <table className="data-table">
              <thead>
                <tr>
                  <th>{this.props.getTranslate('transaction-TableDate')}</th>
                  <th>{this.props.getTranslate('transaction-TableTransaction')}</th>
                  <th>{this.props.getTranslate('transaction-TableUser')}</th>
                  <th>{this.props.getTranslate('transaction-TableType')}</th>
                </tr>
              </thead>
              <tbody>
          {this.state.transactions.map((item, index) => {
            let typeInfo = '';
            if(item.transactionType === 'CORRECTION'){
              typeInfo = <span className="history-balance__balance-edit">{this.props.getTranslate('deposits-EditFromAdministrator')}</span>;
            } else if(item.transactionType === 'ORDER') {
              typeInfo = 'Замовлення №' + item.description;
              if(item.notes === 'COMPANY_SHOP'){
                typeInfo = typeInfo + ' каталог компанії ';
              } else if(item.notes === 'CUSTOM_SHOP') {
                typeInfo = typeInfo + ' з зовнішнього магазину';
              } else {
                typeInfo = item.description;
              }
            } else {
              typeInfo = item.description;
            }
            let coinIcon = <svg className="history-balance__icon">
                              <use href={`${sprite}#coin-icon`}></use>
                          </svg>;
            if(item.bonusType === 'SUPER_COIN'){
              coinIcon = <svg className="history-balance__icon">
                                <use href={`${sprite}#super-coin-icon`}></use>
                            </svg>;
            }
            return <tr key={index}>
              <td><DateFormating start={item.creationDate} /></td>
              <td className="history-balance__balance"><div className="history-balance__icon-wrap">{coinIcon}{String(item.transactionAmount).indexOf('-') !== -1 ? <span className="history-balance__balance-minus">{item.transactionAmount}{this.props.currency ? ' ' + this.props.currency : ''}</span> : <span className="history-balance__balance-plus">{item.transactionAmount}{this.props.currency ? ' ' + this.props.currency : ''}</span>}</div></td>
              <td>{item.createdBy}</td>
              <td>{typeInfo}</td>
            </tr>})}
        </tbody>
      </table>
      </div>
      </div>
        }

      }

    return <>
    {/* Error handler */}
    <ResultErrors
      errors={this.state.errors}
      closeHandler={this.handlerCloseError.bind(this)}
    />

    {/* Query result handler */}
    {this.state.successAddUser && <ResultQuery
      success={this.state.successAddUser}
      resultText={this.state.successText}
      handlerSuccess={this.handlerCloseSuccess}
      handlerError={this.handlerCloseError}
    />}

    {/* Header component */}
    <AccountHeader
      queryLink={this.props.queryLink}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      getTranslate={this.props.getTranslate.bind(this)}
      completed={this.props.completed}
    />

    <div className="account-wrap">

    {/* Navigation component */}
    <AccountNav
      getPermalink={this.props.getPermalink}
      queryLink={this.props.queryLink}
      configsApp={this.props.configsApp}
      newReview={this.props.newReview}
      companyName={this.props.companyName}
      token={this.props.token()}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      dropdownStatus={this.state.dropdownStatus}
      dropdownHandler={this.handlerDropdown}
      getTranslate={this.props.getTranslate}
      completed={this.props.completed}
    />

    <div className="account-content">
      <div className="account-content__inner">

        {/* Account header component */}
        <AccountContentHeader
          title={this.props.getTranslate('accrual-Title')}
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
        />

        <div className="team-nav team-nav_space_between">

          {/* Button for add benefits */}
          <Button
            type="link"
            text={this.props.getTranslate('accrual-AddButton')}
            addClass="header-content-nav__btn"
            handler={this.props.getPermalink("/account/accrual/create")}
          />

        </div>

              <div className="account-content__content">
                <div className="account-wgt account-wgt__events">
                  {this.state.dashboardInfo && this.state.isLoaded ? <div className="benefits-statistics">
                    <div className="benefits-statistics__inner">
                      <div className="benefits-statistics-wgt">
                        <div className="benefits-statistics-wgt__inner">
                          <div className="benefits-statistics-wgt__header">
                            <div className="benefits-statistics-wgt__headline">Coin’s {this.props.getTranslate('accrualDashboard-CountCurrencyTitle')}</div>
                            <button className="benefits-statistics-wgt__setting-link" type="button" onClick={this.handlerEditCurrency.bind(this)}>
                              <svg className="benefits-statistics-wgt__setting-link-icon">
                                <use href={`${sprite}#benefits-settings-icon`}></use>
                              </svg>
                            </button>
                          </div>
                                                  <div className="benefits-statistics-wgt__content">
                          <div className="benefits-statistics-wgt__content-value-wrap">
                              <svg className="benefits-statistics-wgt__content-icon">
                                <use href={`${sprite}#coin-icon`}></use>
                              </svg>
                            {this.state.dashboardInfo.bonuses ? <div className="benefits-statistics-wgt__content-value">{this.props.number_format(this.state.dashboardInfo.bonuses, 0, '', ' ')}</div> : ''}
                          </div>
                        </div>
                        </div>
                      </div>
                      <div className="benefits-statistics-wgt">
                        <div className="benefits-statistics-wgt__inner">
                          <div className="benefits-statistics-wgt__header">
                            <div className="benefits-statistics-wgt__headline">Super Coin’s {this.props.getTranslate('accrualDashboard-CountSuperCurrencyTitle')}</div>
                            <button className="benefits-statistics-wgt__setting-link" type="button" onClick={this.handlerEditSuperCurrency.bind(this)}>
                              <svg className="benefits-statistics-wgt__setting-link-icon">
                                <use href={`${sprite}#benefits-settings-icon`}></use>
                              </svg>
                            </button>
                          </div>
                                                  <div className="benefits-statistics-wgt__content">
                          <div className={`benefits-statistics-wgt__content-value-wrap${!this.state.labelStatus ? ' benefits-statistics-wgt__content-value-wrap_disabled' : ''}`}>
                              <svg className="benefits-statistics-wgt__content-icon">
                                <use href={`${sprite}#super-coin-icon`}></use>
                              </svg>
                            <div className="benefits-statistics-wgt__content-value benefits-statistics-wgt__content-value_theme_yellow">{this.props.number_format(this.state.dashboardInfo.superBonuses, 0, '', ' ')}{!this.state.labelStatus ? <span className="super-coin-disabled">Не активно</span> : ''}</div>
                          </div>
                          <div className="benefits-statistics-wgt__content-headline">{this.props.getTranslate('accrualDashboard-AutoCmbustion')} 22.05.04</div>
                        </div>
                        </div>
                      </div>
                      <div className="benefits-statistics-wgt">
                        <div className="benefits-statistics-wgt__inner">
                          <div className="benefits-statistics-wgt__header">
                            <div className="benefits-statistics-wgt__headline">{this.props.getTranslate('accrualDashboard-ForToday')}</div>
                          </div>
                                                  <div className="benefits-statistics-wgt__content">
                          <div className="benefits-statistics-wgt__content-value-wrap">
                            <div className="benefits-statistics-wgt__content-value">{this.props.number_format(this.state.dashboardInfo.todaySpending, 0, '', ' ')}</div>
                          </div>
                        </div>
                        </div>
                      </div>
                      <div className="benefits-statistics-wgt">
                        <div className="benefits-statistics-wgt__inner">
                          <div className="benefits-statistics-wgt__header">
                            <div className="benefits-statistics-wgt__headline">{this.props.getTranslate('accrualDashboard-ForMonth')}</div>
                          </div>
                                                  <div className="benefits-statistics-wgt__content">
                          <div className="benefits-statistics-wgt__content-value-wrap">
                            <div className="benefits-statistics-wgt__content-value">{this.props.number_format(this.state.dashboardInfo.monthlySpending, 0, '', ' ')}</div>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div> : ''}

                </div>
        {/* Tabs component */}
        <TabItems items={[
          {
            label: this.props.getTranslate('tabItems-AutoAccrual'),
            handle: this.handleTabs.bind(this),
            nameTab: "auto"
          },
          {
            label: this.props.getTranslate('tabItems-OfferAccrual'),
            handle: this.handleGetOfferBenefits.bind(this),
            nameTab: "query"
          },
          {
            label: this.props.getTranslate('tabItems-HistoriesAccrual'),
            handle: this.handleGetHistoriesBenefits.bind(this),
            nameTab: "benefit"
          },
          {
            label: this.props.getTranslate('tabItems-TransactionAccrual'),
            handle: this.handleGetTransactionBenefits.bind(this),
            nameTab: "transaction"
          }
        ]}
        activeHandle={this.state.activeTab}
        />
              {/* Query result */}
              {resultBenefits}

            </div>
            <div className="account-content__footer">

              {/* Pagination component */}
              <Pagination
                total={this.state.totalPages}
                perPage="20"
                path={this.state.paginationPath}
                currentPage={this.state.currentPage}
                items={this.handlerPaginationItems}
              />

              </div>
            <div>
          </div>
        </div>
      </div>
    </div>

    {/*  Modal Edit Category */}
    {this.state.modalEditCurrency ? <Modal
      title={this.props.getTranslate('accrualModal-CurrencyTitle')}
      content={<div className="field-wrap">
                  <label className="field-wrap__label" htmlFor="name-currency">{this.props.getTranslate('accrualModal-CurrencyName')}</label>
                  <input id="name-currency" className={`field-wrap__input`} type="text" value={this.state.companyData.currency} onChange={this.handleNameCoin.bind(this)} placeholder={this.props.getTranslate('accrualModal-CurrencyNamePlaceholder')} />
                </div>}
      type={'formSubmit'}
      buttonSubmitText={this.props.getTranslate('accrualModal-SaveButton')}
      submitHandler={this.handlerEditCurrencySubmit.bind(this)}
      submitLoading={this.state.isLoadingSubmit}
      handlerClose={this.handlerCloseEditModal.bind(this)}
      submitStatus={this.state.modalSubmitStatus}
    /> : ''}

    {/*  Modal Edit Category */}
    {this.state.modalEditSuperCurrency ? <Modal
      title={this.props.getTranslate('accrualModal-SupperCurrencyTitle')}
      content={
        <><div className="field-wrap">
                  <label className="field-wrap__label" htmlFor="status-currency">{this.props.getTranslate('accrualModal-SupperCurrencyStatus')}</label>
        <Select
          styles={customStyles}
          options={this.state.statusModal}
          placeholder={this.props.getTranslate('accrualModal-SupperCurrencyStatusPlaceholder')}
          value={this.state.superCoinInfo.superCoin ? this.state.statusModal[0] : this.state.statusModal[1]}
          onChange={this.handleStatusSuperCoin.bind(this)}
        />
        </div>
        <div className="field-wrap">
                  <label className="field-wrap__label" htmlFor="name-super-currency">{this.props.getTranslate('accrualModal-SuperCurrencyName')}</label>
                  <input id="name-super-currency" className={`field-wrap__input`} value={this.state.superCurrencyData.name} onChange={this.handleNameSuperCoin.bind(this)} type="text" placeholder="Введіть назву" />
                </div>
                <div className="field-wrap">
                          <label className="field-wrap__label" htmlFor="status-currency">{this.props.getTranslate('accrualModal-AutoCombustion')}</label>
                <Select
                  styles={customStyles}
                  options={this.state.autoResetModal}
                  value={this.state.autoResetModal ? this.state.autoResetModal[this.state.autoResetModal.findIndex(el => el.value === this.state.superCurrencyData.recurringType)] : this.state.autoResetModal[0]}
                  placeholder={this.props.getTranslate('accrualModal-TimeAutoCombustionPlaceholder')}
                  onChange={this.handleAutoResetSuperCoin.bind(this)}
                  isLoading={this.state.isLoading}
                />
                </div>
                </>}
      type={'formSubmit'}
      buttonSubmitText={this.props.getTranslate('accrualModal-SaveButton')}
      submitHandler={this.handlerEditSuperCurrencySubmit.bind(this)}
      submitLoading={this.state.isLoadingSubmit}
      handlerClose={this.handlerCloseEditModal.bind(this)}
      submitStatus={this.state.modalSubmitStatus}
    /> : ''}

    {/* Delete item modal */}
    <DeleteModal
      modalStatus={this.state.modalDeleteBenefit}
      modalDisabledStatus={this.state.modalDisabled}
      modalDisabledClass={this.state.addClass}
      handlerModalDisabled={this.handleModalDisabled}
      modalTitle={this.props.getTranslate('modal-deleteAdsTitle')}
      modalDescription={this.props.getTranslate('modal-deleteAdsDescription')}
      modalHandlerDelete={this.handlerDeleteBenefit.bind(this, this.state.idDeleteBenefit)}
      modalHandlerBtnTxt={this.props.getTranslate('removeModal-ButtonSubmit')}
      modalHandlerBtnLoading={this.state.loadingDeleteSumbit}
    />

    </>;
  }
}
export default Benefits;
