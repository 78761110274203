import React from "react";
import DemoModal from '../../components/DemoModal';
import Button from '../Button';
import home_blue_bg from '../../media/home-blue-bg.jpeg';
import mate_logo from '../../media/mate-logo.svg';
class HomeAddCompany extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <section className="home-add-company">
      <div className="wrapper">
        <div className="home-add-company__inner" style={{ backgroundImage: `url(${home_blue_bg})` }}>
          <div className="home-add-company__content">
            <div className="home-add-company__logo-wrap">
              <img className="home-add-company__logo" src={mate_logo} alt="" />
            </div>
            <div className="home-add-company__headline-wrap">
              <h2 className="home-add-company__headline">{this.props.getTranslate('homePossibilities-AddCompanyTitle')}</h2>
            </div>
            <div className="home-add-company__nav">
              <div className="home-add-company__nav-col">
                <Button className="home-add-company__nav-btn home-add-company__nav-btn_theme_white" type="link" text={this.props.getTranslate('index-RegCompanyButton')} btnWidth="full" handler="/registration" />
              </div>
              <div className="home-add-company__nav-col">
                <DemoModal
                  openBtnClass="btn btn_width_100 home-add-company__nav-btn home-add-company__nav-btn_theme_border"
                  getTranslate={this.props.getTranslate}
                  queryLink={this.props.queryLink}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  }
}
export default HomeAddCompany;
