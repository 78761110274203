import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'; // Путь к вашему компоненту
import reportWebVitals from './reportWebVitals';
ReactDOM.hydrate(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
