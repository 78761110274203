import React from "react";
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import AccountContentHeader from './components/AccountContentHeader';
import { ChromePicker } from 'react-color';
import Loading from './components/Loading';
import ResultErrors from './components/ResultErrors';
import Select from 'react-select';
import Cropper from 'react-easy-crop';
import getCroppedImg from './utils/cropImage';
import sprite from './media/icons.svg';
import avatar from './media/avatar_2.png';
import coverSplash from './media/cover-tooltip.png';
import cardImg from './media/card-tooltip.png';
import iconImg from './media/icon-tooltip.png';
import colorImg from './media/color-tooltip.png';
import logoImgTooltip from './media/logo-tooltip.png';
import matespaceLogo from './media/matespace-logo.png';
import { getAuth } from "firebase/auth";
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
class AccountCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: this.props.token(),
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      splashImg: false,
      appImg: false,
      companyData: [],
      error: null,
      isLoaded: false,
      modalInfo: false,
      modalCurrency: false,
      modalColor: false,
      companyName: this.props.companyName,
      companyPhone: '',
      companyEmail: '',
      companyNameCurrency: '',
      successSubmit: false,
      errorSubmit: null,
      splashIconImg: false,
      logoIconImg: false,
      cardImg: false,
      iconImg: false,
      colorImg: false,
      colorValue: '#364ed4',
      logoImg: null,
      isLoadingPhoto: false,
      mainPhotoModal: false,
      modalEditTab: false,
      cardPhotoModal: false,
      iconPhotoModal: false,
      colorTxt: '#000',
      mainImgs: [],
      cardImgs: [],
      iconImgs: [],
      mainImgObj: null,
      cardImgObj: null,
      iconImgObj: null,
      previewImg: null,
      isLoadedSubmitImage: false,
      isLoadingPhotoRemove: null,
      defaultImg: {},
      displayColorPicker: false,
      isLoadingSubmitColor: false,
      downImage: null,
      indexImg: null,
      companyNameCurrencyLength: false,
      errors: null,
      modalStatus: false,
      statusAccount: null,
      isLoadedSubmitStatus: false,
      imageSrc: null,
      crop: { x: 0, y: 0 },
      zoom: 2,
      aspect: 4 / 2,
      rotation: 0,
      croppedAreaPixels: null,
      croppedImage: null,
      mainImgName: '',
      cropImageStatus: false,
      errorFields: false,
      companyNameLength: false,
      errorOnlySpecial: false,
      errorOnlySpecialCurrency: false,
      successText: null,
      errorFormatLogo: false,
      errorSizeImgs: false,
      filesSubmit: null,
      modalTimeZone: false,
      timeZoneOptions: [],
      modalLocale: false,
      localeOptions: []
    }
  }

  onCropChange = (crop) => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(!document.querySelector('.modal-image-edit__main-preview-inner')) {
        document.querySelector('.reactEasyCrop_CropArea').insertAdjacentHTML("beforeend", '<div class="modal-image-edit__main-preview-inner"><div class="modal-image-edit__main-preview-name" style="color:' + this.state.colorTxt  + '">Peter Parker<img src="' + avatar + '" alt="" /></div><div class="modal-image-edit__main-preview-value" style="color:' + this.state.colorTxt  + '">Middle Front-End Developer</div><div class="modal-image-edit__main-preview-value" style="color:' + this.state.colorTxt  + '">Front-End Team</div><div class="modal-image-edit__main-preview-balance" style="color:' + this.state.colorTxt  + '">999</div></div>');
      }
      this.setState({ crop: crop, cropImageStatus: true })
    }
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels: croppedAreaPixels })
  }

  onZoomChange = (zoom) => {
    this.setState({ zoom: zoom })
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };
  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };
  handleChange = (color) => {
    this.setState({ colorValue: color.hex })
  };
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handleModalDisabled = () => {
    document.querySelector('body').style.overflow = "";
    this.setState(prevState => ({
      modalDisabled: true
    }));
    if(this.state.defaultImg){
      this.setState({
        defaultImg: {}
      });
    }
    setTimeout(() => {
      this.setState(prevState => ({
        modalInfo: false,
        modalDisabled: false,
        modalCurrency: false,
        modalColor: false,
        mainPhotoModal: false,
        cardPhotoModal: false,
        iconPhotoModal: false,
        modalEditTab: false,
        previewImg: null,
        imageSrc: null,
        indexImg: null,
        modalStatus: false,
        crop: { x: 0, y: 0 },
        zoom: 2,
        aspect: 4 / 2,
        rotation: 0,
        croppedAreaPixels: null,
        croppedImage: null,
        mainImgName: '',
        cropImageStatus: false,
        filesSubmit: null,
        modalTimeZone: false,
        modalLocale: false
      }));
    }, 500);
  }
  handlerSplashIconImg(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      const element = document.querySelector('.account-item-splash__icon-inner-wrap');
      const elementRect = element.getBoundingClientRect();
      const down = Math.max(0, elementRect.bottom - window.innerHeight);
      this.setState(prevState => ({
        splashIconImg: !prevState.splashIconImg,
        cardImg: false,
        iconImg: false,
        downImage: down
      }));
    }
  }
  handlerCardIconImg(){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      const element = document.querySelector('.account-item-splash__icon-inner-wrap');
      const elementRect = element.getBoundingClientRect();
      const down = Math.max(0, elementRect.bottom - window.innerHeight);
      this.setState(prevState => ({
        cardImg: !prevState.cardImg,
        splashIconImg: false,
        iconImg: false,
        downImage: down
      }));
    }
  }
  handlerIconImg(){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      const element = document.querySelector('.account-item-splash__icon-inner-wrap');
      const elementRect = element.getBoundingClientRect();
      const down = Math.max(0, elementRect.bottom - window.innerHeight);
      this.setState(prevState => ({
        iconImg: !prevState.iconImg,
        cardImg: false,
        splashIconImg: false,
        downImage: down
      }));
    }
  }
  handlerCardIconImg(){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      const element = document.querySelector('.account-item-splash__icon-inner-wrap');
      const elementRect = element.getBoundingClientRect();
      const down = Math.max(0, elementRect.bottom - window.innerHeight);
      this.setState(prevState => ({
        cardImg: !prevState.cardImg,
        splashIconImg: false,
        iconImg: false,
        downImage: down
      }));
    }
  }
  disabledAllTooltip(e){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(!e.target.closest('use')) {
        this.setState(prevState => ({
          iconImg: false,
          cardImg: false,
          splashIconImg: false,
          colorImg: false
        }));
      }
    }
  }
  handlerSplashImg () {
    this.setState(prevState => ({
      splashImg: !prevState.splashImg
    }));
  }
  handlerLogoImg () {
    this.setState(prevState => ({
      logoIconImg: !prevState.logoIconImg
    }));
  }
  handlerCardImg () {
    this.setState(prevState => ({
      cardImg: !prevState.cardImg
    }));
  }
  handlerAppImg () {
    this.setState(prevState => ({
      appImg: !prevState.appImg
    }));
  }
  handlerColorImg () {
    this.setState(prevState => ({
      colorImg: !prevState.colorImg
    }));
  }
  handlerModalStatus () {
    this.setState(prevState => ({
      modalStatus: true
    }));
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/companies`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if (res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            error: true
          })
        });
      } else {
        res.json().then(function(data) {
          let index;
          let indexMain;
          let indexCard;
          let indexIcon;
          if(data.photos){
            index = data.photos.findIndex(el => el.type === 'LOGO');
            indexMain = data.photos.findIndex(el => el.type === 'MAIN');
            indexCard = data.photos.findIndex(el => el.type === 'CARD');
            indexIcon = data.photos.findIndex(el => el.type === 'ICON');
          }
          that.setState({
            isLoaded: true,
            companyData: data,
            statusAccount: data.status === 'ACTIVE' ? {label: 'Активний', value: 'ACTIVE'} : {label: 'Не активний', value: 'DEACTIVATED'} ,
            logoImg: index !== -1 ? data.photos[index] : null,
            mainImgObj: indexMain !== -1 ? data.photos[indexMain] : null,
            cardImgObj: indexCard !== -1 ? data.photos[indexCard] : null,
            iconImgObj: indexIcon !== -1 ? data.photos[indexIcon] : null,
            colorValue: data.color ? data.color : '#364ed4',
            colorTxt: data.cardTextColor ? data.cardTextColor : '#000'
          });

      fetch(`${that.props.queryLink}/api/v1/companies/photos/defaults?type=MAIN`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              mainImgs: data
            });
          });
          fetch(`${that.props.queryLink}/api/v1/companies/photos/defaults?type=CARD`, {
              method: 'GET',
              headers: myHeaders
            }).then(res => {
              if (res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors
                  })
                });
              } else {
                res.json().then(function(data) {
                  that.setState({
                    cardImgs: data
                  });
                });
                fetch(`${that.props.queryLink}/api/v1/companies/photos/defaults?type=ICON`, {
                  method: 'GET',
                  headers: myHeaders
                }).then(res => {
                  if (res.status !== 200) {
                    res.json().then(function(data) {
                      that.setState({
                        errors: data.errors
                      })
                    });
                  } else {
                    res.json().then(function(data) {
                      that.setState({
                        iconImgs: data
                      });
                    });
                  }
                })
              }
            })
        }
      })

        });
      }
    })
  })
  }
  handlerModalInfo() {
    this.setState(prevState => ({
      modalInfo: !prevState.modalInfo,
      companyName: prevState.companyData.name,
      companyPhone: prevState.companyData.phone,
      companyEmail: prevState.companyData.email,
    }));
  }
  handlerCompanyName(event){
    if(event.target.value.length <= 30){
      this.setState({
        companyName: event.target.value,
        companyNameLength: false
      });
    } else {
      this.setState({
        companyNameLength: true
      });
    }
  }
  handlerCompanyPhone(event){
    this.setState({
      companyPhone: event.target.value
    });
  }
  handlerCompanyEmail(event){
    this.setState({
      companyEmail: event.target.value
    });
  }
  handlerCompanyInfoSubmit(event){
    event.preventDefault();
    let that = this;
    let error = false;
    this.setState({
      modalEditLoading: true,
      errorFields: false,
      errorOnlySpecial: false
    });
    const companyNameWithoutSpaces = this.state.companyName.trim();
    const isValidCompanyName = /[a-zA-Z0-9а-яА-Я\s]/.test(companyNameWithoutSpaces);
    if(companyNameWithoutSpaces === '' || !isValidCompanyName){
      error = true;
    }
    if(!isValidCompanyName){
      this.setState({
        errorOnlySpecial: true,
        errorFields: false
      });
    }
    if(!error){
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/companies`, {
          method: 'PUT',
          headers: myHeaders,
          body: JSON.stringify({
            "companyID": that.state.companyData.companyID,
            "email": that.state.companyEmail,
            "name": that.state.companyName,
            "phone": that.state.companyPhone,
            "status": that.state.companyData.status,
            "currency": that.state.companyData.currency,
            "sign": that.state.companyData.sign,
            "description": that.state.companyData.description,
            "photos": that.state.companyData.photos
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                modalEditLoading: false,
                errors: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              that.props.updateCompany();
              that.setState(prevState => ({
                modalEditLoading: false,
                companyData: { ...prevState.companyData, email: prevState.companyEmail, name: prevState.companyName, phone: prevState.companyPhone },
                successSubmit: true,
                successText: that.props.getTranslate('successStatus-Save')
              }));
              setTimeout(() => {
                that.setState({
                  successSubmit: false,
                  successText: null
                });
              }, 5000);
              that.handleModalDisabled();
            })
          }
        })
      })
    } else {
      this.setState({
        modalEditLoading: false,
        errorFields: true
      });
    }
  }
  handlerModalCurrency(){
    this.setState(prevState => ({
      modalCurrency: !prevState.modalCurrency,
      companyNameCurrency: prevState.companyData.currency
    }));
  }
  handlerModalTimeZone(){
    let that = this;
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/companies/config/timezones`, {
          method: 'GET',
          headers: myHeaders
        }).then(res => {
          if(res.status !== 200) {

          } else {
            res.json().then(function(data) {
              const transformedArray = data.map(item => ({
                  label: item.displayName,
                  value: item.timeZone
              }));
              that.setState(prevState => ({
                modalTimeZone: !prevState.modalTimeZone,
                timeZoneOptions: transformedArray
              }));
            })
          }
        })
      })

  }

  handlerModalLocale(){
    let that = this;
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/companies/config/locales`, {
          method: 'GET',
          headers: myHeaders
        }).then(res => {
          if(res.status !== 200) {

          } else {
            res.json().then(function(data) {
              console.log(data);
              const transformedArray = data.map(item => ({
                  label: item,
                  value: item
              }));
              that.setState(prevState => ({
                modalLocale: !prevState.modalLocale,
                localeOptions: transformedArray
              }));
            })
          }
        })
      }) 
  }

  handlerCompanyCurrency(event){
    if(event.target.value.length <= 5){
      this.setState({
        companyNameCurrency: event.target.value,
        companyNameCurrencyLength: false
      });
    } else {
      this.setState({
        companyNameCurrencyLength: true
      });
    }
  }
  handlerCompanyCurrencySubmit(event){
    event.preventDefault();
    let that = this;
    let error = false;
    this.setState({
      modalEditLoading: true,
      errorOnlySpecialCurrency: false
    });
    const currencyNameWithoutSpaces = this.state.companyNameCurrency.trim();
    const isValidCurrencyName = /[a-zA-Z0-9а-яА-Я\s]/.test(currencyNameWithoutSpaces);
    if(currencyNameWithoutSpaces === '' || !isValidCurrencyName){
      error = true;
    }
    if(!isValidCurrencyName){
      this.setState({
        errorOnlySpecialCurrency: true,
        errorFields: false
      });
    }
    if(that.state.companyNameCurrency === ''){
      error = true;
    }
    if(!error) {
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/companies`, {
          method: 'PUT',
          headers: myHeaders,
          body: JSON.stringify({
            "companyID": that.state.companyData.companyID,
            "email": that.state.companyData.email,
            "name": that.state.companyData.name,
            "phone": that.state.companyData.phone,
            "status": that.state.companyData.status,
            "currency": that.state.companyNameCurrency,
            "description": that.state.companyData.description,
            "photos": that.state.companyData.photos
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                modalEditLoading: false,
                errors: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              that.props.updateCompany();
              that.setState(prevState => ({
                modalEditLoading: false,
                companyData: { ...prevState.companyData, currency: prevState.companyNameCurrency },
                successSubmit: true,
                successText: that.props.getTranslate('successStatus-Save')
              }));
              setTimeout(() => {
                that.setState({
                  successSubmit: false,
                  successText: null
                });
              }, 5000);
              that.handleModalDisabled();
            })
          }
        })
      })
    } else {
      this.setState({
        modalEditLoading: false,
        errorFields: true
      });
    }
  }
  handlerModalColor(){
    if(this.state.companyData.color){
      this.setState(prevState => ({
        colorValue: prevState.companyData.color,
      }));
    }
    this.setState(prevState => ({
      modalColor: !prevState.modalColor
    }));
  }
  handlerMainPhotoModal(event) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/companies/photos/defaults?type=MAIN`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              mainImgs: data
            })
          });
          that.setState(prevState => ({
            mainPhotoModal: !prevState.mainPhotoModal
          }));
        }
      })
    })
  }
  handlerCardPhotoModal(event) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/companies/photos/defaults?type=CARD`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              cardImgs: data
            })
          });
          that.setState(prevState => ({
            cardPhotoModal: !prevState.cardPhotoModal
          }));
        }
      })
    })
  }
  handlerIconPhotoModal(event) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/companies/photos/defaults?type=ICON`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              iconImgs: data
            })
          });
          that.setState(prevState => ({
            iconPhotoModal: !prevState.iconPhotoModal
          }));
        }
      })
    })
  }
  handlerUploadLogoPhoto(event){
    let that = this;
    let files = event.target.files;
    let error = false;
    this.setState(prevState => ({
      errorFormatLogo: false,
      isLoadingPhoto: true
    }));
    if(files){
      for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileName = file.name.toLowerCase();
          const extension = fileName.split('.').pop();
          if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png' && extension !== 'webp') {
              error = true;
          }
      }
    }
    if(!error){
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Authorization': 'Bearer ' + idToken
        });
        const files = event.target.files;
        const formData = new FormData();
        formData.append('file', files[0]);
        formData.append('type', 'LOGO');
        fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/photos`, {
          method: 'POST',
          headers: myHeaders,
          body: formData
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                isLoadingPhoto: false
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState({
                logoImg: data,
                isLoadingPhoto: false
              });
            });
          }
        })
      })
    } else {
      this.setState(prevState => ({
        errorFormatLogo: true,
        isLoadingPhoto: false
      }));
    }
  }
  handlerCloseSuccess (event) {
    event.preventDefault();
    this.setState(prevState => ({
      successSubmit: false,
      successText: null
    }));
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerColorValue(event){
    this.setState({
      colorValue: event.target.value
    });
  }
  handlerColorSubmit(event){
    event.preventDefault();
    let that = this;
    this.setState({
      isLoadingSubmitColor: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/companies/colours`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
          "color": that.state.colorValue,
          "type" : "COMPANY"
        })
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoadingSubmitColor: false,
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState(prevState => ({
              isLoadingSubmitColor: false,
              displayColorPicker: false,
              companyData: { ...prevState.companyData, color: prevState.colorValue },
              successSubmit: true
            }));
            setTimeout(() => {
              that.setState({
                successSubmit: false
              });
            }, 5000);
            that.handleModalDisabled();
          });
        }
      })
    })
  }
  handlerRemoveLogo(){
    let that = this;
    this.setState(prevState => ({
      isLoadingPhoto: true
    }));
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/photos`, {
        method: 'DELETE',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoadingPhoto: false,
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              logoImg: null,
              isLoadingPhoto: false
            })
          });
        }
      })
    })
  }
  handlerModalEditTab(status, event){
    event.preventDefault();
    this.setState({
      modalEditTab: status
    })
  }
  handlerSetColor(color, event){
    event.preventDefault();
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/companies/colours`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
          "type" : "CARD_TEXT",
          "color" : color
        })
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoadingPhoto: false,
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              colorTxt: color
            });
            that.setState({
              logoImg: null,
              isLoadingPhoto: false
            })
          });
        }
      })
    })
    if(document.querySelector('.reactEasyCrop_CropArea')){
      document.querySelector('.reactEasyCrop_CropArea').innerHTML = '<div class="modal-image-edit__main-preview-inner"><div class="modal-image-edit__main-preview-name" style="color:' + color + '">Peter Parker<img src="' + avatar + '" alt="" /></div><div class="modal-image-edit__main-preview-value" style="color:' + color + '">Middle Front-End Developer</div><div class="modal-image-edit__main-preview-value" style="color:' + color + '">Front-End Team</div><div class="modal-image-edit__main-preview-balance" style="color:' + color + '">999</div></div>';
    }
  }
  handlerMainPhotoSubmit(type, event){
    event.preventDefault();
    let that = this;
    let error = false;
    this.setState(prevState => ({
      errorFormatImgs: false,
      errorSizeImgs: false
    }));
    let files = that.state.filesSubmit;
    if(files){
      for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileName = file.name.toLowerCase();
          const extension = fileName.split('.').pop();
          const fileSizeInMB = file.size / (1024 * 1024);
          if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png' && extension !== 'webp') {
              error = true;
              this.setState(prevState => ({
                errorFormatImgs: true
              }));
          }
          if (fileSizeInMB > 25) {
              error = true;
              this.setState(prevState => ({
                errorSizeImgs: true
              }));
          }
      }
    }
    if(!error){
    if(that.state.imageSrc) {
    this.setState({
      isLoadedSubmitImage: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Authorization': 'Bearer ' + idToken
      });
      if(that.state.cropImageStatus){
        let croppedImage = '';
        croppedImage = getCroppedImg(
          that.state.imageSrc,
          that.state.croppedAreaPixels,
          that.state.rotation
        );
        const myPromise = new Promise((resolve, reject) => {
          const result = croppedImage;
          resolve(result);
        });
        myPromise.then((result) => {
          var img = new Image();
          img.src = result;
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');
          img.onload = function() {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            var dataURL = canvas.toDataURL('image/jpeg');
            var blob = dataURLToBlob(dataURL);
            const formData = new FormData();
            formData.append('file', blob, that.state.mainImgName);
            formData.append('type', type);
            fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/photos`, {
              method: 'POST',
              headers: myHeaders,
              body: formData
            }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    isLoadedSubmitImage: false,
                    errors: data.errors
                  })
                });
              } else {
                res.json().then(function(data) {
                  if(type === 'MAIN'){
                    that.setState({
                      mainImgObj: data
                    });
                  } else if(type === 'CARD'){
                    that.setState({
                      cardImgObj: data
                    });
                  } else {
                    that.setState({
                      iconImgObj: data
                    });
                  }
                  that.setState({
                    isLoadedSubmitImage: false
                  });
                  that.handleModalDisabled();
                  });
              }
            })
          };
          function dataURLToBlob(dataURL) {
            var byteString = atob(dataURL.split(',')[1]);
            var mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], {type: mimeString});
          }
        });
      } else {
        let files = that.state.filesSubmit;
        const formData = new FormData();
        formData.append('file', files[0]);
        formData.append('type', type);
        fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/photos`, {
          method: 'POST',
          headers: myHeaders,
          body: formData
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoadedSubmitImage: false,
                errors: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              if(type === 'MAIN'){
                that.setState({
                  mainImgObj: data
                });
              } else if(type === 'CARD'){
                that.setState({
                  cardImgObj: data
                });
              } else {
                that.setState({
                  iconImgObj: data
                });
              }
              that.setState({
                isLoadedSubmitImage: false
              });
              that.handleModalDisabled();
              });
          }
        })
      }
    })
  } else {
    that.handleModalDisabled();
  }
}
  }
  handlerDeletePhotoById(id, type, event){
    let that = this;
    this.setState({
      isLoadingPhotoRemove: type
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/photos/${id}`, {
        method: 'DELETE',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.text().then(function(data) {
            that.setState({
              isLoadingPhotoRemove: null,
              errors: data.errors
            });
          })
        } else {
          res.text().then(function(data) {
            if(type === 'LOGO'){
              that.setState({
                logoImg: null
              });
            } else if(type === 'MAIN'){
              that.setState({
                mainImgObj: null
              });
            } else if(type === 'CARD'){
              that.setState({
                cardImgObj: null
              });
            } else {
              that.setState({
                iconImgObj: null
              });
            }
            that.setState({
              isLoadingPhotoRemove: null
            })
          })
        }
      })
    })
  }
  handlerPreviewImg(event){
    let error = false;
    this.setState(prevState => ({
      errorFormatImgs: false,
      errorSizeImgs: false
    }));
    let files = event.target.files;
    if(files){
      for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileName = file.name.toLowerCase();
          const extension = fileName.split('.').pop();
          const fileSizeInMB = file.size / (1024 * 1024);
          if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png' && extension !== 'webp') {
              error = true;
              this.setState(prevState => ({
                errorFormatImgs: true
              }));
          }
          if (fileSizeInMB > 25) {
              error = true;
              this.setState(prevState => ({
                errorSizeImgs: true
              }));
          }
      }
    }
    this.setState({
      filesSubmit: files
    });
    if(!error){
      let nameFile = files[0].name;
      let name = nameFile.split('.')[0].substring(0, 10);
      let type = nameFile.split('.')[1];
      let fullName = name + '.' + type;
      this.setState({
        imageSrc: URL.createObjectURL(files[0]),
        mainImgName: fullName
      });
    }
  }
  handlerSetDefaultImg(url, type, index, event){
    this.setState({
      previewImg: url,
      indexImg: index,
      defaultImg: {
        url: url,
        type: type
      },
      imageSrc: url,
      crop: { x: 0, y: 0 },
      zoom: 2,
      aspect: 4 / 2,
      rotation: 0,
      croppedAreaPixels: null,
      croppedImage: null,
      mainImgName: '',
      cropImageStatus: false
    });
  }
  handlerSubmitDefaultImg(event){
    let that = this;
    if(that.state.previewImg){
      this.setState({
        isLoadedSubmitImage: true
      });
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/photos/defaults`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(that.state.defaultImg)
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoadedSubmitImage: false,
                errors: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              if(data.type === 'MAIN'){
                that.setState({
                  mainImgObj: data
                });
              } else if(data.type === 'CARD'){
                that.setState({
                  cardImgObj: data
                });
              } else {
                that.setState({
                  iconImgObj: data
                });
              }
              that.setState({
                isLoadedSubmitImage: false
              });
              that.handleModalDisabled();
            });
          }
        })
      })
    } else {
      that.handleModalDisabled();
    }
  }
  handlerChangeStatus(event){
    this.setState({
      statusAccount: event
    });
  }
  handlerChangeTieZone(event){
    this.setState({
      timeZoneValue: event
    });
  }
  handlerChangeLocale(event){
    this.setState({
      localeValue: event
    });
  }
  handlerSubmitStatus(event){
    event.preventDefault();
    let that = this;
    this.setState({
      isLoadedSubmitStatus: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/companies/status`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
          status: that.state.statusAccount.value
        })
      }).then(res => {
        if(res.status !== 200) {
          that.setState({
            isLoadedSubmitStatus: false
          });
        } else {
          let companyData = that.state.companyData;
          companyData.status = that.state.statusAccount.value;
          that.setState({
            companyData: companyData,
            isLoadedSubmitStatus: false
          });
          that.handleModalDisabled();
        }
      })
    })
  }

  handlerSubmitTimeZone(event){
    event.preventDefault();
    let that = this;
    this.setState({
      isLoadedSubmitStatus: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/companies/timezone`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
          timeZone: that.state.timeZoneValue.value
        })
      }).then(res => {
        if(res.status !== 200) {
          that.setState({
            isLoadedSubmitStatus: false
          });
        } else {
          that.handleModalDisabled();
        }
      })
    })
  }

  handlerSubmitLocale(event){
    event.preventDefault();
    let that = this;
    this.setState({
      isLoadedSubmitStatus: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/companies/locales`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
          locale: that.state.localeValue.value
        })
      }).then(res => {
        if(res.status !== 200) {
          that.setState({
            isLoadedSubmitStatus: false
          });
        } else {
          that.handleModalDisabled();
        }
      })
    })
  }

  handlerDeletePreview(){
    this.setState({
      imageSrc: null,
      crop: { x: 0, y: 0 },
      zoom: 2,
      aspect: 4 / 2,
      rotation: 0,
      croppedAreaPixels: null,
      croppedImage: null,
      mainImgName: '',
      previewImg: null,
      cropImageStatus: false
    })
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('companyinfo-CompanyTitle') + ' | ' + this.props.companyName;
    }
    const popover = {
      position: 'absolute',
      zIndex: '2',
      left: '0',
      bottom: '100%',
      backgroundColor: '#fff',
      borderRadius: '2px',
      boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.75)'
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
    let companyInfo = '';
    if (this.state.error) {
      companyInfo = <div className="errors-content">Ошибка...</div>;
    } else if (!this.state.isLoaded) {
      companyInfo = <Loading />;
    } else {
      let status = '';
      if(this.state.companyData.status === 'ACTIVE'){
        status = this.props.getTranslate('companyinfo-StatusActive');
      } else if(this.state.companyData.status === 'DEACTIVATED'){
        status = this.props.getTranslate('companyinfo-StatusNotActive');
      }
      companyInfo = <>
      <div className="account-wgt account-content__wgt">
        <h2 className="account-wgt__header">{this.props.getTranslate('companyinfo-InfoTitle')}</h2>
        <div className="account-wgt__inner">
          <div className="account-item account-wgt__item">
            <div className="account-item__headline">{this.props.getTranslate('companyinfo-CompanyName')}</div>
            <div className="account-item__value account-item__value-name">{this.state.companyData.name ? this.state.companyData.name : ''}</div>
            <button className="account-wgt__edit-btn" type="button" onClick={this.handlerModalInfo.bind(this)}>{this.props.getTranslate('companyinfo-Edit')}</button>
          </div>
          {this.state.companyData.email && <div className="account-item account-wgt__item">
            <div className="account-item__headline">{this.props.getTranslate('companyinfo-ReservMail')}</div>
            <div className="account-item__value">{this.state.companyData.email}</div>
          </div>}
          <div className="account-item account-wgt__item account-item-splash">
            <div className="account-item__headline">{this.props.getTranslate('companyinfo-Logo')}</div>
            <div className="account-item-splash__content">
              <div className="account-item-splash__img-wrap account-item-company">
                <div className="account-item-company__inner">
                  {this.state.logoImg ? <img className="account-item-company__logo" src={this.state.logoImg.url.replace( /https:/g, "http:" )} alt="" /> : <img className="account-item-company__logo" src={matespaceLogo} alt="" />}
                </div>
              </div>
              <div className="account-item-splash__icon-wrap" onMouseEnter={this.handlerLogoImg.bind(this)} onMouseLeave={this.handlerLogoImg.bind(this)}>
                <svg>
                  <use href={`${sprite}#phone-company`}></use>
                </svg>
                {this.state.logoIconImg && <div className="account-item-splash__icon-inner-wrap"><img className="account-item-splash__icon-img-wrap" src={logoImgTooltip} alt="" /></div>}
              </div>
            </div>
            {this.state.logoImg ?<div className="edit-logo-nav edit-logo-nav__exists">
              <label className="edit-logo-nav__edit" htmlFor="set-logo">{this.props.getTranslate('companyinfo-Edit')}</label>
              <input id="set-logo" type="file" onChange={this.handlerUploadLogoPhoto.bind(this)} />
              <button className="account-wgt__remove-btn" type="button" onClick={this.handlerDeletePhotoById.bind(this, this.state.logoImg.entityID, 'LOGO')}>{this.props.getTranslate('companyinfo-Delete')}{this.state.isLoadingPhoto && <Loading />}</button>
            </div> : <div className="edit-logo-nav">
              <label className="edit-logo-nav__edit" htmlFor="set-logo">{this.props.getTranslate('company-LoadInBtnImg')}{this.state.isLoadingPhoto && <Loading />}</label>
              <input id="set-logo" type="file" onChange={this.handlerUploadLogoPhoto.bind(this)} />
            </div>}
            {this.state.errorFormatLogo ? <div className="validation-error">Формат зображень повинен бути jpg png або webp</div> : ''}
          </div>
          {this.state.companyData.status && <div className="account-item account-wgt__item">
            <div className="account-item__headline">{this.props.getTranslate('companyinfo-Status')}</div>
            <div className="account-item__value account-item__value-name">{status}</div>
            <button className="account-wgt__edit-btn" type="button" onClick={this.handlerModalStatus.bind(this)}>{this.props.getTranslate('companyinfo-Edit')}</button>
          </div>}
        </div>
      </div>
      <div className="account-wgt account-content__wgt account-content__wgt_p_t">
        <h2 className="account-wgt__header">{this.props.getTranslate('companyinfo-CurrencyTitle')}</h2>
        <div className="account-wgt__inner">
          <div className="account-item account-wgt__item">
            <div className="account-item__headline">{this.props.getTranslate('companyinfo-CurrencyName')}</div>
            <div className="account-item__value account-item__value-name">{this.state.companyData.currency ? this.state.companyData.currency : ''}</div>
            <button className="account-wgt__edit-btn" type="button" onClick={this.handlerModalCurrency.bind(this)}>{this.props.getTranslate('companyinfo-Edit')}</button>
          </div>
          {this.state.companyData.sign && <div className="account-item account-wgt__item">
            <div className="account-item__headline">{this.props.getTranslate('companyinfo-CurrencyIcon')}</div>
            <div className="account-item__value">{this.state.companyData.sign}</div>
          </div>}
          <div className="account-item account-wgt__item">
            <div className="account-item__headline">{this.props.getTranslate('companyinfo-TimeZone')}</div>
            <div className="account-item__value account-item__value-name">{this.state.companyData.timeZone ? this.state.companyData.timeZone : ''}</div>
            <button className="account-wgt__edit-btn" type="button" onClick={this.handlerModalTimeZone.bind(this)}>{this.props.getTranslate('companyinfo-Edit')}</button>
          </div>
          <div className="account-item account-wgt__item">
            <div className="account-item__headline">{this.props.getTranslate('companyinfo-Locale')}</div>
            <div className="account-item__value account-item__value-name">{this.state.companyData.companyLocale ? this.state.companyData.companyLocale : ''}</div>
            <button className="account-wgt__edit-btn" type="button" onClick={this.handlerModalLocale.bind(this)}>{this.props.getTranslate('companyinfo-Edit')}</button>
          </div>
        </div>
      </div>
      <div className="account-wgt account-content__wgt account-content__wgt_p_t">
        <h2 className="account-wgt__header">{this.props.getTranslate('companyinfo-VisualTitle')}</h2>
        <div className="account-wgt__inner">
          <div className="account-item account-item-splash account-wgt__item">
            <div className="account-item__headline">{this.props.getTranslate('companyinfo-Cover')}</div>
            <div className="account-item-splash__content">
              <div className="account-item-splash__img-wrap">
                {this.state.mainImgObj ? <img src={this.state.mainImgObj.url.replace( /https:/g, "http:" )} alt="" /> : (this.state.mainImgs[0] ? <img src={this.state.mainImgs[0].replace( /https:/g, "http:" )} alt="" /> : '')}
              </div>
              <div className="account-item-splash__icon-wrap">
                <svg className="account-item-splash__icon" onMouseEnter={this.handlerSplashIconImg.bind(this)} onMouseLeave={this.handlerSplashIconImg.bind(this)}>
                  <use href={`${sprite}#phone-company`}></use>
                </svg>
                <svg className="account-item-splash__icon-mob" onTouchStart={this.handlerSplashIconImg.bind(this)}>
                  <use href={`${sprite}#phone-company`}></use>
                </svg>
                <div className="account-item-splash__icon-inner-wrap" style={{top: '-' + this.state.downImage + 'px', visibility: this.state.splashIconImg ? 'visible' : 'hidden'}}><img className="account-item-splash__icon-img-wrap" src={coverSplash} alt="" /></div>
              </div>
            </div>
            <div className="account-item__icon-size">1080 x 900 px</div>
            <div className="account-wgt__edit-visual">
              <div className="account-wgt__edit-visual-inner">
                <button className="account-wgt__edit-btn" type="button" onClick={this.handlerMainPhotoModal.bind(this)}>{this.props.getTranslate('companyinfo-Edit')}</button>
              </div>
              {this.state.mainImgObj && <button className="account-wgt__remove-btn" type="button" onClick={this.handlerDeletePhotoById.bind(this, this.state.mainImgObj.entityID, 'MAIN')}>{this.props.getTranslate('companyinfo-Delete')}{this.state.isLoadingPhotoRemove === 'MAIN' && <Loading />}</button>}
            </div>
          </div>
          <div className="account-item account-item-splash account-wgt__item">
            <div className="account-item__headline">{this.props.getTranslate('companyinfo-CardDesign')}</div>
            <div className="account-item-card__content">
              <div className="account-item-card__img-wrap">
                {this.state.cardImgObj ? <img src={this.state.cardImgObj.url.replace( /https:/g, "http:" )} alt="" /> : (this.state.cardImgs[0] ? <img src={this.state.cardImgs[0].replace( /https:/g, "http:" )} alt="" /> : '')}
              </div>
              <div className="account-item-splash__icon-wrap">
                <svg className="account-item-splash__icon" onMouseEnter={this.handlerCardIconImg.bind(this)} onMouseLeave={this.handlerCardIconImg.bind(this)}>
                  <use href={`${sprite}#phone-company`}></use>
                </svg>
                <svg className="account-item-splash__icon-mob" onTouchStart={this.handlerCardIconImg.bind(this)}>
                  <use href={`${sprite}#phone-company`}></use>
                </svg>
                <div className="account-item-splash__icon-inner-wrap" style={{top: '-' + this.state.downImage + 'px', visibility: this.state.cardImg ? 'visible' : 'hidden'}}><img className="account-item-splash__icon-img-wrap" src={cardImg} alt="" /></div>
              </div>
            </div>
            <div className="account-item__icon-size">960 x 600 px</div>
            <div className="account-wgt__edit-visual">
              <div className="account-wgt__edit-visual-inner">
                <button className="account-wgt__edit-btn" type="button" onClick={this.handlerCardPhotoModal.bind(this)}>{this.props.getTranslate('companyinfo-Edit')}</button>
              </div>
              {this.state.cardImgObj && <button className="account-wgt__remove-btn" type="button" onClick={this.handlerDeletePhotoById.bind(this, this.state.cardImgObj.entityID, 'CARD')}>{this.props.getTranslate('companyinfo-Delete')}{this.state.isLoadingPhotoRemove === 'CARD' && <Loading />}</button>}
            </div>
          </div>
          <div className="account-item account-item-splash account-wgt__item" style={{display: 'none'}}>
            <div className="account-item__headline">{this.props.getTranslate('companyinfo-AppIcon')}</div>
            <div className="account-item-app__content">
              <div className="account-item-app__img-wrap">
              {this.state.iconImgObj ? <img src={this.state.iconImgObj.url.replace( /https:/g, "http:" )} alt="" /> : (this.state.iconImgs[0] ? <img src={this.state.iconImgs[0].replace( /https:/g, "http:" )} alt="" /> : '')}
              </div>
              <div className="account-item-splash__icon-wrap">
                <svg className="account-item-splash__icon" onMouseEnter={this.handlerIconImg.bind(this)} onMouseLeave={this.handlerIconImg.bind(this)}>
                  <use href={`${sprite}#phone-company`}></use>
                </svg>
                <svg className="account-item-splash__icon-mob" onTouchStart={this.handlerIconImg.bind(this)}>
                  <use href={`${sprite}#phone-company`}></use>
                </svg>
                <div className="account-item-splash__icon-inner-wrap" style={{top: '-' + this.state.downImage + 'px', visibility: this.state.iconImg ? 'visible' : 'hidden'}}><img className="account-item-splash__icon-img-wrap" src={iconImg} alt="" /></div>
              </div>
            </div>
            <div className="account-item__icon-size">512 x 512 px</div>
            <div className="account-wgt__edit-visual">
              <div className="account-wgt__edit-visual-inner">
                <button className="account-wgt__edit-btn" type="button" onClick={this.handlerIconPhotoModal.bind(this)}>{this.props.getTranslate('companyinfo-Edit')}</button>
              </div>
              {this.state.iconImgObj && <button className="account-wgt__remove-btn" type="button" onClick={this.handlerDeletePhotoById.bind(this, this.state.iconImgObj.entityID, 'ICON')}>{this.props.getTranslate('companyinfo-Delete')}{this.state.isLoadingPhotoRemove === 'ICON' && <Loading />}</button>}
            </div>
          </div>
          <div className="account-item account-wgt__item" style={{width: '100%'}}>
            <div className="account-item__headline">{this.props.getTranslate('companyinfo-colorTitle')}</div>
            <div className="account-item__value account-item__value_color">{this.state.companyData.color ? this.state.companyData.color : '#364ed4'}
              <div className="account-item__color" style={{backgroundColor: this.state.companyData.color ? this.state.companyData.color : '#364ed4'}}></div>

              <div className="account-item-color__icon-wrap">
                <svg className="account-item-color__icon" onMouseEnter={this.handlerColorImg.bind(this)} onMouseLeave={this.handlerColorImg.bind(this)}>
                  <use href={`${sprite}#phone-company`}></use>
                </svg>
                <svg className="account-item-color__icon-mob" onTouchStart={this.handlerColorImg.bind(this)}>
                  <use href={`${sprite}#phone-company`}></use>
                </svg>
                <div className="account-item-color__icon-inner-wrap" style={{top: '-' + this.state.downImage + 'px', visibility: this.state.colorImg ? 'visible' : 'hidden'}}><img className="account-item-splash__icon-img-wrap" src={colorImg} alt="" /></div>
              </div>

            </div>
            <button className="account-wgt__edit-btn" type="button" onClick={ this.handleClick }>{this.props.getTranslate('companyinfo-Edit')}</button>
            <div className="account-item__color">
              { this.state.displayColorPicker ? <div style={ popover }>
                <div style={ cover } onClick={ this.handleClose }/>
                <ChromePicker color={ this.state.colorValue  } onChange={ this.handleChange } disableAlpha={true} />
                <button className="account-wgt__edit-btn account-wgt__edit-btn_color" type="button" onClick={this.handlerColorSubmit.bind(this)}>{this.props.getTranslate('companyinfo-SaveButton')}{this.state.isLoadingSubmitColor && <Loading />}</button>
              </div> : null }
            </div>
          </div>
        </div>
      </div>
      </>;
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {(this.state.successSubmit && this.state.successText) && <div className="response-submit">
    <div className="response-submit__item response-submit__item_success">{this.state.successText}
      <button className="response-submit__close" type="button" onClick={this.handlerCloseSuccess.bind(this)}>
      <svg><use href={`${sprite}#close-icon`}></use></svg>
      </button></div></div>}
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap" onTouchStart={this.disabledAllTooltip.bind(this)}>
    <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.state.companyData.name ? this.state.companyData.name : this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
    <div className="account-content">
      <div className="account-content__inner">

        {/* Account header component */}
        <AccountContentHeader
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
          title={this.props.getTranslate('companyinfo-CompanyTitle')}
          addClass="account-content__header_hide"
        />

        {companyInfo}
      </div>
    </div>
    </div>
    {this.state.modalInfo ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
  <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.props.getTranslate('companyinfo-InfoTitle')}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
      <form onSubmit={this.handlerCompanyInfoSubmit.bind(this)}>
      <div className="field-wrap">
        <label className="field-wrap__label" htmlFor="name">{this.props.getTranslate('companyinfo-CompanyName')}</label>
        <input id="name" className={`field-wrap__input ${this.state.companyNameLength || (this.state.errorFields && !this.state.companyName) || (this.state.errorOnlySpecial && this.state.companyName !== '') ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.companyName} onChange={this.handlerCompanyName.bind(this)} placeholder={this.props.getTranslate('companyinfo-CompanyNameFieldCaption')} />
        {this.state.companyNameLength ? <div className="validation-error">{this.props.getTranslate('validation-CompanyNameLengthField')}</div> : ''}
        {this.state.errorFields && this.state.companyName === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
        {this.state.errorFields && this.state.errorOnlySpecial && this.state.companyName !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpecialField')}</div> : ''}
      </div>
        <div className="register-form__submit">
          <button className="btn btn_width_100 btn_size_lg form-nav__submit" disabled={this.state.modalEditLoading? true : false}>{this.props.getTranslate('companyinfo-SaveButton')}{this.state.modalEditLoading && <Loading />}</button>
        </div>
      </form>
  </div>
</div> : ''}

{this.state.modalStatus ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
<div className="modal-background" onClick={this.handleModalDisabled}></div>
<div className="modal">
  <div className="modal-header">
    <h2 className="modal-headline">{this.props.getTranslate('companyinfo-Status')}</h2>
    <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
      <svg className="close-modal__icon">
        <use href={`${sprite}#close-icon`}></use>
      </svg>
    </button>
  </div>
  <div className="modal-description">{this.props.getTranslate('companyinfo-StatusDescription')}</div>
  <form onSubmit={this.handlerSubmitStatus.bind(this)}>
    <div className="field-wrap">
      <Select
        styles={customStyles}
        defaultValue={this.state.statusAccount}
        options={[{label: this.props.getTranslate('companyinfo-StatusActive'), value: 'ACTIVE'}, {label: this.props.getTranslate('companyinfo-StatusNotActive'), value: 'DEACTIVATED'}]}
        isSearchable={false}
        onChange={this.handlerChangeStatus.bind(this)}
      />
    </div>
    <div className="register-form__submit">
      <button className="btn btn_width_100 btn_size_lg form-nav__submit" disabled={this.state.isLoadedSubmitStatus ? true : false}>{this.props.getTranslate('companyinfo-SaveButton')}{this.state.isLoadedSubmitStatus && <Loading />}</button>
    </div>
  </form>
</div>
</div> : ''}

{this.state.modalCurrency ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
<div className="modal-background" onClick={this.handleModalDisabled}></div>
<div className="modal">
  <div className="modal-header">
    <h2 className="modal-headline">{this.props.getTranslate('companyinfo-CurrencyTitle')}</h2>
    <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
      <svg className="close-modal__icon">
        <use href={`${sprite}#close-icon`}></use>
      </svg>
    </button>
  </div>
  <div className="modal-description">{this.props.getTranslate('companyinfo-CurrencyDescription')}</div>
  <form onSubmit={this.handlerCompanyCurrencySubmit.bind(this)}>
  <div className="field-wrap">
    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="name">{this.props.getTranslate('companyinfo-CurrencyIcon')}</label>
    <input id="name" className={`field-wrap__input ${this.state.companyNameCurrencyLength || (this.state.errorFields && !this.state.companyNameCurrency) || (this.state.errorOnlySpecialCurrency && this.state.companyNameCurrency !== '') ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.companyNameCurrency} onChange={this.handlerCompanyCurrency.bind(this)} placeholder={this.props.getTranslate('companyinfo-CurrencyIconFieldCaption')} />
    {this.state.companyNameCurrencyLength ? <div className="validation-error">Максимум 5 символів</div> : ''}
    {this.state.errorFields && this.state.companyNameCurrency === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
    {this.state.errorFields && this.state.errorOnlySpecialCurrency && this.state.companyNameCurrency !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpecialField')}</div> : ''}
  </div>
    <div className="register-form__submit">
      <button className="btn btn_width_100 btn_size_lg form-nav__submit" disabled={this.state.modalEditLoading? true : false}>{this.props.getTranslate('companyinfo-SaveButton')}{this.state.modalEditLoading && <Loading />}</button>
    </div>
  </form>
</div>
</div> : ''}

{this.state.modalTimeZone ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
<div className="modal-background" onClick={this.handleModalDisabled}></div>
<div className="modal">
  <div className="modal-header">
    <h2 className="modal-headline">Виберіть тайм-зону аккаунту</h2>
    <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
      <svg className="close-modal__icon">
        <use href={`${sprite}#close-icon`}></use>
      </svg>
    </button>
  </div>
  <form onSubmit={this.handlerSubmitTimeZone.bind(this)}>
    <div className="field-wrap">
      <Select
        styles={customStyles}
        options={this.state.timeZoneOptions}
        onChange={this.handlerChangeTieZone.bind(this)}
      />
    </div>
    <div className="register-form__submit">
      <button className="btn btn_width_100 btn_size_lg form-nav__submit" disabled={this.state.modalEditLoading? true : false}>{this.props.getTranslate('companyinfo-SaveButton')}{this.state.modalEditLoading && <Loading />}</button>
    </div>
  </form>
</div>
</div> : ''}

{this.state.modalLocale ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
<div className="modal-background" onClick={this.handleModalDisabled}></div>
<div className="modal">
  <div className="modal-header">
    <h2 className="modal-headline">Виберіть локаль</h2>
    <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
      <svg className="close-modal__icon">
        <use href={`${sprite}#close-icon`}></use>
      </svg>
    </button>
  </div>
  <form onSubmit={this.handlerSubmitLocale.bind(this)}>
    <div className="field-wrap">
      <Select
        styles={customStyles}
        options={this.state.localeOptions}
        onChange={this.handlerChangeLocale.bind(this)}
      />
    </div>
    <div className="register-form__submit">
      <button className="btn btn_width_100 btn_size_lg form-nav__submit" disabled={this.state.modalEditLoading? true : false}>{this.props.getTranslate('companyinfo-SaveButton')}{this.state.modalEditLoading && <Loading />}</button>
    </div>
  </form>
</div>
</div> : ''}

{this.state.cardPhotoModal ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
<div className="modal-background" onClick={this.handleModalDisabled}></div>
<div className="modal">
  <div className="modal-header">
    <h2 className="modal-headline">{this.props.getTranslate('companyinfo-modalCardTitle')}</h2>
    <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
      <svg className="close-modal__icon">
        <use href={`${sprite}#close-icon`}></use>
      </svg>
    </button>
  </div>
  <div className="modal-image-edit">
    {this.state.modalEditTab ? (this.state.imageSrc ? <div className="modal-image-edit__main-preview"><Cropper
      image={this.state.imageSrc}
      crop={this.state.crop}
      zoom={this.state.zoom}
      aspect={this.state.aspect}
      onCropChange={this.onCropChange}
      onCropComplete={this.onCropComplete}
      onZoomChange={this.onZoomChange}
      cropSize={{width: 320, height: 180}}
      showGrid={false}
    /></div> : (this.state.cardImgObj ? <div className="modal-image-edit__main-preview"><Cropper
      image={this.state.cardImgObj.url}
      crop={this.state.crop}
      zoom={this.state.zoom}
      aspect={this.state.aspect}
      onCropChange={this.onCropChange}
      onCropComplete={this.onCropComplete}
      onZoomChange={this.onZoomChange}
      cropSize={{width: 320, height: 180}}
      showGrid={false}
    /></div> : <div className="modal-image-edit__main-preview">
      <div className="modal-image-edit__main-preview-empty">
      <div className="modal-image-edit__main-preview-inner">
      <div className="modal-image-edit__main-preview-name" style={{color: this.state.colorTxt}}>Peter Parker<img src={avatar} alt="" />
      </div><div className="modal-image-edit__main-preview-value" style={{color: this.state.colorTxt}}>Middle Front-End Developer</div>
      <div className="modal-image-edit__main-preview-value" style={{color: this.state.colorTxt}}>Front-End Team</div>
      <div className="modal-image-edit__main-preview-balance">999</div>
      </div>
      </div>
    </div>))
    :
    this.state.previewImg ? <img className="modal-image-edit__preview" src={this.state.previewImg} alt="" /> : (this.state.cardImgObj ? <img className="modal-image-edit__preview" src={this.state.cardImgObj.url} alt="" /> : <img className="modal-image-edit__preview" src={this.state.cardImgs[0].url} alt="" />)}
     <div className="modal-image-edit__nav">
      <div className="modal-image-edit__nav-btn-wrap">
        <button className={`modal-image-edit__nav-btn ${this.state.modalEditTab ? '' : 'active'}`} type="button" onClick={this.handlerModalEditTab.bind(this, false)}>{this.props.getTranslate('companyinfo-modalScreensaverTabChoose')}</button>
      </div>
      <div className="modal-image-edit__nav-btn-wrap">
        <button className={`modal-image-edit__nav-btn ${this.state.modalEditTab ? 'active' : ''}`} type="button" onClick={this.handlerModalEditTab.bind(this, true)}>{this.props.getTranslate('companyinfo-modalScreensaverTabLoad')}</button>
      </div>
    </div>
    <div className="modal-image-edit__color">
      <div className="modal-image-edit__color-label">{this.props.getTranslate('companyinfo-modalCardChooseColor')}</div>
      <div className="modal-image-edit__color-items">
        <div className={`modal-image-edit__color-item ${this.state.colorTxt === '#000000' && 'active'}`} onClick={this.handlerSetColor.bind(this, '#000000')}>
          <div className="modal-image-edit__color-item-inner" style={{backgroundColor: '#000000'}}></div>
        </div>
        <div className={`modal-image-edit__color-item ${this.state.colorTxt === '#ffffff' && 'active'}`} onClick={this.handlerSetColor.bind(this, '#ffffff')}>
          <div className="modal-image-edit__color-item-inner" style={{backgroundColor: '#ffffff', border: '1px solid #c7c7c7'}}></div>
        </div>
      </div>
    </div>
    <div className="modal-image-edit__color-label">Фонове зображення картки</div>
    {this.state.modalEditTab ? <div className="modal-image-edit__content">
      <form onSubmit={this.handlerMainPhotoSubmit.bind(this, 'CARD')}>
        <div className="modal-image-edit__upload">
        {this.state.mainImgName === '' ? <>
            <label htmlFor="upload-image-edit" className="modal-image-edit__upload-label">{this.props.getTranslate('companyinfo-modalScreensaverTabLoadImg')}</label>
            <input id="upload-image-edit" type="file" onChange={this.handlerPreviewImg.bind(this)} />
            {this.state.errorFormatImgs ? <div className="validation-error">Формат зображень повинен бути jpg png або webp</div> : ''}
            {this.state.errorSizeImgs ? <div className="validation-error">Файл не повинен перевищувати 25 мегабайт</div> : ''}
        </> : <div className="upload-image-info">{this.state.mainImgName}
          <button className="upload-image-info__btn" type="button" onClick={this.handlerDeletePreview.bind(this)}>
            <svg className="upload-image-info__delete">
              <use href={`${sprite}#delete`}></use>
            </svg>
          </button>
        </div>}
        </div>
        <div className="modal-image-edit__nav register-form__submit form-nav">
          <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="submit" disabled={this.state.isLoadedSubmitImage ? true : false}>{this.props.getTranslate('companyinfo-modalScreensaverSendBtn')}{this.state.isLoadedSubmitImage && <Loading />}</button>
        </div>
      </form>
    </div> : <div className="modal-image-edit__content">
    <div className="modal-image-edit__imgs">
      {this.state.cardImgs.map((item, index) => {
        return <div className={`modal-image-edit__img ${!this.state.cardImgObj && index === 0 && !this.state.previewImg ? 'active' : ''} ${this.state.cardImgObj && this.state.cardImgObj.url === item && !this.state.previewImg ? 'active' : ''} ${this.state.indexImg === index ? 'active' : ''}`} key={index} onClick={this.handlerSetDefaultImg.bind(this, item, 'CARD', index)}>
                <img src={item.replace( /https:/g, "http:" )} alt="" />
              </div>
      })}
    </div>
    <div className="modal-image-edit__nav register-form__submit form-nav">
      <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="button" onClick={this.handlerSubmitDefaultImg.bind(this)} disabled={this.state.isLoadedSubmitImage ? true : false}>{this.props.getTranslate('companyinfo-modalScreensaverSendBtn')}{this.state.isLoadedSubmitImage && <Loading />}</button>
    </div>
    </div>}
  </div>
</div>
</div> : ''}
{this.state.mainPhotoModal ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
<div className="modal-background" onClick={this.handleModalDisabled}></div>
<div className="modal">
  <div className="modal-header">
    <h2 className="modal-headline">{this.props.getTranslate('companyinfo-modalScreensaverTitle')}</h2>
    <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
      <svg className="close-modal__icon">
        <use href={`${sprite}#close-icon`}></use>
      </svg>
    </button>
  </div>
  <div className="modal-image-edit">
    {this.state.imageSrc ? <img className="modal-image-edit__preview" src={this.state.imageSrc} alt="" /> : (this.state.mainImgObj ? <img className="modal-image-edit__preview" src={this.state.mainImgObj.url} alt="" /> : <img className="modal-image-edit__preview" src={this.state.mainImgs[0].url} alt="" />)}
    <div className="modal-image-edit__nav">
      <div className="modal-image-edit__nav-btn-wrap">
        <button className={`modal-image-edit__nav-btn ${this.state.modalEditTab ? '' : 'active'}`} type="button" onClick={this.handlerModalEditTab.bind(this, false)}>{this.props.getTranslate('companyinfo-modalCardTabChoose')}</button>
      </div>
      <div className="modal-image-edit__nav-btn-wrap">
        <button className={`modal-image-edit__nav-btn ${this.state.modalEditTab ? 'active' : ''}`} type="button" onClick={this.handlerModalEditTab.bind(this, true)}>{this.props.getTranslate('companyinfo-modalCardTabLoad')}</button>
      </div>
    </div>
    {this.state.modalEditTab ? <div className="modal-image-edit__content">
    <form onSubmit={this.handlerMainPhotoSubmit.bind(this, 'MAIN')}>
      <div className="modal-image-edit__upload">
      {this.state.mainImgName === '' ? <>
          <label htmlFor="upload-image-edit" className="modal-image-edit__upload-label">{this.props.getTranslate('companyinfo-modalCardTabLoadImg')}</label>
          <input id="upload-image-edit" type="file" onChange={this.handlerPreviewImg.bind(this)} />
          {this.state.errorFormatImgs ? <div className="validation-error">Формат зображень повинен бути jpg png або webp</div> : ''}
          {this.state.errorSizeImgs ? <div className="validation-error">Файл не повинен перевищувати 25 мегабайт</div> : ''}
      </> : <div className="upload-image-info">{this.state.mainImgName}
        <button className="upload-image-info__btn" type="button" onClick={this.handlerDeletePreview.bind(this)}>
          <svg className="upload-image-info__delete">
            <use href={`${sprite}#delete`}></use>
          </svg>
        </button>
      </div>}
      </div>
      <div className="modal-image-edit__nav register-form__submit form-nav">
          <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="submit" disabled={this.state.isLoadedSubmitImage ? true : false}>{this.props.getTranslate('companyinfo-modalScreensaverSendBtn')}{this.state.isLoadedSubmitImage && <Loading />}</button>
      </div>
      </form>
    </div> : <div className="modal-image-edit__content">
    <div className="modal-image-edit__imgs">
    {this.state.mainImgs.map((item, index) => {
      return <div className={`modal-image-edit__img ${!this.state.mainImgs && index === 0 && !this.state.previewImg ? 'active' : ''} ${this.state.mainImgObj && this.state.mainImgObj.url === item && !this.state.previewImg ? 'active' : ''} ${this.state.indexImg === index ? 'active' : ''}`} key={index} onClick={this.handlerSetDefaultImg.bind(this, item, 'MAIN', index)}>
              <img src={item.replace( /https:/g, "http:" )} alt="" />
            </div>
    })}
    </div>
    <div className="modal-image-edit__nav register-form__submit form-nav">
        <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="button" onClick={this.handlerSubmitDefaultImg.bind(this)} disabled={this.state.isLoadedSubmitImage ? true : false}>{this.props.getTranslate('companyinfo-modalScreensaverSendBtn')}{this.state.isLoadedSubmitImage && <Loading />}</button>
    </div>
    </div>}
  </div>
</div>
</div> : ''}
    </>;
  }
}
export default AccountCompany;
