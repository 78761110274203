import React from "react";
import Loading from './Loading';
import sprite from '../media/icons.svg';
class DeleteModal extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return  <>
      {this.props.modalStatus ? <div id="modal-container" className={this.props.modalDisabledStatus ? `two disabled ${this.props.modalDisabledClass}` : `two ${this.props.modalDisabledClass}`}>
      <div className="modal-background" onClick={this.props.handlerModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.props.modalTitle}</h2>
          <button className="close-modal" type="button" onClick={this.props.handlerModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
          <div className="delete-category-form__description">{this.props.modalDescription}</div>
          <div className="field-wrap__submit">
            <button className="btn btn_size_lg btn_theme_remove btn_width_100 user-info__nav-btn" type="button" disabled={this.props.modalHandlerBtnLoading? true : false} onClick={this.props.modalHandlerDelete}>{this.props.modalHandlerBtnTxt}{this.props.modalHandlerBtnLoading && <Loading />}</button>
          </div>
        </div>
      </div> : ''}
    </>
  }
}
export default DeleteModal;
